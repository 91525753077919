import React from 'react'

function Slide() {
  return (
    <div className='under'>
    Under Construction :3
  </div>
  )
}

export default Slide