import React, { useState } from "react";
import { Link } from "react-router-dom";

function Cafe() {
  const [classNameCouch, setClassNameCouch] = useState("couch");
  const [classNameState, setClassNameState] = useState("couch2");
  const [classNameCouch3, setClassNameCouch3] = useState("couch3");
  const [classNameCouch4, setClassNameCouch4] = useState("couch4");
  const [classNameCouch5, setClassNameCouch5] = useState("couch5");
  const [classNameCouch6, setClassNameCouch6] = useState("couch6");
  const [classNameCouch7, setClassNameCouch7] = useState("couch7");
  const [classNameCouch8, setClassNameCouch8] = useState("couch8");
  const [classNameCouch9, setClassNameCouch9] = useState("couch9");
  const [classNameDoor, setClassNameDoor] = useState("couch7");
  const [classNameDoor1, setClassNameDoor1] = useState("couch4");

  return (
    <div className="cafe">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 1200"
      >
        <g>
          <path
            className="st0"
            d="M896.84,348.3c-0.92,0.53-1.91,0.88-2.92,1.14C894.93,349.19,895.92,348.84,896.84,348.3z"
          />
          <path
            className="st0"
            d="M888.85,288.7c0.29,0.06,0.58,0.11,0.87,0.16c0.19,0.38,0.37,0.73,0.53,1.08c-0.17-0.34-0.34-0.7-0.53-1.08
		C889.44,288.81,889.15,288.76,888.85,288.7z"
          />
          <path
            className="st0"
            d="M892.37,349.77c-0.52,0.09-1.05,0.17-1.57,0.25C891.32,349.94,891.85,349.86,892.37,349.77z"
          />
          <path
            className="st0"
            d="M891.73,294.07c-0.14-1.14-0.64-2.43-1.47-4.13C891.08,291.64,891.59,292.93,891.73,294.07z"
          />
          <path
            className="st0"
            d="M898.17,347.35c0.42-0.37,0.83-0.8,1.21-1.3h0C899,346.55,898.59,346.98,898.17,347.35z"
          />
          <path
            className="st0"
            d="M815.12,406.85c0.05,0.34,0.09,0.68,0.15,1.01C815.22,407.53,815.17,407.19,815.12,406.85z"
          />
          <path
            className="st0"
            d="M798.97,444.88c-0.08,0.02-0.16,0.05-0.25,0.07C798.81,444.93,798.89,444.9,798.97,444.88L798.97,444.88z"
          />
          <path
            className="st0"
            d="M814.98,405.84c-0.28-1.68-0.8-3.24-2.92-4c-1.18-2.05-2.36-4.1-3.53-6.14c1.17,2.04,2.35,4.09,3.53,6.14
		C814.17,402.6,814.7,404.16,814.98,405.84z"
          />
          <path
            className="st0"
            d="M816.25,413.25c3.15,0.56,6.3,1.12,9.44,1.67C822.55,414.37,819.4,413.81,816.25,413.25L816.25,413.25z"
          />
          <path
            className="st0"
            d="M914.09,289.97c0.25,0.62,0.53,1.25,0.86,1.89c0.16,0.11,0.31,0.23,0.47,0.35c-0.15-0.12-0.31-0.24-0.47-0.35
		C914.62,291.22,914.33,290.59,914.09,289.97z"
          />
          <path
            className="st0"
            d="M942.31,244.3c0.37,0.32,0.75,0.64,1.12,0.97l0.1,0.11c0,0-0.1-0.11-0.1-0.11
		C943.06,244.94,942.69,244.62,942.31,244.3z"
          />
          <path
            className="st0"
            d="M985.23,272.12c-0.26,0-0.51,0-0.76-0.01C984.72,272.12,984.97,272.12,985.23,272.12z"
          />
          <path
            className="st0"
            d="M965.92,266.4c-1.04-0.18-1.96-0.33-2.88-0.49c-2.34-1.2-4.69-2.4-7.03-3.61c2.34,1.2,4.69,2.4,7.03,3.61
		C963.95,266.07,964.87,266.22,965.92,266.4z"
          />
          <path
            className="st0"
            d="M986,272.11c0.51,0,1.02,0,1.52,0.04l0,0C987.02,272.12,986.51,272.11,986,272.11z"
          />
          <path
            className="st0"
            d="M801.11,416.84c0.04-4.03,0.08-8.05,0.13-12.08c0,0,0,0,0,0c-0.14-0.32-0.29-0.63-0.43-0.95
		c0.14,0.32,0.29,0.63,0.43,0.95C801.19,408.78,801.15,412.81,801.11,416.84z"
          />
          <path
            className="st0"
            d="M913.74,289.04c-0.31-0.92-0.53-1.83-0.66-2.73C913.21,287.21,913.43,288.12,913.74,289.04z"
          />
          <path
            className="st0"
            d="M896.84,348.3c0.46-0.27,0.91-0.58,1.33-0.95C897.74,347.72,897.3,348.04,896.84,348.3z"
          />
          <path
            className="st0"
            d="M962,310.64c-0.3-0.34-0.6-0.69-0.89-1.03c0,0,0,0,0,0C961.4,309.95,961.7,310.3,962,310.64z"
          />
          <path
            className="st0"
            d="M950.02,359.2c0.27,0.29,0.53,0.57,0.8,0.86C950.55,359.77,950.28,359.49,950.02,359.2
		c-2.2-3.59-4.4-7.19-6.6-10.78C945.62,352.01,947.82,355.61,950.02,359.2z"
          />
          <path
            className="st0"
            d="M788.6,328.24c1.07,0.63,2.05,1.37,2.96,2.21C790.65,329.61,789.67,328.86,788.6,328.24z"
          />
          <path
            className="st0"
            d="M791.9,350.24L791.9,350.24c-0.52-0.73-1-1.49-1.51-2.23C790.9,348.75,791.38,349.51,791.9,350.24z"
          />
          <path
            className="st0"
            d="M792.43,331.32c1.03,1.09,1.94,2.32,2.75,3.64C794.37,333.64,793.46,332.41,792.43,331.32z"
          />
          <path
            className="st0"
            d="M735.96,349.65c1.42,0.25,2.85,0.51,4.27,0.76c1.18,0.9,2.36,1.79,3.55,2.69c-1.19-0.9-2.37-1.8-3.55-2.69
		C738.81,350.16,737.39,349.9,735.96,349.65z"
          />
          <path
            className="st0"
            d="M706.68,323.05c0.91,0.53,1.81,1.06,2.72,1.59c1.32,0.68,2.64,1.37,4.14,2.14c-1.49-0.77-2.82-1.46-4.14-2.14
		C708.49,324.11,707.58,323.58,706.68,323.05c-1.18,1.93-2.36,3.87-3.54,5.8C704.31,326.92,705.5,324.99,706.68,323.05z"
          />
          <path
            className="st0"
            d="M702.9,327.12c0.08,0.58,0.15,1.16,0.23,1.73c0.41,1.46,0.82,2.91,1.23,4.37c-0.41-1.46-0.82-2.91-1.23-4.37
		C703.06,328.28,702.98,327.7,702.9,327.12z"
          />
          <path
            className="st0"
            d="M796.86,412.12c-0.01-0.1-0.03-0.2-0.04-0.31C796.83,411.91,796.84,412.01,796.86,412.12L796.86,412.12
		c1.42,1.57,2.84,3.15,4.25,4.72c1.1,0.65,2.21,1.31,3.31,1.96c-1.1-0.65-2.21-1.31-3.31-1.96
		C799.69,415.26,798.27,413.69,796.86,412.12z"
          />
          <path
            className="st0"
            d="M729.58,312.78c0-0.47-0.11-1-0.36-1.6c-0.99-0.33-1.98-0.67-2.98-1c0.99,0.33,1.98,0.67,2.98,1
		C729.47,311.78,729.58,312.31,729.58,312.78z"
          />
          <path
            className="st0"
            d="M760.35,330.34c0,0,0.01,0,0.03-0.01C760.36,330.34,760.35,330.34,760.35,330.34
		C760.35,330.34,760.35,330.34,760.35,330.34z"
          />
          <path
            className="st0"
            d="M777.44,338.14c-0.65,0-1.29-0.01-1.94-0.01C776.14,338.13,776.79,338.14,777.44,338.14
		c0.76,0.72,1.52,1.45,2.28,2.17c1.45,0.44,2.9,0.88,4.34,1.31c-1.45-0.44-2.9-0.88-4.34-1.31
		C778.96,339.59,778.2,338.87,777.44,338.14z"
          />
          <path
            className="st0"
            d="M799.66,345.98c-0.8-2.34-1.63-4.67-2.4-7.02c-0.52-1.2-1.09-2.34-1.72-3.43c0.63,1.08,1.21,2.23,1.72,3.43
		C798.03,341.31,798.86,343.64,799.66,345.98z"
          />
          <path
            className="st0"
            d="M751.11,339.27c-0.73-0.02-1.48-0.11-2.27-0.28c-0.89-0.5-1.79-0.99-2.69-1.47c0.9,0.48,1.8,0.97,2.69,1.47
		C749.63,339.16,750.38,339.25,751.11,339.27z"
          />
          <path
            className="st0"
            d="M792.92,411.88c-1.5-0.09-3-0.18-4.52-0.27C789.92,411.69,791.42,411.79,792.92,411.88z"
          />
          <path
            className="st0"
            d="M772.36,333.33c0.12-0.3,0.3-0.56,0.55-0.75C772.66,332.77,772.48,333.04,772.36,333.33z"
          />
          <path
            className="st0"
            d="M775.5,338.13L775.5,338.13c-0.29-1.9-0.58-3.8-0.87-5.7C774.92,334.33,775.21,336.23,775.5,338.13z"
          />
          <path
            className="st0"
            d="M790.3,335.21c-0.41-0.17-0.82-0.35-1.23-0.52c-1.68-1.27-3.43-2.05-5.25-2.3c1.82,0.25,3.57,1.03,5.25,2.3
		C789.48,334.86,789.89,335.03,790.3,335.21z"
          />
          <path
            className="st0"
            d="M774.62,332.43c0.76,0.28,1.52,0.55,2.29,0.83C776.15,332.98,775.39,332.7,774.62,332.43
		c-0.78-0.23-1.33-0.13-1.71,0.15C773.3,332.3,773.84,332.2,774.62,332.43z"
          />
          <path
            className="st0"
            d="M917.53,263.47c0.16,0.57,0.38,1.2,0.67,1.91C917.91,264.67,917.69,264.04,917.53,263.47z"
          />
          <path
            className="st0"
            d="M758.9,208.47c0.63-0.82,0.97-1.86,1.45-2.8c0.23-0.26,0.5-0.5,0.68-0.79c-0.18,0.29-0.45,0.53-0.68,0.79
		C759.88,206.61,759.53,207.65,758.9,208.47z"
          />
          <path
            className="st0"
            d="M734.14,200.81c-0.02-0.12-0.04-0.23-0.06-0.35C734.1,200.58,734.12,200.7,734.14,200.81z"
          />
          <path
            className="st0"
            d="M733.88,198.43c0-0.04-0.01-0.09-0.01-0.13C733.87,198.34,733.87,198.38,733.88,198.43z"
          />
          <path
            className="st0"
            d="M755.37,179.83c-0.98-1.88-1.95-3.76-2.93-5.64C753.42,176.08,754.4,177.96,755.37,179.83
		C755.37,179.83,755.37,179.83,755.37,179.83z"
          />
          <path
            className="st0"
            d="M793.63,222.01c2.94-2.23,5.88-4.46,8.71-6.61C799.51,217.55,796.57,219.79,793.63,222.01
		C793.63,222.01,793.63,222.01,793.63,222.01z"
          />
          <path
            className="st0"
            d="M918.3,258.91c-0.3,0.38-0.54,0.75-0.71,1.14C917.77,259.66,918,259.29,918.3,258.91z"
          />
          <path
            className="st0"
            d="M754.61,166.95c0.37-0.13,0.74-0.28,1.11-0.42C755.34,166.67,754.98,166.82,754.61,166.95
		c-0.01,0.67-0.01,1.33-0.02,2C754.6,168.28,754.6,167.62,754.61,166.95z"
          />
          <path
            className="st0"
            d="M758.11,222.92c-0.49,0.06-0.98,0.1-1.46,0.11C757.13,223.02,757.62,222.98,758.11,222.92z"
          />
          <path
            className="st0"
            d="M743.74,217.44c-0.65-0.64-1.27-1.33-1.88-2.05C742.47,216.11,743.1,216.8,743.74,217.44z"
          />
          <path
            className="st0"
            d="M720.67,199.84c0.29,0.99,0.61,1.98,0.95,2.96c0.59,4,1.47,7.91,2.58,11.75c-1.11-3.84-1.99-7.75-2.58-11.75
		C721.28,201.82,720.97,200.83,720.67,199.84z"
          />
          <path
            className="st0"
            d="M753.96,156.87c0.08,0.27,0.17,0.54,0.25,0.82C754.13,157.41,754.04,157.14,753.96,156.87
		C753.96,156.86,753.96,156.86,753.96,156.87z"
          />
          <path
            className="st0"
            d="M739.71,212.43c0.42,0.61,0.83,1.23,1.25,1.84c0.3,0.38,0.6,0.75,0.9,1.11c-0.3-0.36-0.61-0.73-0.9-1.11
		C740.54,213.66,740.12,213.05,739.71,212.43z"
          />
          <path
            className="st0"
            d="M754.82,159.67c-0.19-0.62-0.36-1.18-0.53-1.72C754.46,158.49,754.63,159.06,754.82,159.67z"
          />
          <path
            className="st0"
            d="M455.86,316.3c0.14-0.15,0.28-0.3,0.41-0.44C456.14,316,456,316.14,455.86,316.3z"
          />
          <path
            className="st0"
            d="M753.86,227.93L753.86,227.93c-1.05-0.13-2.1-0.24-3.16-0.34C751.76,227.69,752.82,227.8,753.86,227.93z"
          />
          <path
            className="st0"
            d="M758.11,222.92c0.49-0.06,1-0.14,1.51-0.24c8.35,0,16.7,0,25.06,0c-8.35,0-16.7,0-25.06,0
		C759.11,222.78,758.61,222.86,758.11,222.92z"
          />
          <path
            className="st0"
            d="M953.72,408.67c1.59,1.46,2.98,2.74,4.37,4.01c0.85,0.97,1.7,1.94,2.54,2.92c-0.85-0.97-1.7-1.94-2.54-2.92
		C956.7,411.41,955.31,410.13,953.72,408.67z"
          />
          <path
            className="st0"
            d="M739.34,166.55c-0.65,0.16-1.3,0.32-1.95,0.48l0,0C738.04,166.88,738.69,166.72,739.34,166.55z"
          />
          <path
            className="st0"
            d="M471.53,384.14L471.53,384.14c-0.46-0.02-0.91-0.04-1.37-0.06C470.62,384.11,471.07,384.12,471.53,384.14z"
          />
          <path
            className="st0"
            d="M889.28,230.33c1.23,0.98,2.38,2.16,3.61,3.15c0,0,0,0,0,0C891.67,232.49,890.52,231.31,889.28,230.33z"
          />
          <polygon
            className="st0"
            points="892.96,233.54 892.96,233.54 892.97,233.54 	"
          />
          <path
            className="st0"
            d="M780.38,227.93L780.38,227.93c-8.84,0-17.68,0-26.51,0C762.7,227.93,771.54,227.93,780.38,227.93z"
          />
          <path
            className="st0"
            d="M918.8,258.33c0.36-0.39,0.79-0.8,1.28-1.24h0C919.59,257.53,919.16,257.94,918.8,258.33z"
          />
          <path
            className="st0"
            d="M917.35,262.66c-0.09-0.51-0.11-0.97-0.07-1.4C917.23,261.69,917.26,262.15,917.35,262.66z"
          />
          <path
            className="st0"
            d="M917.59,260.05c-0.17,0.38-0.28,0.78-0.32,1.21C917.31,260.83,917.42,260.43,917.59,260.05z"
          />
          <path
            className="st0"
            d="M736.88,326.03c0.4,0.2,0.86,0.39,1.21,0.61c4.01,2.42,8.03,4.85,12.04,7.27c-4.01-2.42-8.03-4.85-12.04-7.27
		C737.74,326.42,737.28,326.24,736.88,326.03z"
          />
          <path
            className="st0"
            d="M887.1,219.24c1.64,1.29,3.27,2.58,4.91,3.86c0.43,0.11,0.86,0.22,1.29,0.32c-0.43-0.11-0.86-0.22-1.29-0.32
		C890.37,221.81,888.73,220.52,887.1,219.24z"
          />
          <polygon
            className="st0"
            points="922.24,263 922.23,262.99 922.24,263 	"
          />
          <path
            className="st0"
            d="M752.32,157.53c-10.93,3.6-21.86,7.2-32.79,10.8c0,0,0,0,0,0C730.45,164.74,741.38,161.14,752.32,157.53
		L752.32,157.53z"
          />
          <path
            className="st0"
            d="M720.67,199.84c-0.58-1.98-1.06-3.98-1.43-5.99C719.62,195.86,720.09,197.86,720.67,199.84z"
          />
          <path
            className="st0"
            d="M739.34,166.55c1.3-0.33,2.6-0.68,3.89-1.02C741.93,165.88,740.63,166.22,739.34,166.55z"
          />
          <path
            className="st0"
            d="M832.72,265.59c-0.43-0.39-0.91-0.82-1.4-1.25c-0.93-2.64-1.87-5.28-2.8-7.93c0.93,2.64,1.87,5.28,2.8,7.93
		C831.81,264.77,832.29,265.2,832.72,265.59z"
          />
          <path
            className="st0"
            d="M839.97,279.43c0.17-0.96,0.34-1.92,0.51-2.88h0C840.31,277.51,840.14,278.47,839.97,279.43z"
          />
          <path
            className="st0"
            d="M829.52,184.94c-0.61,3.53-1.16,7.08-1.84,10.59C828.35,192.02,828.91,188.47,829.52,184.94
		C829.52,184.94,829.52,184.94,829.52,184.94z"
          />
          <path
            className="st0"
            d="M838.83,189.39c0.63,0.13,1.3,0.22,2.06,0.29C840.12,189.61,839.46,189.52,838.83,189.39z"
          />
          <path
            className="st0"
            d="M837.03,277.2L837.03,277.2c-1.47,3.16-3.36,6.2-4.51,9.44C833.67,283.4,835.56,280.36,837.03,277.2z"
          />
          <path
            className="st0"
            d="M738.05,210c-0.24-0.31-0.47-0.62-0.69-0.94C737.59,209.38,737.81,209.69,738.05,210
		c1.25-3.95,2.93-7.65,4.88-11.2C740.98,202.35,739.3,206.05,738.05,210z"
          />
          <path
            className="st0"
            d="M775.41,241.1c0.01,0,0.01,0,0.02,0C775.43,241.1,775.42,241.1,775.41,241.1z"
          />
          <path
            className="st0"
            d="M769.57,241.3c0.05,0,0.1-0.01,0.15-0.01C769.68,241.3,769.62,241.3,769.57,241.3z"
          />
          <path
            className="st0"
            d="M766.67,241.47c0.06,0,0.11-0.01,0.17-0.01C766.79,241.46,766.73,241.46,766.67,241.47z"
          />
          <path
            className="st0"
            d="M772.48,241.17c0.03,0,0.07,0,0.1,0C772.55,241.16,772.52,241.17,772.48,241.17z"
          />
          <path
            className="st0"
            d="M768.98,312.41c0.52-0.16,1.04-0.33,1.6-0.51C770.02,312.08,769.5,312.25,768.98,312.41
		c-0.41,0.02-0.83-0.01-1.25-0.01C768.14,312.4,768.56,312.43,768.98,312.41z"
          />
          <path
            className="st0"
            d="M727.55,363.7c0.63-2.05,1.27-4.1,1.9-6.16c0.77-0.23,1.53-0.46,2.3-0.69c-0.77,0.23-1.53,0.46-2.3,0.69
		C728.82,359.6,728.19,361.65,727.55,363.7z"
          />
          <path
            className="st0"
            d="M748.29,386.99c0.17-0.02,0.33-0.04,0.5-0.06c0.83,0.88,1.67,1.75,2.49,2.62c-0.83-0.87-1.66-1.75-2.49-2.62
		C748.62,386.95,748.46,386.97,748.29,386.99z"
          />
          <polygon
            className="st0"
            points="723.66,358.87 723.66,358.87 723.66,358.87 	"
          />
          <path
            className="st0"
            d="M734.56,386.09L734.56,386.09c-1.21,2.85-2.42,5.69-3.63,8.54c-1.86-0.22-3.72-0.44-5.61-0.67
		c1.9,0.23,3.76,0.45,5.61,0.67c0.11,0.05,0.22,0.09,0.33,0.11c-0.11-0.02-0.23-0.06-0.33-0.11
		C732.14,391.78,733.35,388.94,734.56,386.09z"
          />
          <path
            className="st0"
            d="M726.32,356.11c-0.7,0.67-1.37,1.48-2.06,2.18C724.95,357.59,725.62,356.78,726.32,356.11z"
          />
          <path
            className="st0"
            d="M892.85,260.83L892.85,260.83c-0.6-0.36-1.18-0.74-1.77-1.12C891.67,260.09,892.26,260.47,892.85,260.83z"
          />
          <path
            className="st0"
            d="M892.85,260.82L892.85,260.82c0.49,0.56,0.97,1.13,1.46,1.69c0.84,0.87,1.67,1.73,2.51,2.6
		c-0.84-0.87-1.67-1.73-2.51-2.6C893.83,261.95,893.34,261.39,892.85,260.82z"
          />
          <path
            className="st0"
            d="M903.54,265.11L903.54,265.11L903.54,265.11c-2.24,0-4.48,0-6.72,0.01
		C899.06,265.11,901.3,265.11,903.54,265.11z"
          />
          <path
            className="st0"
            d="M869.21,318.09C869.21,318.09,869.21,318.09,869.21,318.09c1.95-0.96,3.89-1.93,5.84-2.88
		C873.09,316.17,871.15,317.13,869.21,318.09z"
          />
          <path
            className="st0"
            d="M866.23,322.72c0.96-1.5,1.96-3.06,2.96-4.62c0,0,0,0,0,0C868.19,319.66,867.19,321.22,866.23,322.72z"
          />
          <path
            className="st0"
            d="M910.02,278L910.02,278c-1.42,0.01-2.85,0.02-4.27,0.02c0,0,0,0,0,0C907.17,278.01,908.59,278.01,910.02,278z"
          />
          <path
            className="st0"
            d="M790.46,246.54C790.46,246.54,790.46,246.54,790.46,246.54c0.83-0.01,1.68-0.08,2.52-0.14
		C792.14,246.46,791.29,246.53,790.46,246.54z"
          />
          <path
            className="st0"
            d="M846.37,245.75c-0.28,2.15-0.55,4.26-0.83,6.36c0,0,0,0,0,0C845.81,250,846.09,247.9,846.37,245.75z"
          />
          <path
            className="st0"
            d="M798.81,261.79c-2.2-0.13-4.4-0.21-6.61-0.29C794.41,261.59,796.61,261.66,798.81,261.79z"
          />
          <path
            className="st0"
            d="M957.12,426.57c-0.12,0.17-0.24,0.34-0.38,0.51C956.88,426.91,957.01,426.74,957.12,426.57z"
          />
          <path
            className="st0"
            d="M953.72,425.47c-0.29,0.1-0.57,0.2-0.83,0.29C953.16,425.67,953.43,425.57,953.72,425.47L953.72,425.47z"
          />
          <path
            className="st0"
            d="M955.91,427.98c-0.02,0.02-0.04,0.04-0.06,0.06C955.87,428.02,955.89,428,955.91,427.98z"
          />
          <path
            className="st0"
            d="M950.87,426.29c0.57-0.09,1.24-0.27,2.02-0.53C952.11,426.02,951.44,426.2,950.87,426.29z"
          />
          <path
            className="st0"
            d="M955.32,420.48c-0.27,1.21-0.54,2.42-0.82,3.64C954.77,422.91,955.04,421.7,955.32,420.48L955.32,420.48z"
          />
          <path
            className="st0"
            d="M949.42,426.26c0.27,0.08,0.57,0.11,0.91,0.09C949.99,426.36,949.69,426.33,949.42,426.26z"
          />
          <path
            className="st0"
            d="M953.72,429.76L953.72,429.76c-2.06,0.08-3.97-0.51-5.5-1.62C949.75,429.25,951.65,429.84,953.72,429.76z"
          />
          <path
            className="st0"
            d="M946.99,427.06c-0.56-0.6-1.05-1.29-1.44-2.05C945.95,425.77,946.43,426.46,946.99,427.06z"
          />
          <path
            className="st0"
            d="M726.97,355.54c0.3-0.24,0.6-0.45,0.91-0.59C727.57,355.1,727.27,355.31,726.97,355.54z"
          />
          <path
            className="st0"
            d="M787.6,388.46c0.55-0.2,1.09-0.41,1.64-0.61c0.54-0.41,1.09-0.82,1.63-1.23c-0.54,0.41-1.08,0.82-1.63,1.23
		C788.69,388.06,788.15,388.26,787.6,388.46z"
          />
          <path
            className="st0"
            d="M726.16,359.11L726.16,359.11c1.1-0.52,2.2-1.04,3.3-1.56C728.35,358.07,727.25,358.59,726.16,359.11z"
          />
          <path
            className="st0"
            d="M764.81,355.4c-1.21,1.89-2.42,3.78-3.63,5.67h0C762.39,359.18,763.6,357.29,764.81,355.4z"
          />
          <path
            className="st0"
            d="M900.78,360.67c-0.02-0.14-0.04-0.27-0.07-0.41C900.73,360.4,900.76,360.54,900.78,360.67z"
          />
          <path
            className="st0"
            d="M744.59,359.71C744.59,359.71,744.59,359.71,744.59,359.71c-3-0.83-5.99-1.65-8.99-2.48
		C738.6,358.06,741.59,358.89,744.59,359.71z"
          />
          <path
            className="st0"
            d="M745.63,397.16c-0.3,0.08-0.6,0.14-0.91,0.17C745.04,397.3,745.34,397.23,745.63,397.16z"
          />
          <path
            className="st0"
            d="M901.07,364.72c0,0.01,0,0.02,0,0.03C901.07,364.74,901.07,364.73,901.07,364.72z"
          />
          <path
            className="st0"
            d="M837.69,429.11c0.2-0.47,0.4-0.95,0.6-1.42c0.23-1.42,0.34-2.79,0.35-4.11c-0.01,1.32-0.12,2.69-0.35,4.11
		C838.09,428.17,837.89,428.64,837.69,429.11z"
          />
          <path
            className="st0"
            d="M784.73,390.27c-2.4,1.02-4.8,2.04-7.19,3.06c-3.8,1.15-7.6,2.31-11.4,3.46c3.8-1.15,7.6-2.31,11.4-3.46
		C779.93,392.31,782.33,391.29,784.73,390.27z"
          />
          <path
            className="st0"
            d="M728.02,262.24c-3.06-2.58-6.13-5.15-9.19-7.73C721.89,257.09,724.95,259.67,728.02,262.24
		c0.46,0.42,0.92,0.85,1.37,1.29C728.93,263.09,728.48,262.66,728.02,262.24L728.02,262.24z"
          />
          <path
            className="st0"
            d="M749.64,264.35c-0.03,0.51-0.06,0.97-0.09,1.42c-0.21,0.34-0.41,0.68-0.59,1.02c0.18-0.34,0.37-0.68,0.59-1.02
		C749.58,265.32,749.61,264.86,749.64,264.35z"
          />
          <path
            className="st0"
            d="M741.56,281.55c0.24,0.07,0.47,0.19,0.68,0.38C742.03,281.74,741.8,281.62,741.56,281.55z"
          />
          <path
            className="st0"
            d="M749.24,308.06c-1.08-0.06-2.17-0.08-3.26-0.09C747.07,307.97,748.16,308,749.24,308.06z"
          />
          <path
            className="st0"
            d="M713.26,250.27c0.61,0.44,1.27,0.85,1.95,1.2c0,0,0,0,0,0C714.53,251.12,713.88,250.71,713.26,250.27z"
          />
          <path
            className="st0"
            d="M711.63,248.91c-0.05-0.05-0.11-0.1-0.17-0.15C711.52,248.81,711.57,248.85,711.63,248.91z"
          />
          <polygon
            className="st0"
            points="738.11,307.44 738.11,307.44 738.11,307.44 	"
          />
          <path
            className="st0"
            d="M732.96,262.08c1.71,1.76,3.44,3.51,5.09,5.32C736.4,265.6,734.68,263.85,732.96,262.08z"
          />
          <path
            className="st0"
            d="M729.58,258.53c0.55,0.6,1.11,1.2,1.68,1.79C730.69,259.72,730.13,259.13,729.58,258.53
		c-0.26,0.62-0.52,1.24-0.78,1.86C729.06,259.77,729.32,259.15,729.58,258.53z"
          />
          <path
            className="st0"
            d="M762.2,301.73c0.85-0.28,1.7-0.57,2.54-0.87C763.89,301.16,763.05,301.45,762.2,301.73z"
          />
          <path
            className="st0"
            d="M760.42,330.33L760.42,330.33c-1.91,1.41-3.7,3.17-5.86,3.85C756.72,333.5,758.51,331.74,760.42,330.33z"
          />
          <path
            className="st0"
            d="M742.7,307.97c1.09,0,2.18-0.01,3.27-0.01C744.89,307.96,743.79,307.97,742.7,307.97z"
          />
          <path
            className="st0"
            d="M754.55,334.17c-0.43,0.13-0.88,0.23-1.35,0.26C753.67,334.4,754.12,334.31,754.55,334.17z"
          />
          <path
            className="st0"
            d="M760.54,330.18c0.22-0.2,0.45-0.39,0.67-0.59c1.68-1.17,3.36-2.34,5.11-3.56c-1.75,1.22-3.43,2.39-5.11,3.56
		C760.98,329.78,760.76,329.98,760.54,330.18z"
          />
          <path
            className="st0"
            d="M870.56,309.62c-0.03-2.22-0.48-4.45-0.7-6.68c0,0,0,0,0,0C870.09,305.17,870.53,307.41,870.56,309.62z"
          />
          <path
            className="st0"
            d="M748.1,268.82c0.22-0.67,0.51-1.35,0.86-2.02C748.61,267.47,748.32,268.15,748.1,268.82z"
          />
          <path
            className="st0"
            d="M766.32,297.12c-0.68,0.24-1.37,0.48-2.05,0.7C764.96,297.59,765.64,297.35,766.32,297.12z"
          />
          <path
            className="st0"
            d="M772.36,298.11c-0.41,0.25-0.81,0.49-1.22,0.74c-1.73,0.44-3.43,1-5.13,1.58c1.7-0.58,3.4-1.13,5.13-1.58
		C771.54,298.61,771.95,298.36,772.36,298.11z"
          />
          <path
            className="st0"
            d="M738.09,307.41L738.09,307.41c0.44,0.18,0.89,0.36,1.33,0.54c0.54,0.01,1.09,0.02,1.64,0.02
		c-0.55,0-1.09-0.01-1.64-0.02C738.98,307.77,738.53,307.59,738.09,307.41z"
          />
          <path
            className="st0"
            d="M875.67,267.85c-0.4,0.26-0.77,0.51-1.13,0.75C874.9,268.36,875.27,268.11,875.67,267.85L875.67,267.85z"
          />
          <path
            className="st0"
            d="M747.48,272.82c0-0.33,0-0.66,0.03-1C747.49,272.16,747.48,272.49,747.48,272.82z"
          />
          <path
            className="st0"
            d="M883.13,268.97c1.38-0.46,2.99-0.32,4.55-0.5C886.12,268.65,884.5,268.51,883.13,268.97z"
          />
          <path
            className="st0"
            d="M871.84,270.61c0.74-0.61,1.64-1.27,2.71-2C873.47,269.33,872.58,269.99,871.84,270.61z"
          />
          <path
            className="st0"
            d="M869.94,303.01C869.94,303.01,869.94,303.01,869.94,303.01c0.37,0.32,0.81,0.6,1.13,0.94
		C870.75,303.6,870.31,303.32,869.94,303.01z"
          />
          <path
            className="st0"
            d="M882,269.55C882,269.55,882,269.55,882,269.55c-0.88,0.27-1.75,0.55-2.63,0.82
		C880.25,270.09,881.12,269.82,882,269.55z"
          />
          <path
            className="st0"
            d="M831.3,400.11c-0.23-0.37-0.46-0.74-0.69-1.11l0,0C830.84,399.37,831.07,399.74,831.3,400.11z"
          />
          <path
            className="st0"
            d="M883.05,269c-0.17,0.06-0.34,0.13-0.5,0.21C882.71,269.13,882.88,269.06,883.05,269z"
          />
          <path
            className="st0"
            d="M882,269.55c0.07-1.23,0.13-2.46,0.2-3.69c0,0,0,0,0,0C882.13,267.08,882.06,268.31,882,269.55L882,269.55z"
          />
          <path
            className="st0"
            d="M755.9,270.46c0.28-0.22,0.66-0.31,1.16-0.29C756.56,270.15,756.18,270.24,755.9,270.46z"
          />
          <path
            className="st0"
            d="M760.75,270.72c-0.36,0-0.71,0-1.06,0c-0.78-0.23-1.44-0.4-1.99-0.48c0.55,0.09,1.21,0.25,1.99,0.48
		C760.04,270.72,760.39,270.72,760.75,270.72z"
          />
          <path
            className="st0"
            d="M803.2,271.82c-0.4,0.11-0.8,0.23-1.2,0.35c0,0,0,0,0,0C802.4,272.05,802.8,271.94,803.2,271.82z"
          />
          <path
            className="st0"
            d="M813.26,277.98c-0.01-2.17-0.01-4.34-0.02-6.52h0C813.25,273.64,813.26,275.81,813.26,277.98z"
          />
          <path
            className="st0"
            d="M800.54,328.27c-0.29,1.87-0.57,3.74-0.86,5.61c-2.23-2.38-4.46-4.77-6.71-7.13c2.25,2.37,4.47,4.75,6.71,7.13
		l0,0C799.97,332.01,800.25,330.14,800.54,328.27z"
          />
          <path
            className="st0"
            d="M755.46,271.07c0.11-0.26,0.25-0.46,0.44-0.61C755.71,270.6,755.57,270.8,755.46,271.07z"
          />
          <path
            className="st0"
            d="M804.82,348.31c-0.79-2.22-1.57-4.4-2.34-6.57C803.25,343.91,804.03,346.09,804.82,348.31z"
          />
          <path
            className="st0"
            d="M816.61,320.57c-2.04-0.62-4.07-1.24-6.1-1.85c-2.97-1.41-5.94-2.82-8.91-4.24c2.97,1.41,5.94,2.82,8.91,4.24
		C812.54,319.34,814.57,319.95,816.61,320.57z"
          />
          <path
            className="st0"
            d="M785.41,213.48L785.41,213.48c0.26-0.61,0.52-1.22,0.78-1.83C785.93,212.26,785.67,212.87,785.41,213.48z"
          />
          <path
            className="st0"
            d="M247.47,655.96c-0.07,0.14-0.15,0.26-0.23,0.4C247.31,656.22,247.4,656.1,247.47,655.96z"
          />
          <path
            className="st0"
            d="M243.33,661.12c-1.74-0.91-3.48-1.82-5.27-2.76C239.86,659.31,241.6,660.21,243.33,661.12
		c1.22-0.93,2.28-2.13,3.17-3.5C245.61,658.99,244.55,660.19,243.33,661.12z"
          />
          <path
            className="st0"
            d="M418.9,386.21c0.11,0.39,0.2,0.78,0.25,1.18C419.1,386.99,419.01,386.6,418.9,386.21z"
          />
          <path
            className="st0"
            d="M437.16,730.73c-4.79,3.1-9.58,6.19-14.37,9.29l0,0C427.58,736.92,432.37,733.83,437.16,730.73z"
          />
          <path
            className="st0"
            d="M249.73,647.24c0,0.07-0.01,0.14-0.02,0.21C249.72,647.38,249.73,647.31,249.73,647.24z"
          />
          <path
            className="st0"
            d="M420.86,698.25L420.86,698.25c0.2-0.16,0.41-0.32,0.61-0.48C421.27,697.93,421.06,698.09,420.86,698.25z"
          />
          <path
            className="st0"
            d="M424.95,700.76c-0.48-0.28-0.96-0.55-1.43-0.83C423.99,700.2,424.47,700.48,424.95,700.76
		c0.2-0.16,0.4-0.33,0.6-0.49C425.35,700.43,425.15,700.59,424.95,700.76z"
          />
          <path
            className="st0"
            d="M381.21,744.47c-2.21-1.19-4.42-2.36-6.63-3.53C376.79,742.11,379,743.28,381.21,744.47
		c0.07-0.17,0.14-0.35,0.23-0.57C381.34,744.12,381.28,744.3,381.21,744.47z"
          />
          <path
            className="st0"
            d="M422.68,699.44c0.19-0.25,0.39-0.5,0.58-0.74l0,0C423.07,698.95,422.88,699.2,422.68,699.44L422.68,699.44z"
          />
          <path
            className="st0"
            d="M473.14,672.43L473.14,672.43c0.26-0.2,0.51-0.39,0.77-0.59C473.65,672.04,473.4,672.23,473.14,672.43z"
          />
          <path
            className="st0"
            d="M430.97,392.06c-0.28,1.43-0.55,2.8-0.82,4.16c-0.19,0.78-0.39,1.54-0.64,2.28c0.25-0.74,0.46-1.5,0.64-2.28
		C430.42,394.85,430.69,393.49,430.97,392.06z"
          />
          <path
            className="st0"
            d="M407.02,433.58L407.02,433.58c-1.79-0.82-3.44-1.79-4.97-2.9C403.58,431.78,405.23,432.75,407.02,433.58z"
          />
          <path
            className="st0"
            d="M416.85,382.46c0.22,0.29,0.44,0.59,0.64,0.88C417.29,383.04,417.08,382.75,416.85,382.46z"
          />
          <path
            className="st0"
            d="M429.68,425.81c0.35-0.34,0.69-0.68,1.03-1.02C430.37,425.13,430.03,425.46,429.68,425.81
		C429.68,425.8,429.68,425.8,429.68,425.81C429.68,425.8,429.68,425.81,429.68,425.81z"
          />
          <path
            className="st0"
            d="M397.05,743.66c0,0.22-0.01,0.45-0.04,0.69c-0.29-0.13-0.59-0.25-0.89-0.37c0.3,0.12,0.59,0.24,0.89,0.37
		C397.04,744.11,397.05,743.88,397.05,743.66z"
          />
          <path
            className="st0"
            d="M538.54,727.7c-1.62-0.93-3.13-1.8-4.64-2.67c-0.09-0.14-0.17-0.31-0.25-0.47c0.08,0.15,0.16,0.32,0.25,0.47
		C535.42,725.91,536.93,726.78,538.54,727.7z"
          />
          <path
            className="st0"
            d="M427.13,434.76c0.25-0.19,0.51-0.38,0.76-0.57l0,0C427.64,434.38,427.38,434.57,427.13,434.76z"
          />
          <path
            className="st0"
            d="M508.99,651.69c-2.77-1.47-5.54-2.94-8.3-4.41C503.45,648.75,506.22,650.22,508.99,651.69
		c0.21-0.17,0.41-0.33,0.62-0.5C509.4,651.35,509.2,651.52,508.99,651.69z"
          />
          <path
            className="st0"
            d="M441.03,731.43c-0.83-0.44-1.65-0.92-2.47-1.38c-0.46,0.23-0.93,0.45-1.39,0.68c0.46-0.23,0.93-0.45,1.39-0.68
		C439.38,730.51,440.19,730.99,441.03,731.43z"
          />
          <path
            className="st0"
            d="M304.11,641.98c-0.78-0.38-1.58-0.76-2.45-1.19C302.53,641.22,303.33,641.61,304.11,641.98z"
          />
          <path
            className="st0"
            d="M412.15,648.33c-0.1,0.08-0.2,0.17-0.29,0.25C411.96,648.5,412.06,648.42,412.15,648.33
		c-0.62-0.25-1.23-0.49-1.85-0.74C410.92,647.84,411.54,648.09,412.15,648.33z"
          />
          <path
            className="st0"
            d="M373.57,597.54c0.72,0.25,1.36,0.48,2.01,0.71c0.47,0.39,0.93,0.78,1.38,1.15c-0.45-0.37-0.92-0.76-1.38-1.15
		C374.93,598.02,374.28,597.8,373.57,597.54z"
          />
          <path
            className="st0"
            d="M402.8,743.69L402.8,743.69c-1.84,1.16-3.66,2.34-5.5,3.5C399.14,746.03,400.96,744.85,402.8,743.69
		c3.12-1.71,6.23-3.42,9.35-5.13c5.8-3.29,11.59-6.59,17.55-9.97c-5.95,3.38-11.75,6.68-17.55,9.97
		C409.03,740.27,405.92,741.98,402.8,743.69z"
          />
          <path
            className="st0"
            d="M323.29,575.25c-0.02-0.02-0.04-0.05-0.04-0.05c0,0,0,0,0,0C323.25,575.21,323.27,575.24,323.29,575.25z"
          />
          <path
            className="st0"
            d="M324.44,562.94c-0.38-0.38-0.74-0.81-1.12-1.16c-2.59-0.91-5.18-1.81-7.78-2.72c2.59,0.91,5.18,1.81,7.78,2.72
		C323.71,562.14,324.07,562.56,324.44,562.94z"
          />
          <path
            className="st0"
            d="M304.28,642.06c0.37,0.18,0.74,0.36,1.1,0.53c3.07,1.43,6.14,2.86,9.11,4.25c-2.98-1.39-6.04-2.82-9.11-4.25
		C305.01,642.42,304.65,642.24,304.28,642.06z"
          />
          <path
            className="st0"
            d="M367.73,594.04c-6.72-2.71-13.46-5.37-20.17-8.11C354.27,588.67,361.01,591.33,367.73,594.04
		c-5.1,3.31-10.15,6.69-15.23,10.03C357.58,600.73,362.63,597.35,367.73,594.04c0.19-0.07,0.39-0.13,0.59-0.18
		c9.82-6.44,19.64-12.87,29.46-19.31c-9.82,6.44-19.64,12.87-29.46,19.31C368.12,593.91,367.92,593.97,367.73,594.04z"
          />
          <path
            className="st0"
            d="M325.84,635.61c-2.53-1.25-5.05-2.5-7.58-3.74c-0.55-0.19-1.19-0.53-1.77-0.79c0.59,0.25,1.22,0.6,1.77,0.79
		C320.79,633.12,323.31,634.36,325.84,635.61z"
          />
          <path
            className="st0"
            d="M394.31,743.28c0.6,0.23,1.21,0.45,1.81,0.69C395.52,743.74,394.92,743.51,394.31,743.28z"
          />
          <path
            className="st0"
            d="M400.77,686.83L400.77,686.83c0.21-0.17,0.42-0.35,0.63-0.52l0,0C401.2,686.48,400.98,686.66,400.77,686.83z"
          />
          <path
            className="st0"
            d="M383.41,745.88L383.41,745.88c-0.74-0.47-1.47-0.94-2.21-1.41C381.94,744.94,382.68,745.41,383.41,745.88z"
          />
          <path
            className="st0"
            d="M381.64,743.39c-0.08,0.2-0.15,0.37-0.2,0.52C381.49,743.75,381.56,743.59,381.64,743.39z"
          />
          <path
            className="st0"
            d="M359.94,692.07c-0.51-0.53-1.03-1.05-1.54-1.58c-0.19-0.19-0.39-0.39-0.58-0.58c0.19,0.19,0.39,0.39,0.58,0.58
		C358.91,691.02,359.42,691.54,359.94,692.07z"
          />
          <path
            className="st0"
            d="M390.43,678.05c-0.2,0.16-0.39,0.33-0.59,0.49C390.04,678.38,390.23,678.21,390.43,678.05L390.43,678.05z"
          />
          <path
            className="st0"
            d="M393.41,680.73c-0.64-0.36-1.27-0.73-1.91-1.09c-0.55-0.36-1.11-0.73-1.66-1.09c0.55,0.36,1.11,0.73,1.66,1.09
		C392.13,680,392.77,680.36,393.41,680.73z"
          />
          <path
            className="st0"
            d="M365.25,695.65c-0.62-0.28-1.23-0.56-1.85-0.84c-1.15-0.91-2.31-1.82-3.46-2.74c1.15,0.91,2.31,1.82,3.46,2.74
		C364.01,695.09,364.63,695.37,365.25,695.65z"
          />
          <path
            className="st0"
            d="M321.18,574.68c-6.78-2.88-13.55-5.76-20.33-8.64C307.63,568.92,314.4,571.8,321.18,574.68
		c0.22,0.06,0.45,0.13,0.67,0.19C321.63,574.8,321.4,574.74,321.18,574.68z"
          />
          <path
            className="st0"
            d="M147.13,731.42c0.05,0,0.09-0.01,0.13-0.01C147.23,731.42,147.19,731.42,147.13,731.42
		C147.13,731.42,147.13,731.42,147.13,731.42z"
          />
          <path
            className="st0"
            d="M136.98,878.13c0.01-0.13,0.02-0.26,0.04-0.4c0,0,0,0,0,0C137,877.86,136.99,878,136.98,878.13z"
          />
          <path
            className="st0"
            d="M414.72,383.86c-0.01-0.1,0-0.2,0-0.29C414.72,383.66,414.71,383.76,414.72,383.86z"
          />
          <path
            className="st0"
            d="M478,388.36c-1.6,0.55-3.19,1.1-4.79,1.64c0,0,0,0,0,0C474.81,389.46,476.41,388.91,478,388.36z"
          />
          <path
            className="st0"
            d="M107.4,615.84c1.25-0.88,2.48-1.75,3.71-2.62c1.11-0.73,2.23-1.45,3.35-2.16c-1.12,0.72-2.24,1.44-3.35,2.16
		C109.88,614.08,108.64,614.96,107.4,615.84z"
          />
          <path
            className="st0"
            d="M124.06,531.02c-0.24,0.19-0.49,0.38-0.73,0.57c-0.13,0.11-0.33,0.24-0.49,0.36c0.16-0.12,0.35-0.25,0.49-0.36
		C123.58,531.41,123.82,531.22,124.06,531.02z"
          />
          <path
            className="st0"
            d="M175.95,473.52c2.5-1.38,5.01-2.75,7.52-4.12C180.96,470.77,178.45,472.14,175.95,473.52z"
          />
          <path
            className="st0"
            d="M133.03,839.39c0.03,0.27,0.06,0.54,0.08,0.77C133.09,839.93,133.06,839.66,133.03,839.39z"
          />
          <path
            className="st0"
            d="M125.46,607.43c0.26-0.23,0.52-0.45,0.81-0.71C125.98,606.98,125.72,607.21,125.46,607.43z"
          />
          <path
            className="st0"
            d="M114.82,618.43c-2.29,1.1-4.6,2.22-6.96,3.36C110.22,620.65,112.53,619.53,114.82,618.43z"
          />
          <path
            className="st0"
            d="M218.4,715.07c-0.65,0.3-1.29,0.73-1.96,1.31c-1.68,0.63-3.36,1.26-5.02,1.89c1.67-0.63,3.35-1.26,5.02-1.89
		C217.11,715.8,217.75,715.36,218.4,715.07z"
          />
          <path
            className="st0"
            d="M262.29,724.12c-0.19,0.11-0.37,0.22-0.56,0.32l0,0C261.91,724.34,262.1,724.23,262.29,724.12z"
          />
          <path
            className="st0"
            d="M240.97,707.27c-0.49,0.47-0.98,0.94-1.47,1.4h0C239.98,708.2,240.47,707.74,240.97,707.27z"
          />
          <path
            className="st0"
            d="M220.35,714.59c0.33-0.01,0.66,0.02,1,0.08C221.01,714.61,220.68,714.58,220.35,714.59z"
          />
          <path
            className="st0"
            d="M198.91,861.44c0,0-0.11,0.12-0.11,0.11c-5.01,4.35-10.78,7.33-16.74,9.96c5.96-2.63,11.72-5.61,16.74-9.96
		C198.8,861.55,198.91,861.44,198.91,861.44c0.19-0.15,0.39-0.29,0.58-0.44C199.3,861.15,199.1,861.29,198.91,861.44z"
          />
          <path
            className="st0"
            d="M154.85,872.54c-0.02-0.42-0.04-0.84-0.07-1.25c0,0,0,0,0,0l0,0C154.81,871.71,154.83,872.12,154.85,872.54z"
          />
          <path
            className="st0"
            d="M132.48,834.36c0.16,1.47,0.32,2.93,0.46,4.16C132.8,837.29,132.64,835.82,132.48,834.36
		c-3.42-1.2-5.22-7.53-5.14-13.91C127.26,826.83,129.06,833.16,132.48,834.36z"
          />
          <path
            className="st0"
            d="M169.56,856.92c-0.12,0.23-0.24,0.46-0.37,0.68h0C169.32,857.38,169.44,857.15,169.56,856.92z"
          />
          <path
            className="st0"
            d="M133.61,876c0.83,0.62,2.27,1.02,3.3,1.57c0,0,0,0,0,0C135.88,877.02,134.44,876.62,133.61,876z"
          />
          <path
            className="st0"
            d="M249.44,633.36c0.02,0.18,0.02,0.37,0.03,0.55C249.46,633.73,249.47,633.54,249.44,633.36L249.44,633.36z"
          />
          <path
            className="st0"
            d="M359.91,402.44c0.83-0.94,1.66-1.88,2.51-2.85C361.56,400.55,360.73,401.5,359.91,402.44z"
          />
          <path
            className="st0"
            d="M306.45,504.99c0.19,1.17,0.38,2.35,0.57,3.52C306.83,507.34,306.64,506.16,306.45,504.99z"
          />
          <path
            className="st0"
            d="M359.77,402.61C359.77,402.61,359.77,402.61,359.77,402.61c-0.34,0.25-0.68,0.49-1.03,0.74
		C359.08,403.1,359.43,402.86,359.77,402.61L359.77,402.61c-0.34,0.25-0.68,0.49-1.03,0.74c-2.53,0.81-4.93,1.7-7.01,3.33
		c2.09-1.63,4.48-2.52,7.01-3.33C359.08,403.1,359.43,402.86,359.77,402.61z"
          />
          <path
            className="st0"
            d="M416.38,381.87c0.11,0.13,0.21,0.26,0.32,0.39C416.59,382.13,416.49,382,416.38,381.87
		c-0.17,0.06-0.32,0.12-0.46,0.19C416.06,381.99,416.21,381.92,416.38,381.87z"
          />
          <path
            className="st0"
            d="M393.92,355.88L393.92,355.88c-0.17-0.19-0.34-0.39-0.51-0.58C393.58,355.49,393.75,355.69,393.92,355.88z"
          />
          <path
            className="st0"
            d="M415.55,382.26c-0.22,0.15-0.39,0.31-0.52,0.48C415.16,382.57,415.33,382.41,415.55,382.26z"
          />
          <polygon
            className="st0"
            points="314.53,510.77 314.53,510.77 314.53,510.77 	"
          />
          <path
            className="st0"
            d="M379.26,377.75c-0.5-0.79-1-1.59-1.5-2.38c-0.17-0.58-0.33-1.16-0.49-1.74c0.16,0.58,0.32,1.16,0.49,1.74
		C378.27,376.16,378.77,376.95,379.26,377.75z"
          />
          <path
            className="st0"
            d="M240.23,475.78c-1.81,1.09-3.63,2.18-5.46,3.28C236.6,477.96,238.42,476.87,240.23,475.78
		c0.27-0.19,0.54-0.39,0.84-0.61C240.77,475.4,240.5,475.59,240.23,475.78L240.23,475.78z"
          />
          <path
            className="st0"
            d="M243.6,475.49c0.53,1.65,1,3.09,1.46,4.53c0.53,6.71,1.04,13.41,1.58,20.12c-0.54-6.7-1.06-13.41-1.58-20.12
		C244.59,478.58,244.13,477.14,243.6,475.49z"
          />
          <path
            className="st0"
            d="M241.74,474.7c-0.24,0.18-0.46,0.33-0.66,0.48C241.28,475.03,241.49,474.87,241.74,474.7z"
          />
          <path
            className="st0"
            d="M249.38,544.85c0.26,2.01,0.54,4.02,0.78,6.04c0.01,1.03,0.04,2.06,0.05,3.08c-0.01-1.03-0.04-2.06-0.05-3.08
		C249.92,548.87,249.63,546.86,249.38,544.85z"
          />
          <path
            className="st0"
            d="M317.18,508.26c-0.89,0.79-1.72,1.68-2.61,2.47c0,0,0,0,0,0C315.46,509.94,316.29,509.05,317.18,508.26z"
          />
          <path
            className="st0"
            d="M314.57,510.73L314.57,510.73c-0.92,0.51-1.85,1.01-2.77,1.52c-0.96,0.72-1.93,1.43-2.89,2.15
		c0.96-0.72,1.93-1.43,2.89-2.15C312.73,511.74,313.65,511.23,314.57,510.73z"
          />
          <path
            className="st0"
            d="M313.54,418.44c-0.4,0.07-0.79,0.2-1.16,0.38C312.75,418.64,313.14,418.51,313.54,418.44z"
          />
          <polygon
            className="st0"
            points="364.87,428.4 364.82,428.37 364.87,428.4 	"
          />
          <path
            className="st0"
            d="M146.83,731.51C146.83,731.51,146.83,731.51,146.83,731.51c-2.42,1.67-4.76,3.46-7.21,5.08
		C142.07,734.98,144.42,733.18,146.83,731.51z"
          />
          <path
            className="st0"
            d="M416.77,503.71c0.35,2.15,0.69,4.31,1.05,6.46c0.01,0.78-0.02,1.56-0.03,2.33c0.01-0.78,0.04-1.56,0.03-2.33
		C417.46,508.02,417.12,505.86,416.77,503.71z"
          />
          <path
            className="st0"
            d="M640.7,673.52L640.7,673.52C640.7,673.52,640.7,673.51,640.7,673.52C640.7,673.51,640.7,673.52,640.7,673.52z"
          />
          <path
            className="st0"
            d="M562.55,671.37c-2.07-1.32-4.14-2.63-6.24-3.97C558.41,668.74,560.48,670.05,562.55,671.37
		c3.58,2.16,7.16,4.32,10.75,6.48c4.45,2.77,8.91,5.54,13.38,8.33c-4.47-2.79-8.93-5.56-13.38-8.33
		C569.71,675.69,566.13,673.53,562.55,671.37z"
          />
          <path
            className="st0"
            d="M635.65,675.94c1.69-0.81,3.38-1.63,5.07-2.44l0,0C639.03,674.31,637.34,675.13,635.65,675.94z"
          />
          <path
            className="st0"
            d="M434.09,564.02c1.51-0.51,3.02-1.12,4.53-1.6v0C437.11,562.9,435.61,563.51,434.09,564.02z"
          />
          <path
            className="st0"
            d="M456.07,496.74c2.58-1.7,5.16-3.41,7.76-5.08C461.23,493.33,458.65,495.04,456.07,496.74z"
          />
          <path
            className="st0"
            d="M456.07,496.74c-1.29,0.85-2.58,1.7-3.88,2.55c-2.16,1.24-4.32,2.48-6.47,3.71c2.15-1.23,4.31-2.47,6.47-3.71
		C453.49,498.44,454.78,497.59,456.07,496.74z"
          />
          <path
            className="st0"
            d="M373.38,799.08c-1.44-0.76-2.87-1.56-4.3-2.37C370.51,797.52,371.94,798.32,373.38,799.08z"
          />
          <path
            className="st0"
            d="M445.58,529.61c8.45-4.97,16.74-9.86,25.04-14.74c2.9-1.53,5.79-3.07,8.57-4.54
		c-2.78,1.47-5.68,3.01-8.57,4.54C462.33,519.75,454.03,524.64,445.58,529.61z"
          />
          <path
            className="st0"
            d="M646.62,669.15c-0.68-0.32-1.15-0.55-1.63-0.78c-0.41-0.46-0.76-1.01-1.19-1.43c0.43,0.42,0.78,0.97,1.19,1.43
		C645.47,668.6,645.94,668.82,646.62,669.15z"
          />
          <path
            className="st0"
            d="M290.8,745.89c0.51-0.04,1.06-0.13,1.55-0.14c0.41-0.52,0.78-1.13,1.15-1.73c-0.38,0.6-0.75,1.21-1.15,1.73
		C291.86,745.76,291.32,745.85,290.8,745.89z"
          />
          <path
            className="st0"
            d="M342.55,781c0.18-0.18,0.36-0.37,0.53-0.55l0,0C342.91,780.63,342.73,780.81,342.55,781L342.55,781z"
          />
          <path
            className="st0"
            d="M327.89,771.61c-0.19-0.11-0.37-0.22-0.56-0.33C327.51,771.4,327.7,771.51,327.89,771.61z"
          />
          <path
            className="st0"
            d="M348.45,784.59c0.81,0.58,1.63,1.17,2.44,1.75C350.08,785.76,349.26,785.17,348.45,784.59
		C348.45,784.59,348.45,784.59,348.45,784.59z"
          />
          <path
            className="st0"
            d="M329.03,772.27c0.18,0.12,0.36,0.23,0.54,0.35C329.39,772.51,329.21,772.39,329.03,772.27L329.03,772.27z"
          />
          <path
            className="st0"
            d="M351.24,785.9c-0.11,0.15-0.23,0.3-0.35,0.44C351.01,786.2,351.13,786.05,351.24,785.9L351.24,785.9z"
          />
          <path
            className="st0"
            d="M348.14,784.4c-1.32-0.92-2.63-1.84-3.95-2.76C345.51,782.56,346.83,783.48,348.14,784.4
		C348.14,784.4,348.14,784.4,348.14,784.4z"
          />
          <path
            className="st0"
            d="M343.25,781.73c-0.17-0.17-0.33-0.35-0.5-0.52C342.92,781.38,343.08,781.56,343.25,781.73
		c1.63,0.89,3.26,1.78,4.9,2.67c0,0,0,0,0,0C346.51,783.51,344.88,782.62,343.25,781.73z"
          />
          <path
            className="st0"
            d="M499.23,399.46c0.08-1.23,0.15-2.46,0.21-3.69C499.38,397,499.32,398.23,499.23,399.46z"
          />
          <path
            className="st0"
            d="M479.67,303.08c-8.18-0.24-16.3,3.39-21.26,10.31l-0.01,0.22c0,0,0.01-0.22,0.01-0.22
		C463.37,306.47,471.48,302.85,479.67,303.08z"
          />
          <path
            className="st0"
            d="M371.39,788.31C371.39,788.31,371.39,788.31,371.39,788.31c-2.22,0.79-4.39,1.12-6.52,1.12
		C367,789.43,369.16,789.11,371.39,788.31z"
          />
          <path
            className="st0"
            d="M416.99,537.56c-0.49-5.09-0.95-10.19-1.45-15.29c-0.09-2.42-0.15-4.84-0.23-7.27
		c0.08,2.42,0.13,4.85,0.23,7.27C416.04,527.37,416.5,532.46,416.99,537.56z"
          />
          <path
            className="st0"
            d="M493.33,323.21c-0.49-3.02-0.95-6.04-1.42-9.06C492.37,317.17,492.84,320.19,493.33,323.21z"
          />
          <path
            className="st0"
            d="M470.3,402.78c0.91,0.25,1.82,0.5,2.73,0.74l0,0C472.12,403.27,471.21,403.02,470.3,402.78z"
          />
          <path
            className="st0"
            d="M482.8,355.4C482.8,355.4,482.8,355.4,482.8,355.4c0.08,1.31,0.16,2.62,0.25,3.93
		C482.97,358.02,482.88,356.71,482.8,355.4z"
          />
          <path
            className="st0"
            d="M486.39,301.99c-0.6-0.3-1.25-0.56-1.97-0.78C485.14,301.43,485.79,301.69,486.39,301.99z"
          />
          <path
            className="st0"
            d="M482.29,403.81c1.2-0.19,2.39-0.38,3.57-0.57l0,0C484.68,403.43,483.49,403.62,482.29,403.81z"
          />
          <path
            className="st0"
            d="M492.22,347.42C492.22,347.42,492.22,347.42,492.22,347.42c-0.81-4.35-1.62-8.69-2.43-13.04
		C490.6,338.73,491.41,343.08,492.22,347.42z"
          />
          <path
            className="st0"
            d="M499.38,372.57c0.08,0.69,0.16,1.39,0.25,2.08c0.04,0.33,0.08,0.66,0.12,0.98
		C499.62,374.62,499.5,373.6,499.38,372.57z"
          />
          <path
            className="st0"
            d="M417.87,514.82c-0.09-0.77-0.1-1.54-0.09-2.32C417.78,513.28,417.79,514.06,417.87,514.82z"
          />
          <polygon
            className="st0"
            points="500.15,379.07 500.15,379.07 500.15,379.07 500.15,379.07 500.03,379.07 500.03,379.07 500.15,379.07 
			"
          />
          <path
            className="st0"
            d="M419.37,479.87L419.37,479.87c4,7.19,8.27,14.24,12.12,21.5C427.64,494.12,423.37,487.06,419.37,479.87z"
          />
          <path
            className="st0"
            d="M487.24,302.48c0.54,0.35,1.04,0.76,1.48,1.23C488.28,303.24,487.79,302.83,487.24,302.48z"
          />
          <polygon
            className="st0"
            points="495.13,335.37 495.07,335.33 495.07,335.33 495.13,335.37 	"
          />
          <path
            className="st0"
            d="M498.78,366.88c0.2,1.9,0.4,3.79,0.6,5.69c0.08,0.69,0.16,1.39,0.25,2.08c-0.08-0.69-0.16-1.39-0.25-2.08
		C499.18,370.68,498.98,368.78,498.78,366.88z"
          />
          <path
            className="st0"
            d="M497.65,360.61c0.37,2.09,0.78,4.18,1.14,6.27c0,0,0,0,0,0C498.43,364.79,498.02,362.7,497.65,360.61z"
          />
          <path
            className="st0"
            d="M356.7,757.36c0.66-0.32,1.37-0.55,2.12-0.68C358.08,756.81,357.37,757.04,356.7,757.36z"
          />
          <path
            className="st0"
            d="M191.31,664.25c1.7,0.59,3.64,0.58,5.52,0.71c1.28,0.27,2.55,0.54,3.87,0.82c-1.32-0.28-2.59-0.55-3.87-0.82
		C194.94,664.82,193.01,664.83,191.31,664.25z"
          />
          <path
            className="st0"
            d="M283.86,533.86c0.22,2.12,0.43,4.23,0.65,6.35l0,0C284.3,538.09,284.08,535.98,283.86,533.86z"
          />
          <path
            className="st0"
            d="M286.61,538.72L286.61,538.72c-0.25,0.44-0.5,0.88-0.76,1.32C286.1,539.6,286.36,539.16,286.61,538.72z"
          />
          <path
            className="st0"
            d="M286.61,538.19c0.01-0.36,0.02-0.72,0.05-1.09C286.63,537.46,286.61,537.83,286.61,538.19z"
          />
          <path
            className="st0"
            d="M285.48,543.01c0.1-0.98,0.24-1.97,0.33-2.95h0C285.72,541.04,285.58,542.03,285.48,543.01z"
          />
          <path
            className="st0"
            d="M263,715c-0.57-0.14-1.19-0.2-1.74-0.37C261.82,714.8,262.43,714.86,263,715C263,715,263,715,263,715z"
          />
          <path
            className="st0"
            d="M263.43,717.4c-0.15-0.85-0.29-1.63-0.43-2.4c0,0,0,0,0,0C263.14,715.77,263.28,716.55,263.43,717.4z"
          />
          <path
            className="st0"
            d="M256.5,702.54c-0.26-0.28-0.52-0.57-0.78-0.85h0C255.98,701.97,256.24,702.25,256.5,702.54z"
          />
          <path
            className="st0"
            d="M191.31,664.25c-0.34-0.12-0.67-0.26-0.99-0.43C190.64,663.99,190.97,664.13,191.31,664.25z"
          />
          <path
            className="st0"
            d="M260.62,673.99c-0.48-0.37-0.97-0.73-1.46-1.09C259.65,673.26,260.14,673.62,260.62,673.99z"
          />
          <path
            className="st0"
            d="M272.43,660.63c0.73,1.13,1.45,2.27,2.18,3.4C273.88,662.9,273.16,661.76,272.43,660.63
		c1.67,0.68,3.34,1.35,5.01,2.03c11.09,6.94,22.19,13.89,33.36,20.88c-11.17-6.99-22.26-13.94-33.36-20.88
		C275.77,661.98,274.1,661.3,272.43,660.63z"
          />
          <path
            className="st0"
            d="M261.1,711.89c0.66,1.04,1.31,2.08,1.97,3.12c0,0,0,0,0,0l0,0C262.41,713.97,261.75,712.93,261.1,711.89z"
          />
          <path
            className="st0"
            d="M310.75,649.65c-1.75-1.92-4.66-3.04-7.52-4.22c-2.44-1.21-4.89-2.41-7.22-3.57c2.34,1.15,4.78,2.36,7.22,3.57
		C306.09,646.61,309,647.73,310.75,649.65z"
          />
          <path
            className="st0"
            d="M359.8,479.35c1.74-0.98,3.48-1.97,5.31-3C363.27,477.38,361.54,478.37,359.8,479.35L359.8,479.35z"
          />
          <path
            className="st0"
            d="M288.18,450.13c0.76-0.56,1.29-1.28,1.32-2.49C289.47,448.85,288.95,449.57,288.18,450.13z"
          />
          <path
            className="st0"
            d="M266.27,678.22c-0.85-0.58-1.68-1.19-2.5-1.81C264.59,677.04,265.42,677.64,266.27,678.22z"
          />
          <path
            className="st0"
            d="M287.35,450.65c-0.3,0.16-0.61,0.32-0.94,0.49C286.74,450.98,287.05,450.82,287.35,450.65z"
          />
          <path
            className="st0"
            d="M278.58,547.88c0.56,0.79,1.12,1.58,1.67,2.37c0.25,0.71,0.49,1.42,0.74,2.13c-0.25-0.71-0.49-1.42-0.74-2.13
		C279.7,549.46,279.14,548.68,278.58,547.88z"
          />
          <path
            className="st0"
            d="M322.15,743.07c-0.33-0.07-0.65-0.13-0.97-0.17c0,0,0,0,0,0C321.5,742.94,321.83,743,322.15,743.07z"
          />
          <path
            className="st0"
            d="M373.62,765.8c-1.03,1.68-2.06,3.37-3.09,5.05c-2.62,1.01-5.24,2.02-7.86,3.04c2.62-1.01,5.24-2.03,7.86-3.04
		C371.56,769.17,372.59,767.49,373.62,765.8z"
          />
          <path
            className="st0"
            d="M367.56,776.87c-1.04,0.36-2.14,0.51-3.28,0.52C365.42,777.38,366.52,777.23,367.56,776.87z"
          />
          <path
            className="st0"
            d="M352.5,604.07c-2.54,1.67-5.09,3.33-7.65,4.96C347.41,607.4,349.96,605.74,352.5,604.07z"
          />
          <path
            className="st0"
            d="M308.96,762.26L308.96,762.26c0.03,0,0.06,0.01,0.09,0.01C309.02,762.27,308.99,762.27,308.96,762.26z"
          />
          <path
            className="st0"
            d="M355.45,758.14c0.4-0.3,0.82-0.57,1.26-0.78C356.26,757.58,355.84,757.84,355.45,758.14z"
          />
          <path
            className="st0"
            d="M334.74,759.94c-0.3,0.26-0.62,0.51-0.95,0.75C334.12,760.45,334.44,760.2,334.74,759.94z"
          />
          <path
            className="st0"
            d="M370.55,780.99c1.41-0.83,2.83-1.66,4.24-2.49C373.37,779.33,371.96,780.16,370.55,780.99
		c-3.34,0.24-6.67,0.49-10.01,0.73h0C363.87,781.47,367.21,781.23,370.55,780.99z"
          />
          <path
            className="st0"
            d="M354.33,759.19L354.33,759.19c0.17-0.2,0.35-0.38,0.54-0.56C354.69,758.81,354.51,758.99,354.33,759.19z"
          />
          <path
            className="st0"
            d="M308.96,762.28L308.96,762.28c-0.49-0.23-0.97-0.46-1.46-0.69c-2.44-0.95-4.88-1.9-7.33-2.85
		c2.44,0.95,4.88,1.9,7.33,2.85C307.98,761.82,308.47,762.05,308.96,762.28z"
          />
          <path
            className="st0"
            d="M322.38,743.12c0.32,0.07,0.65,0.14,0.97,0.2C323.03,743.26,322.71,743.19,322.38,743.12z"
          />
          <path
            className="st0"
            d="M350.89,786.34c3.92,2.26,7.84,4.53,11.76,6.79c2.16,1.15,4.29,2.38,6.43,3.59c-2.14-1.21-4.27-2.43-6.43-3.59
		C358.73,790.87,354.81,788.6,350.89,786.34z"
          />
          <path
            className="st0"
            d="M264.44,716.61c-0.21-0.24-0.4-0.46-0.57-0.66C264.05,716.15,264.23,716.37,264.44,716.61z"
          />
          <path
            className="st0"
            d="M316.51,740.53c1.56,0.79,3.1,1.57,4.63,2.34C319.61,742.1,318.07,741.32,316.51,740.53z"
          />
          <path
            className="st0"
            d="M329.59,763.09c-0.47,0.2-0.95,0.38-1.42,0.54C328.65,763.48,329.12,763.29,329.59,763.09z"
          />
          <path
            className="st0"
            d="M263.06,715.01c0.01,0.01,0.01,0.01,0.02,0.02C263.07,715.02,263.07,715.02,263.06,715.01L263.06,715.01z"
          />
          <path
            className="st0"
            d="M323.04,764.73c-0.69,0.08-1.39,0.14-2.09,0.19C321.65,764.87,322.35,764.81,323.04,764.73z"
          />
          <path
            className="st0"
            d="M314.61,765.08c1.77-0.02,3.53-0.02,5.29-0.1C318.15,765.05,316.38,765.05,314.61,765.08L314.61,765.08z"
          />
          <path
            className="st0"
            d="M949.05,792.02c-0.32-2.26-0.64-4.53-0.95-6.79c-0.07-0.4-0.14-0.79-0.21-1.19c0.07,0.4,0.14,0.79,0.21,1.19
		C948.42,787.49,948.73,789.76,949.05,792.02z"
          />
          <path
            className="st0"
            d="M830.49,882.67C830.49,882.67,830.49,882.67,830.49,882.67c0.73-0.18,1.5-0.3,2.25-0.45
		C831.99,882.37,831.23,882.49,830.49,882.67z"
          />
          <path
            className="st0"
            d="M931.46,713.97c-0.66-1.19-0.88-2.58,0.14-4.16c0.45,0.05,0.89,0.13,1.31,0.21c-0.42-0.09-0.86-0.16-1.31-0.21
		c-0.84-0.12-1.66-0.18-2.47-0.18c0.8,0.01,1.62,0.06,2.47,0.18C930.58,711.39,930.79,712.78,931.46,713.97z"
          />
          <path
            className="st0"
            d="M866.22,867.75c1.99-2.17,3.95-4.38,5.97-6.52c1.11-1.22,2.2-2.46,3.32-3.66c-1.12,1.2-2.22,2.44-3.32,3.66
		C870.17,863.36,868.21,865.58,866.22,867.75z"
          />
          <path
            className="st0"
            d="M940.82,728.05c-0.2-0.08-0.39-0.15-0.58-0.23v0C940.43,727.9,940.62,727.97,940.82,728.05z"
          />
          <path
            className="st0"
            d="M941.07,728.15c0.22,0.09,0.45,0.18,0.7,0.28C941.52,728.33,941.29,728.24,941.07,728.15z"
          />
          <path
            className="st0"
            d="M938.9,723.98c0.38,0.84,0.71,1.71,0.99,2.61C939.61,725.69,939.28,724.82,938.9,723.98z"
          />
          <path
            className="st0"
            d="M937.72,667.07c0.78,0.42,1.52,0.93,2.27,1.45C939.24,668,938.49,667.49,937.72,667.07z"
          />
          <path
            className="st0"
            d="M930.72,722.31l0.02,0.01C930.73,722.31,930.72,722.31,930.72,722.31
		C930.72,722.31,930.72,722.31,930.72,722.31z"
          />
          <path
            className="st0"
            d="M936.71,728.01L936.71,728.01c-0.45,0.2-0.9,0.41-1.4,0.63C935.81,728.42,936.26,728.21,936.71,728.01z"
          />
          <path
            className="st0"
            d="M947.09,722.8c0.87,1.12,1.75,2.51,2.57,4.11C948.84,725.31,947.96,723.92,947.09,722.8z"
          />
          <path
            className="st0"
            d="M766.75,747.23c-1.37,0.77-2.58,1.67-3.66,2.66C764.17,748.9,765.38,748,766.75,747.23L766.75,747.23z"
          />
          <path
            className="st0"
            d="M770.46,744.66c0.83-0.68,1.7-1.27,2.61-1.77C772.16,743.39,771.29,743.98,770.46,744.66z"
          />
          <path
            className="st0"
            d="M767.59,746.7c0,0,0.02-0.01,0.03-0.02L767.59,746.7z"
          />
          <path
            className="st0"
            d="M788.89,739.87c0.37-0.16,0.71-0.36,1.03-0.57C789.59,739.52,789.25,739.71,788.89,739.87z"
          />
          <path
            className="st0"
            d="M993.44,733.47c0.32,0.14,0.63,0.31,0.94,0.48C994.07,733.78,993.76,733.61,993.44,733.47z"
          />
          <path
            className="st0"
            d="M966.89,721.76c0.43,0.55,0.87,1.09,1.32,1.63C967.76,722.85,967.32,722.31,966.89,721.76z"
          />
          <path
            className="st0"
            d="M756.29,808.76c-0.66,2.8-1.29,5.61-1.84,8.44C754.99,814.37,755.63,811.57,756.29,808.76z"
          />
          <path
            className="st0"
            d="M796.21,872.73c0,0.2-0.02,0.4-0.05,0.6C796.19,873.13,796.21,872.93,796.21,872.73z"
          />
          <path
            className="st0"
            d="M962.21,769.51c0.02,0.05,0.04,0.11,0.06,0.16C962.25,769.62,962.23,769.56,962.21,769.51L962.21,769.51z"
          />
          <path
            className="st0"
            d="M978.95,769.42c-1.42-2.03-2.85-4.06-4.27-6.09C976.1,765.36,977.52,767.39,978.95,769.42
		c0.23,0.86,0.45,1.71,0.67,2.56C979.4,771.13,979.17,770.27,978.95,769.42C978.95,769.42,978.95,769.42,978.95,769.42z"
          />
          <path
            className="st0"
            d="M791.43,737.95c0.14-0.16,0.28-0.32,0.42-0.48C791.71,737.64,791.57,737.8,791.43,737.95z"
          />
          <path
            className="st0"
            d="M976.03,773.77c-1.67-3.16-3.34-6.32-5.01-9.48c-2.29-2.73-4.57-5.45-6.86-8.18c2.29,2.73,4.57,5.45,6.86,8.18
		C972.7,767.45,974.36,770.61,976.03,773.77z"
          />
          <path
            className="st0"
            d="M980.77,758.89c0.12,2.3,0.24,4.61,0.36,6.91C981.02,763.5,980.89,761.19,980.77,758.89
		c0.04-1.37,0.16-2.75,0.23-4.12C980.94,756.14,980.81,757.52,980.77,758.89z"
          />
          <path
            className="st0"
            d="M872.46,736.99c0.34,0.06,0.68,0.13,1.01,0.2C873.14,737.11,872.8,737.05,872.46,736.99z"
          />
          <path
            className="st0"
            d="M980.59,775.67c-0.21-0.79-0.42-1.6-0.64-2.44C980.17,774.07,980.39,774.88,980.59,775.67z"
          />
          <path
            className="st0"
            d="M865.79,831.7c5.19-3.08,10.38-6.17,15.57-9.25C876.17,825.54,870.98,828.62,865.79,831.7L865.79,831.7z"
          />
          <path
            className="st0"
            d="M958.14,771.44c-2.37,1.27-4.74,2.55-7.11,3.82c-1.87-5.12-4.95-9.36-8.65-13.24
		c3.7,3.88,6.78,8.12,8.65,13.24c0.48,2.59,0.95,5.18,1.43,7.77c-0.48-2.59-0.95-5.18-1.43-7.77
		C953.4,773.98,955.77,772.71,958.14,771.44z"
          />
          <path
            className="st0"
            d="M961.58,780.18c-1.15-2.91-2.3-5.83-3.44-8.74c-1.23-1.47-2.46-2.93-3.75-4.48c1.29,1.55,2.52,3.01,3.75,4.48
		C959.29,774.35,960.43,777.27,961.58,780.18z"
          />
          <path
            className="st0"
            d="M720.28,563.25c0,2.07,0,4.18,0,6.28c0,0,0,0,0,0C720.28,567.43,720.28,565.32,720.28,563.25z"
          />
          <path
            className="st0"
            d="M859.45,779.18c0.21,0.57,0.43,1.15,0.56,1.69c-0.02,0.51-0.04,1.02-0.06,1.53l0,0
		c0.02-0.51,0.04-1.02,0.06-1.53c0.66-0.6,1.4-1.14,2.02-1.77c-0.62,0.63-1.36,1.17-2.02,1.77
		C859.89,780.33,859.66,779.75,859.45,779.18z"
          />
          <path
            className="st0"
            d="M919.83,712c0.71-0.36,1.44-0.69,2.17-0.98C921.27,711.31,920.55,711.64,919.83,712z"
          />
          <path
            className="st0"
            d="M922.01,711.02c2.21-0.87,4.52-1.4,7.02-1.4C926.53,709.62,924.21,710.15,922.01,711.02z"
          />
          <path
            className="st0"
            d="M852.54,813.22c-0.8-0.32-1.62-0.61-2.45-0.88C850.92,812.61,851.74,812.9,852.54,813.22z"
          />
          <path
            className="st0"
            d="M942.14,716.13c0.22,0.27,0.44,0.54,0.65,0.82C942.58,716.67,942.36,716.4,942.14,716.13z"
          />
          <path
            className="st0"
            d="M214.13,692.47c-0.89,0.82-1.77,1.67-2.66,2.49c-0.15,0.3-0.3,0.61-0.44,0.91c0.15-0.3,0.3-0.61,0.44-0.91
		C212.36,694.14,213.24,693.29,214.13,692.47z"
          />
          <path
            className="st0"
            d="M881.37,822.45c-2.13-3.1-4.25-6.19-6.38-9.29c-0.53-1.03-1.06-2.05-1.59-3.08c0.53,1.03,1.06,2.05,1.59,3.08
		C877.11,816.26,879.24,819.36,881.37,822.45z"
          />
          <path
            className="st0"
            d="M862.78,821.07L862.78,821.07c0.26,0.93,0.53,1.87,0.79,2.8C863.31,822.94,863.04,822.01,862.78,821.07
		L862.78,821.07z"
          />
          <path
            className="st0"
            d="M918.27,801.87c0.87,1.18,1.56,2.51,1.94,4.08c0.22,4.03,0.45,8.05,0.67,12.06
		c-0.22-4.01-0.45-8.04-0.67-12.06C919.83,804.38,919.14,803.05,918.27,801.87z"
          />
          <path
            className="st0"
            d="M942.22,670.04c2.13,2.3,4.89,4.3,6.52,6.84C947.12,674.34,944.36,672.34,942.22,670.04
		c-0.76-0.47-1.5-1-2.24-1.52C940.73,669.04,941.46,669.57,942.22,670.04L942.22,670.04z"
          />
          <path
            className="st0"
            d="M933.2,558.31c-0.62,0.05-1.23,0.01-1.83-0.15C931.97,558.31,932.58,558.36,933.2,558.31z"
          />
          <path
            className="st0"
            d="M991.52,734.61c-0.8-0.84-1.68-1.64-2.62-2.4c0.48,0.09,0.95,0.18,1.42,0.29c-0.47-0.1-0.94-0.2-1.42-0.29
		c-1.91-0.65-3.82-1.3-5.73-1.96c1.91,0.65,3.82,1.3,5.73,1.96C989.84,732.97,990.72,733.77,991.52,734.61z"
          />
          <path
            className="st0"
            d="M929.62,557.34c-0.28-0.2-0.56-0.43-0.83-0.69c-0.18-0.56-0.3-1.11-0.36-1.66c0.06,0.55,0.19,1.1,0.36,1.66
		C929.06,556.91,929.34,557.14,929.62,557.34z"
          />
          <path
            className="st0"
            d="M1002.22,742.25c-0.42,0.3-0.82,0.59-1.23,0.88c0,0,0,0,0,0C1001.4,742.84,1001.8,742.55,1002.22,742.25z"
          />
          <path
            className="st0"
            d="M993.68,737.24c-0.43-0.61-0.89-1.21-1.39-1.78C992.8,736.04,993.26,736.63,993.68,737.24z"
          />
          <path
            className="st0"
            d="M935.33,676.2c0.42,0.29,0.84,0.57,1.26,0.86h0C936.17,676.77,935.75,676.49,935.33,676.2z"
          />
          <path
            className="st0"
            d="M928.43,554.99c-0.03-0.27-0.05-0.55-0.06-0.82C928.38,554.44,928.4,554.72,928.43,554.99z"
          />
          <path
            className="st0"
            d="M1000.68,743c-0.08-0.03-0.16-0.06-0.24-0.1c-0.37-0.6-0.83-1.18-1.24-1.77c0.41,0.59,0.87,1.17,1.24,1.77
		C1000.52,742.94,1000.6,742.97,1000.68,743z"
          />
          <polygon
            className="st0"
            points="937.43,677.67 937.43,677.67 937.43,677.67 	"
          />
          <path
            className="st0"
            d="M842.7,684.89L842.7,684.89c-0.02,0.73,0.02,1.42,0.13,2.08C842.71,686.32,842.68,685.62,842.7,684.89z"
          />
          <path
            className="st0"
            d="M743.83,569.01C743.83,569.01,743.83,569.01,743.83,569.01c-0.96,0.16-2.01,0.15-2.93,0.38
		C741.82,569.16,742.87,569.17,743.83,569.01z"
          />
          <path
            className="st0"
            d="M720.27,571.77c-0.03,0.53-0.05,1.06-0.08,1.63C720.22,572.83,720.25,572.3,720.27,571.77c0-0.3,0-0.6,0-0.9
		C720.28,571.17,720.28,571.47,720.27,571.77z"
          />
          <path
            className="st0"
            d="M767.93,557.38c5.35-0.82,10.69-1.63,16.04-2.45C778.62,555.75,773.28,556.57,767.93,557.38
		c-6.6,0.04-13.2,0.09-19.79,0.13v0C754.73,557.47,761.33,557.42,767.93,557.38z"
          />
          <path
            className="st0"
            d="M850.27,550.78c-0.87-0.14-1.73-0.33-2.57-0.56C848.55,550.45,849.4,550.64,850.27,550.78z"
          />
          <path
            className="st0"
            d="M725.63,470.04c0.13,0.68,0.26,1.36,0.39,2.03l0,0C725.9,471.39,725.77,470.72,725.63,470.04z"
          />
          <path
            className="st0"
            d="M693.58,477.15c0.3-0.45,0.6-0.9,0.9-1.35C694.18,476.25,693.88,476.7,693.58,477.15L693.58,477.15z"
          />
          <path
            className="st0"
            d="M732.32,465.06c-0.63-0.13-1.26-0.27-1.9-0.4C731.06,464.8,731.69,464.93,732.32,465.06L732.32,465.06z"
          />
          <path
            className="st0"
            d="M802.77,565.99c-0.22,0.69-0.48,1.34-0.78,1.95C802.29,567.33,802.55,566.68,802.77,565.99z"
          />
          <path
            className="st0"
            d="M900.01,677.01L900.01,677.01c-0.34,0.49-0.68,0.99-1.02,1.48c-1.5,2.93-3,5.86-4.48,8.74
		c1.47-2.88,2.98-5.81,4.48-8.74C899.33,678,899.67,677.5,900.01,677.01z"
          />
          <path
            className="st0"
            d="M763.09,749.89c-0.54,0.5-1.05,1.02-1.52,1.57C762.05,750.91,762.56,750.39,763.09,749.89z"
          />
          <path
            className="st0"
            d="M789.76,871.8c0.22,0.05,0.44,0.1,0.67,0.15l0,0C790.2,871.9,789.98,871.85,789.76,871.8z"
          />
          <path
            className="st0"
            d="M767.59,746.7c-0.28,0.18-0.56,0.36-0.84,0.54c0,0,0,0,0,0C767.03,747.05,767.31,746.88,767.59,746.7z"
          />
          <path
            className="st0"
            d="M782.56,868.56c1.4,0.85,2.81,1.71,4.21,2.56c0.28,1.21,0.56,2.42,0.84,3.64c-0.28-1.21-0.56-2.42-0.84-3.64
		c0,0,0,0,0,0C785.37,870.27,783.97,869.42,782.56,868.56z"
          />
          <path
            className="st0"
            d="M810.63,705.72C810.63,705.72,810.63,705.72,810.63,705.72c-1.82,4.48-3.63,8.97-5.45,13.45
		C807,714.69,808.81,710.2,810.63,705.72z"
          />
          <path
            className="st0"
            d="M798.93,719.14c-1.91,5.53-3.8,11.07-5.73,16.6l0,0C795.13,730.21,797.01,724.67,798.93,719.14z"
          />
          <path
            className="st0"
            d="M770.46,744.66c-0.28,0.23-0.55,0.46-0.81,0.71c-0.22,0.12-0.43,0.24-0.65,0.36c0.22-0.12,0.43-0.24,0.65-0.36
		C769.91,745.12,770.18,744.88,770.46,744.66z"
          />
          <path
            className="st0"
            d="M753.42,845.24c0.34,0.35,0.68,0.71,1.02,1.06c1.96,1.38,3.92,2.76,5.88,4.14c-1.96-1.38-3.92-2.76-5.88-4.14
		C754.1,845.95,753.76,845.59,753.42,845.24z"
          />
          <path
            className="st0"
            d="M886.46,694.21c0.33,0.34,0.66,0.69,1,1.03l0.12,0.16c0,0-0.12-0.16-0.12-0.16
		C887.12,694.9,886.79,694.56,886.46,694.21z"
          />
          <path
            className="st0"
            d="M902.7,674.18c1.58-1.5,3.25-2.85,5.01-4C905.94,671.32,904.27,672.67,902.7,674.18z"
          />
          <path
            className="st0"
            d="M909.85,668.88c1.46-0.8,2.96-1.49,4.49-2.06C912.81,667.39,911.31,668.08,909.85,668.88z"
          />
          <path
            className="st0"
            d="M914.35,666.82c1.38-0.51,2.8-0.92,4.23-1.25C917.15,665.9,915.73,666.31,914.35,666.82z"
          />
          <path
            className="st0"
            d="M793.19,865.71c0.54,0.42,1.04,0.82,1.55,1.22c0.02,0.46,0.03,0.92,0.05,1.38c-0.02-0.46-0.03-0.92-0.05-1.38
		l0,0C794.23,866.53,793.73,866.13,793.19,865.71z"
          />
          <path
            className="st0"
            d="M758.27,800.35c-0.64,2.81-1.33,5.61-1.98,8.41C756.94,805.96,757.63,803.16,758.27,800.35
		c0.35-5.14,0.7-10.29,1.06-15.48C758.97,790.06,758.62,795.21,758.27,800.35z"
          />
          <path
            className="st0"
            d="M797.62,866.83c0.61,0.66,1.22,1.32,1.9,2.06C798.84,868.15,798.23,867.49,797.62,866.83
		C797.62,866.83,797.62,866.83,797.62,866.83z"
          />
          <path
            className="st0"
            d="M794.74,861.37c-0.25-0.3-0.5-0.61-0.75-0.91c-0.47-1.17-0.88-2.37-1.33-3.54c0.45,1.18,0.86,2.38,1.33,3.55
		C794.24,860.76,794.49,861.06,794.74,861.37z"
          />
          <path
            className="st0"
            d="M915.27,816.21c0.21-2.21,0.27-4.44,0.45-6.66c0.03-0.29,0.05-0.56,0.06-0.84c-0.01,0.27-0.02,0.55-0.06,0.84
		C915.54,811.76,915.47,814,915.27,816.21z"
          />
          <path
            className="st0"
            d="M216.09,807.76c1.21-3.31,2.43-6.66,3.66-10.02C218.52,801.1,217.3,804.45,216.09,807.76z"
          />
          <path
            className="st0"
            d="M221.49,792.53c0.01-0.16,0.02-0.31,0.03-0.44C221.51,792.22,221.5,792.37,221.49,792.53z"
          />
          <path
            className="st0"
            d="M20.96,912.12c-0.68,0.48-1.42,0.91-2.12,1.38C19.55,913.03,20.28,912.6,20.96,912.12
		C20.96,912.12,20.96,912.12,20.96,912.12z"
          />
          <path
            className="st0"
            d="M221.47,792.8c0.01-0.09,0.01-0.18,0.02-0.27C221.49,792.62,221.48,792.71,221.47,792.8z"
          />
          <path
            className="st0"
            d="M859.45,779.18c-0.14-0.38-0.27-0.76-0.35-1.12C859.19,778.42,859.32,778.8,859.45,779.18z"
          />
          <path
            className="st0"
            d="M17.26,805.41c0.02,0.22,0.03,0.45,0.05,0.67C17.3,805.86,17.28,805.64,17.26,805.41
		C17.26,805.41,17.26,805.41,17.26,805.41z"
          />
          <path
            className="st0"
            d="M83.48,693.43c-0.11-0.21-0.21-0.42-0.32-0.62c-0.11-1.55-0.26-3.1-0.41-4.65c0.15,1.55,0.29,3.1,0.41,4.65
		C83.27,693.01,83.38,693.22,83.48,693.43z"
          />
          <path
            className="st0"
            d="M51.17,765.63c-1.64-0.91-2.91-2.62-4.47-5.47c-0.06-0.46-0.1-0.93-0.15-1.39c0.05,0.46,0.1,0.93,0.15,1.39
		C48.25,763.01,49.52,764.72,51.17,765.63z"
          />
          <path
            className="st0"
            d="M16.05,684.9c0.91-1.4,1.81-2.8,2.72-4.2c0.06-2.28,0.12-4.57,0.2-6.85c-0.07,2.28-0.14,4.57-0.2,6.85
		C17.86,682.09,16.96,683.5,16.05,684.9c-0.39,0.57-0.79,1.13-1.31,1.89C15.26,686.03,15.66,685.46,16.05,684.9
		C16.05,684.9,16.05,684.9,16.05,684.9z"
          />
          <path
            className="st0"
            d="M676.72,556.08c-1.55-0.85-3.09-1.71-4.63-2.61C673.63,554.36,675.18,555.23,676.72,556.08z"
          />
          <path
            className="st0"
            d="M651.55,440.2c0.1-0.25,0.19-0.52,0.29-0.77C651.75,439.68,651.65,439.94,651.55,440.2z"
          />
          <path
            className="st0"
            d="M679.17,432.41c0.38,1.13,0.84,2.22,1.39,3.28C680.02,434.64,679.55,433.55,679.17,432.41z"
          />
          <path
            className="st0"
            d="M678.52,430.09c0.18,0.79,0.39,1.57,0.65,2.32C678.92,431.66,678.7,430.89,678.52,430.09z"
          />
          <path
            className="st0"
            d="M685.2,441.09c0.57,0.5,1.02,1.07,1.37,1.7C686.22,442.16,685.77,441.59,685.2,441.09z"
          />
          <path
            className="st0"
            d="M685.99,561.01c-1.54-0.81-3.08-1.62-4.63-2.44C682.91,559.39,684.45,560.2,685.99,561.01z"
          />
          <path
            className="st0"
            d="M226.47,789.35c2.43-1.21,4.8-2.39,7.16-3.56C231.27,786.96,228.9,788.14,226.47,789.35z"
          />
          <path
            className="st0"
            d="M616.67,481.58c0.45-0.81,0.84-1.69,1.2-2.58C617.51,479.89,617.12,480.76,616.67,481.58z"
          />
          <path
            className="st0"
            d="M77.56,750.32c0,0.26,0,0.51-0.01,0.77C77.56,750.83,77.57,750.57,77.56,750.32z"
          />
          <path
            className="st0"
            d="M20.39,582.43c0.17,0.28,0.35,0.55,0.53,0.82c-1.21,1.01-2.42,2.02-3.62,3.02c1.2-1,2.41-2.01,3.62-3.02
		C20.74,582.98,20.56,582.71,20.39,582.43z"
          />
          <path
            className="st0"
            d="M722.05,486.22c0.34-1.02,0.69-2.05,1.03-3.07C722.74,484.18,722.39,485.2,722.05,486.22z"
          />
          <path
            className="st0"
            d="M17.85,721.81c0.09-2.7,0.15-5.4,0.19-8.09C18,716.41,17.94,719.11,17.85,721.81z"
          />
          <path
            className="st0"
            d="M14.33,564.54L14.33,564.54c0.15,0.36,0.31,0.71,0.47,1.09C14.64,565.25,14.48,564.9,14.33,564.54z"
          />
          <path
            className="st0"
            d="M18.66,588.29c-0.43-0.63-0.85-1.27-1.36-2.02C17.81,587.02,18.23,587.65,18.66,588.29
		c-0.31,0.42-0.71,0.8-0.99,1.23C17.95,589.09,18.35,588.7,18.66,588.29C18.66,588.29,18.66,588.29,18.66,588.29z"
          />
          <path
            className="st0"
            d="M70.95,735.1c0.26,0.51,0.53,1.03,0.79,1.54c-0.05,0.12-0.06,0.23-0.04,0.34c-0.02-0.11,0-0.22,0.04-0.34
		C71.48,736.13,71.21,735.62,70.95,735.1z"
          />
          <path
            className="st0"
            d="M82.31,683.51c0.14,1.55,0.29,3.1,0.44,4.65C82.61,686.61,82.45,685.06,82.31,683.51z"
          />
          <path
            className="st0"
            d="M18.6,920.94c0,0-0.01,0-0.01,0C18.59,920.94,18.6,920.94,18.6,920.94z"
          />
          <path
            className="st0"
            d="M1183.7,409.87c-0.02,0.19-0.03,0.38-0.05,0.57C1183.67,410.25,1183.69,410.06,1183.7,409.87z"
          />
          <path
            className="st0"
            d="M92.6,755.16C92.6,755.16,92.6,755.16,92.6,755.16c-2.2-0.24-4.29-0.71-6.26-1.43
		C88.31,754.45,90.4,754.92,92.6,755.16z"
          />
          <path
            className="st0"
            d="M106.18,742.35c0.48-0.23,0.95-0.48,1.41-0.8l0,0C107.13,741.87,106.66,742.12,106.18,742.35z"
          />
          <path
            className="st0"
            d="M103.9,743.1c-0.41,0.08-0.83,0.14-1.25,0.16C103.07,743.24,103.48,743.18,103.9,743.1z"
          />
          <path
            className="st0"
            d="M74.14,733.18c-1.03-0.13-2-0.26-2.96-0.38c-0.09,0.8-0.17,1.6-0.26,2.4v0c0.09-0.8,0.17-1.6,0.26-2.4
		C72.15,732.93,73.11,733.05,74.14,733.18z"
          />
          <path
            className="st0"
            d="M161.26,703.58C161.26,703.58,161.26,703.58,161.26,703.58c0.04,2.74-0.76,4.89-2.29,6.97
		C160.5,708.47,161.3,706.32,161.26,703.58z"
          />
          <path
            className="st0"
            d="M83.48,693.43c0.21,0.42,0.44,0.87,0.7,1.38C83.92,694.29,83.7,693.85,83.48,693.43z"
          />
          <path
            className="st0"
            d="M51.17,765.63c0.41,0.23,0.84,0.41,1.31,0.54C52.01,766.04,51.58,765.86,51.17,765.63z"
          />
          <path
            className="st0"
            d="M77.17,753.99c0.13-0.46,0.22-0.93,0.28-1.42C77.39,753.06,77.3,753.54,77.17,753.99z"
          />
          <path
            className="st0"
            d="M203.13,862.73c0.32-0.36,0.62-0.76,0.9-1.17C203.75,861.97,203.45,862.37,203.13,862.73z"
          />
          <path
            className="st0"
            d="M815.17,799.56c-0.77-0.26-1.62-0.5-2.54-0.72c0,0,0,0,0,0c0,0,0,0,0,0
		C813.56,799.06,814.4,799.29,815.17,799.56z"
          />
          <path
            className="st0"
            d="M811.01,812.31c-0.04,0.48-0.13,0.95-0.25,1.4C810.88,813.26,810.97,812.8,811.01,812.31z"
          />
          <path
            className="st0"
            d="M811.04,811.14c-0.05-0.99-0.29-2.04-0.76-3.16c0,0,0,0,0,0C810.75,809.11,810.99,810.16,811.04,811.14z"
          />
          <path
            className="st0"
            d="M810.01,795.25c0.11,0.13,0.21,0.29,0.31,0.46C810.22,795.54,810.12,795.38,810.01,795.25z"
          />
          <path
            className="st0"
            d="M811.22,797.42c-0.04-0.07-0.08-0.14-0.12-0.21C811.14,797.28,811.18,797.36,811.22,797.42L811.22,797.42z"
          />
          <path
            className="st0"
            d="M786.44,839.93c-0.09-0.03-0.17-0.07-0.26-0.11C786.27,839.85,786.35,839.89,786.44,839.93z"
          />
          <path
            className="st0"
            d="M810.5,796.04c0.17,0.33,0.33,0.69,0.51,1.01C810.83,796.73,810.67,796.37,810.5,796.04z"
          />
          <path
            className="st0"
            d="M815.17,799.56c1.39,0.47,2.54,1.04,3.5,1.75C817.71,800.6,816.56,800.03,815.17,799.56z"
          />
          <path
            className="st0"
            d="M789.76,840.66c-0.13-0.01-0.26-0.03-0.39-0.05C789.5,840.63,789.63,840.64,789.76,840.66z"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch3}
              d="M783.28,838.27L783.28,838.27c0.41,0.28,0.82,0.53,1.23,0.76C784.1,838.8,783.69,838.55,783.28,838.27z"
            />
          </a>

          <path
            className="st0"
            d="M917.05,728.92c0.4,0.83,0.77,1.66,1.1,2.49C917.82,730.58,917.46,729.75,917.05,728.92z"
          />
          <path
            className="st0"
            d="M835.07,789.54c8.3-2.37,16.59-4.76,24.89-7.14v0C851.66,784.78,843.37,787.16,835.07,789.54z"
          />
          <path
            className="st0"
            d="M859.95,782.41c0.47-0.06,0.94-0.09,1.41-0.14C860.89,782.31,860.42,782.35,859.95,782.41L859.95,782.41z"
          />
          <path
            className="st0"
            d="M872.13,771.92c0,0.01,0,0.02,0,0.02C872.13,771.94,872.13,771.93,872.13,771.92z"
          />
          <path
            className="st0"
            d="M889.02,706.74c0.82,0.16,1.63,0.37,2.43,0.61C890.65,707.11,889.84,706.9,889.02,706.74z"
          />
          <path
            className="st0"
            d="M839.72,706.07c-1.93-3.82-4.08-5.77-6.93-5.85C835.64,700.3,837.79,702.25,839.72,706.07z"
          />
          <path
            className="st0"
            d="M896.6,709.56c0.35,0.19,0.69,0.39,1.04,0.6C897.3,709.95,896.95,709.75,896.6,709.56z"
          />
          <path
            className="st0"
            d="M911.4,720.52c0.43,0.47,0.83,0.95,1.23,1.44C912.23,721.47,911.83,720.99,911.4,720.52z"
          />
          <path
            className="st0"
            d="M824.96,851.38c-0.08-0.29-0.16-0.56-0.27-0.83C824.8,850.82,824.89,851.1,824.96,851.38z"
          />
          <path
            className="st0"
            d="M556.81,802.68c0.2-1.55,0.45-3.08,0.72-4.62C557.26,799.6,557.01,801.14,556.81,802.68z"
          />
          <path
            className="st0"
            d="M907,846.7c4.07-1.43,8.16-2.82,12.24-4.23C915.16,843.88,911.08,845.27,907,846.7
		c-3.29,1.15-6.72,2.06-10.05,3.13C900.29,848.76,903.72,847.85,907,846.7L907,846.7z"
          />
          <path
            className="st0"
            d="M786.03,947.24c0.79,2.18,1.67,3.92,2.64,5.21C787.7,951.16,786.83,949.42,786.03,947.24L786.03,947.24z"
          />
          <path
            className="st0"
            d="M791.84,954.96c-0.48-0.16-0.94-0.42-1.39-0.74C790.9,954.54,791.36,954.8,791.84,954.96z"
          />
          <path
            className="st0"
            d="M557.54,798.06c0.36-2.05,0.77-4.09,1.17-6.15C558.31,793.97,557.9,796.01,557.54,798.06z"
          />
          <path
            className="st0"
            d="M929.62,557.34c0.56,0.4,1.15,0.66,1.75,0.82C930.77,558.01,930.19,557.74,929.62,557.34z"
          />
          <path
            className="st0"
            d="M995.31,508.57c-0.21,0.12-0.41,0.23-0.61,0.35C994.9,508.8,995.1,508.68,995.31,508.57L995.31,508.57z"
          />
          <path
            className="st0"
            d="M985.28,546.6c0.08,0.1,0.16,0.2,0.23,0.3c0,0,0,0,0,0C985.44,546.8,985.36,546.7,985.28,546.6z"
          />
          <path
            className="st0"
            d="M850.17,818.32c-0.13-0.14-0.26-0.27-0.4-0.39C849.9,818.04,850.04,818.18,850.17,818.32z"
          />
          <path
            className="st0"
            d="M842.72,834.69L842.72,834.69c1.41,2.89,2.83,5.78,4.24,8.68h0C845.55,840.47,844.13,837.58,842.72,834.69z"
          />
          <path
            className="st0"
            d="M860.76,833.9c0.75,2.71-0.33,7.01-2.22,10.7C860.43,840.91,861.51,836.61,860.76,833.9
		c-0.79-3.06-1.58-6.13-2.36-9.19c-0.67-0.95-1.32-1.91-2.08-2.74c0.75,0.83,1.41,1.79,2.08,2.74
		C859.18,827.77,859.97,830.84,860.76,833.9z"
          />
          <path
            className="st0"
            d="M825.07,851.82c0.17,0.73,0.31,1.47,0.56,2.15v0C825.38,853.29,825.24,852.54,825.07,851.82z"
          />
          <path
            className="st0"
            d="M856.32,821.96c-0.38-0.41-0.78-0.8-1.21-1.13C855.54,821.17,855.94,821.55,856.32,821.96z"
          />
          <path
            className="st0"
            d="M726.47,463.82c1.37,0.29,2.67,0.57,3.95,0.84C729.15,464.39,727.84,464.12,726.47,463.82z"
          />
          <path
            className="st0"
            d="M795.03,945.74c-0.15-0.37-0.21-0.74-0.13-1.13c0.51-0.28,0.97-0.48,1.39-0.57c-0.42,0.1-0.89,0.29-1.39,0.57
		C794.82,945,794.88,945.38,795.03,945.74z"
          />
          <path
            className="st0"
            d="M853.67,820.02c0.52,0.21,1,0.49,1.43,0.82C854.67,820.51,854.2,820.23,853.67,820.02z"
          />
          <path
            className="st0"
            d="M858.54,844.6c-0.84,1.64-1.84,3.15-2.92,4.34C856.7,847.74,857.7,846.23,858.54,844.6z"
          />
          <path
            className="st0"
            d="M52.19,906.75c-0.48-0.38-0.93-0.8-1.32-1.25C51.27,905.96,51.71,906.37,52.19,906.75z"
          />
          <path
            className="st0"
            d="M782.14,629.92c-14.61,7.48-29.27,14.89-43.91,22.32c-2.93-1.75-5.85-3.5-8.78-5.26
		c2.93,1.75,5.85,3.5,8.78,5.26c8.75,4.34,17.5,8.68,26.18,12.98c-8.68-4.3-17.43-8.64-26.18-12.98
		C752.88,644.81,767.53,637.4,782.14,629.92z"
          />
          <path
            className="st0"
            d="M784.62,653.66c6.72-3.85,13.44-7.69,20.16-11.53c0.47-0.31,0.94-0.63,1.42-0.94
		c-0.48,0.32-0.95,0.63-1.42,0.94C798.06,645.97,791.34,649.82,784.62,653.66z"
          />
          <path
            className="st0"
            d="M780.38,621.57c-0.7-0.4-1.4-0.79-2.11-1.19c-0.39-0.23-0.79-0.45-1.19-0.66c0.4,0.22,0.8,0.44,1.19,0.66
		C778.97,620.78,779.68,621.18,780.38,621.57z"
          />
          <path
            className="st0"
            d="M774.67,618.43c0.81,0.43,1.61,0.85,2.41,1.29C776.28,619.28,775.47,618.86,774.67,618.43z"
          />
          <path
            className="st0"
            d="M722.32,636.87L722.32,636.87c0,0-0.01,0.01-0.01,0.01C722.32,636.88,722.32,636.87,722.32,636.87z"
          />
          <path
            className="st0"
            d="M1181.3,470.24c0-0.04,0-0.08,0-0.12c0.01-0.53,0.01-1.06,0.02-1.59c-0.01,0.53-0.01,1.06-0.02,1.59
		C1181.31,470.16,1181.3,470.2,1181.3,470.24z"
          />
          <path
            className="st0"
            d="M1181.87,445.01c-0.04,1.47-0.08,2.94-0.12,4.41C1181.79,447.95,1181.83,446.48,1181.87,445.01
		c0.01-0.65,0.01-1.29,0.02-1.94C1181.88,443.72,1181.88,444.36,1181.87,445.01z"
          />
          <path
            className="st0"
            d="M1184.6,380.06c-0.03-3.71-0.08-7.42-0.07-11.13c1.82,0.51,3.65,1.02,5.47,1.54
		c-1.82-0.51-3.65-1.02-5.47-1.54C1184.53,372.63,1184.57,376.35,1184.6,380.06z"
          />
          <path
            className="st0"
            d="M724.99,635.73c-0.9,0.39-1.79,0.77-2.69,1.16c0,0,0,0,0,0C723.2,636.51,724.1,636.12,724.99,635.73z"
          />
          <path
            className="st0"
            d="M1035.32,305.2c-0.48,0.01-0.95,0.02-1.43,0.04c0,0,0,0,0,0C1034.37,305.21,1034.84,305.21,1035.32,305.2z"
          />
          <path
            className="st0"
            d="M1083.7,352.19c0.58,1.2,1.15,2.41,1.71,3.62C1084.85,354.6,1084.28,353.39,1083.7,352.19z"
          />
          <path
            className="st0"
            d="M1075.54,348.66c-1.1-1.11-2.24-2.19-3.42-3.22C1073.3,346.48,1074.44,347.55,1075.54,348.66z"
          />
          <path
            className="st0"
            d="M1063.55,338.98c-0.56-0.36-1.12-0.72-1.69-1.07C1062.43,338.26,1062.99,338.62,1063.55,338.98z"
          />
          <path
            className="st0"
            d="M1086.07,334.74c-1.08-2-2.16-4-3.24-6c0,0,0,0,0,0C1083.91,330.74,1084.99,332.74,1086.07,334.74z"
          />
          <path
            className="st0"
            d="M1009.21,308.06c-0.09-0.08-0.17-0.15-0.26-0.23C1009.03,307.91,1009.12,307.99,1009.21,308.06z"
          />
          <path
            className="st0"
            d="M1004.14,304.55c-1.26-0.62-2.56-1.1-3.94-1.38C1001.58,303.45,1002.88,303.93,1004.14,304.55z"
          />
          <path
            className="st0"
            d="M1013.55,306.33c3.49,0.45,7,0.75,10.49,1.13c0,0,0,0,0,0C1020.54,307.07,1017.03,306.78,1013.55,306.33z"
          />
          <path
            className="st0"
            d="M1181.29,471.19C1181.29,471.19,1181.29,471.19,1181.29,471.19c-0.27,0.51-0.68,1-0.9,1.51
		C1180.61,472.19,1181.02,471.7,1181.29,471.19z"
          />
          <path
            className="st0"
            d="M1156.98,633.66c0.19-0.09,0.37-0.18,0.56-0.26c0.15,0.32,0.3,0.63,0.44,0.95c-0.15-0.32-0.3-0.63-0.44-0.95
		C1157.36,633.49,1157.17,633.58,1156.98,633.66z"
          />
          <path
            className="st0"
            d="M1126.52,632.57c-0.2,0.16-0.4,0.32-0.61,0.48c2.06,0.81,4.13,1.62,6.19,2.43c0,0,0,0,0,0
		c-2.06-0.81-4.13-1.62-6.19-2.43C1126.12,632.89,1126.32,632.73,1126.52,632.57z"
          />
          <path
            className="st0"
            d="M1055.71,334.68c-0.87-0.37-1.74-0.71-2.62-1.03C1053.97,333.97,1054.84,334.31,1055.71,334.68z"
          />
          <path
            className="st0"
            d="M1150,615.47c1.01,1.18,2.03,2.35,3.04,3.53c3.05,3.36,6.1,6.72,9.14,10.08l0,0
		c-3.05-3.36-6.1-6.72-9.14-10.08C1152.03,617.82,1151.02,616.64,1150,615.47c-0.36,0-0.72,0-1.09,0
		C1149.28,615.46,1149.64,615.46,1150,615.47z"
          />
          <path
            className="st0"
            d="M1133.19,632.4c-2.23,0.06-4.45,0.11-6.67,0.17c-0.37-0.24-0.74-0.48-1.1-0.72c0.37,0.24,0.74,0.48,1.1,0.72
		C1128.74,632.51,1130.96,632.46,1133.19,632.4z"
          />
          <path
            className="st0"
            d="M1155.18,633.35c0.26-0.03,0.52-0.07,0.88-0.11C1155.71,633.29,1155.44,633.32,1155.18,633.35L1155.18,633.35z
		"
          />
          <path
            className="st0"
            d="M1140.69,620.42L1140.69,620.42c0.02-0.26,0.03-0.53,0.05-0.81C1140.72,619.89,1140.71,620.16,1140.69,620.42z
		"
          />
          <polygon
            className="st0"
            points="1140.84,622.63 1140.84,622.63 1140.84,622.63 	"
          />
          <path
            className="st0"
            d="M1177.96,559.49c0.23-5.97,0.49-11.95,0.7-17.92c0.01-0.07,0.01-0.14,0.02-0.21
		c-0.01,0.07-0.01,0.14-0.02,0.21C1178.45,547.54,1178.19,553.51,1177.96,559.49z"
          />
          <path
            className="st0"
            d="M1145.46,609.92c-2.54,1.11-5.06,2.21-7.59,3.32v0C1140.4,612.14,1142.92,611.03,1145.46,609.92z"
          />
          <path
            className="st0"
            d="M1128.66,618.17c-1-0.2-2-0.41-3-0.61c0,0,0,0,0,0c-0.6,0.21-1.2,0.41-1.8,0.62c0.6-0.21,1.2-0.41,1.8-0.62
		C1126.66,617.76,1127.66,617.97,1128.66,618.17z"
          />
          <path
            className="st0"
            d="M1145.38,538.7c0.93,0.93,2.13,1.67,3.42,2.34c0,0,0,0,0,0C1147.51,540.37,1146.31,539.63,1145.38,538.7z"
          />
          <path
            className="st0"
            d="M1175.29,528.7C1175.29,528.7,1175.29,528.7,1175.29,528.7c0.93,2.2,1.89,4.38,2.79,6.59
		C1177.18,533.08,1176.23,530.89,1175.29,528.7z"
          />
          <path
            className="st0"
            d="M1152.44,543.43c-1.64-1.08-2.68-1.77-3.71-2.46c0,0,0,0,0,0C1149.76,541.66,1150.8,542.34,1152.44,543.43z"
          />
          <path
            className="st0"
            d="M1148.8,541.04c0,0-0.06-0.06-0.07-0.06C1148.74,540.98,1148.8,541.03,1148.8,541.04z"
          />
          <path
            className="st0"
            d="M1113.81,625.82c0.14,0.13,0.28,0.25,0.42,0.38l0,0C1114.09,626.07,1113.95,625.94,1113.81,625.82z"
          />
          <path
            className="st0"
            d="M1177.52,648.61c0.14-11.55,0.41-23.1,0.56-34.65c1.48,0.53,2.96,1.06,4.41,1.59
		c-1.45-0.52-2.93-1.06-4.41-1.59C1177.93,625.51,1177.66,637.06,1177.52,648.61z"
          />
          <path
            className="st0"
            d="M1132.9,619.01c-4.54,1.68-9.07,3.38-13.63,5.01C1123.83,622.39,1128.36,620.68,1132.9,619.01
		c0.33-0.25,0.66-0.51,1-0.76C1133.56,618.5,1133.23,618.76,1132.9,619.01z"
          />
          <path
            className="st0"
            d="M1174.64,525.95c0.08,0.35,0.16,0.7,0.25,1.05C1174.81,526.65,1174.73,526.3,1174.64,525.95
		C1174.64,525.95,1174.64,525.95,1174.64,525.95z"
          />
          <path
            className="st0"
            d="M1044.71,335.47c0.8,0.24,1.56,0.47,2.32,0.7c1.83,0.58,3.66,1.17,5.51,1.76c-1.85-0.59-3.68-1.17-5.51-1.76
		C1046.27,335.94,1045.51,335.71,1044.71,335.47z"
          />
          <path
            className="st0"
            d="M1046.24,422c1.68,0.71,3.36,1.43,5.03,2.14C1049.59,423.43,1047.91,422.72,1046.24,422
		C1046.24,422,1046.24,422,1046.24,422z"
          />
          <path
            className="st0"
            d="M1048.07,443.6C1048.07,443.6,1048.07,443.6,1048.07,443.6c-2.42-1.34-4.84-2.68-7.42-4.1
		C1043.23,440.92,1045.65,442.26,1048.07,443.6z"
          />
          <path
            className="st0"
            d="M1044.98,352.54c0.91-0.51,1.79-1,2.67-1.49c0.82-0.16,1.64-0.32,2.46-0.48c-0.82,0.16-1.64,0.32-2.46,0.48
		C1046.78,351.54,1045.9,352.03,1044.98,352.54z"
          />
          <path
            className="st0"
            d="M998.25,313.03L998.25,313.03c0.46,0.72,0.92,1.44,1.38,2.16l0,0C999.17,314.47,998.71,313.75,998.25,313.03z"
          />
          <path
            className="st0"
            d="M999.63,315.19c-0.46-0.72-0.92-1.44-1.38-2.16c0,0,0,0,0,0C998.71,313.75,999.17,314.47,999.63,315.19z"
          />
          <path
            className="st0"
            d="M980.91,368.22c0.07,0.49,0.15,0.98,0.23,1.47c-0.07,0.7-0.07,1.42-0.11,2.13c0.04-0.71,0.04-1.43,0.11-2.13
		C981.06,369.2,980.98,368.71,980.91,368.22z"
          />
          <path
            className="st0"
            d="M998.25,313.03c-0.23-0.47-0.47-0.94-0.7-1.41h0C997.79,312.09,998.02,312.56,998.25,313.03L998.25,313.03z"
          />
          <path
            className="st0"
            d="M997.55,311.62L997.55,311.62c0.23,0.47,0.47,0.94,0.7,1.41c0,0,0,0,0,0
		C998.02,312.56,997.79,312.09,997.55,311.62z"
          />
          <path
            className="st0"
            d="M965.89,307.29c0.07-0.22,0.13-0.44,0.2-0.66C966.02,306.86,965.95,307.07,965.89,307.29
		c-0.21,1.46-0.42,2.92-0.63,4.39l0,0C965.47,310.22,965.68,308.75,965.89,307.29z"
          />
          <path
            className="st0"
            d="M1013.24,455.14c-0.31-0.28-0.59-0.59-0.82-0.92C1012.65,454.55,1012.93,454.86,1013.24,455.14z"
          />
          <path
            className="st0"
            d="M1057.15,332.44c0.48,0.13,0.95,0.35,1.4,0.58C1058.1,332.79,1057.64,332.57,1057.15,332.44z"
          />
          <path
            className="st0"
            d="M966.6,290.8c-1.13,1.71-2.26,3.43-3.4,5.14c-0.18,0.28-0.35,0.55-0.56,0.88c0.21-0.33,0.38-0.6,0.56-0.88
		C964.33,294.23,965.47,292.51,966.6,290.8z"
          />
          <path
            className="st0"
            d="M999.56,334.04c-1.64-1.52-3.27-3.03-4.91-4.55c-0.59-0.75-1.19-1.51-1.8-2.29c0.61,0.78,1.21,1.54,1.8,2.29
		C996.29,331.01,997.92,332.53,999.56,334.04z"
          />
          <path
            className="st0"
            d="M995.15,457.02c-0.07,0.02-0.13,0.03-0.2,0.05C995.02,457.06,995.08,457.04,995.15,457.02z"
          />
          <path
            className="st0"
            d="M1009.67,428.45c1.09,0.43,2.13,0.83,3.16,1.24c0.12,0.43,0.23,0.86,0.35,1.29c-0.12-0.43-0.23-0.86-0.35-1.29
		C1011.79,429.29,1010.76,428.88,1009.67,428.45z"
          />
          <path
            className="st0"
            d="M1014.04,430.21c-0.24-0.37-0.48-0.75-0.72-1.12c-2-1.42-3.99-2.84-5.99-4.26c2,1.42,3.99,2.84,5.99,4.26
		C1013.56,429.47,1013.8,429.84,1014.04,430.21z"
          />
          <path
            className="st0"
            d="M1014.11,455.77c-0.28-0.17-0.54-0.35-0.77-0.55C1013.57,455.42,1013.83,455.6,1014.11,455.77z"
          />
          <path
            className="st0"
            d="M991.05,359.07L991.05,359.07c-0.22-0.09-0.44-0.14-0.68-0.16c-1.56-1.64-3.12-3.28-4.68-4.92
		c1.56,1.64,3.12,3.28,4.68,4.92C990.61,358.93,990.84,358.98,991.05,359.07z"
          />
          <path
            className="st0"
            d="M1058.47,335.91c0.19,0.1,0.37,0.21,0.55,0.32C1058.83,336.12,1058.65,336.01,1058.47,335.91L1058.47,335.91z"
          />
          <path
            className="st0"
            d="M1089.99,361.02c-0.96-0.48-1.88-0.95-2.8-1.41c0,0,0,0,0,0C1088.11,360.07,1089.03,360.53,1089.99,361.02z"
          />
          <path
            className="st0"
            d="M1085.61,361.94L1085.61,361.94c0.53-0.78,1.06-1.55,1.58-2.33C1086.66,360.39,1086.13,361.16,1085.61,361.94z
		"
          />
          <path
            className="st0"
            d="M1102.44,398.5c0.4-3.64,0.8-7.29,1.2-10.93c-0.19-3.57-0.37-7.14-0.56-10.71c0.19,3.58,0.38,7.15,0.56,10.71
		C1103.24,391.21,1102.84,394.85,1102.44,398.5z"
          />
          <path
            className="st0"
            d="M1016.9,342.52c-1.61,2.77-3.21,5.55-4.91,8.49C1013.69,348.07,1015.3,345.29,1016.9,342.52
		c2.27-2.39,4.54-4.77,6.81-7.16c0.66-0.45,1.35-0.87,1.98-1.37c-0.63,0.5-1.31,0.92-1.98,1.37
		C1021.44,337.75,1019.17,340.13,1016.9,342.52z"
          />
          <path
            className="st0"
            d="M1062.56,335.47c0.71,0.47,1.42,0.93,2.15,1.38C1063.99,336.4,1063.27,335.94,1062.56,335.47z"
          />
          <path
            className="st0"
            d="M1054.48,473.71c0.01-0.01,0.02-0.02,0.03-0.03C1054.5,473.69,1054.49,473.7,1054.48,473.71z"
          />
          <path
            className="st0"
            d="M1060.74,334.28c-0.27-0.18-0.55-0.36-0.82-0.54c-0.46-0.22-0.91-0.48-1.37-0.72
		c0.46,0.24,0.91,0.49,1.37,0.72C1060.2,333.92,1060.47,334.1,1060.74,334.28z"
          />
          <path
            className="st0"
            d="M1057.13,475.62c0.06-0.19,0.14-0.39,0.22-0.58C1057.27,475.23,1057.2,475.42,1057.13,475.62z"
          />
          <path
            className="st0"
            d="M1051.22,476.31c-0.28,0.27-0.56,0.54-0.85,0.81c-0.96,0.76-1.92,1.52-2.98,2.36
		c1.05-0.83,2.01-1.59,2.98-2.36C1050.65,476.86,1050.93,476.59,1051.22,476.31z"
          />
          <path
            className="st0"
            d="M1067,477.14c0,0,0.07-0.08,0.07-0.08L1067,477.14c0.85-3.24,1.69-6.48,2.53-9.67
		C1068.69,470.66,1067.85,473.9,1067,477.14z"
          />
          <path
            className="st0"
            d="M995.05,460.76c0.38-0.18,0.78-0.36,1.22-0.56C995.83,460.4,995.43,460.58,995.05,460.76z"
          />
          <path
            className="st0"
            d="M982.06,501.39c1.32-1.12,2.65-2.25,4.06-3.45C984.71,499.14,983.38,500.27,982.06,501.39
		C982.06,501.39,982.06,501.39,982.06,501.39z"
          />
          <path
            className="st0"
            d="M992.66,405.3c-1.01-0.21-2.01-0.41-3.02-0.6v0C990.65,404.89,991.65,405.09,992.66,405.3z"
          />
          <path
            className="st0"
            d="M1062.61,466.47c0.29-0.53,0.57-1.06,0.86-1.59c0.45,0.19,0.9,0.39,1.37,0.59c-0.46-0.2-0.91-0.39-1.37-0.59
		c-0.56-0.31-1.12-0.63-1.73-0.96c0.6,0.33,1.16,0.65,1.73,0.96C1063.19,465.41,1062.9,465.94,1062.61,466.47z"
          />
          <path
            className="st0"
            d="M1064.84,465.46c0.46,0.2,0.93,0.4,1.43,0.61C1065.77,465.87,1065.3,465.66,1064.84,465.46z"
          />
          <path
            className="st0"
            d="M1057.75,473.6c0.01,0.45-0.2,0.94-0.39,1.43C1057.55,474.54,1057.76,474.05,1057.75,473.6L1057.75,473.6z"
          />
          <path
            className="st0"
            d="M208.62,851.4c-0.97,2.56-1.93,5.1-2.89,7.63c-0.15,0.2-0.3,0.4-0.44,0.61c0.14-0.21,0.29-0.41,0.44-0.61
		C206.69,856.5,207.65,853.97,208.62,851.4z"
          />
          <path
            className="st0"
            d="M883.2,515.47c-0.13-0.08-0.26-0.16-0.38-0.25c0,0,0,0,0,0C882.94,515.31,883.07,515.39,883.2,515.47z"
          />
          <path
            className="st0"
            d="M1141.29,613.53c-0.89,0.11-1.78,0.26-2.67,0.36v0c-3.32,1.43-6.64,2.86-9.97,4.28
		c3.32-1.43,6.64-2.86,9.97-4.28C1139.51,613.79,1140.4,613.64,1141.29,613.53z"
          />
          <path
            className="st0"
            d="M924.6,511.43L924.6,511.43c0.16,1.11,0.33,2.22,0.49,3.33C924.92,513.64,924.76,512.54,924.6,511.43z"
          />
          <path
            className="st0"
            d="M883.34,516.74c0.03,0.39-0.08,0.67-0.27,0.88C883.26,517.42,883.37,517.13,883.34,516.74z"
          />
          <path
            className="st0"
            d="M911.03,495.51c0.33-0.07,0.65-0.15,0.98-0.21C911.69,495.36,911.36,495.43,911.03,495.51z"
          />
          <path
            className="st0"
            d="M802.77,565.99c0.22-0.69,0.41-1.42,0.56-2.2c0,0,0,0,0,0C803.18,564.57,803,565.3,802.77,565.99z"
          />
          <path
            className="st0"
            d="M864.55,500.04c-0.92-1.6-1.72-3.27-2.38-5.03C862.83,496.77,863.63,498.44,864.55,500.04z"
          />
          <path
            className="st0"
            d="M860.91,490.31c0.18,0.86,0.36,1.7,0.55,2.54c0.22,0.73,0.45,1.45,0.72,2.15c-0.26-0.7-0.5-1.42-0.72-2.15
		C861.28,492.01,861.1,491.17,860.91,490.31z"
          />
          <path
            className="st0"
            d="M932.24,510.23c0.41,0.79,0.87,2.06,1.61,4.11c1.65,0.75,3.3,1.5,4.95,2.25c-1.65-0.75-3.3-1.5-4.95-2.25
		C933.11,512.29,932.65,511.01,932.24,510.23z"
          />
          <path
            className="st0"
            d="M213.53,859.68c0.04-0.69,0.06-1.37,0.08-2.05C213.6,858.31,213.57,859,213.53,859.68L213.53,859.68z"
          />
          <path
            className="st0"
            d="M559,790.38L559,790.38c-0.1,0.51-0.19,1.03-0.29,1.54C558.8,791.4,558.9,790.89,559,790.38z"
          />
          <path
            className="st0"
            d="M852.23,498c0.26-0.03,0.54-0.04,0.83-0.03C852.77,497.96,852.49,497.97,852.23,498z"
          />
          <path
            className="st0"
            d="M245.29,778.84c-1.56,1.16-3.08,2.3-4.6,3.43l0,0C242.21,781.14,243.73,780,245.29,778.84z"
          />
          <path
            className="st0"
            d="M917.26,545.93L917.26,545.93c-0.17-1.96-0.34-3.93-0.51-5.89l0,0C916.92,542.01,917.09,543.97,917.26,545.93z
		"
          />
          <path
            className="st0"
            d="M590.48,771.55c0.82-0.49,1.64-0.99,2.45-1.49C592.12,770.56,591.31,771.06,590.48,771.55L590.48,771.55
		c-0.8,2.34-1.68,4.66-2.44,7.01C588.8,776.2,589.69,773.89,590.48,771.55z"
          />
          <path
            className="st0"
            d="M853.06,497.97c0.29,0.01,0.59,0.03,0.9,0.07C853.65,498,853.35,497.98,853.06,497.97z"
          />
          <path
            className="st0"
            d="M849.83,500.85C849.83,500.85,849.83,500.85,849.83,500.85c-0.14,0-0.28-0.01-0.42-0.01
		C849.55,500.85,849.69,500.85,849.83,500.85z"
          />
          <path
            className="st0"
            d="M852.23,498c-1.17,0.15-2.04,0.81-2.27,2.77c0,0,0,0,0,0C850.18,498.81,851.06,498.15,852.23,498z"
          />
          <polygon
            className="st0"
            points="939.95,499.72 940.11,499.53 939.95,499.72 	"
          />
          <path
            className="st0"
            d="M713,549.32c0.18,0.37,0.36,0.74,0.53,1.12c0.17,2.15,0.27,4.31,0.39,6.47c-0.13-2.16-0.22-4.32-0.39-6.47
		C713.36,550.07,713.18,549.7,713,549.32z"
          />
          <path
            className="st0"
            d="M747.42,528.31c0,0,0,0.01,0,0.01c0.68,1.91,1.37,3.82,2.11,5.89C748.79,532.14,748.1,530.22,747.42,528.31z"
          />
          <path
            className="st0"
            d="M746.35,519.85c-1.11-4.67-0.58-9.7-1.94-14.31C745.77,510.16,745.24,515.19,746.35,519.85
		c-0.13,0.1-0.25,0.2-0.37,0.31C746.1,520.05,746.22,519.95,746.35,519.85c0.28,2.36,0.57,4.72,0.85,7.08c0,0.01,0,0.02,0.01,0.03
		C746.92,524.59,746.64,522.22,746.35,519.85z"
          />
          <path
            className="st0"
            d="M683.15,439.75c-1-1.31-1.87-2.66-2.59-4.06C681.29,437.1,682.15,438.45,683.15,439.75L683.15,439.75z"
          />
          <path
            className="st0"
            d="M620.17,471.82c-0.07,0.58-0.14,1.16-0.19,1.75c-0.79,1.73-1.39,3.63-2.11,5.43c0.72-1.8,1.33-3.7,2.11-5.43
		C620.03,472.98,620.1,472.4,620.17,471.82z"
          />
          <path
            className="st0"
            d="M814.88,571.07c-0.28,1.75-0.56,3.5-0.83,5.25C814.33,574.58,814.61,572.83,814.88,571.07
		c0.03-0.56,0.05-1.12,0.06-1.67C814.93,569.96,814.91,570.52,814.88,571.07z"
          />
          <path
            className="st0"
            d="M834.2,546.03c2.96,0.36,5.75,1.45,8.58,2.51C839.94,547.48,837.15,546.39,834.2,546.03L834.2,546.03z"
          />
          <path
            className="st0"
            d="M714.21,560.79c-0.08-0.86-0.15-1.72-0.2-2.58C714.06,559.07,714.13,559.93,714.21,560.79z"
          />
          <path
            className="st0"
            d="M911.23,550.8c-1.05,0.67-2.12,1.28-3.22,1.8C909.1,552.08,910.18,551.48,911.23,550.8z"
          />
          <path
            className="st0"
            d="M500.9,558.25c-0.35-0.23-0.74-0.46-1.15-0.67C500.16,557.8,500.55,558.02,500.9,558.25L500.9,558.25z"
          />
          <path
            className="st0"
            d="M943.71,502.9c0.03,0.28,0.05,0.56,0.06,0.85C943.76,503.46,943.74,503.18,943.71,502.9z"
          />
          <path
            className="st0"
            d="M690.59,563.46c1.52,0.82,3.04,1.66,4.55,2.51c1.64,1.05,3.27,2.11,4.91,3.16c-1.64-1.05-3.27-2.11-4.91-3.16
		C693.63,565.12,692.12,564.28,690.59,563.46z"
          />
          <path
            className="st0"
            d="M943.77,503.77c0.01,0.37,0,0.75-0.01,1.13C943.76,504.52,943.77,504.14,943.77,503.77z"
          />
          <path
            className="st0"
            d="M614.95,500.37c-0.7,0.37-1.41,0.75-2.11,1.13c0.1,1.73,0.19,3.46,0.29,5.19c-0.1-1.73-0.19-3.46-0.29-5.19
		C613.55,501.12,614.25,500.75,614.95,500.37z"
          />
          <path
            className="st0"
            d="M606.19,507.2c0.39,1.59,0.66,3.24,1.12,4.8C606.85,510.44,606.58,508.79,606.19,507.2
		c-0.15,1.09-0.3,2.18-0.46,3.35C605.89,509.38,606.04,508.29,606.19,507.2z"
          />
          <polygon
            className="st0"
            points="596.19,499.34 596.25,499.3 596.19,499.34 	"
          />
          <path
            className="st0"
            d="M587.52,502.28c-0.59,0.95-1.15,1.88-1.72,2.81c-0.6,0.48-1.17,1.01-1.76,1.51c0.59-0.5,1.16-1.03,1.76-1.51
		C586.36,504.16,586.93,503.23,587.52,502.28z"
          />
          <path
            className="st0"
            d="M931.83,509.59c0.14,0.16,0.27,0.37,0.41,0.63C932.1,509.96,931.97,509.76,931.83,509.59z"
          />
          <path
            className="st0"
            d="M1005.54,956.5c-0.59-0.15-1.16-0.56-1.68-1.4c6.68-3.13,13.36-6.27,20.04-9.4
		c-6.68,3.13-13.36,6.27-20.04,9.4C1004.38,955.94,1004.94,956.35,1005.54,956.5z"
          />
          <path
            className="st0"
            d="M1081.43,923.08c-0.3-0.43-0.59-0.86-0.89-1.28C1080.84,922.22,1081.14,922.65,1081.43,923.08z"
          />
          <path
            className="st0"
            d="M1034.02,887.34c0,1.29,0,2.59,0,3.88c-1.41,1.48-2.82,2.97-4.24,4.45c1.41-1.48,2.82-2.97,4.24-4.45
		C1034.02,889.93,1034.02,888.63,1034.02,887.34z"
          />
          <path
            className="st0"
            d="M1080.54,921.79c-0.45-0.64-0.91-1.27-1.4-1.86c0.16-0.48,0.32-0.96,0.5-1.47c-0.17,0.51-0.33,0.99-0.5,1.47
		c-0.44,0.38-0.88,0.76-1.35,1.17c0.47-0.41,0.91-0.79,1.35-1.17C1079.64,920.52,1080.09,921.15,1080.54,921.79z"
          />
          <path
            className="st0"
            d="M1120.23,619.43c1.23-0.42,2.43-0.84,3.63-1.25C1122.66,618.59,1121.45,619,1120.23,619.43z"
          />
          <path
            className="st0"
            d="M985.43,934.18L985.43,934.18c-0.51-0.23-1.02-0.46-1.53-0.69c-3.33-1.95-6.66-3.89-9.99-5.84
		c3.33,1.95,6.66,3.89,9.99,5.84C984.41,933.72,984.92,933.95,985.43,934.18z"
          />
          <path
            className="st0"
            d="M973.86,922.09c-2.26-1.46-4.53-2.92-6.81-4.4C969.33,919.17,971.6,920.63,973.86,922.09
		c7.4,5.04,14.79,10.07,22.19,15.11C988.66,932.16,981.26,927.13,973.86,922.09z"
          />
          <path
            className="st0"
            d="M169.3,892.59c-0.01,0.88-0.08,1.73-0.29,2.5C169.22,894.32,169.29,893.47,169.3,892.59z"
          />
          <path
            className="st0"
            d="M1056.09,923.49c0.29-0.27,0.57-0.53,0.86-0.8l-0.08,0.02v0c0,0,0.08-0.02,0.08-0.02
		C1056.67,922.96,1056.38,923.23,1056.09,923.49z"
          />
          <path
            className="st0"
            d="M1147.89,615.74c0,0.02,0.01,0.05,0.01,0.08C1147.9,615.79,1147.89,615.76,1147.89,615.74z"
          />
          <path
            className="st0"
            d="M1158.01,628.34c0.47,0,0.94,0,1.41,0c0.66,0.18,1.32,0.35,1.98,0.53c-0.66-0.18-1.32-0.35-1.98-0.53
		C1158.95,628.34,1158.48,628.34,1158.01,628.34z"
          />
          <path
            className="st0"
            d="M1145.83,615.25c-0.01-0.07-0.03-0.13-0.03-0.13c0,0,0,0,0,0L1145.83,615.25z"
          />
          <path
            className="st0"
            d="M988.73,936.14c-0.85-0.98-1.86-1.77-3.34-2.01l0,0C986.87,934.36,987.88,935.16,988.73,936.14z"
          />
          <path
            className="st0"
            d="M1135.91,616.8c-0.52,0.33-1.02,0.69-1.52,1.07C1134.89,617.5,1135.4,617.13,1135.91,616.8z"
          />
          <path
            className="st0"
            d="M1153,638.94C1153,638.94,1153,638.94,1153,638.94c-3.36-0.44-6.72-0.87-10.08-1.31
		C1146.28,638.07,1149.64,638.5,1153,638.94z"
          />
          <path
            className="st0"
            d="M1114.23,626.19c2.4,0.44,4.81,0.87,7.21,1.31c0,0,0,0,0,0C1119.04,627.07,1116.63,626.63,1114.23,626.19z"
          />
          <path
            className="st0"
            d="M1140.58,622.67c-1.71,0.27-3.41,0.55-5.12,0.82C1137.16,623.22,1138.87,622.95,1140.58,622.67
		C1140.58,622.67,1140.58,622.67,1140.58,622.67z"
          />
          <path
            className="st0"
            d="M1147.15,620.43c0.28,0.1,0.56,0.19,0.84,0.29C1147.71,620.63,1147.43,620.53,1147.15,620.43L1147.15,620.43z"
          />
          <path
            className="st0"
            d="M998.89,947.78c-2.79,0.38-5.58,0.76-8.37,1.14C993.31,948.54,996.1,948.16,998.89,947.78L998.89,947.78z"
          />
          <path
            className="st0"
            d="M15.8,918.59C15.8,918.59,15.8,918.59,15.8,918.59c0.31-1.05,0.38-2.31,0.9-3.18
		C16.18,916.28,16.11,917.54,15.8,918.59c0.29,1.21,0.81,1.81,1.44,2.1C16.61,920.4,16.09,919.79,15.8,918.59z"
          />
          <path
            className="st0"
            d="M964.57,1039.44c-0.79,0.71-1.62,1.37-2.5,1.96C962.95,1040.81,963.79,1040.15,964.57,1039.44z"
          />
          <path
            className="st0"
            d="M970.16,1030.18c-0.04,0.21-0.09,0.43-0.14,0.64C970.07,1030.6,970.12,1030.39,970.16,1030.18z"
          />
          <path
            className="st0"
            d="M968.2,1035.1c-0.44,0.71-0.91,1.4-1.45,2.06C967.29,1036.51,967.77,1035.81,968.2,1035.1z"
          />
          <path
            className="st0"
            d="M1075.69,974.56c-1.06,0.76-2.12,1.49-3.21,2.19C1073.57,976.05,1074.64,975.31,1075.69,974.56z"
          />
          <path
            className="st0"
            d="M60.63,909.51c-0.36-0.01-0.71-0.02-1.07-0.05C59.92,909.48,60.27,909.5,60.63,909.51z"
          />
          <path
            className="st0"
            d="M50.88,905.51c-0.2-0.23-0.38-0.46-0.55-0.7c-0.19-0.64-0.37-1.28-0.57-1.95c0.19,0.67,0.38,1.31,0.57,1.95
		C50.5,905.05,50.68,905.28,50.88,905.51z"
          />
          <path
            className="st0"
            d="M965.98,313.83c-0.25-0.72-0.5-1.43-0.75-2.15l0,0C965.48,312.4,965.73,313.11,965.98,313.83z"
          />
          <path
            className="st0"
            d="M960.73,1042.24c-0.91,0.53-1.84,0.98-2.79,1.36C958.89,1043.23,959.82,1042.77,960.73,1042.24z"
          />
          <path
            className="st0"
            d="M1050.23,967.22c2.24-1.42,4.46-2.88,6.69-4.33C1054.69,964.34,1052.47,965.8,1050.23,967.22
		c-5.51,3.54-10.93,7.26-16.44,10.8C1039.31,974.48,1044.72,970.76,1050.23,967.22C1050.23,967.22,1050.23,967.22,1050.23,967.22z"
          />
          <path
            className="st0"
            d="M1080.39,971.07c1.04-0.78,2.08-1.56,3.14-2.31h0c0,0,0,0,0,0C1082.48,969.51,1081.43,970.29,1080.39,971.07z"
          />
          <path
            className="st0"
            d="M984.43,941.89c-0.93-0.6-1.83-1.18-2.73-1.77c-2.83-2.06-5.66-4.1-8.49-6.15c2.83,2.05,5.67,4.09,8.49,6.15
		C982.6,940.71,983.5,941.29,984.43,941.89z"
          />
          <path
            className="st0"
            d="M1079.88,968.48c-0.66-1.37-1.33-2.76-2.01-4.15v0C1078.55,965.73,1079.22,967.12,1079.88,968.48z"
          />
          <path
            className="st0"
            d="M988.73,936.14c0.43,0.49,0.81,1.02,1.2,1.55C989.54,937.16,989.16,936.62,988.73,936.14z"
          />
          <path
            className="st0"
            d="M916.72,1013.12c1.13,0.95,2.26,1.89,3.38,2.84c0.12,0.2,0.23,0.42,0.33,0.64c-0.11-0.22-0.21-0.43-0.33-0.64
		C918.98,1015.01,917.85,1014.06,916.72,1013.12z"
          />
          <path
            className="st0"
            d="M970.37,1027.47c1.92-0.65,3.89-1.19,5.79-1.89C974.26,1026.28,972.29,1026.82,970.37,1027.47
		C970.37,1027.47,970.37,1027.47,970.37,1027.47z"
          />
          <path
            className="st0"
            d="M1079.88,968.48c0.71,0.05,1.36,0.1,1.98,0.15C1081.24,968.59,1080.6,968.54,1079.88,968.48z"
          />
          <path
            className="st0"
            d="M710.32,478.4c0.4-0.66,0.85-1.31,1.3-1.95c1.16,0.43,2.31,0.85,3.47,1.28c-1.16-0.43-2.31-0.85-3.47-1.28
		C711.17,477.09,710.72,477.74,710.32,478.4z"
          />
          <path
            className="st0"
            d="M752.47,429.91c-0.89,0.5-1.78,1-2.69,1.52C750.69,430.92,751.58,430.42,752.47,429.91
		c-2.19-1.05-4.38-2.09-6.57-3.14C748.09,427.82,750.28,428.87,752.47,429.91z"
          />
          <path
            className="st0"
            d="M571.13,741.56c2.64,1.68,5.28,3.37,7.93,5.05c0.18,0.11,0.35,0.23,0.53,0.34c-0.18-0.11-0.35-0.23-0.53-0.34
		C576.41,744.93,573.77,743.25,571.13,741.56z"
          />
          <path
            className="st0"
            d="M732.6,441.55c0.01,0,0.03,0,0.04,0c4.63-2.77,9.7-3.92,14.9-4.66c-5.2,0.74-10.27,1.89-14.9,4.66
		C732.62,441.55,732.61,441.55,732.6,441.55z"
          />
          <path
            className="st0"
            d="M753.33,428.03c0.4,0.14,0.81,0.27,1.21,0.41C754.14,428.3,753.74,428.16,753.33,428.03
		C753.33,428.03,753.33,428.03,753.33,428.03z"
          />
          <path
            className="st0"
            d="M719.72,427.93c-0.19-1.11-0.28-2.22-0.29-3.32C719.43,425.71,719.53,426.82,719.72,427.93z"
          />
          <path
            className="st0"
            d="M754.54,428.43c-0.17-0.28-0.33-0.56-0.5-0.84c0-3.01,0-6.02,0-9.01c0,2.99,0,6,0,9.01
		C754.21,427.88,754.38,428.16,754.54,428.43z"
          />
          <path
            className="st0"
            d="M722.4,435.53C722.4,435.53,722.4,435.53,722.4,435.53c-1.14-2.09-1.98-4.28-2.47-6.5
		C720.42,431.25,721.26,433.45,722.4,435.53z"
          />
          <path
            className="st0"
            d="M706.53,436.31C706.53,436.31,706.53,436.31,706.53,436.31c0.54,0.18,1.09,0.41,1.64,0.62
		C707.62,436.72,707.07,436.49,706.53,436.31z"
          />
          <path
            className="st0"
            d="M717.42,433.91c0.02-0.14,0.03-0.28,0.05-0.41h0C717.45,433.63,717.43,433.77,717.42,433.91z"
          />
          <path
            className="st0"
            d="M719.46,423.52c0.04-0.73,0.12-1.45,0.25-2.16C719.58,422.07,719.5,422.79,719.46,423.52z"
          />
          <path
            className="st0"
            d="M583.6,769.88c-6.39,3.74-12.75,7.47-19.1,11.19C570.85,777.34,577.21,773.62,583.6,769.88z"
          />
          <path
            className="st0"
            d="M525.23,776.76c0.89,0.52,1.78,1.04,2.67,1.56l0.07,0.08c0,0-0.07-0.08-0.07-0.08
		C527.01,777.8,526.12,777.28,525.23,776.76z"
          />
          <path
            className="st0"
            d="M523.82,776c0.47,0.25,0.93,0.5,1.4,0.75l0.01,0.01c0,0-0.01-0.01-0.01-0.01
		C524.75,776.5,524.29,776.25,523.82,776z"
          />
          <path
            className="st0"
            d="M732.38,433.45c-0.01,2.62-0.02,5.23-0.02,7.85c-0.15,0.05-0.3,0.1-0.45,0.15c0.15-0.05,0.3-0.1,0.45-0.15
		C732.36,438.68,732.37,436.06,732.38,433.45z"
          />
          <path
            className="st0"
            d="M756.1,448.46c0,1.65,0,3.24,0,4.83c0.35,1.64,0.7,3.29,1.08,5.07c-0.38-1.79-0.73-3.43-1.08-5.08
		C756.1,451.7,756.1,450.11,756.1,448.46z"
          />
          <path
            className="st0"
            d="M567.52,738.74c0.49,0.47,0.98,0.94,1.47,1.41l0,0C568.5,739.68,568.01,739.21,567.52,738.74L567.52,738.74z"
          />
          <path
            className="st0"
            d="M564.43,737.24c1.03,0.5,2.06,1,3.09,1.5l0,0C566.49,738.24,565.46,737.74,564.43,737.24z"
          />
          <path
            className="st0"
            d="M624.93,716.6c-2.86-1.75-5.73-3.49-8.59-5.24c-3.84-2.53-7.67-5.07-11.74-7.75c4.07,2.68,7.9,5.22,11.74,7.75
		C619.2,713.1,622.06,714.85,624.93,716.6z"
          />
          <path
            className="st0"
            d="M554.97,731.9c-0.45-0.2-0.93-0.4-1.44-0.62C554.04,731.49,554.52,731.7,554.97,731.9z"
          />
          <path
            className="st0"
            d="M705.14,425.62c-0.26-1.99-0.52-4.01-0.79-6.1C704.62,421.61,704.88,423.63,705.14,425.62z"
          />
          <path
            className="st0"
            d="M715.73,461.39c-0.3-0.84-0.61-1.67-0.92-2.51C715.12,459.72,715.44,460.55,715.73,461.39z"
          />
          <path
            className="st0"
            d="M709.77,479.4c-0.11,0.22-0.2,0.44-0.29,0.66C709.57,479.84,709.67,479.62,709.77,479.4z"
          />
          <path
            className="st0"
            d="M699.57,465.44c0.21,0.31,0.42,0.62,0.63,0.94c1.45,3.13,2.89,6.26,4.34,9.4c-1.45-3.13-2.89-6.26-4.34-9.4
		C699.99,466.07,699.78,465.75,699.57,465.44z"
          />
          <path
            className="st0"
            d="M717.29,485.56c-0.22-0.17-0.47-0.37-0.72-0.57C716.82,485.19,717.07,485.39,717.29,485.56z"
          />
          <path
            className="st0"
            d="M715.97,434.8c-0.7,0.29-1.41,0.58-2.11,0.87c0,0-0.01,0.01-0.02,0.02c0.01-0.01,0.02-0.02,0.02-0.02
		C714.57,435.38,715.27,435.09,715.97,434.8c-0.31-1.54-0.66-3.07-0.99-4.6C715.31,431.74,715.67,433.27,715.97,434.8z"
          />
          <path
            className="st0"
            d="M689.43,415.42c1.88,0.25,3.76,0.49,5.64,0.74C693.19,415.91,691.31,415.67,689.43,415.42
		C689.43,415.42,689.43,415.42,689.43,415.42z"
          />
          <path
            className="st0"
            d="M697.52,487.71c0.26-0.07,0.52-0.14,0.77-0.21l0,0C698.04,487.57,697.78,487.64,697.52,487.71z"
          />
          <path
            className="st0"
            d="M704.47,397.02c-1.47,0.57-2.85,1.14-4.15,1.73C701.62,398.17,703.01,397.59,704.47,397.02L704.47,397.02z"
          />
          <path
            className="st0"
            d="M697.61,484.4c-0.06-0.62-0.13-1.26-0.2-1.93C697.48,483.14,697.55,483.78,697.61,484.4z"
          />
          <path
            className="st0"
            d="M711.01,462.79c-0.82-3.55-1.64-7.1-2.47-10.66C709.36,455.69,710.19,459.24,711.01,462.79L711.01,462.79
		L711.01,462.79z"
          />
          <path
            className="st0"
            d="M626.52,744.46c4.73-2.91,9.46-5.82,14.19-8.72c0.05-0.04,0.09-0.08,0.14-0.12c-0.05,0.04-0.09,0.08-0.14,0.12
		C635.97,738.64,631.25,741.55,626.52,744.46z"
          />
          <path
            className="st0"
            d="M776.06,450.64c-0.92,0.24-1.84,0.48-2.76,0.72c-0.53,1.48-1.07,2.96-1.6,4.44c0.53-1.48,1.07-2.96,1.6-4.44
		C774.22,451.12,775.14,450.88,776.06,450.64z"
          />
          <path
            className="st0"
            d="M696.66,400.53c1.14-0.61,2.35-1.2,3.66-1.78C699.02,399.33,697.8,399.92,696.66,400.53z"
          />
          <path
            className="st0"
            d="M589.15,752.32c0.42,0.28,0.84,0.57,1.26,0.85c2.4,1.4,4.8,2.8,7.2,4.21c-2.4-1.4-4.8-2.8-7.2-4.21
		C589.99,752.89,589.57,752.6,589.15,752.32z"
          />
          <path
            className="st0"
            d="M731.41,441.47L731.41,441.47c-0.9-1.95-1.8-3.9-2.71-5.85c-0.44-1.2-0.88-2.4-1.33-3.6
		c0.44,1.2,0.88,2.4,1.33,3.6C729.6,437.57,730.5,439.52,731.41,441.47z"
          />
          <path
            className="st0"
            d="M602.76,758.5c0.49-0.36,0.99-0.73,1.49-1.09l0,0C603.75,757.78,603.25,758.14,602.76,758.5z"
          />
          <path
            className="st0"
            d="M990.22,437.97c-1.1,1.87-1.74,4.07-2.63,6.09c-0.93,3.15-1.95,6.27-2.84,9.42c0.89-3.15,1.91-6.28,2.84-9.42
		C988.48,442.04,989.12,439.84,990.22,437.97z"
          />
          <path
            className="st0"
            d="M709.01,483.31c-0.03-0.23-0.05-0.46-0.05-0.69C708.96,482.84,708.98,483.07,709.01,483.31z"
          />
          <path
            className="st0"
            d="M709.25,437.33c-0.36-0.11-0.73-0.25-1.09-0.4C708.53,437.08,708.89,437.22,709.25,437.33z"
          />
          <path
            className="st0"
            d="M952.21,455.05c0.29,0.48,0.57,0.97,0.86,1.45c0.51,0.62,1.02,1.24,1.53,1.86c-0.51-0.62-1.02-1.24-1.53-1.86
		C952.78,456.01,952.5,455.53,952.21,455.05z"
          />
          <path
            className="st0"
            d="M958.86,456.35c-0.48-1.25-1-2.3-1.58-3.16C957.86,454.05,958.38,455.1,958.86,456.35L958.86,456.35z"
          />
          <path
            className="st0"
            d="M888.12,434.02c-0.37,0.73-0.72,1.47-1.02,2.2c-0.48,0.02-0.96,0.07-1.45,0.11c0.48-0.05,0.97-0.09,1.45-0.11
		C887.4,435.49,887.75,434.76,888.12,434.02z"
          />
          <path
            className="st0"
            d="M954.1,450.61c0.42,0.15,0.82,0.34,1.19,0.58C954.92,450.95,954.52,450.76,954.1,450.61z"
          />
          <path
            className="st0"
            d="M902.27,425.01c0.65,0.47,1.33,0.91,2.05,1.32c0.62,0.7,1.25,1.4,1.88,2.1c-0.63-0.7-1.26-1.39-1.88-2.1
		C903.6,425.91,902.91,425.47,902.27,425.01z"
          />
          <path
            className="st0"
            d="M957.71,462.14c-1.06-1.29-2.09-2.54-3.11-3.78C955.62,459.6,956.65,460.85,957.71,462.14z"
          />
          <path
            className="st0"
            d="M416.3,889.84c-2.07-0.35-4.14-0.69-6.28-1.05C412.15,889.15,414.23,889.49,416.3,889.84
		C416.3,889.84,416.3,889.84,416.3,889.84z"
          />
          <path
            className="st0"
            d="M902.27,425.01c-0.97-0.7-1.85-1.46-2.66-2.28C900.41,423.55,901.3,424.31,902.27,425.01z"
          />
          <path
            className="st0"
            d="M955.74,434.45c0.06,0.64,0.13,1.27,0.19,1.9c-0.03,0.59-0.21,1.23-0.19,1.8c-0.02-0.57,0.15-1.21,0.19-1.8
		C955.87,435.72,955.81,435.09,955.74,434.45z"
          />
          <path
            className="st0"
            d="M378.24,807.26c-0.64-0.19-1.3-0.38-1.95-0.57c-0.42-0.3-0.82-0.64-1.25-0.92c0.43,0.28,0.83,0.63,1.25,0.92
		C376.94,806.88,377.6,807.08,378.24,807.26z"
          />
          <path
            className="st0"
            d="M296.72,762.35L296.72,762.35L296.72,762.35c0,0,0.07-0.08,0.11-0.14
		C296.78,762.26,296.72,762.34,296.72,762.35z"
          />
          <path
            className="st0"
            d="M965.1,477.84c0.07-0.92,0.14-1.84,0.21-2.75c0.23-0.35,0.45-0.73,0.69-1.11c-0.23,0.37-0.46,0.75-0.69,1.11
		C965.24,476.01,965.17,476.92,965.1,477.84z"
          />
          <path
            className="st0"
            d="M966.98,472.57c-0.27,0.32-0.52,0.68-0.75,1.04C966.46,473.24,966.71,472.88,966.98,472.57z"
          />
          <path
            className="st0"
            d="M975.9,475.36c-0.26-0.27-0.53-0.54-0.82-0.81c-0.1-0.48-0.19-0.96-0.29-1.42c0.09,0.47,0.19,0.94,0.29,1.42
		C975.37,474.81,975.64,475.08,975.9,475.36z"
          />
          <path
            className="st0"
            d="M978.59,479.78c0.22,0.63,0.39,1.27,0.52,1.92C978.98,481.05,978.81,480.41,978.59,479.78z"
          />
          <path
            className="st0"
            d="M959.89,477.85c0.05-0.95,0.1-1.91,0.18-2.85C959.99,475.95,959.94,476.9,959.89,477.85z"
          />
          <path
            className="st0"
            d="M961.73,468.79c0.49-0.64,1.09-1.25,1.67-1.87C962.83,467.54,962.23,468.15,961.73,468.79z"
          />
          <path
            className="st0"
            d="M967.56,465.21c0.51-0.07,1.02-0.13,1.52-0.14C968.58,465.08,968.08,465.14,967.56,465.21z"
          />
          <path
            className="st0"
            d="M976.61,476.2c0.45,0.57,0.83,1.15,1.16,1.75C977.44,477.35,977.06,476.76,976.61,476.2z"
          />
          <path
            className="st0"
            d="M298.98,763.78C298.98,763.78,298.98,763.78,298.98,763.78c2.12,0.76,4.22,1.55,6.33,2.31
		C303.2,765.33,301.1,764.54,298.98,763.78z"
          />
          <path
            className="st0"
            d="M796.88,418.51c-0.01-0.25-0.02-0.51-0.02-0.76C796.87,418,796.87,418.26,796.88,418.51
		c1.16,1.86,2.33,3.73,3.49,5.59l0,0C799.21,422.24,798.05,420.37,796.88,418.51z"
          />
          <path
            className="st0"
            d="M882.88,418.41C882.88,418.41,882.88,418.41,882.88,418.41c-1.22-0.43-2.34-0.54-3.37-0.44
		C880.54,417.86,881.66,417.97,882.88,418.41z"
          />
          <path
            className="st0"
            d="M800.38,424.1c0.23-0.12,0.46-0.24,0.7-0.37C800.83,423.86,800.6,423.98,800.38,424.1L800.38,424.1z"
          />
          <path
            className="st0"
            d="M838.48,435.43c0.38-0.59,0.72-1.21,1.02-1.84C839.2,434.23,838.87,434.84,838.48,435.43z"
          />
          <path
            className="st0"
            d="M801.13,420.3c-1.54-0.92-2.9-1.73-4.27-2.55c-1.38-0.97-2.79-1.73-4.23-2.28c1.44,0.54,2.85,1.3,4.23,2.28
		C798.22,418.57,799.58,419.38,801.13,420.3z"
          />
          <path
            className="st0"
            d="M787.41,414.48c1.32,0.01,2.6,0.21,3.85,0.55C790.01,414.69,788.73,414.49,787.41,414.48z"
          />
          <path
            className="st0"
            d="M784.49,451.24c-0.2-0.69-0.4-1.36-0.59-2.02c-0.26-2.12-0.51-4.25-0.78-6.51c0.27,2.27,0.53,4.39,0.78,6.51
		C784.1,449.89,784.29,450.55,784.49,451.24z"
          />
          <path
            className="st0"
            d="M801.88,423.3c-0.29,0.16-0.56,0.3-0.8,0.43C801.32,423.6,801.58,423.46,801.88,423.3z"
          />
          <path
            className="st0"
            d="M800.34,424.12C800.34,424.12,800.34,424.12,800.34,424.12c-1.65,0.93-3.3,1.85-4.94,2.78
		C797.04,425.98,798.69,425.05,800.34,424.12z"
          />
          <path
            className="st0"
            d="M839.92,432.64c0.26-0.65,0.49-1.31,0.7-1.98C840.42,431.33,840.19,431.99,839.92,432.64z"
          />
          <path
            className="st0"
            d="M880.55,401.33c-0.55-0.3-1.07-0.58-1.59-0.86c-1.41-0.77-2.81-1.55-4.23-2.33c1.42,0.78,2.82,1.55,4.23,2.33
		C879.48,400.75,880,401.03,880.55,401.33z"
          />
          <path
            className="st0"
            d="M842.15,423.33c-0.42,2.48-0.81,4.97-1.53,7.33C841.33,428.3,841.72,425.81,842.15,423.33L842.15,423.33z"
          />
          <path
            className="st0"
            d="M867.6,404.31c-0.21,0.38-0.41,0.77-0.62,1.15c-0.59,0.58-1.19,1.15-1.79,1.73c0.6-0.58,1.19-1.16,1.79-1.73
		C867.19,405.08,867.4,404.7,867.6,404.31z"
          />
          <path
            className="st0"
            d="M878.08,418.26c0.36-0.11,0.72-0.19,1.1-0.25C878.81,418.07,878.44,418.15,878.08,418.26z"
          />
          <path
            className="st0"
            d="M856.44,399.2c0.22-0.5,0.44-0.99,0.65-1.49c1.15-0.81,2.29-1.61,3.41-2.39c-1.12,0.78-2.26,1.59-3.41,2.39
		C856.88,398.21,856.66,398.71,856.44,399.2z"
          />
          <path
            className="st0"
            d="M829.95,439.93c-0.84,0.8-1.68,1.6-2.52,2.4C828.27,441.53,829.11,440.73,829.95,439.93L829.95,439.93z"
          />
          <path
            className="st0"
            d="M877.22,408.42L877.22,408.42c-3.41-0.99-6.82-1.97-10.23-2.96C870.4,406.45,873.81,407.44,877.22,408.42z"
          />
          <path
            className="st0"
            d="M856.44,399.2c-0.54,1.24-1.05,2.49-1.36,3.74C855.39,401.69,855.9,400.45,856.44,399.2z"
          />
          <path
            className="st0"
            d="M713.77,483.25c0.46,0.37,1.14,0.73,2.07,1.17l0,0C714.91,483.97,714.23,483.61,713.77,483.25z"
          />
          <path
            className="st0"
            d="M458.73,488.75c7.79-3.95,15.58-7.9,23.37-11.85c0.37-0.29,0.71-0.67,1.13-0.87c-0.42,0.2-0.76,0.57-1.13,0.87
		C474.31,480.85,466.52,484.8,458.73,488.75z"
          />
          <path
            className="st0"
            d="M438.74,448.69c0,6.13,0,12.27,0,18.42c0.05,1.7,0.1,3.39,0.15,5.09c-0.05-1.7-0.11-3.39-0.15-5.09
		C438.74,460.97,438.74,454.82,438.74,448.69z"
          />
          <path
            className="st0"
            d="M589.27,403.11c-1.45,0.89-2.91,1.76-4.39,2.61C586.36,404.87,587.82,404,589.27,403.11z"
          />
          <path
            className="st0"
            d="M606.35,391.9c-1.42,0.94-2.83,1.89-4.25,2.84C603.52,393.79,604.93,392.84,606.35,391.9z"
          />
          <path
            className="st0"
            d="M546.65,486.43c-0.31-0.31-0.63-0.63-0.94-0.94C546.03,485.8,546.34,486.11,546.65,486.43
		C546.65,486.43,546.65,486.43,546.65,486.43z"
          />
          <path
            className="st0"
            d="M445.63,483.53c0.37-12.82,0.73-25.63,1.1-38.51C446.36,457.89,446,470.71,445.63,483.53L445.63,483.53z"
          />
          <path
            className="st0"
            d="M536.14,485.7L536.14,485.7c-1.98-0.89-3.96-1.78-5.94-2.67C532.18,483.92,534.16,484.81,536.14,485.7
		C536.14,485.7,536.14,485.7,536.14,485.7L536.14,485.7C536.14,485.7,536.14,485.7,536.14,485.7z"
          />
          <path
            className="st0"
            d="M444.39,432.85c-1.04-0.46-2.08-0.92-3.28-1.45C442.31,431.93,443.35,432.39,444.39,432.85
		C444.39,432.85,444.39,432.85,444.39,432.85z"
          />
          <path
            className="st0"
            d="M545.91,481.74c0.42,0.12,0.85,0.24,1.27,0.36C546.76,481.98,546.34,481.86,545.91,481.74
		c-0.03,0.02-0.06,0.05-0.09,0.07C545.86,481.78,545.88,481.76,545.91,481.74z"
          />
          <path
            className="st0"
            d="M536.61,319.41c0.1-0.01,0.19-0.02,0.3-0.02C536.81,319.39,536.71,319.4,536.61,319.41z"
          />
          <path
            className="st0"
            d="M696.31,221.56c-0.24,0.15-0.48,0.31-0.72,0.41C695.83,221.87,696.07,221.71,696.31,221.56z"
          />
          <path
            className="st0"
            d="M619.31,225.5c-4.29,3.61-8.61,7.19-12.86,10.86C610.7,232.69,615.02,229.11,619.31,225.5
		C619.31,225.5,619.31,225.5,619.31,225.5z"
          />
          <path
            className="st0"
            d="M546.85,486.51C546.85,486.51,546.85,486.51,546.85,486.51c1.82-0.38,3.65-0.76,5.71-1.19
		C550.5,485.75,548.67,486.13,546.85,486.51z"
          />
          <path
            className="st0"
            d="M583.34,247.93L583.34,247.93c-0.96,0.95-1.93,1.9-2.89,2.84v0C581.41,249.83,582.37,248.88,583.34,247.93z"
          />
          <path
            className="st0"
            d="M455.3,485.5c7.62-3.81,15.25-7.62,22.87-11.43C470.55,477.88,462.92,481.69,455.3,485.5
		c-1.85,1.06-3.7,2.12-5.8,3.33C451.6,487.62,453.45,486.56,455.3,485.5L455.3,485.5z"
          />
          <path
            className="st0"
            d="M533.94,283.69c-0.31-0.35-0.6-0.84-0.9-1.25C533.33,282.85,533.63,283.34,533.94,283.69
		c3.83,3.39,7.7,6.73,11.51,10.15C541.64,290.43,537.77,287.08,533.94,283.69z"
          />
          <path
            className="st0"
            d="M506.71,485c-0.48,0.43-0.95,0.86-1.43,1.29C505.76,485.86,506.23,485.43,506.71,485L506.71,485z"
          />
          <path
            className="st0"
            d="M604.12,239.22c-3.49,0.21-6.99,0.42-10.57,0.64C597.13,239.64,600.62,239.43,604.12,239.22
		c0.58-0.72,1.09-1.52,1.69-2.22C605.21,237.7,604.7,238.5,604.12,239.22z"
          />
          <path
            className="st0"
            d="M696.67,221.35c1.16,0,2.33,0,3.49,0C699,221.35,697.83,221.35,696.67,221.35c-0.12,0.06-0.24,0.13-0.36,0.21
		C696.43,221.49,696.55,221.41,696.67,221.35z"
          />
          <path
            className="st0"
            d="M545.47,484.83c-0.57-0.32-1.15-0.64-1.72-0.96c-0.22-0.32-0.43-0.64-0.66-0.98c0.23,0.34,0.44,0.66,0.66,0.98
		C544.32,484.19,544.89,484.51,545.47,484.83z"
          />
          <path
            className="st0"
            d="M565.57,463.75c-1,0.15-1.87,0.55-2.18,2c0,0,0,0,0,0C563.7,464.3,564.57,463.9,565.57,463.75z"
          />
          <path
            className="st0"
            d="M511.15,502.05L511.15,502.05L511.15,502.05c-0.25,0.2-0.5,0.41-0.76,0.61
		C510.65,502.46,510.9,502.25,511.15,502.05z"
          />
          <path
            className="st0"
            d="M561.44,465.79c0.54,0,1.2,0,1.86,0c0,0,0,0,0,0C562.64,465.79,561.98,465.79,561.44,465.79z"
          />
          <path
            className="st0"
            d="M554.76,463.51C554.76,463.51,554.76,463.51,554.76,463.51c0-1.36,0-2.72,0-4.02
		C554.77,460.79,554.77,462.15,554.76,463.51z"
          />
          <path
            className="st0"
            d="M567.57,463.46c-0.68-0.75-1.36-1.49-1.84-2.03C566.21,461.96,566.89,462.7,567.57,463.46
		C567.57,463.45,567.57,463.45,567.57,463.46C567.57,463.46,567.57,463.46,567.57,463.46z"
          />
          <path
            className="st0"
            d="M508.9,483.22c0.25-0.21,0.51-0.41,0.77-0.6C509.41,482.8,509.15,483.01,508.9,483.22z"
          />
          <path
            className="st0"
            d="M538,503.6c-1.79-1.52-3.56-3.02-5.34-4.53c0.2-0.16,0.39-0.33,0.58-0.49c-0.19,0.16-0.39,0.33-0.58,0.49
		C534.43,500.58,536.21,502.09,538,503.6z"
          />
          <path
            className="st0"
            d="M698.94,346.26c0.23-0.16,0.46-0.31,0.69-0.47l0.13-0.1c-0.07,0.05-0.13,0.1-0.13,0.1
		C699.4,345.94,699.17,346.1,698.94,346.26z"
          />
          <path
            className="st0"
            d="M542.45,500.85c0.06-0.07,0.12-0.14,0.17-0.2C542.57,500.72,542.51,500.78,542.45,500.85L542.45,500.85
		c-0.21,0.17-0.42,0.34-0.62,0.51C542.04,501.19,542.25,501.02,542.45,500.85z"
          />
          <path
            className="st0"
            d="M607.2,425.01c1.4-0.57,2.73-1.11,4.07-1.66c0,0,0,0,0,0C609.94,423.9,608.6,424.44,607.2,425.01z"
          />
          <path
            className="st0"
            d="M642.43,399.3c0.15,0.16,0.29,0.33,0.43,0.5C642.72,399.63,642.58,399.47,642.43,399.3L642.43,399.3z"
          />
          <path
            className="st0"
            d="M637.86,402.78c0.6-0.34,1.2-0.68,1.8-1.02l0,0C639.07,402.1,638.47,402.44,637.86,402.78z"
          />
          <path
            className="st0"
            d="M641.25,399.97c-0.69,0.39-1.38,0.78-2.07,1.18l0,0C639.87,400.76,640.56,400.37,641.25,399.97z"
          />
          <path
            className="st0"
            d="M536.16,485.83c0.01,0.1,0.02,0.16,0.02,0.13C536.17,485.94,536.17,485.9,536.16,485.83
		C536.16,485.83,536.16,485.83,536.16,485.83z"
          />
          <path
            className="st0"
            d="M611.27,423.36c0.08-0.21,0.17-0.41,0.24-0.6C611.43,422.95,611.35,423.15,611.27,423.36
		C611.27,423.36,611.27,423.36,611.27,423.36z"
          />
          <path
            className="st0"
            d="M546.31,464.65C546.31,464.65,546.31,464.65,546.31,464.65c0.46-0.4,0.91-0.8,1.12-0.99
		C547.22,463.85,546.77,464.25,546.31,464.65z"
          />
          <path
            className="st0"
            d="M652.25,387.73c-0.91,1.16-1.77,2.25-2.63,3.34c0,0,0,0,0,0C650.48,389.99,651.33,388.9,652.25,387.73z"
          />
          <path
            className="st0"
            d="M622.86,410.46c0.31,0.29,0.63,0.59,0.99,0.93C623.49,411.06,623.17,410.75,622.86,410.46z"
          />
          <path
            className="st0"
            d="M506.72,485.01L506.72,485.01c0.05-0.04,0.1-0.08,0.15-0.11C506.82,484.93,506.77,484.97,506.72,485.01z"
          />
          <path
            className="st0"
            d="M559.39,655.9c1.09-0.58,2.19-1.17,3.29-1.75c0.41-0.16,0.82-0.31,1.23-0.46c-0.41,0.15-0.82,0.3-1.23,0.46
		C561.58,654.73,560.49,655.32,559.39,655.9z"
          />
          <path
            className="st0"
            d="M573.44,411.45c-2.44,1.43-4.88,2.86-7.32,4.3C568.56,414.32,571,412.88,573.44,411.45
		c2.37-1.01,4.69-2.11,6.96-3.29C578.13,409.34,575.81,410.45,573.44,411.45L573.44,411.45z"
          />
          <path
            className="st0"
            d="M606.35,391.9c0.71-0.47,1.42-0.94,2.13-1.41c9.99-5.56,19.98-11.12,29.97-16.69
		c-9.99,5.56-19.98,11.12-29.97,16.69C607.77,390.96,607.06,391.43,606.35,391.9z"
          />
          <path
            className="st0"
            d="M661.44,361.01c1.44-0.68,2.88-1.37,4.32-2.06c1.19-0.72,2.39-1.44,3.58-2.16c-1.19,0.72-2.39,1.44-3.58,2.16
		C664.32,359.64,662.88,360.32,661.44,361.01z"
          />
          <path
            className="st0"
            d="M661.44,361.01L661.44,361.01c-0.32-1.29-0.63-2.58-0.95-3.87C660.8,358.43,661.12,359.72,661.44,361.01z"
          />
          <path
            className="st0"
            d="M493.62,619.63c1.24,0.54,2.47,1.08,3.71,1.62c2.57,1.2,5.14,2.39,7.66,3.56c-2.52-1.17-5.09-2.37-7.66-3.56
		C496.09,620.71,494.85,620.17,493.62,619.63z"
          />
          <path
            className="st0"
            d="M486.66,615.17c0.39-0.55,0.77-1.11,1.16-1.65C487.43,614.06,487.05,614.62,486.66,615.17z"
          />
          <path
            className="st0"
            d="M526.78,593.89L526.78,593.89c3.36,1.9,6.71,3.8,10.07,5.7C533.49,597.69,530.14,595.79,526.78,593.89z"
          />
          <path
            className="st0"
            d="M485.87,616.24C485.87,616.24,485.87,616.24,485.87,616.24c0.08-0.11,0.16-0.22,0.24-0.32
		C486.03,616.03,485.95,616.14,485.87,616.24z"
          />
          <path
            className="st0"
            d="M698.94,346.26c-0.42,0.4-0.85,0.81-1.29,1.23C698.1,347.06,698.52,346.66,698.94,346.26
		c0.23-0.16,0.46-0.31,0.69-0.47C699.4,345.94,699.17,346.1,698.94,346.26z"
          />
          <path
            className="st0"
            d="M641.46,598.66c2.06,1.2,4.13,2.4,6.2,3.6C645.6,601.06,643.53,599.87,641.46,598.66z"
          />
          <path
            className="st0"
            d="M699.9,345.59c0,0-0.07,0.05-0.13,0.1L699.9,345.59z"
          />
          <path
            className="st0"
            d="M676.95,620.32c3.81,2.34,7.61,4.67,11.46,7.03C684.57,624.99,680.76,622.65,676.95,620.32
		c-5.81-4.06-11.63-8.13-17.43-12.18C665.32,612.19,671.14,616.25,676.95,620.32z"
          />
          <path
            className="st0"
            d="M512.46,446.43c1.18-0.74,2.36-1.48,3.54-2.22c2.34-1.3,4.69-2.6,7.04-3.89c-2.35,1.29-4.7,2.59-7.04,3.89
		C514.82,444.95,513.64,445.69,512.46,446.43z"
          />
          <path
            className="st0"
            d="M524.74,636.22c-0.04-0.02-0.09-0.04-0.12-0.06L524.74,636.22z"
          />
          <path
            className="st0"
            d="M523.33,635.5c-0.04-0.02-0.09-0.04-0.12-0.05L523.33,635.5z"
          />
          <path
            className="st0"
            d="M669.34,356.79c1.43-0.95,2.87-1.9,4.3-2.85c0.35-0.18,0.7-0.35,1.06-0.53c-0.35,0.18-0.71,0.35-1.06,0.53
		C672.2,354.89,670.77,355.84,669.34,356.79z"
          />
          <path
            className="st0"
            d="M522.37,634.86c-0.22-0.27-0.44-0.53-0.65-0.8C521.93,634.33,522.15,634.6,522.37,634.86
		C522.37,634.86,522.37,634.86,522.37,634.86z"
          />
          <path
            className="st0"
            d="M643.33,210.59c0.09,0.24,0.17,0.44,0.24,0.63l0,0C643.5,211.03,643.42,210.83,643.33,210.59z"
          />
          <path
            className="st0"
            d="M580.51,308.18c15.75-0.76,31.51-1.52,47.26-2.29c1.56,0.2,3.13,0.41,4.69,0.61c-1.57-0.2-3.13-0.41-4.69-0.61
		C612.01,306.66,596.26,307.42,580.51,308.18z"
          />
          <path
            className="st0"
            d="M623.31,315.56c-1.63-0.12-3.26-0.23-4.89-0.35c-0.21-0.09-0.42-0.21-0.63-0.32c0.21,0.11,0.42,0.23,0.63,0.32
		C620.05,315.33,621.68,315.44,623.31,315.56z"
          />
          <path
            className="st0"
            d="M609.89,289.32C609.89,289.32,609.89,289.32,609.89,289.32c1.63-0.1,3.25-0.22,4.88-0.33
		C613.14,289.1,611.52,289.22,609.89,289.32z"
          />
          <rect x="608.37" y="472.28" className="st0" width="0" height="0" />
          <polygon
            className="st0"
            points="508.13,444.19 508.13,444.19 508.13,444.19 	"
          />
          <path
            className="st0"
            d="M569.81,281.7c-0.64,0.46-0.94,1.27-0.82,2.61c0,0,0,0,0,0C568.87,282.97,569.17,282.16,569.81,281.7z"
          />
          <path
            className="st0"
            d="M568.33,259.31c-0.22-0.19-0.45-0.37-0.75-0.63C567.88,258.94,568.11,259.12,568.33,259.31
		c3.61-0.41,7.22-0.82,10.93-1.25C575.55,258.49,571.94,258.9,568.33,259.31z"
          />
          <path
            className="st0"
            d="M649.25,224.35c-4.76,0.23-9.52,0.47-14.28,0.7C639.73,224.82,644.49,224.58,649.25,224.35
		c4.12-0.26,8.24-0.54,12.35-0.8C657.48,223.81,653.37,224.09,649.25,224.35z"
          />
          <path
            className="st0"
            d="M549.28,319.03c-3.22,0.13-6.44,0.26-9.65,0.38C542.84,319.29,546.06,319.16,549.28,319.03L549.28,319.03z"
          />
          <path
            className="st0"
            d="M536.33,319.48c-0.45,0.15-0.8,0.56-0.97,1.48c-3.18,1.55-6.35,3.11-9.55,4.67c3.2-1.56,6.38-3.12,9.55-4.67
		C535.53,320.04,535.89,319.63,536.33,319.48z"
          />
          <path
            className="st0"
            d="M683.18,210.65c-11.56,0.4-23.13,0.81-34.69,1.23l0,0C660.05,211.46,671.62,211.05,683.18,210.65z"
          />
          <path
            className="st0"
            d="M536.85,599.59c0.27,0.15,0.55,0.29,0.83,0.44C537.4,599.88,537.12,599.74,536.85,599.59
		c-0.4-0.12-0.79-0.24-1.19-0.36C536.06,599.35,536.45,599.46,536.85,599.59z"
          />
          <path
            className="st0"
            d="M526.79,593.9c-0.71-0.26-1.43-0.52-2.14-0.77c0,0,0,0,0,0C525.36,593.39,526.08,593.64,526.79,593.9z"
          />
          <rect x="508.1" y="444.21" className="st0" width="0" height="0" />
          <path
            className="st0"
            d="M524.65,593.13L524.65,593.13c-1.45-1.02-2.9-2.03-4.35-3.05c-0.63-0.41-1.25-0.82-1.88-1.23
		c0.63,0.41,1.25,0.82,1.88,1.23C521.75,591.1,523.2,592.11,524.65,593.13z"
          />
          <path
            className="st0"
            d="M566.1,490.88c-1.36-0.51-2.7-1.08-4.08-1.51C563.4,489.8,564.74,490.37,566.1,490.88
		C566.1,490.88,566.1,490.88,566.1,490.88z"
          />
          <path
            className="st0"
            d="M578.3,228.52L578.3,228.52c0.72,1.02,1.44,2.05,2.16,3.08C579.74,230.57,579.02,229.55,578.3,228.52z"
          />
          <path
            className="st0"
            d="M569.66,233.5c1.05-0.61,2.11-1.21,3.16-1.82C571.77,232.29,570.72,232.89,569.66,233.5
		c-0.83,0.48-1.65,0.95-2.48,1.43c-1.97,1.1-3.97,2.13-5.92,3.25c1.95-1.12,3.95-2.16,5.92-3.25
		C568.01,234.45,568.84,233.98,569.66,233.5z"
          />
          <path
            className="st0"
            d="M641.93,307.74c-3.18-0.41-6.33-0.82-9.46-1.23C635.6,306.91,638.74,307.32,641.93,307.74z"
          />
          <path
            className="st0"
            d="M1049.25,489.31c-1.45-3.1-2.92-6.2-4.37-9.31l0,0C1046.33,483.11,1047.8,486.21,1049.25,489.31z"
          />
          <path
            className="st0"
            d="M313.34,829.49c-0.15,0.13-0.32,0.25-0.49,0.35C313.03,829.74,313.19,829.62,313.34,829.49z"
          />
          <path
            className="st0"
            d="M1061.66,532.99c-0.44-0.19-0.89-0.38-1.33-0.57C1060.77,532.61,1061.21,532.8,1061.66,532.99z"
          />
          <path
            className="st0"
            d="M312.85,829.84c-0.36,0.21-0.77,0.37-1.23,0.48C312.08,830.21,312.49,830.05,312.85,829.84z"
          />
          <path
            className="st0"
            d="M1062.61,501.45L1062.61,501.45c-0.7,1.14-1.46,2.25-2.19,3.37C1061.15,503.7,1061.91,502.59,1062.61,501.45
		C1062.61,501.45,1062.61,501.45,1062.61,501.45z"
          />
          <polygon
            className="st0"
            points="1102.83,484.32 1102.83,484.32 1102.83,484.32 	"
          />
          <path
            className="st0"
            d="M312.68,820.94c-0.24,2.16-0.49,4.32-0.75,6.63C312.19,825.27,312.43,823.11,312.68,820.94
		C312.68,820.94,312.68,820.94,312.68,820.94z"
          />
          <path
            className="st0"
            d="M1057.45,531.41c-1.65-0.55-3.31-1.1-5.03-1.66C1054.13,530.32,1055.8,530.87,1057.45,531.41z"
          />
          <path
            className="st0"
            d="M1059.92,532.23c-0.82-0.27-1.64-0.54-2.47-0.82C1058.27,531.69,1059.09,531.96,1059.92,532.23L1059.92,532.23
		c0.56-1.85,1.18-3.69,1.71-5.55C1061.09,528.54,1060.48,530.38,1059.92,532.23z"
          />
          <path
            className="st0"
            d="M272.98,810.4c-0.2-0.27-0.4-0.54-0.6-0.81c0,0,0,0,0,0C272.58,809.85,272.78,810.13,272.98,810.4z"
          />
          <path
            className="st0"
            d="M1054.32,511.91c0.12,0.14,0.23,0.28,0.35,0.42c0,0,0,0,0,0C1054.55,512.19,1054.44,512.05,1054.32,511.91z"
          />
          <path
            className="st0"
            d="M1018.5,467.27c-0.1,0.18-0.21,0.35-0.31,0.53c-0.21,0.02-0.41,0.02-0.62,0c0.21,0.02,0.41,0.02,0.62,0
		C1018.3,467.62,1018.4,467.45,1018.5,467.27z"
          />
          <path
            className="st0"
            d="M1063.69,533.9c-0.32-0.16-0.65-0.3-0.97-0.45C1063.04,533.6,1063.37,533.74,1063.69,533.9z"
          />
          <path
            className="st0"
            d="M1046.55,574.46L1046.55,574.46c-0.22-0.59-0.53-1.2-0.81-1.81C1046.03,573.25,1046.33,573.86,1046.55,574.46z
		"
          />
          <path
            className="st0"
            d="M1064.66,534.42c-0.32-0.18-0.64-0.36-0.98-0.52C1064.02,534.06,1064.35,534.24,1064.66,534.42z"
          />
          <path
            className="st0"
            d="M1052.02,537.36c1.67-0.69,3.33-1.38,5-2.07c1.27,0.56,2.54,1.11,3.84,1.68c-1.3-0.57-2.57-1.12-3.84-1.68
		C1055.35,535.98,1053.68,536.67,1052.02,537.36L1052.02,537.36z"
          />
          <path
            className="st0"
            d="M269.63,810.66c1.18,0.74,2.35,1.47,3.51,2.2v0C271.97,812.13,270.81,811.4,269.63,810.66z"
          />
          <path
            className="st0"
            d="M1112.16,401.78C1112.16,401.78,1112.16,401.78,1112.16,401.78c-0.49,1.51-0.91,3.04-1.42,4.54
		C1111.25,404.82,1111.67,403.28,1112.16,401.78z"
          />
          <path
            className="st0"
            d="M1125.03,511.75c-1.92-0.75-3.86-1.5-5.8-2.26c-0.34-0.1-0.67-0.21-1.01-0.32c0.33,0.11,0.67,0.23,1.01,0.32
		C1121.17,510.24,1123.11,511,1125.03,511.75z"
          />
          <path
            className="st0"
            d="M1073.27,446.14c-0.39-0.48-0.76-0.95-1.08-1.35C1072.52,445.19,1072.89,445.65,1073.27,446.14z"
          />
          <path
            className="st0"
            d="M1114.67,379.2c0.61,0.25,1.1,0.45,1.59,0.65c0.15,0.12,0.31,0.23,0.45,0.35c-0.15-0.11-0.3-0.23-0.45-0.35
		C1115.77,379.65,1115.28,379.45,1114.67,379.2z"
          />
          <path
            className="st0"
            d="M1094.31,401.97c-0.07,0.79-0.13,1.59-0.18,2.38C1094.19,403.56,1094.24,402.76,1094.31,401.97L1094.31,401.97
		z"
          />
          <path
            className="st0"
            d="M1120.74,493.63c0.12,0.14,0.25,0.32,0.37,0.5C1120.99,493.96,1120.86,493.77,1120.74,493.63L1120.74,493.63
		c-0.82-0.57-1.63-1.14-2.6-1.82C1119.11,492.5,1119.93,493.06,1120.74,493.63z"
          />
          <path
            className="st0"
            d="M1074.66,452.22c-0.11-0.97-0.22-1.92-0.33-2.86C1074.44,450.3,1074.55,451.25,1074.66,452.22z"
          />
          <path
            className="st0"
            d="M1099.88,410.39C1099.88,410.39,1099.88,410.39,1099.88,410.39c-1.63,0.36-2.28-0.06-2.54-0.79
		C1097.6,410.33,1098.25,410.75,1099.88,410.39z"
          />
          <path
            className="st0"
            d="M1069.89,501.26c0.45-0.57,0.96-1.13,1.51-1.66C1070.85,500.12,1070.35,500.68,1069.89,501.26z"
          />
          <path
            className="st0"
            d="M1069.89,501.26c-2.26,2.87-3.13,6.15-1.59,8c1.42-0.6,2.84-1.19,4.33-1.82c-1.49,0.63-2.91,1.22-4.33,1.82
		C1066.76,507.4,1067.63,504.13,1069.89,501.26z"
          />
          <path
            className="st0"
            d="M283.12,818.82c3.27,1.73,6.53,3.47,9.8,5.2c2.16,1.34,4.32,2.68,6.69,4.16c-2.37-1.47-4.53-2.82-6.69-4.16
		C289.65,822.29,286.39,820.55,283.12,818.82z"
          />
          <path
            className="st0"
            d="M1154.39,415.57L1154.39,415.57c0.49,0.33,0.98,0.67,1.46,1c0.63,0.47,1.22,1,1.86,1.44
		c-0.64-0.44-1.23-0.98-1.86-1.44C1155.37,416.24,1154.88,415.91,1154.39,415.57z"
          />
          <path
            className="st0"
            d="M1097.5,508.37c0.87,0.02,1.72-0.1,2.5-0.39l0,0C1099.23,508.27,1098.37,508.39,1097.5,508.37z"
          />
          <path
            className="st0"
            d="M1120.53,422.13C1120.53,422.13,1120.53,422.13,1120.53,422.13c0.16-1.99,0.27-3.99,0.42-5.99
		C1120.8,418.14,1120.68,420.14,1120.53,422.13L1120.53,422.13z"
          />
          <path
            className="st0"
            d="M1082.07,482.79c-0.1,0.94-0.31,1.91-0.62,2.91c0,0,0,0,0,0C1081.75,484.7,1081.96,483.73,1082.07,482.79z"
          />
          <path
            className="st0"
            d="M1159.39,422.22c0.78,0.6,1.55,1.21,2.33,1.79c0.37,0.16,0.74,0.32,1.12,0.48c-0.37-0.16-0.74-0.32-1.12-0.48
		C1160.94,423.43,1160.17,422.81,1159.39,422.22z"
          />
          <path
            className="st0"
            d="M1150.81,413.44L1150.81,413.44c-4.55-3.11-9.09-6.21-13.64-9.32
		C1141.72,407.22,1146.26,410.33,1150.81,413.44z"
          />
          <path
            className="st0"
            d="M314.57,827.18c-0.15,0.94-0.55,1.74-1.23,2.31C314.03,828.91,314.42,828.11,314.57,827.18z"
          />
          <path
            className="st0"
            d="M1054.83,503.65c-0.31-1.25-0.48-2.57-0.84-3.8C1054.35,501.08,1054.52,502.4,1054.83,503.65
		C1054.83,503.65,1054.83,503.65,1054.83,503.65z"
          />
          <path
            className="st0"
            d="M375.61,860.84c-0.04-0.59-0.07-1.17-0.11-1.76v0C375.53,859.66,375.57,860.25,375.61,860.84z"
          />
          <path
            className="st0"
            d="M1048.99,500.72c0.75,0.26,1.5,0.52,2.25,0.78C1050.49,501.24,1049.74,500.98,1048.99,500.72L1048.99,500.72z"
          />
          <path
            className="st0"
            d="M959.66,482.14c0.11-1.42,0.17-2.86,0.24-4.29C959.82,479.28,959.77,480.72,959.66,482.14
		c0.05,0.31,0.09,0.61,0.14,0.92C959.75,482.76,959.7,482.45,959.66,482.14z"
          />
          <path
            className="st0"
            d="M375.83,864.38c0.07,1.2,0.15,2.41,0.23,3.65C375.98,866.8,375.9,865.58,375.83,864.38z"
          />
          <path
            className="st0"
            d="M959.79,483.06c-0.04-0.1-0.09-0.2-0.13-0.3C959.71,482.86,959.75,482.96,959.79,483.06l0.03,0.11
		c1.34,4.97,2.54,9.98,3.95,14.92c-1.41-4.94-2.61-9.96-3.95-14.92C959.83,483.18,959.79,483.06,959.79,483.06z"
          />
          <path
            className="st0"
            d="M296.88,762.14L296.88,762.14c0,0-0.01,0.01-0.01,0.02C296.87,762.15,296.88,762.14,296.88,762.14z"
          />
          <path
            className="st0"
            d="M298.7,763.6c-0.27-0.22-0.54-0.44-0.82-0.66C298.16,763.16,298.43,763.38,298.7,763.6
		C298.7,763.6,298.7,763.6,298.7,763.6z"
          />
          <path
            className="st0"
            d="M964.5,465.67c1.01-0.1,2.05-0.31,3.07-0.46C966.54,465.36,965.51,465.58,964.5,465.67
		c-0.17,0.21-0.34,0.42-0.53,0.63C964.15,466.09,964.33,465.88,964.5,465.67C964.5,465.67,964.5,465.67,964.5,465.67z"
          />
          <path
            className="st1"
            d="M1181.87,445.01c0.05-4.85,0.1-9.7,0.15-14.56c-0.04,4.21-0.09,8.41-0.13,12.62
		C1181.88,443.72,1181.88,444.36,1181.87,445.01z"
          />
          <rect x="567.52" y="738.74" className="st2" width="0" height="0" />
          <polygon
            className="st2"
            points="422.68,699.44 422.68,699.44 422.68,699.44 	"
          />
          <path
            className="st2"
            d="M410.63,658.8C410.92,659.24,410.84,659,410.63,658.8L410.63,658.8z"
          />
          <polygon
            className="st2"
            points="1052.02,537.36 1052.02,537.36 1052.02,537.36 	"
          />
          <path
            className="st2"
            d="M499.74,375.64c-0.04-0.33-0.08-0.66-0.12-0.98c0.17,1.47,0.35,2.94,0.52,4.41h0
		C500.01,377.93,499.88,376.79,499.74,375.64z"
          />
          <polygon
            className="st2"
            points="997.55,311.62 997.55,311.62 997.55,311.62 997.55,311.62 	"
          />
          <path
            className="st2"
            d="M522.37,634.86c-0.22-0.27-0.44-0.53-0.65-0.8C521.93,634.33,522.15,634.6,522.37,634.86
		C522.37,634.86,522.37,634.86,522.37,634.86C522.37,634.86,522.37,634.86,522.37,634.86C522.37,634.86,522.37,634.86,522.37,634.86
		z"
          />
          <polygon className="st2" points="910.02,278 910.02,278 910.02,278 	" />
          <polygon
            className="st2"
            points="524.65,593.13 524.65,593.13 524.65,593.13 524.65,593.13 	"
          />
          <path
            className="st2"
            d="M563.3,465.71c0,0,0.02,0.02,0.04,0.04L563.3,465.71z"
          />
          <polygon className="st2" points="882,269.55 882,269.55 882,269.55 	" />
          <polygon
            className="st2"
            points="965.23,311.68 965.23,311.68 965.23,311.68 965.23,311.68 	"
          />
          <path
            className="st2"
            d="M198.91,861.44c0.39-0.29,0.78-0.59,1.17-0.88c-0.19,0.15-0.39,0.29-0.58,0.44S199.1,861.29,198.91,861.44z"
          />
          <polygon
            className="st2"
            points="314.53,510.77 314.53,510.77 314.53,510.77 314.53,510.77 	"
          />
          <path
            className="st2"
            d="M889.26,211.41c0,0,0.02-0.04,0.04-0.06L889.26,211.41z"
          />
          <polygon
            className="st2"
            points="314.57,510.73 314.57,510.73 314.57,510.73 314.57,510.73 	"
          />
          <polygon
            className="st2"
            points="997.55,311.62 997.55,311.62 997.55,311.62 997.55,311.62 	"
          />
          <path
            className="st2"
            d="M1167.91,354.88c-4.85-2.6-10.05-4.85-14.28-8.25c-12.29-9.88-26.89-15.64-40.39-23.33
		c-16.05-9.14-32.27-18.17-49.12-25.68c-12.17-5.43-22.55-13.44-34.05-19.75c-6.86-3.76-13.54-7.89-20.16-12.07
		c-13.48-8.5-26.91-17.07-40.27-25.77c-11.79-7.68-23.32-15.79-35.21-23.31c-15.13-9.57-30.46-18.82-45.79-28.07
		c-8.29-5-16.85-9.57-25.12-14.6c-10.78-6.56-21.39-13.39-32.08-20.1c0-2.71,0-5.52,0-8.84c9.99,5.88,19.74,11.35,29.22,17.26
		c21.68,13.51,42.66,28.1,65.41,39.93c12.35,6.42,23.78,14.59,35.64,21.95c20,12.42,40.08,24.7,59.98,37.27
		c12.56,7.93,24.59,16.73,37.34,24.32c18.91,11.25,38.28,21.72,57.39,32.63c14.92,8.52,29.75,17.19,44.6,25.82
		c7.84,4.55,15.65,9.15,23.48,13.73c1.03,0.38,2.06,0.77,3.57,1.33c0.5,0.19,1.06,0.39,1.68,0.63c-0.47-2.67-0.73-4.6-1.16-6.48
		c-0.17-0.73-1.03-1.37-1.06-2.07c-0.14-3.7-0.03-7.4-0.17-11.1c-0.43-10.57-1.27-21.13-1.31-31.69c-0.03-8.89,1.2-17.79,1.23-26.69
		c0.06-15.37-0.21-30.74-0.66-46.11c-0.51-17.3-1.52-34.58-2.06-51.88c-0.29-9.3-0.05-18.63-0.05-27.94
		c0-6.13-0.35-12.28,0.11-18.37c0.59-7.93,2.47-15.8,2.62-23.71c0.27-14.28-0.33-28.58-0.58-42.87c-0.01-0.59-0.12-1.18-0.15-1.77
		c-0.19-3.78-1.02-7.67-0.34-11.29c0.5-2.66-0.54-3.9-2.2-4.11c-4.43-0.56-8.94-0.7-13.41-0.69c-8.67,0.02-17.35,0.36-26.02,0.37
		c-7.24,0.01-14.48-0.37-21.72-0.4c-8.09-0.03-16.19,0.52-24.26,0.14c-8.7-0.41-17.35-1.99-26.05-2.34
		c-7.83-0.31-15.69,0.33-23.54,0.6c-12.84,0.45-25.68,0.93-38.51,1.44c-4.51,0.18-9.02,0.47-13.53,0.72
		c-12.56,0.71-25.12,1.71-37.69,2.04c-9.28,0.24-18.58-0.68-27.86-0.55c-12.73,0.19-25.46,1.39-38.17,1.24
		c-17.46-0.22-34.9-1.7-52.36-1.9c-13.65-0.15-27.3,1.02-40.96,1.23c-10.37,0.16-20.75-0.5-31.13-0.37
		c-13.93,0.17-27.86,0.78-41.79,1.15c-10,0.27-20,0.39-29.99,0.75c-10.09,0.36-20.18,0.91-30.27,1.42
		c-13.99,0.7-27.97,1.58-41.97,2.11c-22.37,0.85-44.75,1.74-67.13,2.07c-11.51,0.17-23.04-1.15-34.57-1.25
		c-23.95-0.21-47.91-0.2-71.86,0c-9.96,0.08-19.91,0.89-29.86,1.37c-0.35,0.02-0.71,0.1-1.06,0.14c-5.84,0.67-11.67,1.75-17.52,1.9
		c-7.11,0.19-14.25-0.4-21.37-0.66c-0.47-0.02-0.95-0.16-1.42-0.16c-7.84,0.09-15.69-0.11-23.5,0.39
		c-8.8,0.56-17.54,2.03-26.34,2.58c-5.07,0.32-10.2-0.61-15.3-0.57c-13.35,0.1-26.69,0.54-40.04,0.62
		c-6.17,0.04-12.35-0.65-18.53-0.62c-9.76,0.05-19.51,0.48-29.27,0.65c-15.61,0.28-31.23,0.49-46.84,0.74
		c-0.71,0.01-1.43,0.14-2.14,0.14c-9.03-0.06-18.07-0.09-27.1-0.21c-9.52-0.13-19.04-0.24-28.55-0.59
		c-6.19-0.22-12.48-0.24-18.53-1.42C92.5,63.78,81.31,62,69.76,63.6c-3.98,0.55-8.11,0.06-12.17,0.14
		c-6.8,0.15-13.59,0.43-20.39,0.56c-4.65,0.09-9.3,0.02-13.9,0.02c-0.24-1.04-0.43-1.9-0.6-2.61c-1.47,0-2.65,0-2.83,0
		c-1.66,1.89-2.6,3.64-4.05,4.44c-2.67,1.48-3.11,3.5-2.52,6.85c1.57,0,3.15,0,4.7,0c-0.23,4.22-0.43,8.58-0.73,12.94
		c-0.4,5.78-1.33,11.56-1.25,17.34c0.2,14.75,0.79,29.49,1.3,44.23c0.11,3.29,0.61,6.57,0.82,9.86c0.47,7.44,1.23,14.89,1.2,22.33
		c-0.04,7.91-0.93,15.81-1.31,23.73c-0.34,7.26-0.48,14.54-0.73,21.81c-0.22,6.41-0.57,12.81-0.7,19.22
		c-0.12,5.89,0.13,11.8-0.06,17.69c-0.37,11.5-1.04,23-1.38,34.5c-0.55,18.52,0.9,36.92,2.72,55.35
		c1.13,11.43,1.22,22.98,1.52,34.48c0.38,14.52,0.67,29.04,0.7,43.56c0.02,11.07-0.39,22.14-0.69,33.21
		c-0.1,3.77-0.36,7.55-0.72,11.31c-0.89,9.35-2.37,18.67-2.73,28.04c-0.63,16.06-0.56,32.14-0.85,48.21
		c-0.06,3.06-0.47,6.11-0.67,9.17c-0.1,1.52-0.09,3.05-0.12,4.57c0.15,0.36,0.31,0.71,0.47,1.09c0.16,0.37,0.33,0.77,0.52,1.2
		c7.25-3.7,14.26-7.29,21.57-11.02c1.08,6.31,1.97,11.58,2.84,16.69c-6.16,3.52-12.49,7.14-18.82,10.75
		c-1.21,1.01-2.42,2.02-3.62,3.02c0.51,0.75,0.93,1.39,1.36,2.02c4.75-2.28,9.51-4.53,14.24-6.85c2.83-1.39,5.6-2.88,9.38-4.83
		c0,3.14,0,5.97,0,8.8c0.98,5.27,2.04,10.54,2.91,15.83c1.29,7.76,2.33,15.56,3.68,23.31c1.54,8.87,3.24,17.72,4.99,26.55
		c1.05,5.32,0.56,6.18-4.21,9.25c-10.37,6.68-20.6,13.55-30.89,20.35c-0.91,1.4-1.81,2.8-2.72,4.2c0.64,0.02,1.35,0.21,1.96,0.16
		c0.2-0.01,0.39-0.05,0.57-0.14c13.15-6.17,25.23-14.12,36.08-23.66c4.46-3.92,9.57-6.23,14.41-9.25
		c6.81-4.24,13.15-9.24,20.04-13.34c11.89-7.09,24.03-13.75,36.11-20.52c0.9-0.5,2.4-0.94,3.14-0.53c5.08,2.79,10.29,0.5,15.4,0.54
		c3.74,0.03,7.48-0.96,11.24-1.26c7.32-0.58,14.65-0.91,21.97-1.54c1.52-0.13,2.96-1.1,5.03-1.91c0,2.34,0,4.71,0,7.08v0
		c0,4.16,0,8.31,0,13.41c-4.49,2.97-9.41,6.28-14.39,9.51c-6.29,4.09-12.56,8.23-18.96,12.15c-6.38,3.91-12.82,7.74-19.38,11.33
		c-5.43,2.97-11.12,5.41-14.91,10.73c-1.16,1.63-3.19,2.63-5.57,4.52c2.38-8.13,1.96-15.11-0.34-22.28
		c-2.42-7.54-8.4-11.59-15.85-10.75c-8.92,6.05-10.52,15.39-10.7,25.05c-0.06,3.09,0.14,6.19,0.42,9.29
		c0.14,1.55,0.29,3.1,0.44,4.65c0.15,1.55,0.29,3.1,0.41,4.65c0.1,0.21,0.21,0.41,0.32,0.62c0.21,0.42,0.44,0.87,0.7,1.38
		c0.13,0.26,0.27,0.53,0.42,0.83c-8.89,4.83-17.68,9.61-26.46,14.39c-1.21,0.41-2.38,0.97-3.62,1.18c-0.97,0.17-2.29-0.53-2.99-0.09
		c-3.51,2.16-6.86,4.56-11,7.37c-3.43-0.51-6.3,1.04-6.86,5.86c-0.07,0.58-1.04,1.15-1.7,1.55c-3.19,1.91-6.38,3.82-9.63,5.62
		c-2.51,1.39-2.83,3.17-0.99,6.22c4.59-2.17,9.13-4.32,13.76-6.52c3.25,3.39,7.33,5.42,13.2,4.36c-6.02,7.19-3.29,14.54-1.96,21.81
		c0.08,0.46,0.14,0.92,0.19,1.38c0.05,0.46,0.1,0.93,0.15,1.39c1.55,2.86,2.82,4.56,4.47,5.47c0.41,0.23,0.84,0.41,1.31,0.54
		c1.4,0.4,3.1,0.41,5.37,0.17c1.69,0,3.44,0.27,5.07-0.06c4.35-0.87,8.2-2.59,9.76-7.29c0.07-0.21,0.26-0.44,0.46-0.52
		c2.39-1.06,3.53-2.64,4.04-4.47c0.13-0.46,0.22-0.93,0.28-1.42s0.09-0.99,0.11-1.49c0.01-0.25,0.01-0.51,0.01-0.77
		c0-0.77-0.04-1.55-0.07-2.34c-0.72-0.73-1.45-1.47-2.17-2.2c-0.65-0.23-1.3-0.46-2.07-0.73c-0.26-0.09-0.52-0.19-0.81-0.29
		c-0.19-2.37-0.4-4.91-0.61-7.45c-0.08-0.1-0.12-0.21-0.14-0.32c-0.02-0.11,0-0.22,0.04-0.34c-0.26-0.51-0.53-1.03-0.79-1.54
		c0,0-0.01,0.03-0.01,0.05l-0.01,0.05c0,0,0,0,0,0v0c-2.49-1.12-4.99-2.23-7.44-3.34c5.25-6.58,4.25-13.27-2.8-18.43
		c5.78-3.06,11.59-6.14,17.2-9.11c-1.51,6.42,3.4,12.19,11.58,13.03c-1.61,3.71-3.56,7.19-1.45,11.54c2.46,5.08,6.5,7.04,11.74,7.64
		c12.76,1.46,23.39-3.12,33.4-10.66c6.41-4.82,12.6-9.66,17.41-16.16c3.11-4.21,4.44-8.67,2.98-13.92c3.57,1.11,4.6,3.64,4.21,7.38
		c1.27,0.14,2.39,0.27,3.52,0.39c0-0.02,0-0.05,0-0.07l0,0c0,0,0,0,0,0c0,0,0.08,0.08,0.08,0.08c-0.75-1.75-1.55-3.49-2.23-5.27
		c-1.29-3.35-3.93-5.42-6.89-7.07c-2.02-1.12-4.32-1.74-6.37-2.81c-0.81-0.42-1.29-1.46-1.95-2.26c4.52,1.6,8.63,3.14,12.79,4.53
		c7.69,2.58,11.57,9.54,8.83,16.46c-0.66,1.67-2.24,2.98-3.32,4.35c-0.34-2.94-0.63-5.45-0.92-7.94c0,0-0.01,0-0.01,0
		c0.04,2.74-0.76,4.89-2.29,6.97c-0.51,0.7-1.11,1.39-1.78,2.1c-5.24,5.51-12.14,8.33-17.98,12.77c-4.56,3.46-8.64,7.54-13.06,11.19
		c-0.97,0.8-2.5,1.28-3.77,1.28c-2.33-0.01-4,0.38-5.12,2.78c-0.58,1.24-2.19,2-3.34,2.98c-0.03-0.39-0.07-0.79-0.1-1.18
		c-2.08-0.3-4.16-0.6-6.24-0.9c-0.46,0.32-0.94,0.57-1.41,0.8c-0.74,0.36-1.51,0.61-2.28,0.76c-0.41,0.08-0.83,0.14-1.25,0.16
		c-1.04,0.05-2.11-0.07-3.23-0.36c-3-0.77-6.06-1.34-9.1-1.99c-8.06-3.22-12.55-11.34-8.56-19.58c0.56-1.16,0.6-2.57,0.49-3.97
		c-4.02,3.54-5.01,8.29-5.6,13.2c-5.86-1.99-7.01-4.33-4.43-9.88c1.61-3.45,3.63-6.72,5.88-10.84c-5.65,0.88-6.84,5.01-8.54,8.43
		c-3.53,7.14-3.19,9.53,1.61,14.52c0.96,0.12,1.93,0.25,2.96,0.38c1.03,0.13,2.14,0.28,3.39,0.44
		c6.76,14.66,18.49,14.58,31.82,11.09c-0.26,3.02-6.43,6.87-16.76,10.45c0,0,0,0,0,0c0,0,0,0,0,0c-1.76,1.03-3.51,2.08-5.27,3.12
		c-1.76,1.04-3.52,2.06-5.32,3.03c-8.65,4.67-17.51,8.95-25.99,13.9c-10.17,5.93-20.09,12.3-29.99,18.67
		c-3.13,2.02-5.86,4.66-8.77,7.02c-1.46,1.57-2.92,3.14-4.38,4.71c0.24,0.28,0.48,0.56,0.72,0.84c1.22-0.35,2.44-0.7,3.66-1.05
		c0,0,0,0,0,0c0,0,0,0,0,0c1.67-0.76,3.35-1.53,5.02-2.29c0.79-0.64,1.59-1.28,2.38-1.92c0,0,0,0,0,0
		c6.36-3.91,12.73-7.82,19.09-11.72l0,0c0.81-0.51,1.62-1.02,2.43-1.53c0.12,0.22,0.25,0.43,0.37,0.65
		c-0.75,0.48-1.49,0.97-2.24,1.45c0,0,0,0,0,0c-6.36,3.91-12.73,7.82-19.09,11.73c-0.85,0.61-1.7,1.23-2.54,1.84c0,0,0,0,0,0
		c-1.77,1.06-3.55,2.13-5.32,3.19c-0.04,0.98-0.08,1.97-0.13,2.95c9.48-3.21,18.11-7.95,26.29-13.74
		c7.92-5.6,16.22-10.67,24.37-15.94c0.66-0.43,1.43-0.69,3.14-1.5c0,10.84-0.09,21.03,0.03,31.22c0.1,8.33,0.34,16.66,0.72,24.98
		c0.62,13.75,1.41,27.5,2.11,41.25c0.06,1.18,0.01,2.37,0.01,4.08c-10.08,5.87-20.34,11.68-30.43,17.75
		c-7.61,4.58-15.01,9.49-22.51,14.26c-0.68,0.48-1.42,0.91-2.12,1.38c-0.7,0.46-1.36,0.96-1.9,1.57c-0.09,0.11-0.18,0.22-0.25,0.35
		c-0.51,0.87-0.58,2.13-0.9,3.18c2.31-0.98,4.67-1.87,7-2.81c2.33-0.94,4.63-1.95,6.82-3.15c6.6-3.63,12.9-7.82,19.52-11.9
		c0.22,0.76,0.42,1.46,0.62,2.12c0.19,0.67,0.38,1.31,0.57,1.95c0.17,0.24,0.35,0.48,0.55,0.7c0.39,0.45,0.83,0.87,1.32,1.25
		c1.94,1.52,4.55,2.47,7.37,2.7c0.35,0.03,0.71,0.05,1.07,0.05c0.36,0.01,0.72,0,1.08-0.02c1.09-0.05,2.18-0.21,3.27-0.48
		c8.32-2.06,15.39-6.46,21.96-11.79c1.55-1.26,3.11-2.51,4.64-3.79c2.85-2.4,5.72-2.46,9.05-0.74c7.84,4.05,16.26,4.72,24.66,2.51
		c10.76-2.83,21.35-6.29,31.91-9.44c1.6,3.17,3.43,7.02,5.5,10.73c0.51,0.92,1.81,1.42,2.56,1.97c2.3-0.59,3.32-1.83,3.76-3.37
		c0.22-0.77,0.29-1.62,0.29-2.5c0-0.18,0-0.35,0-0.53c-0.47-1.69-0.95-3.39-1.43-5.11c-0.48-1.73-0.98-3.49-1.49-5.32
		c5.37-2.04,10.95-3.63,16.02-6.23c6.79-3.48,13.24-7.62,19.67-11.75c0.39-0.25,0.74-0.57,1.06-0.93c0.32-0.36,0.62-0.76,0.9-1.17
		c0.43-0.63,0.83-1.29,1.26-1.92c0.14-0.21,0.29-0.41,0.44-0.61c0.96-2.53,1.92-5.06,2.89-7.63c0.97-2.56,1.95-5.16,2.96-7.82
		c1.81,4.55,2.16,9.27,2.03,14.05c-0.02,0.68-0.05,1.37-0.08,2.05c-0.72,3.15-1.43,6.31-2.15,9.46c0.66,1.63,1.32,3.25,1.98,4.88
		c1.78-0.91,4.49-1.38,5.15-2.81c1.53-3.28,2.84-7.01,2.84-10.57c0-7.34,0.26-14.87-2.73-21.85c-2.42-5.64-5.14-11.15-7.74-16.76
		c1.69-4.63,3.44-9.42,5.21-14.27c1.21-3.31,2.43-6.66,3.66-10.02c0.57-1.56,1.14-3.12,1.71-4.69c0.01-0.08,0.01-0.16,0.02-0.25
		c0.01-0.09,0.01-0.18,0.02-0.27c0.01-0.16,0.02-0.31,0.03-0.44c0.02-0.24,0.03-0.38,0.02-0.29c1.67-0.83,3.31-1.65,4.93-2.45
		c2.43-1.21,4.8-2.39,7.16-3.56c2.36-1.18,4.71-2.35,7.06-3.51l0,0c1.52-1.14,3.05-2.27,4.6-3.43s3.15-2.35,4.8-3.58
		c0.14-5.94,0.29-11.99,0.44-18.1c0.15-6.1,0.3-12.26,0.45-18.42c0-0.5,0-1,0-1.5c0-1.52,0-3.08,0-4.83
		c1.63,0.75,3.01,1.39,4.4,2.01c7.73,3.47,7.95,3.82,7.59,12.18c0.03,8.59-0.16,17.18,0.16,25.75c0.33,8.6,1.17,17.19,1.78,25.78
		c0.57,4.53,0.97,9.1,1.82,13.57c0.27,1.42,1.49,3.04,2.74,3.82c18.22,11.42,36.5,22.73,54.81,34.01
		c13.67,8.43,27.4,16.76,41.06,25.2c1.59,0.99,2.95,2.36,4.37,3.53c4.63-1.14,10.07,3.07,10.15,7.77c0.1,5.86,2.34,8.76,7.9,10.14
		c5.6,1.39,11.15,3.12,16.83,4.02c7.66,1.21,19.41-0.44,24.63-8.93c4.47-8.89,3.39-17.84,0.41-26.64
		c6.46-3.59,12.96-7.21,19.47-10.82c8.5-4.72,17-9.42,25.49-14.15c6.22-3.47,12.36-7.11,18.68-10.4c2-1.04,4.47-1.18,6.8-1.75
		c1.52-1.16,3.15-2.62,4.98-3.77c8.7-5.43,17.46-10.76,26.18-16.14c5.36-3.31,10.7-6.66,15.88-9.9c2.08,0.86,4.06,1.68,6.04,2.49
		c0.31-0.19,0.62-0.37,0.93-0.56c-0.57-1.65-1.14-3.3-1.81-5.25c0.98-0.59,2.06-1.23,3.2-1.91c1.13-0.68,2.32-1.39,3.51-2.1v0l0,0
		c9.96-6.08,19.92-12.17,29.89-18.23c0.36-0.22,0.78-0.33,1.19-0.46c0.14-0.04,0.28-0.09,0.41-0.13v0l0,0
		c0.82-0.49,1.64-0.99,2.45-1.49c2.44-1.5,4.88-3.02,7.45-4.24c1.17-0.56,2.97,0.19,4.87,0.38c0.13-1.1,0.27-2.22,0.38-3.14
		c14.66-9.19,29.1-18.61,43.91-27.41c10.08-5.99,20.79-10.91,31.02-16.68c6.81-3.84,13.25-8.31,19.86-12.5
		c-0.13-0.3-0.25-0.6-0.38-0.91c1.13,0.56,2.25,1.12,4.11,2.05c0.48-2.35,0.9-4.36,1.22-5.93c-4.51-3.25-8.89-6.41-13.22-9.53
		c-4.33-3.12-8.59-6.2-12.86-9.27c-3.17-1.95-6.35-3.9-9.5-5.85c-3.16-1.94-6.29-3.87-9.4-5.78c18.38-9.44,36.65-18.82,54.86-28.18
		c4.11,2.59,8.5,5.36,12.89,8.13c3.36,1.83,6.72,3.66,10.07,5.5c7.04,3.87,14.09,7.71,21.1,11.63c1.49,0.84,2.83,1.94,4.66,3.22
		c17.32-9.91,34.66-19.84,52.46-30.02c1.49-4.2,0.94-5.93-3.19-8.21c-8.15-4.5-16.34-8.96-24.49-13.46
		c-1.21-0.67-2.35-1.49-3.52-2.23c-3.04-1.49-6.07-2.98-9.11-4.46c-2.18-1.06-4.36-2.13-6.53-3.19c-1.88-0.88-3.75-1.75-6.11-2.85
		c-7.24,1.51-13.15,6.79-19.76,10.84c-9.44,5.78-19.09,11.23-28.65,16.82c-1.01,0.59-2.01,1.18-2.85,1.68
		c-8.89-8.35-20.67-12.02-30.64-18.6c-5.37-3.75-10.73-7.5-16.51-11.54c3.63-1.83,6.65-3.46,9.75-4.9
		c10.42-4.8,20.61-9.97,29.34-17.6c0.61-0.53,1.48-0.78,2.06-1.34c1.27-1.21,3.58-2.75,3.38-3.76c-0.39-2.03-0.2-4.79-3.38-5.75
		c-2.25-0.68-4.15-2.53-6.2-3.85c-1.64-1.05-3.27-2.11-4.91-3.16c-1.5-0.85-3.02-1.69-4.55-2.51s-3.06-1.64-4.6-2.45
		c-1.54-0.81-3.08-1.62-4.63-2.44c-1.55-0.82-3.09-1.65-4.64-2.5c-1.55-0.85-3.09-1.71-4.63-2.61c-4.62-2.68-9.2-5.61-13.65-9.05
		c-0.52,0.15-2.23,0.28-3.52,1.08c-10.53,6.56-20.96,13.27-31.45,19.88c-6.83,4.3-13.57,8.8-20.64,12.68
		c-5.16,2.83-10.97,4.47-16.17,7.24c-7.61,4.06-14.86,8.78-22.4,12.98c-3.81,2.13-7.92,3.73-12.31,5.77
		c-7.11-4.23-14.38-8.56-21.66-12.89c-9.98-4.74-19.59-10.09-28.96-17.12c-5.25-0.03-9.58-4.03-14.39-6.73
		c-10.39-5.84-20.55-12.09-30.86-18.21c11.22-8.98,24.4-14.62,37.56-21.75c-1.45,9.89-0.18,18.59,1.89,27.12
		c0.31,1.3,2.43,2.16,4.21,3.11c0.41,0.22,0.8,0.44,1.15,0.67c0.53-1.5,1.05-3.01,1.58-4.51c0-7.1,0-14.19,0-20.94
		c5.7,0.45,11.18,0.88,17.01,1.33c0,1.14,0,2.51,0,3.88c0.42,2.73,0.84,5.45,1.27,8.17c0.42,2.72,0.84,5.45,1.24,8.18
		c0.19,1.27,0.23,2.56,0.33,3.84c0.38,1.17,1.29,2.47,1.04,3.49c-0.58,2.3,0.61,3.18,2.33,3.53c1.94,0.4,4.02,0.16,4.56-2.22
		c0.26-1.14,0.41-2.3,0.53-3.47s0.21-2.34,0.35-3.51c-0.69-7.51-1.39-15.03-2.01-22.55c-0.03-0.36,0.77-0.78,1.19-1.18
		c5.84-1.77,11.1-4.36,15.64-8.72c2.29-2.2,5.63-3.51,9.28-3.43c4.53,0.1,8.15-2.13,11.04-5.53c2.36-2.77,5.2-3.91,8.96-3.98
		c2.33-0.04,4.71-1.61,6.93-2.75c0.67-0.34,1.28-0.81,1.87-1.31c0.59-0.5,1.16-1.03,1.76-1.51c0.57-0.93,1.14-1.85,1.72-2.81
		s1.19-1.94,1.83-2.98c2.25,0,4.58,0,6.91,0l-0.06,0.03c0.48-0.24,0.96-0.48,1.44-0.72c1.88-1.93,3.76-3.87,5.55-5.7
		c0.95,4.53,1.98,9.4,3.01,14.28c0.39,1.59,0.66,3.24,1.12,4.8c0.15,0.52,0.33,1.03,0.54,1.53c0.44,1.03,1.83,1.65,2.8,2.46
		c2.24-0.48,2.95-1.96,2.8-4.11c-0.12-1.73-0.22-3.46-0.32-5.19c-0.1-1.73-0.19-3.46-0.29-5.19c-0.28-1.72-0.39-3.49-0.86-5.16
		c-1.33-4.8-0.55-8.64,3.1-12.54c0.62-0.66,1.13-1.41,1.58-2.22c0.45-0.81,0.84-1.69,1.2-2.58c0.72-1.8,1.33-3.7,2.11-5.43
		c0.05-0.58,0.12-1.16,0.19-1.75c0.21-1.75,0.45-3.49,0.39-5.23c-0.15-4.67-0.57-9.34-0.89-14.13
		c15.92-10.08,32.22-20.39,49.11-31.08c-0.59,4.34-1.14,8.44-1.65,12.16c-4.64,0.72-9.03,1.24-13.34,2.18
		c-1.45,0.32-2.94,1.5-3.92,2.69c-0.34,0.42-0.58,0.85-0.68,1.24c-0.2,0.84,0.29,1.44,2,1.15c-0.01-0.05-0.02-0.09-0.02-0.14
		c0.05,0.03,0.1,0.05,0.15,0.08c0.08-0.17,0.16-0.35,0.23-0.54c0.1-0.25,0.19-0.52,0.29-0.77c0.19-0.5,0.39-0.96,0.67-1.2
		c0.07-0.06,0.15-0.11,0.24-0.14c4.62-1.38,9.3-2.78,14.64-0.87c0.07-0.03,0.14-0.06,0.21-0.1c0.01,0.06,0.02,0.12,0.03,0.18
		c0.02,0.01,0.04,0.01,0.06,0.02c3.28-5.81,2.22-12.25,3.37-18.58c-4.27-1.4-9.44,0.61-12.84-4.3c2.23-0.5,4.16-0.93,5.94-1.33
		c0.89,1.04,1.54,1.8,2.15,2.51c1.7-0.47,3.2-0.88,4.88-1.35c-0.47-6.96,3.15-12.59,6.58-18.83c-6.21,0.16-11.75,0.09-16.05-5.29
		c7.62-2.4,13.19,1.2,18.04,4.91c3.43-2.79,6.35-5.25,9.35-7.57c2.29-1.77,2.02-5.41-0.43-7.06c-2.81-1.89-5.45-4.02-7.86-6.45
		c7.53,0.64,11.36,1.97,11.54,9.39c0.03,1.42,1.18,2.81,1.81,4.21c0.23-0.43,0.45-0.85,0.68-1.28c3.38,0.38,6.77,0.76,10.23,1.15
		c0,1.32,0,2.1,0,2.68c-3.27-0.6-6.44-1.18-9.65-1.77c-2.27,2.31,0.05,6.9-4.16,8.07c-0.46-1.11-0.93-2.23-1.47-3.52
		c0.33-1.06,0.73-2.37,1.14-3.68c-7.08,5.58-15.14,10.12-16.85,19.78c-0.32,1.83-0.05,3.76-0.05,5.8c1.83,0,2.99,0,4.35,0
		c0,1.32,0,2.52,0,3.95c-1.35,0.18-2.49,0.33-3.68,0.49c-1.86,7.17-3.73,14.38-5.6,21.58c-0.31-0.02-0.63-0.05-0.94-0.07
		c-0.14-0.9-0.28-1.8-0.54-3.47c-0.08-0.03-0.16-0.05-0.23-0.08c-2.39,1.1-4.53,2.17-6.74,3.06c-3.04,1.22-6.15,2.23-9.33,0.45
		c-0.01,0.01-0.01,0.03-0.02,0.04c-0.04,0.01-0.07,0.01-0.11,0.02c0.38,3.13,2.88,2.53,4.89,2.53c1.46,0,2.93-0.41,4.9,0.14
		c-2.54,2.56-4.93,5.3-7.65,7.65c-5.36,4.62-11.67,6.48-18.76,6.05c-1.53-0.09-3.35,0.29-4.62,1.11c-3.45,2.21-2.96,4.33,1.43,7.33
		c6.34-0.84,12.67-1.68,18.74-2.49c-2.28,4.31,0.1,8.75,2.04,13.25c4.2,2.31,6.47,1.35,7.83-3.37c0.35-1.22,0.52-2.48,0.78-3.73
		c-0.01-3.09-0.66-6.36,0.13-9.23c1-3.65,2.44-7.67,4.97-10.31c5.77-6.04,7.45-13.5,9.14-21.11c0.41-1.83,0.42-3.74,0.81-5.57
		c0.11-0.53,0.92-0.91,2.29-2.15c-0.37,3.39-0.22,6.42,0.41,9.2c0.18,0.79,0.39,1.57,0.65,2.32c0.38,1.13,0.84,2.22,1.39,3.28
		c0.73,1.41,1.59,2.76,2.59,4.06c0.8,0.39,1.48,0.83,2.05,1.33c0.57,0.5,1.02,1.07,1.37,1.7c0.69,1.26,0.93,2.77,0.75,4.56
		c-0.12,1.16,0.15,2.37,0.38,3.56c0.08,0.4,0.15,0.8,0.2,1.2l0,0c1.95,4.5,3.91,9,5.86,13.5c1.67,2.5,3.33,4.99,5.13,7.69
		c-1.64,0.93-3.03,1.72-4.41,2.51c-0.3,0.45-0.6,0.9-0.9,1.35c0.02,1.19,0.04,2.39,0.06,3.58c0.02,1.19,0.04,2.39,0.07,3.58
		c0.03,0.97,0.37,2,0.16,2.91c-0.63,2.72,0.18,4.6,3.07,5.83c1.76-0.96,3.68-2.01,5.56-3.04c3.28,4.17,3.28,4.17,9.87,0.44
		c1.47,0.89,3.01,1.82,5.06,3.06c0.37-0.52,0.73-1.03,1.08-1.52c1.06-1.48,2.04-2.86,3.02-4.24c0.17-0.51,0.34-1.02,0.51-1.53
		c0.34-1.02,0.69-2.05,1.03-3.07c0.51-1.54,1.01-3.08,1.47-4.63c0.47-1.59,0.79-3.21,1.13-4.84c0.11-0.54,0.23-1.08,0.35-1.62
		c-0.13-0.67-0.26-1.35-0.39-2.03c-0.4-2.05-0.81-4.18-1.29-6.66c0.73,0.15,1.44,0.3,2.12,0.45c1.37,0.29,2.67,0.57,3.95,0.84
		c0.64,0.14,1.27,0.27,1.9,0.4c7.85-0.03,15.69-0.03,23.54-0.12c1.51-0.02,3.01-0.4,4.51-0.62c4.84-0.3,9.76-0.2,14.51-1.03
		c4.66-0.81,9.16-2.56,14.66-4.17c0,5.41,0.47,10.67-0.13,15.81c-0.6,5.16,1.38,9.59,6.16,11.56c2.36,0.97,4.49,2.48,6.83,3.54
		c1.23,0.56,2.67,0.65,4.13,0.98c0.83-1.29,1.51-2.34,2.11-3.26c1.69,1.1,3.43,2.23,5.67,3.69c0.69-1.85,1.19-3.17,1.71-4.56
		c1.21,0.53,2.48,1.1,4.69,2.07c0.2-0.39,0.8-1.11,0.92-1.9c0.64-4.29,1.74-8.62,1.54-12.88c-0.21-4.38-3.13-7.49-7.27-8.58
		c0.49-4.05,0.95-7.84,1.44-11.91c1.06-0.05,2.3-0.11,3.32-0.15c0.66,2.88,0.86,5.66,1.99,8c0.82,1.69,2.85,3.85,4.37,3.87
		c1.86,0.03,4.19-1.57,5.53-3.15c2.93-3.45,2.5-7.59,1.21-11.66c-0.38-1.21-0.75-2.43-1.12-3.6c2.15-1.6,4.19-3.12,6.67-4.96
		c0.46,2.62,0.59,5.04,1.35,7.24c0.86,2.51,1.13,5.97,5.15,5.62c2.42-0.21,4.98-3.58,4.7-6.97c-0.27-3.3-0.82-6.81-2.26-9.71
		c-2.13-4.28-3.28-8.26-2.1-13.12c0.65-2.7-0.29-5.78-0.52-8.88c10.2,4.03,21.52,5.62,28.26,15.1c-3.16,0-6.46-0.46-9.56,0.12
		c-3.4,0.64-5.6,3.23-6.54,6.65c2.39,5.5,4.09,6.06,11.94,4.11c-0.34,3.29-0.7,6.74-1.18,11.36c-2.05,1.58-2.91,5.15-2.88,9.05
		c0.03,2.86,1.2,5.2,4.07,5.86c2.93,0.68,4.99-0.85,5.97-3.68c0.61-1.75,1.12-3.52,1.62-5.11c4.04,1.66,8.08,3.45,12.23,4.98
		c4.57,1.69,9.91-2.23,9.08-6.68c-0.73-3.88-4.24-4.09-6.88-5.08c-3.56-1.34-7.41-1.87-11.07-2.97c-1.18-0.35-2.16-1.39-3.23-2.11
		c0.02-0.43,0.05-0.86,0.07-1.29c5.15-1.92,10.3-3.83,16.41-6.11c14.98,9.12,31.06,18.9,47.28,28.78
		c-0.84,5.48,4.65,8.36,13.82,6.71c0.1,1.9,0.11,3.79,0.34,5.66c0.22,1.81,0.66,3.6,1.14,6.11c-3.72-1.75-6.92-3.28-10.15-4.75
		c-1.67-0.76-3.35-1.63-5.12-2.04c-2.32-0.54-4.48,2.76-3.74,5.5c1.98,7.27,11.65,13.25,18.98,8.45c0.59-0.38,1.23-0.69,2.52-1.4
		c-1.01,3.19-2.21,5.78-2.59,8.49c-0.65,4.74-1.52,9.58,0.82,14.2c1.67,3.28,4.27,4.43,8.03,3.69c-0.09,8.24-3.86,14.71-10.86,18.44
		c-6.21,3.32-13.22,5.13-19.84,7.71c-2.47,0.96-4.28,2.52-5.28,4.4c-0.38,0.7-0.64,1.45-0.79,2.23c-0.05,0.26-0.08,0.52-0.11,0.79
		c-0.02,0.27-0.03,0.53-0.03,0.8s0.02,0.54,0.06,0.82c0.06,0.55,0.19,1.1,0.36,1.66c0.27,0.27,0.55,0.5,0.83,0.69
		c0.56,0.4,1.15,0.66,1.75,0.82c0.6,0.15,1.21,0.2,1.83,0.15c0.92-0.07,1.86-0.36,2.77-0.79c3.32-1.56,6.48-3.48,9.13-4.93
		c1.18,4.78,2.26,9.95,3.82,14.97c0.43,1.38,2.6,3.44,3.4,3.21c1.65-0.48,3.17-2.11,4.25-3.62c0.74-1.03,0.65-2.66,0.92-4.02
		c-0.82-4.81-1.63-9.63-2.49-14.7c12.4-10.44,21.46-22.82,17.14-41.14c2.54,1.48,5.18,3.02,7.5,4.38
		c1.21,5.37,2.18,10.68,3.68,15.83c0.62,2.13,2.2,5,4.98,3.9c1.98-0.79,3.7-3.07,4.77-5.09c1.81-3.41-0.02-6.77-1.15-10.05
		c-0.54-1.55-1.04-3.11-1.64-4.89c1.44-0.81,2.91-1.64,4.39-2.47c0.21-0.12,0.41-0.23,0.61-0.35c0.83-0.8,1.66-1.6,2.64-2.54
		c1.43,0.85,2.92,1.73,3.87,2.3c0,5.63,0,10.78,0,15.93c0.72,2.04,1.12,4.3,2.28,6.04c0.84,1.26,2.94,2.74,4.07,2.47
		c1.22-0.3,2.13-2.44,2.87-3.94c0.37-0.76,0.07-1.85,0.07-3.25c5.8,0.29,5.48-4.36,6.61-7.97c4.48,2.46,8.95,4.9,13.39,7.34
		c-2.18,6.92,3.95,11.21,9.56,8.89c3.55-1.47,4.67-0.43,6.41,3.01c1.42,2.81,3.44,5.36,5.46,7.81c1.46,1.78,1.99,3.38,0.65,5.37
		c-2.94,4.37-5.97,8.68-8.75,13.15c-1.93,3.1-3.48,6.57-1.71,10.2c0.9,1.83,2.58,4.28,4.17,4.5c1.65,0.22,4.29-1.49,5.31-3.11
		c2.21-3.48,3.65-7.44,5.18-10.73c1.17,4.78,2.49,10.13,3.8,15.49c4.73,0.25,6.65-2.58,7.88-5.29c1.02-2.26,1.17-5.17,0.88-7.7
		c-0.75-6.49-4.09-11.79-8.46-16.55c-1.53-1.67-2.55-3.8-3.81-5.72c0.24-0.36,0.48-0.71,0.72-1.07c2.5,1.19,5.05,2.27,7.48,3.57
		c8.96,4.78,17.89,9.64,26.57,14.33c-3.55,13.51-14.2,19.84-25.15,25.86c-0.99,2.62-0.2,5.05,2.12,6.05
		c1.79,0.77,4.54,0.92,6.2,0.07c3.72-1.91,7.03-4.63,10.78-7.2c0.42,2.28,0.69,4.87,1.4,7.33c0.83,2.89,1.82,5.78,3.16,8.46
		c1.84,3.66,4.92,4.58,7.87,2.96c3.21-1.76,3.34-6.16,1.73-9.05c-1.97-3.54-4.8-6.69-6.25-10.4c-1.85-4.72-1.06-9.59,1.59-14.09
		c0.99-1.68,1.53-3.62,2.42-5.79c5.5,3.55,10.98,7.29,16.67,10.71c15.57,9.35,31.25,18.53,46.84,27.84
		c5.48,3.27,10.84,6.76,16.25,10.14l0,0c1.48,0.53,2.96,1.06,4.41,1.59c2.65-4.85,1.45-9.69-2.89-12.38
		c-0.29-0.18-0.56-0.39-0.82-0.61c-0.26-0.22-0.52-0.45-0.79-0.66v0c-4.04-2.22-8.09-4.43-12.14-6.64s-8.09-4.44-12.12-6.68
		c-4.87-2.71-9.66-5.57-14.79-8.55c1.25-1.74,2.25-3.14,3.25-4.53c5.56,3.45,9.31,0.94,10.03-6.6c0.18-1.82-0.19-3.7-0.35-6.23
		c0.76-1.05,1.94-2.7,3.13-4.35c-0.21-0.25-0.43-0.49-0.64-0.74c-1.32,0.96-2.63,1.92-4.02,2.94c-5.06-3.93-9.94-2.08-10.87,4.62
		c-0.33,2.4,0.27,4.92,0.28,7.39c0,1.09-0.41,2.17-0.64,3.26c-0.34-0.04-0.68-0.08-1.02-0.12c-0.2-3.91-0.43-7.82-0.6-11.73
		c-0.35-8.3-0.64-16.61-1.01-24.91c-0.06-1.35-0.16-2.7-0.27-4.04c-0.11-1.35-0.23-2.69-0.34-4.04v0c-0.56-4.65-1.13-9.3-1.73-14.21
		c14.15,3.43,24.88,14.25,39.36,16.16c0.3-0.69,0.55-1.29,0.78-1.83c0.38-0.9,0.69-1.63,1-2.36c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0
		c-0.13-0.57-0.27-1.13-0.4-1.7c-0.08-0.35-0.16-0.7-0.25-1.05c0,0,0,0,0,0l0,0c0.08-2.24,0.16-4.48,0.24-6.73
		c0,0,0.1-0.05,0.1-0.05c0.03-1.01,0.06-2.02,0.09-3.04c0.03-1.01,0.07-2.02,0.13-3.03c0.7-11.47,1.45-22.94,2.12-34.41
		c0.54-9.35,1.08-18.71,1.43-28.07c0.37-9.74,0.5-19.49,0.77-29.24c0.02-0.56,0.3-1.12,0.47-1.67c0.1-3.93,0.37-7.86,0.19-11.78
		c-0.05-1.15-1.17-2.56-2.2-3.3c-12.03-8.63-23.94-17.5-38.29-22.14c-0.31-0.1-0.55-0.43-0.98-0.77
		c-1.05-13.38-2.1-26.87-3.18-40.62c7.91,4.23,15.47,8.07,22.83,12.27c8.81,5.02,17.45,10.35,26.16,15.55
		c1.82,0.51,3.65,1.02,5.47,1.54c0.24-0.32,0.48-0.63,0.72-0.95c-2.09-2.07-4.19-4.13-6.28-6.2
		C1178.93,360.51,1173.36,357.79,1167.91,354.88z M74.85,752.83c-2.77,2.24-5.56,4.49-8.72,7.04c0.02,0.12,0.16,0.95,0.3,1.81
		c-3.84,2.06-7.72,1.47-11.52,0.8c-2.54-0.45-4.3-1.82-4.8-4.8c-0.63-3.72-1.85-7.35-2.9-11.32c2.7,0.86,4.95,2.1,7.25,2.21
		c4.01,0.19,8.09-0.07,12.08-0.61C70.14,747.48,73.53,748.94,74.85,752.83z M68.86,739.48c-2.39,6.99-11.22,8.14-19.36,2.34
		C52.99,735.65,63.89,732.14,68.86,739.48z M63.11,724.39c1.88,4.41-3.5,4.97-4.79,7.11c-3.58,0-6.61,0.12-9.63-0.03
		c-3.92-0.2-7.84-0.61-12.24-0.97c0-3.1,0-5.55,0-7.99c1.05,0.17,1.71,0.28,2.35,0.38c-0.01-0.02-0.01-0.03-0.02-0.05
		c0.02,0.02,0.05,0.03,0.07,0.05c1.8-0.61,3.69-1.08,5.04-2.35c0.61-0.58,1.11-1.32,1.44-2.32c0.16-0.48,1.09-0.81,1.72-1.03
		c1.82-0.63,3.66-1.18,6.39-2.04c1.88,1.56,4.45,3.7,6.95,5.78c-2.11,4.58-3.65,5.61-9.24,4.72c-4.13-0.66-8.65,0.04-12.31-2.77
		c-0.01,0-0.01,0-0.02,0.01l-0.05-0.05c0,0,0.05,0.05,0.05,0.06c-0.01,0-0.02,0-0.03-0.01c0.87,2.34,1.74,4.68,2.66,7.15
		c0.66-0.07,1.67,0.13,1.98-0.25c2.65-3.28,6.25-1.37,9.37-1.99C56.31,727.12,59.64,725.59,63.11,724.39z M721.12,637.47
		c0.4-0.2,0.8-0.4,1.21-0.6c0,0,0,0,0,0l0,0c0,0,0,0.01-0.01,0.01c0,0-0.01,0.01-0.01,0.01c0.9-0.39,1.79-0.77,2.69-1.16
		c0.79-0.34,1.57-0.68,2.36-1.02c0.22-0.19,0.45-0.37,0.67-0.56c10.43-6.49,20.87-12.96,31.28-19.48c3.28-2.05,6.22-2.41,9.58,0.21
		c1.77,1.38,3.76,2.48,5.78,3.55c0.81,0.43,1.61,0.85,2.41,1.29c0.4,0.22,0.8,0.44,1.19,0.66c0.7,0.4,1.4,0.79,2.11,1.19
		c2.65,1.5,5.29,2.99,7.94,4.49l0,0c5.73,3.17,11.46,6.35,17.19,9.52c0.85,0.42,1.7,0.83,2.56,1.26c0.87,0.43,1.75,0.86,2.68,1.32
		c-1.65,1.1-3.13,2.08-4.56,3.03c-0.48,0.32-0.95,0.63-1.42,0.94c-6.72,3.84-13.43,7.68-20.16,11.53s-13.46,7.7-20.21,11.56
		c-8.68-4.3-17.43-8.64-26.18-12.98c-2.93-1.75-5.85-3.5-8.78-5.26c-3.34-1.95-6.68-3.91-10.02-5.86c-0.68-0.53-1.37-1.06-2.22-1.73
		C718.65,638.69,719.9,638.07,721.12,637.47z M671.57,399.03c-1.03,2.61-1.93,4.87-3.14,7.93c-0.72-3.21-1.27-5.64-1.94-8.63
		C668.18,398.56,669.77,398.78,671.57,399.03z M636.49,462.69c-2.07,0.33-5.23,1.61-7.15-2.38c1.83-0.67,3.47-1.91,4.89-1.69
		c7.74,1.21,14.44-0.81,20.5-5.75C651.12,460.85,643.58,461.55,636.49,462.69z M658.08,458.14c-2.26,4.76,0.56,10.55-2.61,16.06
		c-5.47-9.01-1.97-15.75,3.75-22.7c-0.97,0.13-1.53,0.21-1.99,0.27c-0.15,0.02-0.29,0.04-0.43,0.06c-0.29,0.02-0.57,0.04-0.86,0.06
		c0.15-0.19,0.31-0.38,0.46-0.57c3.49-1.81,4.53-6.9,8.94-7.25C662.88,448.8,660.33,453.4,658.08,458.14z M667.17,443.25
		c-0.23-0.08-0.45-0.15-0.68-0.23c0.21-0.67,0.42-1.34,0.62-2.01c0.26,0.09,0.53,0.18,0.79,0.27
		C667.66,441.93,667.42,442.59,667.17,443.25z M676.03,411.72c1.03-2.84,1.89-5.21,2.75-7.58c0.31-0.01,0.61-0.01,0.92-0.02
		c0.34,2.42,0.68,4.84,1.07,7.6C678.95,411.72,677.85,411.72,676.03,411.72z M941.01,485.18c4.47,2,8.74,3.9,12.7,5.67
		C949.4,494.4,942.31,491.75,941.01,485.18z M953.18,566.31c-4.43-5.77-3.67-12.48-3.79-20.2c-6.89,1.88-10.96,7.38-17.67,8.97
		c0-0.81-0.36-2,0.07-2.48c1.14-1.26,2.54-2.34,4-3.23c1.39-0.85,3.06-1.22,4.46-2.05c3.8-2.28,7.71-3.59,11.71-1.67
		c-0.18,0.77-0.83,2-0.64,3.08c0.69,4.02,1.8,7.96,2.45,11.99C954.07,562.53,953.67,564.45,953.18,566.31z M1060.12,554.07
		c7.54,6.71,9.15,17.24,3.36,21.89C1061.26,572.72,1058.81,556.8,1060.12,554.07z M1150.24,567.22c-0.86,2.32-1.54,4.74-2.72,6.89
		c-0.27,0.5-2.23,0.07-4.04,0.07c2.33-3.01,4.02-5.18,5.71-7.36C1149.55,566.95,1149.9,567.08,1150.24,567.22z M1144.28,561.71
		c1.14,0.58,2.37,1.2,4.09,2.07c-2.23,2.81-4.39,5.53-6.55,8.24C1140.51,567.71,1140.37,564.08,1144.28,561.71z M1070.62,310.2
		c2.24,2.79,4.49,5.58,7.08,8.8c-1.81,0.63-3.04,1.06-4.16,1.45c-3.28-4.21-7.1-7.15-12.27-9.17c3.63,3.89,6.85,7.34,9.74,10.44
		c-0.23,4.16-0.44,7.86-0.67,11.85c-7.91-3.72-13.16-11.86-22.82-12.22c0.17-0.41,0.34-0.81,0.51-1.22
		c2.96,0.54,5.93,1.08,8.89,1.62c0.84,0.51,1.67,1.01,2.51,1.52c0.12-0.22,0.24-0.44,0.36-0.66c-0.76-0.45-1.52-0.91-2.28-1.36v0
		c-1.18-0.32-2.35-0.67-3.52-1.02c-3.52-1.06-7.03-2.17-10.63-2.74c-7.78-1.23-15.24,1.26-22.74,2.93c-1.8,0.4-3.68,0.44-5.26,0.61
		c-0.38-1.55-0.75-3.01-1.1-4.44s-0.7-2.81-1.04-4.2c-1.31-1.63-2.63-3.07-3.99-4.31c-0.09-0.08-0.17-0.15-0.26-0.23
		c-1.54-1.37-3.13-2.47-4.81-3.29c-1.26-0.62-2.56-1.1-3.94-1.38c-3.83-0.8-8.15-0.25-13.3,1.88c-0.52-3.94,2.16-6.46,4.34-8.74
		c2.13-2.22,5.09-3.81,7.94-5.1c1.6-0.72,3.73-0.25,5.62-0.36c3.06-0.18,6.14-0.77,9.16-0.54c5.85,0.44,11.67,1.33,17.51,2.04
		c-0.03,0.51-0.06,1.03-0.08,1.54c-4.58,0-9.16,0-13.73,0c-0.02,0.29-0.05,0.59-0.07,0.88c0.37,0.13,0.74,0.34,1.12,0.38
		c5.14,0.5,10.32,1.59,15.42,1.31c6.07-0.34,11.69,1.24,17.5,2.23C1059.56,300.02,1065.75,303.95,1070.62,310.2z M1078.99,324.09
		c1.28,0,2.24,0,3.57,0c0.1,1.71,0.18,3.18,0.27,4.66c1.08,2,2.16,4,3.24,6c0.53-0.15,1.06-0.29,1.59-0.44
		c-0.16-2.89-0.32-5.78-0.48-8.67c0.23-0.23,0.46-0.47,0.7-0.7c0.91,0.8,2.4,1.47,2.63,2.43c0.77,3.29,1.31,6.67,1.49,10.04
		c0.11,1.98-0.64,4-0.97,5.83c-9.64,0-13.77-2.9-12.54-8.17c0.28,0.16,0.55,0.32,0.83,0.48c0.71-1.05,1.42-2.11,2.13-3.16
		C1080.64,329.68,1079.85,326.97,1078.99,324.09z M1080.17,346.7c0.9,0.89,1.75,1.92,2.32,3.04c0.41,0.81,0.81,1.63,1.21,2.45
		c0.58,1.2,1.15,2.41,1.71,3.62c0.59,1.27,1.18,2.53,1.77,3.8c0.92,0.46,1.84,0.92,2.8,1.41c0.96,0.49,1.97,0.99,3.07,1.55
		c-1.29,2.53-2.55,5-4.29,8.42c-1.25-3.57-2.2-6.31-3.16-9.05v0v0c-1.61-2.85-3.41-5.5-5.37-7.98c-0.49-0.62-0.99-1.23-1.5-1.83
		c-1.02-1.2-2.09-2.35-3.19-3.47c-1.1-1.11-2.24-2.19-3.42-3.22c-0.59-0.52-1.19-1.02-1.8-1.52c-2.15-1.76-4.41-3.4-6.77-4.93
		c-0.56-0.36-1.12-0.72-1.69-1.07c-0.93-0.58-1.88-1.14-2.84-1.69c-0.18-0.1-0.37-0.21-0.55-0.32c-0.91-0.43-1.83-0.84-2.76-1.23
		c-0.87-0.37-1.74-0.71-2.62-1.03c-0.73-0.26-1.46-0.51-2.19-0.73c-0.37-0.11-0.74-0.22-1.11-0.31c-0.74-0.2-1.49-0.37-2.24-0.52
		c-2.27-0.44-4.59-0.63-7.02-0.48c-1.49,0.1-3.03-0.98-4.59-1.32c-4.15-0.89-7.25,1.31-10.25,3.7c-0.63,0.5-1.31,0.92-1.98,1.37
		c-2.27,2.39-4.54,4.77-6.81,7.16c-1.61,2.77-3.21,5.55-4.91,8.49c-0.57,0.98-1.15,1.98-1.74,3c0.83-4.24,1.62-8.24,2.41-12.24
		c-1.03-3.07,0.66-5.46,1.97-7.94c0.9-2.28,1.81-4.56,2.66-6.71c6.06,0.55,11.79-0.44,17.36-2.5c2.27-0.84,4.02-0.89,6.06,1.02
		c4.55,4.28,10.66,5.22,16.44,6.8c0.48,0.13,0.95,0.35,1.4,0.58s0.91,0.49,1.37,0.72c0.27,0.18,0.55,0.36,0.82,0.54
		c0.61,0.4,1.21,0.8,1.82,1.2c0.71,0.47,1.42,0.93,2.15,1.38c0.54,0.33,1.08,0.65,1.64,0.96c1.8,0.99,3.77,1.65,5.65,2.51
		c0.93,0.42,1.93,0.8,2.7,1.44C1076.59,343.32,1078.42,344.98,1080.17,346.7z M1103.07,376.85c0.19,3.58,0.38,7.15,0.56,10.71
		c-0.4,3.64-0.8,7.29-1.2,10.93c-0.53,0.02-1.05,0.03-1.58,0.05c-1.49-9.28-1.41-18.75-2.22-28.11c-0.51-7.3-1.58-14.61-1.38-21.88
		c0.19-6.55,0.2-7.17,6.55-9.61c9.71,11.6,8.5,19.94,3.47,32.49c-0.59-9.31-0.68-18.62-5.93-27.27
		C1101.94,355.3,1102.51,366.12,1103.07,376.85z M1098.17,391.44c-0.35,0.07-0.71,0.15-1.07,0.22c-1.67-5.33-3.44-10.63-4.93-16
		c-0.25-0.91,0.63-2.17,1.08-3.23c0.94-2.2,1.95-4.38,2.99-6.71C1096.89,374.35,1097.53,382.9,1098.17,391.44z M1067.07,477.06
		c0,1.07,0,2.13,0,3.15c-1.07,1.14-2.03,2.17-2.56,2.74c0.27,7.19-2.92,12.45-6.09,17.15c-3.6-6.73-7.26-13.56-11.03-20.61
		c1.05-0.83,2.01-1.59,2.98-2.36c0.28-0.27,0.56-0.54,0.85-0.81c0.43-0.21,0.86-0.42,1.29-0.63c0.66-0.66,1.32-1.31,1.97-1.97
		c0.01-0.01,0.02-0.02,0.03-0.03c0.86-0.88,1.69-1.78,2.42-2.75c0.19-0.24,0.37-0.49,0.54-0.75c0.35-0.51,0.66-1.03,0.95-1.58
		c0.85-1.65,1.37-3.53,1.37-5.77c0.72,0.4,1.36,0.76,1.96,1.09c0.6,0.33,1.16,0.65,1.73,0.96c0.45,0.19,0.9,0.39,1.37,0.59
		s0.93,0.4,1.43,0.61c0.98,0.42,2.04,0.88,3.26,1.4c-0.83,3.19-1.68,6.43-2.53,9.67L1067.07,477.06z M1053.42,468.84
		c-2.15,2.12-4.38,4.15-6.47,6.11c-4.22-8.12,3.16-14,2.43-22.02c1.89,2.42,2.98,4.07,4.31,5.48
		C1057.15,462.1,1057.15,465.16,1053.42,468.84z M1004.68,479.36c-0.29,1.34-0.58,2.68-0.87,4.01c-9.67-1.45-16.4-9.32-15.45-20.02
		c0.42-0.16,0.85-0.32,1.3-0.48c1.33-0.5,2.77-1.03,4.2-1.57c0.39-0.18,0.78-0.36,1.19-0.54c0.38-0.18,0.78-0.36,1.22-0.56
		c0.3-0.14,0.61-0.28,0.95-0.44c0.79,7.04,4.06,12.13,10.93,14.71C1006.97,476.14,1005.82,477.75,1004.68,479.36L1004.68,479.36z
		 M968.86,487.13c3.27,2.6,6.17,4.9,9.3,7.39c1.17-0.74,2.43-1.54,3.97-2.51c-0.17,0.98-0.28,1.67-0.41,2.39
		c1.48,1.2,2.89,2.34,4.4,3.55c-1.41,1.2-2.74,2.32-4.06,3.45c0,0,0,0,0,0c0,0,0,0,0,0c-9.07-2.93-13.61-5.92-14.08-9.9
		C967.83,490.16,968.13,488.72,968.86,487.13z M967.59,479.85c-0.33-5.19,0.03-5.69,3.88-5.06c0.38,1.97,0.79,4.08,1.36,6.98
		C970.48,480.91,968.99,480.36,967.59,479.85z M983.44,489.02c-1.44-4.96-2.85-9.81-4.37-15.03c1.28-0.98,3.37-2.57,4.9-3.74
		c3.95,8.43,8.13,17.33,12.18,25.98C992.12,493.93,987.52,491.32,983.44,489.02z M990.35,410.4c-5.66,0.12-7.51,2.7-5.41,8.59
		c1.53,4.28,3.87,8.27,5.75,12.19c-0.85-0.19-2.45-0.55-3.78-0.85c-1.9-5.95-3.72-11.63-5.6-17.52c1.67-1.74,4.37-3.5,1.95-5.68
		c0.35-3.02,0.62-5.34,0.82-7.15c1.72,1.47,3.64,3.1,5.56,4.73c1.01,0.19,2.01,0.39,3.02,0.6c3.02,0.62,6.03,1.27,9.08,1.49
		c1.72,0.12,3.56-1.35,5.11-1.99c1.06,3.74,2.06,7.27,2.95,10.39c-3.25,3.45-6.62,7.03-9.88,10.48
		C998.17,419.92,996.21,413.95,990.35,410.4z M996.52,429.22c-6.75-2.72-7.1-9.14-9.17-15.14
		C992.32,415.34,996.91,423.2,996.52,429.22z M971.92,435.76c1.25-0.44,2.46-0.86,4.15-1.46c0.02,0.01,0.7,0.37,1.68,0.88
		c0.25-1.17,0.43-2.03,0.59-2.79c3.04,0.6,6.12,1.21,9.96,1.97c-5.34,7.19-6.14,14.88-7.88,22.3c-1.31,5.6-2.42,6.13-8.09,4.76
		c-1.11-0.27-2.21-0.55-3.69-0.91C969.78,451.94,970.86,443.84,971.92,435.76z M968.22,429.32c1.12-5.37,2.23-10.73,3.35-16.1
		c0.45,0.07,0.9,0.14,1.35,0.22c-0.52,4.39-1.04,8.79-1.59,13.38c-0.25-0.1-0.73-0.28-1.52-0.57c-0.19,1.19-0.36,2.24-0.53,3.3
		C968.93,429.47,968.57,429.39,968.22,429.32z M976.09,413.01c1.19,0.2,2.24,0.39,3.37,0.58c0.41,4.83,0.81,9.6,1.22,14.37
		c-0.23,0.06-0.46,0.11-0.69,0.17c-0.55-0.86-1.11-1.73-2.24-3.48c-0.23,2.03-0.34,3.04-0.49,4.4c-0.64-1.35-1.16-2.44-1.78-3.75
		c-0.62,0.59-1,0.95-1.86,1.77C974.5,422.06,975.29,417.56,976.09,413.01z M974.67,405.27c0.67-6.38,1.34-12.71,2.01-19.04
		c0.22-0.05,0.44-0.09,0.66-0.14c0,6.51,0,13.02,0,19.73C976.51,405.66,975.69,405.49,974.67,405.27z M994.39,311.61
		c1.03,0.16,2.1,0.02,3.16,0c0-0.01-0.01-0.01-0.01-0.02c0,0,0.02,0,0.03,0.01c0.02,0,0.04,0.01,0.04,0.01
		c4.16-0.26,6.63,0.43,9.02,4.09c0.55,0.84,1.1,1.84,1.66,3.03c2.58,4.85,3.4,9.67-0.85,14.01c-0.7,0.72-2.34,0.53-3.54,0.75
		c-3.83-3.43-7.65-6.86-11.48-10.3c-0.17,0-0.33,0-0.51,0c-0.53,0-1.16,0-2.2,0c0.48,0.61,0.93,1.19,1.37,1.74
		c0.63,0.8,1.2,1.54,1.77,2.26c0.61,0.78,1.21,1.54,1.8,2.29c1.64,1.52,3.27,3.03,4.91,4.55c-0.2,0.32-0.41,0.65-0.61,0.97
		c-2.19-1.33-4.37-2.67-6.56-4c-4.58-1.64-4.72-5.9-5.58-9.65c-0.33-1.47-0.25-3.12,0.01-4.63
		C987.52,312.59,990.12,310.95,994.39,311.61z M989.72,307.66c4.12-2.39,8.62-3.7,13.01-1.76c4.11,1.82,7.92,4.54,8.44,9.1
		c-2.99-2.2-5.87-5.49-9.44-6.65C998.1,307.17,993.78,308.09,989.72,307.66z M1052.54,350.1c-0.8,0.16-1.61,0.31-2.42,0.47
		c-0.82,0.16-1.64,0.32-2.46,0.48c-0.88,0.49-1.76,0.98-2.67,1.49c-0.91,0.51-1.86,1.04-2.88,1.6c0-6.52,0-12.72,0-19.46
		c0.95,0.29,1.8,0.54,2.61,0.79c0.8,0.24,1.56,0.47,2.32,0.7c1.83,0.58,3.66,1.17,5.51,1.76
		C1052.54,342.07,1052.54,345.86,1052.54,350.1z M1055.45,351.05c0-0.92,0-2.43,0-4c1.25,0.43,2.31,0.79,3.35,1.15
		c0.14,5.2-7.35,11.09-13.56,10.41C1047.29,354.2,1052.19,354.17,1055.45,351.05z M1050.74,367.16c3.97,2.69,5.39,3.61,6.76,8
		c-5.41,1.82-12.8-1.96-18.06-9.22c-4.04-5.58-4.27-9.25-0.58-14.16C1038.63,360.37,1045.27,363.45,1050.74,367.16z M1046.96,433.55
		c0,0.57,0,1.14,0,1.76c-1.2-0.21-2.19-0.38-3.32-0.57c0-5.98,0-11.99,0-18.27c3.25,2.83,8.25,2.88,11.06,7.38
		c-1.47,0.13-2.45,0.21-3.43,0.29C1049.83,427.28,1048.4,430.41,1046.96,433.55z M1049.63,440.1c-0.38,0.85-0.97,2.17-1.55,3.5
		c0,0,0,0,0,0c0,0,0,0,0,0c-2.42-1.34-4.84-2.68-7.42-4.1c0.87-0.58,1.76-1.18,2.3-1.55C1045.56,438.79,1047.5,439.42,1049.63,440.1
		z M1051.81,416.61c-1.36-1.26-3.53-1.59-5.17-2.6c-3.88-2.39-6.98-5.49-7.62-10.27c-0.22-1.66-0.04-3.37-0.04-5.06
		c1.87,5.24,5.74,8.57,9.76,11.66c2.78,2.14,5.88,3.87,8.85,5.78c2.87,1.85,3.11,6.14,0.64,7.84
		C1056.1,421.46,1054.17,418.79,1051.81,416.61z M1039.59,422.32c-0.11,0.06-0.21,0.12-0.32,0.17c-0.16-0.3-0.32-0.59-0.48-0.89
		c0.12-0.06,0.24-0.12,0.36-0.18C1039.29,421.73,1039.44,422.02,1039.59,422.32z M1042.78,399.18c0-7.19,0-14.73,0-22.26
		c0-0.93,0-1.87,0-2.92c3.28,1.65,6.44,3.25,9.74,4.91c0,0.91,0,1.96,0,3.01c0,3.69,0,7.37,0,10.27
		C1049.03,394.69,1045.93,396.91,1042.78,399.18z M1055.8,397.67c-3.15,1.94-6.45,3.63-10.49,5.85c0-1.17-0.34-2.35,0.06-3.17
		c1.95-4.07,6.88-6.48,13-6C1057.32,395.76,1056.78,397.07,1055.8,397.67z M1036.87,368.16c4.17,3.03,2.51,6.97,2.93,10.9
		C1035.61,376.06,1037.3,372.12,1036.87,368.16z M1039.56,347.65c-2.13,1.92-3.84,3.47-5.55,5.02c-1.06,3.96-0.69,7.67,0.58,11.68
		c1,3.14-0.06,6.87,0.23,10.31c0.2,2.43,0.33,5.86,1.86,6.99c4.38,3.22,3.69,7.45,3.79,11.67c-5.13,3.52-5.53,6.17-2.31,15.3
		c-3.29,3.54-3.22,5.7,0.43,12.18c-0.2,0.27-0.41,0.53-0.61,0.8c-2.32-2.03-4.98-3.77-6.88-6.13c-6.49-8.03-9.16-17.48-10.88-27.61
		c-2.43-14.29,1.83-26.97,7.18-39.64c2.81-4.83,7.2-7.88,12.16-11.04C1039.56,341.3,1039.56,344.91,1039.56,347.65z M1025.16,343.64
		c-1.75,2-2.93,4.22-3.83,6.58c-0.89,2.35-1.5,4.84-2.09,7.35c-1.9,8.1-4.47,16.14-3.76,24.55c0.59,6.95,1.93,13.84,3.02,21.34
		c-2.14,0.64-4.87,1.45-8.54,2.54c-0.16-2.55-0.35-4.59-0.4-6.64c-0.05-1.78,0.1-3.57,0.1-5.35c0.01-6.59-0.45-13.23,0.11-19.77
		c0.94-11.09,4.12-21.56,10.9-31.09c1.21,0,2.62,0,4.03,0c0.36-0.23,0.73-0.46,1.09-0.68c0.16,0.19,0.32,0.38,0.48,0.57
		C1025.9,343.23,1025.53,343.44,1025.16,343.64L1025.16,343.64z M988.18,335.33c3.81,1.46,7.63,2.93,11.44,4.39
		c2.32-0.2,4.63-0.4,7.45-0.64c-0.61,1.34-1.14,2.49-1.84,4.01c0.53,0.59,1.19,1.32,1.98,2.2c-0.25,1.66-1.79,2.64-3.97,2.88
		c-0.93,0.11-1.98,0.08-3.09-0.09c-0.37-0.06-0.75-0.13-1.13-0.21c-1.92-0.43-3.97-1.25-5.9-2.47c-0.42-0.05-0.83-0.1-1.25-0.14
		c0.01-0.17,0.01-0.33,0.02-0.5c0.54,0,1.08,0,1.62,0c0,0,0,0,0,0c3.56,1.14,5.86,1.56,7.9,1.17c1.23-0.23,2.36-0.75,3.62-1.58
		c-2.02-0.49-3.71-0.9-5.4-1.31c-3.57-1.43-7.13-2.86-10.7-4.29c-0.25-0.19-0.5-0.39-0.75-0.58l0,0c0.25,0.19,0.5,0.39,0.75,0.58
		c-0.25-0.19-0.5-0.39-0.75-0.58c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c0,0,0,0,0,0c-1.45-1.66-2.89-3.32-4.34-4.98
		c-0.23-0.37-0.46-0.74-0.69-1.11c0.12-0.09,0.23-0.18,0.35-0.27c0.29,0.27,0.57,0.53,0.86,0.8c0,0,0,0,0,0
		C985.62,333.52,986.9,334.42,988.18,335.33z M983.98,342.55c2.53,2.24,4.81,4.26,7.09,6.28v0c1.38,2.39,2.77,4.77,4.15,7.16
		c-0.31,0.32-0.61,0.65-0.92,0.97c-1.1-0.5-2.2-1.01-3.3-1.51v0c-1.56-0.66-3.13-1.31-4.69-1.97c-0.2,0.17-0.4,0.34-0.6,0.51
		c1.56,1.64,3.12,3.28,4.68,4.92c0.23,0.02,0.46,0.08,0.68,0.16c0.81-0.07,1.92-0.06,2.85-0.09c0.93-0.04,1.69-0.13,1.78-0.42
		c0.69-2.27,0.53-4.79,0.69-7.31c2.07,0.21,4.96,0.5,7.79,0.79c0.53,15.8,1.06,31.61,1.59,47.41c0.34,0.05,0.68,0.11,1.02,0.16
		c-0.97,0.56-1.94,1.12-3.59,2.07c-0.92-11.88-1.8-23.25-2.67-34.62c-0.36,0.02-0.72,0.05-1.08,0.07
		c-1.58,11.77,0.94,23.44,1.67,35.15c-3.87,1.64-7.4,1.68-10.71-0.85c-2.23-2.26-3.72-4.57-2.3-8.05c3.78-9.25,0.2-17.9-2.52-26.59
		c-0.07-0.21-0.09-0.44-0.11-0.68c-0.01-0.23-0.01-0.47-0.04-0.7c-0.26-1.23-0.58-2.46-0.86-3.69c-0.28-1.23-0.53-2.47-0.67-3.71
		c-0.26-2.43-0.06-4.9-0.06-7.85c-3.31,3.62-3.17,7.54-2.58,11.46c0.2,1.36,0.51,2.71,0.84,4.05s0.69,2.68,1.01,4.03
		c1.83,3.84,2.83,7.75,3.02,11.77c0.15,3.12-0.19,6.31-0.99,9.57c-5.17-4.93-6.88-10.49-4.48-17.16c0.23-0.64,0.31-1.34,0.35-2.05
		c0.04-0.71,0.04-1.43,0.11-2.13c-0.08-0.49-0.16-0.98-0.23-1.47c-0.5-3.44-0.81-6.95-1.86-10.21c-1.23-3.81-0.7-7.06,1.24-10.26
		C981.41,345.91,982.78,344.22,983.98,342.55z M980.5,389.19c0,4.69,0,9.37,0,14.06c-0.29,0.01-0.59,0.02-0.88,0.03
		c0-4.69,0-9.37,0-14.06C979.91,389.21,980.21,389.2,980.5,389.19z M1008.76,420.56c2.49-2.96,8.42-1.39,11.15,2.84
		c-1.28,1.08-2.59,2.19-3.98,3.36c-2.73-0.87-5.48-1.74-8.23-2.62c-0.12,0.23-0.24,0.46-0.36,0.69c2,1.42,3.99,2.84,5.99,4.26
		c0.24,0.37,0.48,0.75,0.72,1.12c-0.29,0.26-0.57,0.51-0.86,0.77c-0.12-0.43-0.23-0.86-0.35-1.29c-1.04-0.41-2.08-0.81-3.16-1.24
		c-1.09-0.43-2.23-0.87-3.46-1.36c-0.39,5.35,3.88,5.39,6.04,7.59c-0.12,0.27-0.35,0.79-0.51,1.15c-3.15-1.54-6.29-3.08-10.14-4.97
		C1003.95,427.43,1006.07,423.76,1008.76,420.56z M1022.46,434.02c1.09,0.1,1.96,2.72,3.29,4.79c-6.42,0.7-7.79,2.25-9.11,8.11
		c0.35-3.44,0.29-6.99,1.24-10.25C1018.28,435.36,1020.96,433.88,1022.46,434.02z M1014.11,455.77c-0.28-0.17-0.54-0.35-0.77-0.55
		c-0.03-0.03-0.07-0.05-0.1-0.08c-0.31-0.28-0.59-0.59-0.82-0.92c-0.47-0.66-0.8-1.41-1.08-2.22c-0.14-0.41-0.26-0.83-0.37-1.26
		c-0.29-1.09-0.54-2.26-0.9-3.46c3.92,2.11,5.22,4.86,5.42,8.3c0.23,0.38,0.47,0.77,0.7,1.15c-0.13,0.1-0.25,0.19-0.38,0.29
		c-0.28-0.27-0.55-0.53-0.83-0.8l0,0C1014.67,456.09,1014.38,455.94,1014.11,455.77z M1002.59,447.37c-2.03,2.56-3.35,5.7-5.26,9.07
		c-0.28,0.07-0.82,0.22-1.46,0.39c-0.23,0.06-0.47,0.13-0.72,0.19c-0.07,0.02-0.13,0.03-0.2,0.05c-0.1,0.03-0.2,0.05-0.31,0.08
		c-1.52,0.71-3.03,1.42-4.55,2.13c2.04-4.66,2.64-10.03,7.35-13.03c2.05-0.95,4.09-1.92,6.15-2.86c1.93-0.87,3.87-1.71,6.49-2.86
		C1007.18,443.12,1004.51,444.95,1002.59,447.37z M1007.18,459.79c-1.93-0.73-3.13-3.39-2.89-6.78c2.39,1.3,4.75,2.24,6.68,3.73
		c1.81,1.39,3.16,3.37,4.71,5.1c0.36-0.15,0.72-0.3,1.08-0.44c0.68-3.21,1.36-6.41,1.93-9.13c0.56,0.64,1.48,1.69,2.42,2.76
		c0.03-0.02,0.05-0.05,0.08-0.07c0-3.49,0-6.98,0-10.62c0.95-0.59,1.91-1.19,3.78-2.34c0.6,3.35,1.09,6.07,1.49,8.32
		c-2.08,1.83-3.68,3.24-5.27,4.64c0,0.05,0,0.1,0,0.16l-0.08-0.08c-0.92,3.09-1.85,6.18-2.66,8.9
		C1015.08,462.7,1011.11,461.29,1007.18,459.79z M1013.63,416.05c-0.52-2-1.03-3.98-1.52-5.88c2.86-0.91,5.83-1.85,8.9-2.83
		c4.21,7.88,9.39,15.35,18.37,20.74c-4.4,1.09-7.82,1.94-11.26,2.79C1024.05,420.64,1022.96,417.21,1013.63,416.05z M1033.4,433.41
		c2.35,2.15,2.34,4.44,1.17,7.01c-1.5,3.29-2.83,6.65-4.13,9.74C1027.19,445.2,1028.7,435.02,1033.4,433.41z M1033.51,446.92
		c0.37-2.37,5.49-3.62,8.66-1.99c4.87,2.51,5.87,3.22,3.79,7.19c-1.54-2.87-2.75-5.93-7.46-4.7c2.56,4.01,4.97,7.8,7.38,11.58
		c-0.49,0.33-0.97,0.66-1.46,0.99c-4.41-2.66-5.18-7.89-7.42-13.39c-0.9,2.34-1.4,3.65-1.94,5.08
		C1032,451.15,1033.26,448.51,1033.51,446.92z M1057.12,426.55c-0.27,2.62-0.55,5.32-0.83,8.02c-0.3-0.17-0.6-0.35-0.9-0.52
		c-1.83,1.2-3.65,2.41-5.46,3.6C1050.67,430.16,1051.43,425.04,1057.12,426.55z M1057.83,438.92c2.43,1.16,4.6,2.2,6.84,3.28
		c-1.55,2.29-3.07,4.53-4.68,6.91c-1.23-1.87-2.51-3.8-3.95-5.97C1056.58,441.87,1057.16,440.5,1057.83,438.92z M1068.12,443.78
		c1.2,1.9,2.18,3.45,3.11,4.93c-1.79,0.65-3.35,1.22-4.89,1.78c0,1.76,0,3.16,0,5.21c-1.49-0.94-2.62-1.65-3.96-2.49
		C1064.27,450.11,1065.99,447.28,1068.12,443.78z M1066.31,434.04c-2.04-0.27-4.08-0.54-6.43-0.86c1.24-5.01,2.44-9.9,3.65-14.79
		c-1.55-2.08-3.13-4.14-4.62-6.27c-0.74-1.05-1.7-2.17-1.85-3.34c-0.29-2.35-0.09-4.76-0.09-6.04c1.93-3.09,3.35-5.35,5.14-8.21
		c-7.8-3.12-5.63-9.43-5.85-14.94c1.48-1.09,2.44-2.51,2.88-4.09c0.15-0.53,0.23-1.07,0.26-1.63c0.01-0.28,0.01-0.56,0-0.85
		c-0.1-2-0.93-4.12-2.47-6.13c-0.34-1.35-0.67-2.71-1-4.02s-0.64-2.57-0.93-3.73c6.83-5.92,6.39-14.95-0.09-16.4
		c0-1.16,0-2.34,0-3.86c13.53,6.01,23.87,14.98,29.25,28.85c2.26,5.81,4.29,11.77,5.65,17.83c0.95,4.26,0.49,8.82,0.84,13.23
		c0.71,9-0.93,17.56-5.13,25.5c-1.29,2.43-3.69,4.27-5.59,6.39c-2.37,1.97-4.73,3.95-7.01,5.85
		C1070.51,435.63,1068.41,434.84,1066.31,434.04z M1009.04,273.99c4.57-1.76,6.09,2.59,9.62,4.72c-0.73-1.67-1.1-2.54-1.78-4.1
		c12.74,5.52,23.4,13.44,34.8,20.06c-0.1,0.32-0.2,0.64-0.31,0.96c-5.33-1.36-10.66-2.69-15.98-4.08
		c-5.29-1.38-10.52-3.79-15.84-4.02c-10.28-0.45-21.06-1.29-29.64,6.27c-2.5,2.21-4.84,5.34-5.66,8.47c-0.58,2.2,1.55,5.1,2.58,8.02
		c-4.49,4.89-3.19,11.07-1.68,17.48c-1.46,0.25-2.91,0.5-5.32,0.91c0.7,2.63,0.83,5.31,2.08,7.27c1.87,2.93,1.55,4.91-0.98,7.01
		c-0.26,0.22-0.51,0.46-0.74,0.71c-0.7,0.76-1.31,1.64-1.98,2.44c-0.9,1.52-1.3,3.07-1.44,4.63c-0.29,3.44,0.72,6.95,0.58,10.4
		c-0.21,6.98-0.13,13.99-0.75,20.94c-0.32,3.63-2,7.12-2.81,10.74c-0.48,2.16-0.35,4.44-0.68,6.64c-0.37,2.54,0.15,5.4-2.62,7.16
		c-0.56,0.35-0.77,1.42-0.93,2.2c-0.71,3.38-1.34,6.77-2.15,10.92c-0.57-0.29-1.11-0.56-1.61-0.81c-1.52-0.77-2.79-1.41-4.07-2.05
		c-0.37-0.42-0.74-0.85-1.11-1.27c-0.85-0.97-1.7-1.94-2.54-2.92c-1.39-1.27-2.78-2.55-4.37-4.01c-0.53-0.49-1.08-1-1.67-1.53
		c-0.71-2.93,5.77-6.29,0.38-9.91c2.74-3.53,2.84-7.04,0.8-10.78c0.73-1.77,1.51-3.66,2.44-5.94c0.8,0,1.96,0,3.12,0
		c0.96-0.51,1.91-1.02,2.87-1.53c0.92-1.2,1.8-2.41,2.65-3.64c1.7-2.45,3.24-4.97,4.56-7.57c0.66-1.3,1.27-2.62,1.81-3.97
		c2.17-5.39,3.31-11.17,2.92-17.58c-3.36-3.12-9.61-2.29-11.73-7.41c-1.1-2.66-1.42-5.63-2.06-8.3c7.25-3.88,9.63-10.53,6.14-16.73
		c0,2.32,0,4.63,0,6.95c-0.12,0-0.24-0.01-0.36-0.01c-0.79-2.82-1.59-5.64-2.45-8.73c0.37-0.05,0.72-0.11,1.07-0.16
		c0.34-0.05,0.68-0.1,1.02-0.15c-0.01-0.02-0.01-0.03-0.02-0.05l0,0c0,0,0,0,0,0l0.04,0.05c0.21-1.46,0.42-2.92,0.63-4.39
		c0.07-0.22,0.13-0.44,0.2-0.66c0.21-0.67,0.42-1.38,0.69-2.25c-1.3-0.72-2.57-1.43-3.85-2.14c-0.22,0.23-0.44,0.47-0.66,0.7
		c0.7,0.76,1.4,1.51,2.63,2.84c-1.79,0-3.14,0-3.98,0c-0.83-2.42-1.53-4.45-2.23-6.48c-0.37,0.09-0.74,0.18-1.11,0.28
		c-0.12,1.58-0.25,3.17-0.37,4.83c-0.77,0-1.48,0-2.24,0c0.62-0.71,1.18-1.36,2.1-2.42c-1.76-0.53-3.12-0.94-5.22-1.56
		c-0.34,2.08-0.6,3.72-0.87,5.36c-2.62,4.59-4.87,9.31-2.99,15.04c0.78,2.39,2.79,4.4,1.63,7.44c-0.32,0.83,1.43,2.46,2.27,3.78
		c0,3.5,0,7.23,0,11.07c1.8-1.68,1.89-1.69,1.14-7.88c-0.33-2.76-1.07-5.47-1.72-8.68c1.31,0.63,2.63,1.27,4.25,2.05
		c-2.71,6.24-0.45,11.69,2.15,17.09c0.06,0.27,0.12,0.54,0.18,0.82c-0.19-0.14-0.37-0.29-0.56-0.43c-3.3-1.37-6.6-2.73-10.04-4.16
		c1.56-1.87,2.83-3.39,4.09-4.9c-2.83-1.33-5.01-2.36-7.19-3.38c-0.24-0.46-0.48-0.92-0.72-1.38c-1.19,1.66-2.38,3.31-3.57,4.97
		c0.82-7.71,2.21-15.28-0.37-22.85c6.13-7.14,8.63-16.57,3.73-21.56c7.41-6.85,3.72-17.15-3.5-22.43c2.66-3.08,3.25-6.66,1.76-10.37
		c-1.31-3.25-3.04-6.33-3.97-9.92c2.45,3.32,5.33,6.42,7.27,10.01c5.07,9.39,9.74,18.96,12.09,29.52c0.68,3.02,2.57,5.78,4.27,9.45
		c0.67-1.04,1.1-1.72,1.45-2.27c0.21-0.33,0.38-0.6,0.56-0.88c1.13-1.71,2.26-3.43,3.4-5.14c4.88-3.99,9.75-7.99,14.44-11.82
		c2.85,3.05,5.04,5.4,7.22,7.74c0.21-0.15,0.42-0.29,0.63-0.44c-0.86-2.91-1.72-5.81-2.69-9.07c2.96-0.77,5.18-1.35,7.42-1.93
		c2.39,3.05,4.73,6.04,7.07,9.02c0.18-0.09,0.37-0.18,0.55-0.27c-1.14-3.2-2.28-6.4-3.53-9.91c3.02-0.5,5.42-0.89,7.38-1.22
		c2.62,3.29,4.99,6.27,7.36,9.24c0.32-0.21,0.65-0.43,0.97-0.64C1011.95,278.99,1010.49,276.48,1009.04,273.99z M953.72,429.76
		L953.72,429.76c-2.06,0.08-3.97-0.51-5.5-1.62c-0.44-0.32-0.85-0.68-1.22-1.08c-0.56-0.6-1.05-1.29-1.44-2.05
		c-0.52-1.02-0.87-2.17-1.02-3.43c-0.28-2.37-0.05-4.79-0.05-7.19c0.51-0.02,1.02-0.05,1.52-0.07c0.32,2.52,0.44,5.08,0.99,7.54
		c0.62,2.72,1.2,4.06,2.41,4.4c0.27,0.08,0.57,0.11,0.91,0.09c0.17-0.01,0.35-0.03,0.54-0.06c0.57-0.09,1.24-0.27,2.02-0.53
		c0.26-0.09,0.54-0.18,0.83-0.29v0l0,0c0,0.02,0,0.05,0,0.07c0.26-0.47,0.52-0.95,0.78-1.42c0.27-1.21,0.54-2.42,0.82-3.64
		c-0.86-0.96-1.72-1.93-2.6-2.92s-1.79-2.01-2.75-3.08c0.52-0.78,1.12-1.68,2.02-3.02c-0.99,0.08-1.55,0.12-2.12,0.17
		c-0.28,0.04-0.56,0.08-0.84,0.13c0.15-0.18,0.29-0.36,0.44-0.55c0.67-0.24,1.77-0.84,1.93-0.65c2.11,2.52,4.09,5.15,6.11,7.76
		c0.92,3.2,1.06,5.4,0.19,7.28c-0.15,0.33-0.35,0.66-0.57,0.98c-0.12,0.17-0.24,0.34-0.38,0.51c-0.25,0.3-0.52,0.61-0.83,0.91
		c-0.02,0.02-0.04,0.04-0.06,0.06C955.26,428.61,954.55,429.17,953.72,429.76L953.72,429.76L953.72,429.76L953.72,429.76
		L953.72,429.76C953.72,429.76,953.72,429.76,953.72,429.76z M921.92,377.91c-1.44-2.29-1.3-5.57-1.96-8.93
		c2.08,1.58,3.66,3.05,5.48,4.1c3.67,2.13,6.06,0.8,6.93-3.42c2.14-10.34,4.39-20.66,6.77-31.02c0.56,13.28,2.69,26.1,10.51,37.56
		c-2.43,1.95-2.84,4.15-1.43,7.09c0.59,1.23-0.36,3.2-0.71,5.46c0.5,0.56,1.21,1.34,1.92,2.12c-2.8,4.97-3.13,10.2-1.53,15.92
		c-5.4-2.36-10.39-4.55-15.48-6.77c-1.05-6.28-6.27-9.89-10.26-14.64C924.34,382.88,923.69,380.72,921.92,377.91z M915.84,322.28
		c-0.65,2.15-1.3,4.3-1.85,6.13c-1.9-3.92-1.41-12.37,1.41-18.89c0.68,0.74,1.27,1.39,1.87,2.04c2.85,1.5,5.7,2.99,8.55,4.49
		c4-0.07,7.99-0.14,12.14-0.21c1.35,13.16-4.06,26.28-5.81,39.92c-0.38,0.02-0.76,0.04-1.14,0.06c-0.64-10.49-1.28-20.97-1.92-31.46
		c0.37-0.06,0.74-0.13,1.1-0.19c0.84,3.06,1.67,6.12,2.46,8.99c2.34-3.53,0.85-9.34-3.72-11.71c-0.55,1.16-1.53,2.3-1.59,3.48
		c-0.76,14.24,0.84,28.32,2.99,42.36c0.05,0.31,0.33,0.59,0.5,0.87c-1.93,1.42-4.09,1.33-6.55-0.29c-1.12-0.73-2.3-1.78-3.54-3.14
		c-0.73-0.78-1.35-1.61-1.87-2.48c-1.55-2.62-2.2-5.6-2.25-8.58c-0.16-9.94,0.19-19.9,0.27-29.85
		C916.9,323.31,916.21,322.79,915.84,322.28z M900.78,360.67c-0.02-0.14-0.04-0.27-0.07-0.41c-0.06-0.39-0.13-0.78-0.21-1.17
		c-0.23-0.38-0.45-0.75-0.67-1.13c-0.67-1.11-1.3-2.17-1.79-2.99c1.02-1.13,1.96-2.18,2.87-3.19c0.9-1.01,1.77-1.97,2.64-2.93
		c1.58-3.2,3.17-6.4,4.75-9.61c0.16,0.02,0.32,0.04,0.49,0.06c-0.42,5.67-0.95,11.33-1.24,17c-0.25,4.86-0.13,9.73-0.38,14.58
		c-0.07,1.4-1.04,2.73-1.29,4.14c-0.5,2.83-0.79,5.69-1.16,8.53c-1.87-0.68-3.91-1.42-6.13-2.22c0.93-5.04,2.25-10.07,2.46-15.2
		c0.02-0.47,0.03-0.93,0.03-1.4c0-0.01,0-0.02,0-0.03C901.06,363.38,900.97,362.03,900.78,360.67z M893.16,384.77
		c-0.08,0.14-0.57,1.02-0.76,1.37c-4.67-0.84-8.98-1.61-12.57-2.26c1.2-0.63,3.41-1.8,5.85-3.08c0.22,0.17,0.82,0.65,1.87,1.47
		c0.66-1.26,1.53-2.26,1.69-3.37c0.26-1.87,0.21-3.82,0.04-5.71c-0.4-4.3,1.24-7.91,3.82-11.3c-0.01,0.04-0.02,0.07-0.03,0.11
		c0.49-1.25,0.8-2.02,1.23-3.12c0.86,0.59,1.51,1.04,2.5,1.72c-1.33,0.52-2.27,0.9-3.74,1.48c-0.55,2.41-1.59,4.87-1.38,7.22
		c0.2,2.25,0.65,5.24,4.1,6.06c0.55,3.5-2.79,3.06-5.35,4.85C891.22,381.53,892.05,382.92,893.16,384.77z M900.22,399.78
		c-3.07-1.51-5.3-2.61-7.69-3.79C895.69,393.16,898.8,394.46,900.22,399.78z M886.2,406.74c0.57,1.58,1.09,3.04,1.7,4.74
		c-3.67,1.24-7.2,2.44-10.72,3.64c-0.06-0.17-0.11-0.34-0.17-0.51C879.91,412.12,882.82,409.63,886.2,406.74z M884.99,400.18
		c-0.75-0.52-1.5-1.04-2.25-1.56c0.13-0.21,0.26-0.42,0.39-0.63c0.81,0.45,1.62,0.9,2.43,1.35
		C885.37,399.61,885.18,399.9,884.99,400.18z M879.53,391.69c0.84,2.17,1.47,3.79,2,5.17c-5.77-2.64-11.92-5.45-18.08-8.27
		c0.12-0.37,0.23-0.74,0.35-1.11c1.09,0,2.59-0.46,3.19,0.08c3.88,3.48,8.45,2.12,12.82,2.16c3.38,0.03,6.75,0.06,10.85,0.09
		c-0.87,2.15-1.28,3.17-1.8,4.47C885.95,393.47,883.19,392.7,879.53,391.69z M852.95,362.6c-0.3,3.11-0.54,5.61-0.78,8.12
		c-2.83-0.11-3.7-2.48-3-7.23c2.86-2.03,4.88-3.46,6.82-4.84c0.56,1.15,1.23,2.51,1.89,3.87c0.44-0.08,0.88-0.16,1.33-0.24
		c-0.24-3.74-0.74-7.48-0.64-11.21c0.1-3.69-1.55-7.97,3.05-11.04c1.34,1.73,3.99,3.63,3.79,5.17c-0.62,4.85-2.27,9.57-3.61,14.31
		c-0.54,1.91-1.34,3.75-2.14,5.95C858.05,364.76,855.83,363.82,852.95,362.6z M859.15,376.32c-0.3,2.01-0.58,3.93-0.89,6
		c-2.97,0-5.76,0-8.82,0c0-2.32,0-4.39,0-6.9C852.58,375.71,855.79,376,859.15,376.32z M838.29,427.7c-0.2,0.47-0.4,0.94-0.6,1.42
		l-0.01,0.01c-0.83,2.11-1.65,4.22-2.83,7.23c-1.07-1.75-1.63-2.66-2.19-3.58c-0.66-0.77-1.98-1.62-1.89-2.28
		c1.37-10.1,0.49-19.81-4.4-28.97c5.61,4.69,10,9.93,11.61,16.44c0.44,1.78,0.67,3.65,0.66,5.62
		C838.63,424.91,838.52,426.28,838.29,427.7z M766.14,396.79c-3.9,2.25-6.1-1.82-9.7-2.18c1.92-2.51,3.52-4.61,5.13-6.71
		c-0.44-0.44-0.88-0.88-1.31-1.32c-2.01,1.71-4.02,3.43-6.52,5.55c-0.81-0.85-1.63-1.71-2.46-2.58s-1.66-1.75-2.49-2.62
		c-0.16,0.02-0.32,0.04-0.5,0.06c-0.51,0.06-1.11,0.13-2.04,0.24c2.06,2.24,3.77,4.1,5.47,5.95c-1.18,1.12-2.36,2.15-3.61,2.92
		c-0.79,0.48-1.61,0.85-2.47,1.07c-0.3,0.08-0.6,0.14-0.91,0.17c-1.17,0.14-2.43,0-3.81-0.54c-0.47-0.19-0.93-0.38-1.4-0.56
		c0,0.01,0.01,0.01,0.01,0.02c-2.64-0.5-5.27-0.99-7.91-1.49c-0.12,0.01-0.24,0-0.35-0.02c-0.11-0.02-0.23-0.06-0.33-0.11
		c-1.86-0.22-3.72-0.44-5.61-0.67c-1.13-0.13-2.27-0.27-3.44-0.41c-1.04-0.12-2.11-0.25-3.19-0.38c0.94-9.22-2.37-18.53,0.81-27.72
		c0.3-0.88,0.67-1.73,1.07-2.58c0.39-0.85,0.8-1.69,1.18-2.54c0.2-0.24,0.4-0.48,0.6-0.72c-0.01,0.01-0.02,0.01-0.03,0.02
		c0,0,0.01-0.01,0.02-0.01l0.02-0.01c0,0,0,0.01-0.01,0.01c0.44-0.24,0.89-0.49,1.33-0.73l0,0l0,0c0.2-0.18,0.4-0.37,0.6-0.58
		c0.69-0.71,1.36-1.51,2.06-2.18c0.21-0.21,0.43-0.39,0.65-0.57c0.3-0.24,0.6-0.45,0.91-0.59c0.22-0.1,0.44-0.18,0.67-0.22
		c6.22-1.23,11.99,0.19,16.63,4.88c1.57,2.14,3.07,4.32,4.72,6.4c1.01,1.27,2.18,2.02,3.52,2.09c0.8,0.04,1.66-0.17,2.58-0.67
		c0,0,0,0,0,0c1.73-2.12,3.46-4.24,5.18-6.36c1.21-1.89,2.42-3.78,3.63-5.67c5.79-8.92,17.58-8.47,24.49,0.9
		c3.55,4.82,4.92,10.56,5.36,16.48c0.22,2.99,0.32,6,0.42,9c0.03,1,0.07,2,0.11,3c0,0.44,0,0.87,0,1.31
		c-0.17,1.62,1.66,4.17-1.94,4.2c-2.84-0.01-5.68-0.02-8.52-0.03c-2.4,1.02-4.8,2.04-7.19,3.06
		C773.74,394.48,769.94,395.64,766.14,396.79z M775.31,412.66c-5.29,0.81-10.65,1.64-15.85,2.44c0-4.01,0-7.51,0-11.68
		c2.47-0.3,4.74-0.43,6.95-0.9c2.15-0.45,4.22-1.25,6.33-1.89C773.58,404.57,774.41,408.42,775.31,412.66z M756.01,410.01
		c-0.02,4.76-1.77,6.16-6.33,5.44c-5.32-0.85-10.67-1.48-15.95-2.19c0.3-2.57,0.5-5.09,0.92-7.57c0.54-3.17,2.54-4.28,5.56-3.73
		c5.03,0.91,10.11,1.58,15.79,2.45C756.01,406,756.01,408,756.01,410.01z M779.65,233.54c0,0,0.01,0.01,0.02,0.01
		C779.66,233.55,779.65,233.54,779.65,233.54c0.05-0.03,0.09-0.06,0.12-0.08c0.03-0.06,0.05-0.09,0,0c0.08-0.05,0.06-0.04,0,0
		c-0.02,0.04-0.04,0.08-0.07,0.13c-0.02,0-0.04,0-0.06,0c-0.02,0.5-0.05,1-0.08,1.72c-0.02,0.44-0.04,0.97-0.08,1.62
		c-10.82-0.51-21.55-1.02-32.36-1.54c0-1.19,0-2.47,0-3.62c6.25,0.83,12.5,1.96,18.79,2.38c4.54,0.3,9.15-0.34,13.72-0.56
		C779.65,233.57,779.65,233.55,779.65,233.54z M756.19,241.13c3.01,0.14,6.07,0.27,9.13,0.41c0.45-0.02,0.91-0.05,1.36-0.08
		c0.06,0,0.11-0.01,0.17-0.01c0.91-0.05,1.82-0.11,2.73-0.15c0.05,0,0.1-0.01,0.15-0.01c0.92-0.05,1.84-0.09,2.76-0.13
		c0.03,0,0.07,0,0.1,0c0.94-0.03,1.88-0.06,2.82-0.06c0.01,0,0.01,0,0.02,0c1.44-0.01,2.89,0.02,4.33,0.11
		c2.88,0.18,5.76,0.6,8.63,1.43c1.97,0.57,4.39-0.41,6.6-0.63c1.86-0.18,3.72-0.3,5.58-0.45c1.49-0.18,2.98-0.36,4.26-0.52
		c1.05,2.98,2.31,5.99,3.16,9.12c0.6,2.21,1.1,4.62,0.87,6.85c-0.47,4.57-2.18,5.48-6.83,5.03c-1.07-0.1-2.15-0.19-3.23-0.25
		c-2.2-0.13-4.4-0.21-6.61-0.29c-1.04-0.04-2.07-0.08-3.11-0.13c-8.4-0.72-16.81-1.45-25.21-2.17c-5.51-0.43-11.02-0.86-15.84-1.23
		c-1.59-1.34-2.7-2.28-3.81-3.21c-0.31,0.29-0.61,0.58-0.92,0.87c-1.77-3.68-3.55-7.37-5.29-10.99c1.11-1.28,2.1-2.42,3.62-4.17
		C746.25,240.69,751.16,240.91,756.19,241.13z M781.98,235.24c3.46-0.3,6-0.1,7.96,2.29C787.43,237.22,784.26,239.37,781.98,235.24z
		 M793.26,234.43c2.21-0.38,4.33-0.75,6.66-1.15c0.64,1.63,1.18,2.98,1.74,4.42c-2.84,0-5.4,0-8.18,0
		C793.42,236.66,793.36,235.77,793.26,234.43z M848.66,240.39c2.7,5.4,4.09,11,1.85,16.87c-0.57,1.5-2.17,2.61-3.29,3.9
		c-0.28-0.17-0.57-0.33-0.85-0.5c0-0.91-0.27-1.93,0.05-2.7c0.91-2.14,1.16-3.92-1.38-5.04c0.17-0.27,0.33-0.54,0.5-0.82
		c0.27-2.11,0.55-4.21,0.83-6.36s0.56-4.35,0.86-6.64C847.87,239.68,848.27,240.04,848.66,240.39z M848.76,217.58
		c0.48,3.3,0.96,6.62,1.45,9.99C846.91,223.71,846.38,220.23,848.76,217.58z M850.34,275.89c0.27-0.68,1.48-1.25,2.33-1.38
		c2.53-0.37,5.09-0.48,7.64-0.7c-8.58,18.96-6.82,37.34,3.05,55.26c-2.57,0.8-5.03,1.57-7.65,2.39c-5.44-7.89-7.69-16.91-8.7-26.36
		c-0.37-3.49-1.01-6.95-1.31-10.45C845.12,287.91,847.94,281.92,850.34,275.89z M858.03,227.95c-2.9-1.94-5.16-4.05-4.44-7.99
		c-0.67-3.12,1.13-4.03,4.05-4.35c-0.26,1.34-0.48,2.49-0.7,3.64C857.29,222.04,857.64,224.83,858.03,227.95z M857.36,242.15
		c0,4.27,0,8.37,0,12.47c-1.5-1.17-2.74-2.14-4.49-3.5c0-2.27,0-5.36,0-8.97C854.31,242.15,855.76,242.15,857.36,242.15z
		 M853.75,268.83c3.46-0.52,6.88-1.04,10.31-1.55c0.11,0.37,0.21,0.74,0.32,1.11c-3.87,1.12-7.74,2.23-12.51,3.61
		C852.83,270.38,853.31,269.58,853.75,268.83z M872.97,237.82c8.4,6.24,17.26,11.88,25.46,18.36c1.56,1.23,2.52,3.23,3.42,5.29
		c0.54,1.24,1.07,2.5,1.69,3.64c0,0,0,0,0,0l0,0l0,0h0c1.1,1.63,2.23,3.24,3.33,4.87c1.1,1.63,2.19,3.26,3.21,4.94
		c0.28,0.46,0.21,1.14,0.1,1.84c-0.06,0.42-0.14,0.85-0.16,1.24c-0.08,0.68-0.16,1.36-0.24,2.04c-0.24,2.05-0.49,4.15-0.75,6.42
		c-1.17-2.96-2.25-5.7-3.33-8.44c0,0,0,0,0,0l0,0c0.02,0,0.03,0,0.05,0c-0.29-0.49-0.59-0.98-0.88-1.47
		c-2.68-3.81-5.36-7.62-8.04-11.43c-0.84-0.87-1.67-1.73-2.51-2.6c-0.49-0.57-0.97-1.13-1.46-1.69c0,0,0,0,0,0c0,0,0,0,0,0l0,0
		c-0.6-0.36-1.18-0.74-1.77-1.12c-0.4-0.26-0.8-0.52-1.19-0.78c-1.64-1.07-3.29-2.12-5.08-2.83c-3.07-1.23-6.41-1.97-9.7-2.4
		c-4.17-0.55-8.41-0.59-12.94-0.87c0-2.84,0-5.64,0-8.45c-0.38-2.42-0.76-4.85-1.19-7.57C865.02,234.79,868.88,234.78,872.97,237.82
		z M861.42,218.34c2.68-1.3,2.68-1.3,6.42,8.44C863.93,228.72,861.26,225,861.42,218.34z M866.36,338.03
		c-3.35-0.82-6.38-1.56-9.42-2.3c0.06-0.37,0.12-0.74,0.18-1.11c3.51,0.92,6.84-3.86,10.64,0.19
		C867.22,336.05,866.77,337.08,866.36,338.03z M869.12,343.89c1.16,1.32,2.29,2.61,3.29,3.76c2.87,0.2,5.78,0.4,8.01,0.55
		c-2.43,4.4-4.93,8.93-7.38,13.37c-2.22-0.85-4.34-1.66-6.05-2.31C867.68,354.3,868.35,349.4,869.12,343.89z M870.39,374.69
		c2.62-0.34,5.24-0.75,7.67-1.1c2.29,1.58,4.55,3.14,7.26,5.01c-5.96,1.59-11.64,3.1-17.5,4.66c-0.46-1.57-0.69-2.33-0.91-3.09
		C865.84,376.62,866.79,375.15,870.39,374.69z M885.03,371.2c-0.09,0.05-0.16,0.09-0.25,0.13c-0.24,1.47-0.37,3.01-0.34,4.64
		c-0.8-1.13-1.61-2.25-2.29-3.21c0.45-0.25,1.3-0.71,2.63-1.43c0.02-0.1,0.04-0.2,0.05-0.29c-2.43-2.01-4.43-3.67-6.88-5.71
		c-0.02-0.32-0.08-1.35-0.15-2.55c1.83,1.06,3.5,2.02,5.17,2.99c0.25-0.22,0.5-0.45,0.74-0.67c-0.41-1.03-0.82-2.06-1.23-3.1
		c0.36-0.23,0.78-0.51,1.76-1.15c-1.78-1.31-3.22-2.37-4.88-3.6c1.65-2.1,2.92-3.74,4.34-5.56c3.1,1.63,6.03,3.16,9.02,4.73
		c-3.93,4.24-6.87,8.9-7.89,14.62C884.91,371.09,884.97,371.14,885.03,371.2z M885.35,343.66c-3.8,2.5-10.37,1.47-13.53-1.86
		c-0.84-0.88-1.67-1.78-2.74-2.93c0.4-0.98,0.74-1.82,0.93-2.27c2.93,1.24,5.77,3.29,8.73,3.46c2.82,0.17,5.75-1.62,8.81-2.61
		C889.28,340.38,887.49,342.25,885.35,343.66z M874.44,335.23c0-3.89,0-7.24,0-10.6c2.99-1.85,5.99-3.7,9.74-6.02
		c0,2.78,0,4.37,0,5.96c0,3.77,0,7.53,0,11.72C881.01,335.94,878.19,335.63,874.44,335.23z M882.17,313.37
		c-4.39-1.95-8.46-4.51-10.8-9.03c-0.07-0.14-0.17-0.27-0.29-0.39c-0.32-0.34-0.76-0.62-1.13-0.94c0,0.01,0,0.02,0,0.02
		c-0.03-0.03-0.05-0.05-0.08-0.08c0.22,2.22,0.67,4.46,0.7,6.68c0,0.32,0,0.63-0.02,0.95c-0.12,2.53-0.9,5.03-1.4,7.55l0.06-0.05
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0.01,0,0.02-0.01,0.03c0,0,0,0,0,0c0,0,0,0,0,0c1.95-0.96,3.89-1.93,5.84-2.88
		c1.25-0.61,2.51-1.2,3.98-1.89c0.66,1.57,1.1,2.62,1.64,3.92c-5.24,1.33-9.52,4.04-11.5,9.61c-0.4-0.15-0.81-0.31-1.21-0.46
		c0.17-1.79,0.28-3.58,0.53-5.36c0.03-0.25,0.08-0.5,0.14-0.75c0.17-0.74,0.4-1.46,0.59-2.19c0,0-0.01,0-0.01,0.01
		c-1,1.56-2,3.12-2.96,4.62c-0.38,0.6-0.76,1.19-1.13,1.77c-11.75-15.11-8.58-42.83-0.73-50.47c0.77,2.27,1.59,4.7,2.75,8.12
		c-0.16,1.68-0.42,4.58-0.79,8.56c-2.22,3.01-0.68,8.17,3.54,12.24c0,0,0,0,0,0l0.02,0.02c-0.01-0.01-0.02-0.02-0.02-0.02
		c0.02,0.02,0.05,0.04,0.08,0.06c-0.63-3.03-1.25-6.07-1.88-9.1c0.3-0.16,0.59-0.32,0.89-0.49c3.85,6.59,7.51,13.36,15.68,16.03
		c0.58-0.34,1.47-0.86,2.42-1.42c0.08-1.1,0.16-2.12,0.29-3.88c0.38,1.23,0.38,1.96,0.74,2.25c2.1,1.69,2.62,3.66,0.91,5.79
		C887.15,314.48,884.42,314.37,882.17,313.37z M865.1,272.58c0.07-0.07,0.14-0.14,0.22-0.21c0.06,0.08,0.13,0.16,0.19,0.23
		c-0.04,0.07-0.08,0.14-0.11,0.22C865.29,272.74,865.19,272.66,865.1,272.58z M876.52,300.37c0.14-0.34,0.28-0.69,0.43-1.03
		c2.1,0.3,4.21,0.6,6.43,0.92c0,1.22,0,2.23,0,4.41C880.67,302.98,878.59,301.68,876.52,300.37z M886.25,297.27
		c-5.94,1.6-14.52-2.39-17.44-7.85c-1.13-2.11-1.73-3.96,0.87-5.38c4.21,7.58,10.52,10.92,19.24,8.9
		C890.14,295.9,888.21,296.74,886.25,297.27z M874.35,278.73c0-1.05,0-2.11,0-3.61c2.99-0.97,6.06-1.96,9.67-3.13
		c0.06,1.3,0.11,2.25,0.15,3.21c0,4.93,0,9.86,0,15.45c-2.11-0.69-3.89-1.26-5.67-1.84c-3.18-0.69-4.79-2.46-4.28-5.89
		C874.43,281.55,874.32,280.13,874.35,278.73z M883.13,268.97c-0.03,0.01-0.05,0.02-0.08,0.03c-0.17,0.06-0.34,0.13-0.5,0.21
		c-0.19,0.1-0.37,0.21-0.55,0.34h0c0,0,0,0,0,0c-0.88,0.27-1.75,0.55-2.63,0.82c-0.48,0.15-0.97,0.3-1.45,0.46
		c-1.44,1.03-2.89,2.05-4.32,3.07c-1.43,1.02-2.85,2.03-4.25,3.02c-0.94-2.2-0.47-3.86,2.49-6.31c0.74-0.61,1.64-1.27,2.71-2
		c0.36-0.24,0.73-0.49,1.13-0.75l0,0h0c0.01,0.01,0.02,0.02,0.02,0.03c0.22-0.19,0.43-0.37,0.65-0.56
		c-0.22,0.19-0.43,0.37-0.65,0.56l0,0c0.22-0.19,0.43-0.37,0.65-0.56c1.95-0.49,3.9-0.98,5.85-1.47v0c0,0,0,0,0,0v0
		c0.65-0.21,1.31-0.39,1.96-0.59c0.65-0.2,1.3-0.41,1.92-0.68c0.86-0.37,1.59-1.06,2.18-1.46c0.95,0.69,2.24,1.63,3.7,2.69
		c-0.81,1.35-1.81,2.02-2.89,2.37c-0.45,0.14-0.92,0.23-1.39,0.29C886.12,268.65,884.5,268.51,883.13,268.97z M874.16,257.21
		c4.49,1.09,8.32,2.02,12.15,2.95c0.02,0.43,0.04,0.87,0.05,1.3c-0.76,0.49-1.46,1.28-2.28,1.41c-2.42,0.4-4.91,0.83-7.34,0.72
		C872.63,263.4,874.7,260.33,874.16,257.21z M854.91,356.61c-1.21-0.9-2.74-2.05-4.3-3.22c0.54-2.15,1.16-4.24,1.57-6.37
		c0.4-2.06,0.58-4.16,0.9-6.57c1.11-0.52,2.26-1.06,3.31-1.55c-0.49,2.84-1.19,5.66-1.42,8.52
		C854.72,350.43,854.91,353.48,854.91,356.61z M850.31,333.87c-2.06,1.38-4.04,2.7-6.2,4.15c-2.93-0.96-6.17-1.93-8.78,1.38
		c-0.25,2.09-0.51,4.17-0.82,6.8c-1.89-2.84-1.02-5.11,0.29-7.37c1.36-4.93,4.26-8.42,9.36-10.09
		C846.3,330.53,848.45,332.32,850.31,333.87z M843.14,339.69c-1.4,3.53-2.38,6.01-3.37,8.49c-0.25-0.01-0.5-0.01-0.74-0.02
		c-0.54-2.28-1.09-4.57-1.77-7.46C839.4,340.34,840.95,340.07,843.14,339.69z M833.64,349.13c2.99,0.2,5.33,2.84,5.3,6.74
		c-2.33-1.53-4.23-2.77-6.26-4.09C833.05,350.77,833.35,349.95,833.64,349.13z M837.1,358.54c-3.35,7.89-0.25,9.84,5.3,14.16
		c-1.37,2.15-2.77,4.34-4.08,6.39c-9.2-2.16-16.71-14.13-6.31-23.97C833.8,356.32,835.49,357.45,837.1,358.54z M824.44,355.08
		c0.19,0.79,0.5,1.68,0.93,2.66c-0.25,0.47-0.79,1.46-1.47,2.71c-2.9-2.53-3-5.42-1.77-8.18c1.33-3.01,3.74-4.94,7.53-4.73
		c0,0.89,0,1.67,0,2.45C825.12,349.93,823.61,351.64,824.44,355.08z M830.61,399c0.23,0.37,0.46,0.74,0.69,1.11
		c-0.12,0.09-0.23,0.18-0.35,0.26c-0.29-0.27-0.57-0.53-0.86-0.8c0,0,0,0,0,0c-1.32-1.5-2.65-3-3.96-4.52
		c-2.08-2.4-3.48-4.86-1.77-8.18c0.63-1.23,0.55-2.82,0.91-4.9C827.2,388.12,828.9,393.56,830.61,399z M823.03,343.39
		c-2.28,5.08-3.95,8.81-5.62,12.54c0.25,0.27,0.5,0.53,0.75,0.8c-0.82,0.43-1.64,0.87-2.57,1.36c-0.29-0.58-0.77-1.09-0.76-1.59
		c0.14-6.99-0.06-14.02,0.69-20.96c0.42-3.92,3.78-6.4,7.5-8.42C823.03,333.27,823.03,339.16,823.03,343.39z M820.32,373.9
		c-1.64,0.97-3.3,1.96-5.08,3.02c0-4.87,0-9.5,0-13.91c1.59-0.79,2.73-1.36,3.9-1.94C822.81,365.07,820.68,369.52,820.32,373.9z
		 M820.64,393.39c-2.02-1.53-4.45-3.37-7.39-5.6c0.45-1.76,1.01-3.95,1.62-6.32c1.66-0.82,3.39-1.68,4.76-2.35
		C819.97,383.98,820.31,388.81,820.64,393.39z M815.28,328.49c-0.81,0.57-2.23,0.28-3.38,0.39c-7.42-3.89-15.59-6.84-22-11.99
		c-4.31-3.46-8.51-5.21-14.28-6.36c1.94-3.43,3.76-6.64,5.81-10.26c2.79,2.88,7.57,3.43,8.67,8.31c0.16,0.71,1.65,1.2,2.6,1.65
		c2.96,1.43,5.93,2.84,8.9,4.25s5.94,2.82,8.91,4.24c2.03,0.62,4.07,1.23,6.1,1.85c2.04,0.62,4.08,1.24,6.14,1.86
		C820.12,324.61,817.81,326.71,815.28,328.49z M807.73,379.72c3.31,2.27,1.36,5.8,3.9,8.5c-2.19,0-2.97,0-3.9,0
		C807.73,385.52,807.73,382.92,807.73,379.72z M806.5,357.12c1.33,0.62,2.63,1.22,3.28,1.53c0,5.93,0,11.32,0,16.72
		c-0.42,0.01-0.84,0.03-1.26,0.04C807.86,369.37,807.19,363.35,806.5,357.12z M810.11,354.56c-1.26-0.46-2.62-0.97-3.53-1.3
		c-0.59-1.67-1.18-3.32-1.76-4.95c-0.79-2.22-1.57-4.4-2.34-6.57c-0.94-2.63-1.87-5.25-2.8-7.86c-2.23-2.38-4.46-4.77-6.71-7.13
		c-1.94-2.04-5.38-2.35-7.17-4.57c0.37-0.51,0.74-1.03,1.11-1.54c4.54,2.55,9.08,5.09,13.63,7.64c3.74,1.97,7.47,3.93,11.29,5.94
		C811.27,340.88,810.7,347.57,810.11,354.56z M783.11,317.23c0,1.74,0,3.25,0,4.97c-2.25,0-4.34,0-6.59,0c0-2.65,0-5.29,0-8.43
		C778.8,314.96,780.96,316.1,783.11,317.23z M758.51,266.45c-1.19-0.04-2.35-0.97-3.3-2.19c16.73,1.78,33.26,3.29,49.93,1.44
		c0.05,0.3,0.11,0.61,0.16,0.91c-2.35,0.43-4.72,1.3-7.06,1.22c-8.37-0.27-16.72-0.94-25.08-1.28
		C768.28,266.37,763.39,266.63,758.51,266.45z M765.61,277.07c-1.28,0-2.13,0-2.98,0c-2.33-0.22-4.67-0.45-7.08-0.68
		c-0.36-2.73-0.47-4.41-0.1-5.33c0.11-0.26,0.25-0.46,0.44-0.61c0.28-0.22,0.66-0.31,1.16-0.29c0.19,0.01,0.41,0.03,0.64,0.07
		c0.55,0.09,1.21,0.25,1.99,0.48c0.35,0,0.71,0,1.06,0c1.08,0,2.21,0,3.56,0C764.73,272.78,765.14,274.74,765.61,277.07z
		 M813.26,277.98c0.01,2.16,0.01,4.31,0.02,6.47c0,0.62,0,1.23,0,1.87c0,1.9,0,3.94,0,6.57c-4.51-1.42-8.66-2.72-12.71-3.99
		c0-1.59,0-2.64,0-3.69c0.48-4.34,0.96-8.68,1.43-13.02c0.4-0.12,0.8-0.24,1.2-0.35c1.2-0.34,2.41-0.67,3.56-1.14
		c2.21-0.89,4.36-1.94,6.48-2.9c0,1.09,0,2.38,0,3.68C813.25,273.64,813.26,275.81,813.26,277.98z M812.92,296.7
		c-0.26,5.57-0.5,10.8-0.78,16.78c-5.06-2.57-9.35-4.74-13.79-6.98c0.57-4.79,1.12-9.41,1.7-14.36
		C804.55,293.73,808.8,295.24,812.92,296.7z M784.66,281.64c-0.2-3.2-0.37-6.08-0.57-9.23c4.62,0,8.85,0,13.68,0
		c-0.3,4.86-0.58,9.5-0.9,14.81C792.36,285.16,788.35,283.33,784.66,281.64z M796.07,290.2c-0.45,4.42-0.9,8.81-1.38,13.53
		c-3.86-2.36-7.51-4.59-11.58-7.07c0.18-3.81,0.36-7.63,0.56-11.92C788.26,286.75,792.06,288.43,796.07,290.2z M771,271.02
		c2.54,0.23,5.31,0.48,8.56,0.78c0.16,2.47,0.3,4.8,0.48,7.54C775.63,277.28,770.48,277.13,771,271.02z M815.67,247.23
		c-1.01-2.83-1.96-5.5-2.97-8.33c0.89-0.24,1.65-0.45,2.42-0.66C818.19,242.24,818.39,244.42,815.67,247.23z M814.84,257.98
		c0.91,4.06,0.23,4.92-4.43,4.71c3.87-7.98-0.27-15.05-1.6-22.52c0.56,0,1.31,0,2.15,0C812.26,246.15,813.51,252.08,814.84,257.98z
		 M742.88,261.85c0.73,2.05,1.46,4.1,2.19,6.15c0.26-0.09,0.51-0.17,0.77-0.26c-0.27,0.98-0.54,1.95-0.8,2.93
		c-0.36,0.07-0.71,0.15-1.07,0.22c-0.7-2.88-1.39-5.77-2.09-8.65C742.21,262.12,742.55,261.98,742.88,261.85z M742.66,273.06
		c3.6,5.13,5.95,11.14,9.29,16.65c-0.58-2.68-1.17-5.37-1.75-8.05c-1.41-2.59-2.43-5.2-2.66-7.85c-0.03-0.33-0.05-0.66-0.05-0.99
		c0-0.33,0-0.66,0.03-1c0.07-1,0.26-2,0.59-3.01c0.22-0.67,0.51-1.35,0.86-2.02c0.18-0.34,0.37-0.68,0.59-1.02
		c0.03-0.46,0.06-0.92,0.09-1.42c0.03-0.51,0.07-1.06,0.11-1.71c1.1,0.44,1.89,0.76,2.68,1.09c-0.36,0.09-0.72,0.17-1.08,0.26
		c0,4.86-0.38,9.77,0.11,14.58c0.48,4.7,1.85,9.3,2.89,14.26c-0.53,0-1.03,0-1.49,0c-1.39,0-2.51,0-3.63,0
		c-2.39-8.7-5.48-16.95-12.29-23c-0.97-0.86-2.02-1.68-3.15-2.45c-1.06-0.72-2.03-1.57-2.97-2.46c-0.49-0.46-0.97-0.93-1.45-1.39
		c-0.45-0.44-0.91-0.87-1.37-1.29c-3.06-2.58-6.12-5.15-9.19-7.73c-1.2-1.01-2.41-2.03-3.61-3.04c-0.68-0.36-1.34-0.76-1.95-1.2
		c-0.58-0.42-1.13-0.88-1.64-1.36c-0.05-0.05-0.11-0.1-0.17-0.15c-1.62-1.59-2.82-3.47-3.41-5.38c-0.1-0.32-0.18-0.64-0.24-0.96
		c-0.13-0.64-0.19-1.28-0.16-1.9c0.03-0.94,0.24-1.85,0.64-2.71c1.65,0.97,3.25,1.91,4.84,2.85c5.49,5.96,10.97,11.92,16.46,17.88
		c0.55,0.6,1.11,1.2,1.68,1.79c0.56,0.59,1.13,1.18,1.71,1.77c1.71,1.76,3.44,3.51,5.09,5.32
		C739.7,269.21,741.27,271.08,742.66,273.06z M742.24,281.93c0.42,0.38,0.77,1.02,1.01,2.06c-2.37,0.65-4.46,1.22-6.54,1.79
		c-0.98,1.24-1.9,2.53-2.97,3.69c-0.59,0.65-1.42,1.08-2.15,1.6c-0.25-0.21-0.51-0.42-0.76-0.63c0.4-0.98,0.8-1.96,1.27-3.1
		c-1.29,0.13-2.65,0.27-4.02,0.41c0.62,3.87,2.33,6.24,6.51,7.67c8.62,2.93,16.94,1.97,25.39-0.09c0.57-0.14,1.18-0.12,1.78-0.17
		c1.64-0.72,3.28-1.42,4.91-2.17c3.15-1.44,6.76-2.39,6.96-7.8c-0.89-0.62-2.42-1.67-3.96-2.73c-0.25,0.29-0.49,0.58-0.74,0.87
		c0.6,1.22,1.2,2.44,1.93,3.9c-0.9,0.65-2.01,1.45-3.13,2.26c0.08-0.31,0.15-0.62,0.23-0.93c-3.84-1.4-7.68-2.8-11.85-4.33
		c-0.07-0.78-0.21-2.26-0.31-3.3c4.42,0,9.13-0.17,13.82,0.04c6.25,0.28,10.01,6.16,8.02,12.3c-0.38,1.18-0.93,2.31-1.4,3.47
		c0.33,1.09,0.65,2.18,0.98,3.26c0.5,0.24,0.99,0.49,1.49,0.73c-2.28,1.69-4.57,3.38-6.85,5.07c-6.26,2.35-12.6,3.57-19.39,2.56
		c-1.07-0.16-2.15-0.26-3.23-0.32c-1.08-0.06-2.17-0.08-3.26-0.09c-1.09-0.01-2.18,0-3.27,0.01c-0.55,0-1.1,0-1.65,0
		c-0.55,0-1.09-0.01-1.64-0.02c-0.44-0.18-0.89-0.36-1.33-0.54c0.01,0.01,0.01,0.02,0.02,0.03l0,0c0,0,0,0,0,0l0,0
		c-1.09-0.14-2.2-0.23-3.29-0.35c-1.1-0.12-2.19-0.27-3.26-0.51c-3.93-0.89-6.12-3.48-6.6-7.5c-0.13-0.25-0.27-0.5-0.4-0.75
		c0.1-0.06,0.19-0.12,0.29-0.18c0.13,0.2,0.25,0.39,0.38,0.59c3.41,1.4,6.82,2.81,10.23,4.21c4.96,0.3,9.92,0.66,14.88,0.89
		c4.17,0.2,8.04-0.85,11.87-2.12c0.85-0.28,1.7-0.57,2.54-0.87c0.42-0.15,0.85-0.29,1.27-0.44c1.7-0.58,3.4-1.13,5.13-1.58
		c0.41-0.25,0.81-0.49,1.22-0.74c1.07-0.65,2.14-1.3,3.22-1.96l0,0c-0.19-0.21-0.39-0.53-0.58-0.74c-0.11-0.13-0.23-0.21-0.33-0.2
		c-0.72,0.05-1.44,0.14-2.15,0.24c-0.72,0.1-1.43,0.22-2.15,0.32c-1.35,0.4-2.69,0.87-4.04,1.34c-0.68,0.24-1.37,0.48-2.05,0.7
		c-2.03,0.67-4.07,1.23-6.14,1.41c-7.01,0.6-14.11,0.57-21.14,0.16c-5.56-0.33-9.96-3.35-13.67-8.98c0.83-0.53,1.64-1.04,2.44-1.54
		s1.58-1,2.37-1.5v0c0.83-1.71,2.07-2.66,3.48-3.28c0.2-0.09,0.41-0.17,0.61-0.25c1.25-0.46,2.6-0.72,3.91-1.06
		c0.22-0.06,0.44-0.11,0.65-0.17c0.22-0.06,0.43-0.13,0.64-0.19c0.95-0.19,2-0.7,2.96-0.87c0.41-0.07,0.81-0.09,1.17,0.02
		C741.8,281.62,742.03,281.74,742.24,281.93z M760.42,330.33C760.42,330.33,760.42,330.33,760.42,330.33L760.42,330.33
		c-1.91,1.41-3.7,3.17-5.86,3.85c-0.43,0.13-0.88,0.23-1.35,0.26c-0.93,0.07-1.95-0.07-3.06-0.53c-4.01-2.42-8.03-4.85-12.04-7.27
		c-0.35-0.22-0.82-0.4-1.21-0.61c-0.4-0.2-0.74-0.43-0.84-0.74c-0.95-2.72-1.63-5.54-2.6-9c1.76,0.57,2.78,0.91,3.81,1.24
		c10.13,0.52,19.68-2.34,29.26-5.01c0.39-0.11,0.8-0.13,1.21-0.13c0.42,0,0.84,0.03,1.25,0.01c0.52-0.16,1.04-0.33,1.6-0.51
		c0.56-0.18,1.16-0.37,1.83-0.58c0,1.57,0,2.7,0,3.84c-0.2,2.57-0.39,5.14-0.54,7c-1.97,1.37-3.8,2.64-5.55,3.86
		c-1.75,1.22-3.43,2.39-5.11,3.56c-0.22,0.2-0.45,0.39-0.67,0.59c-0.06,0.06-0.13,0.11-0.19,0.17c0,0,0.01,0,0.03-0.01
		C760.39,330.33,760.42,330.33,760.42,330.33z M724.09,308.19c0.7,0.64,1.43,1.31,2.16,1.98c0.99,0.33,1.98,0.67,2.98,1
		c0.25,0.6,0.36,1.13,0.36,1.6c0,0.94-0.46,1.66-1.23,2.28c-0.38,0.31-0.84,0.59-1.36,0.86c-2.39,1.25-4.84,2.54-6.88,4.26
		c-3.29,2.76-6.5,2.77-9.99,0.68c-1.56-0.7-3.12-1.4-5.38-2.41C711.46,314.88,717.66,311.6,724.09,308.19z M706.68,323.05
		c0.91,0.53,1.81,1.06,2.72,1.59c1.32,0.68,2.64,1.37,4.14,2.14c0.5,0.26,1.02,0.53,1.56,0.81c0.22,4.07-3.65,5.68-5.42,8.91
		c-1.98-1.22-3.64-2.25-5.3-3.28c-0.41-1.46-0.82-2.91-1.23-4.37c-0.08-0.57-0.15-1.15-0.23-1.73c-0.23-1.75-0.47-3.56-0.75-5.67
		C703.98,322.1,705.33,322.58,706.68,323.05z M717.39,334.76c4.5-4.69,15.14-5.99,20.86-1.87c2.47,1.77,5.2,3.19,7.9,4.63
		c0.9,0.48,1.8,0.97,2.69,1.47c0.79,0.17,1.54,0.27,2.27,0.28c0.73,0.02,1.42-0.04,2.09-0.15c0.34-0.06,0.66-0.13,0.99-0.22
		c0.65-0.17,1.27-0.41,1.87-0.68c2.71-1.24,4.99-3.41,7.15-5.5c5.54-5.37,12.05-7.54,19.28-6.55c2.3,0.31,4.32,1.03,6.1,2.08
		c1.07,0.63,2.05,1.37,2.96,2.21c0.3,0.28,0.59,0.57,0.88,0.87c1.03,1.09,1.94,2.32,2.75,3.64c0.12,0.19,0.24,0.38,0.35,0.57
		c0.63,1.08,1.21,2.23,1.72,3.43c0.77,2.35,1.6,4.68,2.4,7.02c0.8,2.34,1.55,4.69,2.18,7.07c2.53,9.67,2.68,19.51,1.52,29.42
		c-0.49,4.15-0.6,8.35-0.92,13.11c-1.65-1.05-2.41-1.53-2.7-1.72c0-6.41,0.24-12.56-0.06-18.68c-0.44-8.93-2.3-17.53-7.78-24.93l0,0
		c0,0,0,0,0,0c-1.66-0.81-3.29-1.73-4.92-2.62c-2.73-1.49-5.47-2.92-8.39-3.69c-5.92-1.58-11.31,1.09-15.24,5.57
		c-3.38,3.84-6.07,8.29-8.64,11.88c-3.76-2.85-7.35-5.58-10.91-8.28c-1.19-0.9-2.37-1.8-3.55-2.69c-1.43-0.25-2.85-0.51-4.27-0.76
		c-1.42-0.25-2.85-0.5-4.28-0.72c-5.55-0.85-11.03,2.51-14.48,9.08c-1.36,2.59-2.31,5.38-3.45,8.08c-0.06,0.23-0.12,0.47-0.18,0.7
		c0,0.49,0,0.98,0,1.47c0,0.45,0,0.91,0,1.36c0,7.95,0,15.9,0,23.85c0.32,0.08,0.65,0.16,0.97,0.24c-0.96,0.59-1.91,1.19-3.53,2.2
		c-0.34-2.25-0.62-4.09-0.91-5.94c-0.7-4.21-1.68-8.4-2.03-12.65c-0.5-6.13-0.98-12.31-0.68-18.44
		C707.84,349.78,710.84,341.58,717.39,334.76z M733.31,401.96c-0.95,3.76-1.85,7.29-2.63,10.37c-8.24-1.46-15.6-2.76-22.86-4.04
		C710.63,400.14,723.89,396.42,733.31,401.96z M782.57,397.01c7.13-4.25,8.87-3.93,15.6,0.99c0.28,0.61,0.54,1.2,0.81,1.78
		c0.63,1.4,1.23,2.72,1.83,4.03c0.14,0.32,0.29,0.63,0.43,0.95c0,0,0,0,0,0c0,0,0,0,0,0c1.43,4.45,2.86,8.91,4.29,13.36
		c-0.37,0.23-0.74,0.45-1.1,0.68c-1.1-0.65-2.21-1.31-3.31-1.96c-1.42-1.57-2.84-3.15-4.25-4.72c-1.31-0.08-2.62-0.16-3.93-0.24
		c-1.5-0.09-3-0.18-4.52-0.27c-2.86-0.17-5.78-0.35-8.82-0.54c-0.87-3.2-1.95-7.16-3.06-11.21
		C778.79,398.78,780.68,397.9,782.57,397.01z M806.71,443.09c-1.6,0.66-3.09,1.27-4.58,1.88c-1.51,1.8-3.37,3.56-1.74,6.18
		c0.69,1.11,1.57,2.8,2.5,2.88c1.28,0.12,2.68-1.02,4.83-1.97c-2.07-0.89-3.1-1.34-4.9-2.12c0.83-1.39,1.63-2.72,2.61-4.35
		c3.15,2.43,5.16,4.78,3.79,7.87c-0.79,1.78-2.93,3.61-4.78,4.05c-1.25,0.3-3.61-1.46-4.51-2.9c-1.66-2.66-2.6-5.76-3.94-8.9
		c1.46-0.41,2.14-0.6,2.73-0.77c0.08-0.02,0.17-0.05,0.25-0.07c0-2.43,0-4.86,0-7.93c1.54,2.43,2.81,4.45,4.09,6.46
		c0.25-0.02,0.5-0.05,0.75-0.07c0.11-1.41,0.23-2.81,0.4-5.01C805.24,440.28,805.96,441.66,806.71,443.09z M808.06,417.08
		c-0.73-4.08-1.56-8.68-2.35-13.11c0.56-2.99,1.13-6.02,1.77-9.42c1.91-0.85,4.18-2.24,6.58,0.09c6.97,3.86,9.13,10.8,11.3,17.71
		c0.3,0.95,0.27,2.01,0.34,2.57c-3.14-0.56-6.29-1.11-9.44-1.67c-1.18,0.47-2.36,0.94-3.54,1.41
		C811.02,415.54,809.34,416.42,808.06,417.08z M816.37,419.72c0.47-0.79,2.11-1.34,3.17-1.26c4.13,0.28,6.02,2.29,7.17,6.99
		c-2.99,1.37-5.68,2.6-8.31,3.8C815.44,426.33,814.57,422.76,816.37,419.72z M827.85,378.01c2.7,1.67,5.46,3.37,8.43,5.2
		c-1.17,4.02-3.2,8.41,0.52,12.29c0.94,0.98,2.72,1.15,4.09,1.68c0.69,3.67,1.48,7.89,2.24,11.95
		C836.63,405.6,827.88,387.91,827.85,378.01z M839.59,394.23c-3.09-4.26-1.47-7.21,0.05-10.42c1.1,0.42,2.04,0.79,3.6,1.39
		C842.09,388.06,841,390.76,839.59,394.23z M843.05,363.95c-0.49-1.94-0.88-3.91-1.36-6.12c3.87,0.6,4.19,1.55,3.76,10.86
		c-0.32,0.09-0.64,0.19-0.96,0.28C844,367.29,843.47,365.63,843.05,363.95z M843.2,351.7c0.78-2.81,1.58-5.65,2.37-8.49
		c0.34,0,0.68,0,1.02,0c0.8,3.36,1.6,6.71,2.57,10.8C846.86,353.12,845.41,352.56,843.2,351.7z M849.32,355.33
		c2.83,1.88,1.82,3.11-0.67,4.24c-0.84-0.93-1.77-1.97-2.86-3.19C846.93,356.05,848.04,355.71,849.32,355.33z M862.26,376.01
		c0.23,0.06,0.46,0.11,0.69,0.17c0.12,2.24,0.24,4.49,0.37,6.93c-0.76,0-1.62,0-3.22,0C860.89,380.51,861.58,378.26,862.26,376.01z
		 M863.32,371.21c-0.45-1.23-0.9-2.47-1.35-3.7c0.33-0.11,0.67-0.21,1-0.32c0.4,1.24,0.8,2.48,1.2,3.72
		C863.88,371.02,863.6,371.12,863.32,371.21z M895.01,371.4c-0.3-0.18-0.59-0.36-0.89-0.55c0.29-2.29,0.57-4.58,0.86-6.88
		c0.55-0.03,1.11-0.05,1.66-0.08c0.17,1.86,0.56,3.73,0.41,5.56C897,370.16,895.73,370.76,895.01,371.4z M896.84,348.3
		c-0.92,0.53-1.91,0.88-2.92,1.14c-0.51,0.13-1.03,0.23-1.55,0.32c-0.52,0.09-1.05,0.17-1.57,0.25c-1.05,0.16-2.1,0.32-3.12,0.58
		c-0.28-0.27-0.56-0.54-0.84-0.81c1.57-3.16,3.14-6.32,4.71-9.47c0.1-3.37-0.48-6.36-4.34-7.4c0-4.65,0-9.18,0-13.7
		c3.4-3.44,5.41-9.13,3.7-13.78c-0.14-0.39-0.31-0.77-0.5-1.14c-0.39-0.74-0.89-1.45-1.51-2.1c-0.31-0.33-0.65-0.64-1.02-0.94
		c1.48-1.61,2.51-2.84,3.13-3.94c0.25-0.44,0.44-0.87,0.56-1.29c0.19-0.63,0.23-1.26,0.15-1.94c-0.14-1.14-0.64-2.43-1.47-4.13
		c-0.17-0.34-0.34-0.7-0.53-1.08c-0.29-0.05-0.58-0.11-0.87-0.16c-0.29-0.06-0.59-0.11-0.9-0.17c0-5.42,0-10.74,0-15.85
		c2.41-1.32,4.67-2.56,7.36-4.04c2.24,2.56,4.26,5.35,5.97,8.35c0.57,1,1.11,2.03,1.61,3.07s0.97,2.12,1.4,3.21
		c1.29,3.28,2.25,6.77,2.81,10.46c0.13,0.53,0.27,1.06,0.42,1.59c0.43,1.59,0.89,3.17,1.09,4.78c1.47,12.02,0.54,23.86-3.61,35.26
		c-1.36,3.74-3.72,7.13-5.62,10.68c-0.38,0.5-0.78,0.93-1.21,1.3S897.3,348.04,896.84,348.3z M908.18,387.95
		c1.68-0.25,3.03-0.45,4.57-0.68c0,1.11,0,2.34,0,3.56c-0.6-0.09-1.18-0.17-1.9-0.27c0,1.61,0,2.91,0,4.57
		c-0.79-0.29-1.39-0.52-2.4-0.89c-0.1,0.67-0.18,1.3-0.27,1.9c0,0.68,0,1.35,0,2.02c-0.09-0.02-0.19-0.03-0.28-0.05
		c0.09-0.65,0.18-1.29,0.28-1.97C908.18,393.54,908.18,390.89,908.18,387.95z M909.51,348.89c0.52-0.22,1.04-0.44,1.56-0.65
		c0.94,2.5,1.87,4.99,2.81,7.49c-0.36,0.15-0.72,0.31-1.09,0.46C911.7,353.75,910.6,351.32,909.51,348.89z M929.4,279.52
		c2.82-1.07,5.72-1.95,8.43-3.24c3.75-1.78,4.5-1.78,6.35,1.86c1.49,2.92,2.84,5.91,0.74,9.3c-1.6,2.57-3.44,3.96-6.92,2.46
		c0.23,1.17,0.36,1.86,0.62,3.21c-2.39,0.42-4.74,0.83-7.09,1.24c-4.29,0.04-8.59,0.28-12.86-0.01c-1.13-0.07-2.2-1.25-3.28-2.12
		c-0.15-0.12-0.31-0.24-0.47-0.35c-0.33-0.64-0.61-1.27-0.86-1.89c-0.12-0.31-0.24-0.62-0.34-0.93c-0.31-0.92-0.53-1.83-0.66-2.73
		c-0.43-2.98,0.15-5.8,1.79-8.5c0.59,0.53,1.13,1.01,1.68,1.5C920.84,279.38,925.12,279.45,929.4,279.52z M925.16,274.94
		c3.23-3.41,7.51-2.76,11.88-1.81c-3.81,1.1-7.62,2.21-11.43,3.31C925.47,275.94,925.32,275.44,925.16,274.94z M928.7,305.09
		c-4.44-1.22-9.07-2.03-12.05-6.64c4.25,1.33,8.5,2.67,12.75,4c2.65-0.41,5.49-0.37,7.91-1.36c2.75-1.13,4.19-3.69,3.54-7.05
		c2.39,1.66,3.06,5.48,1.57,8.69c-1.64,3.53-3.74,6.48-6.92,8.18c-2.47,1.32-5.6,1.88-9.67,1.37c-2.62-1.06-5.11-2.25-7.13-3.89
		s-3.57-3.75-4.29-6.63c4.01,2.13,8.07,4.28,12.14,6.44c2.68,0.78,5.52,2.27,7.59-2.33C932.08,305.56,930.39,305.32,928.7,305.09z
		 M926.04,298.06c3.62-0.5,7.23-1,10.85-1.5c0.06,0.43,0.11,0.87,0.17,1.3c-3.62,0.41-7.25,0.82-10.87,1.23
		C926.13,298.75,926.08,298.41,926.04,298.06z M957.92,336.06c2.25,3.93,4.33,7.57,6.66,11.65c-1.23,0-2.87,0-4.71,0
		C961.91,343.26,955.18,340.62,957.92,336.06z M950.26,315.35c1.24-2.53,0.45-6.82,5.91-6.5c-0.34,2.72-0.9,5.25-0.87,7.76
		c0.02,1.25,0.82,3.05,1.82,3.6c1.69,0.93,3.82,1.06,5.77,1.52c0.13-0.37,0.25-0.73,0.38-1.1c-1.99-0.84-3.98-1.68-6.59-2.77
		c0.26-2.93,0.55-6.24,0.88-10.1c1.22,0.65,2.24,1.19,3.25,1.73c0.05-0.01,0.1-0.01,0.15-0.02l0,0c0,0,0,0,0,0h0l0,0
		c0.04,0.05,0.08,0.09,0.12,0.14c0.42,0.17,0.83,0.34,1.25,0.52c-0.12,0.17-0.24,0.34-0.35,0.52c-0.3-0.34-0.6-0.69-0.89-1.03
		c-0.06-0.02-0.12-0.05-0.18-0.07c-0.03-0.02-0.06-0.03-0.09-0.05c-0.35,0.04-0.75,0.08-1.6,0.17c-0.14,0.02-0.29,0.03-0.46,0.05
		c4.11,3.68,6.19,7.79,4.63,12.49c-0.52,1.56-3.27,2.37-5.05,3.55c-1.19-1.41-1.43-4.5-3.83-0.94c-1.63-2.16-3.1-4.1-4.7-6.22
		c1.19-0.39,1.84-0.6,2.93-0.96C951.79,316.75,951.07,316.09,950.26,315.35z M965.79,356.75c-13.49,6.6-11.95,4.34-18.68-6.95
		C953.8,352.29,959.72,354.5,965.79,356.75z M969.92,355.81c0.44,0.3,0.88,0.6,1.31,0.9c-2.15,4.11-4.29,8.22-6.44,12.33
		c-0.32-0.15-0.63-0.31-0.95-0.46C965.88,364.32,967.9,360.07,969.92,355.81z M967.3,352.49c0.84-1.44,1.69-2.88,2.76-4.71
		c0.37,0.29,1.34,1.04,2.31,1.8C969.16,353.6,969.16,353.6,967.3,352.49z M962.04,371.49c0.51-0.88,1.03-1.76,1.54-2.65
		c0.23,0.15,0.46,0.29,0.69,0.44c-0.62,0.86-1.24,1.71-1.86,2.57c-0.28,0.7-0.56,1.39-0.83,2.09c-0.22-0.1-0.44-0.2-0.66-0.3
		C961.3,372.93,961.67,372.21,962.04,371.49C962.04,371.49,962.04,371.49,962.04,371.49z M963.34,364.97
		c-2.3-0.69-4.36-1.31-6.69-2.01c3.34-1.39,6.41-2.67,10.15-4.23C965.55,360.98,964.57,362.75,963.34,364.97z M950.82,360.06
		c-0.09,0.12-0.18,0.23-0.26,0.35c-0.37-0.23-0.74-0.46-1.11-0.7c-1.54-0.79-3.08-1.58-4.66-2.39c-0.62-2.88-1.24-5.78-1.87-8.69
		c0.17-0.07,0.34-0.14,0.5-0.21c2.2,3.59,4.4,7.19,6.6,10.78C950.28,359.49,950.55,359.77,950.82,360.06z M946.19,345.28
		c-4.71-1.87-4.89-3.78-1.38-9.08c1.04,0.51,2.08,1.01,3.8,1.84C944.96,339.49,944.52,342.04,946.19,345.28z M949.08,417.82
		c1.96,1.56,3.13,2.49,4.75,3.78c-1.23,1.35-1.9,2.09-2.58,2.83c-0.35-0.08-0.69-0.15-1.04-0.23
		C949.89,422.4,949.57,420.61,949.08,417.82z M967.62,283.55c-3.18-0.15-4.4-1.51-5.33-3.91c-2.33-5.98-5.06-11.81-7.7-17.86
		c0.58,0.21,0.99,0.37,1.41,0.52c2.34,1.2,4.69,2.4,7.03,3.61c0.92,0.16,1.84,0.31,2.88,0.49c1.04,0.18,2.21,0.37,3.62,0.61
		C964.52,272.06,966.23,277.81,967.62,283.55z M959.46,281.88c-3.98-8.55-7.94-17.05-12.17-26.13
		C951.56,258.73,960.56,278.53,959.46,281.88z M971.52,280.05c0.7-3.49,1.3-6.5,1.96-9.8c2.23,1.42,4.53,2.88,7.36,4.67
		C977.62,276.7,974.82,278.23,971.52,280.05z M985.68,254.91c0.85,0.5,1.7,1,2.56,1.5l0,0c4.23,2.21,8.47,4.43,12.7,6.64
		c-0.25,0.44-0.5,0.89-0.75,1.33c-4.18-2.48-8.36-4.96-12.54-7.44c-0.77-0.45-1.54-0.9-2.32-1.35
		C985.45,255.37,985.57,255.14,985.68,254.91z M973.95,260c-0.09-1.01-0.18-2.01-0.28-3.08c-0.16-1.77-0.34-3.71-0.56-6.07
		c10.34,6.51,19.87,12.5,29.53,18.58c-2.49,0.45-5,0.9-7.52,1.35c-2.52,0.45-5.06,0.91-7.6,1.36c0,0,0,0,0,0l0,0
		c-0.49-0.04-1-0.04-1.52-0.04c-0.26,0-0.51,0-0.77,0c-0.26,0-0.51,0-0.76-0.01c-1.01-0.02-1.99-0.13-2.83-0.52
		c-3.08-1.42-5.85-3.53-8.88-5.43C973.15,264.14,973.55,262.07,973.95,260L973.95,260z M969.76,278.84
		c-2.78-3.89-0.26-6.3,2.09-8.74C971.19,272.86,970.53,275.63,969.76,278.84z M966.82,243.48c-17.24-6.1-19.01-10.65-26.58-19.12
		C949.05,230.76,958.87,235.85,966.82,243.48z M938.4,222.97c0.31,0.16,0.62,0.32,0.93,0.48c-0.07,0.12-0.13,0.24-0.2,0.36
		c-0.3-0.16-0.61-0.33-0.91-0.49C938.28,223.2,938.34,223.09,938.4,222.97z M934.87,222.81c0.49,0.69,0.76,1.05,1.02,1.41
		c3.11,3.23,6.36,6.33,9.28,9.72c4.76,5.53,10.87,8.73,17.59,11.13c0.2,0.07,0.41,0.13,0.64,0.19c0.23,0.06,0.46,0.11,0.7,0.16
		c0.35,0.08,0.71,0.18,1.04,0.3c0.99,0.38,1.73,1.09,1.46,2.81c1.16,5.01,2.32,10.02,3.49,15.03c-0.36,0.23-0.73,0.47-1.09,0.7
		c-10.16-3.94-19.73-8.71-25.45-18.9l-0.1-0.11c-0.37-0.32-0.75-0.64-1.12-0.97c-2.65-2.12-5.3-4.25-7.95-6.37
		c-0.2-0.1-0.47-0.2-0.71-0.3c-0.06-0.02-0.11-0.05-0.16-0.08c-0.1-0.05-0.18-0.1-0.22-0.16c-0.02-0.03-0.04-0.06-0.04-0.09
		C932.82,232.48,932.66,227.68,934.87,222.81z M930.5,238.94c6.86,3.48,14.54,10.99,15.17,14.61c-5.25-4.47-10.62-9.05-16-13.62
		C929.95,239.6,930.22,239.27,930.5,238.94z M935.69,248.6c-0.01,0.09-0.03,0.17-0.04,0.26c-0.06-0.03-0.12-0.07-0.18-0.1
		C935.54,248.71,935.62,248.66,935.69,248.6z M921.95,213.84c-6.67,0.92-29.97-14.09-31.06-19.83
		C901.17,200.58,911.53,207.19,921.95,213.84z M887.4,204.69c0.37,0.26,0.73,0.52,1.65,1.17c0.48-1.93,0.9-3.63,1.42-5.75
		c0.81,0.64,1.62,1.29,2.44,1.94c4.46,2.69,9.28,4.91,13.27,8.17c5.31,4.34,10.79,7.65,17.73,8.47c1.98,0.23,3.82,1.64,5.63,2.47
		c0.29,4.9-3.72,9.42-0.54,14.47c-0.97-0.73-2.04-1.36-2.9-2.2c-6.02-5.78-11.32-12.45-19.33-15.78c-2.15-0.89-4.23-2.06-6.46-2.59
		c-3.59-0.84-6.32-2.8-8.67-5.37c-0.34-0.37-0.66-0.75-0.98-1.14c-1.31-0.98-2.61-1.96-3.92-2.93
		C886.95,205.3,887.17,204.99,887.4,204.69z M877.92,185.64c2.94,1.72,5.62,3.29,8.55,5c-0.45,0.93-0.8,1.65-1.17,2.42
		c-2.29-0.87-4.57-1.72-6.85-2.6c-0.19-0.07-0.32-0.31-0.54-0.55C877.92,188.69,877.92,187.4,877.92,185.64z M877.69,192.71
		c2.7,1.33,4.88,2.4,7.13,3.51c0,2.94,0,5.4,0,8.72c-2.66-0.25-5.07-0.48-7.13-0.67C877.69,200.22,877.69,196.74,877.69,192.71z
		 M874.03,184.48c0.48-0.1,0.97-0.21,1.45-0.31c0.11,0.69,0.35,1.4,0.31,2.08c-0.24,4.73-0.54,9.47-0.82,14.2
		c-0.07,1.2-0.17,2.4-0.25,3.6c-0.23-0.03-0.46-0.06-0.69-0.09C874.03,197.47,874.03,190.98,874.03,184.48z M889.16,211.3
		c0.05,0.01,0.1,0.01,0.15,0.02l0,0c0.04,0.03,0.08,0.06,0.12,0.09c0,0,0,0,0,0c0.23,0,0.31,0,0.31,0c0,0-0.09,0-0.31,0
		c0.13,0.09,0.2,0.14,0.24,0.17c0.09,0.06-0.05-0.04-0.24-0.17c-0.06,0-0.12,0-0.18,0C889.23,211.37,889.19,211.34,889.16,211.3
		c-2.63-0.35-5.27-0.85-7.9-1.11c-0.9-0.09-1.79-0.15-2.69-0.18c-1.89-0.05-3.8,1.05-5.7,1.63c0.09,0.61,0.17,1.22,0.26,1.83
		c3.2,0,6.47-0.42,9.59,0.1c4.79,0.79,9.55,2,14.21,3.41c13.24,4.01,22.26,13.31,29.6,24.46c3.86,5.86,7.57,11.86,10.74,18.11
		c2.16,4.25,1.15,6.51-2.08,9.23c-3.15,0.18-5.9,0.33-8.64,0.49c-1.44-2.11-2.89-4.21-4.33-6.32c0,0,0.02,0.02,0.03,0.04
		c0,0,0.01,0.01,0.01,0.01c-0.19-0.73-0.39-1.45-0.58-2.18c1.3-1.03,2.6-2.06,4.71-3.73c-2.68,0-4.48,0-6.29,0
		c-0.49,0.44-0.91,0.85-1.28,1.24c-0.18,0.2-0.35,0.39-0.5,0.58c-0.3,0.38-0.54,0.75-0.71,1.14c-0.17,0.38-0.28,0.78-0.32,1.21
		s-0.02,0.89,0.07,1.4c0.05,0.26,0.11,0.52,0.19,0.81c0.16,0.57,0.38,1.2,0.67,1.91c0.58,1.42,1.44,3.16,2.58,5.38
		c-0.96,0.25-1.91,0.5-2.86,0.75c-0.94,0.25-1.88,0.49-2.82,0.74c-0.36,0.2-0.72,0.39-1.1,0.6c-0.39,0.21-0.8,0.44-1.27,0.69
		c-2.7-6.43-5.22-12.44-7.75-18.46l0,0c-0.17-0.63-0.34-1.26-0.51-1.9c-0.5-1.9-0.99-3.8-1.62-5.66c-0.46-1.37-1.33-2.6-2.5-4.81
		c-0.62,2.53-0.98,4.02-1.39,5.69c-7.15-9.16-17.55-14.22-27.01-20.55c7.13,0.16,13.61,2,19.53,5.68c0.48,0,0.97,0,1.45,0.01
		c0,0,0,0,0,0c0,0-0.02-0.01-0.05-0.03c-0.02-0.01-0.05-0.03-0.05-0.03c0,0,0,0,0,0l0,0c0.01,0,0.02,0,0.02,0
		c-1.23-0.99-2.38-2.17-3.61-3.15c-0.75-0.6-1.54-1.12-2.39-1.48c-4.63-1.95-9.52-3.26-14.31-4.84c-0.18,0.3-0.37,0.6-0.55,0.89
		c-0.95-2.19-1.9-4.38-2.94-6.8c8.42-1.18,15.13,3.11,22.31,5.47c0.37,0.24,0.74,0.48,1.1,0.72c0.26-0.29,0.53-0.58,0.79-0.87
		c-0.43-0.11-0.86-0.22-1.29-0.32c-1.64-1.29-3.27-2.58-4.91-3.86c-5.98-3.54-12.25-5.99-19.42-4.88c0.45-3.92,2.13-6.83,5.11-7.11
		C878.62,206.7,884.69,206.1,889.16,211.3z M830.58,171.7c0.08-2.11,0.53-4.2,0.66-6.32c0.12-2.01,0.03-4.03,0.03-6.11
		c13.23,7.86,26.32,15.63,39.33,23.36c0,5.29,0,10.28,0,15.45c-7.24,3.14-12.17-1.43-17.25-5.21c-1.7-1.27-3.1-2.96-4.58-4.51
		c-1.69-1.78-3.57-2.37-6.05-1.82c-4.64,1.03-10.56-1.57-11.96-5.85C829.86,177.97,830.47,174.71,830.58,171.7z M827.68,195.53
		c0.68-3.52,1.23-7.06,1.84-10.59c0,0,0,0,0,0c0,0,0,0,0,0c4.79,2.81,6.73,3.93,9.31,4.45c0.63,0.13,1.3,0.22,2.06,0.29
		c1.34,0.12,2.95,0.18,5.13,0.25c6.79,6.72,13.23,14.18,25.1,12.99c-2.57,2.84-4.83,5.33-7.23,7.99c-5,0.35-10.62,1.48-16.07,0.92
		c-5.32-0.55-10.74-2.22-15.61-4.5C827.59,205.17,826.73,200.46,827.68,195.53z M843.78,225.29c1.65,2.74,1.1,5.42,0.59,8.52
		c-1.07,6.58-1.07,13.33-1.49,19.81c-1.76,2.21-3.57,4.49-5.48,6.91C835.8,247,838.17,234.14,843.78,225.29z M834.95,236.92
		c-0.5,6.66-1.01,13.32-1.78,19.98c-2.55-8.95-6.31-17.83-2.54-27.24c1.28-3.19,3.16-6.29,5.4-8.88c1.33-1.53,3.9-1.99,5.91-2.93
		c0.5,0.46,0.99,0.92,1.49,1.39C839.84,224.82,835.47,230.05,834.95,236.92z M826.31,206.45c3.56,2.61,7.31,5.36,11.08,8.13
		c-7.69,3.9-10.19,11.52-11.12,19.8c-0.72,6.48-0.63,13.02,1.78,19.29c0.33,0.85,0.33,1.83,0.48,2.75c0.93,2.64,1.87,5.28,2.8,7.93
		c0.48,0.43,0.96,0.86,1.4,1.25c0.43,0.39,0.81,0.73,1.1,0.98c2.54-0.74,4.83-1.42,7.8-2.29c-0.17,1.8-0.32,3.45-0.46,5.01
		c-0.1,1.04-0.19,2.03-0.28,3s-0.18,1.91-0.26,2.85c-0.04,0.47-0.09,0.93-0.13,1.4l0,0c-0.17,0.96-0.34,1.92-0.51,2.88
		c0.75,13.07-2.12,26.23-0.09,39.27c0.63,0.05,1.26,0.11,1.89,0.16c0.47-2.24,0.93-4.47,1.33-6.37c1.18,4.35,2.46,9.1,3.58,13.25
		c-1.94,0-4.31-0.58-6.16,0.15c-2.17,0.86-3.9,2.85-5.82,4.36c-1.65,4.41-3.3,8.81-5.06,13.51c-0.57,0-1.72,0-2.7,0
		c0-6.01,0.23-11.98-0.16-17.91c-0.1-1.5-2.2-2.86-3.39-4.29c-2.08-1.21-4.16-2.43-6.7-3.9c0-17.22,0-34.68,0-52.36
		c2.61-0.31,5.58-0.76,6.1-4.52c-1.3-3.92-2.59-7.84-4-12.1c2.91-2.7,0.84-5.99,0.13-9.25c-0.23-1.12-0.46-2.25-0.69-3.37
		c0.18-0.03,0.35-0.06,0.53-0.09c0.26,0.97,0.51,1.93,0.77,2.9l0,0c0.4,0.34,0.8,0.68,1.51,1.28c0.23,0.2,0.5,0.42,0.81,0.69
		C823.43,228.64,824.9,217.31,826.31,206.45z M773.62,112.06c1.18,0.66,2.5,1.37,3.8,2.13c8.45,4.92,16.9,9.87,25.36,14.78
		c4.02,2.33,8.06,4.64,12.14,6.89c2.08,1.15,3.08,2.7,2.78,5.15c-0.19,1.52-0.04,3.09-0.04,5.55c-8.45-5.46-16.24-10.52-24.07-15.53
		c-4.91-3.14-9.69-6.57-14.88-9.16C774.44,119.74,772.05,117.27,773.62,112.06z M771.86,122.37c1.34,0.25,2.78,0.14,3.79,0.75
		c7.35,4.46,14.6,9.06,21.89,13.62c-0.15,0.31-0.29,0.63-0.44,0.94c-3.89-0.6-7.79-1.19-10.34-1.58
		c-5.66-3.14-10.26-5.69-14.91-8.26C771.86,126.27,771.86,124.73,771.86,122.37z M783.57,140.38c5.4,0.35,10.79,0.96,16.18,1.46
		c9.5,0.89,16.38,8.16,17.33,17.14c0.59,5.61,0.01,11.41,3.16,16.87c1.65,2.86,0.9,7.18,0.96,10.85c0.11,7.39,0.2,14.78-0.01,22.16
		c-0.23,7.87-1.54,15.71-5.21,22.64c-1.28,2.42-5.45,3.36-8.39,4.8c-0.97,0.47-2.22,0.36-3.45,0.53c0-0.65,0-1.22,0-1.8c0,0,0,0,0,0
		c0-1.07,0-2.13,0-2.23c2.31-2.78,5.01-4.69,5.71-7.17c1.1-3.92,0.85-8.22,1.17-12.35c-0.48-0.06-0.96-0.11-1.44-0.17
		c-0.41,2.58-1.07,5.15-1.19,7.74c-0.26,5.79-3.76,7.92-9.52,7.46c-5.21-0.41-10.55,0.89-15.83,1.5c-0.45,0.05-0.9,0.18-1.34,0.32
		c-0.44,0.14-0.88,0.31-1.32,0.43c-8.84,0-17.68,0-26.52,0c-4.28-0.68-8.54-1.59-12.85-1.97c-4.47-0.39-7.22-2.81-9.34-6.42
		c-1.23-2.1-2.58-4.14-3.87-6.21c-0.36,0.18-0.71,0.37-1.07,0.55c1.88,3.63,3.72,7.29,5.65,10.89c1.49,2.77,3.1,5.48,4.66,8.22
		c-0.41,0.39-0.83,0.78-1.24,1.17c-1.55-1.17-3.84-2.01-4.54-3.56c-2.76-6.09-5.22-12.29-7.06-18.69
		c-1.11-3.84-1.99-7.75-2.58-11.75c-0.34-0.98-0.66-1.97-0.95-2.96c-0.58-1.98-1.06-3.98-1.43-5.99
		c-0.94-5.03-1.24-10.15-1.03-15.34c0.44-3.39,0.87-6.78,1.31-10.17l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.32-1.55,0.73-2.86,1.55-3.87
		c0.82-1.01,2.05-1.71,3.99-2.05c4.5-0.78,8.71-3.09,13.13-4.46c4.5-1.4,9.1-2.46,14.12-3.79c0,1.6,0,2.49,0,3.37l0,0h0
		c0.1,0.51,0.2,1.03,0.29,1.53c0.1,0.5,0.19,0.98,0.27,1.43c-9.02,2.44-18.08,4.9-28.05,7.6c1.4,1.79,1.96,2.52,2.26,2.9
		c2.82-1.09,5.36-2.07,7.84-3.02c0.82-0.32,1.64-0.63,2.46-0.95v0c0.65-0.15,1.3-0.31,1.95-0.48c1.3-0.33,2.6-0.68,3.89-1.02
		c3.24-0.85,6.49-1.66,9.79-2.05c6.43-0.76,12.96-0.65,19.45-0.8c0.99-0.02,1.99,0.57,2.98,0.88c0.21-0.06,0.42-0.14,0.62-0.24
		c1.76-0.37,3.53-0.74,5.51-1.15c0-0.61,0-1.35,0-2.39c-2.48,0-4.81,0-7.58,0c0-1.65,0-3.01,0-4.37c-0.28-0.49-0.56-0.98-1.43-2.51
		c13.07,1.55,25.29,3,37.52,4.45c-10.79-7.61-23.46-6.67-35.87-7.89c0.63-1.48,1.3-3.04,1.92-4.47c-2.37-5.41-6.62-2.47-10-2.34
		c-3.47,0.14-7.4-0.61-10.05,2.79c-1.65,1.79-3.3,3.57-4.31,4.67c-10.71,3.05-20.79,5.92-30.51,8.68
		c1.73-8.11,8.47-14.07,17.19-16.43c7.21-1.95,14.39-3.06,21.79-3.19C768.04,139.57,775.81,139.88,783.57,140.38z M770.98,153.08
		c0,1.02,0,1.67,0,2.31c0.26,1.25,0.52,2.5,0.88,4.25c-5.66,0.38-11.12,0.74-16.71,1.11c-0.12-0.38-0.23-0.74-0.33-1.08
		c-0.19-0.62-0.36-1.18-0.53-1.72c-0.03-0.09-0.05-0.18-0.08-0.26c-0.08-0.28-0.17-0.55-0.25-0.82c0,0,0,0,0,0s0,0,0,0
		c0-0.2,0-0.41,0-0.62c0-0.63,0-1.28,0-2.08C759.53,153.82,765.08,153.46,770.98,153.08z M755.78,149.99
		c3.69-7.29,10.62-3.72,16.65-4.67c-0.65,1.66-1.15,2.95-1.82,4.67C765.73,149.99,760.71,149.99,755.78,149.99z M765.14,121.99
		c0,2.76,0,5.21,0,7.31c-11.6,5.25-23.31,9.3-35.49,11.82c-0.11-0.82-0.28-2.06-0.48-3.54C740.5,131.47,754.19,130.31,765.14,121.99
		z M726.33,138.6c0.53,1.87,0.98,3.41,1.53,5.37c-0.76,0.75-1.4,1.4-2.04,2.03c-0.3-0.27-0.55-0.38-0.55-0.5
		C725.23,143.32,723.03,140.91,726.33,138.6z M722.1,141.36c0.24,2.78,0.43,5.02,0.61,7.02c-1.77,3.45-3.48,6.8-5.1,9.97
		c-2-3.82,0.26-9.32-4.1-12.83C716.07,144.28,718.93,142.89,722.1,141.36z M458.41,313.39c4.96-6.92,13.08-10.54,21.26-10.31
		c0.63,0.02,1.26,0.06,1.89,0.12c1.26,0.13,2.51,0.35,3.75,0.66c4.06,1.03,3.71,5.08,4.07,8.02c1.04,8.4,1.57,16.86,2.25,25.31
		c0.14,1.7,0.24,3.4,0.33,5.11c0.09,1.7,0.17,3.41,0.26,5.11c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0.13,0.58,0.27,1.16,0.42,1.74
		c0.44,1.74,0.91,3.48,1.11,5.25c0.84,7.4,1.5,14.83,2.24,22.25c0.4,2.96,0.96,5.91,1.17,8.89c0.54,7.72,0.53,15.43-1.91,22.91
		c-0.74,1.12-1.48,2.23-2.22,3.35c-4.8,1.8-9.61,3.59-14.41,5.39c-0.23-0.31-0.47-0.61-0.7-0.92c1.22-1.01,2.44-2.01,3.66-3.02
		c1.69-2.23,3.4-4.44,5.05-6.7c0.73-1,1.33-2.03,0.55-2.74c-0.26-0.24-0.68-0.44-1.3-0.59c-1.19,0.19-2.38,0.38-3.57,0.57
		c-3.59,0.57-7.27,1.15-11.29,1.79c0.71,1.81,1.03,2.6,1.34,3.4c2.54-0.13,5.07-0.27,7.81-0.41c-2.47,3.43-7.16,5.54-6.25,11.03
		c-0.78,0.09-1.56,0.09-2.3,0.28c-9.99,2.59-11.06-1.44-12.07-8.9c-0.88-6.53-0.59-13.22-0.85-19.83
		c-0.42-10.74-0.87-21.48-1.31-32.22c-0.87-12.18-1.74-24.36-2.57-36.11c-0.12-1.68-0.24-3.35-0.36-5.01
		c0.56-0.6,1.02-1.09,1.42-1.53c0.14-0.15,0.28-0.3,0.41-0.44c0.48-0.51,0.87-0.94,1.27-1.36c0.36-0.28,0.72-0.56,1.08-0.84
		l-0.22-0.04L458.41,313.39z M457.03,306.63c0.02-0.07,0.38-0.05,0.58-0.07c0.1,0.15,0.19,0.3,0.29,0.45
		c-0.33,0.2-0.66,0.4-0.99,0.6C456.94,307.28,456.94,306.94,457.03,306.63z M456.09,308.08c-0.26,0.52-0.4,1.15-0.8,1.53
		c-2.07,2.03-3.37,3.88-2.55,7.34c0.74,3.15-0.55,6.73-0.44,10.11c0.22,6.33,0.81,12.66,1.25,18.98c0.51,5.84,1.11,11.68,1.52,17.53
		c0.56,7.89,1.26,15.78,1.38,23.68c0.12,8.19-0.69,16.4-0.49,24.59c0.08,3.23,1.38,6.6,2.81,9.58c1.49,3.12,5.8,4.29,9.7,3.19
		c6.36-1.8,12.6-4.17,19.06-5.47c7.38-1.49,10.3-6.73,11.07-12.93c0.28-2.24,0.48-4.49,0.63-6.75c0.08-1.23,0.15-2.46,0.21-3.69
		c0.27-5.55,0.33-11.13,0.59-16.69h0c0,0,0,0,0,0l0.12,0c-0.17-1.47-0.35-2.94-0.52-4.41c-0.08-0.69-0.16-1.39-0.25-2.08
		c-0.2-1.9-0.4-3.79-0.6-5.69c-0.36-2.09-0.76-4.18-1.14-6.27c-0.37-2.09-0.72-4.19-0.95-6.29c-0.53-4.73-0.88-9.49-1.25-14.24
		c-0.12-1.58-0.25-3.17-0.38-4.75l0,0v0l0.06,0.04c-0.16-1.68-0.33-3.37-0.49-5.05c-0.44-2.37-0.93-4.73-1.31-7.11
		c-0.49-3.02-0.95-6.04-1.42-9.06c-0.34-2.22-0.68-4.44-1.02-6.65c-0.56-1.61-1.27-2.83-2.16-3.78c-0.45-0.47-0.94-0.88-1.48-1.23
		c-0.27-0.18-0.56-0.34-0.86-0.49c-0.6-0.3-1.25-0.56-1.97-0.78c-2.5-0.79-5.74-1.2-9.96-1.86c6.09-2.96,10.34-2.41,15.73,1.58
		c4.14,2.91,4.2,7.62,4.95,11.87c1.86,10.56,3.53,21.17,5.01,31.79c0.79,5.71,1.15,11.49,1.49,17.25
		c0.58,9.81,1.41,19.64,1.31,29.45c-0.06,5.97,0.32,12.24-2.68,17.86c-1.2,7.35-6.53,10.9-12.69,13.55
		c-6.53,2.81-13.37,4.4-20.48,5.07c-6.62,0.63-12.33-4.44-12.73-11.61c-0.4-7.05-0.29-14.21-1.55-21.11
		c-2.86-15.57-1.7-31.23-2-46.85c-0.01-0.47,0.06-0.95,0.09-1.43c-0.28-1.49-0.79-2.98-0.8-4.47c-0.08-8.59,0.24-17.19-0.14-25.76
		C449.74,311.83,452.74,310.05,456.09,308.08z M477.23,438.24c-3.15,1.52-5.77,2.79-8.69,4.2c-0.91-2.64-1.7-4.96-2.65-7.72
		c5.33-1,10.63-1.99,15.68-2.94c-0.32,6.65-0.63,12.84-0.94,19.33c-0.35,0.13-0.98,0.36-1.49,0.55
		C478.49,447.16,477.85,442.6,477.23,438.24z M477.34,457.62c-4.87,2.17-9.39,4.19-14.44,6.44c-0.56-5.15-1.11-10.24-1.67-15.4
		c4.89-2.71,9.39-5.21,14.45-8.02C476.28,446.75,476.81,452.14,477.34,457.62z M447.21,401.55c0.05,1.6,0.13,3.2-2.11,3.33
		c-2.27,1.43-3.49,4.8-6.39,4.31c-0.81-22.69-1.59-44.88-2.39-67.31c1-0.62,1.99-1.24,2.97-1.85c0.98-0.61,1.95-1.22,2.93-1.82
		c0.4-0.33,0.81-0.66,1.27-1.04c0.46-0.38,0.98-0.8,1.61-1.32c0.26,2.41,0.62,4.44,0.67,6.48c0.29,11.66,0.48,23.33,0.75,34.99
		C446.7,385.41,446.97,393.48,447.21,401.55z M446.22,410.14c0,5.18,0,9.94,0,15.13c-0.46,0.2-1.33,0.59-2.3,1.02
		c-0.61-4.49-1.19-8.76-1.77-12.95C443.46,412.31,444.63,411.39,446.22,410.14z M440.75,527.98c1.99,7.49,3.82,15.02,5.76,22.53
		c0.32,1.22,0.85,2.38,1.37,3.83c-1.74,4.05-5.05,6.56-9.25,8.09c-1.51,0.48-3.02,1.08-4.53,1.6c-1.51,0.51-3.03,0.93-4.57,1.01
		c-1.41,0.08-3.77-2.17-4.3-3.82c-1.63-5.08-2.97-10.33-3.73-15.61c-1.47-10.23-2.48-20.52-3.63-30.79
		c-0.09-0.77-0.1-1.54-0.09-2.32c0.01-0.78,0.04-1.56,0.03-2.33c-0.36-2.15-0.7-4.31-1.05-6.46c-0.35-2.15-0.71-4.31-1.11-6.45
		c-1.05-5.66-2.21-11.3-3.33-16.95c-0.32,0.05-0.65,0.1-0.97,0.16c0.59,4.35,1.19,8.69,1.76,13.04c0.63,4.75,1.4,9.48,1.79,14.25
		c0.2,2.41,0.31,4.83,0.39,7.25c0.08,2.42,0.13,4.85,0.23,7.27c0.5,5.09,0.96,10.19,1.45,15.29c0.49,5.09,1.01,10.18,1.64,15.26
		c0.54,4.37,1.93,8.63,2.93,12.91c-5.55,0.73-8.34-1.06-9.43-6.54c-0.86-4.35-1.49-8.75-2.23-13.13c-1.09,0.07-2.17,0.14-3.4,0.22
		c0.31,2.7,0.55,4.89,0.81,7.18c-3.23-0.53-3.96-2.75-3.79-5.35c0.41-6.09,0.82-12.18,1.51-18.24c0.75-6.61,1.85-13.17,2.75-19.76
		c0.94-6.82,2.26-13.62,2.62-20.47c0.33-6.24-0.37-12.55-0.67-18.82c-0.06-1.26-0.38-2.51-0.65-4.16c3.94,2.26,6.88,4.82,8.09,8.96
		c0.47,0.7,0.93,1.4,1.4,2.1c0.26,0.72,0.53,1.44,0.79,2.16c0,0,0-0.01,0-0.01c0,0,0,0,0,0l0-0.01c4,7.19,8.27,14.24,12.12,21.5
		c1.28,2.42,2.52,4.86,3.68,7.33C437.98,514.69,439.04,521.5,440.75,527.98z M439.34,506.65c2.11-1.21,4.24-2.43,6.39-3.66
		s4.31-2.47,6.47-3.71c1.29-0.84,2.59-1.69,3.88-2.55c2.58-1.7,5.16-3.41,7.76-5.08c1.3-0.83,2.61-1.66,3.92-2.47
		c3.85-2.37,7.88-4.44,11.93-6.69c0.78,9.09,1.52,17.72,2.26,26.38c-0.9,0.47-1.81,0.96-2.74,1.45c-2.78,1.47-5.68,3.01-8.57,4.54
		c-8.3,4.88-16.6,9.77-25.04,14.74C443.49,521.92,441.46,514.47,439.34,506.65z M640.7,673.52
		C640.7,673.52,640.7,673.51,640.7,673.52c0.01-0.01,0.01-0.02,0.02-0.02c-1.69,0.81-3.38,1.63-5.07,2.44
		c-4.73,2.55-9.43,5.14-14.18,7.65c-4.9,2.58-9.84,5.07-14.76,7.61c-2.3,1.19-4.6,2.39-6.52,3.39c-4.54-2.83-9.04-5.62-13.51-8.41
		c-4.47-2.79-8.93-5.56-13.38-8.33c-3.58-2.16-7.16-4.32-10.75-6.48c-2.07-1.32-4.14-2.63-6.24-3.97c-0.62-2.68,1.12-3.89,3.03-4.86
		c8.45-4.29,16.96-8.48,25.41-12.79c5.58-2.85,11.09-5.85,16.9-8.93c3.47,2.26,6.97,4.54,10.47,6.82c3.08,1.9,6.16,3.79,9.24,5.69
		c7.33,4.4,14.67,8.79,21.97,13.24c0.17,0.1,0.32,0.23,0.46,0.37c0.43,0.42,0.78,0.97,1.19,1.43c0.47,0.23,0.95,0.45,1.63,0.78
		c0.41,0.2,0.89,0.43,1.49,0.71c-2.26,1.12-4.06,2-5.75,2.84C641.8,672.98,641.25,673.25,640.7,673.52L640.7,673.52L640.7,673.52z
		 M557.82,674.12c7.56,4.55,15.13,9.09,22.69,13.64c3.36,2.13,6.71,4.27,10.07,6.4c1.4,0.79,2.8,1.58,4.97,2.81
		c-3.94,2.01-7.43,3.49-10.59,5.47c-8.89,5.57-17.67,11.31-26.45,17.05c-3.34,2.18-6.52,4.6-9.86,6.79
		c-0.63,0.41-1.87,0.61-2.44,0.26c-2.98-1.78-5.83-3.77-8.73-5.69c-6.7-4.46-13.41-8.9-20.08-13.39
		c-5.59-3.76-11.13-7.58-16.57-11.3c16.29-9.43,32.23-18.65,48.84-28.27c0.85,0.65,1.8,1.37,2.8,2.14c0.67,0.51,1.37,1.04,2.08,1.59
		c0.36,0.27,0.72,0.55,1.08,0.82C556.34,672.99,557.08,673.55,557.82,674.12z M396.56,802.04c-5.32,3.51-11.27,2.29-16.56,0.16
		c-2.26-0.91-4.45-1.98-6.62-3.13c-1.44-0.76-2.87-1.56-4.3-2.37c-2.14-1.21-4.27-2.43-6.43-3.59c-3.92-2.26-7.84-4.53-11.76-6.79
		c-0.81-0.58-1.63-1.17-2.44-1.75c0,0,0,0,0,0c0.93,0.44,1.86,0.87,2.79,1.31l0,0c2.03,0.85,4.06,1.65,6.11,2.28
		c0.82,0.25,1.65,0.47,2.48,0.66c0.41,0.09,0.83,0.18,1.25,0.25c1.25,0.22,2.51,0.34,3.79,0.35c2.13,0.01,4.3-0.32,6.52-1.12
		c0.92-0.54,1.86-1.04,2.76-1.62c9.36-5.98,10.85-12.86,4.88-22.09c-0.45-0.7-0.51-1.76-0.47-2.64c0.1-1.98,0.64-3.96,0.52-5.92
		c-0.3-5.19-3.73-7.89-8.49-6.53c-1.91,0.54-3.55,2.03-5.51,3.21c-9.52-2.72-16.56,0.9-19.42,10.17c-2.9,0.82-5.42,1.55-6.16,5.3
		c-0.73,3.68-0.08,6.74,1.58,9.53c0.56,0.93,1.23,1.83,2,2.71v0l0,0c0.37,0.4,0.74,0.79,1.11,1.19c1.32,0.92,2.63,1.84,3.95,2.76
		c0.06,0.04,0.12,0.09,0.18,0.13l0,0l0,0c-0.06-0.04-0.12-0.08-0.18-0.13c-1.63-0.89-3.26-1.78-4.9-2.67
		c-0.17-0.17-0.33-0.35-0.5-0.52c-0.07-0.07-0.14-0.14-0.2-0.21c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-4.33-2.79-8.65-5.58-12.98-8.37
		c-0.18-0.12-0.36-0.23-0.54-0.35l0,0c-0.38-0.22-0.76-0.44-1.14-0.66c-0.19-0.11-0.37-0.22-0.56-0.33
		c-0.4-0.23-0.81-0.47-1.24-0.72c-1.05-0.61-2.2-1.27-3.55-2.05c3.26-0.45,5.6-0.77,7.94-1.09c4.58-1.66,8.72-4.14,10.79-8.63
		c1.14-2.46,0.89-5.53,1.44-8.3c0.3-1.51,0.57-3.27,1.51-4.36c3.35-3.9,3.64-7.91,0.52-13.22c-1.95-3.32-5.85-4.7-10.07-4
		c-1.39,0.23-3.49-0.06-4.34-0.97c-4.74-5.08-10.45-5.65-16.74-4.32c-1.25,0.27-2.61,0.27-3.76,0.78c-2.37,1.04-4.14,2.16-5.6,5.01
		c-2.2,4.31-3.02,10-8.57,12.3c-0.89,0.37-1.54,1.31-2.16,2.31c-0.38,0.6-0.75,1.21-1.15,1.73c-0.5,0-1.04,0.09-1.55,0.14
		c-0.51,0.04-1,0.03-1.39-0.17c-6.7-3.48-13.35-7.04-19.94-10.71c-0.99-0.55-1.6-1.76-2.39-2.67c0.27-0.31,0.54-0.62,0.8-0.93
		c1.11,0.45,2.21,0.9,3.48,1.42c0.52-0.68,1.06-1.4,1.6-2.11c0.68-1.22,1.06-2.85,2.09-3.59c8.3-6,16.72-11.82,25.08-17.74
		c1.83-1.29,3.52-2.77,5.15-4.06c18.52,10.83,37.01,21.65,55.5,32.47c8.16,4.78,16.64,9.1,24.4,14.45
		c7.58,5.22,14.41,11.5,21.61,17.28c8.44,6.78,6.14,17.9,0.15,24.05C403.85,796.77,400.27,799.59,396.56,802.04z M397.94,815.07
		c-2.41,0.76-4.75,1.49-7.12,2.24c-0.36-2.59-0.69-4.98-1.05-7.49c2.19-0.58,4.08-1.07,6.45-1.69
		C396.76,810.29,397.29,812.45,397.94,815.07z M388.1,818.25c-1.44-0.16-2.97-0.34-4.53-0.52c0-3.16,0-5.84,0-8.68
		c1.12,0,2.12,0,3.68,0C387.51,811.98,387.79,814.92,388.1,818.25z M345.37,767.29c-0.05,5.22,1.81,8.35,4.65,10.41
		c2.84,2.06,6.66,3.06,10.51,4.02c3.34-0.24,6.67-0.49,10.01-0.73c1.41-0.83,2.83-1.66,4.24-2.49c0.22,0.35,0.45,0.69,0.67,1.04
		c-1.64,1.18-3.28,2.36-4.93,3.54l0.05,0.05c-4.42,2.8-9.13,2.18-13.47,0.34c-4.27-1.81-8.42-4.18-12.1-6.98
		C342.12,774.28,342.53,769.97,345.37,767.29z M354.33,759.19c0.17-0.2,0.35-0.38,0.54-0.56s0.38-0.34,0.58-0.49
		c0.4-0.3,0.82-0.57,1.26-0.78c0.66-0.32,1.37-0.55,2.12-0.68c2.25-0.38,4.87,0.13,7.65,1.52c-1.38,1.42-2.6,2.67-3.82,3.92
		c-2.04,0.94-3.93,0.76-5.59-0.26c-0.5-0.31-0.98-0.69-1.43-1.14c-0.15-0.15-0.3-0.31-0.45-0.47
		C354.89,759.93,354.61,759.58,354.33,759.19L354.33,759.19L354.33,759.19z M362.68,773.89c2.62-1.01,5.24-2.03,7.86-3.04
		c1.03-1.68,2.06-3.37,3.09-5.05c0.43,0.29,0.86,0.58,1.29,0.86c-1.47,2.8-2.94,5.59-4.41,8.39c-0.91,0.88-1.9,1.45-2.94,1.82
		c-1.04,0.36-2.14,0.51-3.28,0.52c-0.76,0.01-1.53-0.05-2.31-0.14c-0.93-0.06-1.82-0.15-2.67-0.27c-0.42-0.06-0.84-0.13-1.24-0.21
		c-1.21-0.23-2.31-0.52-3.31-0.88c-0.33-0.12-0.65-0.24-0.96-0.38c-0.61-0.26-1.18-0.55-1.69-0.87c-1.78-1.1-2.9-2.52-3.23-4.19
		c-0.17-0.89-0.27-1.8-0.02-2.92C352.91,771.27,357.34,773.57,362.68,773.89z M369.59,754.86c1.41-0.54,2.58-0.99,4.53-1.73
		c0.37,2.73,0.67,4.93,0.96,7.14c-0.33,0.2-0.65,0.41-0.98,0.61C372.71,759.03,371.33,757.18,369.59,754.86z M375.61,778.2
		c0.85-2.28,1.7-4.56,2.55-6.85c0.25,0.09,0.5,0.17,0.76,0.26c-0.74,2.31-1.47,4.63-2.21,6.94
		C376.34,778.44,375.97,778.32,375.61,778.2z M300.17,758.74c2.44,0.95,4.88,1.9,7.33,2.85c0.49,0.23,0.97,0.46,1.46,0.69
		c0,0,0-0.01,0-0.01h0l0,0c0.03,0,0.06,0.01,0.09,0.01c2.59,0.38,5.18,0.77,7.76,1.15c0,0,0,0,0,0c2.87-1.07,5.75-2.14,8.62-3.2
		c5.18-2.35,9.26-5.85,12.06-12.18c0.72,1.8,1.54,2.87,1.46,3.86c-0.28,3.48-1.87,6.04-4.21,8.04c-0.3,0.26-0.62,0.51-0.95,0.75
		c-0.82,0.61-1.71,1.16-2.65,1.67c-0.51,0.27-1.03,0.51-1.55,0.73c-0.47,0.2-0.95,0.38-1.42,0.54c-0.67,0.22-1.35,0.41-2.03,0.57
		c-1.02,0.24-2.06,0.41-3.1,0.53c-0.69,0.08-1.39,0.14-2.09,0.19c-0.35,0.02-0.7,0.04-1.05,0.06c-1.76,0.08-3.52,0.08-5.29,0.1l0,0
		c0,0,0,0,0,0c-1.89-0.45-3.77-0.9-5.66-1.35c-3.08-1.27-6.16-2.55-9.24-3.82C299.86,759.51,300.02,759.12,300.17,758.74z
		 M295.41,748.65c1.28-2.13,2.57-4.26,3.85-6.38c0.4,0.07,0.81,0.14,1.21,0.21c0.65,3.74,1.29,7.48,1.94,11.22
		c0.46,1.28,0.92,2.57,1.41,3.92C298.03,756.59,296.48,754.91,295.41,748.65z M313.62,739.07c0.98,0.49,1.93,0.98,2.89,1.46
		c1.56,0.79,3.1,1.57,4.63,2.34c0.02,0.01,0.03,0.02,0.05,0.02c0.31,0.04,0.64,0.1,0.97,0.17c0.08,0.02,0.15,0.03,0.23,0.05
		c0.32,0.07,0.65,0.14,0.97,0.2c0.72,0.13,1.41,0.19,2-0.02c2.09-0.75,3.94-2.17,5.58-3.13c0.4,6.61-4.01,14.54-9.87,15.98
		c-2.22,0.54-4.74-0.15-7.12-0.28c-4.18-2.76-6.92-6.81-8.37-11.36c-0.9-2.83-0.17-6.18-0.17-9.6
		C308.33,736.39,311.03,737.76,313.62,739.07z M309.12,730.37c1.08-0.44,2.64-0.93,4.06-1.69c4.35-2.32,8.55-1.85,12.66,0.54
		c0.55,0.32,0.84,1.1,1.84,2.47c-5.56-0.41-10.14-0.75-14.94-1.1c0,1.83,0,2.76,0,3.81C311.34,732.86,310.05,731.42,309.12,730.37z
		 M339.57,735.4c-1.44-0.06-2.72-0.12-4.36-0.2c-0.26-0.76-0.62-1.84-0.99-2.92c3.78-1.22,6.94,0.12,8.34,3.31
		c1.64,3.71,0.61,6.06-3.85,8.73c0.34-1.81,0.76-3.57,0.98-5.36C339.83,737.94,339.64,736.88,339.57,735.4z M263.43,717.4
		c-1.93,2.1-3.82,3.45-6.93,1.58c-4.26-2.56-5.95-5.2-5.2-9.97c3.22,1.86,6.29,3.67,9.41,5.39c0.17,0.09,0.35,0.17,0.54,0.23
		c0.55,0.17,1.17,0.23,1.74,0.37c0-0.02-0.01-0.04-0.01-0.07c0,0,0.02,0.02,0.03,0.04c0.02,0.02,0.04,0.04,0.04,0.04c0,0,0,0,0,0
		c-0.66-1.04-1.31-2.08-1.97-3.12c-0.26-0.28-0.52-0.57-0.78-0.85c0.09-0.12,0.18-0.23,0.27-0.35c0.37,0.23,0.74,0.46,1.11,0.7
		c1.7,0.91,3.4,1.82,5.69,3.05c-1.29,0.96-2.11,1.57-2.94,2.18c-0.21-0.24-0.4-0.46-0.57-0.66c-0.29-0.33-0.54-0.63-0.79-0.92
		c-0.01-0.01-0.01-0.01-0.02-0.02c-0.02,0-0.04-0.01-0.05-0.01C263.14,715.77,263.28,716.55,263.43,717.4z M226.69,694.65
		c5.27,0.39,9.32,3.82,14.22,5.54c-0.62,1.7-1.17,3.19-1.99,5.44c-3.99-2.05-7.74-3.99-11.49-5.92c-0.26-0.28-0.53-0.57-0.79-0.85
		c0.09-0.12,0.18-0.23,0.27-0.35c0.37,0.23,0.73,0.46,1.1,0.7l0,0c0.34,0.03,0.68,0.05,1.02,0.08c1.02,0.08,2.07,0.17,3.21,0.26
		C231.94,695.98,228.78,696.08,226.69,694.65z M221.03,687.11c3.76-0.75,7.53-1.51,10.76-2.16c1.54,1.48,2.79,2.68,4.04,3.89
		c0.24-0.26,0.48-0.52,0.72-0.78c2.03,3.88,5.32,6.06,9.51,5.26c1.9-0.36,3.42-2.71,5.49-4.48c0.75,0.38,2.4,1.22,4.06,2.06
		c-3.69,4.99-3.67,6.73,0.11,10.78c0.26,0.28,0.52,0.57,0.78,0.85c-0.09,0.12-0.18,0.23-0.27,0.35c-0.37-0.23-0.74-0.46-1.11-0.7
		l0,0c-1.06-0.52-2.11-1.05-3.17-1.57c-3.16-1.59-6.32-3.19-9.55-4.61C235.38,692.95,228.25,690.1,221.03,687.11z M213.42,622.66
		c2.29,3.36,0.78,5.78-1.28,7.36c0.72,3.58,1.35,6.72,2.05,10.27c-4.65,1.11-8.67,0.73-12.35-0.82c-1.07-0.45-1.73-3.03-1.74-4.64
		c-0.02-5.08,0.58-10.17,0.51-15.25c-0.04-3.06-0.87-6.11-1.34-9.16c-0.39,0.01-0.77,0.01-1.16,0.02c-0.12,1.64-0.24,3.27-0.36,4.91
		c-0.21,4.72-0.78,9.46-0.55,14.17c0.32,6.66,1.23,13.28,1.88,19.92c0.07,0.69,0.08,1.38,0.15,2.64c-3.74,0.32-7.34,0.63-11.03,0.94
		c-0.36-4.3,0.11-8.89-1.28-12.82c-2.2-6.21-1.13-12.32-1.26-18.49c-0.09-4.1-0.35-8.2-0.54-12.51c4.15,0.21,8.11,0.41,12.08,0.61
		c4.96-1.23,9.92-2.47,15.18-3.78c0.37,2.88,0.93,6.2,1.19,9.55c0.16,2.07-0.13,4.18-0.21,6.27
		C213.36,622.11,213.28,622.47,213.42,622.66z M191.61,671.42c4.14,2.45,8.47,2.35,12.91,1.75c0.22,0.32,0.45,0.65,0.67,0.97
		c-1.68,0.5-3.4,1.52-5.04,1.39c-6.09-0.46-12.02-1.72-16.98-5.67c-3.23-2.58-3.35-5.34-0.26-7.89
		C184.44,666.41,187.74,669.13,191.61,671.42z M204.81,666.65c-1.44-0.31-2.8-0.59-4.12-0.87s-2.6-0.55-3.87-0.82
		c-1.88-0.13-3.81-0.12-5.52-0.71c-0.34-0.12-0.67-0.26-0.99-0.43c-0.64-0.34-1.23-0.8-1.76-1.42c0.08-0.38,0.17-0.76,0.25-1.14
		c3.27,0.28,6.55,0.56,9.82,0.85c2.23,0,4.45,0,7.22,0C205.5,663.61,205.2,664.94,204.81,666.65z M203.34,658.79
		c-1.09,0.08-2.17,0.17-3.26,0.25c-1.28,0.02-2.58,0.1-3.88,0.12c-2.86,0.04-5.76-0.19-8.71-1.86c4.49-0.42,8.43-0.8,12.37-1.17
		c3.74-1.72,4.32-3.09,3.04-7.15c-0.52-1.66-1.01-3.32-1.43-5c-0.14-0.54-0.02-1.15-0.02-1.77c4.01,0.52,7.77,1.01,11.75,1.53
		c1.43,4-1.29,9.24,3.64,13.03c0.55-2.24,1-4.05,1.48-6.02c2.6,1.9,5.21,3.82,8.53,6.25C218.63,657.62,210.95,658.21,203.34,658.79z
		 M246.39,599.41c-2.53,3.37-6.17,4.89-9.79,6.49c-1.2,0.53-2.4,1.07-3.56,1.69c-4.7,2.52-9.17,5.47-13.72,8.26
		c-0.78,0.48-1.49,1.07-2.37,1.71c-1.23-3.73-0.17-5.77,3.33-7.25c8.59-3.61,17.04-7.59,25.54-11.42c0.37-0.23,0.74-0.46,1.11-0.69
		c0.09,0.12,0.18,0.23,0.26,0.35C246.92,598.84,246.65,599.13,246.39,599.41C246.39,599.41,246.39,599.41,246.39,599.41z
		 M274.22,511.18c3.67-14.15,5.61-27.96,1.03-42.41c0.94-0.55,2-1.17,3.21-1.87c3.96,6.46,6.13,13.49,5.76,20.46
		c-0.35,6.85-3.09,13.57-4.77,20.35c0.37,0.18,0.74,0.36,1.11,0.54c1.5-2.06,2.99-4.12,4.72-6.5c0,7.48,1.02,14.9-0.32,21.87
		c-1.1,5.73-4.89,10.97-7.67,16.34c-6.43,12.4-8.25,25-1.82,37.97c0.62,1.25,1.84,2.21,2.85,3.4c3.62-2.65,3.89-6.34,3.98-9.7
		c0.08-2.84-0.01-5.68-0.14-8.52c-0.13-2.84-0.31-5.69-0.42-8.53c-0.25-0.72-0.5-1.45-0.75-2.17c-0.25-0.71-0.49-1.42-0.74-2.13
		c-0.56-0.79-1.11-1.57-1.67-2.37c-0.56-0.79-1.13-1.6-1.71-2.42c1.64-2.92,3.32-5.91,5-8.91c0.21,0.01,0.41,0.01,0.62,0.02
		c0,1.11-0.12,2.23,0.06,3.31c0.07,0.39,0.85,0.66,1.3,0.99c0.22-0.22,0.44-0.44,0.67-0.66c-0.22-2.12-0.43-4.23-0.65-6.35
		c-0.27,0-0.53-0.01-0.8-0.01c0.58-1.12,1.16-2.23,1.86-3.59c2.15,2.05,1.87,4.47,1.73,6.84c-0.02,0.36-0.04,0.73-0.05,1.09
		c0,0.18,0,0.36,0,0.54l0,0v0c-0.25,0.44-0.5,0.88-0.76,1.32c0.25-0.44,0.5-0.88,0.76-1.32c-0.25,0.44-0.5,0.88-0.76,1.32
		l-0.04,0.02c-0.09,0.98-0.23,1.96-0.33,2.95c-0.1,0.98-0.17,1.96-0.12,2.94c0.06,1.06,0.92,2.09,1.99,2.95
		c0.27,5.17,0.44,10.34,0.84,15.5c0.2,2.63-0.3,4.86-2.3,6.66c0.45,0.82,0.9,1.63,1.36,2.45c0.62,3.54,1.99,2.78-4.33,7.95
		c0.74,0.69,1.44,1.35,2.82,2.65c-2.83,0.66-5.17,1.2-7.47,1.73c2.86,3.82,5.19,3.14,9.71-2.84c0.14,6.5-0.89,7.64-4.21,7.71
		c0.64,1.32,1.28,2.52,1.82,3.77c1.75,4,3.05,8,3.14,12.54c0.14,7.46,1.13,14.9,1.47,22.36c0.17,3.76-0.33,7.55-0.53,11.44
		c-0.93,0.42-1.72,0.78-2.51,1.14c-0.18-0.43-0.38-0.66-0.34-0.84c2.91-12.04,2.36-23.22-7.25-32.57
		c-3.9-3.79-7.26-8.13-10.89-12.21c-2.95-3.32-3.62-7.26-3.34-11.78c3.31,1.19,6.42,2.3,9.63,3.45c-1.46-3.74-3.96-5.22-9.35-5.66
		c-0.62-10.64-1.23-21.27-1.89-32.56c1.47,1.8,2.58,3.15,3.69,4.5c0.24-0.11,0.48-0.22,0.72-0.32c-0.7-2.31-1.39-4.63-2.11-6.94
		c-0.73-2.36-1.42-4.73-2.26-7.05c-2.32-6.45,1.37-11.47,3.5-16.58c5.32,1.49,5.69,6.99,8.7,10.17c0-5.96,0-11.74,0-18.19
		c-3.43,2.25-2.65,5.36-2.96,8.55c-5.46-5.35-10.76-10.12-12.34-18.56c3.58,4.49,6.55,8.22,9.81,12.3c0.89-1.45,1.46-2.38,2.17-3.55
		c-9.91-6.58-14.46-15.71-13.36-27.67c1.07,0.68,2.21,1.41,4.22,2.69c-1.76-4.82-5.33-8.81-1.88-13.36
		c1.08,6.35,4.64,11.16,8.86,15.59c3.49,3.66,5.57,7.6,2.11,12.39c-1.37-2.78-2.77-6.12-6.7-6.5
		C268.51,501.65,271.17,506.08,274.22,511.18z M289.51,447.64c-0.03,1.21-0.56,1.93-1.32,2.49c-0.25,0.19-0.54,0.36-0.83,0.52
		c-0.3,0.16-0.61,0.32-0.94,0.49c-0.81,0.41-1.66,0.87-2.42,1.55c0.62-2.23,1-3.6,1.38-4.98c1.4-6.28,2.8-12.57,4.1-18.4
		c0,5.54,0,11.61,0,17.68C289.5,447.23,289.51,447.44,289.51,447.64z M267.15,639.27c0.02-0.01-0.46,0.24-1.07,0.55
		c0-2.74,0-5.42,0-7.96c1.01,0.94,2.04,1.91,3.09,2.88c1.05,0.98,2.1,1.97,3.16,2.96h0c0.37,0.23,0.73,0.46,1.1,0.7
		c0.09-0.12,0.18-0.23,0.27-0.35c-0.26-0.28-0.53-0.57-0.79-0.85c-2.37-4.29-4.78-8.57-7.08-12.9c-0.41-0.78-0.57-1.77-0.58-2.67
		c-0.05-5.01-0.03-10.01-0.03-15.2c1.49-1.41,3.04-2.88,4.77-4.51c1.92,2.22,3.88,4.5,6.14,7.11c-4.41,12.45-1.69,24.53,3.9,36.67
		c-1.81,0.92-3.44,1.75-5.47,2.78C272.16,645.5,269.74,642.5,267.15,639.27z M273.12,649.64c-0.63,0.59-1.25,1.19-2.44,2.32
		c-1.3-2.17-2.47-4.1-3.6-5.97c-0.36-0.42-0.71-0.84-1.07-1.26c0.01-0.15,0.02-0.31,0.03-0.46c0.34,0.57,0.69,1.15,1.04,1.72
		c1.39,1.64,2.78,3.29,4.17,4.93C271.87,650.49,272.5,650.07,273.12,649.64z M260.87,462.63c-1.84,0.6-3.76,0.95-6.29,1.57
		c-0.84-6.2-1.65-12.16-2.46-18.12c4.2,5.34,6.8,11.98,14.64,14.18C264.51,461.18,262.73,462.03,260.87,462.63z M254.46,477.65
		c0.2-0.01,0.4-0.02,0.6-0.03c0.76,8.55,1.31,17.12,2.32,25.64c2.13,17.93,2.85,35.9,2.91,53.94c0.04,11.27,0.99,22.53,1.33,33.79
		c0.37,12.02,0.57,24.05,0.71,36.08c0.11,9.36,0.02,18.72,0.02,28.39c0.05,0,0.05,0,0,0l0,0c-0.16,0.01-0.86,0.06-2.17,0.16
		c0-4.74,0.15-9.32-0.03-13.9c-0.54-14.47-1.21-28.94-1.82-43.41c-0.02-0.36-0.01-0.72-0.02-1.07c-0.24-20.48-0.41-40.97-0.75-61.45
		c-0.16-9.98-0.46-19.97-1.01-29.93c-0.47-8.48-1.39-16.93-2.08-25.4C254.4,479.54,254.46,478.59,254.46,477.65z M269.38,661.92
		c0.13,0.27,0.25,0.54,0.38,0.81c0.78-0.84,1.56-1.67,2.34-2.51c0,0,0,0,0,0c0.82-0.98,1.49-2.19,2.42-2.99
		c0.13-0.11,0.27-0.22,0.42-0.32c5.89-3.88,11.93-7.53,17.83-11.4c1.13-0.74,1.87-2.06,3.25-3.65c2.34,1.15,4.78,2.36,7.22,3.57
		c2.86,1.18,5.77,2.3,7.52,4.22c0.8,0.87,1.36,1.9,1.56,3.18c3.16,1.19,5.67,1.92,7.97,3.07c3.69,1.84,7.24,3.95,11.13,6.1
		c-1.63,1.36-3.03,2.52-4.86,4.04c3.5,1.79,5.56-0.43,7.92-1.71c1.03,0.74,2.15,1.55,3.81,2.75c-1.48,0.81-2.83,1.55-4.12,2.25
		c-1.29,0.7-2.51,1.37-3.74,2.04l0,0c-3.25,2.01-6.49,4.02-9.76,6.04s-6.55,4.06-9.88,6.12c-11.17-6.99-22.26-13.94-33.36-20.88
		c-1.67-0.68-3.34-1.35-5.01-2.03c0.73,1.13,1.45,2.27,2.18,3.4c6.42,3.99,12.87,7.93,19.26,11.97c4.31,2.74,8.53,5.62,12.65,8.35
		c-4.47,2.78-8.89,5.52-13.4,8.33c-7.98-4.19-16.22-8.32-24.24-12.83c-0.89-0.5-1.76-1.05-2.6-1.63c-0.85-0.58-1.68-1.19-2.5-1.81
		c-1.06-0.8-2.1-1.62-3.15-2.42c-0.48-0.37-0.97-0.73-1.46-1.09c-0.11-0.08-0.21-0.16-0.32-0.24c-0.8-0.15-1.6-0.3-2.4-0.45
		s-1.62-0.3-2.44-0.45c-0.18-0.96-0.36-1.88-0.63-3.32C258.59,666.32,263.98,664.12,269.38,661.92z M320.51,470.77
		c10.42-6.91,21.09-13.44,31.71-20.03c3.41-2.12,7-3.95,10.65-5.99c1.38,10.29,2.72,20.32,4.09,30.55
		c-0.63,0.36-1.25,0.71-1.86,1.05c-1.83,1.03-3.57,2.02-5.31,3l0,0c0,0,0,0,0,0c0,0,0.05-0.05,0.05-0.05
		c-0.02,0-0.04,0.01-0.05,0.01c0,0.01,0,0.01,0,0.02c-5.43,3.1-10.85,6.23-16.3,9.31c-3.46,1.95-6.97,3.82-10.44,5.75
		c-6.01,3.35-12.03,6.69-18.01,10.11c-1.72,0.98-3.3,2.2-5.48,3.68c-1.44-9.23-2.85-18.22-4.17-26.7c1.16-0.21,2.54-0.15,2.68-0.54
		C310.25,475.1,316.08,473.7,320.51,470.77z M313.56,471.73c0-0.26,0-0.53,0-0.79c0.09,0,0.19,0,0.28,0c0,0.26,0,0.52,0,0.78
		C313.75,471.72,313.66,471.73,313.56,471.73z M314.47,470.2c5.51-7.59,11.02-15.19,16.04-22.1c2.53-0.79,4.51-1.42,6.64-2.08
		c4.46,0.67,9.21,1.38,14.44,2.16c-12.29,7.6-24.41,15.1-36.52,22.6C314.87,470.58,314.67,470.39,314.47,470.2z M375.37,480.41
		c-0.3,2.06-0.51,3.53-0.76,5.19c-4.48,2.64-9.02,5.31-13.81,8.13c-0.86-1.99-1.6-3.7-2.46-5.7
		C363.96,485.52,369.44,483.07,375.37,480.41z M386.32,485.02c1.05,0.56,2.15,1.15,3.96,2.12c-4.38,1.79-8.3,3.14-11.96,5
		c-2.02,1.03-3.4,3.24-5.38,4.4c-17.68,10.31-35.42,20.51-53.15,30.73c-6.04,3.48-12.06,6.99-18.16,10.38
		c-1.51,0.84-3.22,1.45-4.91,1.75c-0.8,0.14-1.78-0.69-2.25-0.89C324.28,519.07,354.35,500.58,386.32,485.02z M388.06,503.72
		c-29.12,15.59-58.47,31.94-86.72,51.03c-0.37-4.51-0.67-8.22-0.97-11.79c29.19-16.93,58.19-33.74,87.68-50.85
		C388.06,496.02,388.06,499.51,388.06,503.72z M389.81,521.35c-1.85,0.16-3.56,0.3-5.7,0.48c-0.48-3.59-0.96-7.26-1.48-11.13
		c1.15-0.77,2.02-1.34,3.43-2.28C387.35,512.83,388.54,516.97,389.81,521.35z M382.42,531.39c-3.81,1.48-7.91,3.08-12.61,4.91
		c-1.5-5.41-2.87-10.34-4.24-15.25c5.12-2.99,9.87-5.76,14.66-8.56C380.98,518.88,381.68,524.94,382.42,531.39z M364.8,528.55
		c-2.05,0.46-4.05,0.91-6.25,1.41c0-1.63,0-3.13,0-4.72c1.45-0.86,2.87-1.71,4.74-2.82C363.78,524.41,364.23,526.22,364.8,528.55z
		 M297.38,555.81c-0.42,0.01-0.85,0.01-1.27,0.02c-0.46-4.29-0.91-8.58-1.51-14.19c1.41,1.11,2.66,1.62,2.69,2.19
		C297.47,547.82,297.38,551.82,297.38,555.81z M315,550.73c9.24-5.52,18.21-11.48,27.39-17.08c4.29-2.62,8.82-4.86,13.32-7.32
		c0.53,2.58,0.93,4.59,1.4,6.9c2.95-0.3,5.38-0.54,8.04-0.8c1.03,2.26,2.09,4.58,3.17,6.95c7.16,0.46,12.29-4.43,17.85-5.76
		c-0.59-2.83-1.14-5.5-1.67-8.01c3.29-1.17,5.89-2.1,8.49-3.03c-1.53-5.71-2.8-10.46-4.13-15.43c2.35-1.15,4.42-2.16,6.54-3.19
		c0.32,5.42,0.6,10.62,0.94,15.81c0.65,10.06,1.41,20.12,1.97,30.19c0.23,4.16,0.06,8.35,0.07,12.52c0.55,3.41,1.11,6.82,1.74,10.7
		c-0.45,0.26-1.39,0.83-2.34,1.39c-9.82,6.44-19.64,12.87-29.46,19.31c-0.2,0.06-0.4,0.12-0.59,0.18
		c-5.1,3.31-10.15,6.69-15.23,10.03c-2.54,1.67-5.09,3.33-7.65,4.96c-2.56,1.63-5.14,3.24-7.74,4.81
		c-12.47,7.51-25.27,14.49-37.93,21.69c-0.81,0.46-1.64,0.89-3.09,1.68c-3.28-27.5-3.86-54.67-3.26-81.92
		c0.26,1.22,0.48,2.46,0.79,3.67c0.11,0.43,0.47,0.8,0.7,1.18C302.42,559.56,308.51,554.61,315,550.73z M390.6,490.72
		c3.18,3.92,3.44,6.42,1.61,10.57C391.61,497.38,391.11,494.05,390.6,490.72z M393.6,433.53c-0.17-1.26-0.02-2.57-0.02-4.09
		c4.67,2.57,5.51,6.37,5.75,10.94c0.37,6.81,1.54,13.58,2.38,20.37c0.35,2.81,0.8,5.61,1.03,8.43c0.77,9.44,1.78,18.87,2.08,28.32
		c0.17,5.38-0.79,10.82-1.42,16.21c-0.65,5.49-1.52,10.95-2.42,17.25c-0.47-1.76-0.96-2.84-1.01-3.94
		c-0.31-6.16-0.54-12.33-0.75-18.5c-0.5-14.84-0.58-29.72-1.58-44.52C396.94,453.79,394.96,443.68,393.6,433.53z M403.58,599.31
		c0.69,2.13,1.16,3.57,1.63,5.01c0.3-0.06,0.6-0.11,0.91-0.17c0.12-0.86,0.25-1.72,0.48-3.36c6.95,4.46,13.64,8.75,20.47,13.13
		c-0.88,0.75-1.56,1.32-3.16,2.67c11.19-0.32,15.66,8.82,23.39,12.69c-4.19,2.48-8.14,4.85-12.11,7.17
		c-6.32,3.69-12.63,7.41-19.03,10.98c-1.15,0.64-2.66,0.62-4,0.91c-0.1,0.08-0.2,0.17-0.29,0.25c0.1-0.08,0.2-0.17,0.29-0.25
		c-0.62-0.25-1.23-0.49-1.85-0.74c-0.06,0.16-0.13,0.31-0.19,0.47c0.48,0.25,0.97,0.51,1.45,0.76c-0.14,0.1-0.28,0.2-0.42,0.3
		c-0.96,0.72-1.88,1.57-2.96,1.93c-3.4,1.16-6.52,4.36-10.6,1.7c-0.33-0.22-1.21,0.41-2.19,0.79c1.18,0.88,2.06,1.53,2.85,2.12
		c-4.62,4.03-9.94,5.83-16.43,3.95c0.1,0.54,0.2,1.07,0.3,1.61c1.33,0.58,2.66,1.17,3.87,1.7c-6.85,4.19-13.73,8.41-20.8,12.74
		c-1.95-1.07-4.04-2.21-6.65-3.65c2.52-0.65,4.57-1.18,6.62-1.71c-0.01-0.12-0.01-0.24-0.02-0.36c-2.63,0-5.27,0-7.98,0
		c0.1-0.87,0.16-1.49,0.34-3.1c-1.5,1.61-2.39,2.56-3.41,3.64c-3.06-1.85-6.25-3.78-9.46-5.72c0.29-1.01,0.52-1.8,0.93-3.26
		c-1.34,0.89-2.08,1.39-2.88,1.92c-1.35-0.8-2.73-1.63-4.19-2.49c0.89-1.05,1.85-2.17,2.8-3.29c-4.82,3.71-8.23,0.14-11.86-2.06
		c-3.18-1.93-6.36-3.88-9.73-5.94c1.31-0.95,2.5-1.81,3.68-2.67c-0.12-0.24-0.24-0.47-0.35-0.71c-1.82,0.63-3.64,1.26-5.61,1.95
		c-0.97-0.45-1.95-0.91-2.94-1.37c-2.98-1.39-6.04-2.82-9.11-4.25c-0.37-0.18-0.73-0.35-1.1-0.53c-0.05-0.03-0.11-0.05-0.17-0.08
		c-0.78-0.38-1.58-0.76-2.45-1.19c-0.45-0.22-0.92-0.44-1.41-0.68c5.09-3.23,9.87-6.38,14.8-9.26c0.33-0.19,0.86-0.03,1.44,0.22
		c0.59,0.25,1.22,0.6,1.77,0.79c2.52,1.25,5.05,2.49,7.58,3.74c2.53,1.25,5.07,2.5,7.62,3.76c-1.09,1.33-1.9,2.31-2.71,3.3
		c0.26,0.29,0.52,0.58,0.78,0.87c1.33-0.76,2.65-1.52,4.37-2.51c3.18,2.42,8.24,2.69,9.89,6.84c6.65,3.11,13.06,6.12,19.35,9.06
		c0.82,2.54,1.58,4.89,2.34,7.25c0.24-0.05,0.49-0.09,0.73-0.14c0-1.69,0-3.38,0-3.99c16.74-12.04,36.74-17.02,51.54-31.08
		c1.64,0,3.29,0,4.93,0c0.03-0.18,0.07-0.36,0.1-0.54c-1.51-0.39-3.03-0.79-4.54-1.18c0.14-0.16,0.28-0.32,0.42-0.48
		c-1.42-1.15-2.76-2.42-4.27-3.43c-2.99-1.99-6.15-3.73-9.08-5.8c-1.13-0.8-1.82-2.21-3-3.71c-5.32,0.24-8.63-5.04-13.51-7.15
		c-0.42-0.18-0.78-0.48-1.26-0.79c0.36-0.45,0.75-0.94,0.95-1.19c-3.16-0.83-6.21-1.63-9.64-2.52c1.54-1.81,2.5-2.93,3.46-4.05
		c-0.13-0.15-0.25-0.29-0.38-0.44c-1.8,0.87-3.6,1.74-5.71,2.76c-0.39-0.33-0.82-0.68-1.27-1.06c-0.45-0.37-0.92-0.76-1.38-1.15
		c-0.65-0.23-1.29-0.46-2.01-0.71c-0.24-0.08-0.49-0.17-0.74-0.26c2.42-1.67,4.61-3.21,6.84-4.7c2.11-1.4,4.26-2.73,6.66-4.27
		c0,1.13,0,1.94,0,2.76C392.28,593.91,398.24,596.76,403.58,599.31z M467.55,664.05c-3.63-1.32-6.83-2.48-10.03-3.64
		c-0.16,0.3-0.33,0.61-0.49,0.91c0.9,0.82,1.8,1.64,2.1,1.92c0.11,1.76,0.18,3.03,0.22,3.69c-5.76,1.42-11.93,2.5-17.76,4.54
		c-3.37,1.18-6.34,3.67-8.15,7.22c-0.43,0.85-2.36,0.94-3.92,1.5c-0.01-0.03,0.21,0.54,0.63,1.63c-1.28,0.29-2.53,0.57-3.79,0.86
		c-3.46,3.91-10.55,4.77-11.39,11.96c-4.82-2.96-9.19-5.64-13.56-8.32c-0.21,0.17-0.42,0.35-0.63,0.52h0
		c0.88,0.65,1.79,1.28,2.68,1.92c0.89,0.64,1.77,1.3,2.6,2c1.49,1.26,2.79,2.74,4.17,4.13c-0.1,0.37-0.21,0.74-0.31,1.11
		c-1.62,0.31-3.24,0.63-4.99,0.96c-0.75,2.26-0.99,2.41-5.75,3.4c0.11,0.66,0.23,1.33,0.5,2.87c-2.47,0.58-5.27,0.96-7.86,1.92
		c-2.44,0.91-5.96,0.65-6.24,4.74c-1.22,0-2.39,0-3.55,0c-1.23,0.51-2.47,1.02-3.77,1.56c0.36,1.47,0.6,2.44,0.84,3.42
		c-3.34,0.93-6.58,1.83-9.81,2.73c0.06,0.3,0.13,0.6,0.19,0.9c-6.79-0.14-10.92-5.46-16.11-8.58c-12.82-7.7-25.38-15.83-38.04-23.79
		c-0.57-0.36-1.08-0.78-1.84-1.34c7.05-3.84,13.81-7.52,20.57-11.2c2.2-1.49,4.4-2.98,6.83-4.63c1.47,1.03,2.96,2.08,4.33,3.04
		c-0.79,0.85-1.44,1.55-2.17,2.34c3.02,0.3,5.51,0.55,7.29,0.73c0.85,2.47,1.38,4.01,2.19,6.37c0.93-1.78,1.35-2.57,1.86-3.56
		c1.52,0.94,3.04,1.88,4.77,2.95c-2.32,2.88-3.04,5.85-1.32,9.09c0.19,0.19,0.39,0.39,0.58,0.58c0.51,0.53,1.03,1.05,1.54,1.58
		c1.15,0.91,2.31,1.82,3.46,2.74c0.62,0.28,1.23,0.56,1.85,0.84l0.27,0.03c3.25-0.77,6.63-1.2,9.71-2.4
		c3.06-1.2,3.28-3.94,0.95-6.62c-1.01-1.16-2.13-2.25-3.28-3.27c-1.01-0.89-2.15-1.63-3.3-2.49c4.22-2.58,8.12-4.97,11.8-7.22
		c2.9,1.67,5.68,3.27,8.46,4.87c0.2-0.16,0.39-0.33,0.59-0.49c-0.58-0.42-1.17-0.85-1.76-1.28c-1.78-1.29-3.66-2.66-5.93-4.3
		c4.76-2.87,9.25-5.57,13.32-8.02c3.36,0.2,6.29,0.37,9.22,0.55c0.04-0.18,0.07-0.37,0.11-0.55c-1.81-0.47-3.63-0.94-5.44-1.41
		c-0.08-0.37-0.16-0.73-0.23-1.1c3.62-1.06,7.24-2.12,10.86-3.18c0,0-0.01-0.01-0.01-0.01c-0.35-0.28-0.8-0.44-1.07-0.57
		c8.55-4.61,17.28-9,25.65-13.98c3.85-2.29,6.84-2.01,10.48,0.08c7.32,4.21,14.83,8.09,22.26,12.11c-0.52,1.27-0.98,2.4-1.44,3.53
		c-0.42-0.38-0.84-0.76-1.27-1.14C465.91,660.38,466.63,662,467.55,664.05z M383.41,745.88
		C383.41,745.88,383.41,745.88,383.41,745.88L383.41,745.88c-0.74-0.47-1.47-0.94-2.21-1.41c-2.21-1.19-4.42-2.36-6.63-3.53
		c-6.64-3.52-13.29-7.02-19.82-10.74c-11.4-6.49-22.63-13.3-33.95-19.93c-1.53-0.9-3.18-1.59-4.8-2.33
		c-4.22-1.93-8.67-3.3-11.56-7.53c-1.38-2.02-3.89-3.26-6.05-4.98c4.9-2.73,9.6-5.36,13.89-7.75c2.22,1.69,3.8,3.32,5.73,4.28
		c6.23,3.11,11.72,7.22,17.26,11.41c5.91,4.46,12.67,7.77,18.75,12.03c12.1,8.47,23.95,17.29,36.02,25.81
		c1.28,0.9,2.77,1.5,4.28,2.08c0.6,0.23,1.21,0.45,1.81,0.69c0.3,0.12,0.59,0.24,0.89,0.37c0.03-0.24,0.04-0.47,0.04-0.69
		c0-0.22-0.02-0.43-0.04-0.64c-0.08-0.61-0.28-1.15-0.55-1.63c-0.18-0.32-0.4-0.62-0.64-0.91c-1.08-1.28-2.65-2.22-3.75-3.46
		c-1.14-1.28-2.79-2.1-4.23-3.14c0-1.12,0-2.18,0-3.77c2.12,0.14,4.12,0.27,7.01,0.46c-1.43-1.93-2.48-3.35-3.53-4.76
		c-2.96-1.75-5.92-3.51-8.88-5.26c4.31-0.29,7.01,2.77,10.34,4.49c0.76,0.15,1.52,0.31,2.48,0.5c0-0.82,0-1.54,0-2.34
		c1.11,0.14,2.19,0.27,3.26,0.4c0-0.58,0-1.17,0-1.98c1.09,0.3,2.04,0.56,3.29,0.9c0.15-0.78,0.31-1.57,0.5-2.55
		c0.76,0.28,1.38,0.51,2.22,0.82c0.25-0.71,0.5-1.41,0.78-2.18c1.37,0.53,2.72,1.05,4.19,1.62c0.17-2.25,0.32-4.2,0.48-6.35
		c1.93,0.75,3.6,1.39,6.34,2.46c-1.06-2.23-1.63-3.45-2.21-4.67c0.12-0.17,0.23-0.35,0.35-0.52c6.81,4.5,13.62,8.99,21.25,14.03
		c-2.03,1.15-4.03,2.29-6.01,3.42c-5.95,3.38-11.75,6.68-17.55,9.97c-3.12,1.71-6.23,3.42-9.35,5.13c-1.84,1.16-3.66,2.34-5.5,3.5
		c-1.84,1.16-3.68,2.3-5.56,3.4c-0.61,0.36-1.92,0.11-2.59-0.33c-0.5-0.33-0.99-0.68-1.48-1.04
		C386.23,748.14,384.84,746.98,383.41,745.88C383.41,745.88,383.41,745.88,383.41,745.88z M389.16,710.47
		c1.58-2.45,1.58-2.45,7.04,1.94c0.77,0.46,1.54,0.91,2.31,1.37c-0.13,0.23-0.25,0.46-0.38,0.7c-0.85-0.52-1.71-1.03-2.56-1.55
		C393.44,712.12,391.32,711.3,389.16,710.47z M408.68,713.38c0.04-0.14,0.09-0.27,0.13-0.41c0.38,0.09,0.75,0.18,1.13,0.27
		c-0.1,0.22-0.2,0.43-0.3,0.65C409.32,713.72,409,713.55,408.68,713.38z M412.34,710.61c0.06-0.19,0.13-0.39,0.19-0.58
		c0.31,0.13,0.63,0.25,0.92,0.42c0.03,0.02-0.13,0.36-0.2,0.55C412.94,710.87,412.64,710.74,412.34,710.61z M417.32,708.42
		c0.22-0.41,0.45-0.83,0.67-1.24c1.7,0.49,3.39,0.98,5.3,1.53c0.18-1.03,0.33-1.91,0.46-2.67c1.49,0.44,2.92,0.86,4.36,1.28
		c0.31-0.23,0.62-0.46,0.92-0.7c-0.81-1.6-1.62-3.19-2.43-4.79l0,0c-0.55-0.36-1.1-0.72-1.65-1.08c-0.48-0.28-0.96-0.55-1.43-0.83
		c-0.28-0.16-0.55-0.32-0.83-0.48c-0.61-0.4-1.21-0.8-1.82-1.2h0l0,0c-0.46-0.11-0.92-0.22-1.38-0.32c0.29-0.32,0.59-0.64,0.88-0.96
		c0.37,0.27,0.74,0.54,1.11,0.8c0.6,0.31,1.2,0.62,1.79,0.93l0,0l0,0c0,0,0,0,0,0c0.76,0.52,1.52,1.04,2.28,1.56
		c0.55,0.36,1.1,0.72,1.66,1.08c2.58,1.27,5.16,2.54,8.05,3.95c2.26-3.29-0.7-4.21-2.08-6.07c2.38,0.88,4.77,1.76,7.36,2.72
		c0.13-0.76,0.25-1.5,0.39-2.36c0.57,0.11,1.14,0.21,2.56,0.48c-0.85-1.98-1.46-3.39-2.23-5.2c1.83,0.53,3.57,1.03,5.32,1.53
		c0.48-3.1-1.08-4.39-11.24-10.03c5.54-0.76,8.53,5.2,14.3,5.73c-2.39-5.03-7.38-6.19-10.8-9.76c1.19,0.16,2.58-0.03,3.54,0.53
		c3.52,2.03,6.87,4.35,10.35,6.45c1.22,0.74,2.65,1.14,4.15,1.36c-3.21-6.04-9.73-7.56-14.66-12.18c1.03,0,2.29-0.39,3.06,0.06
		c4.09,2.4,8.05,5.03,12.09,7.51c0.86,0.53,1.88,0.8,2.83,1.18c0.29-0.2,0.57-0.4,0.86-0.59c-0.59-1.11-0.91-2.64-1.83-3.26
		c-4.06-2.69-8.3-5.12-13.42-8.21c1.97,0,3.35-0.42,4.19,0.08c2.89,1.71,5.5,3.87,8.39,5.58c1.32,0.79,3.03,0.93,4.56,1.37
		c0.27-0.47,0.54-0.95,0.81-1.42c-1.41-1.24-2.72-2.64-4.26-3.68c-1.58-1.07-3.4-1.79-4.94-3.5c0.99,0.18,2.14,0.09,2.94,0.57
		c10.68,6.41,21.3,12.89,32.1,19.45c-16.58,9.82-32.86,19.47-49.46,29.3C432.64,718.17,424.98,713.29,417.32,708.42z M429.76,688.52
		c0.16-0.28,0.32-0.57,0.48-0.85c3.45,1.19,6.64,2.87,9.14,5.8v0c0.61,0.29,1.22,0.59,1.83,0.88c-0.15,0.28-0.29,0.56-0.44,0.85
		c-0.63-0.37-1.26-0.75-1.89-1.12C435.85,692.23,432.81,690.38,429.76,688.52z M365.75,680.42c2.99,2.72,6.1,5.55,9.48,8.62
		c-6.87,4.35-12.01,4.07-14.97-0.43C358.72,686.26,360.64,683.24,365.75,680.42z M399.18,615.55c-7.53,4.99-14.86,9.85-22.01,14.59
		c-4.05-2.26-8.71-4.87-13.45-7.52c4.48-3.87,9.26-8.01,14.04-12.15c0.58-0.29,1.16-0.59,1.74-0.88c-0.14-0.25-0.28-0.51-0.41-0.76
		c-0.6,0.35-1.2,0.69-1.81,1.04c-5.61,4.15-11.23,8.3-16.89,12.48c-3.36-3.14-6.96-6.5-10.73-10.02
		c6.95-3.49,14.49-7.28,22.55-11.33c0.99,0.85,2.33,2,2.93,2.51c2.81,0.83,4.87,1.44,7.46,2.21c-1.28,1.01-2.03,1.6-2.79,2.19
		C388.22,606.02,393.09,612.34,399.18,615.55z M416.6,629.11c-7.31,3.73-13.91,7.1-19.77,10.1c-5.5-2.73-10.21-5.06-15.05-7.46
		c4.7-6.67,13.48-8.87,19.95-14.31c4.14,2.56,8.16,5.05,12.36,7.64c-1.47,0.37-2.78,0.7-4.09,1.02c-0.03,0.31-0.07,0.62-0.1,0.93
		C411.72,627.6,413.54,628.16,416.6,629.11z M335.33,638.28c1.02-0.86,1.28-1.18,1.62-1.35c5.57-2.89,11.13-5.81,16.74-8.64
		c9.16-4.62,14.85-3.55,21.99,4.27c-3.76,2.15-7.48,4.23-11.15,6.38c-3.19,1.88-6.15,4.26-9.54,5.61c-1.94,0.77-4.72,0.46-6.76-0.31
		c-3.21-1.21-6.11-3.22-9.35-5.01c0.43-0.48,0.83-0.93,1.51-1.68C338.78,637.79,337.52,637.97,335.33,638.28z M377.85,634.69
		c2.47-1.14,6.62,0.79,9.79,1.91c2.11,0.74,3.86,2.53,5.91,3.95c-8.67,4.68-17,9.17-25.51,13.76c-0.39-2.08-0.68-3.68-0.98-5.28
		c-0.39-0.03-0.79-0.05-1.18-0.08c-0.49,1.17-0.98,2.34-1.58,3.77c-3.04-1.49-6.21-3.05-9.73-4.77
		C362.41,643.36,369.84,638.4,377.85,634.69z M358.4,622.61c-8.83,4.59-17.27,8.98-25.76,13.4c-3.69-1.9-7.51-3.87-12.14-6.26
		c9.08-5.47,17.8-10.72,27.22-16.4C351.3,616.45,354.87,619.55,358.4,622.61z M260.02,693.78c1.53,0.59,3.23,1.23,5.03,1.92
		c0,1.12,0,2.13,0,3.2c-3.15,2.22-5.59,0.81-8.02-1.57C258.03,696.15,258.96,695.04,260.02,693.78z M256.9,685.72
		c1.93-2.19,3.46-3.93,5.04-5.72c1.44,0.82,2.8,1.6,4.41,2.51c-2.56,1.65-4.66,3.01-7.18,4.63
		C258.54,686.75,257.59,686.15,256.9,685.72z M265.04,704.39c0.32,1.09,0.72,2.43,1.34,4.51c-2.74-1.64-4.76-2.85-7.54-4.51
		C261.37,704.39,262.95,704.39,265.04,704.39z M262.61,689.29c6.65-4.62,8.83-4.63,13.02-0.66c-1.09,1.78-2.15,3.52-3.4,5.58
		C269.44,692.78,266.27,691.16,262.61,689.29z M279.19,690.09c3.05,1.58,6.25,3.24,9.45,4.89c-0.06,0.21-0.12,0.42-0.18,0.62
		c-4.52,0.2-9.04,0.4-13.81,0.6C276.53,693.67,277.76,692.03,279.19,690.09z M291.61,702c-6.69,2.75-13.33,5.47-20.48,8.41
		c-0.34-3.84-0.66-7.57-0.96-10.91C277.77,700.38,284.74,701.2,291.61,702z M292.03,708.34c0.06,0.29,0.12,0.59,0.18,0.88
		c-6.39,4.37-12.78,8.74-19.49,13.33c-0.33-2.4-0.6-4.32-0.95-6.8C277.9,712.04,284.95,710.16,292.03,708.34z M258.35,677.52
		c-2.45,2.26-4.25,3.92-6.14,5.67c-2.29-1.3-4.28-2.43-6.89-3.92C249.59,677.86,252.92,675.11,258.35,677.52z M247.55,687.16
		c-1.35,1.4-2.1,2.73-2.68,2.66c-2.1-0.25-4.14-0.97-6.21-1.52c0.1-0.51,0.2-1.02,0.3-1.53C241.45,686.89,243.94,687,247.55,687.16z
		 M243.42,678.37c-1.3-0.8-2.87-1.76-4.8-2.95c2.91-2.14,5.58-4.1,7.32-5.38c1.86,1.26,2.98,2.01,4.1,2.77
		C247.89,674.61,245.75,676.41,243.42,678.37z M233.3,672.13c-6-3.29-11.9-6.52-18.15-9.94c9.53-1.18,18.5-1.7,26.45,4.35
		C238.73,668.47,236.04,670.29,233.3,672.13z M243.33,661.12c-1.74-0.91-3.48-1.82-5.27-2.76c7.57-7.63,8.83-15.97-2.48-25.44
		c-5.15-4.31-11.49-5.42-18.26-4.24c0-1.66,0-3.31,0-5.35c11.63-0.11,22.05,2.47,29.36,12.23c1.68,2.24,2.64,5.07,2.97,8.06
		c0.13,1.19,0.16,2.4,0.09,3.61c0,0.07-0.01,0.14-0.02,0.21c-0.19,2.99-0.96,5.96-2.25,8.51c-0.07,0.14-0.15,0.26-0.23,0.4
		c-0.23,0.43-0.47,0.86-0.74,1.26C245.61,658.99,244.55,660.19,243.33,661.12z M235.03,657.12c-6.02-3.34-11.53-6.39-16.9-9.36
		c-0.35-4.88-0.68-9.46-1.03-14.46c5.78,0.63,11.6-1.75,16.4,2.25c2.66,2.22,4.85,5.06,6.88,7.9c0.6,0.84,0.28,2.76-0.25,3.9
		C238.64,650.59,236.84,653.7,235.03,657.12z M244.05,566.3c0.8,0.22,1.57,0.43,2.79,0.77c0,8.51,0,17.16,0,25.8
		c-0.08,0.02-0.17,0.04-0.25,0.05C245.75,584.12,244.91,575.32,244.05,566.3z M244.18,563.8c-0.44-2.13-0.88-4.28-1.32-6.42
		c-0.45-0.02-0.9-0.04-1.34-0.05c-0.38,2.32-0.77,4.65-1.15,6.97c-0.28,0-0.57,0-0.85,0c-0.4-2.49-0.79-4.98-1.35-8.51
		c-0.84,2.45-1.38,4.04-1.93,5.65c-3.08-4.96,1.07-8.09,2.85-11.96c5.44,0.08,7.66,1.42,8.19,4.75
		C248.21,560.05,247.36,562.75,244.18,563.8z M236.48,529.82c-1.47,0.3-3.01,0.62-4.69,0.97c-0.6-3.18-1.19-6.35-1.81-9.65
		C235.57,522.53,236.65,523.98,236.48,529.82z M236.6,532.48c-0.79,2.67-1.51,5.1-2.23,7.54c-0.38-0.05-0.75-0.09-1.13-0.14
		c-0.11-2.02-0.22-4.04-0.34-6.21C234.13,533.27,235.16,532.94,236.6,532.48z M239.37,531.24c1.07-5.91-2.67-11.59-8.52-12.43
		c-1.28-0.18-2.69,0.55-4.18,0.89c0-1.48,0-2.89,0-4.07c1.85,0.08,3.57,0.16,5.15,0.23c2.79-3.87,2.91-4.68,1.49-5.97
		c-1.73,1.69-3.44,3.36-5.15,5.03c-0.39-0.22-0.77-0.45-1.16-0.67c0-2.51,0-5.02,0-8.01c5.82-3.33,2.36-10.46,4.87-15.5
		c3.56,0.12,3.55,3,3.84,4.9c1.42,9.13,2.29,18.35,3.79,27.46c1.32,8,0.88,15.71-2.23,23.2c-1.77,4.29-4.26,7.87-9.78,8.22
		c0-2.16,0-4.22,0-6.74C236.22,545.93,238.01,538.76,239.37,531.24z M228.3,558.37c1.32-0.24,2.84-0.51,4.13-0.74
		c0.74,2.7,1.46,5.35,2.18,7.96c-1.95,1.01-3.98,2.06-6.31,3.27C228.3,565.4,228.3,561.99,228.3,558.37z M228.81,571.42
		c4.05-1.35,8.23-2.74,12.47-4.15c0.39,2.14,0.7,3.82,1.07,5.83c-5.36-2.27-8.9,2.34-13.54,2.8
		C228.81,574.55,228.81,573.27,228.81,571.42z M241.26,546.53c0.34-7.14,1.4-14.27,0.86-21.27c-0.81-10.61-2.61-21.15-4.24-31.69
		c-0.6-3.86-2.76-5.07-7.57-4.67c-0.89,4.7-1.82,9.59-2.74,14.48c-0.35-0.04-0.7-0.09-1.04-0.13c0-4.42-0.07-8.84,0.06-13.25
		c0.02-0.8,0.64-1.83,1.31-2.31c4.33-3.13,8.74-6.14,13.78-9.65c1.81,23.48,3.57,46.24,5.34,69.13
		C244.17,546.86,242.33,546.65,241.26,546.53z M228.36,521.59c0.56,3.37,1.1,6.67,1.69,10.23c-0.84,0.43-1.81,0.93-2.79,1.44
		c-0.04,0.28-0.07,0.56-0.11,0.83c1.08,0.25,2.15,0.49,3.83,0.87c0,2.31,0,4.76,0,7.67c-0.86,0.37-2.1,0.91-3.31,1.44
		C225.64,540.43,226.16,523.23,228.36,521.59z M234.19,592.56c-2.24,0.43-4.37,1.39-6.71,2.17c1.88-5.46,2.76-6.11,8.12-7.3
		c1.47-0.33,2.76-1.41,4.62-2.4c-4.47-3.63-7.26-0.02-10.85,0.58c-0.29-2.16-0.61-4.49-0.86-6.31c4.85-1.42,9.3-2.72,13.42-3.93
		c0.51,6.56,1.05,13.44,1.64,21.03c-4.13,1.68-8.83,3.41-13.36,5.51c-4.53,2.1-8.88,4.58-13.46,6.97c-0.35-1.7-0.71-3.46-1.07-5.18
		c7.04-6.52,17.23-5.17,24.71-10.59C238.43,589.26,236.11,592.19,234.19,592.56z M209.05,662.61c6.11,3.87,12.52,7.92,19.35,12.24
		c-6.39,4.64-12.52,9.09-19.35,14.05C209.05,679.55,209.05,671.05,209.05,662.61z M415.2,790.52c0.92,9.51-4.65,17.86-15.74,24.09
		c-0.23-2.49-0.46-4.88-0.71-7.52C404.68,802.37,411.83,798.39,415.2,790.52z M416.94,778.83c9.45,3.39,16.77,9.23,25.34,14.43
		c-7.93,7.52-17.93,10.43-25.71,16.84c-0.53-0.37-1.06-0.75-1.58-1.12C422.58,799.48,419.94,789.45,416.94,778.83z M419.2,777.45
		c-1.09-0.57-2.78-0.86-3.19-1.74c-3.64-7.9-10.92-12.26-16.98-17.81c-1.47-1.35-3.08-2.56-4.7-3.9c4.76-2.34,9.5-4.67,14.24-6.99
		s9.48-4.65,14.21-6.98l0,0c4.79-3.1,9.58-6.19,14.37-9.29c0.46-0.23,0.93-0.45,1.39-0.68c0.72-0.5,1.44-1,2.37-1.66
		c5.99,4.15,11.94,8.27,17.89,12.4c9.09,5.71,18.18,11.42,27.27,17.13c2.06,1.3,4.14,2.57,5.63,3.49
		c-15.14,9.9-30.36,19.85-45.89,29.99c-0.32-0.19-0.77-0.46-1.3-0.77c-0.52-0.31-1.11-0.66-1.7-1.01
		C434.95,785.56,427.08,781.5,419.2,777.45z M485.15,751.46c-13.14-8.29-26.28-16.58-40.11-25.3c14.71-8.5,28.72-16.62,42.77-24.67
		c1.9-1.09,3.98-1.91,6.06-2.59c0.87-0.28,2.19-0.21,2.92,0.29c12.22,8.27,24.39,16.64,36.55,25c0.13,0.09,0.22,0.23,0.31,0.38
		c0.08,0.15,0.16,0.32,0.25,0.47c1.51,0.87,3.02,1.74,4.64,2.67c1.62,0.93,3.34,1.92,5.26,3.02c-16.12,9.47-31.64,18.59-47.88,28.14
		C492.63,756.6,488.89,754.03,485.15,751.46z M495.33,691.69c-9.87-5.67-19.4-11.15-28.92-16.62c0.17-0.35,0.34-0.69,0.51-1.04
		c2.06,0.8,4.12,1.61,6.88,2.69c-0.27-1.76-0.47-3.03-0.67-4.29h0l0,0c-0.44-0.08-0.89-0.16-1.33-0.24
		c0.27-0.31,0.55-0.63,0.82-0.94c0.43,0.2,0.85,0.39,1.28,0.59c0.99,0.23,1.97,0.46,3.36,0.78c-0.08-1.44-0.15-2.66-0.23-4.02
		c1.44,0.15,3.18,0.33,5.55,0.58c-1.69-6.53-8.01-5.78-11.22-9.9c1.99,0.31,4.06,0.36,5.94,1c2.62,0.88,5.07,2.29,7.66,3.29
		c0.82,0.32,1.86,0.05,2.88-0.36c2.28-0.01,3.8,3.24,7.07,0.34c-4.26-2.39-8.53-4.77-12.79-7.16c0-0.17,0.01-0.35,0.01-0.52
		c1.72,0.48,3.58,0.69,5.14,1.48c4.41,2.26,8.69,4.76,13.39,6.65c-2.1-5.93-8.23-6.9-12.7-10.21c0.37-0.84,0.72-1.64,1.08-2.46
		c5.25,3.66,10.45,7.28,15.65,10.9c-0.12-4.16-3.44-5.14-5.82-6.91c-2.5-1.85-5.26-3.35-8.09-5.12c3.61-1.82,6.7-2.65,10.11,0.23
		c2.64,2.22,5.83,3.81,8.84,5.56c1.05,0.61,2.3,0.89,3.46,1.32c0.23-0.3,0.45-0.6,0.68-0.9c-1.63-1.58-3.26-3.16-4.89-4.73
		c-2.77-1.47-5.54-2.94-8.3-4.41c0.15-0.3,0.29-0.61,0.44-0.91c3.42,0.33,5.95,2.58,8.49,4.82c2.41,1.04,4.83,2.07,7.31,3.14
		c0.38-3.3-4.91-6.99-14.5-10.12c1.64-0.96,2.85-1.67,4.03-2.37c4.91,3.28,9.28,7.82,14.51,9.3c7.82,2.21,12.83,8.32,19.74,11.54
		c0.93,0.43,1.73,1.13,3.5,2.31C527.36,674.19,511.14,683.05,495.33,691.69z M520.14,644.68c-0.54-0.05-1.08-0.09-1.62-0.14
		c0,0,0,0,0,0c-2.11-0.91-4.21-1.82-6.79-2.94c-0.37-0.16-0.75-0.32-1.13-0.49c4.03-1.48,6.1,0.95,8.31,2.94
		c0.42,0.04,0.85,0.08,1.27,0.12C520.16,644.34,520.15,644.51,520.14,644.68z M500.26,640.75c-2.73,1.74-5.32,3.3-8.42,4.02
		c-3.24,0.75-5.74,2.36-7.46,5.32c-0.51,0.87-1.77,1.34-2.75,1.88c-8.99,4.95-16.52,0.38-24.1-3.74c-5.01-2.72-9.99-5.47-15.21-8.34
		c3.08-2,6.28-4.07,9.79-6.35c4.26,3.49,9.65,4.41,15.29,4.87c6.99,0.56,9.59-2.72,8.15-10.82c-7.89-4.4-15.57-0.98-23.41,0.77
		c-5.07-3.99-10.19-8.01-16.06-12.63c2.33-0.2,3.68-0.31,5.03-0.43c0.08-0.17,0.15-0.34,0.23-0.51c-0.93-0.37-1.86-0.75-2.62-1.05
		c4.33-3.2,8.87-6.55,14.06-10.39c17.77,14.52,41.22,19.8,62.16,31.68C509.13,637.19,504.22,638.23,500.26,640.75z M454.85,630.57
		c6.27-1.36,12.04-3.03,18.17-0.61c-0.43,1.33-0.81,2.49-1.26,3.9C465.94,634.38,460.36,634.27,454.85,630.57z M433.37,613.81
		c-3.08-2.17-6.9-4.97-10.82-7.61c-4.74-3.18-9.61-6.17-14.34-9.37c-1.12-0.76-1.88-2.03-2.94-3.22c-0.05,0.06-0.51,0.58-0.94,1.07
		c-4.53-2.29-9-4.54-13.64-6.87c2.54-6.01,9.11-6.89,14.1-10.87c6.92,4.04,13.93,8.1,20.92,12.21c7.04,4.14,14.05,8.31,21.27,12.59
		C442.77,605.48,438.33,609.42,433.37,613.81z M403.52,566.5c0.05-0.73,0.08-1.3,0.12-1.87c-0.38-2.5-0.77-4.99-1.15-7.49
		c0.38-0.22,0.76-0.43,1.14-0.65c1.13,1.28,2.25,2.57,3.38,3.85c0.95,2.21,1.89,4.42,3.31,7.74
		C407.7,567.47,405.69,567,403.52,566.5z M410.81,441.64c5.39-0.85,11.25-1.77,17.28-2.72c4.96,8.13,3.05,18.36-6.55,20.67
		c-4.96,1.2-9.57-0.67-12.25-6.4c-1.77-3.77-2.56-8-3.2-12.57c5.9,8.49,13.14,7.27,21.29,4.17
		C421.63,441.84,414.92,445.69,410.81,441.64z M427.23,476.18c-1.2-2.11-2.41-4.22-3.72-6.52c0.62-0.57,1.27-1.17,2.34-2.16
		c0.78,3.15,1.46,5.9,2.14,8.64C427.74,476.15,427.48,476.17,427.23,476.18z M434.01,501.88c0.11,0.49,0.22,0.98,0.33,1.48
		c-0.16,0.04-0.31,0.09-0.47,0.13c-0.1-0.41-0.2-0.82-0.3-1.23c-3.58-7.44-7.14-14.89-10.73-22.33c-1.46-3.02-2.99-6.01-4.51-9.06
		c0.77,0,1.74,0,2.54,0C426.91,480.55,433.3,490.04,434.01,501.88z M417.7,465.6c-3.79,1.07-5.83-0.31-7.93-5.24
		C412.65,462.26,415,463.81,417.7,465.6z M446.2,305.96c0.18,4.24-2.89,6.76-8.37,6.28C440.53,310.21,443.23,308.19,446.2,305.96z
		 M441.45,318.48c0,4.93,0,9.13,0,13.41c-1.51,0.93-2.96,1.83-4.72,2.91c-0.32-4.53-0.61-8.55-0.91-12.78
		C437.35,321.06,438.97,320.04,441.45,318.48z M435.35,485.71c-0.28,0.04-0.56,0.09-0.84,0.13c-2.62-8.21-5.25-16.41-7.79-24.36
		c6.54-5.11,9.05-12.32,4.36-23.8c1.61-1.86,3.03-3.48,4.28-4.92C435.35,450.2,435.35,467.95,435.35,485.71z M431.47,416.27
		C431.47,416.27,431.47,416.27,431.47,416.27L431.47,416.27c-0.22,0.29-0.43,0.58-0.65,0.87c-1.63,2.75-3.65,4.91-6.24,6.21
		c-1.55,0.78-3.3,1.26-5.29,1.38c-2.47,0-4.94,0-8.14,0c1.3,1.52,2.2,2.57,3.11,3.63c5.74,2.78,10.62,0.32,15.42-2.56
		c0.34-0.34,0.69-0.67,1.03-1.01c1.01-1.12,2.02-2.25,3.04-3.39c3.54,4.45,0.59,10.69-5.87,12.79c0,0,0,0,0,0
		c-0.25,0.19-0.51,0.38-0.76,0.57c-4.66,2.37-9.49,1.27-14.29,0.73c-1.44-0.45-2.88-0.89-4.32-1.34c-0.51-0.2-1.01-0.41-1.52-0.61
		c0,0.01,0.01,0.03,0.01,0.04c0,0,0,0,0,0l0,0c-1.79-0.82-3.44-1.79-4.97-2.9c-4.57-3.31-8.01-7.78-10.74-12.77
		c-3.25-5.96-5.62-12.4-8.37-18.62c0.36-0.32,0.73-0.63,1.09-0.95c0.65,0.49,1.48,0.86,1.92,1.5c2.45,3.55,4.52,7.41,7.3,10.68
		c1.99,2.34,4.87,3.93,7.36,5.85c2.12,0.54,4.22,1.33,6.38,1.55c1.87,0.19,3.81-0.25,5.8-1.1c-6.93-1.68-13.47-3.45-17.38-11.06
		c5.74,2.54,10.22,6.63,17.16,6.1c-8.17-4.88-15.55-9.67-20.77-17.12c1.26-0.82,2.23-1.45,2.82-1.83
		c5.81,2.17,11.31,4.23,16.81,6.28c1.27-0.5,2.69-0.78,3.8-1.52c5.4-3.62,8.24-6.21,4.82-14.18c-0.1-0.24-0.19-0.48-0.27-0.73
		c-0.24-0.75-0.39-1.53-0.6-2.29c-0.95-1.14-1.89-2.28-2.84-3.43c0.41-0.8,0.77-1.49,1.49-2.9c2.3,2.5,4.38,4.76,6.46,7.02
		c0,0,0,0,0,0c0.19,0.25,0.37,0.5,0.56,0.75c3.18,7.27,3.32,14.6,0.61,22.14c2.14-1.37,3.29-3.34,4.04-5.54
		c0.25-0.74,0.46-1.5,0.64-2.28c0.27-1.36,0.54-2.73,0.82-4.16c0.28-1.43,0.58-2.93,0.9-4.57c2.66,9.16,4.55,17.83,0.42,26.66
		c-0.1,0.46-0.2,0.91-0.3,1.37C431.82,415.77,431.64,416.02,431.47,416.27L431.47,416.27C431.47,416.27,431.47,416.27,431.47,416.27
		z M378.38,395.21c-5.48-5.59-4.02-12.79-5.59-19.16c4.49,6.32,10.73,10.16,17.45,13.61C386.51,391.41,382.55,393.26,378.38,395.21z
		 M412.2,330.58c7.03-5,13.71-9.74,20.38-14.48c0.2,0.21,0.4,0.41,0.6,0.62c-0.83,0.83-1.64,1.69-2.51,2.48
		c-1,0.9-2.06,1.73-3.61,3.02c1.74,1.25,2.97,2.13,4.35,3.12c0.2,3.39,0.42,7.04,0.64,10.68c2.68,10.48,1.31,21.15,1.5,31.75
		c0.09,4.76-0.45,9.54-0.71,14.28c-3.52-3.22-6.92-6.37-10.38-9.47c-0.59-0.53-1.36-1.15-2.09-1.19c-4.11-0.25-4.28-2.91-3.9-6
		c-0.51-2.49-1.14-4.97-1.5-7.49c-0.99-6.95-1.94-13.9-2.75-20.87C411.97,334.69,412.2,332.29,412.2,330.58z M413.2,378.29
		c1.1,1.24,2.14,2.41,3.18,3.58l0,0c0.11,0.13,0.21,0.26,0.32,0.39c0.05,0.07,0.11,0.13,0.16,0.2c0.22,0.29,0.44,0.59,0.64,0.88
		c0.62,0.92,1.11,1.87,1.41,2.87c0.11,0.39,0.2,0.78,0.25,1.18c0.16,1.2,0.02,2.47-0.52,3.84c-2.23,4.71-7.36,6.39-11.6,3.9
		c-2.75-1.62-5.59-3.08-8.65-4.76C403.35,386.54,410.45,385.3,413.2,378.29z M408.99,378.29c-2.64,1.71-5.46,3.54-8.28,5.37
		c3.72-8.86,6.56-17.55,6.42-27.04C409.09,358.94,410.09,370.43,408.99,378.29z M409.05,331.57c0,2,0,3.73,0,6.73
		c-1.61-2.21-2.64-3.64-3.89-5.35C406.71,332.41,407.76,332.03,409.05,331.57z M398.74,343.13c4.04,1.04,5.92,3.87,6.14,7.56
		c0.58,9.88-1.06,19.4-5.68,28.26c-1.68,3.23-3.82,6.05-7.84,6.51c-4.18,0.48-6.39-2.99-9.44-4.79c-0.28-0.16-0.45-0.51-0.84-0.96
		c5.21-3.95,5.79-8.86,3.07-14.55c-1.56-3.26-4.01-4.41-7.87-3.97c-0.89,4.26-0.11,8.36,1,12.42c0.16,0.58,0.32,1.16,0.49,1.74
		c0.5,0.79,1,1.59,1.5,2.38c-0.2,0.12-0.41,0.25-0.61,0.37c-0.47-0.73-0.94-1.47-1.41-2.2c0,0,0,0,0,0
		c-3.14-4.06-5.12-8.41-5.13-13.23c0-1.61,0.21-3.26,0.68-4.98c1.13-4.18,4.06-5.04,7.42-2.35c1.92,1.53,3.75,3.42,5.96,4.29
		c1.61,0.63,3.77-0.17,5.93-0.35c0,1.85-0.57,4.62,0.16,6.98c0.74,2.39,2.77,4.39,4.09,6.35c5.19-1.55,6.11-5.3,6.31-9.03
		c0.21-3.98-2.33-6.95-6.09-7.96c-0.81-0.22-1.77,0.15-2.67,0.25l0,0v0c-0.28,0.26-0.57,0.53-0.85,0.79
		c-0.12-0.09-0.23-0.18-0.35-0.27c0.23-0.37,0.46-0.73,0.7-1.1c1.33-3.33,2.64-6.68,4.01-9.99
		C397.73,344.57,398.25,343.93,398.74,343.13z M377.75,363.77c6.07,1.53,7.86,7.16,4.19,12.11
		C380.53,371.8,379.27,368.15,377.75,363.77z M395.87,357.73c1.26,0.91,3.3,1.6,3.62,2.76c0.71,2.59,1.46,5.6-1.45,7.9
		C394.7,365.8,396.08,361.9,395.87,357.73z M388.65,357.33c-3.18-2.29-5.73-4.12-8.65-6.22c4.87-2.31,9.28-4.41,14.35-6.82
		C392.34,348.88,390.62,352.82,388.65,357.33z M371.2,349.56c0.56-0.38,1.13-0.75,1.69-1.13c0,0,0,0,0,0
		c3.9-2.61,7.99-4.8,12.16-6.83c1.39-0.68,2.79-1.33,4.19-1.98c0.21,0.43,0.42,0.87,0.63,1.3c-5.47,2.68-10.94,5.36-16.4,8.04
		c-0.56,0.37-1.13,0.75-1.69,1.12c0,0,0,0,0,0c-0.97,0.9-1.94,1.8-3,2.77c-0.35,0.32-0.71,0.66-1.08,1
		c-0.46-0.22-1.17-0.54-2.2-1.02C367.53,351.67,369.37,350.61,371.2,349.56z M370.4,375.19c-0.41,0.04-0.81,0.07-1.22,0.11
		c-0.15-6.13-3.01-11.96-2.55-18.15c0.32-0.04,0.64-0.07,0.96-0.11C368.54,363.09,369.47,369.14,370.4,375.19z M366.26,401.2
		c2.39,1.86,4.83,1.08,7.35,0.26c2.31-0.75,4.68-1.28,7.2-1.95c1.39,10.18,5.02,19.02,10.31,26.52c0.35,7.19,0.51,13.93,1.06,20.65
		c0.41,5.05,1.49,10.04,1.95,15.09c0.46,5.08,0.82,10.21,0.65,15.3c-0.09,2.61-1.42,5.18-2.02,7.2c-2.51-1.84-4.57-3.36-6.98-5.13
		c-0.48-9.1-0.99-18.81-1.49-28.28c-5.3-3.72-15.01-2.78-16.64,1.92c2.37,0,4.57,0.1,6.76-0.03c2.06-0.12,4.11-0.48,6.84-0.81
		c0.77,10.19,1.5,19.82,2.26,29.81c-1.8,0.79-3.59,1.58-5.63,2.48c-0.13-2.1-0.23-3.84-0.36-5.96c-1.47-0.21-2.83-0.4-5.01-0.71
		c-2.55-11.7-5.17-23.73-7.9-36.23c-8.17,4.43-17.38,1.35-26.43,2.23c0-1.26,0-2.28,0-3.24c-3.86-2.94-5.3-2.45-7.65,1.25
		c-5.03,7.93-10.32,15.71-15.63,23.46c-3.2,4.66-6.77,9.07-9.92,13.76c-0.85,1.26-1.12,3.19-1,4.76c0.37,5.18,0.96,10.34,1.61,15.5
		c0.25,1.98,0.55,3.95,0.87,5.93c0.19,1.17,0.38,2.35,0.57,3.52c0.13,0.8,0.26,1.59,0.38,2.39c0.5,1.16,1,2.33,1.5,3.49
		c0.96-0.72,1.93-1.43,2.89-2.15c0.92-0.51,1.85-1.01,2.77-1.52c0.89-0.79,1.72-1.68,2.61-2.47c0.54-0.48,1.11-0.92,1.73-1.27
		c11.64-6.61,23.36-13.08,35.56-19.88c0.22,0.41,0.44,0.8,0.64,1.17c0.61,1.12,1.12,2.07,1.64,3.03c0.27,0.92,0.54,1.85,0.79,2.69
		c0.08,0.28,0.16,0.55,0.24,0.81c-22,13.59-43.75,27.03-65.83,40.67c-0.35-3.76-0.76-6.92-0.91-10.1
		c-0.54-11.39-0.97-22.79-1.47-34.18c-0.46-10.43-0.93-20.86-1.44-31.29c-0.06-1.23-0.39-2.45-0.68-4.16
		c9.62-4.23,5.33-13.67,6.75-21.52c9.46,0.9,13.35-6.8,18.6-12.2c7.85,3.22,11.71,2.02,17.25-4.83c0.75-0.92,2.61-1.84,3.58-1.54
		c7.53,2.35,12-2.24,16.13-7.08c0.62-0.73,1.28-1.36,1.98-1.9c2.09-1.63,4.48-2.52,7.01-3.33c0.34-0.25,0.68-0.49,1.03-0.74
		c-0.01,0-0.01,0-0.02,0c0.05-0.06,0.11-0.12,0.16-0.18c0.83-0.94,1.66-1.88,2.51-2.85c0.9-1.03,1.83-2.08,2.81-3.19
		C365.58,398.04,365.92,399.62,366.26,401.2z M292.87,425.51c0.32,0.2,0.63,0.4,0.95,0.6c4.28-3.6,8.45-7.34,12.87-10.75
		c2.18-1.68,4.83-2.76,7.36-4.17c1.72,3.24,2.93,5.53,4.14,7.81c-0.15,0.26-0.3,0.52-0.46,0.77c-1.63-1.25-2.99-1.56-4.2-1.34
		c-0.4,0.07-0.79,0.2-1.16,0.38c-0.19,0.09-0.37,0.19-0.55,0.29c-1.26,0.75-2.4,1.93-3.6,2.89c-1.62,2.33-3.54,4.2-5.76,5.59
		c-2.21,1.4-4.72,2.33-7.52,2.79c-0.32,0.05-0.71-0.34-1.06-0.53C293.55,428.4,293.21,426.95,292.87,425.51z M351.39,400.89
		c7.13-2.04,7.7-9.16,11.53-14.81c0.75,2.52,1.28,4.32,1.8,6.04C361.11,396.36,358.38,401.62,351.39,400.89z M360.32,364.25
		c0.46,4.8,0.8,8.41,1.17,12.33c-1.61,0-2.62,0-3.04,0c-1.22-2.69-2.32-5.11-3.81-8.38C355.76,367.41,357.68,366.08,360.32,364.25z
		 M353.88,372.05c0.47,3.15,0.94,6.3,1.43,9.63c2.58-0.7,5.06-2.65,7.63,0.43c-1.19,0.76-2.28,1.45-3.55,2.25c-2.26,0-5.09,0-8.07,0
		c0.5-2.82,2.43-5.9-0.72-8.49c0.72-1.34,1.41-2.63,2.11-3.93C353.09,371.98,353.49,372.01,353.88,372.05z M346.38,372.92
		c1.44-2.55,3.34-4.84,5.33-7.02c0.49,6.53-5.24,14.85-9.36,15.55C343.53,378.91,344.75,375.8,346.38,372.92z M329.33,378.22
		c0.28,0.1,0.55,0.2,0.83,0.3c0.65,2.64,1.29,5.27,1.94,7.91c-0.26,0.03-0.51,0.06-0.77,0.09c-1.15-0.97-2.31-1.94-4.09-3.44
		C327.83,381.71,328.58,379.96,329.33,378.22z M317.11,385.04c-0.59,3.51-1.26,6.91-1.72,10.34c-0.5,3.76-2.83,5.25-7.27,5.05
		c1.15-2.66,2.51-5.56,3.67-8.54C312.91,389.02,313.73,386,317.11,385.04z M308.96,393.97c-1.23,0.74-1.53,0.92-1.83,1.1
		c-0.09-0.2-0.19-0.41-0.28-0.61C307.22,394.37,307.58,394.29,308.96,393.97z M306.08,395.08c0.09,0.36,0.17,0.72,0.26,1.07
		c-3.05,2.62-6.87,2.15-10.57,2.18c-0.07-0.32-0.14-0.63-0.22-0.95C299.06,396.62,302.57,395.85,306.08,395.08z M300.2,402.29
		c1.82,0.31,3.56,1.02,5.34,1.55c6.34,1.89,12.42-4.23,12.13-9.35c-0.18-3.14,1.08-6.36,1.7-9.61c1.48,0,2.51,0,3.52,0
		c0.12,0.26,0.31,0.48,0.27,0.65c-0.21,1.03-0.29,2.13-0.74,3.05c-1.41,2.85-0.82,5.72,1.93,6.45c1.71,0.45,4.43-1.31,6.13-2.75
		c4.65-3.94,3.8-8.82,1.47-13.62c3.17-2.29,4.85-1.84,6.23,1.38c0.74,1.72,1.67,3.35,2.81,5.62c3.1-2.18,5.45-3.83,8.5-5.98
		c-1.05,3.77-1.94,6.98-2.99,10.72c4.11-1.51,7.29-4.45,12.69-2.86c-2.32,2.98-3.77,5.98-6.15,7.56c-2.45,1.62-5.82,1.83-8.93,2.71
		c0.92,3.19,1.45,6.4-1.5,8.71c-3.57,2.79-7.37,3.2-10.93,0.15c1.44-1.73,3.02-3.6,4.59-5.48c-0.12-0.3-0.24-0.59-0.36-0.89
		c-0.77,0.09-2.02-0.08-2.24,0.31c-2.59,4.65-6.32,5.02-10.97,2.95c-0.39,4.46,3.05,3.71,5.49,4.39c-0.89,3.74-3.03,6.16-7.44,6.97
		c-1.58-2.1-3.3-4.37-4.86-6.43c2.44-3.02,5.03-6.25,7.63-9.47c-0.38-0.4-0.76-0.8-1.14-1.19c-0.9,0.66-2.01,1.15-2.67,2
		c-3.06,3.91-6.56,6.66-12.56,5.87c1.09,1.05,2.18,2.1,2.88,2.78c-3.41,2.66-7.05,5.49-10.69,8.32c-0.28,0.26-0.56,0.53-0.84,0.79
		c-0.12-0.1-0.24-0.19-0.37-0.29c0.23-0.36,0.47-0.73,0.7-1.09c1.2-2.62,2.41-5.24,3.7-8.04c-3.46-1.44-6.9-2.87-11.09-4.6
		C294.77,401.12,297.55,401.84,300.2,402.29z M289.65,404.82c2.22,1.78,4.79,3.84,7.55,6.06c-0.8,1.8-1.86,4.2-2.93,6.6
		c-0.11-0.03-0.23-0.06-0.34-0.1c0.24-1.52,0.48-3.05,0.7-4.43C289.03,409.44,288.76,408.9,289.65,404.82z M273.06,415.5
		c0.19-0.15,0.37-0.29,0.56-0.44c-0.02,0.28-0.04,0.56-0.05,0.84c0,0,0,0,0,0c-1.2,2.79-2.4,5.58-3.71,8.63
		c-0.44,1.02-0.89,2.07-1.36,3.16c-1.51-1.17-2.54-1.99-3.59-2.78c-1.13-0.85-2.28-1.66-3.97-2.89
		C265.11,419.77,269.08,417.63,273.06,415.5z M252.88,426.67c3.44-1.02,6.47-0.37,9.24,1.93c5.97,4.97,10.6,3.25,12.42-4.56
		c1.36-5.85,3.87-9.21,8-10.65c3.58,1.84,3.68,5.62,4.06,8.78c1.13,9.21-1.1,18.05-4.76,26.39c-2.13,4.85-6.8,7.49-11.9,8.44
		c-5.32,0.99-8.9-2.1-11.59-6.39c-0.3-0.47-0.74-0.85-0.73-0.84c2.59-0.33,5.27-0.79,7.96-0.97c2.5-0.17,3.03-1.75,2.3-3.52
		c-1.15-2.77-2.68-5.39-4.23-7.97c-1.8-3.01-4.72-2.61-8.28-2.17c-0.41,2.57-0.84,5.26-1.28,8.03
		C251.29,437.89,250.83,432.46,252.88,426.67z M259.08,436.4c1.29,3.55,2.52,6.92,3.78,10.4c-2.67,0.92-3.97-0.86-4.78-2.79
		C256.99,441.43,256.33,438.67,259.08,436.4z M227.37,439.66c1.83-1.15,3.6-2.4,5.34-3.69c1.74-1.29,3.47-2.6,5.22-3.88
		c0.19-0.12,0.38-0.24,0.58-0.37c0.6-0.38,1.27-0.81,2.23-1.43c0.15,14.35,0.25,28.23,3.74,41.54c2.06,0.25,3.23,0.39,4.41,0.53
		c0.4-0.05,0.8-0.09,1.2-0.14c0.26,1.19,0.66,2.36,0.75,3.56c0.98,12.5,1.93,25.01,2.84,37.52c0.54,7.33,1.23,14.66,1.42,22
		c0.25,9.25-0.03,18.5,0.09,27.76c0.11,8.81,0.61,17.61,0.64,26.42c0.02,7.01-0.64,14.03-0.61,21.04
		c0.05,10.83,0.47,21.65,0.65,32.48c0.1,5.76,0.02,11.52,0.02,17.34c-2.34,1.39-4.54,2.7-7.47,4.45c0.4-1.54,0.46-2.33,0.79-2.98
		c4.59-9.08,5.71-18.19,0.37-27.4c-0.08-0.14-0.11-0.32-0.12-0.5s-0.01-0.38-0.03-0.55c-2.2-1.98-4.33-4.06-6.57-6
		c-0.74-0.65-1.5-1.28-2.27-1.89c-5.63-4.45-12.42-5.51-19.45-5.81c5.15-5.4,11.44-8.47,18.13-11.03c2.67-1.02,4.95-3.03,7.5-4.42
		c0.6-0.33,1.47-0.17,2.27-0.14c0.11,0,0.23,0,0.34,0c0.3-0.71,0.78-1.43,1.09-2.14c0.19-0.43,0.31-0.85,0.3-1.28
		c-0.36-13.03-0.89-26.05-1.29-39.08c-0.05-1.5,0.58-3,0.67-4.52c0.06-1.02,0.06-2.05,0.04-3.08s-0.04-2.06-0.05-3.08
		c-0.24-2.01-0.52-4.03-0.78-6.04s-0.48-4.03-0.59-6.05c-0.24-4.4,0.17-8.84-0.1-13.24c-0.53-8.48-1.36-16.95-2.05-25.42
		c-0.54-6.7-1.06-13.41-1.58-20.12c-0.46-1.44-0.93-2.88-1.46-4.53c-0.18-0.55-0.36-1.12-0.56-1.73c-0.51,0.37-0.94,0.67-1.3,0.94
		c-0.24,0.18-0.46,0.33-0.66,0.48c-0.3,0.22-0.57,0.41-0.84,0.61c-1.81,1.09-3.63,2.18-5.46,3.28c-1.55,0.93-3.11,1.87-4.71,2.83
		c-1.16,0.7-2.34,1.4-3.53,2.12c0-9.2,0.13-18.3-0.06-27.4c-0.08-3.85-0.81-7.69-1.29-11.52
		C224.89,442.85,225.1,441.08,227.37,439.66z M204.46,511.27c0.14-3.98,1.14-7.93,1.22-11.9c0.19-9.33,0.14-18.67,0.03-28.01
		c-0.05-4.49-0.51-8.98-0.64-13.47c-0.03-0.95,0.26-2.37,0.91-2.81c4.99-3.4,10.11-6.62,15.52-10.11c0,1.51,0,2.67,0,3.82
		c0,22.8-0.15,45.6,0.07,68.4c0.11,11.75,0.85,23.5,1.39,35.24c0.21,4.61,0.66,9.21,0.97,13.34c-2.12-3.45-4.81-6.88-6.43-10.77
		c-1.27-3.04-1.35-6.62-1.69-9.98c-0.4-3.99-0.48-8.01-0.79-12.01c-0.1-1.27-0.12-2.81-0.83-3.72c-3.56-4.54-2.51-9.1-0.6-13.83
		c1.47-3.64-0.02-5.31-5.78-5.23c0.49,2.93,0.98,5.59,1.37,8.27c0.15,1.05-0.11,2.19,0.17,3.2c0.64,2.31,1.49,4.56,2.27,6.83
		c0.66,1.93,1.73,3.81,1.92,5.78c0.67,7.05,0.99,14.13,1.56,21.18c0.08,0.97,0.63,2.04,1.28,2.8c5.69,6.65,6.88,14.83,7.98,23.05
		c0.2,1.5,0.4,3,0.63,4.5c0.39,2.51,0,4.82-2.17,6.36c-3.97,2.84-8.04,5.54-12.62,8.68c-0.34-5.17-0.61-9.46-0.89-13.75
		c-0.79-12.02-1.8-24.04-2.26-36.08c-0.15-3.95,1.38-7.95,1.61-11.95c0.16-2.77,0.25-6.07-1.05-8.3
		C203.99,524.58,204.22,517.95,204.46,511.27z M90.66,525.2c8.5-4.93,17.15-9.61,25.73-14.41c1.92-1.08,3.76-2.3,6-3.68
		c1.28,6.56,2.47,12.72,3.55,18.25c-12.46,6.96-24.67,13.78-36.98,20.66c0-1.1,0.07-2.92-0.01-4.74c-0.19-4.12-0.66-8.24-0.58-12.36
		C88.39,527.64,89.52,525.86,90.66,525.2z M127.59,538.02c0,4.33,0,8.66,0,12.99c-0.24,0.03-0.48,0.07-0.72,0.1
		c-0.93-4.66-1.82-9.33-2.82-13.98c-0.36-1.65-1.01-3.23-1.46-4.87c-0.02-0.07,0.1-0.18,0.26-0.3c0.16-0.12,0.35-0.25,0.49-0.36
		c0.24-0.19,0.48-0.38,0.73-0.57c0.73-0.58,1.5-1.18,2.39-1.89C128.64,532.04,127.27,535.15,127.59,538.02z M128.06,567.36
		c-4.9,2.93-9.75,5.83-14.57,8.71c-4.82,2.88-9.62,5.75-14.42,8.62c-0.27,0.31-0.54,0.61-0.87,0.99c-0.34,0.38-0.74,0.84-1.27,1.44
		c-0.82-3.84-1.62-7.14-2.22-10.48c-1.39-7.83-2.74-15.66-3.94-23.52c-0.15-1,0.33-2.65,1.08-3.16c4.67-3.14,9.51-6.04,14.29-9.02
		c4.77-2.73,9.55-5.47,14.63-8.38C123.25,544.41,125.6,555.6,128.06,567.36z M41.51,561.24c-1.74-4.11,0.87-6.82,2.89-8.68
		c4.33-3.99,8.95-7.66,14.75-9.93c6.41-2.51,12.25-6.45,18.34-9.77c1.54-0.84,3.09-1.67,4.94-2.68c0.94,6.1,1.84,11.94,2.77,17.99
		c-13.59,8.14-27.16,16.26-41.02,24.56C43.27,568.62,42.97,564.69,41.51,561.24z M58.88,651.63c-1.02-4.9-1.95-9.38-2.89-13.87
		c-3.2-15.28-6.43-30.56-9.58-45.85c-0.4-1.96-0.61-3.96-0.83-5.96c-0.07-0.67-0.15-1.33-0.24-2c-0.43-1.11-0.69-2.1-0.76-3.01
		c-0.23-2.72,1.14-4.63,4.1-6.35c8.97-5.2,17.8-10.63,26.74-15.88c3.34-1.96,6.84-3.68,10.57-5.67c0.53,1.4,0.98,2.56,1.43,3.72
		c2.98,5.94,2.51,12.55,3.66,18.84c1.87,10.24,3.51,20.53,5.32,30.78c1.04,5.87,2.22,11.72,3.43,18.02
		C88.28,636.05,73.42,642.95,58.88,651.63z M100.48,604.71c-0.94-4.25-1.87-8.5-2.82-12.8c9.86-6.52,19.75-13.06,29.97-19.82
		c0,9.55,0.13,18.93-0.14,28.29c-0.04,1.4-1.72,3.09-3.06,4.05c-3.25,2.31-6.61,4.46-9.97,6.61c-1.12,0.72-2.24,1.44-3.35,2.16
		c-1.23,0.87-2.47,1.74-3.71,2.62s-2.5,1.77-3.79,2.68C102.59,614.02,101.53,609.37,100.48,604.71z M124.78,612.86
		c-2.18,1.31-4.35,2.61-6.53,3.92c-1.14,0.55-2.28,1.1-3.43,1.65c-2.29,1.1-4.6,2.22-6.96,3.36c-1.18,0.57-2.38,1.15-3.59,1.73
		c-0.13-0.99-0.22-1.75-0.29-2.29c3.69-1.99,7.22-3.9,10.76-5.8c3.56-2.65,7.11-5.3,10.67-7.94c0.02-0.02,0.04-0.03,0.05-0.05
		c0.26-0.23,0.52-0.45,0.81-0.71c0.31-0.27,0.66-0.58,1.08-0.94c0.28,2.04,0.49,3.6,0.73,5.37
		C127.03,611.7,125.9,612.28,124.78,612.86z M126.23,505.12c7.83-5.25,15.5-10.39,23.69-15.88c0.78,9.94,1.53,19.49,2.28,29.09
		c-7.49,1.51-15.02,3.02-22.97,4.62C128.23,516.98,127.21,510.95,126.23,505.12z M178.95,522.31c-0.21,3.29-0.6,6.58-0.67,9.88
		c-0.3,13.46-0.52,26.91-0.74,40.37c-0.18,10.81-0.33,21.62-0.43,32.43c-0.02,1.63,0.27,3.26,0.48,5.59
		c-3.38,0.53-7.01,1.09-10.7,1.67c-0.61-1.48-1.04-2.53-1.48-3.59c-0.32-0.1-0.64-0.2-0.96-0.31c-0.29,0.97-0.58,1.94-1.14,3.77
		c-4.02,0.43-8.89,0.96-13.5,1.45c-0.9-2.87-1.64-5.2-2.38-7.54c-0.41,0.08-0.82,0.17-1.23,0.25c0.23,2.07,0.47,4.13,0.81,7.13
		c-4.61,0.4-9.27,0.8-14.36,1.24c0-1.31,0-2.59,0-3.86c0-20.06,0.12-40.12-0.07-60.18c-0.06-6.73-0.9-13.44-1.35-20.17
		c-0.09-1.4-0.01-2.82-0.01-4.19c6.36-0.92,12.18-1.77,18.37-2.66c1.52,5.22,3.01,10.37,4.51,15.51c0.37-0.07,0.73-0.13,1.1-0.2
		c-0.52-5.25-1.03-10.49-1.62-16.52c8.16-1.21,16.56-2.46,25.37-3.77C178.96,520.22,179.01,521.27,178.95,522.31z M180.05,601.01
		c0-2.01,0-3.78,0-5.55c0.21-12.95,0.37-25.91,0.64-38.86c0.13-6.04,0.37-12.09,0.72-18.13c0.32-5.66,0.98-11.3,1.19-16.96
		c0.07-1.86-0.81-3.76-1.18-5.3c-6.02,0-11.42,0-17.39,0c-0.42-6.06-0.88-12.16-1.26-18.26c-0.29-4.61-0.66-9.23-0.62-13.84
		c0.01-1.36,0.96-3.31,2.08-3.97c3.88-2.26,7.79-4.44,11.72-6.61c2.5-1.38,5.01-2.75,7.52-4.12c1.43-0.78,2.86-1.56,4.28-2.35
		c4.51-3.13,9.02-6.27,14.02-9.75c0.45,1.38,1.01,2.39,1.07,3.42c0.32,5.68,0.52,11.37,0.76,17.06c0.24,5.8,0.88,11.63,0.61,17.41
		c-0.33,7.03-1.53,14.01-2.15,21.03c-0.24,2.74-0.68,5.83,0.28,8.24c1.52,3.81,0.24,7.43,0.59,11.1c0.25,2.64,0.95,5.24,1.46,7.86
		c0.18,0.9,0.69,1.83,0.58,2.68c-1.68,13.06,0.03,25.99,1.4,38.94c0.55,5.24,0.96,10.49,1.43,15.71
		C198.49,604.57,189.33,603.4,180.05,601.01z M183.12,637.22c2.63,5.55,1.47,11.04,0.81,17.88c-2.75-1.69-4.97-3.05-7.9-4.84
		c1.34,1.93,2.22,3.21,3.59,5.19c-5.32,0.98-8.76-3.01-13.53-3.19c2.85,2.01,5.7,4.03,8.55,6.04c-0.06,0.32-0.13,0.65-0.19,0.97
		c-5.49-1.35-10.97-2.69-16.85-4.14C166.06,649.19,174.38,643.35,183.12,637.22z M161.13,658.19c4.66,2.56,9.89,4.08,14.61,5.94
		c4.29,15.85,17.31,13.73,29.14,14.53c0,4.87,0,9.5,0,14.03c-17.68-11.3-35.29-22.56-52.77-33.74
		C154.27,656.02,157.14,656,161.13,658.19z M148.4,661.07c14.79,9.93,29.54,19.85,44.3,29.76c-0.24,0.38-0.48,0.77-0.72,1.15
		c-16.17-8.09-30.09-19.88-46.47-28.9C146.94,662.09,147.59,661.64,148.4,661.07z M118.83,688.68c0.06,0.46,0.12,0.92,0.19,1.39
		c-1.36,0.09-2.73,0.18-4.23,0.28c-0.93,0.33-2.12,0.74-3.3,1.16C113.18,687.9,116.06,688.48,118.83,688.68z M117.58,696.47
		c0.06,0.21,0.24,0.84,0.38,1.3c-1.6,1.81-6.07,0.6-7.63-1.94C112.68,696.04,114.89,696.24,117.58,696.47z M118.98,707.96
		c-0.32-0.14-0.64-0.27-0.97-0.41c-1.73,3.57-3.46,7.15-5.32,10.97c0-2.41,0-4.55,0-5.92c1.73-3.54,3.25-6.64,5.1-10.41
		C118.28,704.54,118.63,706.25,118.98,707.96z M110.58,702.16c-3.85,1.88-8.01,3.9-12.25,5.97c-0.78-2.97-1.62-6.15-2.65-10.05
		c4.15-1.46,7.8-2.74,10.91-3.83C108.07,697.2,109.33,699.69,110.58,702.16z M100.11,716.93c-0.69,0.88-1.54,1.97-2.59,3.31
		c-1.3-0.35-2.73-0.74-4.61-1.26c2.09-2.44,4.01-4.67,6.05-7.05C99.4,713.81,99.74,715.29,100.11,716.93z M96.09,667.05
		c0.36-0.02,0.71-0.03,1.07-0.05c0.3,4.62,0.6,9.25,0.95,14.61c1.32,0.63,3.24,1.54,5.14,2.44c-2.04,1.3-4.46,2.84-7.39,4.71
		C94.81,680.96,92.48,673.94,96.09,667.05z M89.61,658.92c1.28-3.19,4.02-5.27,7.42-5.13c3.66,0.14,6.4,2.55,7.59,6
		c1.4,4.05,2.3,8.27,3.51,12.77c-2.89,0-5.1,0-7.43,0c-0.9-4.46-1.81-8.94-2.74-13.53c-11.08,11.6-6.95,23.68-2.29,37.08
		c-2.72-0.18-5.06-0.34-7.25-0.49c-0.63-5.19-1.05-10.2-1.89-15.13C85.24,672.92,86.84,665.82,89.61,658.92z M86.22,712.14
		c-3.17-1.29-6.23-2.68-6.34-8.06c2.9,2.81,5.11,4.95,7.32,7.09C86.88,711.49,86.55,711.81,86.22,712.14z M82.6,701.57
		c3.25-2.03,6.45-3.91,10.03-0.47C88.13,705.52,85.24,705.65,82.6,701.57z M90.2,724.2c0.65-1.37,1.19-2.48,2.05-4.28
		c1.94,1.74,3.68,3.3,5.42,4.86c-0.32,0.33-0.63,0.65-0.95,0.98C94.64,725.26,92.55,724.76,90.2,724.2z M147.36,704.3
		c0.81-1.95,1.97-3.55,5.26-2.31c-1.63,2.59-2.95,5.16-4.71,7.39c-5.84,7.42-14.09,11.69-22.07,16.23c-2.63,1.5-5.51,2.55-8.3,3.76
		c-3.06,1.33-5.69,2.08-9.09-0.34c-1.95-1.39-5.69-0.25-9-0.25c-0.59-0.64-1.45-1.57-2.38-2.58c1.44-0.63,2.6-1.15,3.77-1.66
		c0.28,1.05,0.57,2.13,1.04,3.91c2.26-4.15,5.13-4.84,8.85-3.64c0.85,0.27,2.67-0.51,3.15-1.33c3.4-5.9,4.08-6.15,11-2.65
		c0.45-0.71,0.82-1.29,1.48-2.34c-2.62-1.08-5.15-2.12-7.97-3.28c0.26-0.62,0.58-1.4,0.99-2.42c4.03,0.64,7.58,0.22,7.85-5.54
		c2.41,1.08,4.07,1.81,5.93,2.65c0.63-0.77,1.35-1.65,2.25-2.76c2.25,0,4.78-0.15,7.27,0.04
		C145.07,707.36,146.47,706.44,147.36,704.3z M140.32,702.48c-3.83,0.83-7.64,1.65-12.38,2.67c0.87-4.86,1.54-8.66,2.22-12.47
		c0.52,0,1.04,0.01,1.56,0.01c0.96,3.38,1.92,6.77,2.88,10.15c0.35-0.08,0.71-0.16,1.06-0.24c1.33-3.55,0.76-7.1-1.18-10.22
		c-0.86-1.39-2.95-2.03-4.54-3.05c-4,4.93-3.21,10.01-3.81,14.71c-1.13,1.61-2.28,3.26-3.75,5.35c-0.39-0.89-1.24-1.87-1.1-2.67
		c1.08-6.18,2.18-12.37,3.62-18.47c1.06-4.52,2.22-5.37,7.15-6.93C139.38,686.55,139.24,694.77,140.32,702.48z M134.62,679.34
		c4.19-0.32,7.09,4.03,6.51,10.08C139.14,686.34,136.77,682.67,134.62,679.34z M165.52,713.64c0.03-0.02,0.06-0.04,0.09-0.06l0,0
		c0.05-0.02,0.09-0.04,0.14-0.06c0.16-0.26,0.31-0.51,0.47-0.77l0,0c0.46-0.81,0.88-1.61,1.25-2.41c0.19-0.4,0.36-0.8,0.52-1.21
		c0.32-0.8,0.59-1.61,0.81-2.42c1.19-4.43,0.69-8.87-2.47-13.35c-2.73-3.86-7.14-6.18-14.23-7.17c0.99-1.16,1.88-2.2,2.86-3.35
		c-0.69-2.43-1.64-4.59-4.55-5.09c-3.63-0.62-5.2,1.9-7.1,4.92c-2.01-5.54-5.95-7.09-11.26-6.19c-5.28,0.89-6.99,5.43-9.59,9.04
		c-2.55-0.54-4.99-1.06-7.9-1.68c9.2-5.17,18.04-10.14,27.39-15.4c15.84,9.54,32.05,19.3,48.91,29.45
		c-7.38,6.15-16.07,10.29-24.21,15.26c-0.29,0.12-0.59,0.24-0.88,0.36c-0.02,0.04-0.05,0.07-0.07,0.11
		C165.63,713.64,165.57,713.64,165.52,713.64c-0.05,0.03-0.09,0.06-0.14,0.09c-0.01,0.01-0.01,0.01-0.02,0.01
		c-0.07,0.04-0.1,0.07-0.12,0.08c-0.01,0.01-0.02,0.02-0.02,0.01c0,0,0.01-0.01,0.02-0.01c0.01-0.01,0.03-0.02,0.05-0.04
		c0.02-0.01,0.04-0.03,0.07-0.05C165.41,713.72,165.46,713.69,165.52,713.64c-0.16,0.01-0.26,0.01-0.3,0.02
		C165.11,713.66,165.29,713.65,165.52,713.64z M197.73,707.42c0.87,4.79,1.62,8.87,2.43,13.3c-7.22-3.77-14.38-4.14-21.2-0.68
		c-8.59,4.36-15.41,10.87-19.59,19.7c-3.39,7.17-5.41,14.8-5.16,22.77c0.48,15.01,3.97,29.24,12.34,42.32
		c-9.47,2.4-16.82,7.77-24.35,13.88c0-2.9,0-5.53,0-8.53c-2-0.62-3.73-1.16-5.69-1.78c-0.75-9.14-1.53-18.66-2.31-28.17
		c0.28,0.08,0.56,0.16,0.84,0.24c0.64-2.01,1.77-4.01,1.84-6.05c0.24-6.55,0.09-13.11,0.09-19.8c-1.14-0.67-2.23-1.3-3.47-2.02
		c-0.09-1.96-0.2-4.07-0.35-7.18C154.05,733.11,175.56,720.46,197.73,707.42z M137.16,824.2c-0.32-4.42-0.59-8.22-0.88-12.29
		c0.98-0.04,1.75-0.08,3.29-0.15C138.27,815.98,140.69,820.25,137.16,824.2z M137.42,878.01c-0.15,0.04-0.3,0.08-0.45,0.12
		c0.01-0.13,0.02-0.27,0.04-0.4c-0.02-0.02-0.05-0.03-0.07-0.05c-0.01-0.04-0.02-0.08-0.03-0.11c-1.03-0.55-2.47-0.95-3.3-1.57
		c-0.29-0.21-0.5-0.45-0.6-0.73c-3.53-10.13-2.66-20.15,1.56-29.93c0.08-0.19,0.37-0.29,1.13-0.84c0.14,1.65,0.73,3.16,0.27,4.2
		c-2.86,6.51-2.25,13.16-1.15,19.84c0.5,3.04,1.37,6.02,2.08,9.03c0.04,0.02,0.08,0.04,0.11,0.06l0,0c0,0.04-0.01,0.07-0.01,0.11
		C137.15,877.83,137.29,877.92,137.42,878.01z M151.38,721.61c0.24,0.44,0.47,0.88,0.71,1.32c-4.57,2.55-9.13,5.11-13.7,7.66
		c-0.19-0.37-0.38-0.75-0.57-1.12C141.88,725.98,146.22,723.01,151.38,721.61z M121.71,745.01c8.06-4.51,16.14-8.97,24.23-13.43
		c0.24-0.13,0.58-0.08,0.89-0.07c0.04-0.03,0.08-0.05,0.11-0.08l0,0c0.06,0,0.13-0.01,0.19-0.01c0.02-0.01,0.03-0.02,0.04-0.03
		c0.22-0.16,0.35-0.25,0,0c-0.01,0.01-0.03,0.02-0.04,0.03c0.18-0.01,0.32-0.02,0.32-0.02c0,0-0.06,0-0.19,0.01
		c-0.04,0-0.08,0.01-0.13,0.01c-0.04,0.03-0.09,0.06-0.13,0.09c-0.05,0-0.11,0-0.17,0c-2.42,1.67-4.76,3.46-7.21,5.08
		c-0.83,0.55-1.67,1.07-2.53,1.57c-6.79,3.91-13.75,7.54-20.65,11.25c-0.66,0.36-1.46,0.48-2.44,0.17
		C116.57,748.05,119.1,746.46,121.71,745.01z M73.69,777.38c18.66-9.91,37.06-19.68,55.83-29.66c0,2.44,0,4.48,0,6.87
		c-8.18,2.67-6.28,9.49-5.06,15.03c0.76,3.45-0.31,8.75,4.94,10.33c0.55,9.21,1.11,18.54,1.67,27.89c-0.35,0.38-0.68,0.8-0.98,1.27
		c-0.3,0.47-0.58,0.97-0.83,1.52c-0.13,0.27-0.24,0.55-0.36,0.84c-1.01,2.58-1.53,5.79-1.57,8.98c-0.08,6.38,1.72,12.7,5.14,13.9
		c0.16,1.47,0.32,2.93,0.46,4.17c0.03,0.3,0.07,0.6,0.1,0.87c0.03,0.27,0.06,0.54,0.08,0.77c0.02,0.2,0.04,0.39,0.06,0.56
		c-5.8,3.83-10.68,7.53-15.98,10.48c-11.79,6.56-23.27,13.56-34.18,21.5c-0.93,0.68-1.78,1.45-3.06,2.5
		c-0.37-5.22-0.67-10-1.04-14.77c-0.91-11.53-1.85-23.06-2.77-34.59c-0.04-0.47-0.07-0.95-0.08-1.43c-0.24-10-0.34-20.01-0.77-30
		C75.04,788.76,74.25,783.14,73.69,777.38z M129.97,777.04c-4.22-11.02-4.58-16.68-1.12-19.13
		C129.22,764.25,129.57,770.23,129.97,777.04z M141.44,887.96c-8.63,3.68-17.63,5.78-26.98,5.34c-4.21-0.2-8.59-1.22-12.49-2.85
		c-7.54-3.16-7.39-2.15-13.43,2.27c-5.56,4.08-11.08,8.29-17,11.79c-4.17,2.47-8.97,3.61-14.06,2.56c-4.1-0.84-5.6-2.14-6.59-7.23
		c3.78-2.31,7.84-4.61,11.71-7.19c12.07-8.06,24-16.31,36.11-24.3c4.11-2.71,8.66-4.74,12.88-7.29c5.58-3.37,11.03-6.95,16.57-10.46
		c-0.43,2.9-0.96,5.76-1.26,8.64c-0.95,9.12-0.43,17.91,7.23,24.42c1.48,1.26,3.66,1.68,5.52,2.48c0.81-1.86,1.61-3.72,2.42-5.58
		c-0.71-1.71-1.42-3.43-2.13-5.14c-3.22-8.61-4.35-17.27-1.2-25.55c5.98,1.96,11.61,3.81,17.52,5.74c-0.34,2.39-0.71,5.05-1.09,7.78
		c-0.13,0.91-0.26,1.83-0.39,2.74c0,1.72,0,3.43,0,5.15l0,0c0,0,0,0,0,0c0.03,0.42,0.05,0.83,0.07,1.25
		c0.02,0.47,0.04,0.94,0.07,1.42c0.05,0.89,0.12,1.77,0.25,2.65c0.25,1.6,0.79,3.15,1.34,5.24
		C151.47,883.89,146.42,885.83,141.44,887.96z M156.18,849.85c-0.85-2.47-1.65-4.8-2.46-7.14c-0.34,0.05-0.68,0.09-1.03,0.14
		c0.1,2.19,0.2,4.38,0.3,6.68c-4.09-0.08-1.87-5.07-4.86-6.71c-0.31,2.11-0.55,3.72-0.79,5.32c-0.22,0.01-0.43,0.02-0.65,0.03
		c-0.42-2.51-0.83-5.01-1.25-7.52c-0.3-0.01-0.59-0.01-0.89-0.02c-0.25,2.01-0.49,4.02-0.74,6.02c-0.22,0.04-0.43,0.07-0.65,0.11
		c-0.36-2.24-0.73-4.48-1.09-6.72c-0.18-0.01-0.35-0.02-0.53-0.03c-0.45,1.35-0.9,2.71-1.34,4.06c-0.12,0.01-0.24,0.02-0.36,0.02
		c0-2.1,0-4.21,0-7.07c-1.18,1.1-1.78,1.67-2.47,2.32c-0.14-0.19-0.34-0.34-0.34-0.49c-0.09-7.67,4.35-12.87,9.98-17.01
		c6.16-4.53,12.76-8.48,19.13-12.72c1.07-0.71,2-1.65,2.99-2.49c0.13,0.3,0.26,0.59,0.39,0.89c2.42-0.15,4.84-0.2,7.25-0.46
		c8.76-0.95,20.58-0.12,27.05,9.18c-9.09,10.86-18.81,21.14-31.87,27.89c-0.98-0.51-1.88-0.98-3.1-1.62
		c-0.16,0.85-0.13,1.68-0.48,2.29c-0.36,0.62-1.1,1.03-1.62,1.49c-1.28-0.98-2.29-1.76-3.7-2.84c-0.2,2.01-0.33,3.33-0.58,5.78
		c-1.84-2.6-3.09-4.37-4.42-6.26C156.25,845.4,160.77,848.47,156.18,849.85z M171.07,782.59c-0.56,6.78-1.04,12.67-1.56,18.96
		C167.18,795,169.3,789.26,171.07,782.59z M179.99,774.25c4.63-5.47,9.01-7.26,15.43-3.26c0.9,10.25,1.78,20.23,2.62,29.76
		c-2.36,3.92-5.36,1.67-7.93,1.61c-5.62-0.13-11.24-0.51-17.15-0.8C171.92,792.11,173.13,782.35,179.99,774.25z M206.78,803.29
		c-0.51,3.15-0.83,5.2-1.21,7.53c-0.97-1.2-1.81-2.26-3.03-3.77C203.45,806.25,204.72,805.12,206.78,803.29z M203.54,782.69
		c0.32-0.07,0.64-0.13,0.96-0.2c0.95,4.37,1.9,8.74,2.84,13.11c-0.41,0.05-0.81,0.11-1.22,0.16
		C205.26,791.41,204.4,787.05,203.54,782.69z M162.07,879.71c-0.44,0.07-0.87,0.14-1.31,0.21c-0.53-2.03-1.44-4.04-1.52-6.08
		c-0.22-5.35-0.01-10.72-0.1-16.07c-0.04-2.34,0.77-3.6,3.15-4.17c2.22-0.53,4.33-1.57,6.49-2.39
		C160.45,859.32,161.99,869.71,162.07,879.71z M207.73,840.07c-0.46,3.65-1.03,7.33-2.15,10.82c-0.28,0.87-0.6,1.73-0.95,2.58
		c-0.54,1.27-1.17,2.5-1.92,3.69c-0.75,1.19-1.62,2.32-2.63,3.41c-0.39,0.29-0.78,0.59-1.17,0.88c0,0-0.11,0.12-0.11,0.12
		c-5.01,4.34-10.78,7.32-16.74,9.96c-1.99,0.88-3.99,1.72-6,2.56c-2.71,1.13-5.44,2.28-8.25,3.11c-0.75,0.22-1.84-0.68-2.78-1.06
		c-0.01-3.05-0.02-6.09-0.03-9.14c1.4-3.13,2.79-6.26,4.19-9.38c0.13-0.22,0.25-0.45,0.37-0.68c0.35-0.69,0.69-1.4,1.22-1.91
		c7.44-7.23,15.01-14.31,23.96-19.7c1.09-0.66,2.23-1.3,3.43-1.71C203.54,831.78,208.41,834.69,207.73,840.07z M218.3,863.79
		c-0.88,0-1.96,0-3.07,0c1.72-10.87-0.04-21.03-6.3-30.99c-2.95-1.26-5.84-5.05-10.59-2.79c2.02-2.22,4.04-4.44,6.22-6.83
		C216.4,834.3,220.39,847.89,218.3,863.79z M219.22,779.91c-0.43,10.86-3.84,21.06-9.15,30.62c0.23-2.81,0.41-5.62,0.7-8.42
		c0.99-9.65-1.03-18.6-5.74-27.09c-4.08-7.36-11.63-11.35-19.63-9.9c-10.93,1.98-17.5,8.89-19.05,20.07
		c-0.37,2.68-0.66,5.37-1.06,8.63c-3.73-9.21-5.75-18.06-6.3-27.51c-0.68-11.64,2.1-22.02,8.87-31.17
		c5.3-7.17,12.35-12.63,21.76-12.75c7.74-0.1,13.63,4.49,18.09,10.36c6.11,8.02,8.71,17.61,10.14,27.4
		C218.8,766.66,219.49,773.34,219.22,779.91z M245.77,734.42c0,12.25,0,24.5,0,36.09c-8.28,5.96-15.77,11.36-23.41,16.86
		c2.1-18.52-0.93-35.86-8.75-52.38c-0.35-0.74-0.69-1.51-1.18-2.14c-1.46-1.86-3.38-3.47-4.39-5.54c-0.98-2.01-1.03-4.46-1.59-7.19
		c1.64-0.62,3.3-1.24,4.97-1.87c1.67-0.63,3.35-1.26,5.02-1.89c0.67-0.57,1.32-1.01,1.96-1.31c0.32-0.15,0.65-0.26,0.97-0.34
		c0.32-0.08,0.65-0.12,0.98-0.13s0.66,0.02,1,0.08c1.01,0.18,2.08,0.68,3.25,1.5c6.85,4.8,14.04,9.1,21.17,13.65
		C245.77,731.35,245.77,732.88,245.77,734.42z M266,727.29c-0.93,0.67-1.87,1.34-3.24,2.33c-1.83-1.21-3.69-2.43-5.56-3.66
		c-1.87-1.23-3.76-2.47-5.64-3.71c0,0,0,0,0,0c-4.22-2.75-8.35-5.67-12.61-8.35c-1.42-0.89-2.86-1.76-4.32-2.58
		c-7.92-4.46-16.14-8.4-24.85-12.89c0.38-0.78,0.81-1.66,1.25-2.57c0.15-0.3,0.3-0.61,0.44-0.91c0.9-0.82,1.77-1.66,2.66-2.49
		c0.89-0.82,1.8-1.62,2.77-2.35c0.61-0.46,1.95-0.4,2.75-0.07c1.79,0.74,3.45,1.8,4.51,2.38c-0.14,2.3-0.26,4.33-0.37,6.3
		c-0.04,0.66-0.08,1.31-0.12,1.95c2.22,2.16,4.7,3.79,7.37,5.06c1.33,0.64,2.71,1.18,4.12,1.66s2.86,0.89,4.33,1.26
		c0.49-0.47,0.98-0.93,1.47-1.4c1.48-1.41,3.01-2.86,4.73-4.5c0.97,0.49,2.89,1.45,5.24,2.62c-5.23,0.94-4.22,4.84-4.44,8.14
		c1.8,2.77,3.6,5.53,5.4,8.3c3.28,0.88,6.56,1.75,9.84,2.63c0.19-0.11,0.37-0.22,0.56-0.32c1.32-0.76,2.75-1.6,5.09-2.96
		C266.79,723.77,266.39,725.53,266,727.29z M325.41,797.08c-0.64,1.65-1.16,2.99-1.69,4.38c-3.05-2.44-4.15-8.66-2.81-16.79
		c6.51,4.07,12.9,7.75,18.96,11.91c11.56,7.94,22.93,16.16,34.88,24.61c0,1.26,0,3.5,0,6.21c-6.96-6.29-15.09-8.12-23.62-8.11
		c-1.19-3.77-1.64-8.06-3.76-11.23c-3.66-5.48-9.01-8.7-15.84-3.34c-0.83,0.65-1.62,1.34-2.52,2.09
		C325.53,804.45,329.8,799.36,325.41,797.08z M322.01,804.5c1.03,3.03,1.78,5.24,2.67,7.87c0.95-0.5,1.79-0.94,2.57-1.35
		c0.78,0.7,1.76,1.57,2.96,2.65c1.29-2.09,2.19-3.82,3.35-5.36c1.65-2.2,3.72-3.8,6.72-2.81c3.21,1.06,5.24,3.37,5.79,6.62
		c0.4,2.37,0.32,4.87,0.14,7.28c-0.25,3.47,1.87,5.33,5.16,4.56c6.36-1.48,12.09,0.55,17.46,3.71c2.11,1.25,3.87,3.1,5.95,4.8
		c0.31,4.19,0.64,8.56,0.97,13.07c-1.64-1.49-3.11-2.78-4.53-4.13c-3.89-3.71-8.39-5.41-13.84-4.69c-1.73,0.23-3.57-0.56-5.34-0.46
		c-1.16,0.07-2.63,0.61-3.35,1.46c-5.49,6.47-16.15,6.87-22.41,1.16c-5.37-4.9-7.97-11-9.41-17.84c-0.38-1.79-0.87-3.55-1.52-6.14
		c1.07,1.38,1.62,2.1,2.41,3.11C323.69,815.26,319.73,809.53,322.01,804.5z M355.01,842.1c6.28-1.24,11.57-0.66,14.94,5.24
		C367.71,849.42,361.4,847.13,355.01,842.1z M367.38,854.3c-2.87,2.27-5.54,0.02-7.97-0.98c-7.45-3.06-9.27-5.34-8.59-9.56
		C356.13,847.13,361.52,850.56,367.38,854.3z M317.48,789.72c-0.17,2.47-0.66,4.91-1,7.37c-0.32,0.1-0.65,0.19-0.97,0.29
		c-0.57-2.69-1.14-5.38-1.56-7.35c-1.53-1.42-2.82-2.21-3.53-3.36c-3.96-6.44-9.32-7.77-15.8-3.75
		c-6.19-11.06-12.63-21.41-26.39-24.38c0-2.56,0-5.5,0-9.07c8.09,5.08,16.11,9.75,23.73,14.99c7.31,5.02,13.89,11.15,21.41,15.77
		C317.62,782.85,317.74,785.92,317.48,789.72z M279.33,816.7c-2.06-1.28-4.13-2.56-6.19-3.83c-1.16-0.73-2.33-1.46-3.51-2.2
		c0-2.72,0-5.42,0-8.13c0-9.96,0-19.93,0-29.92c3.37-0.11,5.38,2.27,8.34,10.48c-1.59,1.09-3.2,2.2-4.41,3.03
		c0.01,3.51-0.02,6.91-0.38,10.22s-1.05,6.54-2.32,9.7c0.51,1.18,1.03,2.35,1.54,3.53c0.2,0.27,0.4,0.54,0.6,0.81
		c0,0,0.02,0.03,0.02,0.03c2.3,1.8,4.59,3.61,6.89,5.41C279.7,816.12,279.51,816.41,279.33,816.7z M278.66,809.97
		c-0.09,0.15-0.18,0.29-0.28,0.44c-0.34-0.25-0.69-0.5-1.03-0.75c0,0,0,0,0,0c-0.39-0.12-0.79-0.25-1.18-0.37
		c0.06-0.17,0.12-0.34,0.17-0.51c0.42,0.18,0.85,0.36,1.27,0.54C277.96,809.53,278.31,809.75,278.66,809.97z M274.91,807.36
		c-0.27-0.03-0.54-0.05-1.83-0.17c1.29-0.16,1.56-0.19,1.82-0.22C274.9,807.09,274.91,807.22,274.91,807.36z M280.52,811.56
		c-0.45,0-0.91,0-1.36,0c0.06-0.19,0.12-0.39,0.18-0.58c0.4,0.07,0.8,0.15,1.2,0.22C280.54,811.32,280.53,811.44,280.52,811.56z
		 M299.6,828.17c-2.37-1.47-4.53-2.81-6.69-4.16c-3.27-1.73-6.53-3.47-9.8-5.2c0.18-0.38,0.36-0.75,0.54-1.13
		c3.91,1.82,7.83,3.64,11.74,5.46c0.6,0.29,1.2,0.58,1.8,0.87c1.82,0.87,3.69,1.77,5.77,2.78c0.2,0.73,0.53,1.91,1.17,4.2
		C302.53,829.99,301.03,829.06,299.6,828.17z M281.22,813.06c0.14-0.3,0.29-0.59,0.43-0.89c2.45,1.1,4.89,2.19,7.34,3.29
		c-0.2,0.41-0.4,0.83-0.6,1.24C286,815.48,283.61,814.27,281.22,813.06z M307.85,817.58c-3.3,4.27-6.71,4.71-10.85,1.15
		c-4.2-3.6-7.44-7.82-8.96-13.36c-2.64-9.62-4.46-19.53-9.69-28.31c-2.33-3.91-4.81-7.34-10.08-7.99c0-2.6,0-4.83,0-7.87
		c2.57,1.34,5.14,2.1,6.95,3.72c7.21,6.44,13.7,13.46,16.63,23.06c0.4,1.31,1.93,2.27,3.4,3.9c0.8-1.65,1.01-2.65,1.62-3.21
		c1.38-1.26,2.94-3.16,4.47-3.19c1.75-0.04,4.51,1.23,5.14,2.67c1.76,4.02,2.73,8.4,3.84,12.69c0.42,1.62,0.34,3.36,0.49,5.05
		c0.34,0.09,0.69,0.18,1.03,0.27c1.33-1.53,2.65-3.06,3.98-4.59c0.34,0.12,0.68,0.25,1.02,0.37c-0.28,2.69-0.56,5.37-0.86,8.26
		c-2.2,0-4.17,0-6.81,0c0.74,1.62,1.15,2.52,1.61,3.52C309.85,814.94,308.86,816.27,307.85,817.58z M314.57,827.18
		c-0.15,0.94-0.55,1.74-1.23,2.31c0,0,0,0,0,0c-0.15,0.13-0.32,0.25-0.49,0.35c-0.36,0.21-0.77,0.37-1.23,0.48
		c0.11-0.94,0.21-1.85,0.31-2.75c0.26-2.31,0.51-4.47,0.75-6.63c0,0,0,0,0,0c0,0,0,0,0-0.01
		C314.21,823.13,314.85,825.4,314.57,827.18z M352.92,860.97c-0.35-0.15-0.7-0.29-1.05-0.44c0,0,0,0,0,0
		c-8.26-3.53-16.05-7.84-23.42-12.88c-2.46-1.68-4.86-3.44-7.22-5.28c-3.32-2.59-6.86-4.88-10.34-7.34
		c2.04-0.42,4.01-0.82,5.29-1.09c4.28,4.09,8.06,8.76,12.82,11.95c5.16,3.45,11.2,1.5,17.31-0.24c-1.53,6.41,2.53,10.28,5.9,14.46
		c0.28,0.19,0.55,0.37,0.83,0.56C353,860.76,352.96,860.86,352.92,860.97z M354.32,856.17c-0.63-0.65-1.25-1.29-1.88-1.94
		c-0.06-0.24-0.11-0.48-0.17-0.71c0.13,0.16,0.26,0.31,0.4,0.47l0,0c0.74,0.48,1.48,0.97,2.22,1.45
		C354.69,855.68,354.51,855.92,354.32,856.17z M355.35,856.18c3.86,1.27,7.42,2.45,11.61,3.83
		C362.52,861.94,357.76,860.2,355.35,856.18z M369.54,869.94c-2.63-1.3-5.25-2.59-7.88-3.89c0.04-0.15,0.09-0.3,0.13-0.45
		c2.09,0.31,4.17,0.61,7.04,1.04c0.33-1.28,0.68-2.69,1.05-4.09c0.97-3.63,1.87-7.27,2.98-10.85c0.19-0.63,1.27-0.98,2.63-1.95
		c0,3.71,0,6.52,0,9.33c0.04,0.59,0.07,1.17,0.11,1.76c0.07,1.17,0.15,2.35,0.22,3.55c0.07,1.2,0.15,2.41,0.23,3.65
		c0.12,1.86,0.24,3.8,0.36,5.83C373.72,872.33,371.63,871.14,369.54,869.94z M376.19,814.69c-8.76-5.13-17.58-10.15-26.24-15.43
		c-5.35-3.25-10.32-7.15-15.74-10.25c-2.97-1.7-4.78-4.35-7.21-6.4c-3.01-2.55-7.05-3.83-10.36-6.08c-1.51-1.02-3-2.07-4.48-3.13
		c-4.42-3.15-8.77-6.42-13.17-9.62c-0.03-0.01-0.06-0.02-0.09-0.03c0,0,0.02-0.02,0.02-0.02c0,0-0.02,0.02-0.02,0.02
		c-0.06-0.05-0.12-0.1-0.19-0.15c-0.66-0.42-1.32-0.84-1.99-1.26l0,0l0,0l0,0l0,0c-7.24-4.07-14.47-8.14-21.79-12.25
		c-2.44-1.37-4.89-2.75-7.35-4.13c-0.19-2.74-0.38-5.64-0.61-9.01c2.84,1.83,5.45,3.6,8.14,5.23c4.83,2.94,9.78,5.69,14.51,8.76
		c1.3,0.84,2.12,2.5,2.99,3.89c1.49,2.39,2.85,4.86,4.26,7.3h0l0,0c0.33,0.27,0.67,0.54,1,0.81c0.27,0.22,0.54,0.44,0.82,0.66
		c0,0,0,0,0,0c0,0,0,0,0,0c0.07,0.04,0.14,0.09,0.2,0.13c0.02,0.02,0.05,0.04,0.07,0.05c0,0,0,0,0,0c0,0,0,0,0,0
		c2.12,0.76,4.22,1.55,6.33,2.31c2.14,0.78,4.3,1.52,6.47,2.18c2,0.6,4.21,0.49,5.86,0.65c6.22,4.57,12.17,8.95,18.14,13.32
		c12.22,8.95,25.12,16.76,38.8,23.27c0.15,0.07,0.3,0.16,0.44,0.25c0.43,0.28,0.83,0.63,1.25,0.92c0.66,0.19,1.31,0.38,1.95,0.57
		c0.64,0.19,1.27,0.37,1.87,0.55c0.42,1.7,0.9,3.13,1.08,4.59c0.17,1.37,0.04,2.78,0.04,4.64
		C379.39,816.19,377.79,815.44,376.19,814.69z M380.87,854.68c-0.66-10.12-1.29-19.77-1.96-30.04c9.09,4.83,18.38,6.25,28.43,5.66
		c0.56-1.79,1.09-3.52,1.74-5.59c0,4.21,0,8.03,0,11.96c-3,0-5.93-0.45-8.67,0.09c-7.95,1.56-12.39,7.74-16.89,13.7
		C382.62,851.65,381.91,853.01,380.87,854.68z M387.49,861.71c-1.47-1.01-3.13-1.73-4.23-2.33c4.17-8.53,8.91-14.05,15.34-17.37
		c1.56,0,3.15,0.19,4.68-0.03c7.55-1.12,20,3.69,22.86,12.83c1.74,5.55-1.41,10.81-7.5,12.28
		C407.49,869.77,397.06,868.29,387.49,861.71z M415.4,880.26c-0.4,0.27-0.8,0.54-1.2,0.8c-1.5-2.12-2.99-4.24-4.64-6.57
		c1.62-0.55,2.7-0.92,4.28-1.46C414.39,875.6,414.9,877.93,415.4,880.26z M389.88,878.36c-2.22-1.28-4.21-2.42-6.29-3.62
		c0-3.21,0-6.38,0-9.87c1.86,1.06,3.57,2.04,5.34,3.05C389.23,871.22,389.54,874.61,389.88,878.36z M389.81,892.11
		c-8.01-2.66-4.19-9.98-6.56-15.21C391.44,879.76,389.97,886.03,389.81,892.11z M398.94,872.36c0.31,3.52,0.63,7.12,0.96,10.89
		c-2.45-1.01-4.71-1.93-7.03-2.89c-0.34-3.45-0.68-6.83-1.05-10.57C394.42,870.73,396.6,871.52,398.94,872.36z M400.48,894.57
		c-2.06-0.41-3.95-0.79-6.27-1.25c-0.44-3.2-0.88-6.47-1.36-10c2.68,1.25,5.09,2.38,7.63,3.56
		C400.48,889.3,400.48,891.76,400.48,894.57z M401.32,873.4c-0.15-0.18-0.3-0.37-0.45-0.55c0.28,0.01,0.56,0.02,0.84,0.03
		c0,0,0,0,0,0c2.17,0.44,4.34,0.88,6.49,1.31c0.9,4.31,1.26,8.85,7.05,9.4c0.53,0.05,0.95,1.34,2.08,3.03
		c-5.52-0.95-9.93-1.71-14.26-2.45C402.44,880.3,401.88,876.85,401.32,873.4z M405.7,895.49c-0.43,0.03-0.93-0.91-1.39-1.41
		c-0.32-2.04-0.64-4.08-1.01-6.42c2.36,0.39,4.57,0.77,6.71,1.12c2.14,0.36,4.21,0.71,6.28,1.05c0.43,1.29,0.85,2.57,1.48,4.48
		C413.54,894.76,409.63,895.21,405.7,895.49z M417.97,883.35c0.28,0.08,0.57,0.16,0.85,0.24c1.07-3.71,2.13-7.42,3.21-11.17
		c2.51,3.43,2.38,8.29,3.41,12.89c-2.23,0.35-4.3,0.67-6.46,1C418.62,885.25,418.29,884.3,417.97,883.35z M420.16,894.16
		c-0.45-2.03-0.72-3.28-1.05-4.75c1.63-0.33,2.86-0.71,4.11-0.8c0.98-0.07,1.98,0.22,3.96,0.47
		C424.72,890.86,422.75,892.29,420.16,894.16z M428.47,884.92c-1.65-4.9-3.16-9.36-4.69-13.9c1.16-1.02,2.46-2.16,4.08-3.59
		C429.96,873.19,429.69,878.83,428.47,884.92z M431.01,856.77c-2.55-8.6-6.23-12.83-15.59-17.53c-0.55-7.35-1.11-14.86-1.7-22.77
		c11.29-6.46,22.68-12.99,34.06-19.5c14.51,8.56,29.18,17.22,44.03,25.98C471.45,834.28,451.24,845.52,431.01,856.77z
		 M537.31,797.24c-12.27,7.32-24.58,14.56-36.82,21.92c-2.15,1.29-3.68,0.94-5.79-0.28c-6.65-3.86-13.56-7.3-20.28-11.05
		c-7.63-4.26-15.16-8.68-23.3-13.36c2.66-2.08,5.26-4.01,7.75-6.09c11.28-9.39,24.68-15.32,37.11-22.84
		c0.67-0.41,2.06-0.4,2.72,0.03c12.94,8.5,25.83,17.09,38.72,25.67c1.18,0.78,2.32,1.61,4.11,2.85
		C539.94,795.29,538.71,796.4,537.31,797.24z M583.6,769.88c-6.39,3.74-12.75,7.47-19.1,11.19c-5.66,3.32-11.32,6.63-16.97,9.94
		c-2.45-1.45-4.92-2.88-7.37-4.32c-2.45-1.44-4.9-2.9-7.32-4.4c-1.75-1.08-3.25-2.58-4.87-3.88l-0.07-0.08
		c-0.89-0.52-1.78-1.04-2.67-1.56l-0.01-0.01c-0.47-0.25-0.93-0.5-1.4-0.75c-7.27-4.44-14.54-8.89-22.73-13.89
		c16.32-9.74,31.76-18.95,47.3-28.23c15.54,9.49,31.31,19.11,47.34,28.89C591.67,765.15,587.63,767.52,583.6,769.88z M641.41,735.15
		c0-0.01,0.01-0.02,0.01-0.03c-0.24,0.2-0.48,0.41-0.72,0.61c0.24-0.2,0.48-0.41,0.72-0.61v0c-0.19,0.17-0.39,0.33-0.58,0.5
		c-0.05,0.04-0.09,0.08-0.14,0.12c-4.73,2.9-9.46,5.82-14.19,8.72c-4.73,2.91-9.47,5.8-14.22,8.68c-2.6,1.57-5.36,2.86-8.05,4.27
		c-0.5,0.37-1,0.73-1.49,1.09c-0.49,0.36-0.98,0.72-1.45,1.07c-1.37-0.81-2.54-1.5-3.7-2.19c-2.4-1.4-4.8-2.8-7.2-4.21
		c-0.42-0.28-0.84-0.57-1.26-0.85c-0.03-0.02-0.05-0.03-0.08-0.05c-1.7-0.93-3.39-1.86-5.09-2.79c-0.93-0.49-1.87-0.98-2.8-1.47
		c-0.54-0.35-1.07-0.7-1.61-1.05c-0.18-0.11-0.35-0.23-0.53-0.34c-2.64-1.68-5.28-3.37-7.93-5.05l0.02,0.02
		c-0.72-0.48-1.45-0.97-2.17-1.45c0,0,0.02,0.02,0.02,0.02c0,0,0,0,0,0l0,0c-0.49-0.47-0.98-0.94-1.47-1.41
		c-1.03-0.5-2.06-1-3.09-1.5c-1.83-0.99-3.67-1.98-5.5-2.97c-0.47-0.43-0.94-0.86-1.42-1.29c0,0,0,0,0,0
		c-0.61-0.26-1.23-0.53-1.88-0.81c-0.22-0.09-0.44-0.19-0.66-0.29c-0.45-0.2-0.93-0.4-1.44-0.62s-1.05-0.45-1.64-0.71
		c2.08-1.14,3.51-1.93,4.94-2.71c6.13-4.52,11.99-9.51,18.47-13.46c8.14-4.96,16.79-9.08,25.15-13.53c1.41,0.93,2.8,1.85,4.15,2.74
		c4.07,2.68,7.9,5.22,11.74,7.75c2.86,1.75,5.73,3.49,8.59,5.24c6.22,3.8,12.43,7.6,18.65,11.4c0.21,0.1,0.41,0.2,0.62,0.3
		c1.46,0.71,2.97,1.44,4.98,2.41C646.31,732.35,643.86,733.75,641.41,735.15z M670.79,683.42c8.31,6.08,16.63,12.16,24.94,18.25
		c0.94,0.69,1.88,1.38,3.02,2.22c-13.85,10.73-30.83,15.35-44.95,24.56c-7.71-4.58-15.09-8.96-22.48-13.35
		c-8.52-5.36-17.03-10.72-25.83-16.25c16.06-8.75,31.79-17.31,47.82-26.04C658.95,676.22,664.87,679.82,670.79,683.42z
		 M676.95,620.31c3.81,2.34,7.61,4.67,11.46,7.03c6.4,3.93,12.91,7.92,19.67,12.07c-19.1,9.84-37.46,19.3-55.49,28.59
		c-11.14-6.79-21.58-13.16-32.03-19.52c-5.14-2.94-10.28-5.87-15.53-8.87c17.73-11.39,37.9-17.98,54.48-31.49
		C665.32,612.19,671.14,616.25,676.95,620.31z M657.86,549.64c15.56,9.46,30.64,18.64,46.55,28.31
		c-8.69,5.67-16.68,11.28-25.08,16.21c-4.96,2.91-10.64,4.55-15.84,7.08c-3.57,1.74-6.34,0.42-9.31-1.3
		c-12.63-7.33-25.3-14.59-37.95-21.86c-0.61-0.35-1.27-0.61-2.05-0.99C628.88,567.85,643.52,558.65,657.86,549.64z M610.99,578.23
		c7.79,5.39,15.18,10.7,22.77,15.7c2.51,1.66,5.1,3.21,7.7,4.73c2.06,1.2,4.13,2.4,6.2,3.6c0.54,0.32,1.08,0.63,1.62,0.95
		c0.72,0.34,1.44,0.68,2.24,1.06c0.81,0.38,1.71,0.81,2.78,1.32c-1.27,0.86-2.39,1.63-3.44,2.34c-1.05,0.71-2.02,1.38-3,2.04
		c-4.86,2.8-9.71,5.6-14.58,8.38c-4.09,2.33-8.15,4.7-12.29,6.93c-6.82,3.68-13.7,7.28-20.33,10.79
		c-14.18-9.67-28.04-19.12-42.66-29.09C575.84,597.31,593.55,587.7,610.99,578.23z M552.73,609.35c0.73-0.41,2.32,0.05,3.17,0.61
		c13.2,8.79,26.35,17.67,39.49,26.55c0.46,0.31,0.74,0.88,1.25,1.5c-8.84,4.43-17.48,8.82-26.18,13.08
		c-1.25,0.61-2.54,1.13-3.85,1.61c-0.9,0.34-1.8,0.66-2.7,1c-0.41,0.15-0.82,0.3-1.23,0.46c-1.1,0.58-2.19,1.16-3.29,1.75
		c-3.28,1.75-6.56,3.51-9.93,5.07c-1,0.46-2.8-0.02-3.88-0.66c-5.79-3.46-11.46-7.1-17.05-11.16c0.97,0.18,1.94,0.35,4.09,0.74
		c-4.11-5.62-9.77-7.62-14.59-10.68c0.2-0.4,0.41-0.8,0.61-1.2c2.1,1.05,4.22,2.06,6.3,3.15c2.26,1.18,4.43,2.55,6.75,3.61
		c2.22,1.01,4.58,1.71,6.87,2.54c0.24-0.46,0.47-0.91,0.71-1.37c-4.72-3.22-9.43-6.45-14.15-9.67l-0.29,0.02c0,0-0.05-0.02-0.1-0.05
		l-0.12-0.06c-0.02-0.01-0.04-0.02-0.04-0.02c-0.29-0.2-0.57-0.41-0.86-0.61l-0.3,0c0,0-0.05-0.02-0.1-0.04l-0.12-0.05
		c-0.03-0.01-0.06-0.02-0.06-0.02c-0.26-0.19-0.52-0.37-0.78-0.56c0.01,0.01,0.02,0.03,0.03,0.04l0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0-0.08-0.08-0.08-0.08c0.02,0.01,0.03,0.02,0.05,0.03c-0.22-0.27-0.44-0.53-0.65-0.8c-0.79-1.79-1.58-3.59-2.38-5.38
		c-0.65,0.43-1.3,0.87-1.95,1.3c1.49-1.02,2.93-2.14,4.49-3.04C532.13,621.02,542.39,615.11,552.73,609.35z M504.89,580.23
		c2.02,1.43,4.1,2.76,6.21,4.07c2.1,1.31,4.23,2.59,6.32,3.91c0.33,0.22,0.66,0.43,1,0.65c0.63,0.41,1.25,0.82,1.88,1.23
		c1.45,1.02,2.9,2.03,4.35,3.05c0.71,0.26,1.43,0.52,2.14,0.77c0,0-0.01-0.02-0.01-0.02c0,0,0,0,0,0l0,0
		c3.36,1.9,6.71,3.8,10.07,5.7c0.27,0.15,0.55,0.29,0.83,0.44c0.91,0.48,1.82,0.96,2.73,1.44c1.18,0.63,2.36,1.28,3.48,2
		c2.72,1.74,2.5,3.08-0.25,4.69c-8.88,5.21-17.74,10.46-26.53,15.82c-1.64,1-2.94,2.58-4.78,4.23c-2.35-1.09-4.82-2.24-7.33-3.41
		s-5.09-2.37-7.66-3.56c-1.24-0.54-2.47-1.08-3.71-1.62c-2.58-1.13-5.17-2.26-7.75-3.39c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-6.88-3.34-13.79-6.64-20.64-10.06c-1.77-0.88-3.34-2.15-5.16-3.34c14.13-4.83,25.72-13.58,37.32-22.27
		C500.02,578.61,502.1,578.26,504.89,580.23z M496.27,575.62c-13.55,8.23-26.97,16.37-41.23,25.02
		c-14.96-8.57-30.23-17.32-45.51-26.07c-0.03-0.37-0.06-0.74-0.08-1.1c3.26-0.82,6.53-1.59,9.77-2.49c1.75-0.48,3.45-1.17,5.66-1.94
		c3.88,2.85,8.2,0.84,12.19-1c3.68-1.7,7.09-4.13,10.34-6.61c2.99-2.27,3.47-7.05,1.78-11.08c1.71,0.52,3.58,0.77,5.11,1.62
		C468.02,559.64,481.7,567.4,496.27,575.62z M448.51,545.91c-0.77-4.25-1.51-8.36-2.28-12.62c12.09-7.08,23.91-14,36.18-21.19
		c0.3,4.78,0.57,9.1,0.81,13C471.47,532.15,460.16,538.92,448.51,545.91z M486.3,506.34c-3.41-15.75-3.47-24.99,0.34-27.19
		C486.89,488.5,490.28,497.57,486.3,506.34z M544.29,461.37c0.38,1.1,0.73,2.15,1.07,3.14c-1.67,0.57-3.28,1.13-5.21,1.79
		c-0.63-0.44-1.54-1.09-2.4-1.71C539.87,463.55,542.02,462.49,544.29,461.37z M539.75,447.87c0.63-0.49,1.34-1.04,2.39-1.86
		c0.53,4.22,1.01,8.05,1.53,12.25c-0.49,0.33-1.32,0.88-2.21,1.47C540.89,455.79,540.36,452.08,539.75,447.87z M583.6,437.5
		c0.26,0.45,0.53,0.9,0.79,1.35c3.57-0.99,7.14-1.99,10.71-2.98c-0.03-0.36-0.07-0.72-0.1-1.08c-0.67-0.31-1.34-0.62-2.11-0.97
		c2.87-1.61,5.72-3.21,8.92-5c0,3.44,0,6.38,0,9c-6.42,7.27-15.49,8.22-23.92,10.72c-3.43,1.02-6.72,2.53-10.39,3.94
		c-1.1-3.86-3.74-2.51-6.14-1.55c-4.82,1.93-9.6,3.98-14.2,5.9c-0.54-4.12-1.05-8.06-1.56-11.96c16.68-13.49,35.7-23.32,55.48-32.28
		c0,4.99,0,9.83,0,14.98c-3.38,1.94-6.99,4.01-11.25,6.45c1.27-2.83,2.23-4.99,3.56-7.94C587.16,428.5,586.72,433.99,583.6,437.5z
		 M608.46,472.17L608.46,472.17L608.46,472.17L608.46,472.17L608.46,472.17c-0.03,0.04-0.06,0.07-0.09,0.11
		c-0.01,0.07-0.03,0.14-0.03,0.19c-0.03,0.17-0.02,0.13,0,0c0.01-0.05,0.02-0.11,0.03-0.19c-0.06,0.07-0.11,0.13-0.15,0.18
		c-0.1,0.11-0.1,0.12,0.15-0.18v0v0c0.01-0.06,0.02-0.12,0.03-0.18c0.04-0.07,0.08-0.14,0.12-0.21c0.42-1.91,0.84-3.83,1.25-5.71
		c-8.89-4.43-17.81-8.87-26.72-13.31c0.14-0.47,0.28-0.93,0.41-1.4c5.91-0.27,11.83-0.83,17.73-0.68c3.45,0.09,6.97,1.03,10.29,2.12
		c3.65,1.19,4.96,3.95,3.38,7.51c-1.77,3.98-4.19,7.67-6.35,11.47C608.5,471.99,608.48,472.08,608.46,472.17z M590.84,470.71
		c0.21,2.27,0.13,4.56,0.17,6.84c-2.06,0-4.19,0.33-6.15-0.11c-1.32-0.29-2.42-1.6-3.61-2.48c0,0-0.01,0.01-0.01,0.01l-0.06-0.07
		c0,0,0.06,0.07,0.06,0.07c-0.01,0-0.01,0-0.02,0.01c0.86,1.72,1.8,3.41,2.54,5.18c0.41,0.98,0.46,2.12,0.67,3.19
		c-0.47,0.19-0.93,0.37-1.4,0.56c-1.88-2.41-3.76-4.81-5.9-7.56c1.71-0.57,2.9-0.97,4.1-1.37c-0.01-0.02-0.03-0.05-0.04-0.07
		c0.02,0.02,0.05,0.04,0.07,0.05c0.97-0.91,1.93-1.82,3-2.83c-1.33-2.28-2.59-4.44-3.9-6.7c0.86-0.36,1.34-0.56,1.59-0.67
		c-0.24-1.15-0.46-2.22-0.68-3.28c-3.15-1.29-6.3-2.58-9.45-3.86c0.04-0.4,0.08-0.81,0.12-1.21c2.79-1.39,5.58-2.78,8.15-4.06
		c6.7,7.48,12.93,14.44,19.71,22c-6.21,0.84-6.49-4.86-10.1-6.53C590.08,468.79,590.75,469.72,590.84,470.71z M566.22,490.93
		C566.22,490.93,566.22,490.93,566.22,490.93L566.22,490.93C566.22,490.94,566.22,490.93,566.22,490.93
		c-0.04-0.02-0.08-0.03-0.12-0.05c-1.43,0.78-2.86,1.57-4.26,2.33c-1.41,0.77-2.79,1.52-4.08,2.23c-2.23-1.52-4.24-2.9-6.39-4.37
		c0.88-0.49,1.96-0.84,2.69-1.55c2.66-2.57,5.32-0.98,7.97-0.16c1.38,0.43,2.72,1,4.08,1.51c0,0,0,0,0,0c0,0,0,0,0,0
		c0.02-0.01,0.03-0.02,0.05-0.03c-4.55-4.92-11.27-5.15-16.99-0.48c-0.38,0.31-0.76,0.65-1.14,1c2.43,1.84,4.81,3.64,7.19,5.44
		c-0.13,0.24-0.26,0.47-0.38,0.71c-3.72-2.05-7.44-4.1-11.14-6.14c-0.07-1.11-0.14-2.2-0.22-3.53c1.52,0.31,2.94,0.6,4.79,0.98
		c-0.32-0.53-0.58-0.95-0.81-1.33c-0.22-0.36-0.41-0.67-0.6-0.99c-0.03,0.01-0.06,0.01-0.1,0.02l0.07-0.07c0,0-0.07,0.07-0.07,0.07
		c-0.03-0.03-0.07-0.07-0.1-0.1c-0.43-0.08-0.86-0.17-1.3-0.25c0.12-0.23,0.24-0.46,0.36-0.69c0.31,0.31,0.63,0.63,0.94,0.94
		c0,0,0,0,0,0l0,0c0.06,0.01,0.11,0.02,0.17,0.03c0.01,0.02,0.02,0.03,0.03,0.05c0,0,0,0,0,0c0,0,0,0,0,0
		c1.82-0.38,3.65-0.76,5.71-1.19c-0.37-0.72-0.77-1.48-1.35-2.59c1.6,0.22,2.95,0.56,4.29,0.53c1.05-0.02,2.08-0.51,3.63-0.93
		c3.21,2.09,6.87,4.47,11.14,7.25c-1.24,0.39-2.24,0.7-3.18,0.99c-0.31,0.1-0.62,0.19-0.93,0.29
		C566.15,490.85,566.22,490.94,566.22,490.93z M541.99,486.06c-0.02,0.18-0.03,0.36-0.05,0.54c-1.04-0.21-2.07-0.46-3.12-0.61
		c-0.88-0.13-1.78-0.16-2.67-0.23c0,0.02,0.01,0.05,0.01,0.07c0.05,0.03,0.06,0.05,0.09,0.06c0.04,0.03,0.05,0.03,0,0
		c-0.02-0.02-0.05-0.03-0.09-0.06c0.01,0.1,0.02,0.16,0.02,0.13c0.01,0.04,0-0.03-0.02-0.13c-0.03-0.02-0.07-0.05-0.11-0.07
		l0.05-0.03c0.02-0.01,0.04-0.03,0.04-0.03c0,0,0,0,0,0c-1.98-0.89-3.96-1.78-5.94-2.67c-2.57-1.18-5.13-2.36-8.1-3.73
		c1.22-0.7,1.87-1.08,2.7-1.56c1.97,1.53,3.97,3.07,5.96,4.61c1.02,0.48,2.04,0.97,2.74,1.3c3.81-2.79,7.33-5.37,11.57-8.48
		c-2.5-1.26-5.01-2.51-7.51-3.77c-0.01,0-0.02,0.01-0.03,0.01l-0.05-0.06c0,0,0.05,0.06,0.05,0.06c0,0,0,0,0,0
		c1.25,1.43,2.5,2.86,3.76,4.3c-2.43,1.78-5.21,3.81-8.04,5.89c-2.43-1.6-4.72-3.11-7.53-4.96c1.04-0.46,2.07-0.91,3.07-1.36
		c3.01-1.33,5.88-2.6,8.74-3.87c-0.02-0.02-0.03-0.04-0.05-0.06c0.03,0.01,0.05,0.03,0.08,0.04c1.1-0.54,2.2-1.07,3.18-1.55
		c3.19,2.15,6.16,4.14,8.95,6.01c0.28,1.34,0.55,2.65,0.87,4.18c-1.08-0.22-2.38-0.48-4.23-0.85c-0.14,0.84-0.28,1.69-0.42,2.54
		c0.42,0.12,0.85,0.24,1.27,0.36c-0.25,0.28-0.51,0.56-0.76,0.84c-0.37-0.24-0.73-0.48-1.1-0.71l0,0c-0.63-0.09-1.26-0.18-2.08-0.29
		c-0.27-0.04-0.57-0.08-0.89-0.13c0.27,0.39,0.51,0.75,0.73,1.09c0.23,0.34,0.44,0.66,0.66,0.98c0.57,0.32,1.15,0.64,1.72,0.96
		c-0.09,0.19-0.18,0.38-0.26,0.58c-0.67-0.34-1.35-0.69-2.02-1.03c-1.35-0.37-2.71-0.74-4.06-1.11c-0.1,0.19-0.2,0.39-0.3,0.58
		C539.88,484.59,540.93,485.32,541.99,486.06z M565.73,461.43c-0.17-0.18-0.31-0.34-0.42-0.46c-1.48,1.23-3.2,2.66-4.93,4.09
		c-0.15-0.17-0.29-0.35-0.44-0.52c-0.2,0.85-0.4,1.7-0.61,2.55c-0.2,0.01-0.39,0.03-0.59,0.04c-0.26-0.89-0.51-1.79-0.71-2.49
		c-1.16-0.4-2.22-0.76-3.27-1.11c0,0.01,0,0.03,0,0.04l-0.06-0.06c0,0,0.06,0.06,0.06,0.06c0,0,0,0,0,0c-0.03,0-0.07,0-0.1,0
		c-0.35,0.62-0.77,1.15-1.23,1.58c-2.07,1.91-5.02,1.83-7.12-0.47l0,0c0,0,0,0,0,0c0.46-0.4,0.91-0.8,1.12-0.99
		c0.07-0.06,0.11-0.1,0.12-0.11c2.74,0,4.93,0,7.11,0c0.01-0.02,0.03-0.04,0.04-0.06c0.02,0.01,0.04,0.01,0.06,0.02
		c0-1.36,0-2.72,0-4.02c-1.46,0.91-3.01,1.88-4.85,3.02c-0.7-1-1.25-1.79-2.03-2.92c3.87-1.86,7.51-3.62,11.25-5.42
		c0.91,0.85,1.76,1.64,2.89,2.7c-0.65,1.09-1.3,2.17-2.54,4.23c-0.34-2.2-0.5-3.25-0.7-4.51c-0.56,0-1.17,0-1.38,0
		c0.42,1.48,0.83,2.91,1.45,5.09c1.59-1.37,3.09-2.66,5.15-4.43c-0.38-0.07,0.28,0.05,1.04,0.19c0.52-0.55,1.18-1.25,2.11-2.24
		c2.17,1.71,4.14,3.27,5.95,4.7c-0.76,1.82-1.36,3.25-2.23,5.31c-1.13-0.6-2.22-1.17-3.31-1.75c0.02,0.02,0.04,0.04,0.06,0.07
		l-0.06-0.07c0,0,0.06,0.07,0.06,0.07c-0.02,0-0.03,0.01-0.05,0.01c0.44,3.28,1.19,3.6,5.4,2.35c-0.93,2.97-1.31,3.12-6.71,2.49
		c2.93,3.63,7.03,2.11,8.79-2.39c-0.93-2.03-1.55-3.36-2.16-4.7c0.22-0.13,0.45-0.27,0.67-0.4c2.12,2.28,4.23,4.57,6.57,7.09
		c-4.82,2.11-9.95,4.35-15.45,6.76c0.11-2.41,0.22-4.91,0.37-8.17c-0.02-0.01-0.83-0.4-1.66-0.81c0,0.01-0.01,0.02-0.01,0.04l0,0
		c-0.02,0-0.05,0-0.07,0c0,3.66,0,7.32,0,11.38c-3.48-2.05-6.71-3.96-10.18-6.01c0.85-2.09,1.65-4.07,2.62-6.48
		c1.13,1.49,2.05,2.72,3.38,4.46c0.69-1.28,1.18-2.19,1.81-3.36c0.15,0,0.33,0,0.51,0c0.54,0,1.2,0,1.86,0c0-0.02,0-0.05,0-0.07
		c0.03,0.01,0.05,0.03,0.08,0.04c0.31-1.45,1.18-1.85,2.18-2c0.66-0.1,1.37-0.1,2.01-0.22c0-0.03-0.01-0.05-0.01-0.08
		C566.89,462.7,566.21,461.96,565.73,461.43z M561.14,454.65c0.01-0.5,0.02-1,0.02-1.5c1.22-0.61,2.43-1.22,3.97-1.99
		c0.47,0.92,0.9,1.76,1.91,3.75C564.47,454.8,562.81,454.73,561.14,454.65z M563.8,480.84c-0.02,0.11-0.05,0.22-0.07,0.33
		c-0.35-0.05-0.7-0.11-1.06-0.16c0.06-0.2,0.11-0.4,0.17-0.59C563.16,480.56,563.48,480.7,563.8,480.84z M562.53,479.66
		c-0.11,0.22-0.22,0.45-0.33,0.67c-0.57-0.25-1.13-0.49-1.7-0.74c0.07-0.19,0.13-0.38,0.2-0.57
		C561.31,479.24,561.92,479.45,562.53,479.66z M559.97,477.86c-0.09,0.22-0.17,0.44-0.26,0.65c-0.6-0.22-1.2-0.43-1.8-0.65
		c0.18-0.26,0.37-0.51,0.55-0.77C558.96,477.35,559.46,477.6,559.97,477.86z M568.62,480.95c1.18-0.88,2.06-1.54,2.97-2.22
		c0.85,1.74,1.68,3.46,2.52,5.17c-0.2,0.2-0.39,0.4-0.59,0.59C571.98,483.38,570.43,482.26,568.62,480.95z M573.18,478.27
		c1.07-0.46,2.07-0.89,3.04-1.3c1.72,2.75,3.3,5.3,5.04,8.09c-0.48,0.8-1.07,1.77-1.73,2.85
		C577.4,484.67,575.37,481.6,573.18,478.27z M558.03,476.67c-0.17,0.27-0.33,0.54-0.5,0.8c-4.63-2.69-9.27-5.38-14.29-8.29
		c0.82-0.55,1.44-0.96,2.08-1.39C549.61,470.78,553.82,473.72,558.03,476.67z M535.08,463.21c0.94,1.48,1.84,2.88,2.79,4.38
		c-0.81,0.28-1.71,0.58-3.03,1.03c-0.92-5.42-1.84-10.78-2.79-16.36c1.82-1.08,3.88-2.31,5.83-3.47c0.55,3.84,1.07,7.52,1.67,11.69
		C538.39,461.18,536.95,462.06,535.08,463.21z M533.17,469.23c-1.2,0.65-2.91,1.58-4.61,2.51c-1.18-4.75-2.37-9.57-3.65-14.72
		c1.49-1.06,3.17-2.26,5.19-3.69C531.16,458.79,532.15,463.92,533.17,469.23z M525.78,473.35c-1.4,1.12-2.91,2.34-4.29,3.45
		c-0.62-2.62-1.25-5.17-1.84-7.73c-0.6-2.59-1.15-5.19-1.75-7.89c2-1.15,3.78-2.18,5.56-3.21
		C524.25,463.14,524.98,468.04,525.78,473.35z M518.11,470.48c0.51,2.58,1.09,5.14,1.68,7.87c-1.42,0-3.21,0-4.7,0
		c-1.05-4.33-2.06-8.54-3.15-13.05c1.29-0.87,2.89-1.94,4.67-3.13C517.13,465.08,517.58,467.79,518.11,470.48z M512.97,479.16
		c-0.83,0.15-2.27,0.42-3.92,0.73c-0.74-3.59-1.5-7.27-2.29-11.1c1.19-0.65,2.37-1.29,3.51-1.91
		C511.26,471.34,512.12,475.25,512.97,479.16z M506.92,481.24c-1.27,0.85-2.69,1.8-4.17,2.79c-0.63-4.12-1.25-8.17-1.86-12.21
		c1.43-0.69,2.65-1.29,3.96-1.92C505.55,473.79,506.23,477.48,506.92,481.24z M502.85,488.47c0.83-0.74,1.63-1.47,2.43-2.18
		c0.48-0.43,0.96-0.86,1.43-1.29l0,0c0,0,0,0,0,0c0,0-0.08,0.08-0.08,0.08c0.07,0.03,0.13,0.05,0.2,0.08
		c-0.04-0.05-0.07-0.1-0.11-0.15l0,0c0.05-0.04,0.1-0.08,0.15-0.11c0.69-0.53,1.35-1.12,2.03-1.68c0.25-0.21,0.51-0.41,0.77-0.6
		c0.62-0.46,1.26-0.84,1.96-1.08c5.01-1.72,9.78-0.78,14.21,2.22c1.71,1.16,3.68,1.94,5.56,2.92c-0.36,0.48-0.74,0.97-1.51,2
		c1.49-0.21,2.33-0.33,3.02-0.43c1.94,1.58,3.81,3.09,5.95,4.83c0.28-1.01,0.45-1.63,0.72-2.57c5.06,3.22,10.02,6.38,14.99,9.54
		c-0.08,0.32-0.16,0.64-0.25,0.95c-3.78-0.24-7.57-0.48-11.35-0.72l0,0c-1.93-1.24-3.86-2.49-5.79-3.73
		c-0.22,0.36-0.43,0.72-0.65,1.08c1.98,1.08,3.96,2.16,5.94,3.24h0c0.06-0.07,0.12-0.14,0.17-0.2c-0.06,0.07-0.12,0.14-0.17,0.2
		c-0.21,0.17-0.42,0.34-0.62,0.51c-0.61,0.5-1.18,0.98-1.6,1.32c-2.52-1.47-4.75-2.78-6.99-4.09c-0.19,0.16-0.39,0.33-0.58,0.49
		c1.78,1.5,3.55,3.01,5.34,4.53c-1.33,1.77-1.41,1.75-4.25-0.46c-1.42-1.1-2.87-2.15-4.31-3.23c-0.23,0.26-0.45,0.51-0.68,0.77
		c2.11,1.97,4.21,3.93,6.42,5.99c-0.54,0.71-0.96,1.25-1.37,1.79c-2.61-1.73-5.17-3.43-7.73-5.12c-0.2,0.26-0.4,0.52-0.6,0.78
		c2.39,1.93,4.78,3.86,7.17,5.79c-0.15,0.35-0.31,0.7-0.46,1.05c-0.94-0.3-1.99-0.43-2.8-0.94c-7.39-4.62-14.68-9.39-22.13-13.92
		c-2.42-1.47-5.2-2.36-8.6-3.87C500.15,490.9,501.53,489.66,502.85,488.47z M511.15,502.05L511.15,502.05
		C511.15,502.05,511.15,502.05,511.15,502.05c3.48,2.06,6.95,4.13,10.43,6.19c1.01,0.54,2.02,1.08,3.03,1.63
		c-0.13,0.24-0.26,0.49-0.39,0.73c-1.1-0.6-2.2-1.21-3.3-1.81c-3.51-2.04-7.02-4.08-10.52-6.13c-0.66-0.42-1.31-0.84-1.97-1.26
		c0,0,0,0,0,0c-1.09-0.65-2.17-1.3-3.26-1.94l0,0c-0.41-0.1-0.82-0.21-1.23-0.31c0.03-0.16,0.06-0.32,0.1-0.48
		c0.51,0.09,1.02,0.18,1.53,0.27c0,0,0,0,0,0c1.12,0.59,2.25,1.18,3.37,1.77C509.68,501.14,510.41,501.6,511.15,502.05
		L511.15,502.05L511.15,502.05z M525,510.44c0.77,0.37,1.54,0.74,2.31,1.1c-0.1,0.24-0.2,0.48-0.3,0.72
		c-0.81-0.35-1.62-0.71-2.43-1.06C524.72,510.95,524.86,510.7,525,510.44z M496.83,490.26c-0.37-2.96-0.68-5.42-0.97-7.88
		c-0.31-2.56-0.59-5.11-0.88-7.62c1.45-0.69,2.57-1.23,4.01-1.92c0.58,4.43,1.13,8.64,1.75,13.37
		C499.67,487.32,498.45,488.58,496.83,490.26z M496.97,497.61c12.11,5.61,21.38,15.24,34.3,18.3c1.01,0.8,2.03,1.61,4.09,3.24
		c-5.59,1.1-10.39,2.97-15.11,2.77c-7.07-0.3-14.09-1.93-21.08-3.27c-1.73-0.33-3.47-1.48-4.84-2.67c-3.81-3.34-4.48-6.53-2.17-11
		C493.59,502.18,495.59,499.69,496.97,497.61z M490.99,496.33c-0.82-6.1-1.62-12.1-2.44-18.23c1.42-0.91,2.65-1.7,4.06-2.6
		c0.65,6.41,1.27,12.47,1.9,18.62C493.52,494.74,492.44,495.42,490.99,496.33z M499.46,552.02c-0.39,0.04-0.77,0.08-1.16,0.12
		c-0.94-6.87-1.88-13.74-2.82-20.61c0.26-0.05,0.53-0.09,0.79-0.14c1.18,1.27,2.37,2.55,3.18,3.43
		C499.46,540.81,499.46,546.41,499.46,552.02z M527.83,558.79c-0.58,0-1.17,0-1.75,0c-1.36-8.39-2.71-16.78-4.13-25.53
		c1.83,0,3.02,0,4.54,0C527.76,541.87,528.75,550.31,527.83,558.79z M538.65,525.68c-4.26,1.01-8.51,2.59-12.86,2.92
		c-7.96,0.6-16.06,2.01-23.9-0.65c-3.54-1.2-7.01-2.8-10.2-4.73c-1.32-0.8-1.82-2.95-2.86-4.79c16.81,7.11,33.13,7.86,49.91,2.58
		c0.07,0.7,0.19,1.39,0.19,2.08C538.92,523.78,538.79,524.47,538.65,525.68z M533.99,513.68c5.07-3,9.67-5.7,14.25-8.43
		c1.17-0.7,2.26-1.53,3.43-2.24c0.76-0.46,1.77-1.3,2.37-1.1c5.61,1.91,9.49-1.96,13.79-4.1c3.27-1.62,6.21-3.92,10.05-6.41
		c-1.84-0.47-2.73-0.7-3.62-0.93c0.03-0.28,0.06-0.55,0.09-0.83c1.83,0.26,3.67,0.51,5.99,0.84c3.16-2.18,5.56-8.85,12.18-2.84
		c-16.71,11.2-34.17,20.71-52.51,29.12C538.03,515.74,536.31,514.86,533.99,513.68z M571.03,507.22c-1.99,1.7-4.02,3.46-6.09,5.18
		c-4.06,3.37-8.49,5.73-14.01,5.68c-1.33-0.01-2.75,0.89-3.99,1.6c-1.67,0.97-3.22,2.16-5.07,3.44c0-1.41,0-2.35,0-2.57
		c15.49-8.13,30.64-16.09,45.98-24.15C585.61,503.93,578.63,510.12,571.03,507.22z M587.86,495.56c3.3-1.99,6.6-3.98,9.89-5.96
		c0.15,0.28,0.31,0.56,0.46,0.84c0.98-1.52,1.97-3.04,3.15-4.87C603.1,493.16,597.84,497.18,587.86,495.56z M594.49,485.88
		c-1.6-0.76-3.44-1.64-5.35-2.54c4.28-3.06,8.45-6.04,12.7-9.07c-4.19-5.08-7.79-9.43-11.39-13.79c0.14-0.3,0.29-0.6,0.43-0.89
		c5.25,2.55,10.51,5.09,15.75,7.64C605.67,475.36,599.49,480.09,594.49,485.88z M606.74,498.21c1-1.38,1.76-2.41,2.92-3.99
		c0.46,5.12,0.88,9.72,1.38,15.25C606.76,505.94,609.44,501.05,606.74,498.21z M605.02,488.61c-0.19-1.82-0.34-3.29-0.46-4.46
		c3.96-5.14,7.81-10.16,12.04-15.66C615.2,476.91,611.41,483.3,605.02,488.61z M663.97,410.33c-2.69,0.72-6.85-0.41-7.42,3.46
		c-0.3,2.04,1.95,4.45,3.29,7.18c-14.69,8.35-31.04,16.05-44.94,27.64c-2.4-0.9-4.65-1.75-7.06-2.66c0-1.59,0-3.34,0-5.41
		c18.54-11.4,37.21-22.87,55.66-34.21C663.62,407.34,663.79,408.83,663.97,410.33z M658.74,389.24c-0.9,3.27,1.78,5.3,4.43,6.82
		c-0.12,2.11-0.23,4.17-0.33,5.96c-4.9,3.11-9.79,6.22-14.67,9.31c-4.88,3.1-9.75,6.19-14.61,9.28c-0.37,0.24-0.74,0.48-1.11,0.71
		c-0.25-0.28-0.5-0.56-0.75-0.84c0.43-0.12,0.85-0.25,1.28-0.37c0.19-0.61,0.38-1.22,0.57-1.82c-0.2-0.11-0.41-0.23-0.61-0.34
		c-1.3,1.7-2.67,3.35-3.9,5.1c-1.73,2.45-4.09,3.06-6.78,2.26c-2.46-0.73-4.84-1.73-7.26-2.63c0.83-0.37,1.55-1.03,2.33-1.63
		c1.33-1.04,2.81-1.89,5.28-0.77c-0.89-2.17-1.59-3.9-2.29-5.62c-0.3,0.13-0.61,0.27-0.91,0.4c0.35,0.81,0.7,1.62,1.18,2.73
		c-2.84,1.36-5.6,2.68-8.75,4.19c0,0.01-0.08,0.19-0.19,0.46c-0.04,0.1-0.08,0.21-0.13,0.32c-0.08,0.19-0.16,0.39-0.24,0.6
		c-0.01,0.02-0.02,0.05-0.03,0.07c1.22-0.24,2.45-0.47,3.67-0.71c0.01,0,0.01-0.01,0.02-0.01c0.7,0.72,1.3,1.64,2.15,2.07
		c1.82,0.9,3.78,1.52,5.68,2.26c-5.33,3.09-10.43,6.04-16.04,9.3c-0.22-3.35-0.43-6.44-0.61-9.13c0.49-0.36,0.96-0.71,1.41-1.05
		c1.34-1,2.55-1.89,3.75-2.79c0,0,0-0.01,0.01-0.01c-1.34,0.54-2.67,1.09-4.07,1.66c-0.47,0.19-0.95,0.38-1.43,0.58
		c-0.7-5.11-1.38-10.06-2.08-15.13c1.95-0.99,3.87-1.96,6.22-3.15c-2.35,4.88-1.71,9.98,1.69,13.25c-2.55-4.67-2.2-9.32,0.07-14.52
		c2.9,0.77,5.97,1.58,9.04,2.4c0,0,0,0,0,0c-2.25-1.22-4.5-2.45-6.82-3.71c3.31-1.93,7.04-4.11,11.08-6.47
		c-0.62,3.79-1.19,7.21-1.76,10.72c-0.86-0.21-1.72-0.42-2.59-0.63c0,0,0.01,0.01,0.01,0.01c0,0-0.01-0.01-0.01-0.01
		c0,0,0.02,0.02,0.05,0.04c0.03,0.02,0.05,0.05,0.05,0.05c0,0,0,0,0,0c0.65,0.61,1.31,1.23,2.14,2.01c0.31,0.29,0.63,0.59,0.99,0.93
		c1.23-16.18,5.71-16.88,14.8-15.47c-1.58-0.76-3.16-1.52-4.88-2.35c10.39-5.77,20.72-11.5,30.79-17.08
		c0.68,2.98,1.35,5.91,2.02,8.87C663.59,385.38,659.8,385.39,658.74,389.24z M677.68,370.15c0.19,2.16,0.38,4.33,0.56,6.49
		c0.1-0.02,0.2-0.05,0.31-0.07c0,4.06,0,8.11,0,11.97c-3.16-1.03-6.81-2.21-10.46-3.39c2.82-3.53,0.42-7.42,0.64-11.23
		c1.43-0.73,2.85-1.45,4.25-2.16c1.4-0.71,2.8-1.42,4.19-2.13c0.31-1.05,0.62-2.1,0.93-3.16c-0.23-0.28-0.47-0.56-0.7-0.84
		c-2.24,1.06-4.56,2-6.72,3.22c-15.57,8.77-31.14,17.53-46.63,26.43c-12.81,7.36-25.46,15.01-38.3,22.31
		c-5.64,3.2-11.71,5.64-17.37,8.8c-10.9,6.09-21.64,12.45-32.45,18.68c-17.65,10.17-34.13,22.32-52.67,30.97
		c-0.42,0.2-0.76,0.57-1.13,0.87c-7.79,3.95-15.58,7.9-23.37,11.85c-5.3,3.81-10.61,7.61-15.89,11.46c-1.33,0.97-2.55,2.11-4.3,3.57
		c-0.3-2.81-2.32-4.73-0.09-7.84c1.34-1.87,0.86-5.19,0.84-7.85c-0.04-3.5-0.13-6.99-0.23-10.49c-0.05-1.8-0.11-3.6-0.17-5.4
		c-0.05-1.7-0.11-3.39-0.15-5.09c0-6.14,0-12.29,0-18.42c0-6.13,0-12.25,0-18.35c0.87,0.39,1.65,0.73,2.37,1.05
		c1.2,0.53,2.24,0.99,3.28,1.45c0.44-0.12,0.88-0.24,1.33-0.36c0.45-0.12,0.91-0.25,1.38-0.38c-0.12,4.31-0.25,8.61-0.37,12.91
		c-0.37,12.88-0.73,25.7-1.1,38.51c0.38,1.47,0.76,2.94,1.21,4.69c0.15,0.58,0.31,1.2,0.48,1.86c0.76-0.44,1.49-0.86,2.19-1.26
		c2.1-1.2,3.95-2.27,5.79-3.33c7.62-3.81,15.25-7.62,22.87-11.43c13.28-7.8,26.62-15.5,39.82-23.44
		c10.56-6.35,20.88-13.09,31.43-19.46c9.59-5.79,19.49-11.09,29.01-16.99c12.31-7.63,24.26-15.85,36.61-23.4
		c7.84-4.79,16.13-8.86,24.24-13.22c10.72-5.77,21.5-11.45,32.17-17.3c2.26-1.24,4.19-3.08,6.76-5.01
		c0.42,4.99,0.83,9.04,1.03,13.11C679.26,368.93,678.22,369.55,677.68,370.15z M678.04,351.74c-1.19,0.59-2.28,1.14-3.34,1.67
		c-0.35,0.18-0.71,0.35-1.06,0.53c-1.43,0.95-2.87,1.9-4.3,2.85c-1.19,0.72-2.39,1.44-3.58,2.16c-1.44,0.69-2.88,1.37-4.32,2.06l0,0
		v0c-6.2,3.39-12.4,6.77-18.6,10.16c-1.46,0.88-2.92,1.76-4.38,2.64c-9.99,5.56-19.98,11.12-29.97,16.69
		c-0.71,0.47-1.42,0.94-2.13,1.41c-1.42,0.94-2.83,1.89-4.25,2.84c-4.24,2.85-8.49,5.7-12.84,8.37c-1.45,0.89-2.91,1.76-4.39,2.61
		s-2.97,1.66-4.48,2.45c-2.27,1.18-4.58,2.28-6.96,3.29c-2.44,1.43-4.88,2.86-7.32,4.3c-7.32,4.31-14.63,8.62-22,12.83
		c-6.98,3.99-14.03,7.86-21.08,11.74c-2.35,1.29-4.7,2.59-7.04,3.89c-1.18,0.74-2.36,1.48-3.54,2.22
		c-10.42,5.98-20.79,12.03-31.26,17.92c-8.64,4.86-17.4,9.51-26.1,14.25c-1.41,1.2-2.83,2.39-4.83,4.09c0-2.5,0-4.41,0-6.32
		c0.41-9.08,0.93-18.16,1.2-27.24c0.19-6.19,0.04-12.4,0.04-18.63c1.82-1.08,3.56-2.11,5.31-3.15c5.74,2.32,11.74,3.32,18.02,2.02
		c0.98-0.06,1.97-0.13,2.95-0.19c-0.87,0.23-1.74,0.47-2.61,0.7c-3.62,1.23-7.24,2.47-11.16,3.8c0.86,3.51,1.61,6.61,2.38,9.74
		c-0.48,0.3-0.92,0.49-1.25,0.8c-2.14,2-5.65,2.86-5.73,6.58c-0.09,4.41-0.06,8.83-0.01,13.24c0.03,2.52,1.7,4.49,3.89,3.66
		c6.1-2.31,11.94-5.28,17.89-7.99c-0.35-0.29-0.7-0.58-1.05-0.87c-0.28-1.72-0.55-3.44-0.82-5.08c1.76-0.63,3.04-1.09,4.43-1.58
		c0-3.12-0.18-6.1,0.05-9.06c0.31-3.95,0.94-7.87,1.43-11.81c-0.44-0.76-0.88-1.52-1.27-2.19c-2.1,0-3.93,0-5.75,0
		c2.46-0.71,4.94-1.36,7.4-2.09c0.83-0.25,1.66-0.5,2.49-0.77c7.2-2.34,12.56-6.56,14.35-14.37c0.9-3.95,2.53-7.81,2.85-11.79
		c0.49-5.98,0.31-12.04,0.05-18.05c-0.27-6-0.95-11.98-1.53-17.97c-0.9-9.36-1.85-18.71-2.82-28.06c-0.35-3.37-0.81-6.73-1.22-10.09
		c-0.98-6.66-1.96-13.31-2.95-19.97c-1.81-2.84-3.61-5.69-5.42-8.53c-6.37-5.18-13.49-5.51-21.1-3.38
		c3.66-2.24,7.34-4.43,10.97-6.72c9.09-5.73,18.12-11.56,27.25-17.23c3.34-2.08,6.88-3.83,10.34-5.73l0,0
		c1.9-0.98,3.81-1.96,5.71-2.94c2.01-1.35,4-2.74,6.05-4.03c9.56-6.04,19.11-12.08,28.71-18.05c0.64-0.4,1.28-0.78,1.93-1.15
		c1.95-1.12,3.95-2.16,5.92-3.25c0.83-0.48,1.65-0.95,2.48-1.43c1.05-0.61,2.11-1.21,3.16-1.82c1.83-1.05,3.65-2.11,5.48-3.16v0l0,0
		l0,0c1.21-0.7,2.42-1.4,3.62-2.1c2.99-1.91,5.97-3.84,8.97-5.72c6.93-4.35,13.86-8.69,20.82-12.99c0.96-0.59,2.08-0.94,3.12-1.4
		c3.83-1.45,7.66-2.9,11.49-4.35c9.21-5.27,18.6-10.25,27.57-15.9c6.45-4.07,12.22-9.19,18.48-13.57
		c7.84-5.47,15.9-10.61,23.81-15.99c4.14-2.82,8.15-5.84,12.78-9.17c0.47,4.6,0.71,8.18,1.22,11.72c1.37,9.48,2.58,19,4.37,28.4
		c2.07,10.89,4.6,21.71,7.13,32.51c0.95,4.06,2.76,7.92,3.69,11.99c1.18,5.15,5.38,6.9,8.3,8.61c0.65,2.83,0.98,4.98,1.65,7.01
		c0.78,2.33,2.1,4.49,2.75,6.84c0.74,2.65,1,5.42,1.47,8.14c-0.42,0.14-0.84,0.27-1.26,0.41c-4.51-5.14-8.93-10.37-13.58-15.39
		c-3.29-3.55-6.63-7.18-10.49-10.05c-2.11-1.57-5.34-1.62-7.79-2.29c-3.91,6.02-1.36,10.5,2.08,14.03
		c5.59,5.74,11.75,10.95,17.81,16.22c4.64,4.04,9.5,7.83,14.76,12.14c-3.96,1.19-6.82,2.14-9.72,2.91
		c-9.23,2.47-11.32,11.5-10.15,19.41c0.15,1.04,0.2,2.09,0.29,3.14c0.3,0.49,0.61,0.97,0.71,1.14c-6.25,3.59-12.28,7.24-18.49,10.55
		c-2.55,1.36-3.34,3.38-3.56,5.87c-0.36,3.92-0.59,7.86-0.88,11.77c-5.62,3.28-11.91,6.53-17.68,10.52
		C678.97,344.75,677.06,347.93,678.04,351.74z M693.78,374.36c-0.2,0.08-0.4,0.16-0.6,0.25c-0.23-0.48-0.46-0.97-0.7-1.45l0,0
		c-3.64-1.68-7.28-3.36-10.92-5.04c2.51-5.52-1.14-11.86,0.21-18.43c3.97,2.15,7.91,4.28,12.03,6.52
		c-0.25,5.48-0.49,10.87-0.73,16.26C693.31,373.09,693.55,373.72,693.78,374.36z M698.2,375.76c1.68-0.97,3.37-1.95,5.27-3.06
		c0.36,3.6,0.68,6.9,1.01,10.23C699.04,382.85,697.89,381.48,698.2,375.76z M704.51,348.24c-0.66,6.66-1.32,13.32-2.03,20.48
		c-1.18,0.79-2.87,1.93-5.28,3.55c0-6.58,0-12.58,0-19.07c1.9-1.53,4.36-3.5,6.81-5.48l0,0c0.22-0.27,0.44-0.54,0.67-0.8
		c0.12,0.07,0.24,0.13,0.35,0.2C704.85,347.48,704.68,347.86,704.51,348.24z M699.9,345.59l-0.13,0.1l-0.13,0.1
		c-0.23,0.16-0.46,0.31-0.69,0.47c-0.42,0.4-0.85,0.81-1.29,1.23c-0.45,0.43-0.91,0.87-1.42,1.36c-3.54-1.39-7.15-2.81-11.52-4.53
		c5.22-3.05,9.77-5.84,14.48-8.35c0.61-0.33,1.45-0.24,2.3-0.12c0.51,0.07,1.03,0.16,1.51,0.17c0.42,0.24,0.84,0.48,1.28,0.73
		c1.32,0.75,2.81,1.6,4.98,2.84C705.61,341.93,702.75,343.76,699.9,345.59z M685.25,415.42c0.78-6.89,4.6-11.23,11.41-14.89
		c1.14-0.61,2.35-1.2,3.66-1.78s2.69-1.15,4.15-1.73h0l0,0c0.33,0.04,0.65,0.09,0.99,0.13c1,0.13,2.04,0.27,3.28,0.43
		c-0.24,0.3-0.46,0.58-0.66,0.84c-0.61,0.78-1.09,1.39-1.58,2c-0.24,0.22-0.47,0.45-0.71,0.67c0,0-0.03,0.02-0.03,0.02
		c-0.47,1.42-0.94,2.83-1.42,4.25c-1.42,4.26-2.85,8.55-4.33,12.98c-1.43-0.63-3.19-1.41-4.94-2.19c-1.88-0.25-3.76-0.49-5.64-0.74
		C688.04,415.42,686.66,415.42,685.25,415.42z M688.25,420.59c1.47,0,2.99,0,4.46,0c3.06,4.27,2.64,5.91-1.82,8.58
		C686.05,425.53,685.61,424.12,688.25,420.59z M689.34,435.05c6.64-0.4,8.54-5.2,9.58-11.03c2,4.95,0.12,11.88-3.69,14.69
		C693.03,440.34,691.2,439.3,689.34,435.05z M693.39,454.84c0.19-0.25,0.39-0.5,0.58-0.75c4.03,1.38,5.93,0.53,7.75-3.11
		c0.92-1.83,0.97-3.04-1.51-3.77c-0.97,1.26-1.81,2.35-2.75,3.57c-1.95-1.28-3.5-2.54-1.53-4.78c1.63-1.86,3.44-2.72,5.72-0.67
		c4.86,4.37,4.23,8.17-2.89,13.61C697.08,457.65,695.24,456.25,693.39,454.84z M700.88,486.06c-0.58,0.32-1.58,0.88-2.58,1.44
		c-0.26,0.07-0.52,0.14-0.77,0.21c0.12-0.21,0.24-0.42,0.36-0.63c-0.09-0.88-0.18-1.76-0.28-2.69c-0.06-0.62-0.13-1.26-0.2-1.93
		c-0.1-1.01-0.22-2.1-0.34-3.31c0.56-0.2,1.83-0.67,3.1-1.14c0.24,1.24,0.57,2.47,0.68,3.72
		C700.97,483.01,700.88,484.3,700.88,486.06z M721.28,476.47c-1.28,3.12-2.42,6.29-3.61,9.39c-0.11-0.09-0.24-0.19-0.38-0.3
		c-0.22-0.17-0.47-0.37-0.72-0.57c-0.24-0.19-0.48-0.38-0.73-0.57c-0.92-0.45-1.6-0.8-2.07-1.17c-0.43-0.34-0.67-0.69-0.72-1.12
		c-0.1-0.89,0.61-2.13,2.05-4.4c-1.16-0.43-2.31-0.85-3.47-1.28c-0.46,0.65-0.91,1.29-1.3,1.95c-0.2,0.33-0.38,0.66-0.55,1
		c-0.11,0.22-0.2,0.44-0.29,0.66c-0.19,0.47-0.34,0.95-0.43,1.44c-0.03,0.18-0.06,0.37-0.07,0.55s-0.02,0.37-0.02,0.56
		c0,0.23,0.02,0.46,0.05,0.69c0.07,0.55,0.22,1.12,0.46,1.72c-0.37,1.09-0.74,2.18-1.1,3.28c-0.39-0.07-0.77-0.15-1.16-0.22
		c-0.65-1.75-1.31-3.5-1.96-5.26c0.57-0.7,1.15-1.4,1.72-2.1c0.58-0.72,1.17-1.44,1.75-2.15c-0.71-0.47-1.42-0.95-2.13-1.42
		c-0.68-0.46-1.37-0.92-2.05-1.38c-1.45-3.13-2.89-6.26-4.34-9.4c-0.21-0.31-0.42-0.63-0.63-0.94c-0.63-0.93-1.23-1.82-1.74-2.58
		c2.36-1.17,4.73-2.36,7.33-3.65c0.4,1.64,0.9,3.7,1.4,5.76c0.77,0.49,1.53,0.98,2.56,1.64c0.35-0.7,0.67-1.35,0.98-1.98
		s0.61-1.23,0.91-1.84c-0.82-3.55-1.64-7.1-2.47-10.66c0.29-0.07,0.59-0.13,0.88-0.2c0.94,3.62,1.87,7.24,2.81,10.85
		c0.69,0.76,1.37,1.52,2.55,2.82c0.26-0.78,0.48-1.41,0.67-1.97c0.19-0.56,0.36-1.06,0.53-1.55c-0.08-0.23-0.16-0.46-0.24-0.7
		c-0.3-0.84-0.61-1.67-0.92-2.51c-0.66-1.78-1.29-3.56-1.59-5.4c-0.32-1.94,0.54-4.08,1.36-5.89c1.17,4.6,2.34,9.19,3.5,13.79
		C721.69,464.48,723.2,471.79,721.28,476.47z M718.13,447.4c1.74-0.17,2.72-0.27,3.89-0.39c0.38,2.6,0.72,4.99,1.19,8.21
		C718.3,453.98,720.16,449.99,718.13,447.4z M727.71,455.86c-0.67-2.52-1.31-4.94-2.09-7.9c1.57,0,2.48,0,3.59,0
		c0.3,2.37,0.6,4.78,0.92,7.38C729.41,455.49,728.75,455.63,727.71,455.86z M716.66,440.5c-2.37,1.78-4.75,3.55-7.45,5.58
		c-0.43-1.57-0.87-3.19-1.32-4.82c-0.45-1.63-0.9-3.29-1.36-4.95c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.46-3.52-0.91-7.04-1.39-10.69c-0.26-1.99-0.52-4.01-0.79-6.1c-0.26-1.97-0.52-4-0.79-6.1c9.07,0.66,16.91,1.23,25.16,1.83
		c-0.99,3.33-1.7,5.73-2.4,8.1c-0.36-0.49-0.83-1.14-1.31-1.78c-0.38,0.16-0.76,0.32-1.13,0.47c1.17,3.32,2.33,6.65,3.5,9.97
		c0.44,1.2,0.88,2.4,1.33,3.6c0.9,1.95,1.8,3.9,2.71,5.85l0,0c0,0.01,0,0.01,0.01,0.02c0.04,0,0.08,0.01,0.12,0.01l-0.01,0.02
		c0.01-0.01,0.02-0.02,0.02-0.02c0.07,0,0.13,0.01,0.19,0.01c0.06-0.02,0.12-0.04,0.17-0.06c0.15-0.05,0.3-0.1,0.45-0.15
		c0.01-2.62,0.02-5.23,0.02-7.85c-0.79-2.44-1.58-4.89-2.36-7.31c2.19,1.98,4.23,3.82,6.27,5.66c0.4-0.39,0.79-0.78,1.19-1.17
		c-2.33-2.8-4.66-5.59-7.2-8.66c0.58-1.68,1.25-3.64,2-5.84c7.4,0.84,14.51,1.64,21.76,2.46c0,2.99,0,6,0,9.01
		c0.17,0.28,0.34,0.56,0.5,0.84c0.72,2.18,1.44,4.37,2.42,7.31c-3.14,0.41-6.3,0.71-9.42,1.15c-5.2,0.74-10.27,1.89-14.9,4.66
		c-0.01,0-0.03,0-0.04,0c-0.29-0.02-0.57-0.04-0.86-0.05c-0.09,0.03-0.18,0.06-0.27,0.09l0,0c-0.02-0.03-0.03-0.07-0.05-0.1
		C726.5,441.16,721.58,440.83,716.66,440.5z M733.89,458.46c0-0.63,0-1.22,0-1.8c0-0.58,0-1.14,0-1.7
		c-0.27-1.11-0.55-2.23-0.83-3.38c-0.28-1.15-0.58-2.34-0.89-3.61c1.65-0.08,2.8-0.14,4.26-0.21c0.56,2.49,1.09,4.84,1.63,7.2
		c0.59,0.95,1.18,1.91,1.77,2.86c0.41-0.04,0.83-0.08,1.24-0.13c0-0.91,0-1.82,0-2.74c-0.32-1.28-0.64-2.56-0.97-3.87
		c-0.33-1.31-0.66-2.65-1.01-4.04c1.25-0.48,2.4-0.92,4.06-1.56c0.51,3.57,0.99,6.86,1.46,10.14c0.55,0.86,1.11,1.72,2.28,3.55
		C742.06,458.91,738.12,458.69,733.89,458.46z M748.82,455.8c-0.84-3.38-1.69-6.77-2.62-10.49c0.67-0.32,1.52-0.72,2.96-1.4
		c0.39,4.24,0.73,7.93,1.06,11.61C749.76,455.62,749.29,455.71,748.82,455.8z M753.18,458.37c0.28-1.5,0.53-2.82,0.77-4.13
		c-0.74-3.19-1.48-6.38-2.34-10.07c1.41-0.28,2.67-0.53,4.49-0.89c0,1.83,0,3.54,0,5.19c0,1.65,0,3.24,0,4.83
		c0.35,1.65,0.7,3.29,1.08,5.08C755.81,458.37,754.66,458.37,753.18,458.37z M758.02,418.72c6.02-0.47,11.77-0.92,17.13-1.34
		c1.65,4.83,3.28,9.61,4.97,14.57c-6.38,1.24-12.99,2.53-19.86,3.86c-0.57-2.58-1.04-4.71-1.46-6.61c1.99-0.32,3.81-0.61,5.64-0.9
		c-0.03-0.31-0.07-0.62-0.1-0.92c-2.25-0.62-4.49-1.25-7.04-1.95C757.55,423.06,757.79,420.86,758.02,418.72z M760.41,458.2
		c0-1.95,0-3.33,0-4.7c-0.48-2.39-0.96-4.77-1.47-7.31c-0.17-0.85-0.34-1.71-0.52-2.59c1.19-0.32,2.36-0.64,3.99-1.08
		c0,3.73,0,7,0,10.28c0.64,1.23,1.28,2.45,1.92,3.68c0.35-0.02,0.69-0.04,1.04-0.06c0.29-1.44,0.57-2.87,0.86-4.31
		c-0.12-1.46-0.24-2.93-0.36-4.43c-0.12-1.5-0.25-3.04-0.38-4.66c0.99-0.08,1.99-0.16,3.07-0.24c0.36,3.27,0.69,6.29,1.02,9.31
		c0.41,1.24,0.81,2.48,1.22,3.72c0.3,0,0.61-0.01,0.91-0.01c0.53-1.48,1.07-2.96,1.6-4.44c0,0,0,0,0,0c-0.17-1.5-0.34-3-0.52-4.52
		c-0.17-1.52-0.35-3.05-0.53-4.63c1.1,0,2.24,0,3.81,0c0,2.85,0,5.64,0,8.43c0.54,1.52,1.08,3.03,1.86,5.23
		C771.95,456.66,766.39,457.41,760.41,458.2z M779.64,455.22c0.1-2.06,0.17-3.65,0.24-5.24c-0.23-2.67-0.46-5.35-0.72-8.31
		c1.01-0.35,2.18-0.76,3.67-1.28c0.1,0.79,0.19,1.56,0.28,2.32c0.27,2.27,0.53,4.39,0.78,6.51c0.19,0.66,0.39,1.33,0.59,2.02
		c0.2,0.69,0.41,1.41,0.64,2.19C783.55,453.95,781.84,454.5,779.64,455.22z M786.77,440.42c0.31-0.12,0.61-0.24,0.92-0.36
		c1.03,2.65,2.05,5.3,3.37,8.7C786.61,446.6,787.36,443.25,786.77,440.42z M784.17,430.86c-1.8-5.1-3.47-9.82-5.1-14.43
		c2.13-0.96,4.14-1.56,6.06-1.81c0.77-0.1,1.53-0.15,2.27-0.14c1.32,0.01,2.6,0.21,3.85,0.55c0.46,0.13,0.92,0.27,1.37,0.44
		c1.44,0.54,2.85,1.3,4.23,2.28c1.36,0.81,2.73,1.63,4.27,2.55c0.92,0.55,1.91,1.14,3.01,1.79c-0.96,0.51-1.67,0.89-2.25,1.21
		c-0.29,0.16-0.56,0.3-0.8,0.43c-0.24,0.13-0.47,0.25-0.7,0.37c0.01,0.02,0.03,0.04,0.04,0.06c-0.01-0.02-0.03-0.04-0.04-0.07
		c-1.16-1.86-2.33-3.73-3.49-5.59c-1.19,0-2.37,0-3.56,0c-0.07,0.26-0.14,0.51-0.22,0.77c1.43,1.09,2.86,2.18,4.28,3.27c0,0,0,0,0,0
		c0.98,0.52,1.96,1.05,2.95,1.57c0,0,0,0,0,0c-1.65,0.93-3.3,1.85-4.94,2.78C791.89,428.14,788.38,429.37,784.17,430.86z
		 M795.92,448.09c-0.38,0.06-0.76,0.12-1.14,0.18c-0.63-3.12-1.26-6.24-1.89-9.36c0.56-0.09,1.12-0.18,1.68-0.27
		C795.02,441.79,795.47,444.94,795.92,448.09z M818.5,478.12c-0.67,1.57-1.41,3.11-2.37,4.73c0.35-3.07,0.31-5.9-0.94-8.02
		c-0.97-1.65-2.66-2.88-5.47-3.47c-0.69,0.25-1.39,0.5-2.08,0.75c0.41,0.56,0.78,1.16,1.25,1.67c0.8,0.86,1.64,1.69,2.49,2.49
		c1.28,1.2,1.58,2.28,1.32,3.29c-0.16,0.63-0.54,1.24-1.04,1.84c-0.2,0.24-0.42,0.47-0.65,0.71c-0.11,0.12-0.23,0.23-0.35,0.35
		c0.01,0.2,0.03,0.39,0.04,0.59c0.04,0.6,0.08,1.23,0.13,1.99c-3.12-0.83-5.08-2.34-4.47-5.87c-1.24-1.66-2.48-3.32-3.72-4.97
		c-1.31-0.43-2.62-0.85-4.13-1.34c-0.57,3.74,3.12,3.42,4.41,5.06c0.42,2.57,0.83,5.05,1.29,7.81c-4.3-0.96-8.05-3.08-10.88-6.64
		c-0.63-0.79-0.74-2.23-0.64-3.34c0.33-3.98,0.88-7.93,1.29-11.9c0.39-3.76,0.7-7.53,1.13-12.16c2.7,4.36,3.83,9.17,9.23,8.91
		c4.01-0.2,6.79-3.75,8.46-9.37c-0.39,4.21-0.79,8.43-1.13,12.12c-2.68,0.41-4.8,0.73-6.92,1.06c-0.09,0.61-0.18,1.21-0.26,1.82
		c1.41,0.55,2.79,1.25,4.25,1.6c1.93,0.47,4.02,0.42,5.88,1.05C818.53,470.18,820.15,474.28,818.5,478.12z M812.68,435.19
		c-0.17-1.38-0.84-2.69-1.13-4.06c-0.36-1.7-0.66-3.41-0.84-5.15c-0.17-0.86-0.23-1.76-0.18-2.7c0.01,0.91,0.09,1.81,0.18,2.7
		c0.49,2.52,1.85,4.71,3.74,6.54c2.04,1.99,4.56,3.44,7.58,1.22c0.77-0.57,1.95-0.59,2.97-0.84c0.01-0.02,0.02-0.04,0.03-0.06
		l0.09,0.03c0,0-0.09-0.03-0.1-0.04c0-0.02,0.01-0.04,0.01-0.05c-0.1-0.11-0.19-0.21-0.29-0.32c0.14-0.08,0.28-0.15,0.42-0.23
		c-0.05,0.18-0.09,0.37-0.13,0.55c0.03,0.03,0.06,0.06,0.08,0.09c-0.04,0.01-0.08,0.02-0.12,0.02c-0.65,1.25-1.09,2.75-1.95,3.77
		c-0.12,0.15-0.26,0.28-0.4,0.41c-1.35,1.16-3.26,2.12-5,2.25C815.02,439.54,813.06,438.2,812.68,435.19z M845.62,439.5
		c1.31,3.06,3.15,6.14,1.39,9.66c-0.44,0.87-1.11,1.62-1.68,2.43c-0.44-0.05-0.88-0.1-1.32-0.14c-0.58-4.88-1.17-9.76-1.81-15.09
		c-0.6,0.17-1.6,0.2-2.28,0.68c-2.95,2.06-5.64,4.57-8.76,6.31c-2.41,1.35-2.7,2.89-1.99,5.11c0.5,1.55,1.13,3.06,1.56,4.63
		c1.27,4.56,0.37,6.36-4.14,8.3c-3.23-3.99-1.1-8.08,0.3-12.77c-2.83,0.26-5.13,0.47-6.71,0.62c2.27-2.16,4.73-4.51,7.24-6.9
		c0.84-0.8,1.68-1.6,2.52-2.4c-0.31-0.32-0.62-0.65-0.94-0.98c-0.32-0.34-0.66-0.69-1.02-1.07c0.47-0.96,1.01-2.04,2.02-4.08
		c0.88,2.59,1.47,4.32,2.06,6.05c1.18-0.36,2.62-0.4,3.5-1.13c1.2-0.99,2.15-2.09,2.92-3.28c0.38-0.59,0.72-1.21,1.02-1.84
		c0.15-0.32,0.29-0.63,0.42-0.96c0.26-0.65,0.49-1.31,0.7-1.98c0.71-2.35,1.1-4.85,1.53-7.33c-0.32-3.44-0.63-6.88-0.95-10.31
		c0.14-0.06,0.28-0.12,0.42-0.18c0.81,2.8,1.62,5.6,2.43,8.4c-0.18,2.21-0.27,4.44-0.57,6.64C842.93,432,844.02,435.76,845.62,439.5
		z M845.54,387.57c1.18,7.3,2.2,13.55,3.29,20.25C844.08,405.48,842.9,397.76,845.54,387.57z M866.38,414.12
		c-6.18,1.87-12.62,1.51-19.32-1.4c1.42-1.21,3.28-2.14,4.04-3.63c0.77-1.53,0.73-3.63,0.54-5.43c-0.27-2.6-0.73-5.19-1.18-7.77
		c-0.15-0.86-0.3-1.72-0.44-2.59c-0.15-0.98-0.3-1.96-0.45-2.96c-0.15-1-0.31-2.01-0.47-3.06c6.35-1.1,12.11-1.4,14.64,5.77
		c-1.02,0.72-2.11,1.48-3.23,2.26c-1.12,0.78-2.26,1.59-3.41,2.39c-0.21,0.5-0.43,0.99-0.65,1.49c-0.54,1.24-1.05,2.49-1.36,3.74
		c-0.54,2.26-0.39,4.54,1.53,6.87c0.7,0.85,1.64,1.52,3.03,2.77c1.94-1.88,3.76-3.65,5.56-5.39c0.6-0.58,1.19-1.16,1.79-1.73
		c0.21-0.38,0.41-0.77,0.62-1.15c0.3-0.56,0.6-1.12,0.9-1.68c0.37-1.29,0.73-2.58,1.14-4.03c0.25-0.87,0.51-1.8,0.8-2.81
		c1.44,0.79,2.87,1.58,4.28,2.36s2.82,1.55,4.23,2.33c0.52,0.28,1.04,0.56,1.59,0.86c0.92,0.5,1.94,1.04,3.21,1.73
		c-2.5,2.05-4.52,3.71-6.54,5.37l0,0h0C873.7,411.08,870.09,413,866.38,414.12z M866.63,394.19c-0.94,4.91-1.25,9.95-6.2,12.95
		c-0.96-1.23-1.8-2.31-2.87-3.68C860.17,399.94,862.33,396.05,866.63,394.19z M873.28,422.6c-0.68,0.25-1.4,0.36-2.25,0
		c2.04-1.34,3.95-3.07,6.11-4c0.31-0.13,0.63-0.24,0.95-0.34c0.36-0.11,0.72-0.19,1.1-0.25c0.11-0.02,0.21-0.03,0.32-0.04
		c1.04-0.11,2.15,0.01,3.37,0.44c0.81-0.55,1.61-1.11,2.49-1.71s1.84-1.26,2.95-2.02c0,2.22,0,3.53,0,5.37
		c-1.83-0.35-3.6-0.69-5.37-1.03C879.72,420.21,876.51,421.42,873.28,422.6z M885.53,423.49c0,1.15,0,2.27,0,3.69
		c-2.3,0.69-4.63,1.39-7.31,2.2c-0.82-0.94-1.66-1.91-3.14-3.6C878.88,424.95,882.1,424.24,885.53,423.49z M872.38,466.88
		c-4.07-2.68-4.06-6.83-0.71-11.83c0.97,0.85,1.92,1.7,3.24,2.86C874.12,460.68,873.29,463.64,872.38,466.88z M900.03,438.02
		c-1.65-0.9-3.29-1.79-5.12-2.78c0.44-1.7,0.77-2.99,1.15-4.47c1.55,2.28,3.03,4.44,4.5,6.6
		C900.38,437.58,900.21,437.8,900.03,438.02z M901.82,439.11c-0.35-0.1-0.71-0.19-1.06-0.29c0.11-0.21,0.22-0.42,0.33-0.64
		c0.3,0.17,0.59,0.33,0.89,0.5C901.93,438.83,901.87,438.97,901.82,439.11z M905.99,437.61c-2.7-2.93-5.04-6.19-7.71-9.16
		c-1.08-1.2-2.64-1.98-3.99-2.95c-0.47,2.43-0.64,4.97-1.5,7.26c-0.77,2.05-2.28,3.83-3.46,5.73c-2.92,0.71-5.85,1.38-8.76,2.13
		c-6.36,1.63-7.85,7.03-4.8,10.21c1.98,2.06,5.28,3.03,8.14,4.04c3.65,1.29,7.45,2.14,11.07,3.71c-4.97,3.01-9.45,1.2-13.84-0.9
		c-1.67-0.8-3.17-1.96-4.68-3.06c-4.39-3.21-5.12-7.31-2.28-12.19c0.63-1.08,1.17-2.21,1.75-3.32c-0.22-0.39-0.44-0.78-0.66-1.18
		c-2.53,0.79-5.17,1.34-7.55,2.43c-3.71,1.71-4.74,1.53-7.28-2.55c2.11-3.09,5.37-3.96,8.82-3.58c4.05,0.45,8.02,1.61,12.06,2.19
		c1.4,0.2,2.86,0.03,4.31-0.11c0.48-0.05,0.97-0.09,1.45-0.11c0.3-0.74,0.66-1.47,1.02-2.2c1.1-2.2,2.29-4.39,2.44-6.65
		c0.48-7.22,0.96-14.59-2.57-21.41c-0.34-0.66-0.19-1.58-0.26-2.38c0.37-0.07,0.75-0.14,1.12-0.21c1.84,5.95,3.68,11.9,5.53,17.84
		c1.72,1.67,3.46,3.33,5.19,5c1.73,1.67,3.45,3.34,5.14,5.05c2.03,2.04,3.88,4.25,5.81,6.39
		C908.53,440.39,906.99,438.69,905.99,437.61z M922.27,451.15c-5.09-2.91-9.38-5.37-13.98-8.01c0.92-0.51,1.54-0.79,2.1-1.16
		c2.94-1.95,3.52-4.33,1.33-7.17c-1.71-2.23-3.62-4.3-5.52-6.39c-0.63-0.7-1.26-1.39-1.88-2.1c-0.72-0.41-1.41-0.85-2.05-1.32
		c-0.97-0.7-1.85-1.46-2.66-2.28c-2.95-3-4.85-6.76-6-11.12c-0.8-3.03-2.22-5.9-3.09-8.92c-0.38-1.31-0.06-2.81-0.06-4.67
		c0.87,0.32,1.69,0.63,2.46,0.91c2.3,0.85,4.19,1.56,6.08,2.26c3.13-1.93,3.75-3.04,0.88-6.98c-0.96-1.31-2.3-2.94-4.07-5.02
		c0.55-1.63,1.11-3.28,1.88-5.55c2.55,1.5,4.97,2.93,7.39,4.36c-0.37,0.14-0.74,0.28-1.11,0.42c0.82,5,1.63,10,2.45,14.99
		c0.48,0.77,0.97,1.54,2.1,3.32c0.87-1.22,1.5-2.09,2.17-3.03c0.58,0.5,1.37,1.18,2.62,2.26c0-1.62,0-2.73,0-3.85
		c0.25-0.19,0.5-0.37,0.76-0.56c1.02,1.41,2.23,2.73,3.01,4.26c0.99,1.93,1.94,3.23,4.47,1.13c0,5.45-0.09,10.52,0.02,15.59
		c0.13,5.68,0.51,11.35,0.68,17.02C922.36,443.16,922.27,446.76,922.27,451.15z M919.32,397.22c0.33,1.8,0.56,3.03,0.79,4.25
		c-0.21,0.07-0.42,0.13-0.62,0.2c-1.1-3.3-2.2-6.6-3.28-9.84c2.29-0.13,4.04-0.23,6.33-0.36c0.69,1.07,1.65,2.57,3.06,4.78
		c-1.44-0.73-2.21-1.12-3.43-1.74c0.29,1.78,0.5,3.08,0.79,4.88C921.67,398.61,920.83,398.11,919.32,397.22z M931.64,456.29
		c-1.88-0.97-3.75-1.93-5.62-2.89c-0.5-16.53-0.99-32.87-1.46-48.56c1.83-1.54,2.99-2.52,4.2-3.54c4.44,2.72,9.25,5.67,14.28,8.76
		c-0.07,1.1-0.14,2.26-0.21,3.42c0.47,4.83-0.4,9.76,2.2,14.45c0.9,1.63,0.2,4.83-0.86,6.7c-1.63,2.89-0.92,5,0.59,7.41
		c3.02-0.17,4.2-1.67,4.11-4.54c-0.06-1.95,0.25-3.92,0.39-5.87c0.43,0,0.87,0,1.3-0.01c0.38,4.38,0.76,8.77,1.15,13.33
		c4.48-1.31,5.32-3,4.14-6.25c-0.06-0.17-0.09-0.35-0.1-0.54c-0.02-0.57,0.15-1.21,0.19-1.8c-0.06-0.63-0.13-1.26-0.19-1.9
		c-0.06-0.64-0.13-1.3-0.2-1.97c3.69-2.36,4.93-3.17,5.74-11.35c0.74,0.59,1.28,1.02,1.82,1.45c2.44,0.56,2.86,2.22,2.95,4.51
		c0.12,3.18,0.76,6.34,1.83,9.38c-0.98,7.18-1.96,14.37-2.98,21.87c-1.35-2.15-2.57-4.28-3.97-6.3c-2.63-3.79-6.02-6.13-10.96-5.79
		c-2.46,0.17-3.23,1.27-3.38,3.53c-0.37,5.6,3.21,9.34,6.11,13.35C943.8,466.61,938.32,459.63,931.64,456.29z M945.26,471
		c2.22-3.61,6.4-4.69,11.92-2.96C954.78,472.09,949.03,473.45,945.26,471z M957.71,462.14c-1.06-1.29-2.09-2.54-3.11-3.78
		c-0.51-0.62-1.02-1.24-1.53-1.86c-0.29-0.48-0.57-0.96-0.86-1.45c-0.86-1.45-1.74-2.94-2.69-4.54c1.82-0.36,3.32-0.33,4.59,0.11
		c0.42,0.15,0.82,0.34,1.19,0.58c0.74,0.48,1.4,1.14,1.98,2s1.1,1.91,1.58,3.16l0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c0.43,0.47,0.85,0.94,1.28,1.41c0.51,1.19,1.02,2.38,1.59,3.7c-0.65,0.71-1.27,1.39-2.41,2.64
		C958.77,463.43,958.24,462.78,957.71,462.14z M962.05,516.04c-4.67-5.01-1.91-10.9-2.68-16.65
		C964.2,505.8,965.64,513.92,962.05,516.04z M992.18,506.94c-1.73,0.83-3.99,0.56-5.45,0.72c1.25,6.03,2.54,11.63,3.47,17.29
		c0.15,0.9-1.3,2.06-2.06,3.18c-3.15-2.31-3.37-5.53-3.5-8.64c-0.42-10.24,2.23-9.24-8.61-14.42c-1.23-0.59-2.27-1.58-3.41-2.38
		c-0.89-0.2-1.79-0.39-3.3-0.73c0.35,2.02,0.4,3.65,0.94,5.11c3.46,9.48,1,17.86-4.56,25.76c0.82-4.01,1.86-7.93,2.37-11.92
		c0.78-6.19-0.89-12.03-2.8-17.9c-0.53-1.63-1.02-3.27-1.49-4.92c-1.41-4.94-2.61-9.96-3.95-14.92l-0.03-0.11
		c-0.04-0.1-0.09-0.2-0.13-0.3c-0.04-0.1-0.08-0.2-0.11-0.3c-0.02-0.09,0.06-0.21,0.1-0.32c0.11-1.42,0.17-2.86,0.24-4.29
		c0.05-0.95,0.1-1.91,0.18-2.85c0.12-1.42,0.3-2.83,0.61-4.2c0.16-0.71,0.55-1.37,1.04-2.01s1.09-1.25,1.67-1.87
		c0.19-0.21,0.38-0.41,0.57-0.62c0.18-0.21,0.36-0.42,0.53-0.63c1.01-0.1,2.05-0.31,3.07-0.46c0.51-0.07,1.02-0.13,1.52-0.14
		c0.5-0.01,0.99,0.02,1.47,0.11c8.06,1.62,11.4-0.48,13.37-8.53c0.26-1.06,0.54-2.12,0.84-3.17c0.89-3.15,1.91-6.28,2.84-9.42
		c0.89-2.02,1.53-4.22,2.63-6.09c0.16-0.27,0.32-0.53,0.5-0.78c1.64-2.33,3.8-3.81,7.23-2.55c2.94,1.08,6.24,1.21,9.24,2.56
		c-2.36,1.14-4.73,2.28-7.67,3.7c0.31-1.46,0.52-2.48,0.91-4.32c-1.1,0.71-1.68,1.08-2.26,1.46c0.19,0.3,0.39,0.59,0.58,0.89
		c-3.72,0.66-7.74,0.82-9.27,5.25c0.25,1.32,0.51,2.65,0.76,3.97c-0.18-0.09-0.35-0.18-0.53-0.27c-1.72,5.72-3.05,11.59-5.29,17.1
		c-1.57,3.88-5.31,5.89-9.92,6.8c0.09,0.43,0.18,0.89,0.27,1.35c0.09,0.47,0.19,0.94,0.29,1.42c0.29,0.27,0.56,0.54,0.82,0.81
		c0.26,0.27,0.49,0.55,0.72,0.84c0.45,0.57,0.83,1.15,1.16,1.75c0.33,0.6,0.6,1.21,0.82,1.84s0.39,1.27,0.52,1.92
		c0.51,2.61,0.32,5.41-0.14,8.28c-4.4-4.07-2.38-10.14-4.44-14.93c-1.37-1.21-2.56-2.98-4.15-3.51c-1.56-0.52-2.58,0.07-3.39,1.03
		c-0.27,0.32-0.52,0.68-0.75,1.04c-0.08,0.12-0.16,0.25-0.23,0.37c-0.23,0.37-0.46,0.75-0.69,1.11c-0.07,0.92-0.14,1.84-0.21,2.75
		c0.25,3.01,0.49,6.02,0.75,9.22c-0.68-1.3-1.27-2.41-2.09-3.97c-1.71,7.45,0.52,13.03,6.33,16.97c1.04,0.7,2.06,1.41,3.09,2.12l0,0
		c1.24,0.49,2.47,0.98,3.71,1.47c1.24,0.49,2.48,0.98,3.72,1.44c2.11,0.79,3.6,0.39,4.98-1.74c2.68-4.14,7.03-4.52,11.06-0.65
		C995.18,504.16,993.94,506.09,992.18,506.94z M998.21,497.94c-0.37-0.34-0.75-0.67-1.12-1.01c0.13-0.14,0.26-0.28,0.39-0.42
		c0.31,0.38,0.63,0.75,0.94,1.13c0.1,0.24,0.2,0.48,0.3,0.72C998.56,498.22,998.38,498.08,998.21,497.94z M1004.34,497.13
		c-0.29-0.26-0.58-0.52-0.86-0.77c0,0,0,0,0,0c-2.89-3.63-5.78-7.25-8.66-10.88c-0.26-0.28-0.52-0.57-0.78-0.85
		c0.09-0.12,0.18-0.23,0.27-0.35c0.37,0.23,0.74,0.46,1.1,0.7c2.54,1.05,5.08,2.11,7.45,3.09c0.39,2.63,0.77,5.15,1.15,7.66
		c0.24,0.38,0.47,0.76,0.71,1.14C1004.59,496.96,1004.47,497.04,1004.34,497.13z M1007.69,528.27c-3.16-5.19-4.2-16.24-0.78-21.98
		c0.68,5.05,1.55,10.09,1.95,15.17C1009.02,523.55,1008.16,525.71,1007.69,528.27z M1013.65,520.84c-3.39-4.45-3.69-9.28-2.55-14.38
		c1.07-4.81,2.03-9.65,3.03-14.48c0.29-0.01,0.58-0.03,0.88-0.04C1016.97,501.63,1014.28,511.18,1013.65,520.84z M1016.24,483.58
		c0.3-2.35,0.61-4.69,0.91-7.04c0.23-0.07,0.45-0.14,0.68-0.22c0.83,2.42,1.66,4.84,2.49,7.25c0.98,3.57,2.03,7.12,2.87,10.72
		c0.14,0.61-0.52,1.96-0.93,2.01c-0.75,0.1-2.14-0.36-2.3-0.88C1018.75,491.48,1015.02,488.28,1016.24,483.58z M1036.84,472.88
		c0.48,2.12,1.09,4.78,1.8,7.88c-5.01,1.53-9.69,2.95-14.65,4.46c-1.1-3.27-2.04-6.06-2.93-8.7
		C1026.43,475.28,1031.57,474.09,1036.84,472.88z M1026.28,469.35c2.08-1.63,4.17-3.27,6.52-5.11c1.33,4.81-0.07,6.4-5.75,6.22
		C1026.8,470.08,1026.54,469.71,1026.28,469.35z M1018.71,508.53c0.16-3.25,0.29-5.94,0.42-8.57c1.45,0.36,2.89,0.72,4.21,1.05
		c0.43-0.61,0.81-1.16,1.17-1.67c0.36-0.51,0.69-0.99,1.03-1.47c0-0.67,0-1.34,0-2.02c0-2.04,0-4.15,0-6.55
		c0.8-0.16,2.41-0.49,3.28-0.67c-0.21,3.53-0.41,6.74-0.6,9.95c1.56,2.52,2.22,3.49,3.96,3.39c1.04-0.05,2.48-0.49,4.73-1.2
		c0.52-0.73,1.05-1.45,1.58-2.17c1.6-2.16,3.21-4.32,4.57-6.63c0.87-1.48,0.9-3.47,1.31-5.22c-0.31-0.13-0.62-0.25-0.93-0.38
		c-1.48,2.32-2.71,4.87-4.53,6.88c-1.82,2.01-4.23,3.48-6.41,5.21c-3.15-2.85-1.27-4.93,0.17-6.58c2.64-3.03,5.68-5.71,8.44-8.64
		c0.57-0.6,0.63-1.68,0.92-2.54c-0.79-2.96-1.65-5.91-2.35-8.9c-0.55-2.35-0.73-4.79-1.38-7.11c-0.98-3.51-3.36-6.23-6.89-6.67
		c-1.9-0.24-5.07,1.03-5.9,2.57c-1.72,3.19-2.56,7.02-0.18,10.61c-6.96,0.58-10.96,4.18-13.38,11.96
		c-0.77,4.36-1.53,8.72-2.35,13.37c-3.75-4.75-3.96-9.95-0.87-15.15c2.07-3.63,4.14-7.26,6.27-11.01c-0.83-0.46-2.04-1.14-3.25-1.82
		c-1.09-1.37-2.17-2.74-3.26-4.12c0.26-0.3,0.51-0.6,0.77-0.9c2.78,1.41,5.55,2.82,8.33,4.23c0.21,0.02,0.41,0.02,0.62,0
		c0.1-0.18,0.21-0.35,0.31-0.53c0.18,0.18,0.37,0.36,0.55,0.54c0.7-0.32,1.84-0.47,2.03-0.98c0.67-1.81,0.92-3.77,1.52-5.61
		c0.96-2.95,2.11-5.88,5.79-6.25c3.99-0.4,7.3,0.92,9.97,4.06c3.61,4.25,3.33,9.73,4.95,14.61c0.69,2.07,1.05,4.24,1.57,6.36
		c1.45,3.11,2.92,6.2,4.37,9.31c1.45,3.1,2.9,6.22,4.3,9.34c0.17,0.39,0.31,0.79,0.43,1.2c0.36,1.23,0.53,2.54,0.84,3.8c0,0,0,0,0,0
		c0,1.18,0,2.36,0,3.34c-1.3,0.58-2.23,0.93-3.09,1.4c-0.91,0.5-1.75,1.11-2.66,1.69c0.74-2.93,1.45-5.76,2.17-8.58
		c-0.75-0.26-1.5-0.52-2.25-0.78c-0.95,2.03-1.79,4.16-3.05,5.96c-0.98,1.4-2.23,2.59-3.98,3.37c-1.12,0.49-2.52,0.33-3.79,0.47
		c-0.18-0.34-0.36-0.68-0.54-1.02c2.63-2.9,5.25-5.81,7.88-8.71c0.97-1.43,1.94-2.86,2.88-4.23c-0.57-1.39-1.1-2.68-1.69-4.11
		c-2.54,2.84-5.05,5.64-7.56,8.43c-0.45,0.55-0.9,1.09-1.36,1.64c-1.37,1.65-2.78,3.35-4.35,5.25c-2.15-1.53-4.47-3.18-6.79-4.83
		c-0.27,0.28-0.53,0.55-0.8,0.83c2.87,2.48,5.74,4.96,8.61,7.44c-0.27,0.42-0.55,0.83-0.82,1.25
		C1028.59,511.14,1023.58,509.82,1018.71,508.53z M1054.67,512.33c-0.14,0.12-0.28,0.24-0.41,0.36c-0.06-0.18-0.14-0.35-0.15-0.53
		c-0.01-0.08,0.14-0.17,0.21-0.25C1054.44,512.05,1054.55,512.19,1054.67,512.33C1054.67,512.33,1054.67,512.33,1054.67,512.33
		C1054.67,512.33,1054.67,512.33,1054.67,512.33z M1032.07,516.97c7.88,2.69,14.21-1.11,21.63-3.79
		c-3.16,8.26-10.72,7.05-15.69,9.33C1035.94,520.59,1034.29,519.05,1032.07,516.97z M1044.63,527.49c-0.94,0.37-1.9,0.69-2.61,1.31
		c-1.14,0.77-2.27,1.56-3.48,1.87c-1.56,0.4-3.25-0.01-5.23-2.32c1.81-0.46,3.06-0.77,4.31-1.09c3.36-1.19,6.72-2.39,10.39-3.7
		C1047.73,526.1,1046.2,526.87,1044.63,527.49z M1049.45,573.04c-0.38,0.58-1.26,0.83-2.1,1.11c-0.28,0.09-0.55,0.19-0.8,0.31l0,0h0
		c-0.22-0.59-0.53-1.2-0.81-1.81c-0.48-1.01-0.91-2.01-0.76-2.92c0.31-1.94,1.62-3.78,2.73-5.52c2.75-4.3,5.63-8.51,8.78-13.25
		C1056.27,559.29,1053.75,566.46,1049.45,573.04z M1061.9,539.66c-0.58,0.07-1.18,0.11-1.8,0.11c-0.62,0-1.25-0.04-1.87-0.11
		c-2.5-0.29-4.94-1.11-6.21-2.29c-0.51-0.86-1.02-1.73-1.67-2.82c-0.39-0.66-0.83-1.39-1.34-2.27c3.35,1.26,5.68,2.14,8.01,3.01
		c1.27,0.56,2.54,1.11,3.84,1.68c1.3,0.57,2.63,1.15,4.01,1.76C1064.09,539.21,1063.05,539.51,1061.9,539.66z M1068.71,541.07
		c-0.06-2.96-1.27-4.71-2.96-5.95c-0.34-0.25-0.71-0.48-1.09-0.7c-0.32-0.18-0.64-0.36-0.98-0.52c-0.32-0.16-0.65-0.3-0.97-0.45
		c-0.35-0.16-0.7-0.31-1.06-0.46c-0.44-0.19-0.89-0.38-1.33-0.57c-0.14-0.06-0.28-0.12-0.42-0.19l0,0v0
		c-0.82-0.27-1.64-0.54-2.47-0.82c-1.65-0.55-3.31-1.1-5.03-1.66c-0.86-0.28-1.73-0.57-2.61-0.86c0.79-1.87,1.41-3.35,2.03-4.83
		c2.19-5.26,4.27-10.57,6.63-15.76c0.55-1.21,1.24-2.35,1.96-3.48c0.73-1.13,1.49-2.24,2.19-3.37v0v0
		c2.42-7.71,4.88-15.41,7.23-23.14c0.81-2.65,1.34-5.39,2-8.08c0.39,0.06,0.77,0.11,1.16,0.17c0,3.57,0,7.15,0,10.72
		c0.28,0.07,0.57,0.13,0.85,0.2c0.77-1.14,1.55-2.28,2.54-3.76c2.66,5.05,1.37,9.84,0.42,14.9c-2.45,0.45-4.39,1.46-6.02,2.82
		c-0.54,0.45-1.05,0.94-1.54,1.46c-0.24,0.26-0.48,0.52-0.71,0.8c-1.61,1.9-2.95,4.1-4.35,6.23c0.21,3.07,0.43,6.14,0.64,9.21
		c1.7,2.84,3.64,2.62,5.68,0.41c1.11-1.2,2.14-2.46,2.63-3.03c2.14,4.35,4.39,8.92,6.95,14.13c-4.13,9.63-4.28,13.63-0.02,22.69
		C1075.88,544.91,1072.27,542.97,1068.71,541.07z M1075.66,497.26c-0.26,1.62-0.51,3.24-0.77,4.84c-0.25,1.6-0.5,3.17-0.75,4.69
		c-0.52,0.22-1.02,0.43-1.52,0.64c-1.49,0.63-2.91,1.22-4.33,1.82c-1.54-1.85-0.67-5.13,1.59-8c0.45-0.57,0.96-1.13,1.51-1.66
		c1.11-1.05,2.41-1.98,3.83-2.67c0.19-0.28,0.37-0.55,0.56-0.83c0.1,0.04,0.2,0.07,0.3,0.11
		C1075.95,496.56,1075.8,496.91,1075.66,497.26C1075.66,497.26,1075.66,497.26,1075.66,497.26z M1083.46,546.7
		c-3.37-7.06-5.21-13.73-0.6-20.72C1087.47,531.43,1087.97,543.84,1083.46,546.7z M1084.45,549.97c6.58-2.97,5.26-9,5.57-15.29
		c3.51,7.05,5.22,13.41,3.55,21.23C1090.07,553.63,1087.27,551.81,1084.45,549.97z M1071.31,589.72c3.84-2.7,7.85-5.52,11.87-8.34
		C1080.54,587.87,1074.66,591.39,1071.31,589.72z M1092.54,570.71c-3.53,6.99-2.87,16.14,2.24,21.99c1.01,1.15,2.32,2.11,3.05,3.4
		c0.69,1.2,1.39,2.95,0.99,4.06c-0.35,0.97-2.24,1.37-3.24,1.91c-5.58-6.88-4.74-15.14-7.13-22.3c-1.48-0.1-2.51-0.16-3.54-0.23
		c-0.02,0.02-0.03,0.03-0.04,0.05c0,0,0,0,0,0c-0.03,0.04-0.06,0.09-0.09,0.13c-0.05,0.42-0.11,0.84-0.16,1.26
		c-0.2-0.07-0.4-0.14-0.6-0.2c0.25-0.35,0.51-0.7,0.76-1.05c0.01-0.06,0.02-0.12,0.02-0.19c0,0,0,0,0,0c0,0,0,0,0,0
		c0.04,0,0.07,0,0.11,0.01c4.29-4.76,7.69-10.16,9.78-16.08c0.3-0.85,0.57-1.71,0.82-2.58c2.07-7.36,1.78-14.93-1.02-22.36
		c-2.83-7.49-7.21-13.86-12.62-19.58c-4.55-4.82-4.76-10.58-3.82-16.52c0.89-5.61,2.25-11.14,3.4-16.71
		c0.31-1.01,0.52-1.98,0.62-2.91c0.45-4.06-1.03-7.52-4.53-10.62c-4.51-4-8.53-8.39-14.01-11.37c-5.91-3.22-10.61-8.03-13.01-15.1
		c0.42-1.08,1-2.56,1.81-4.65c0.61,6.18,3.32,10.92,7.55,13.99c1.55,1.13,3.09,2.44,4.73,3.57c2.73,1.89,5.73,3.32,9.54,2.67
		c2.62-0.47,5.23-0.94,7.85-1.41c3.58-2.63,6.6-5.61,7.32-10.33c0.1-0.64,1.33-1.11,2.04-1.65c3.89,1.35,4.81,5.59,2.05,8.98
		c-4.67,5.71-10.94,6.95-17.83,6.57c-0.58,0.07-1.16,0.15-2.62,0.33c7.06,5.12,14.49,9.71,10.43,19.82
		c0.62,6.4,3.28,11.78,8.05,16.11c2.23,0.95,4.55,1.72,6.65,2.89c1.6,0.9,2.92,2.31,4.79,3.83c-1.45,0.8-2.14,1.18-2.83,1.56
		c-0.78,0.29-1.64,0.41-2.5,0.39c-2.6-0.06-5.35-1.45-6.78-3.79c-1.7-2.78-3.03-5.78-4.64-8.61c-0.56-0.99-1.46-1.79-2.83-3.42
		c-2.58,5.97-4.09,11.13-3.06,16.71c1.12,6.06,4.46,10.91,8.7,15.29c3.27,3.37,6.25,7.02,9.65,10.87c-3.39,1.01-2.36,3.21-2.03,5.2
		c0.66,4.02,1.4,8.03,2,12.06C1099.54,559.66,1095.41,565.04,1092.54,570.71z M1098.77,537.05c-0.11-0.33-0.22-0.66-0.33-0.99
		c0.14-0.05,0.29-0.1,0.43-0.15c0.13,0.32,0.26,0.65,0.38,0.97C1099.09,536.94,1098.93,536.99,1098.77,537.05z M1127.28,573.31
		c-7.2-3.9-14.21-7.77-21.29-11.5c-2.41-1.27-5-2.32-6.58,1.51c0-5.84,0-11.39,0-16.45c2.42,3.81,4.73,8.28,7.84,12.1
		c3.72,4.57,8.88,4.41,11.1,0.13c1.77-3.41-0.41-9.61-3.61-12.18c-4.31-3.46-8.84-6.78-12.57-10.8c-4.19-4.51-7.6-9.73-11.34-14.65
		c-1.57-2.05-3.24-4.03-4.66-6.19c-3.25-4.93-1.54-10.45-1.47-15.85c0.56,1.23,1.16,2.46,1.69,3.7c2.03,4.8,5.9,7.4,10.68,8.86
		c2.37,0.72,6.84-1.46,7.79-3.68c1.08-2.54-0.58-5.92-3.74-7.56c-3.05-1.58-6.51-2.67-9.06-4.84c-3.96-3.37-5.82-7.97-5.79-13.42
		c0.02-3.91-0.49-7.89-1.43-11.68c-0.37-1.51-2.52-2.59-4.34-4.32c6.47-0.43,11.17-3.45,14.97-8.07c2.63-3.2,2.02-9.33-1.29-11.75
		c-3.23-2.37-6.87-0.9-9.1,4.46c-1.46,3.52-3.76,5.42-7.17,6.36c-0.81,0.22-1.71,0.11-2.62,0.16c-0.22-1.9-0.43-3.69-0.63-5.43
		c-0.11-0.97-0.22-1.92-0.33-2.86c-0.09-0.75-0.17-1.49-0.26-2.23c-0.27-0.33-0.53-0.67-0.79-0.99c-0.39-0.48-0.76-0.95-1.08-1.35
		s-0.6-0.75-0.81-1.01c3.31-2.5,6.63-5,9.94-7.5c7.91-6.37,10.67-15.29,12.14-24.79c0.36-2.35,0.51-4.74,0.67-7.13
		c0.05-0.8,0.11-1.59,0.18-2.38c0-1.99,0-3.97,0-6.02c4.78,4.8,5.41,7.3,2.83,11c0.07,0.88-0.06,1.92,0.2,2.65
		c0.26,0.73,0.91,1.15,2.54,0.79c2.43-4.76,4.71-9.53,6.1-14.57c1.08-3.92,1.62-8,1.27-12.35c-0.19-2.34,1.18-4.85,2.02-7.22
		c1.13-3.2,2.45-6.34,3.69-9.5c0.45-4.53,1.12-9.05,1.29-13.59c0.4-10.41-7.95-21.44-16.94-24.43c-1.23-0.41-2.63-2.01-2.91-3.28
		c-0.76-3.47-3.2-5.11-6.07-4.68c-5.65,0.85-8.72-2.24-11.57-6.12c-1.84-2.51-3.57-5.11-5.01-8.13c3.66,2.19,7.28,4.43,10.98,6.56
		c12.55,7.23,25.06,14.52,37.71,21.58c4.59,2.56,6.39,5.74,6.23,11.26c-0.3,9.93,0.53,19.89,0.9,30.14
		c-1.99-0.47-3.76-0.88-5.81-1.36c-1.22,2.02-2.48,4.12-3.86,6.43c-0.31-0.24-0.73-0.56-1.18-0.9c-0.15-0.11-0.3-0.23-0.45-0.35
		c-0.49-0.2-0.98-0.4-1.59-0.65c-0.61-0.25-1.34-0.55-2.31-0.95c-0.33,2.95-0.89,5.6-0.86,8.24c0.02,2.55,0.13,5.1,0.26,7.64
		c0.13,2.55,0.28,5.09,0.4,7.64c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.17,0.38,0.46,0.76,0.59,1.14c0.04,0.13,0.07,0.25,0.07,0.38
		c0.05,5.88,0.03,11.76,0.03,17.68c-3.07-0.92-5.97-1.79-9.97-2.99c0,4.77,0.03,9.01-0.01,13.25c-0.06,5.5,0.37,11.08-0.38,16.49
		c-1.44,10.43-3.56,20.76-5.4,31.13c-0.14,0.8-0.34,1.6-0.52,2.39c-0.89,3.81-0.08,5.01,3.5,6.72c3.89,1.85,7.61,4.07,10.64,5.72
		c0,3.69-0.18,6.52,0.05,9.31c0.22,2.67,1.11,5,4.43,5.34c1.03,0.11,2.03,0.47,3.03,0.82c0.33,0.11,0.67,0.23,1.01,0.32
		c1.94,0.76,3.88,1.51,5.8,2.26c0,5.16,0,10,0,14.84v0c0.1,0.72,0.21,1.43,0.32,2.15c0.34,2.15,0.7,4.29,0.8,6.45
		c0.57,12.23,0.99,24.48,1.46,36.72C1127.62,572.22,1127.46,572.54,1127.28,573.31z M1099.85,538.45c0.13-0.1,0.26-0.2,0.38-0.31
		c0.3,0.28,0.6,0.55,0.9,0.83c0,0,0,0,0,0c2.92,2.72,5.88,5.39,8.78,8.14c0.97,0.91,1.92,1.84,2.87,2.77c2.03,2,3.49,4.4,2.69,7.55
		c-3.83,2.24-5.84-0.83-7.2-3.02c-2.56-4.13-4.58-8.6-6.73-12.99c-0.31-0.63-0.62-1.25-0.93-1.87
		C1100.36,539.17,1100.1,538.81,1099.85,538.45z M1131.43,387.69c-0.22,0.35-0.43,0.7-0.65,1.05c-2.91-1.77-5.81-3.54-8.72-5.31
		c0.27-0.43,0.54-0.86,0.81-1.3C1125.72,383.98,1128.57,385.83,1131.43,387.69z M1119.68,487.12c0.3,2.17,0.76,4.34,1.06,6.51l0,0v0
		c0.12,0.14,0.25,0.32,0.37,0.5c0.07,0.1,0.14,0.2,0.21,0.29c0.19,0.26,0.37,0.46,0.51,0.43c7.23-1.44,12.87,3.19,19.24,4.97
		c2,0.56,3.91,1.43,5.99,2.21c0,2.36,0,4.6,0,6.83c-4.89-1.98-9.88-4.01-14.87-6.04c-1.68-0.65-3.36-1.3-5.04-1.96
		c-4.84-3.33-9.67-6.66-14.52-9.97c-2.54-1.74-5.11-3.44-7.66-5.16c-0.46-0.3-0.93-0.59-1.4-0.89c-0.25-0.18-0.5-0.36-0.75-0.54
		c0,0,0,0,0,0l0,0h0l0,0c-0.45-0.06-0.82-0.16-1.11-0.28c-0.44-0.19-0.72-0.44-0.89-0.74c-0.11-0.2-0.18-0.42-0.21-0.65
		c-0.15-1.04,0.37-2.35,0.18-3.37c0.67-4.61,1.48-9.2,1.97-13.83c1.06-10.01,2.17-20.02,2.84-30.06c0.27-4.04-0.56-8.15-0.91-12.51
		c3.47-0.38,6.07,1.83,8.86,3.51c0.98,0.67,1.96,1.35,2.94,2.02c0.21,0.04,0.41,0.09,0.61,0.18c1.07,0.56,2.04,1.17,2.8,1.91
		c0.76,0.74,1.3,1.61,1.51,2.69c0.07,0.36,0.1,0.74,0.09,1.14s-0.06,0.84-0.15,1.29c-0.2,10.58-0.29,21.16-0.66,31.74
		c-0.19,5.29-1.19,10.56-1.28,15.85C1119.39,484.52,1119.5,485.82,1119.68,487.12z M1124.94,439c-0.12-6.26-0.99-12.51-1.28-18.77
		c-0.14-2.92,0.28-5.86,0.45-8.75c2.98,1.05,5.6,1.98,8.52,3c0.74,2.13,1.6,4.62,2.51,7.25c3.68-0.89,1.28-3.91,2.44-5.94
		c7.34,4.44,14.73,8.93,21.83,13.22c-0.46,23.49-0.92,47.03-1.38,70.8c-11.68-3.7-22.98-7.27-35.08-11.1c0-1.99-0.42-4.98,0.07-7.81
		C1125.45,467,1125.2,452.99,1124.94,439z M1162.29,487.23c-0.22-8.69-0.57-17.39-0.68-26.08c-0.12-9.63-0.14-19.26,0.05-28.88
		c0.04-2.28,1.08-4.53,1.81-7.41c0,22.95,0,45.11,0,67.26c-0.39,0-0.78,0-1.17,0C1162.3,490.49,1162.34,488.86,1162.29,487.23z
		 M1162.74,424.88c-0.41-0.04-0.81-0.09-1.22-0.13c-1.04,0.02-2.29,0.42-3.09-0.01c-6.23-3.4-12.34-7.01-18.55-10.45
		c-3.32-1.84-6.75-3.47-10.18-5.21c0-1.97,0-4.33,0-6.68c4,2.94,8.06,6.18,12.38,9.03c4.85,3.21,9.96,6.03,14.91,9.1
		c0.83,0.51,1.62,1.09,2.4,1.69c0.78,0.6,1.55,1.21,2.33,1.79c0.37,0.16,0.74,0.32,1.12,0.48
		C1162.81,424.62,1162.77,424.75,1162.74,424.88z M1134.22,554.46c0.41,6.36-0.34,12.79-0.67,19.18c-0.04,0.79-0.74,1.54-1.92,2.13
		c-1.2-20.57-2.41-41.13-3.61-61.7c0.39-0.18,0.78-0.36,1.17-0.54c0.53,1.06,1.42,2.07,1.53,3.17
		C1131.99,529.29,1133.42,541.86,1134.22,554.46z M1171.66,450.77c-0.26,4.5-0.42,9.01-0.79,13.51c-0.61,7.42-1.37,14.83-2.05,22.25
		c-0.07,0.71-0.07,1.43-0.07,2.15c0,11.76,0,23.53,0,35.29c0,1.42,0,2.85,0,5.19c-3.44-1.66-6.43-2.92-9.25-4.48
		c-5.34-2.95-10.49-6.25-15.9-9.07c-3.52-1.84-7.09-3.59-10.67-5.32c-3.58-1.73-7.17-3.44-10.75-5.18
		c-0.51-0.12-1.01-0.25-1.52-0.37c-0.51-0.12-1.01-0.24-1.53-0.32c-5.87-0.95-6.03-0.62-5.07-8.48c1.95,1.76,3.63,3.28,5.31,4.8
		c8.43,7.38,19.92,8.15,29.69,13.55c4.23-2.93,0.79-7.12,1.59-10.79c2.54,0.45,5.37,0.95,8.59,1.52c0.85-1.03,1.86-2.24,2.82-3.41
		c0,5.52,0,11.15,0,16.77c0.28,0.04,0.55,0.08,0.83,0.12c0.28-0.67,0.76-1.32,0.8-2c0.53-8.28,0.61-16.61,1.55-24.84
		c2.23-19.61,0.97-39.26,1.35-58.89c0-0.12-0.03-0.25,0.01-0.36c2.7-7.86-2.56-11.02-8.22-13.98c-0.23-0.12-0.45-0.26-0.66-0.4
		c-0.64-0.44-1.23-0.98-1.86-1.44c-0.49-0.33-0.98-0.67-1.46-1c-0.49-0.24-0.97-0.48-1.46-0.73l0.02,0.02
		c-0.72-0.48-1.45-0.97-2.17-1.45c0,0,0.02,0.02,0.02,0.02c0,0,0,0,0,0l0,0c-4.55-3.11-9.09-6.21-13.64-9.32l0.01,0.01
		c-0.72-0.49-1.44-0.97-2.16-1.46l0.02,0.02c-0.72-0.48-1.45-0.97-2.17-1.45l0.02,0.02c-0.72-0.48-1.45-0.97-2.17-1.45l0.02,0.02
		c-1.28-0.47-2.56-0.94-4.08-1.49c0,3.11,0,6.04,0,9.33c-0.95-0.52-1.7-0.93-2.46-1.35c-3.15-0.31-2.47,2.34-2.68,3.86
		c-0.27,1.98-0.44,3.97-0.59,5.97c-0.15,2-0.26,4-0.42,5.99c0.07,1.12,0.13,2.24,0.21,3.51c0.02,0.42,0.05,0.86,0.08,1.31
		c-2.01-1.28-3.72-2.37-5.9-3.76c0.28-1.25,0.59-2.63,0.89-4.01c0,0,0,0,0,0c0.18-4.66,0.64-9.34,0.59-13.98
		c-0.01-0.66-0.03-1.33-0.06-1.99c-0.32-6.7-1.3-13.37-2.02-20.2c9.61,4.21,18.05,9.83,25.94,16.49
		c7.99,6.75,16.88,12.17,26.87,15.63c1.99,0.69,3.81,1.89,6.44,3.22C1172.95,428.99,1172.3,439.88,1171.66,450.77z M1131.81,389.53
		c0.06-0.21,0.12-0.42,0.19-0.62c0.4,0.08,0.8,0.17,1.21,0.25c-0.01,0.13-0.01,0.25-0.02,0.38
		C1132.73,389.53,1132.27,389.53,1131.81,389.53z M1133.94,390.99c0.08-0.2,0.16-0.4,0.24-0.59c0.4,0.09,0.8,0.17,1.21,0.26
		c-0.01,0.11-0.02,0.22-0.02,0.34C1134.89,390.99,1134.42,390.99,1133.94,390.99z M1136.68,392.34
		c12.14,7.77,25.79,13.04,36.75,22.77C1158.9,411.24,1148.46,400.72,1136.68,392.34z M1177.11,409.85
		c-13.39-7.42-26.94-14.92-40.89-22.65C1145.29,387.48,1176.27,405.06,1177.11,409.85z M1135.63,380.27
		c-2.24-1.1-3.46-1.69-5.07-2.48c-0.41-12.21-0.82-24.51-1.22-36.63c0.24-0.26,0.32-0.41,0.37-0.4c0.94,0.14,1.87,0.3,2.98,0.49
		C1133.65,353.94,1134.6,366.63,1135.63,380.27z"
          />
          <path
            className="st3"
            d="M1178.08,613.96c-5.41-3.39-10.77-6.87-16.25-10.14c-15.59-9.32-31.27-18.49-46.84-27.84
		c-5.69-3.41-11.17-7.16-16.67-10.71c-0.89,2.17-1.43,4.11-2.42,5.79c-2.65,4.5-3.44,9.37-1.59,14.09c1.45,3.71,4.29,6.86,6.25,10.4
		c1.61,2.9,1.48,7.29-1.73,9.05c-2.95,1.62-6.03,0.7-7.87-2.96c-1.34-2.68-2.33-5.57-3.16-8.46c-0.71-2.46-0.98-5.05-1.4-7.33
		c-3.75,2.58-7.06,5.29-10.78,7.2c-1.66,0.86-4.41,0.71-6.2-0.07c-2.32-1-3.1-3.43-2.12-6.05c10.95-6.02,21.6-12.35,25.15-25.86
		c-8.68-4.69-17.61-9.54-26.57-14.33c-2.44-1.3-4.99-2.39-7.48-3.57c-0.24,0.36-0.48,0.71-0.72,1.07c1.25,1.92,2.28,4.05,3.81,5.72
		c4.38,4.76,7.71,10.06,8.46,16.55c0.29,2.53,0.14,5.45-0.88,7.7c-1.23,2.71-3.15,5.54-7.88,5.29c-1.31-5.36-2.63-10.71-3.8-15.49
		c-1.53,3.28-2.98,7.25-5.18,10.73c-1.02,1.62-3.66,3.33-5.31,3.11c-1.59-0.22-3.28-2.67-4.17-4.5c-1.77-3.63-0.22-7.1,1.71-10.2
		c2.78-4.47,5.81-8.78,8.75-13.15c1.34-1.99,0.82-3.59-0.65-5.37c-2.02-2.45-4.04-5-5.46-7.81c-1.74-3.44-2.86-4.48-6.41-3.01
		c-5.61,2.32-11.74-1.97-9.56-8.89c-4.44-2.43-8.9-4.88-13.39-7.34c-1.13,3.61-0.81,8.25-6.61,7.97c0,1.4,0.31,2.5-0.07,3.25
		c-0.74,1.49-1.65,3.64-2.87,3.94c-1.13,0.27-3.23-1.21-4.07-2.47c-1.17-1.74-1.56-4-2.28-6.04c-0.58,7.56-2.47,14.76-7.22,20.75
		c-2.44,3.09-5.63,5.68-8.81,8.05c-1.19,0.89-3.34,0.49-6.04,0.78c0.31-0.37,0.61-0.73,0.89-1.07c2-2.4,3.44-4.13,4.87-5.85
		c-0.07-0.1-0.15-0.21-0.23-0.3c-2.44,0.23-4.8,0.23-6.26-2.75c0.49-2.52,2.7-3.22,5.04-3.63c8.04-1.41,11.17-5.19,11.32-13.48
		c0.09-4.96,0.5-9.92,0.65-14.89c0.03-1.09-0.47-2.19-0.72-3.29c-0.21,0.12-0.41,0.23-0.61,0.35c-1.48,0.83-2.95,1.66-4.39,2.47
		c0.6,1.78,1.1,3.34,1.64,4.89c1.14,3.28,2.97,6.64,1.15,10.05c-1.07,2.02-2.79,4.3-4.77,5.09c-2.78,1.11-4.36-1.76-4.98-3.9
		c-1.5-5.15-2.47-10.46-3.68-15.83c-2.32-1.36-4.97-2.9-7.5-4.38c4.31,18.32-4.74,30.7-17.14,41.14c0.86,5.07,1.67,9.88,2.49,14.7
		c1.03,0.47,2.33,0.69,3.06,1.46c6.41,6.77,10.69,14.6,10.37,24.18c-0.24,7.2-0.14,14.64-1.93,21.52
		c-2.7,10.44-7.59,20.05-14.53,28.61c-5.65,6.97-11.72,13.34-19.19,18.33c-0.63,0.42-1.04,1.18-1.56,1.79
		c7.74,1.04,13.09,6.03,18.21,11.26c1.35,1.38,2.36,3.25,3.06,5.08c2.05,5.36,4.12,10.75,5.68,16.27c0.76,2.71,0.14,5.78,0.64,8.6
		c0.51,2.82,1.17,5.8,2.59,8.23c4.58,7.86,9.86,14.86,20.32,15.3c3.61,0.15,7.21,1.56,10.73,2.67c4.82,1.52,8.12,4.66,9.08,9.74
		c7.18,2.25,11.21,8.25,9.9,15.09c-1.4,7.35-7.12,10.7-14.06,13.28c5.26,4.73,6.04,10.59,7.06,16.37c1.39,3.6,2.79,7.19,4.18,10.79
		c4.26,4.55,8.17,9.53,9.04,15.77c0.75,5.36,0.19,10.91,0.21,16.37c1.49,5.51-1.49,14.81-5.56,17.32c-0.98,4.69-2.85,6.29-7.01,6.49
		c-4.3,2.92-8.04,5.72-12.02,8.1c-2.63,1.57-5.01-0.05-6.38-2.16c-1.27-1.96-1.71-4.45-2.52-6.71c-3.01,1.48-6.08,2.86-9.03,4.45
		c-13.25,7.17-26.57,14.21-39.66,21.65c-13.59,7.73-26.83,16.08-40.45,23.75c-10.42,5.87-21.13,11.23-31.83,16.58
		c-9.88,4.93-19.93,9.52-29.88,14.3c-7.06,3.39-14.09,6.85-21.13,10.27c0.81,3.87-1.11,7.75-4.53,9.28
		c-0.71,0.32-1.61,0.69-1.95,1.3c-1.98,3.55-5.01,4.29-8.62,3.44c-3.02,2.82-5.67,3.84-7.96,3.06c-0.48-0.16-0.94-0.42-1.39-0.74
		c-0.62-0.45-1.22-1.02-1.78-1.77c-0.97-1.29-1.85-3.02-2.64-5.21c-0.39,1.01-0.9,1.87-1.51,2.61c-0.15,0.18-0.31,0.36-0.47,0.53
		c-0.33,0.34-0.67,0.66-1.04,0.95c-1.65,1.31-3.67,2.17-5.73,2.98c-5.47,2.16-10.89,4.44-16.38,6.54c-1.14,0.44-2.51,0.46-3.77,0.4
		c-3.33-0.16-6.65-0.69-9.98-0.69c-8.69,0-17.63,0.49-24.09-6.67c-2.69-2.98-4.37-6.97-6.06-10.7c-0.58-1.27,0.21-3.16,0.41-5.2
		c-0.96-1.66-2.22-3.77-3.42-5.91c-2.8-4.99-2.51-9.42,1.93-13.08c6.64-5.49,13.75-10.42,20.66-15.58
		c0.25-17.5,0.51-34.99,0.74-52.49c0.07-5.24,0.22-10.49,0.05-15.72c-0.37-11.04,1.75-21.78,3.47-32.59
		c0.89-5.58,0.88-11.29,1.61-16.9c2.06-15.59,6.88-30.08,18-41.72c5.75-6.02,13.21-8.77,21.36-9.55c2.16-0.21,4.4,0.38,7.49,0.7
		c3.19-9.54,6.39-19.13,9.59-28.71c-10.94,3.01-21.35,7.32-31.15,13.02c-8.69,5.06-16.15,11.66-23.18,18.82
		c-12.43,12.64-24.56,25.68-39.33,35.68c-10.22,6.92-20.75,13.41-31.37,19.7c-8.49,5.04-17.26,9.62-26.02,14.18
		c-16.2,8.44-31.72,18.34-49.44,23.6c-7.07,2.1-14.24,3.86-21.72,3.24c-10.66-0.88-18.58-8.86-20.03-19.36
		c-0.6-4.32-0.4-8.5,0.14-12.62c0.2-1.55,0.45-3.08,0.72-4.62c0.36-2.05,0.77-4.09,1.17-6.15c0.1-0.51,0.2-1.03,0.29-1.54v0
		c-1.19,0.71-2.37,1.42-3.51,2.1c-1.13,0.68-2.22,1.33-3.2,1.91c0.67,1.96,1.24,3.61,1.81,5.25c-0.31,0.19-0.62,0.37-0.93,0.56
		c-1.98-0.82-3.96-1.63-6.04-2.49c-5.19,3.24-10.52,6.59-15.88,9.9c-8.72,5.39-17.49,10.72-26.18,16.14
		c-1.83,1.14-3.47,2.61-4.98,3.77c-2.33,0.57-4.8,0.71-6.8,1.75c-6.32,3.29-12.45,6.93-18.68,10.4
		c-8.49,4.73-16.99,9.43-25.49,14.15c-6.51,3.61-13.01,7.23-19.47,10.82c2.98,8.8,4.05,17.75-0.41,26.64
		c0.26,0.51,0.71,1.01,0.74,1.54c0.53,7.51-6.39,15.64-13.83,16.38c-11.09,1.11-21.96-0.59-32.68-3.04
		c-4.43-1.01-7.44-4.69-8.39-8.97c-1.08-4.85-4.61-6.78-8.13-9.1c-27.9-18.37-55.83-36.71-83.67-55.17
		c-14.33-9.5-28.42-19.37-42.79-28.81c-6.11-4.02-12.66-7.38-19.01-11.04c-0.57,1.57-1.14,3.13-1.71,4.69
		c-1.23,3.36-2.45,6.71-3.66,10.02c-1.77,4.86-3.52,9.64-5.21,14.27c2.6,5.61,5.33,11.12,7.74,16.76c2.99,6.98,2.73,14.5,2.73,21.85
		c0,3.55-1.31,7.29-2.84,10.57c-0.67,1.43-3.37,1.9-5.15,2.81c-0.66-1.63-1.32-3.25-1.98-4.88c-14.03,7.64-28.06,15.28-42.08,22.92
		c0,0.18,0,0.36,0,0.53c-0.01,0.88-0.08,1.73-0.29,2.5c-0.43,1.54-1.46,2.78-3.76,3.37c-0.75-0.55-2.04-1.04-2.56-1.97
		c-2.07-3.72-3.9-7.57-5.5-10.73c-10.56,3.15-21.15,6.61-31.91,9.44c-8.39,2.21-16.81,1.55-24.66-2.51
		c-3.33-1.72-6.2-1.65-9.05,0.74c-1.53,1.28-3.09,2.53-4.64,3.79c-6.57,5.33-13.64,9.73-21.96,11.79c-1.09,0.27-2.18,0.42-3.27,0.48
		c-0.36,0.02-0.72,0.02-1.08,0.02c-0.36-0.01-0.71-0.02-1.07-0.05c-2.82-0.23-5.44-1.18-7.37-2.7c-0.48-0.38-0.93-0.8-1.32-1.25
		c-0.2-0.23-0.38-0.46-0.55-0.7c-5.93,3.45-11.82,6.98-17.8,10.34c-3.58,2.01-7.32,3.75-10.99,5.61c0.26,7.86,0.83,15.72,0.71,23.57
		c-0.28,19.18-0.97,38.35-1.32,57.53c-0.21,11.31-0.01,22.62-0.13,33.93c-0.11,9.53-0.71,19.07-0.57,28.6
		c0.25,17.14,0.88,34.28,1.4,51.41c0.05,1.65,0.77,3.32,0.59,4.91c-0.91,7.87,0.81,11.35,8.41,15.18c1.31-2.14,2.67-4.34,4.24-6.9
		c2.26,0.21,4.85,0.55,7.45,0.67c3.1,0.14,6.22,0.3,9.3,0.08c7.8-0.56,15.58-1.96,23.37-1.91c26.33,0.18,52.66,0.77,78.99,1.34
		c15.46,0.34,30.92,1.01,46.38,1.39c14.06,0.35,28.14,0.46,42.2,0.76c5.11,0.11,10.21,0.44,15.31,0.72
		c12.8,0.69,25.59,1.74,38.4,2.02c10.94,0.24,21.9-0.59,32.86-0.57c14.9,0.03,29.8,0.51,44.7,0.65c32.09,0.3,64.18,0.39,96.27,0.79
		c11.89,0.15,23.77,0.87,35.65,1.37c16.62,0.7,33.24,1.73,49.87,2.04c9.36,0.17,18.74-1.19,28.12-1.23
		c14.42-0.07,28.85,0.36,43.27,0.66c4.27,0.09,8.55,0.77,12.81,0.6c14.13-0.55,28.24-1.69,42.37-1.96
		c12.26-0.24,24.55,0.76,36.82,0.52c15.2-0.3,30.4-1.21,45.58-2.07c8.99-0.51,17.95-1.42,26.92-2.19
		c10.74-0.92,21.46-2.14,32.21-2.73c10.82-0.59,21.67-0.86,32.5-0.72c18.33,0.23,36.65,0.83,54.97,1.37
		c13.08,0.39,26.14,1.04,39.22,1.4c13.34,0.36,26.69,0.44,40.04,0.75c17.26,0.41,34.51,1.25,51.76,1.32
		c12.35,0.05,24.7-1.09,37.06-1.28c31.46-0.48,62.93-0.79,94.4-0.96c2.38-0.01,3.48-0.9,3.94-2.62c1.06-3.98,2.49-8.04,2.5-12.07
		c0.05-17.28-0.31-34.56-0.62-51.84c-0.1-5.58-0.43-11.16-0.72-16.74c-0.2-3.91-0.44-7.82-0.79-11.72
		c-0.89-10.02-2.51-20.02-2.59-30.04c-0.09-10.89,1.34-21.79,1.92-32.69c0.61-11.4,1.41-22.81,1.4-34.21
		c-0.02-32.81-0.47-65.62-0.59-98.43c-0.04-10.84,0.42-21.68,0.64-32.52c0.47-23.11,1.03-46.22,1.36-69.34
		c0.11-7.62-0.43-15.24-0.59-22.86c-0.1-4.65,0.16-9.32-0.09-13.97c-0.59-11.13-1.87-22.25-1.97-33.38
		c-0.06-6.93-0.02-13.86,0.06-20.79C1177.66,637.06,1177.93,625.51,1178.08,613.96L1178.08,613.96z M89.25,919.73
		c-4.35,2.07-9.15,3.38-13.89,4.4c-3.75,0.81-4.82,0.01-7.2-2.8c2.5-6.43,12.55-12.25,15.34-9.78c2.45,0.11,4.65-0.38,6.22,0.4
		C92.8,913.49,92.73,918.07,89.25,919.73z M86.42,904.83c-0.5-3.44,1.47-6.31,4.89-7.13c3.42-0.83,4.71,0.04,6.22,4.16
		C95.4,904.78,90.01,906.23,86.42,904.83z M1090.72,966.38c-2.25,1.38-4.84,2.17-7.19,2.38h0h0c-1.06,0.76-2.1,1.53-3.14,2.31
		c-1.56,1.17-3.12,2.35-4.7,3.49c-1.06,0.76-2.12,1.49-3.21,2.19c-0.54,0.35-1.1,0.69-1.65,1.02c-15.45,9.09-31,18.04-46.63,26.83
		c-10.84,6.1-21.88,11.86-33.05,17.88c-1.07-0.77-2.21-1.58-3.69-2.64c-2.96,1.58-6.11,3.45-9.43,4.98
		c-0.61,0.28-1.24,0.53-1.87,0.77c-1.9,0.7-3.87,1.24-5.79,1.89c0.04,0.91-0.04,1.81-0.21,2.7c-0.04,0.21-0.09,0.43-0.14,0.64
		c-0.36,1.49-0.98,2.93-1.82,4.29c-0.44,0.71-0.91,1.4-1.45,2.06c-0.66,0.81-1.39,1.57-2.18,2.28c-0.79,0.71-1.62,1.37-2.5,1.96
		c-0.44,0.3-0.89,0.58-1.34,0.84c-0.91,0.53-1.84,0.98-2.79,1.36c-0.95,0.38-1.91,0.68-2.86,0.9c-0.62,0.14-1.31,0.03-2-0.15
		c-0.7-0.18-1.41-0.43-2.09-0.56c-3.83,1.15-7.63,2.46-11.46,3.67c-3.82,1.21-7.66,2.32-11.57,3.06
		c-9.72,1.85-19.65,3.11-29.59,1.24c-4.87-0.91-10.29-7.09-10.16-11.83c0.04-1.59,1.04-3.7,2.3-4.61
		c5.64-4.05,11.53-7.75,17.33-11.58c0.97-0.73,1.94-1.46,2.91-2.19c1.42-1.13,2.85-2.26,4.27-3.39c-4.82-2.49-9.47-5.12-12.6-9.85
		c-3.9-5.89-5.57-11.87-1.51-18.37c-4.79-2.38-6.7-6.28-7.3-11.17c-1.71-13.93,3.97-25.38,11.96-36.04
		c2.48-3.31,5.35-6.34,8.25-9.74c-1.68-0.77-3.09-1.42-5.45-2.49c1.8-1.66,2.95-3.34,4.54-4.07c6.23-2.84,12.78-5.03,18.86-8.14
		c5.94-3.03,11.44-6.92,17.16-10.38c12.59-7.61,24.78-16.03,37.92-22.53c13.63-6.75,27.99-12.2,42.39-17.17
		c12.34-4.26,25.33-3.94,38.09-1.27c9.62,2.01,16.67,11.37,17.71,21.09c0.93,8.68-3.33,15.23-6.73,21.57
		c3.75,3.18,7.82,5.96,10.96,9.56c1.74,2,2.36,5.3,2.71,8.11c1.07,8.53-1.57,16.59-3.73,24.68c-0.47,1.77-0.82,3.57-1.13,4.98
		c3.19,0.39,6.25,0.76,9.32,1.13C1095.93,961.86,1093.61,964.61,1090.72,966.38z M1167.99,640.91c-1.19,2.05-2.73,3.82-4.25,4.63
		c-0.36,0.75-0.6,1.73-0.96,2.49c-0.22,0.45-0.48,0.83-0.84,1.03c-6.58,3.69-13.42,6.9-19.93,10.69c-4.48,2.61-8.11,2.33-11.64-1.41
		c-6.76-7.17-13.61-14.27-20.36-21.45c-3.47-3.69-6.81-7.52-10.58-11.7c1.33-0.23,1.98-0.34,2.63-0.46c-0.81-1-1.62-2-2.48-3.05
		c0.56-2.35,1.15-4.81,1.6-6.69c12.04-4.37,23.69-8.56,35.3-12.85c2.55-0.94,6.74,0.56,8.99,3.23c4.65,5.51,9.35,10.97,14.19,16.31
		c3,3.31,6.31,6.33,9.44,9.52c0.47,0.47,0.92,1.13,1.03,1.76C1170.49,635.15,1169.52,638.27,1167.99,640.91z"
          />
          <path
            className="st3"
            d="M43.47,897.86c10.1-6.07,20.35-11.89,30.43-17.75c0-1.71,0.05-2.9-0.01-4.08c-0.7-13.75-1.49-27.5-2.11-41.25
		c-0.38-8.32-0.62-16.65-0.72-24.98c-0.12-10.19-0.03-20.39-0.03-31.22c-1.7,0.8-2.47,1.07-3.14,1.5
		c-8.15,5.28-16.45,10.34-24.37,15.94c-8.18,5.79-16.81,10.52-26.29,13.74c0.49,19.28,0.85,38.57,1.53,57.85
		c0.26,7.42,0.62,14.84,1.01,22.26c0.39,7.42,0.81,14.83,1.18,22.25c0,0,0,0,0,0C28.46,907.36,35.87,902.44,43.47,897.86z
		 M47.46,819.51c-0.26-3.26,3.85-5.84,7.15-4.49c5.64,2.3,10.23,7.35,7.62,16.51c-1.57,5.48-2.92,11.04-4.11,16.61
		c-0.37,1.71-0.07,3.64,0.27,5.4c0.59,2.99-0.6,5.33-3.42,5.77c-2.83,0.45-3.64-1.64-3.96-3.78c-1.32-8.67-2.51-17.36-3.75-26.05
		c0.39-0.05,0.77-0.1,1.16-0.15C48.1,826.07,47.72,822.8,47.46,819.51z"
          />
          <path
            className="st3"
            d="M26.03,793.89c9.9-6.37,19.82-12.74,29.99-18.67c8.48-4.95,17.34-9.23,25.99-13.9
		c1.8-0.97,3.56-1.99,5.32-3.03c1.76-1.04,3.51-2.09,5.27-3.12c0,0,0,0,0,0c-2.2-0.24-4.29-0.71-6.26-1.43
		c-3.29-1.2-6.25-3.08-8.84-5.75c0.03,0.78,0.07,1.57,0.07,2.34c0,0.26,0,0.51-0.01,0.77c-0.01,0.51-0.05,1.01-0.11,1.49
		c-0.06,0.49-0.15,0.96-0.28,1.42c-0.51,1.84-1.65,3.42-4.04,4.47c-0.19,0.09-0.39,0.32-0.46,0.52c-1.56,4.7-5.4,6.43-9.76,7.29
		c-1.63,0.32-3.38,0.05-5.07,0.06c-0.8,4.62-2.93,7.86-7.79,9.37c-5.51,1.72-10.76,4.27-16.22,6.16c-2.84,0.98-5.25-1.15-7.51-4.95
		c-1.73-2.92-1.06-4.92,1.37-6.72c5.83-4.29,11.82-8.3,19-10.05c-0.06-0.46-0.1-0.93-0.15-1.39c-0.05-0.46-0.11-0.93-0.19-1.38
		c-1.33-7.27-4.06-14.61,1.96-21.81c-5.87,1.07-9.95-0.97-13.2-4.36c-4.64,2.2-9.17,4.34-13.76,6.52c-1.83-3.05-1.52-4.82,0.99-6.22
		c3.25-1.8,6.44-3.71,9.63-5.62c0.66-0.4,1.63-0.97,1.7-1.55c0.56-4.82,3.43-6.37,6.86-5.86c4.14-2.81,7.49-5.21,11-7.37
		c0.7-0.43,2.02,0.26,2.99,0.09c1.24-0.21,2.42-0.77,3.62-1.18c1.84-1.86,3.48-4.01,5.56-5.53c4.65-3.4,9.47-6.57,14.33-9.66
		c1.52-0.97,3.41-1.37,5.13-2.03c-0.11-1.55-0.26-3.1-0.41-4.65c-0.15-1.55-0.31-3.1-0.44-4.65c-0.28-3.1-0.48-6.2-0.42-9.29
		c0.18-9.66,1.78-19,10.7-25.05c7.45-0.84,13.43,3.21,15.85,10.75c2.3,7.16,2.72,14.15,0.34,22.28c2.38-1.89,4.41-2.89,5.57-4.52
		c3.79-5.32,9.48-7.76,14.91-10.73c6.56-3.59,13-7.41,19.38-11.33c6.4-3.93,12.67-8.06,18.96-12.15c4.97-3.23,9.9-6.54,14.39-9.51
		c0-5.1,0-9.25,0-13.41c-1.36,1.72-2.38,4.02-4.15,5.05c-7.27,4.21-15.25,6.06-23.54,4.4c-6.97-1.39-13.55-2.11-20.34,1.07
		c-9.62,4.52-19.5,8.46-29.25,12.68c-4.08,1.76-8.12,3.61-12.17,5.42c0.01,0,0.03,0,0.04,0c-3.21,1.44-6.45,2.83-9.63,4.34
		c-18.14,8.59-35.55,18.47-52.26,29.61c-3.91,2.61-8.4,4.35-12.62,6.49c-0.04,8.03,0.06,16.07-0.04,24.11
		c-0.03,2.7-0.09,5.4-0.19,8.09c-0.34,9.84-1.73,19.65-1.88,29.48c-0.19,13.1,0.34,26.21,0.63,39.31c0.04,1.72,0.15,3.44,0.27,5.15
		s0.27,3.43,0.39,5.15C20.18,798.55,22.9,795.9,26.03,793.89z"
          />
          <path
            className="st3"
            d="M1161.04,344.29c-14.86-8.63-29.69-17.3-44.6-25.82c-19.11-10.91-38.48-21.38-57.39-32.63
		c-12.75-7.59-24.78-16.39-37.34-24.32c-19.9-12.57-39.98-24.85-59.98-37.27c-11.86-7.36-23.29-15.53-35.64-21.95
		c-22.75-11.83-43.73-26.42-65.41-39.93c-9.48-5.91-19.23-11.38-29.22-17.26c0,3.32,0,6.13,0,8.84c10.68,6.71,21.3,13.54,32.08,20.1
		c8.27,5.03,16.83,9.6,25.12,14.6c15.33,9.25,30.66,18.5,45.79,28.07c11.89,7.53,23.41,15.63,35.21,23.31
		c13.35,8.7,26.79,17.27,40.27,25.77c6.62,4.18,13.29,8.31,20.16,12.07c11.5,6.3,21.88,14.32,34.05,19.75
		c16.84,7.51,33.07,16.54,49.12,25.68c13.5,7.69,28.1,13.45,40.39,23.33c4.24,3.4,9.43,5.65,14.28,8.25
		c5.45,2.92,11.02,5.63,16.53,8.43c0.02-1.77,0.05-3.53,0.07-5.3C1176.69,353.44,1168.88,348.84,1161.04,344.29z"
          />
          <path
            className="st4"
            d="M49.66,660.35c4.77-3.07,5.26-3.92,4.21-9.25c-1.75-8.83-3.44-17.68-4.99-26.55
		c-1.35-7.75-2.4-15.55-3.68-23.31c-0.88-5.29-1.94-10.55-2.91-15.83c-6.33,2.07-12.69,4.04-18.96,6.27
		c-1.46,0.52-2.59,1.95-3.88,2.96c-0.24,4.63-0.7,9.26-0.66,13.88c0.12,14.65,0.57,29.31,0.62,43.96c0.02,4.7-0.09,9.4-0.22,14.1
		c-0.07,2.42-0.15,4.83-0.22,7.25c-0.07,2.28-0.14,4.57-0.2,6.85C29.06,673.9,39.29,667.02,49.66,660.35z"
          />
          <path
            className="st3"
            d="M1158.37,353.38c-7.36-4.2-14.92-8.04-22.83-12.27c1.08,13.75,2.13,27.24,3.18,40.62
		c0.42,0.34,0.66,0.67,0.98,0.77c14.35,4.64,26.26,13.5,38.29,22.14c1.03,0.74,2.14,2.15,2.2,3.3c0.18,3.91-0.09,7.85-0.19,11.78
		c0.31,1.94,0.57,3.89,0.96,5.82c0.26,1.29,0.69,2.55,1.05,3.83c0.52-6.31,1.15-12.61,1.66-18.92c0.02-0.19,0.03-0.38,0.05-0.57
		c0.2-2.47,0.38-4.95,0.52-7.42c0.07-1.28,0.14-2.57,0.2-3.85c0.26-6.17,0.24-12.35,0.18-18.54c-0.03-3.71-0.08-7.42-0.07-11.13
		C1175.82,363.73,1167.18,358.4,1158.37,353.38z"
          />
          <path
            className="st3"
            d="M1177.25,544.3c-0.14-0.19-0.28-0.38-0.42-0.57c-1.53,0.85-2.99,1.9-4.62,2.46c-0.98,0.34-2.35,0.21-3.33-0.21
		c-5.31-2.3-10.54-4.76-15.81-7.16c-1.45,0.71-2.91,1.42-4.36,2.14c1.04,0.69,2.08,1.38,3.71,2.46c-0.45,1.67-0.96,3.56-1.47,5.45
		c-0.5-0.01-1-0.02-1.49-0.03c-0.14-1.3-0.29-2.6-0.46-4.18c-3.07-1.55-4.8-0.91-8.18,3.43c1.77,5.58,4.76,6.51,11.94,3.53
		c0.05,0.97,0.09,1.85,0.13,2.63c-4.05,1.89-8.31,1.48-10.82-0.98c-2.04-2.01-3.1-4.34-1.97-7.22c0.18-0.45,0.37-0.86,0.59-1.25
		c1.54-2.7,4.17-3.91,8.1-3.79c-1.29-0.67-2.49-1.4-3.42-2.34c-0.93-0.93-1.58-2.07-1.77-3.54c-0.05-0.42-0.07-0.87-0.04-1.35
		c-2.56-0.96-5.13-1.92-7.69-2.87c0.1,1.35,0.23,2.69,0.34,4.04c0.11,1.35,0.21,2.69,0.27,4.04c0.37,8.3,0.66,16.61,1.01,24.91
		c0.16,3.91,0.4,7.82,0.6,11.73c0.34,0.04,0.68,0.08,1.02,0.12c0.23-1.09,0.64-2.17,0.64-3.26c-0.01-2.47-0.61-4.99-0.28-7.39
		c0.93-6.7,5.82-8.55,10.87-4.62c1.39-1.01,2.7-1.97,4.02-2.94c0.21,0.25,0.43,0.49,0.64,0.74c-1.19,1.65-2.38,3.3-3.13,4.35
		c0.16,2.53,0.52,4.4,0.35,6.23c-0.73,7.54-4.47,10.05-10.03,6.6c-1,1.39-2,2.79-3.25,4.53c5.14,2.98,9.93,5.84,14.79,8.55
		c4.03,2.25,8.07,4.47,12.12,6.68s8.1,4.43,12.14,6.64v0c0.01-1.07,0.05-2.14,0.02-3.2c-0.21-7.1-0.71-14.19-0.59-21.28
		c0.1-5.98,0.3-11.95,0.53-17.92s0.49-11.95,0.7-17.92C1178.19,542.48,1177.72,543.39,1177.25,544.3z"
          />
          <path
            className="st5"
            d="M30.69,683.12c16.71-11.15,34.12-21.02,52.26-29.61c3.18-1.51,6.42-2.9,9.63-4.34c-0.01,0-0.03,0-0.04,0
		c4.06-1.81,8.1-3.66,12.17-5.42c9.75-4.22,19.64-8.17,29.25-12.68c6.79-3.19,13.37-2.46,20.34-1.07c8.3,1.65,16.28-0.19,23.54-4.4
		c1.76-1.02,2.79-3.33,4.15-5.05v0c0-2.37,0-4.74,0-7.08c-2.07,0.81-3.52,1.78-5.03,1.91c-7.31,0.63-14.65,0.96-21.97,1.54
		c-3.76,0.3-7.5,1.29-11.24,1.26c-5.11-0.04-10.32,2.25-15.4-0.54c-0.74-0.41-2.25,0.03-3.14,0.53
		c-12.08,6.77-24.22,13.43-36.11,20.52c-6.88,4.1-13.23,9.11-20.04,13.34c-4.85,3.01-9.95,5.32-14.41,9.25
		c-10.85,9.54-22.93,17.49-36.08,23.66c-0.17,0.08-0.36,0.12-0.57,0.14c-0.61,0.04-1.32-0.14-1.96-0.16c0,0,0,0,0,0
		c-0.39,0.57-0.79,1.13-1.31,1.89c-0.32,0.45-0.68,0.97-1.12,1.6c0.48,0.13,0.92,0.25,1.33,0.36c1.23,0.34,2.18,0.6,3.13,0.86
		C22.3,687.47,26.78,685.73,30.69,683.12z"
          />
          <path
            className="st5"
            d="M1178.08,535.29c-0.9-2.21-1.86-4.4-2.79-6.59c0,0,0,0,0,0c-0.31,0.73-0.62,1.46-1,2.36
		c-0.23,0.54-0.48,1.14-0.78,1.83c-14.48-1.91-25.21-12.72-39.36-16.16c0.6,4.91,1.16,9.56,1.73,14.21v0
		c2.56,0.96,5.13,1.92,7.69,2.87c0.34-1.6,0.43-3.3,1.07-4.77c0.8-1.85,1.88-3.38,4.59-2.63c2.59,0.72,2.9,2.48,3,4.54
		c0.04,0.76-0.25,1.54-0.5,2.96c-1.2-1.5-2.02-2.52-3.37-4.22c-1.07,0.95-2.91,1.81-3.02,2.86c-0.13,1.27,0.89,3.07,1.98,3.94
		c1.02,0.81,2.81,0.61,4.2,1.04c0.6,0.19,1.04,0.85,1.56,1.29c5.26,2.4,10.5,4.86,15.81,7.16c0.98,0.42,2.35,0.55,3.33,0.21
		c1.63-0.56,3.09-1.62,4.62-2.46c0.14,0.19,0.28,0.38,0.42,0.57c0.47-0.91,0.95-1.82,1.42-2.73c0.01-0.07,0.01-0.14,0.02-0.21
		c0.11-1.11,0.21-2.22,0.32-3.32c0,0,0,0,0,0c0,0,0,0,0,0C1178.7,537.12,1178.45,536.18,1178.08,535.29z"
          />
          <path
            className="st3"
            d="M39.73,572.5c-0.87-5.11-1.77-10.38-2.84-16.69c-7.31,3.73-14.33,7.32-21.57,11.02
		c-0.19-0.43-0.36-0.83-0.52-1.2c-0.16-0.37-0.32-0.73-0.47-1.09v0c-1.01,0.76-1.81,1.44-2.39,2.09c-1.75,1.96-1.59,3.68,0.4,6.5
		c1.79,2.54,4.29,4.56,6.37,6.9c0.65,0.72,1.16,1.56,1.68,2.4c0.17,0.28,0.35,0.55,0.53,0.82C27.25,579.64,33.57,576.02,39.73,572.5
		z"
          />
          <path
            className="st5"
            d="M1180.95,425.53c-0.38-1.93-0.65-3.88-0.96-5.82c-0.16,0.56-0.45,1.11-0.47,1.67
		c-0.27,9.75-0.4,19.5-0.77,29.24c-0.35,9.36-0.89,18.71-1.43,28.07c-0.66,11.47-1.42,22.94-2.12,34.41
		c-0.06,1.01-0.1,2.02-0.13,3.03c-0.03,1.01-0.05,2.02-0.09,3.04c0.78-3.66,1.92-7.29,2.29-11c1.14-11.64,1.97-23.31,2.98-34.96
		c0.02-0.18,0.07-0.35,0.14-0.52c0.22-0.51,0.63-1.01,0.9-1.51c0,0,0,0,0,0c0.01-0.32,0.01-0.63,0.02-0.95c0-0.04,0-0.08,0-0.12
		c0.01-0.53,0.01-1.06,0.02-1.59c0.04-2.66,0.07-5.31,0.1-7.97c0.1-3.72,0.21-7.43,0.31-11.15c0.04-1.47,0.08-2.94,0.12-4.41
		c0.01-0.65,0.01-1.29,0.02-1.94c0.05-4.21,0.09-8.41,0.13-12.62c-0.01-0.36-0.02-0.73-0.03-1.09
		C1181.64,428.09,1181.2,426.83,1180.95,425.53z"
          />
          <path
            className="st3"
            d="M1180.25,473.22c-1.01,11.65-1.84,23.32-2.98,34.96c-0.36,3.7-1.5,7.33-2.29,11c0,0-0.1,0.05-0.1,0.05
		c-0.08,2.24-0.16,4.48-0.24,6.73l0,0c0.08,0.35,0.16,0.7,0.25,1.05c0.13,0.57,0.27,1.13,0.4,1.7c0,0,0,0,0,0
		c0.93,2.2,1.89,4.38,2.79,6.59c0.36,0.89,0.62,1.83,0.92,2.75c0,0,0,0,0,0c0.3-2.63,0.72-5.25,0.98-7.88
		c0.08-0.88,0.15-1.75,0.19-2.63c0.57-13.66,1.08-27.32,1.44-40.99c0.13-5.11-0.2-10.23-0.32-15.34c0,0,0,0,0,0
		c-0.27,0.51-0.68,1-0.9,1.51C1180.32,472.87,1180.27,473.04,1180.25,473.22z"
          />
          <path
            className="st5"
            d="M42.29,585.41c0-2.83,0-5.65,0-8.8c-3.78,1.95-6.56,3.44-9.38,4.83c-4.73,2.32-9.49,4.57-14.24,6.85
		c0,0,0,0,0,0c-0.31,0.42-0.71,0.8-0.99,1.23c-0.09,0.14-0.17,0.29-0.23,0.45c-0.9,2.34,0.08,4.52,2.01,4.68
		c1.28-1.01,2.42-2.44,3.88-2.96C29.59,589.45,35.96,587.48,42.29,585.41z"
          />
          <path
            className="st5"
            d="M50.33,904.8c-0.19-0.64-0.37-1.28-0.57-1.95c-0.19-0.67-0.4-1.37-0.62-2.12c-6.62,4.08-12.92,8.27-19.52,11.9
		c-2.19,1.2-4.49,2.2-6.82,3.15c-2.33,0.94-4.69,1.83-7,2.81c0,0,0,0,0,0c0.29,1.21,0.81,1.81,1.44,2.1
		c0.41,0.19,0.87,0.25,1.35,0.25c0,0,0.01,0,0.01,0c0.55-0.01,1.13-0.09,1.7-0.15c0.43-0.04,0.85-0.07,1.24-0.04
		c3.67-1.86,7.4-3.6,10.99-5.61C38.51,911.79,44.4,908.26,50.33,904.8z"
          />
          <path
            className="st6"
            d="M1182.02,430.45c-0.05,4.85-0.1,9.7-0.15,14.56"
          />
          <path
            className="st6"
            d="M1181.43,460.56c-0.04,3.19-0.08,6.37-0.13,9.56"
          />
          <path
            className="st3"
            d="M22.69,803.62C22.69,803.62,22.69,803.62,22.69,803.62c0.85-0.61,1.69-1.23,2.54-1.84
		c6.36-3.91,12.73-7.82,19.09-11.73c0,0,0,0,0,0c0.75-0.48,1.49-0.97,2.24-1.45c-0.12-0.22-0.25-0.43-0.37-0.65
		c-0.81,0.51-1.62,1.02-2.43,1.53l0,0c-6.36,3.91-12.73,7.82-19.09,11.72c0,0,0,0,0,0c-0.79,0.64-1.59,1.28-2.38,1.92
		c-1.67,0.76-3.35,1.53-5.02,2.29c0,0,0,0,0,0c0.02,0.22,0.03,0.45,0.05,0.67c0.02,0.24,0.04,0.48,0.06,0.72
		C19.14,805.74,20.92,804.68,22.69,803.62z"
          />
          <path
            className="st7"
            d="M588.05,778.55c-0.25,0.78-0.49,1.57-0.7,2.37c-1.01,3.76-2.24,7.58-2.43,11.42
		c-0.51,10.24,3.61,19.38,15.19,18.6c4.08-0.28,8.34-1.07,12.09-2.64c22.45-9.36,43.56-21.33,64.54-33.54
		c11.75-6.83,22.8-14.6,32.56-24.12c9.3-9.07,18.48-18.26,27.94-27.16c9.65-9.07,20.26-17.02,32.28-22.6
		c10.01-4.64,20.5-8.26,30.82-12.23c3.07-1.18,6.28-2.02,9.43-3.01c5.35-4.83,11.98-5.01,18.62-5.14c1.93-2.35,7.68-3.34,11.54-2
		c4.95-7.23,11.95-11.34,20.55-10.56c7.8,0.71,15.01,4.27,21.56,8.7c3.01,2.04,5.76,4.47,8.14,6.35c2.6-4.26,4.47-9.47,8.07-12.81
		c9.66-8.99,21.11-13.86,34.75-11.09c0.22,0.04,0.47-0.05,0.71-0.08c0.51-0.6,0.92-1.36,1.56-1.79
		c7.47-4.99,13.53-11.36,19.19-18.33c6.94-8.55,11.83-18.17,14.53-28.61c1.78-6.88,1.68-14.32,1.93-21.52
		c0.32-9.57-3.96-17.4-10.37-24.18c-0.72-0.77-2.02-0.99-3.06-1.46c-0.28,1.36-0.19,2.99-0.92,4.02c-1.08,1.51-2.6,3.14-4.25,3.62
		c-0.8,0.23-2.98-1.83-3.4-3.21c-1.56-5.02-2.64-10.19-3.82-14.97c-2.65,1.45-5.81,3.36-9.13,4.93c-0.91,0.43-1.84,0.71-2.77,0.79
		c-0.62,0.05-1.23,0.01-1.83-0.15c-0.6-0.15-1.18-0.42-1.75-0.82c-0.28-0.2-0.56-0.43-0.83-0.69c-10.18,2.23-20.55,3.82-30.47,6.88
		c-10.06,3.1-19.86,7.26-29.44,11.7c-11.42,5.29-22.45,11.38-33.68,17.07c-8.71,4.42-17.47,8.72-26.18,13.13
		c-7.94,4.03-15.84,8.14-23.76,12.22c1.17,0.75,2.3,1.56,3.52,2.23c8.16,4.5,16.34,8.95,24.49,13.46c4.13,2.28,4.68,4.01,3.19,8.21
		c-17.8,10.18-35.14,20.11-52.46,30.02c-1.83-1.27-3.17-2.38-4.66-3.22c-7.01-3.92-14.06-7.77-21.1-11.63
		c-3.35-1.84-6.72-3.67-10.07-5.5c-15.35,9.69-30.7,19.36-46.03,29.09c-1.06,0.67-1.89,1.72-2.83,2.59
		c4.27,3.08,8.53,6.15,12.86,9.27c4.33,3.12,8.71,6.28,13.22,9.53c-0.32,1.57-0.74,3.58-1.22,5.93c-1.86-0.93-2.98-1.49-4.11-2.05
		c0.13,0.3,0.25,0.6,0.38,0.91c-6.61,4.19-13.05,8.66-19.86,12.5c-10.23,5.76-20.93,10.69-31.02,16.68
		c-14.81,8.79-29.25,18.21-43.91,27.41c-0.11,0.92-0.25,2.04-0.38,3.14c-1.9-0.19-3.7-0.94-4.87-0.38
		c-2.57,1.22-5.01,2.74-7.45,4.24c-0.81,0.5-1.63,1-2.45,1.49C589.69,773.89,588.8,776.2,588.05,778.55z"
          />
          <path
            className="st3"
            d="M835.2,592.3c11.23-5.69,22.27-11.79,33.68-17.07c9.57-4.43,19.37-8.59,29.44-11.7
		c9.92-3.06,20.3-4.65,30.47-6.88c-0.18-0.56-0.3-1.11-0.36-1.66c-0.03-0.27-0.05-0.55-0.06-0.82c0-0.27,0-0.54,0.03-0.8
		c0.02-0.27,0.06-0.53,0.11-0.79c0.15-0.78,0.41-1.53,0.79-2.23c1.01-1.88,2.81-3.44,5.28-4.4c6.62-2.57,13.62-4.39,19.84-7.71
		c7-3.74,10.77-10.2,10.86-18.44c-3.75,0.74-6.36-0.42-8.03-3.69c-2.35-4.62-1.48-9.46-0.82-14.2c0.37-2.7,1.58-5.29,2.59-8.49
		c-1.29,0.71-1.93,1.01-2.52,1.4c-7.33,4.8-17-1.18-18.98-8.45c-0.75-2.74,1.42-6.04,3.74-5.5c1.77,0.41,3.44,1.28,5.12,2.04
		c3.23,1.47,6.43,3,10.15,4.75c-0.48-2.51-0.93-4.3-1.14-6.11c-0.23-1.87-0.23-3.76-0.34-5.66c-9.17,1.66-14.66-1.22-13.82-6.71
		c-16.22-9.87-32.3-19.66-47.28-28.78c-6.11,2.27-11.26,4.19-16.41,6.11c-0.02,0.43-0.05,0.86-0.07,1.29
		c1.07,0.72,2.05,1.76,3.23,2.11c3.66,1.1,7.51,1.64,11.07,2.97c2.64,0.99,6.15,1.2,6.88,5.08c0.83,4.45-4.51,8.38-9.08,6.68
		c-4.14-1.53-8.19-3.33-12.23-4.98c-0.5,1.59-1.01,3.36-1.62,5.11c-0.98,2.83-3.04,4.36-5.97,3.68c-2.87-0.66-4.04-3-4.07-5.86
		c-0.03-3.9,0.82-7.46,2.88-9.05c0.48-4.62,0.84-8.07,1.18-11.36c-7.86,1.94-9.55,1.39-11.94-4.11c0.94-3.42,3.14-6.01,6.54-6.65
		c3.1-0.59,6.4-0.12,9.56-0.12c-6.74-9.48-18.06-11.07-28.26-15.1c0.24,3.1,1.18,6.18,0.52,8.88c-1.17,4.86-0.03,8.83,2.1,13.12
		c1.45,2.91,1.99,6.42,2.26,9.71c0.28,3.39-2.28,6.76-4.7,6.97c-4.02,0.35-4.29-3.11-5.15-5.62c-0.76-2.21-0.89-4.63-1.35-7.24
		c-2.48,1.84-4.52,3.36-6.67,4.96c0.36,1.18,0.73,2.39,1.12,3.6c1.29,4.07,1.72,8.21-1.21,11.66c-1.34,1.57-3.67,3.18-5.53,3.15
		c-1.52-0.02-3.55-2.18-4.37-3.87c-1.13-2.34-1.33-5.12-1.99-8c-1.02,0.05-2.26,0.1-3.32,0.15c-0.49,4.06-0.95,7.85-1.44,11.91
		c4.14,1.09,7.07,4.2,7.27,8.58c0.2,4.26-0.91,8.59-1.54,12.88c-0.12,0.79-0.72,1.51-0.92,1.9c-2.21-0.98-3.48-1.54-4.69-2.07
		c-0.52,1.4-1.02,2.71-1.71,4.56c-2.24-1.46-3.98-2.59-5.67-3.69c-0.6,0.92-1.28,1.97-2.11,3.26c-1.46-0.33-2.9-0.42-4.13-0.98
		c-2.33-1.05-4.47-2.56-6.83-3.54c-4.78-1.97-6.77-6.4-6.16-11.56c0.6-5.13,0.13-10.39,0.13-15.81c-5.51,1.61-10.01,3.36-14.66,4.17
		c-4.75,0.82-9.67,0.72-14.51,1.03c0.52,0.21,1.03,0.59,1.55,0.62c6.64,0.28,13.29,0.44,19.93,0.79c2.06,0.11,4.09,0.72,5.95,1.06
		c1.43,4.59-0.83,7.89-2.38,11.22c-0.47,1-2.36,1.95-3.56,1.91c-14.29-0.47-28.57-1.12-43.96-1.78c-3.68,3.21-8.46,7.38-13.24,11.55
		c-1.33-0.82-2.23-1.38-3.12-1.93c-0.98,1.38-1.96,2.76-3.02,4.24c-0.35,0.49-0.71,1-1.08,1.52c-2.06-1.24-3.59-2.18-5.06-3.06
		c-6.58,3.74-6.58,3.74-9.87-0.44c-1.89,1.03-3.8,2.08-5.56,3.04c-2.89-1.24-3.71-3.11-3.07-5.83c0.21-0.91-0.14-1.94-0.16-2.91
		c-0.03-1.19-0.05-2.39-0.07-3.58c-0.02-1.19-0.04-2.39-0.06-3.58v0c-1.36,0.34-2.84,0.45-4.07,1.07
		c-7.13,3.59-14.36,7.02-21.23,11.07c-4.44,2.61-8.21,6.35-12.5,9.27c-6.28,4.27-12.76,8.24-19.14,12.36
		c-0.55,0.35-1.02,0.83-1.53,1.26c0.13,0.27,0.27,0.53,0.4,0.8c2.95-1.29,5.9-2.57,8.85-3.86c0.34,0.53,0.68,1.06,1.02,1.59
		c-3.18,2.92-6.35,5.83-9.53,8.75c0.1,0.18,0.19,0.36,0.29,0.54c5.78-2.46,11.57-4.9,17.33-7.39c4.99-2.15,9.9-4.42,15.64-3.32
		c1.41,0.27,3-0.52,4.52-0.68c4.99-0.5,9.98-1.06,14.98-1.35c4.77-0.28,7.64,3.36,11.07,5.81c4.72-8.53,12.88-12.82,21.27-16.45
		c9.92-4.29,20.24-7.22,31.26-7.36c8.65-0.11,17.3-1.44,25.92-1.17c6.58,0.21,13.09,2.2,19.66,3.15c3.97,0.57,8,0.63,12,0.97
		c4.05,0.34,7.58,1.63,9.57,5.57c6.78,0.45,6.78,0.45,9.18,5.44c4.74-0.8,9.1,1.37,10.54,5.35c4.86,13.46,9.69,26.93,14.52,40.4
		c0.19,0.53,0.37,1.05,0.71,2.01c-1.41,0.1-2.76,0.01-4.06-0.2c-0.87-0.14-1.73-0.33-2.57-0.56c-1.68-0.46-3.31-1.06-4.93-1.67
		c-2.83-1.07-5.62-2.16-8.58-2.51c0.14,1.64,0.12,3.27-0.08,4.84c-0.34,2.63-1.19,5.13-2.73,7.4c-1.03,1.53-3.02,2.77-4.82,3.34
		c-3.71,1.16-7.68,1.43-8.31,6.62c-0.13,1.07-2.2,1.9-3.37,2.84c-0.28,1.75-0.56,3.5-0.83,5.25c0.25,0.26,0.5,0.51,0.75,0.77
		c-1.61,0.63-3.52,2.11-4.76,1.67c-1.58-0.56-3.14-2.46-3.76-4.14c-0.65-1.75-1.15-3.55-1.61-5.37c-0.45-1.82-0.86-3.66-1.33-5.47
		c-0.15,0.77-0.34,1.5-0.56,2.2c-0.22,0.69-0.48,1.34-0.78,1.95c-1.79,3.64-4.97,5.72-9.87,5.77c-8.49,0.08-17,0.37-25.47-0.09
		c-1.49-0.08-2.97-0.23-4.45-0.43c-4.44-0.59-8.86-1.58-13.3-2.29v0c-1.69-0.63-3.38-1.26-5.07-1.88l0,0c0,0,0,0,0,0c0,0,0,0,0,0
		c-1.35,4.74-3.84,8.59-7.03,12.01c-1.45,1.55-3.05,3.02-4.74,4.43c-15.49,12.92-32.72,23.04-50.45,32.42
		c9.97,6.59,21.75,10.25,30.64,18.6c0.84-0.49,1.85-1.09,2.85-1.68c9.56-5.59,19.21-11.04,28.65-16.82
		c6.61-4.05,12.52-9.33,19.76-10.84c2.35,1.1,4.23,1.98,6.11,2.85c3.75-2.31,6.28-1.07,6.53,3.19c3.04,1.49,6.07,2.98,9.11,4.46
		c7.92-4.08,15.82-8.19,23.76-12.22C817.73,601.01,826.49,596.71,835.2,592.3z M833.63,487.03c-1.81-2.58-0.37-4.83,0.79-6.77
		c3.02-5.02,6.43-9.82,9.81-14.9c3.17,6.73,4.45,13.72,0.45,20.8C841.86,491.14,836.7,491.41,833.63,487.03z M854.28,534.23
		c-2.39-3.15-4.61-6.34-4.3-10.58c-0.51-1.25-1.01-2.49-1.52-3.74c-0.54-1.35-1.09-2.69-1.62-4.05c-0.66-1.67-1.79-3.32-1.87-5.01
		c-0.25-5.1-0.08-10.23-0.09-15.35c-0.01-4.55,5.22-6.91,9.72-4.48c1.8-9.38,9.65-12.8,17.01-15.59c12.97-4.9,19.74-5.02,31.67,4.34
		c10.56-5.51,22.07-2.47,27.77,7.35c1.65,2.85,2.37,6.24,3.51,9.39c1.85,1,3.7,2,5.55,3l-0.16,0.2c2.8,0.21,3.57,1.53,3.76,3.18
		c0.03,0.28,0.05,0.56,0.06,0.85c0,0.01,0,0.02,0,0.02c0.01,0.37,0,0.75-0.01,1.13c-0.02,0.57-0.03,1.14-0.01,1.69
		c0.34,3.89,0.68,7.77,0.96,10.98c0.72,2.77,1.91,5.09,1.61,7.19c-0.15,1.06-3.03,1.72-5.18,2.81c-0.24,1.02-0.68,2.88-1.09,4.62
		c-2,0-3.53,0-5.46,0c0.61,4.37-1.75,5.49-5.91,5.31c-1.38-0.06-2.85,1.94-4.01,3.41c-0.34,0.18-0.67,0.33-1.01,0.46
		c-2.35,0.86-4.72,0.36-6.91-1.33c0.17,1.96,0.34,3.93,0.51,5.89l0,0l0,0c-1.92,1.89-3.93,3.52-6.03,4.87
		c-1.05,0.67-2.12,1.28-3.22,1.8c-5.48,2.64-11.53,3.38-18.15,1.69c-7.33-1.87-14.36-4.86-21.85-7.47c-0.08,0.12-0.62,0.98-1.2,1.9
		c-4.12-1.14-2.65-5.13-3.94-7.58c-4.29,1.34-5.22,0.76-6.54-3.39C855.93,536.49,855.09,535.31,854.28,534.23z"
          />
          <path
            className="st8"
            d="M710.05,550.24c0-1.54,0-3.07,0-4.44c-8.03-9.56-15.61-19.1-10.36-32.73c-3.42-2.45-6.3-6.09-11.07-5.81
		c-5,0.29-9.99,0.85-14.98,1.35c-1.52,0.15-3.11,0.95-4.52,0.68c-5.74-1.1-10.65,1.16-15.64,3.32c-5.77,2.49-11.55,4.93-17.33,7.39
		c-0.1-0.18-0.19-0.36-0.29-0.54c3.18-2.92,6.35-5.83,9.53-8.75c-0.34-0.53-0.68-1.06-1.02-1.59c-2.95,1.29-5.9,2.57-8.85,3.86
		c-0.13-0.27-0.27-0.53-0.4-0.8c0.51-0.42,0.98-0.9,1.53-1.26c6.38-4.12,12.86-8.09,19.14-12.36c4.29-2.91,8.05-6.65,12.5-9.27
		c6.87-4.04,14.1-7.48,21.23-11.07c1.22-0.62,2.7-0.72,4.07-1.07c0.3-0.45,0.6-0.9,0.9-1.35c-0.24-3.4-0.48-6.8-0.72-10.2
		c-1.95-4.5-3.91-9-5.86-13.5l0,0v0c-0.47,0.94-0.96,1.87-1.44,2.81s-0.94,1.88-1.36,2.85c-0.59,1.35-0.93,2.8-1.39,4.2
		c-0.4,0.24-0.8,0.49-1.21,0.73c-0.14-0.57-0.22-1.17-0.44-1.71c-0.22-0.53-0.6-1-0.55-0.91c-4.69,1.47-9.16,2.87-13.64,4.28
		c-2.37,1.64-4.73,3.27-7.1,4.91c-0.26,1.24-0.43,2.51-0.78,3.73c-1.36,4.72-3.63,5.67-7.83,3.37c-0.63,0.69-1.15,1.56-1.92,2.05
		c-9.66,6.2-19.32,12.38-29.04,18.48c-2.02,1.27-4.14,2.37-6.26,3.49c-0.7,0.37-1.41,0.75-2.11,1.13c0.1,1.73,0.19,3.46,0.29,5.19
		c0.1,1.73,0.2,3.46,0.32,5.19c0.15,2.15-0.56,3.63-2.8,4.11c-1.25,4.64-5.49,5.91-9.13,7c-5.69,1.71-10.2,4.7-14.7,8.51
		c-11.48,9.7-25.01,15.8-39.29,20.19c-5.4,1.66-10.92,2.91-16.39,4.34c-0.14,1.16-0.23,2.34-0.35,3.51s-0.27,2.33-0.53,3.47
		c-0.54,2.38-2.62,2.61-4.56,2.22c-1.72-0.35-2.91-1.23-2.33-3.53c0.25-1.01-0.66-2.31-1.04-3.49c-2.16-1.93-4.31-3.86-6.97-6.24
		c-2.2,1.37-5.08,3.35-8.12,5.01c-1.91,1.04-3.77,3.18-6.34,1.28c-0.35-0.23-0.74-0.46-1.15-0.67c-1.78-0.94-3.9-1.81-4.21-3.11
		c-2.07-8.53-3.34-17.24-1.89-27.12c-13.15,7.13-26.34,12.77-37.56,21.75c10.31,6.12,20.46,12.37,30.86,18.21
		c4.8,2.7,9.14,6.7,14.39,6.73c9.37,7.02,18.98,12.37,28.96,17.12c6.95-4.09,13.77-8.42,20.88-12.21
		c7.55-4.02,15.32-7.64,23.08-11.24c22.96-10.65,45.91-21.36,69-31.73c9.17-4.12,18.62-7.69,28.11-11.01
		c5.87-2.05,11.8-1.45,16.42,3.36c1.69,1.76,2.73,4.14,4.1,6.22c2.87,4.35,5.78,8.67,8.65,13.02c0.39,0.58,0.63,1.26-0.01,1.69
		c-1.79,5.58-3.59,11.16-5.38,16.74c1.64,1.05,3.27,2.11,4.91,3.16c1.69-6.74,3.38-13.48,5.15-20.53
		C707.21,549.28,708.63,549.76,710.05,550.24z"
          />
          <path
            className="st5"
            d="M321.18,574.68c0.71,0.2,1.42,0.4,2.12,0.59c-0.48-0.14-0.97-0.27-1.45-0.41
		C321.63,574.8,321.4,574.74,321.18,574.68z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M293.62,558.95c-0.32-1.21-0.54-2.44-0.79-3.67c-0.6,27.25-0.02,54.42,3.26,81.92
		c1.45-0.79,2.28-1.21,3.09-1.68c12.66-7.2,25.46-14.18,37.93-21.69c2.6-1.57,5.18-3.17,7.74-4.81c2.56-1.63,5.11-3.29,7.65-4.96
		c5.08-3.34,10.14-6.72,15.23-10.03c-6.72-2.71-13.46-5.37-20.17-8.11c-2.24-0.91-4.47-1.83-6.7-2.77
		c-2.97-1.25-5.91-2.57-8.84-3.91c-2.93-1.34-5.85-2.71-8.78-4.04c0,0,0.03,0.03,0.04,0.05c0,0.01,0.01,0.02,0.01,0.02
		c-0.71-0.2-1.42-0.4-2.12-0.59c-6.78-2.88-13.55-5.76-20.33-8.64c0.09-0.19,0.19-0.37,0.28-0.56c18.45,6.52,36.9,13.05,55.36,19.57
		c-0.02,0.08-0.03,0.17-0.05,0.25c-3.66-0.94-7.33-1.89-10.99-2.83c5.67,2.63,11.36,5.22,16.99,7.93c2.04,0.99,3.93,2.29,5.88,3.46
		c9.82-6.44,19.64-12.87,29.46-19.31c-0.51-2.82,1.51-6.23-2.05-8.2c0.27-1.85,0.52-3.56,0.81-5.54c0.64,0.57,1.25,1.12,1.85,1.66
		c-0.01-4.17,0.16-8.36-0.07-12.52c-0.56-10.07-1.32-20.12-1.97-30.19c-0.34-5.19-0.62-10.38-0.94-15.81
		c-2.11,1.03-4.18,2.04-6.54,3.19c1.33,4.97,2.6,9.72,4.13,15.43c-2.6,0.93-5.2,1.86-8.49,3.03c0.52,2.51,1.08,5.18,1.67,8.01
		c-5.56,1.33-10.69,6.22-17.85,5.76c-1.08-2.36-2.14-4.69-3.17-6.95c-2.65,0.27-5.09,0.51-8.04,0.8c-0.47-2.31-0.88-4.32-1.4-6.9
		c-4.5,2.45-9.02,4.69-13.32,7.32c-9.18,5.61-18.16,11.57-27.39,17.08c-6.5,3.88-12.59,8.83-20.68,9.4
		C294.09,559.75,293.74,559.38,293.62,558.95z M340.98,555.97c6.61,2.21,13.22,4.42,19.84,6.63c-0.09,0.28-0.19,0.56-0.28,0.84
		c-6.62-2.18-13.24-4.37-19.86-6.55C340.78,556.59,340.88,556.28,340.98,555.97z M357.88,565.66c0.54,0.19,1.07,0.37,1.61,0.56
		c-0.06,0.16-0.12,0.32-0.18,0.49c-0.56-0.23-1.13-0.46-1.69-0.69c-3.28-1.22-6.57-2.44-9.86-3.67c-3.28-1.22-6.57-2.45-9.85-3.69
		c-1.66-0.63-3.27-1.42-4.77-2.51C341.82,558.14,349.6,562.58,357.88,565.66z M316.01,557.84c15.57,4.93,30.84,10.67,45.53,17.95
		c-3.93-1.36-7.87-2.72-11.8-4.08c6.42,3.04,12.84,6.09,19.27,9.13c-0.05,0.19-0.09,0.37-0.14,0.56c-2.91-1.03-5.86-1.93-8.71-3.11
		c-11.52-4.75-23.02-9.57-34.49-14.44c-0.46-0.19-0.85-0.54-1.23-0.92c-0.38-0.38-0.74-0.81-1.12-1.16
		c-2.59-0.91-5.18-1.81-7.78-2.72C315.7,558.66,315.85,558.25,316.01,557.84z"
            />
          </Link>
          <path
            className="st9"
            d="M643.76,217.37c1.16,0.94,2.84,1.73,4.3,1.76c8.35,0.16,16.7-0.02,25.05,0.1c7.61,0.11,15.21,0.41,22.81,0.73
		c1.44,0.06,2.86,0.58,4.24,1.39c-1.16,0-2.33,0-3.49,0c-0.12,0.06-0.24,0.13-0.36,0.21c-0.24,0.15-0.48,0.31-0.72,0.41
		c-0.12,0.05-0.24,0.08-0.37,0.08c-7.09,0.28-14.18,0.42-21.27,0.77c-4.12,0.21-8.24,0.46-12.36,0.72s-8.24,0.54-12.35,0.8
		c0.65,1.2,1.16,2.51,1.99,3.58c2.02,2.62,4.26,5.07,6.27,7.7c3.64,4.77,7.34,9.51,10.68,14.49c2.19,3.27,3.75,6.95,5.68,10.4
		c1.14,2.04,2.44,3.98,3.91,6.35c5.91,0,11.97-0.95,17.55,0.27c5.7,1.25,10.92,4.66,16.38,7.05c2.12,0.93,4.3,1.78,6.53,2.42
		c2.62,0.76,3.75,2.33,3.4,5c-0.29,2.23-0.29,4.56-1.02,6.64c-0.52,1.49-1.8,2.95-3.11,3.9c-3.04,2.18-6.32,4.04-9.88,6.27
		c5.08,1.7,9.48,3.18,13.88,4.65c-0.09-1.05-0.14-2.1-0.29-3.14c-1.16-7.92,0.93-16.94,10.15-19.41c2.9-0.78,5.76-1.72,9.72-2.91
		c-5.26-4.31-10.12-8.1-14.76-12.14c-6.06-5.27-12.22-10.47-17.81-16.22c-3.45-3.54-5.99-8.01-2.08-14.03
		c2.45,0.66,5.69,0.72,7.79,2.29c3.86,2.87,7.19,6.5,10.49,10.05c4.65,5.02,9.06,10.25,13.58,15.39c0.42-0.14,0.84-0.27,1.26-0.41
		c-0.47-2.72-0.73-5.5-1.47-8.14c-0.66-2.35-1.98-4.51-2.75-6.84c-0.68-2.03-1-4.18-1.65-7.01c-2.92-1.71-7.12-3.46-8.3-8.61
		c-0.93-4.06-2.74-7.93-3.69-11.99c-2.54-10.8-5.06-21.61-7.13-32.51c-1.79-9.4-3-18.92-4.37-28.4c-0.51-3.54-0.75-7.12-1.22-11.72
		c-4.63,3.34-8.64,6.36-12.78,9.17c-7.9,5.38-15.97,10.52-23.81,15.99c-6.26,4.37-12.03,9.5-18.48,13.57
		c-8.96,5.66-18.36,10.63-27.57,15.9c3.49,2.96,7.03,5.88,10.46,8.91C639.17,212.97,641.3,215.37,643.76,217.37z M643.33,210.59
		c-0.16-0.41-0.36-0.95-0.72-1.88c20.95-0.73,41.31-1.45,61.68-2.16c0.02,0.33,0.04,0.67,0.06,1c-3.42,0.3-6.84,0.71-10.26,0.89
		c-14.58,0.76-29.16,1.44-43.74,2.22c-0.24,0.01-0.48,0.13-0.71,0.29c-0.39,0.27-0.77,0.66-1.16,0.92
		c11.56-0.41,23.13-0.83,34.69-1.23c1.72-0.06,3.45-0.01,5.17,0.3c-15.33,2.17-30.67,3.57-46.29,1.07c0.5-0.27,1.01-0.53,1.51-0.8
		C643.5,211.03,643.42,210.83,643.33,210.59z"
          />
          <path
            className="st10"
            d="M573.44,411.45L573.44,411.45c-0.02-3.25,0-6.5-0.01-9.75c-0.01-3.25-0.04-6.5-0.17-9.74
		c-0.24-6.03-0.7-12.06-1.4-18.06c-1.23-10.49-2.59-21-8.62-30.11c-1.81-2.74-4.16-5.17-7.68-5.11c-1.5,0.03-3.88,1.02-4.33,2.17
		c-2.23,5.69-3.96,11.59-5.85,17.42c-0.4,1.23-0.76,2.47-1.4,4.57c-3.51-1.28-6.81-2.48-10.08-3.67
		c-2.6-10.01-8.79-17.38-16.84-15.83c-0.31-4.33-0.62-8.62-0.93-12.97c3.28-1.6,6.5-3.18,9.7-4.74c3.2-1.56,6.38-3.12,9.55-4.67
		c0.17-0.91,0.53-1.32,0.97-1.48c0.09-0.03,0.18-0.05,0.28-0.06c0.1-0.01,0.19-0.02,0.3-0.02c0.1,0,0.2,0.01,0.31,0.02
		c0.84,0.09,1.76,0.44,2.41,0c1.41-1.75,2.51-3.93,4.27-5.18c6.94-4.92,6.4-13.56-1.27-17.61c-1.25-0.66-2.44-1.46-3.68-2.15
		c-3.43-1.9-6.8-3.85-11.03-2.26c-0.91,0.34-2.21-0.34-3.33-0.56c-7.94,1.64-15.24,4.82-21.91,9.38c-2.09,1.43-3.91,3.27-5.85,4.92
		c0.98,6.66,1.96,13.31,2.95,19.97c1.87,0.71,3.73,1.46,5.62,2.13c1.89,0.68,3.8,1.29,6.08,2.06c-0.57,4.98-1.35,9.87-1.63,14.78
		c-0.2,3.54,2.15,5.79,5.8,7.74c-2.96,17.87,4.35,34.65,7.41,50.88c-5.16,5.02-10.04,9.15-14.16,13.94
		c-3.56,4.13-4.13,9.5-2.02,14.5c1.72,4.08,1.04,8.13,1.26,12.22c0.05,0.06,0.1,0.12,0.14,0.16c0,0,0,0,0.01,0.01
		c0.03,0.04,0.06,0.07,0.07,0.08c0.01,0.01,0.01,0.01,0,0c-0.01-0.01-0.04-0.04-0.07-0.08c0,0,0,0-0.01-0.01
		c-0.03-0.04-0.08-0.09-0.14-0.16c0,0-0.03,0.03-0.03,0.03c0.17,0.56,0.08,0.29,0,0l0,0l0,0c-1.15-0.48-2.3-0.95-3.45-1.43
		c-1.51,2.69-2.64,5.72-4.64,7.99c-2.03,2.3-5.05,2.78-8.07,1.32c-3.56-1.72-4.81-4.87-5.32-8.5c-0.57-4.03,0.91-7.31,3.65-10.14
		c-1.68-0.72-3.36-1.44-5.04-2.16c-0.49,3.93-1.12,7.86-1.43,11.81c-0.23,2.96-0.05,5.95-0.05,9.06c-1.39,0.5-2.67,0.95-4.43,1.58
		c0.26,1.64,0.54,3.36,0.82,5.08c0.35,0.29,0.7,0.58,1.05,0.87c-5.95,2.71-11.79,5.67-17.89,7.99c-2.19,0.83-3.86-1.14-3.89-3.66
		c-0.06-4.41-0.08-8.83,0.01-13.24c0.07-3.71,3.59-4.58,5.73-6.58c0.33-0.31,0.77-0.5,1.25-0.8c-0.76-3.12-1.52-6.23-2.38-9.74
		c3.92-1.34,7.54-2.57,11.16-3.8c0.87-0.23,1.74-0.47,2.61-0.7c-0.98,0.06-1.97,0.13-2.95,0.19c-6.27,1.3-12.27,0.3-18.02-2.02
		c-1.75,1.04-3.49,2.07-5.31,3.15c0,6.23,0.15,12.44-0.04,18.63c-0.27,9.09-0.79,18.16-1.2,27.24c1.61,0.74,3.22,1.49,4.83,2.23
		c8.71-4.74,17.46-9.39,26.1-14.25c10.47-5.88,20.84-11.94,31.26-17.92c-0.46-1.92-0.92-3.83-1.67-6.91c2.23,2,3.72,3.35,5.21,4.69
		c2.34-1.3,4.69-2.6,7.04-3.89c7.04-3.88,14.09-7.76,21.08-11.74c7.37-4.21,14.68-8.52,22-12.83
		C568.56,414.32,571,412.88,573.44,411.45L573.44,411.45z M526.71,403.37c0.8-8.09,1.42-15.68,5.65-22.44
		c0.31-0.5,0.65-0.99,0.95-1.51c3.81-6.55,10-7.69,15.11-1.77c4.42,5.12,7.74,11.26,11.14,17.18c1.97,3.44,3.14,7.35,4.43,10.48
		C551.82,404.68,539.49,404.04,526.71,403.37z"
          />
          <path
            className="st10"
            d="M699.29,332.41c0.29-3.92,0.52-7.85,0.88-11.77c0.23-2.49,1.01-4.51,3.56-5.87
		c6.21-3.31,12.24-6.96,18.49-10.55c-0.11-0.17-0.41-0.66-0.71-1.14c-4.4-1.48-8.81-2.95-13.88-4.65c3.56-2.23,6.83-4.09,9.88-6.27
		c1.32-0.95,2.6-2.4,3.11-3.9c0.72-2.08,0.73-4.42,1.02-6.64c0.35-2.67-0.78-4.24-3.4-5c-2.22-0.64-4.4-1.5-6.53-2.42
		c-5.46-2.39-10.68-5.81-16.38-7.05c-5.58-1.22-11.63-0.27-17.55-0.27c-1.47-2.37-2.77-4.32-3.91-6.35
		c-1.93-3.45-3.49-7.14-5.68-10.4c-3.34-4.98-7.04-9.72-10.68-14.49c-2-2.63-4.25-5.08-6.27-7.7c-0.82-1.07-1.33-2.38-1.99-3.58
		c-4.76,0.23-9.52,0.47-14.28,0.7c4.88,5.21,9.92,10.27,14.59,15.65c6.82,7.87,13.38,15.98,19.93,23.84
		c-3.63,16.95-14.85,29.27-22.76,43.82c-1.61-0.21-3.21-0.42-4.81-0.63c-3.18-0.41-6.33-0.82-9.46-1.23
		c-1.57-0.2-3.13-0.41-4.69-0.61c-15.75,0.76-31.51,1.52-47.26,2.29c4.39,1.69,8.77,3.38,13.19,5.08c0.94-0.89,1.69-1.59,2.49-2.34
		c1.9,0.49,3.69,1.1,5.54,1.39c5.04,0.81,10.11,1.46,15.16,2.22c0.13,0.02,0.27,0.06,0.39,0.11c0.18,0.07,0.35,0.15,0.52,0.25
		c0.21,0.11,0.42,0.23,0.63,0.32c1.63,0.12,3.26,0.23,4.89,0.35s3.25,0.26,4.88,0.44c0.61,0.07,1.13,0.84,1.7,1.29
		c3.87,0.08,7.74,0.05,11.59,0.26c5.75,0.33,9.38,4.07,12.87,8.45c-0.55,1.64-1.13,3.39-1.87,5.58
		c-0.68-5.95-7.91-13.47-13.29-12.88c-3.26,0.36-5.32,1.51-6.39,4.62c-1.52,4.43-3.18,8.82-5.13,14.18
		c-2.68-3.51-4.79-6.13-6.75-8.86c-3.36-4.67-7.3-8.44-13.3-9.33c-0.26-0.04-0.46-0.49-0.68-0.75c0,0,0,0,0,0
		c-0.45,0.13-0.9,0.27-1.34,0.4c-6.97,9.47-7.25,20.09-5.53,31.14c0.83,5.35,1.01,10.81,1.93,16.15c0.47,2.73,1.86,5.31,2.96,7.91
		c1.39,3.29,2.91,6.54,4.32,9.7c-3.79,2.35-3.81,3.61-0.8,6.63c9.99-5.56,19.98-11.12,29.97-16.69c-2.1-0.39-4.34-1.43-6.29-1.03
		c-5.1,1.04-10.1,2.59-15.06,4.2c-1.71,0.55-3.17,1.88-5.72,3.45c0.72-3.8,1.39-6.81,1.81-9.85c0.27-1.99-0.01-4.06,0.29-6.04
		c0.4-2.55,0.74-5.24,1.81-7.54c2.4-5.19,5.92-6.42,11.09-4.06c8.08,3.69,13.54,9.84,16.45,18.24c6.2-3.39,12.4-6.77,18.6-10.16v0
		c-0.32-1.29-0.63-2.58-0.95-3.87c-0.94-3.87-1.88-7.75-2.91-11.6c-0.66-2.5-1.62-4.92-2.44-7.38c0.29-0.16,0.58-0.32,0.87-0.48
		c1.74,3.13,3.69,6.17,5.16,9.42c1.74,3.85,3.07,7.89,4.58,11.85c1.19-0.72,2.39-1.44,3.58-2.16c-0.15-1.87-0.3-3.74-0.54-6.67
		c1.91,1.51,3.37,2.66,4.84,3.82c0.35-0.18,0.7-0.35,1.06-0.53c1.06-0.53,2.15-1.08,3.34-1.67c-0.98-3.81,0.93-6.98,3.57-8.8
		C687.37,338.94,693.66,335.7,699.29,332.41z M689.73,272.31c0.43,0.01,1.06,0.21,1.27,0.54c3.33,5.34,9.32,4.88,14.27,6.78
		c3.9,1.5,7.51,3.78,12.12,6.16c-7.02,3.17-12.9,5.83-19.32,8.74c-5.71-7.34-11.49-14.75-17.32-22.24
		C684,272.29,686.86,272.26,689.73,272.31z M650.92,313.68c8.94,2.46,17.34,4.77,25.73,7.07c0.76,5.22,1.5,10.32,2.24,15.37
		C674.96,335.62,653.98,319.09,650.92,313.68z M677.81,317.14c-8.97-2.11-17.87-4.49-26.95-6.83c7.36-13.33,16.71-25.68,21.54-41.55
		c3.73,4.72,6.87,8.7,10.03,12.67c3.77,4.73,7.48,9.5,11.37,14.12c1.6,1.9,2.16,3.6,0.48,5.61c-4.1,4.91-8.13,9.89-12.42,14.65
		C681.01,316.75,679.02,317.43,677.81,317.14z"
          />
          <path
            className="st11"
            d="M557.54,798.06c-0.27,1.54-0.52,3.08-0.72,4.62c-0.54,4.12-0.73,8.3-0.14,12.62
		c1.45,10.5,9.37,18.48,20.03,19.36c7.48,0.62,14.64-1.14,21.72-3.24c17.72-5.26,33.24-15.15,49.44-23.6
		c8.76-4.57,17.53-9.15,26.02-14.18c10.62-6.3,21.15-12.78,31.37-19.7c14.77-10.01,26.9-23.04,39.33-35.68
		c7.03-7.16,14.49-13.77,23.18-18.82c9.8-5.7,20.21-10.01,31.15-13.02c2.06-7.74,5.3-14.87,10.88-20.77
		c-3.15,0.99-6.35,1.83-9.43,3.01c-10.32,3.97-20.81,7.59-30.82,12.23c-12.02,5.58-22.63,13.53-32.28,22.6
		c-9.46,8.89-18.64,18.09-27.94,27.16c-9.76,9.52-20.82,17.28-32.56,24.12c-20.98,12.21-42.1,24.18-64.54,33.54
		c-3.76,1.57-8.01,2.36-12.09,2.64c-11.58,0.78-15.7-8.35-15.19-18.6c0.19-3.84,1.42-7.66,2.43-11.42c0.21-0.79,0.45-1.58,0.7-2.37
		c0.75-2.35,1.64-4.67,2.44-7.01v0c-0.13,0.05-0.27,0.09-0.41,0.13c-0.41,0.13-0.83,0.24-1.19,0.46
		c-9.97,6.07-19.93,12.15-29.89,18.23c-0.1,0.51-0.19,1.03-0.29,1.54C558.31,793.97,557.9,796.01,557.54,798.06z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M358.74,403.35c-2.54,0.81-4.93,1.7-7.01,3.33c-0.7,0.54-1.36,1.17-1.98,1.9c-4.13,4.84-8.6,9.43-16.13,7.08
		c-0.97-0.3-2.84,0.62-3.58,1.54c-5.53,6.86-9.4,8.05-17.25,4.83c-5.25,5.41-9.14,13.1-18.6,12.2c-1.42,7.85,2.87,17.29-6.75,21.52
		c0.29,1.71,0.62,2.93,0.68,4.16c0.51,10.43,0.99,20.86,1.44,31.29c0.5,11.39,0.93,22.79,1.47,34.18c0.15,3.18,0.56,6.35,0.91,10.1
		c22.08-13.64,43.83-27.08,65.83-40.67c-0.08-0.26-0.16-0.53-0.24-0.81c-0.25-0.84-0.52-1.77-0.79-2.69
		c-13.18,6.23-26.36,12.48-39.55,18.69c-0.62,0.29-1.31,0.44-2,0.6c-0.23,0.05-0.46,0.11-0.68,0.17c0,0,0,0,0,0h0l0,0l0,0
		c0.01-0.01,0.03-0.03,0.04-0.04c0,0,0,0,0,0c-0.92,0.51-1.85,1.01-2.77,1.52c-0.96,0.72-1.93,1.43-2.89,2.15
		c-0.95,0.43-1.9,0.86-3.66,1.66c-0.88-1.73-2.3-3.48-2.67-5.43c-1.41-7.38-2.54-14.82-3.64-22.25c-0.19-1.31,0.29-2.72,0.46-4.08
		c0.42-0.02,0.84-0.04,1.26-0.07c0.36,1.24,0.85,2.46,1.07,3.72c1.26,7.16,2.41,14.33,3.73,21.48c0.11,0.59,1.27,0.99,1.93,1.48
		c-0.12-0.8-0.25-1.6-0.38-2.39c-0.19-1.17-0.38-2.35-0.57-3.52c-0.32-1.97-0.62-3.95-0.87-5.93c-0.65-5.15-1.24-10.32-1.61-15.5
		c-0.11-1.57,0.16-3.5,1-4.76c3.15-4.69,6.72-9.1,9.92-13.76c5.32-7.75,10.6-15.53,15.63-23.46c2.35-3.7,3.79-4.19,7.65-1.25
		c0,0.96,0,1.98,0,3.24c9.04-0.88,18.25,2.2,26.43-2.23c2.72,12.5,5.35,24.53,7.9,36.23c2.18,0.31,3.54,0.5,5.01,0.71
		c0.13,2.13,0.23,3.86,0.36,5.96c2.05-0.9,3.84-1.69,5.63-2.48c-0.76-9.99-1.49-19.62-2.26-29.81c-2.73,0.34-4.78,0.69-6.84,0.81
		c-2.19,0.13-4.39,0.03-6.76,0.03c1.63-4.7,11.34-5.64,16.64-1.92c0.5,9.47,1.01,19.17,1.49,28.28c2.42,1.77,4.47,3.29,6.98,5.13
		c0.6-2.02,1.94-4.59,2.02-7.2c0.17-5.09-0.19-10.22-0.65-15.3c-0.46-5.05-1.54-10.04-1.95-15.09c-0.55-6.72-0.71-13.46-1.06-20.65
		c-5.29-7.5-8.91-16.34-10.31-26.52c-2.52,0.67-4.9,1.2-7.2,1.95c-2.52,0.82-4.96,1.6-7.35-0.26c-2.16,0.47-4.33,0.94-6.49,1.42
		C359.43,402.86,359.08,403.1,358.74,403.35z M380.06,442.89c-1.76-0.98-3.51-1.95-5.27-2.93c-8.33-4.01-16.67-8.02-24.99-12.06
		c-0.32-0.16-0.49-0.62-0.72-0.95c-1.66-0.72-3.32-1.45-4.99-2.17c0.01,0.01,0.02,0.02,0.03,0.03c-3.35-1.37-6.69-2.74-10.04-4.11
		c0.15-0.39,0.3-0.77,0.45-1.16c2.45,0.82,4.9,1.63,7.35,2.45c11.44,5.11,22.88,10.22,34.32,15.32c-0.07,0.27-0.14,0.54-0.2,0.81
		c-0.39,0.11-0.78,0.21-1.16,0.32c0.16,0.33,0.32,0.67,0.48,1c1.72,0.88,3.44,1.77,5.16,2.65
		C380.33,442.37,380.19,442.63,380.06,442.89z M372.7,430.31c0.17,0.08,0.29,0.26,0.39,0.47c0.1,0.19,0.18,0.39,0.29,0.57
		c0.47,0.19,0.93,0.38,1.4,0.57c0.65,0.4,1.31,0.8,1.96,1.2c-0.16,0.3-0.32,0.61-0.48,0.91c-3.81-1.89-7.62-3.78-11.44-5.67
		l0.05,0.03c0,0,0,0,0,0l0,0c-5.35-2.35-10.7-4.71-16.05-7.06c0.12-0.34,0.24-0.68,0.36-1.02c0.68,0.09,1.35,0.17,2.03,0.26
		c0.46-0.13,1.05-0.52,1.37-0.36C359.3,423.54,366.01,426.91,372.7,430.31z"
            />
          </Link>
          <path
            className="st3"
            d="M76.06,824.4c0.01,0.48,0.04,0.95,0.08,1.43c0.93,11.53,1.87,23.06,2.77,34.59c0.38,4.77,0.68,9.55,1.04,14.77
		c1.28-1.05,2.14-1.82,3.06-2.5c10.91-7.94,22.39-14.94,34.18-21.5c5.3-2.95,10.18-6.64,15.98-10.48c-0.02-0.17-0.04-0.35-0.06-0.56
		c-0.03-0.24-0.05-0.5-0.08-0.77c-0.03-0.27-0.06-0.56-0.1-0.87c-0.14-1.23-0.3-2.69-0.46-4.16c-3.42-1.2-5.22-7.53-5.14-13.91
		c0.04-3.19,0.55-6.39,1.57-8.98c0.11-0.29,0.23-0.57,0.36-0.84c0.25-0.54,0.53-1.05,0.83-1.52c0.3-0.47,0.63-0.89,0.98-1.27
		c-0.56-9.35-1.12-18.68-1.67-27.89c-5.25-1.58-4.18-6.88-4.94-10.33c-1.22-5.54-3.12-12.36,5.06-15.03c0-2.4,0-4.44,0-6.87
		c-18.77,9.97-37.17,19.75-55.83,29.66c0.56,5.76,1.35,11.37,1.59,17.01C75.72,804.39,75.82,814.4,76.06,824.4z M87.53,821.76
		c-0.03-5.84-0.2-11.69,0.1-17.51c0.09-1.77,1.07-4.17,2.42-5c1.07-0.65,3.49,0.38,5.05,1.18c5.26,2.7,7.14,7.47,7.62,14.42
		c-0.7,1.87-1.64,4.98-3.01,7.88c-1.03,2.19-2.39,4.3-3.98,6.11c-1.72,1.94-1.79,3.82-1.01,6.07c1.01,2.92-0.13,5.66-2.46,6.2
		c-2.78,0.64-4.74-0.91-5.46-4.11c-0.83-3.74-0.96-7.29,0.91-10.88C88.29,825.01,87.54,823.23,87.53,821.76z"
          />
          <path
            className="st9"
            d="M609.28,383.86c-1.41-3.16-2.93-6.4-4.32-9.7c-1.1-2.6-2.49-5.18-2.96-7.91c-0.92-5.33-1.1-10.79-1.93-16.15
		c-1.72-11.05-1.44-21.67,5.53-31.14c-11.45,0.42-22.89,0.95-34.34,1.22c-8.2,0.19-16.41-0.01-24.61,0.09
		c-2.1,0.02-4.2,0.52-6.3,0.79c0.91,6.49,3.28,12.85,2.33,19.87c-3.61-0.82-5.62-2.51-5.85-5.75c-0.33-4.7-0.5-9.42-0.73-14.12
		c-0.24-0.04-0.48-0.08-0.72-0.12c-3.18,1.55-6.35,3.11-9.55,4.67c-3.2,1.56-6.43,3.14-9.7,4.74c0.31,4.35,0.62,8.65,0.93,12.97
		c8.06-1.55,14.25,5.82,16.84,15.83c3.27,1.19,6.57,2.39,10.08,3.67c0.64-2.1,1-3.34,1.4-4.57c1.89-5.83,3.62-11.72,5.85-17.42
		c0.45-1.15,2.82-2.14,4.33-2.17c3.53-0.06,5.87,2.37,7.68,5.11c6.03,9.11,7.39,19.62,8.62,30.11c0.7,5.99,1.16,12.03,1.4,18.06
		c0.13,3.24,0.17,6.49,0.17,9.74c0.01,3.25-0.01,6.5,0.01,9.75h0c2.37-1.01,4.69-2.11,6.96-3.29c1.51-0.79,3.01-1.6,4.48-2.45
		s2.94-1.72,4.39-2.61c4.35-2.67,8.59-5.52,12.84-8.37c1.41-0.95,2.83-1.9,4.25-2.84c0.71-0.47,1.42-0.94,2.13-1.41
		C605.47,387.48,605.49,386.22,609.28,383.86z"
          />
          <path
            className="st4"
            d="M153.59,522.39c0.59,6.02,1.11,11.27,1.62,16.52c-0.37,0.07-0.73,0.13-1.1,0.2
		c-1.5-5.15-2.99-10.29-4.51-15.51c-6.19,0.9-12.02,1.74-18.37,2.66c0,1.36-0.08,2.78,0.01,4.19c0.45,6.72,1.29,13.44,1.35,20.17
		c0.18,20.06,0.07,40.12,0.07,60.18c0,1.28,0,2.56,0,3.86c5.09-0.44,9.75-0.84,14.36-1.24c-0.34-2.99-0.57-5.06-0.81-7.13
		c0.41-0.08,0.82-0.17,1.23-0.25c0.74,2.33,1.47,4.67,2.38,7.54c4.61-0.5,9.48-1.02,13.5-1.45c0.55-1.83,0.85-2.8,1.14-3.77
		c0.32,0.1,0.64,0.2,0.96,0.31c0.43,1.05,0.87,2.11,1.48,3.59c3.69-0.57,7.32-1.14,10.7-1.67c-0.21-2.33-0.49-3.96-0.48-5.59
		c0.11-10.81,0.26-21.62,0.43-32.43c0.22-13.46,0.44-26.91,0.74-40.37c0.07-3.3,0.46-6.58,0.67-9.88c0.07-1.04,0.01-2.09,0.01-3.69
		C170.15,519.92,161.75,521.17,153.59,522.39z M172.64,569.29c-2.25,2.66-5.14,4.79-7.57,6.95c-4.82-2.1-5.26-3.54-3.33-7.93
		c0.68-1.56,0.36-3.77,1.37-4.91c1.29-1.46,3.54-3.04,5.23-2.9c2.08,0.17,4.65,1.65,5.79,3.37
		C174.85,564.93,173.8,567.93,172.64,569.29z"
          />
          <path
            className="st11"
            d="M700.52,549.23c0.64-0.43,0.4-1.1,0.01-1.69c-2.87-4.35-5.78-8.67-8.65-13.02c-1.37-2.07-2.41-4.46-4.1-6.22
		c-4.62-4.81-10.55-5.4-16.42-3.36c-9.5,3.32-18.94,6.89-28.11,11.01c-23.09,10.38-46.03,21.08-69,31.73
		c-7.76,3.6-15.53,7.22-23.08,11.24c-7.11,3.79-13.93,8.12-20.88,12.21c7.27,4.33,14.55,8.66,21.66,12.89
		c4.39-2.03,8.49-3.64,12.31-5.77c7.54-4.2,14.79-8.92,22.4-12.98c5.2-2.77,11.01-4.41,16.17-7.24c7.07-3.88,13.81-8.38,20.64-12.68
		c10.49-6.61,20.93-13.32,31.45-19.88c1.29-0.8,3-0.93,3.52-1.08c4.45,3.44,9.03,6.37,13.65,9.05c1.54,0.89,3.09,1.76,4.63,2.61
		c1.55,0.85,3.09,1.68,4.64,2.5c1.55,0.82,3.09,1.63,4.63,2.44c1.54,0.81,3.07,1.62,4.6,2.45s3.04,1.66,4.55,2.51
		C696.93,560.39,698.72,554.81,700.52,549.23z"
          />
          <path
            className="st3"
            d="M91.07,575.61c-1.15-6.29-0.67-12.91-3.66-18.84c-3.38,2.55-6.7,5.18-10.16,7.63
		c-4.32,3.06-8.62,6.16-13.14,8.9c-6.15,3.72-12.5,7.12-18.76,10.65c0.09,0.66,0.16,1.33,0.24,2c0.22,2,0.43,4,0.83,5.96
		c3.15,15.29,6.38,30.57,9.58,45.85c0.94,4.48,1.87,8.97,2.89,13.87c14.54-8.68,29.4-15.58,40.93-27.22
		c-1.21-6.3-2.39-12.15-3.43-18.02C94.57,596.14,92.94,585.85,91.07,575.61z M83.47,601.1c-3-0.09-5.91-3.15-5.98-6.29
		c-0.07-3.19,2.33-6.01,5.11-6.02c4.51-0.02,6.35,1.47,6.36,5.14C88.96,598.35,86.78,601.2,83.47,601.1z"
          />
          <path
            className="st3"
            d="M406.8,769.54c-7.19-5.78-14.03-12.06-21.61-17.28c-7.77-5.35-16.24-9.68-24.4-14.45
		c-18.49-10.82-36.99-21.64-55.5-32.47c-1.63,1.29-3.33,2.77-5.15,4.06c-8.36,5.92-16.78,11.73-25.08,17.74
		c-1.03,0.74-1.41,2.37-2.09,3.59c1.27,1.12,2.38,2.81,3.85,3.24c3.26,0.95,5.39,2.88,7.56,5.4c2.19,2.54,5.28,4.3,7.97,6.4
		c0.41-0.52,0.78-1.13,1.15-1.73c0.63-0.99,1.28-1.94,2.16-2.31c5.55-2.3,6.37-7.99,8.57-12.3c1.45-2.86,3.23-3.98,5.6-5.01
		c1.15-0.5,2.5-0.51,3.76-0.78c6.29-1.33,12-0.76,16.74,4.32c0.85,0.91,2.95,1.2,4.34,0.97c4.22-0.7,8.13,0.68,10.07,4
		c3.12,5.32,2.83,9.32-0.52,13.22c-0.94,1.09-1.21,2.85-1.51,4.36c-0.55,2.76-0.3,5.84-1.44,8.3c-2.08,4.49-6.21,6.97-10.79,8.63
		c-0.48,1.62-0.97,3.23-1.45,4.85c0.18,0.12,0.36,0.23,0.54,0.35c4.33,2.79,8.65,5.58,12.98,8.37c0,0,0,0,0,0
		c0.18-0.18,0.36-0.37,0.53-0.55v0c-0.77-0.88-1.44-1.78-2-2.71c-1.67-2.79-2.31-5.85-1.58-9.53c0.74-3.75,3.26-4.48,6.16-5.3
		c2.86-9.27,9.91-12.89,19.42-10.17c1.96-1.18,3.6-2.66,5.51-3.21c4.76-1.36,8.19,1.33,8.49,6.53c0.11,1.95-0.43,3.94-0.52,5.92
		c-0.04,0.88,0.02,1.94,0.47,2.64c5.96,9.23,4.48,16.1-4.88,22.09c-0.9,0.57-1.84,1.08-2.76,1.62c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.76,2.96-1.08,4.09-3.03,4.53c-1.17,0.26-2.92,0.28-5.71,0.29c2.16,1.15,4.29,2.38,6.43,3.59c1.43,0.81,2.86,1.6,4.3,2.37
		c2.16,1.15,4.36,2.22,6.62,3.13c5.29,2.13,11.23,3.35,16.56-0.16c3.71-2.45,7.29-5.27,10.39-8.46
		C412.93,787.44,415.24,776.31,406.8,769.54z"
          />
          <path
            className="st3"
            d="M1125.35,528.74c-0.11-0.72-0.22-1.43-0.32-2.15v0v0c-0.74-0.41-1.41-0.87-2.03-1.37
		c-0.31-0.25-0.6-0.51-0.88-0.79c-0.56-0.55-1.06-1.13-1.49-1.76c-0.87-1.25-1.5-2.66-1.87-4.19c-0.18-0.77-0.31-1.56-0.36-2.38
		c-0.14-2.06,0.14-4.28,0.84-6.6c-0.34-0.1-0.67-0.21-1.01-0.32c-1-0.34-2.01-0.71-3.03-0.82c-3.31-0.34-4.21-2.67-4.43-5.34
		c-0.23-2.79-0.05-5.61-0.05-9.31c-3.04-1.65-6.75-3.87-10.64-5.72c-3.58-1.7-4.39-2.91-3.5-6.72c0.19-0.8,0.38-1.59,0.52-2.39
		c1.84-10.37,3.96-20.71,5.4-31.13c0.75-5.41,0.33-10.99,0.38-16.49c0.04-4.24,0.01-8.48,0.01-13.25c4,1.2,6.9,2.07,9.97,2.99
		c0-5.92,0.02-11.8-0.03-17.68c0-0.13-0.03-0.26-0.07-0.38c-0.13-0.38-0.42-0.76-0.59-1.14c0,0,0,0,0,0
		c-0.49,1.51-0.91,3.04-1.42,4.54c-0.17,0.5-0.35,0.99-0.55,1.48c-2.04,5-4.22,5.69-8.97,3.06c-0.4-0.22-0.88-0.31-1.33-0.46
		c-1.63,0.36-2.28-0.06-2.54-0.79c-0.26-0.73-0.13-1.77-0.2-2.65c-0.94-1.66-1.89-3.32-2.83-4.98c-0.07,0.79-0.13,1.59-0.18,2.38
		c-0.16,2.39-0.31,4.77-0.67,7.13c-1.47,9.5-4.22,18.42-12.14,24.79c-1.2,4.43-2.13,9.05-7.26,10.85c0.09,0.74,0.17,1.49,0.26,2.23
		c0.11,0.94,0.22,1.89,0.33,2.86c0.2,1.73,0.41,3.52,0.63,5.43c0.91-0.05,1.82,0.06,2.62-0.16c3.4-0.95,5.71-2.84,7.17-6.36
		c2.23-5.36,5.87-6.83,9.1-4.46c3.3,2.42,3.91,8.55,1.29,11.75c-3.8,4.63-8.5,7.65-14.97,8.07c1.82,1.73,3.97,2.81,4.34,4.32
		c0.93,3.79,1.45,7.78,1.43,11.68c-0.03,5.45,1.83,10.05,5.79,13.42c2.55,2.17,6.01,3.26,9.06,4.84c3.16,1.64,4.82,5.02,3.74,7.56
		c-0.94,2.22-5.42,4.4-7.79,3.68c-4.78-1.46-8.65-4.06-10.68-8.86c-0.53-1.25-1.12-2.47-1.69-3.7c-0.08,5.4-1.78,10.92,1.47,15.85
		c1.42,2.15,3.1,4.13,4.66,6.19c3.74,4.92,7.15,10.14,11.34,14.65c3.74,4.02,8.27,7.34,12.57,10.8c3.2,2.57,5.38,8.77,3.61,12.18
		c-2.22,4.28-7.38,4.43-11.1-0.13c-3.11-3.82-5.41-8.29-7.84-12.1c0,5.06,0,10.62,0,16.45c1.58-3.82,4.18-2.78,6.58-1.51
		c7.08,3.73,14.08,7.6,21.29,11.5c0.19-0.76,0.34-1.09,0.33-1.41c-0.46-12.24-0.89-24.48-1.46-36.72
		C1126.05,533.03,1125.69,530.88,1125.35,528.74z"
          />
          <path
            className="st12"
            d="M467.9,656.38c-7.44-4.02-14.94-7.9-22.26-12.11c-3.64-2.1-6.63-2.37-10.48-0.08
		c-8.38,4.98-17.11,9.37-25.65,13.98c0.27,0.14,0.72,0.29,1.07,0.57c0,0,0.01,0.01,0.01,0.01c-3.62,1.06-7.24,2.12-10.86,3.18
		c0.08,0.37,0.16,0.73,0.23,1.1c1.81,0.47,3.63,0.94,5.44,1.41c-0.04,0.18-0.07,0.37-0.11,0.55c-2.93-0.17-5.86-0.35-9.22-0.55
		c-4.07,2.45-8.56,5.16-13.32,8.02c2.27,1.65,4.14,3.01,5.93,4.3c0.59,0.43,1.18,0.85,1.76,1.28h0c0.55,0.36,1.11,0.73,1.66,1.09
		c0.57,0.26,1.14,0.52,1.72,0.78c-0.13,0.27-0.27,0.54-0.4,0.81c-0.64-0.36-1.27-0.73-1.91-1.09c-0.55-0.36-1.11-0.73-1.66-1.09
		c-2.78-1.6-5.56-3.2-8.46-4.87c-3.67,2.25-7.57,4.63-11.8,7.22c1.15,0.86,2.29,1.6,3.3,2.49c1.16,1.02,2.27,2.11,3.28,3.27
		c2.34,2.68,2.11,5.42-0.95,6.62c-3.08,1.2-6.46,1.63-9.71,2.4c1.68,1.67,3.24,3.48,5.05,5c3.75,3.15,7.63,6.16,11.46,9.22
		c1.16,0,2.32,0,3.55,0c0.28-4.09,3.8-3.83,6.24-4.74c2.59-0.96,5.39-1.35,7.86-1.92c-0.27-1.55-0.38-2.21-0.5-2.87
		c4.76-0.98,5-1.14,5.75-3.4c1.75-0.34,3.37-0.65,4.99-0.96c0.1-0.37,0.21-0.74,0.31-1.11c-1.38-1.39-2.68-2.87-4.17-4.13
		c-0.83-0.71-1.71-1.36-2.6-2c-0.89-0.64-1.79-1.27-2.68-1.92h0l0,0l0,0c-0.92-0.64-1.84-1.27-2.76-1.91
		c0.23-0.31,0.45-0.62,0.68-0.93c0.91,0.77,1.81,1.55,2.72,2.32h0l0,0c4.37,2.68,8.74,5.36,13.56,8.32
		c0.83-7.19,7.92-8.05,11.39-11.96c1.26-0.29,2.5-0.57,3.79-0.86c-0.42-1.08-0.64-1.65-0.63-1.63c1.56-0.55,3.49-0.64,3.92-1.5
		c1.81-3.55,4.78-6.04,8.15-7.22c5.83-2.04,12-3.12,17.76-4.54c-0.04-0.66-0.11-1.92-0.22-3.69c-0.3-0.28-1.2-1.1-2.1-1.92
		c0.16-0.3,0.33-0.61,0.49-0.91c3.2,1.16,6.4,2.32,10.03,3.64c-0.91-2.05-1.63-3.66-2.35-5.28c0.42,0.38,0.84,0.76,1.27,1.14
		C466.92,658.78,467.39,657.65,467.9,656.38z M397.49,683.97c-1.18-0.75-2.36-1.5-3.53-2.26c0.23-0.3,0.46-0.6,0.68-0.9
		c1.12,0.8,2.23,1.6,3.35,2.41C397.82,683.47,397.65,683.72,397.49,683.97z M410.63,658.8C410.84,659,410.92,659.24,410.63,658.8
		L410.63,658.8z"
          />
          <path
            className="st13"
            d="M620.99,625.29c4.14-2.23,8.21-4.6,12.29-6.93c4.87-2.78,9.72-5.59,14.58-8.38c0.47-2.25,0.94-4.51,1.42-6.76
		c-0.54-0.32-1.08-0.63-1.62-0.95c-2.06-1.21-4.13-2.4-6.2-3.6c-2.6-1.52-5.19-3.07-7.7-4.73c-7.59-5.01-14.98-10.31-22.77-15.7
		c-17.44,9.47-35.15,19.08-52.99,28.76c14.62,9.97,28.48,19.42,42.66,29.09C607.3,632.56,614.17,628.97,620.99,625.29z"
          />
          <path
            className="st12"
            d="M376.96,599.4c0.45,0.37,0.88,0.73,1.27,1.06c2.11-1.02,3.91-1.89,5.71-2.76c0.13,0.15,0.25,0.29,0.38,0.44
		c-0.96,1.12-1.92,2.25-3.46,4.05c3.43,0.9,6.48,1.7,9.64,2.52c-0.2,0.25-0.59,0.74-0.95,1.19c0.48,0.3,0.84,0.61,1.26,0.79
		c4.88,2.12,8.18,7.39,13.51,7.15c1.18,1.5,1.87,2.92,3,3.71c2.93,2.07,6.09,3.81,9.08,5.8c1.51,1.01,2.85,2.28,4.27,3.43
		c-0.14,0.16-0.28,0.32-0.42,0.48c1.51,0.39,3.03,0.79,4.54,1.18c-0.03,0.18-0.07,0.36-0.1,0.54c-1.64,0-3.29,0-4.93,0
		c-14.8,14.06-34.8,19.04-51.54,31.08c0,0.61,0,2.3,0,3.99c-0.24,0.05-0.49,0.09-0.73,0.14c-0.76-2.36-1.52-4.71-2.34-7.25
		c-6.28-2.94-12.7-5.95-19.35-9.06c-1.65-4.15-6.71-4.42-9.89-6.84c-1.72,0.99-3.05,1.75-4.37,2.51c-0.26-0.29-0.52-0.58-0.78-0.87
		c0.81-0.99,1.62-1.97,2.71-3.3c-2.55-1.26-5.09-2.51-7.62-3.76c-2.53-1.25-5.05-2.5-7.58-3.74c-3.85,3.01-7.72,6-11.54,9.04
		c-0.55,0.44-0.9,1.11-1.34,1.68c3.07,1.43,6.14,2.86,9.11,4.25c0.99,0.46,1.97,0.92,2.94,1.37c1.96-0.68,3.78-1.31,5.61-1.95
		c0.12,0.24,0.24,0.47,0.35,0.71c-1.19,0.86-2.37,1.72-3.68,2.67c3.37,2.06,6.55,4,9.73,5.94c3.63,2.2,7.04,5.77,11.86,2.06
		c-0.95,1.12-1.91,2.24-2.8,3.29c1.46,0.87,2.84,1.69,4.19,2.49c0.8-0.53,1.54-1.03,2.88-1.92c-0.42,1.46-0.64,2.25-0.93,3.26
		c3.2,1.94,6.39,3.87,9.46,5.72c1.01-1.08,1.9-2.03,3.41-3.64c-0.18,1.61-0.25,2.23-0.34,3.1c2.71,0,5.35,0,7.98,0
		c0.01,0.12,0.01,0.24,0.02,0.36c-2.05,0.53-4.1,1.06-6.62,1.71c2.61,1.43,4.71,2.58,6.65,3.65c7.07-4.33,13.95-8.54,20.8-12.74
		c-1.21-0.53-2.54-1.12-3.87-1.7c-0.1-0.54-0.2-1.07-0.3-1.61c6.49,1.88,11.81,0.08,16.43-3.95c-0.79-0.58-1.67-1.24-2.85-2.12
		c0.98-0.37,1.86-1,2.19-0.79c4.08,2.66,7.2-0.54,10.6-1.7c1.08-0.37,2-1.22,2.96-1.93c0.14-0.1,0.28-0.2,0.42-0.3
		c-0.48-0.25-0.97-0.51-1.45-0.76c0.06-0.16,0.13-0.31,0.19-0.47c0.62,0.25,1.23,0.49,1.85,0.74c1.34-0.29,2.85-0.27,4-0.91
		c6.39-3.57,12.7-7.28,19.03-10.98c3.97-2.32,7.92-4.68,12.11-7.17c-7.73-3.87-12.2-13.01-23.39-12.69c1.6-1.35,2.28-1.92,3.16-2.67
		c-6.83-4.38-13.52-8.68-20.47-13.13c-0.23,1.64-0.36,2.5-0.48,3.36c-0.3,0.06-0.6,0.11-0.91,0.17c-0.47-1.44-0.94-2.87-1.63-5.01
		c-5.33-2.55-11.3-5.4-17.26-8.25c-3.58,2.4-7.17,4.79-10.75,7.19C376.04,598.64,376.51,599.03,376.96,599.4z"
          />
          <path
            className="st3"
            d="M501.09,762.11c8.19,5,15.46,9.45,22.73,13.89c0.47,0.25,0.93,0.5,1.4,0.75c0,0,0.01,0.01,0.01,0.01
		c0.89,0.52,1.78,1.04,2.67,1.56c0,0,0.07,0.08,0.07,0.08c1.99,0.49,4.01,0.89,5.98,1.48c2.47,0.75,4.91,1.61,7.34,2.49
		c5.58,2.01,6.19,2.87,6.25,8.63c5.65-3.31,11.31-6.62,16.97-9.94c6.35-3.72,12.71-7.44,19.1-11.19c4.03-2.36,8.07-4.73,12.13-7.1
		c-16.03-9.78-31.79-19.4-47.34-28.89C532.85,743.16,517.42,752.37,501.09,762.11z"
          />
          <path
            className="st5"
            d="M498.7,765.58c-0.65-0.43-2.04-0.44-2.72-0.03c-12.43,7.52-25.83,13.45-37.11,22.84
		c-2.49,2.07-5.1,4.01-7.75,6.09c8.14,4.68,15.67,9.1,23.3,13.36c6.72,3.75,13.62,7.19,20.28,11.05c2.11,1.23,3.64,1.58,5.79,0.28
		c12.24-7.36,24.56-14.6,36.82-21.92c1.4-0.83,2.63-1.94,4.21-3.13c-1.78-1.24-2.93-2.07-4.11-2.85
		C524.53,782.67,511.64,774.08,498.7,765.58z M501.22,806.34c-1.27,1.51-2.54,3.02-3.96,4.71c-0.75-0.71-1.3-1.23-2.11-2
		c2.04-1.14,3.86-2.16,5.68-3.17c0.33-0.18,0.66-0.36,0.99-0.54c0.08,0.13,0.17,0.26,0.25,0.4
		C501.78,805.93,501.5,806.13,501.22,806.34C501.21,806.34,501.21,806.34,501.22,806.34z"
          />
          <path
            className="st5"
            d="M240.47,804.09c14.37,9.44,28.46,19.31,42.79,28.81c27.84,18.46,55.77,36.8,83.67,55.17
		c3.52,2.32,7.05,4.25,8.13,9.1c0.95,4.28,3.97,7.96,8.39,8.97c10.72,2.45,21.59,4.15,32.68,3.04c7.45-0.74,14.37-8.87,13.83-16.38
		c-0.04-0.53-0.49-1.03-0.74-1.54c-5.22,8.49-16.98,10.15-24.63,8.93c-5.68-0.9-11.23-2.63-16.83-4.02
		c-5.56-1.38-7.8-4.28-7.9-10.14c-0.08-4.7-5.52-8.91-10.15-7.77c-1.43-1.17-2.78-2.55-4.37-3.53
		c-13.66-8.44-27.39-16.77-41.06-25.2c-18.3-11.29-36.59-22.6-54.81-34.01c-1.25-0.78-2.47-2.4-2.74-3.82
		c-0.85-4.48-1.25-9.04-1.82-13.57c-8.07-5.29-16.15-10.58-24.22-15.86l0,0l0,0l0,0c-2.34,1.17-4.69,2.33-7.06,3.51
		c-2.36,1.17-4.73,2.35-7.16,3.56c-1.62,0.81-3.26,1.62-4.93,2.45c0.01-0.08,0,0.05-0.02,0.29c-0.01,0.12-0.02,0.27-0.03,0.44
		c-0.01,0.09-0.01,0.18-0.02,0.27c-0.01,0.08-0.01,0.16-0.02,0.25C227.81,796.71,234.35,800.07,240.47,804.09z"
          />
          <path
            className="st3"
            d="M413.72,816.47c0.59,7.91,1.15,15.42,1.7,22.77c9.36,4.71,13.04,8.93,15.59,17.53
		c20.23-11.25,40.44-22.5,60.8-33.82c-14.85-8.77-29.52-17.42-44.03-25.98C436.4,803.48,425.01,810,413.72,816.47z"
          />
          <path
            className="st7"
            d="M654.18,599.94c2.97,1.72,5.74,3.04,9.31,1.3c5.2-2.53,10.88-4.17,15.84-7.08
		c8.4-4.93,16.39-10.54,25.08-16.21c-15.91-9.67-30.99-18.85-46.55-28.31c-14.34,9.01-28.97,18.21-43.68,27.45
		c0.78,0.37,1.44,0.64,2.05,0.99C628.89,585.35,641.56,592.61,654.18,599.94z"
          />
          <path
            className="st4"
            d="M1005.08,272.9c-1.97,0.32-4.36,0.72-7.38,1.22c1.25,3.5,2.39,6.7,3.53,9.91c-0.18,0.09-0.37,0.18-0.55,0.27
		c-2.34-2.99-4.68-5.98-7.07-9.02c-2.24,0.58-4.46,1.16-7.42,1.93c0.97,3.26,1.83,6.17,2.69,9.07c-0.21,0.15-0.42,0.29-0.63,0.44
		c-2.19-2.34-4.37-4.69-7.22-7.74c-4.68,3.84-9.56,7.83-14.44,11.82c0.15,1.57,0.31,3.15,0.48,4.88c-1.8,0.12-2.84,0.19-3.88,0.26
		c-0.18,0.28-0.35,0.55-0.56,0.88c-0.35,0.54-0.78,1.22-1.45,2.27c-1.71-3.68-3.6-6.43-4.27-9.45
		c-2.36-10.56-7.03-20.14-12.09-29.52c-1.94-3.6-4.82-6.69-7.27-10.01c0.93,3.59,2.66,6.67,3.97,9.92c1.5,3.7,0.9,7.29-1.76,10.37
		c7.22,5.28,10.91,15.58,3.5,22.43c4.9,4.99,2.4,14.42-3.73,21.56c2.58,7.57,1.19,15.14,0.37,22.85c1.19-1.66,2.38-3.31,3.57-4.97
		c0.24,0.46,0.48,0.92,0.72,1.38c2.18,1.03,4.36,2.05,7.19,3.38c-1.26,1.51-2.53,3.03-4.09,4.9c3.44,1.43,6.74,2.79,10.04,4.16
		c0.19,0.14,0.37,0.29,0.56,0.43c-0.06-0.27-0.12-0.54-0.18-0.82c-2.6-5.4-4.86-10.85-2.15-17.09c-1.62-0.78-2.94-1.42-4.25-2.05
		c0.65,3.21,1.39,5.92,1.72,8.68c0.74,6.19,0.66,6.2-1.14,7.88c0-3.85,0-7.57,0-11.07c-0.84-1.33-2.59-2.95-2.27-3.78
		c1.17-3.03-0.84-5.04-1.63-7.44c-1.88-5.73,0.37-10.45,2.99-15.04c0.27-1.64,0.53-3.27,0.87-5.36c2.09,0.63,3.46,1.04,5.22,1.56
		c-0.92,1.06-1.49,1.71-2.1,2.42c0.76,0,1.47,0,2.24,0c0.13-1.66,0.25-3.24,0.37-4.83c0.37-0.09,0.74-0.18,1.11-0.28
		c0.7,2.03,1.4,4.06,2.23,6.48c0.83,0,2.18,0,3.98,0c-1.23-1.33-1.93-2.09-2.63-2.84c0.22-0.23,0.44-0.47,0.66-0.7
		c1.28,0.71,2.55,1.42,3.85,2.14c-0.27,0.87-0.49,1.58-0.69,2.25c-0.07,0.22-0.14,0.44-0.2,0.66c5.53,3.7,7.06,13.29,5.34,18.28
		c-0.89,2.58-3.86,4.44-6.62,7.42c-1.21,5,4.31,9.34,11.75,8.86c0.59,1.35,1.22,2.81,1.86,4.27c0.67-0.8,1.28-1.68,1.98-2.44
		c0.23-0.25,0.48-0.49,0.74-0.71c2.53-2.1,2.85-4.08,0.98-7.01c-1.25-1.96-1.38-4.64-2.08-7.27c2.41-0.41,3.86-0.66,5.32-0.91
		c-1.51-6.41-2.81-12.59,1.68-17.48c-1.03-2.91-3.15-5.82-2.58-8.02c0.82-3.13,3.16-6.26,5.66-8.47c8.58-7.56,19.36-6.72,29.64-6.27
		c5.33,0.23,10.56,2.64,15.84,4.02c5.32,1.39,10.65,2.72,15.98,4.08c0.1-0.32,0.2-0.64,0.31-0.96c-11.4-6.62-22.06-14.55-34.8-20.06
		c0.68,1.56,1.05,2.43,1.78,4.1c-3.53-2.13-5.05-6.47-9.62-4.72c1.45,2.49,2.91,5,4.37,7.51c-0.32,0.21-0.65,0.43-0.97,0.64
		C1010.07,279.16,1007.7,276.19,1005.08,272.9z M1022.94,283.71c0.09,0.35,0.18,0.69,0.27,1.04c-0.16,0.04-0.33,0.08-0.49,0.12
		c-0.1-0.34-0.19-0.69-0.29-1.03C1022.61,283.8,1022.78,283.75,1022.94,283.71z M1020.7,280.85c0.53,0.79,1.06,1.59,1.58,2.38
		c-0.21,0.14-0.42,0.29-0.63,0.43c-0.5-0.8-1.01-1.61-1.51-2.41C1020.33,281.12,1020.51,280.98,1020.7,280.85z M1019.31,278.8
		c0.22,0.57,0.44,1.15,0.66,1.72c-0.19,0.07-0.38,0.13-0.57,0.2c-0.22-0.57-0.44-1.15-0.66-1.72
		C1018.93,278.94,1019.12,278.87,1019.31,278.8z M1014.35,282.28c0.09,0.35,0.18,0.69,0.27,1.04c-0.16,0.04-0.33,0.08-0.49,0.12
		c-0.1-0.34-0.19-0.69-0.29-1.03C1014.01,282.36,1014.18,282.32,1014.35,282.28z"
          />
          <path
            className="st3"
            d="M430.41,779.98c3.91,2.44,8.22,4.21,12.42,6.3c0,1.21,0,2.27,0,3.34c0.59,0.35,1.18,0.7,1.7,1.01
		c0.52,0.31,0.98,0.58,1.3,0.77c15.53-10.15,30.75-20.1,45.89-29.99c-1.48-0.92-3.56-2.19-5.63-3.49
		c-9.09-5.71-18.18-11.42-27.27-17.13c-5.93-3.12-11.86-6.24-17.79-9.36c-0.83-0.44-1.65-0.92-2.47-1.38
		c-0.46,0.23-0.93,0.45-1.39,0.68c6.22,4.32,12.44,8.64,18.65,12.96c3.02,2.1,6.17,4.05,8.99,6.39c0.99,0.82,1.84,2.77,1.58,3.95
		c-0.68,3.08-1.81,6.11-3.13,8.99c-1.21,2.65-3.49,2.73-5.69,1.21c-5.81-4.02-11.61-8.05-17.4-12.09
		c-5.79-4.04-11.58-8.08-17.38-12.12l0,0l0,0l0,0c-4.74,2.32-9.47,4.65-14.21,6.98s-9.49,4.66-14.24,6.99
		c1.63,1.34,3.23,2.55,4.7,3.9c6.05,5.55,13.33,9.91,16.98,17.81c0.41,0.88,2.1,1.18,3.19,1.74
		C423.24,776.97,426.85,777.76,430.41,779.98z"
          />
          <path
            className="st12"
            d="M533.9,725.04c-0.09-0.14-0.17-0.31-0.25-0.47c-0.08-0.15-0.18-0.3-0.31-0.38
		c-12.17-8.36-24.33-16.73-36.55-25c-0.73-0.49-2.05-0.57-2.92-0.29c-2.08,0.68-4.16,1.5-6.06,2.59
		c-14.05,8.05-28.06,16.17-42.77,24.67c13.83,8.72,26.97,17.01,40.11,25.3c13.75-9.73,28.68-17.3,43.99-24.2
		C530.73,726.54,532.32,725.78,533.9,725.04z"
          />
          <path
            className="st5"
            d="M191.19,599.3c1.86-3.49,4.2-7.3,4.31-11.06c0.35-11.29-0.07-22.62-0.57-33.91
		c-0.44-10.05-1.31-20.09-2.11-30.13c-0.62-7.78-1.4-15.55-2.15-23.32c-0.27-2.85-3.28-4.8-6.64-4.34
		c-1.14,0.15-2.25,0.49-2.72,1.05c1.9-2.22,4.3-4.19,5.56-6.73c1.3-2.62,1.48-5.8,2.16-8.79c-2.08-1.02-3.56-1.74-4.81-2.36
		c1.18-4.23,2.35-8.44,3.52-12.66c-1.43,0.79-2.85,1.57-4.28,2.35c-2.51,1.37-5.01,2.74-7.52,4.12c-3.93,2.17-7.84,4.35-11.72,6.61
		c-1.12,0.65-2.07,2.61-2.08,3.97c-0.04,4.61,0.33,9.23,0.62,13.84c0.38,6.1,0.84,12.2,1.26,18.26c5.96,0,11.37,0,17.39,0
		c0.37,1.54,1.25,3.44,1.18,5.3c-0.21,5.66-0.87,11.3-1.19,16.96c-0.34,6.04-0.59,12.08-0.72,18.13
		c-0.27,12.95-0.43,25.91-0.64,38.86c2.19,1.68,4.2,3.72,6.63,4.88C187.83,600.88,190.69,600.24,191.19,599.3z"
          />
          <path
            className="st12"
            d="M554.54,671.61c-0.71-0.54-1.41-1.08-2.08-1.59c-1.01-0.77-1.95-1.49-2.8-2.14
		c-16.61,9.62-32.55,18.84-48.84,28.27c5.44,3.71,10.98,7.54,16.57,11.3c6.67,4.49,13.39,8.93,20.08,13.39
		c6.17-2.67,12.33-5.36,18.52-7.99c2.07-0.88,4.18-1.69,6.33-2.35c7.52-2.28,11.57-15.1,10.02-20.91c-0.31-1.17-1.26-2.33-2.22-3.15
		c-4.13-3.55-8.38-6.95-12.55-10.45c-0.58-0.49-0.98-1.2-1.46-1.81c-0.82-0.17-1.64-0.34-2.46-0.51c0.1-0.28,0.21-0.56,0.31-0.84
		c0.72,0.45,1.45,0.89,2.17,1.34l0,0c0.56-0.02,1.13-0.04,1.69-0.06c-0.74-0.56-1.47-1.12-2.2-1.68
		C555.26,672.16,554.9,671.89,554.54,671.61z"
          />
          <path
            className="st12"
            d="M1122.95,488.7c12.1,3.83,23.4,7.41,35.08,11.1c0.46-23.77,0.92-47.3,1.38-70.8
		c-7.1-4.3-14.49-8.78-21.83-13.22c-1.17,2.03,1.24,5.05-2.44,5.94c-0.91-2.62-1.78-5.12-2.51-7.25c-2.92-1.03-5.54-1.95-8.52-3
		c-0.17,2.89-0.59,5.83-0.45,8.75c0.29,6.26,1.16,12.51,1.28,18.77c0.26,13.99,0.52,28-1.92,41.89
		C1122.53,483.72,1122.95,486.71,1122.95,488.7z M1126.01,486.89c0.89-8.97,1.78-17.93,2.71-27.37
		C1131.22,463.23,1129.55,484.98,1126.01,486.89z M1135.55,488.18c0-9.36,0-18.28,0-27.2c0.28-0.01,0.57-0.01,0.85-0.02
		c0.19,3.04,0.52,6.08,0.53,9.13c0.01,3.19,0.1,6.46-0.54,9.54C1135.82,482.34,1139.16,485.56,1135.55,488.18z M1141.34,459.85
		c-4.2-2.6-8.34-5.3-12.5-7.98c0.38-0.53,0.76-1.06,1.14-1.59c4.38,2.6,8.76,5.21,13.75,8.18
		C1142.34,459.29,1141.58,460,1141.34,459.85z M1153.01,479.85c-1.45,1.24-2.14,1.83-3.09,2.64c-1.29-0.88-2.51-1.71-3.73-2.54
		c0.18-0.47,0.35-0.93,0.53-1.4C1148.58,478.93,1150.43,479.31,1153.01,479.85z M1146.88,472.2c2.37,1.98,3.99,3.33,5.82,4.86
		C1149.21,478.15,1147.71,477.03,1146.88,472.2z M1153.64,485.4c-0.09,0.44-0.17,0.88-0.26,1.32c-3.11,1.45-4.97-1.75-7.59-2.13
		c0.1-0.4,0.2-0.8,0.3-1.2C1148.62,484.06,1151.13,484.73,1153.64,485.4z M1147.38,467.2c2.71,1.59,5.33,3.12,7.94,4.65
		C1149.34,473.14,1147.24,471.69,1147.38,467.2z M1129.54,442.98c6.64,4.75,13.28,9.49,20.54,14.69
		C1145.54,457.27,1129.17,446.15,1129.54,442.98z M1142.8,488.9c-0.34-0.14-0.68-0.27-1.02-0.41c0-8.02,0-16.03,0-24.05
		c0.44-0.03,0.87-0.05,1.31-0.08c0.19,1.08,0.5,2.15,0.55,3.23c0.28,5.79,0.6,11.58,0.65,17.37
		C1144.31,486.27,1143.33,487.59,1142.8,488.9z M1145.77,487.72c2.1-1.72,5.32-0.62,9.77,3.34
		C1153.35,493.89,1148.3,492.17,1145.77,487.72z M1151.39,428.34c4.39,1.77,6.32,6.61,4.65,10.96c-0.56,1.45-0.45,3.16-0.78,5.78
		C1151.73,439.21,1148.81,434.39,1151.39,428.34z M1153.9,451.23c-0.09,0.2-0.19,0.41-0.28,0.61c-0.63,0-1.38,0.23-1.87-0.04
		c-4.67-2.5-9.38-4.93-13.9-7.68c-3.1-1.88-5.99-4.12-8.8-6.43c-0.8-0.65-0.96-2.08-1.41-3.15c0.3-0.11,0.59-0.22,0.89-0.33
		C1136.98,439.89,1145.44,445.56,1153.9,451.23z M1128.64,416.5c1.28,1.56,2.56,3.12,5.16,6.3c-1.61,1.41-2.92,2.56-4.22,3.71
		c-0.63-0.22-1.27-0.45-1.9-0.67c-0.84-2.47-1.67-4.93-2.5-7.37C1126.57,417.67,1127.35,417.23,1128.64,416.5z"
          />
          <path
            className="st3"
            d="M208.68,539.11c-0.24,4.01-1.77,8-1.61,11.95c0.46,12.04,1.47,24.05,2.26,36.08
		c0.28,4.29,0.56,8.58,0.89,13.75c4.58-3.14,8.64-5.85,12.62-8.68c2.17-1.55,2.56-3.86,2.17-6.36c-0.23-1.5-0.43-3-0.63-4.5
		c-1.1-8.22-2.29-16.4-7.98-23.05c-0.65-0.76-1.2-1.83-1.28-2.8c-0.57-7.06-0.9-14.14-1.56-21.18c-0.19-1.97-1.26-3.85-1.92-5.78
		c-0.77-2.27-1.63-4.52-2.27-6.83c-0.28-1-0.01-2.14-0.17-3.2c-0.39-2.67-0.88-5.33-1.37-8.27c5.76-0.08,7.25,1.59,5.78,5.23
		c-1.91,4.73-2.97,9.29,0.6,13.83c0.71,0.9,0.73,2.45,0.83,3.72c0.31,4,0.38,8.02,0.79,12.01c0.34,3.37,0.42,6.94,1.69,9.98
		c1.62,3.88,4.31,7.32,6.43,10.77c-0.31-4.13-0.76-8.73-0.97-13.34c-0.54-11.74-1.28-23.49-1.39-35.24
		c-0.22-22.8-0.06-45.6-0.07-68.4c0-1.15,0-2.31,0-3.82c-5.41,3.49-10.53,6.7-15.52,10.11c-0.66,0.45-0.94,1.86-0.91,2.81
		c0.13,4.49,0.58,8.98,0.64,13.47c0.11,9.33,0.16,18.67-0.03,28.01c-0.08,3.97-1.08,7.92-1.22,11.9
		c-0.24,6.68-0.47,13.31,3.16,19.54C208.93,533.04,208.84,536.34,208.68,539.11z M214.14,459.12c0,1.36,0.62,3.36-0.11,4.22
		c-2.75,3.25-1.54,6.95-1.85,10.48c-0.15,1.72-0.45,3.42-1.21,5.13c-1.07-5.55-2.15-11.1-3.38-17.5
		C208.94,460.06,210.32,457.36,214.14,459.12z"
          />
          <path
            className="st14"
            d="M207.71,732.73c-4.47-5.87-10.35-10.45-18.09-10.36c-9.41,0.12-16.45,5.58-21.76,12.75
		c-6.77,9.15-9.55,19.54-8.87,31.17c0.55,9.45,2.57,18.3,6.3,27.51c0.4-3.26,0.69-5.95,1.06-8.63c1.55-11.18,8.13-18.09,19.05-20.07
		c8-1.45,15.54,2.55,19.63,9.9c4.71,8.49,6.74,17.44,5.74,27.09c-0.29,2.8-0.47,5.62-0.7,8.42c5.31-9.56,8.71-19.76,9.15-30.62
		c0.26-6.57-0.42-13.25-1.38-19.77C216.41,750.34,213.81,740.75,207.71,732.73z M198.04,756.51c-2.29,2.89-4.94,4.62-8.75,4.4
		c-7.2-0.42-13.13-3.45-16.08-9.98c-1.28-2.82-0.32-6.79,0.1-10.19c0.22-1.77,1.47-3.42,2.45-5.55c2-0.31,4.26-0.67,6.82-1.07
		c0.11,1.71,0.18,2.96,0.33,5.35c1.2-1.81,1.82-2.74,2.45-3.67c2.31-3.45,6.08-3.75,9.47-1.33
		C201.11,738.97,202.36,751.06,198.04,756.51z"
          />
          <path
            className="st3"
            d="M647.53,634.91c-8.52,4.19-17.08,8.31-25.61,12.49c-0.51,0.25-0.91,0.72-1.36,1.09
		c10.45,6.37,20.89,12.73,32.03,19.52c18.03-9.29,36.38-18.75,55.49-28.59c-6.77-4.15-13.27-8.14-19.67-12.07
		c-3.84-2.36-7.65-4.69-11.46-7.03C667.15,625.18,657.36,630.08,647.53,634.91z"
          />
          <path
            className="st3"
            d="M449.18,550.37c1.7,4.03,1.21,8.81-1.78,11.08c-3.25,2.47-6.66,4.9-10.34,6.61c-3.99,1.84-8.31,3.86-12.19,1
		c-2.21,0.77-3.9,1.46-5.66,1.94c-3.24,0.89-6.51,1.66-9.77,2.49c0.03,0.37,0.06,0.74,0.08,1.1c15.28,8.75,30.56,17.5,45.51,26.07
		c14.26-8.66,27.68-16.8,41.23-25.02c-14.57-8.22-28.25-15.98-41.98-23.64C452.76,551.13,450.89,550.89,449.18,550.37z"
          />
          <path
            className="st9"
            d="M487.33,321.35c-0.35-1.82-0.91-3.6-1.38-5.4c-0.98,0.98-1.96,1.96-2.95,2.94c4.44,8.22,0.78,16.34-5.59,20.85
		c-3.05,2.16-6.03,1.6-9.17,0.65c-3.04,5.85-6.05,11.71-9.15,17.52c-0.27,0.51-1.14,0.69-1.73,1.03
		c0.44,10.74,0.89,21.48,1.31,32.22c0.26,6.62-0.03,13.3,0.85,19.83c1.01,7.47,2.08,11.49,12.07,8.9c0.73-0.19,1.52-0.19,2.3-0.28
		c-0.91-5.49,3.78-7.6,6.25-11.03c-2.73,0.14-5.27,0.28-7.81,0.41c-1.32,0.31-2.65,0.61-3.97,0.92c-0.23-0.42-0.46-0.83-0.7-1.25
		c1.78-1.72,3.57-3.43,5.35-5.15c-0.91-0.25-1.82-0.5-2.73-0.74c-0.01-0.24-0.01-0.49-0.02-0.73c4.71-1.86,9.41-3.72,14.25-5.63
		c-0.08-1.76-0.14-3.23-0.24-5.33c-2.91,0.79-5.32,1.46-7.73,2.12c-4.59,3.64-6.74,3.22-7.83-2.13c1.42-0.39,2.9-0.79,4.37-1.19
		c0,0,0.1,0.11,0.1,0.12c1.6-0.55,3.19-1.1,4.79-1.64c0.03-0.01,0.06-0.02,0.1-0.03c1.83-1.29,3.66-2.59,5.49-3.88
		c-0.21-0.42-0.43-0.84-0.64-1.26c-2.19-0.86-4.32-0.54-6.45-0.04c-1.65,0.39-3.3,0.88-4.96,0.99h0l0,0
		c-0.46-0.02-0.91-0.04-1.37-0.06l-0.28-0.12l-0.3,0.01c-1.06-0.54-2.12-1.07-3.67-1.85c5.12-4,9.91-5.88,15.53-5.27
		c4.13,0.45,5.28,1.73,5.18,5.97c-0.02,1.06-0.15,2.11-0.23,3.17c3.09,2.48,4.54,7.58,2.85,11c-0.34,0.7-1.75,0.88-2.66,1.3
		c-1.45,1.2-2.9,2.39-4.35,3.59c1.21,0.46,2.43,0.92,3.64,1.38l0,0c0.62,0.15,1.04,0.35,1.3,0.59c0.79,0.71,0.18,1.74-0.55,2.74
		c-1.65,2.26-3.36,4.47-5.05,6.7c2.05-0.63,4.13-1.17,6.14-1.91c1.89-0.7,3.71-1.78,5.31,0.46c0.74-1.12,1.48-2.23,2.22-3.35
		c0.25-10.6,0.49-21.2,0.74-31.8c-0.73-7.42-1.4-14.84-2.24-22.25c-0.2-1.77-0.67-3.51-1.11-5.25c-0.15-0.58-0.29-1.16-0.42-1.74
		l0,0c-0.81-4.35-1.62-8.69-2.43-13.04S488.16,325.69,487.33,321.35z M483.93,376.04c-0.36,0.03-0.71,0.07-1.07,0.1
		c-0.39-0.75-0.78-1.49-1.17-2.24c-1.36-0.58-2.1-1.4-2.5-2.35c-0.07-0.16-0.12-0.32-0.17-0.49c-0.1-0.33-0.16-0.68-0.21-1.03
		c-0.04-0.35-0.06-0.72-0.07-1.08s-0.01-0.74,0-1.11c0.01-0.56,0.02-1.12-0.01-1.67c-0.06-1.44,0.47-3.25-1.33-3.64
		c-0.25-0.06-0.56-0.08-0.91-0.07c-0.24,0.37-0.5,0.69-0.77,0.96c-0.7,0.71-1.48,1.09-2.27,1.14c-0.31,0.02-0.63-0.01-0.94-0.1
		c-1.1-0.3-2.17-1.24-3.05-2.81c-0.2-0.2-0.4-0.4-0.6-0.6c-0.27-0.3-0.55-0.6-0.82-0.91c-4.47-9.05,2-17.42,13.73-16.84
		c0.17,1.98,0.34,3.99,0.52,6.01s0.35,4.05,0.52,6.09v0c0,0,0,0,0,0c0,0,0,0,0,0c0.16,0.22,0.37,0.44,0.55,0.66s0.33,0.45,0.37,0.69
		c0.77,4.61,1.71,9.21,2.04,13.85C485.9,372.36,484.59,374.23,483.93,376.04z"
          />
          <path
            className="st3"
            d="M154.92,873.96c-0.03-0.47-0.05-0.94-0.07-1.42c-0.02-0.42-0.04-0.84-0.07-1.25c-0.2,0.24-0.42,0.48-0.63,0.71
		c-0.65,0.71-1.31,1.4-1.79,2.21c-1.46,2.42-3.05,4.28-6.29,4.32c-1.34,0.02-2.67,1.32-4,2.04c-0.81,1.86-1.61,3.72-2.42,5.58
		c-1.86-0.81-4.05-1.23-5.52-2.48c-7.66-6.51-8.18-15.29-7.23-24.42c0.3-2.88,0.83-5.74,1.26-8.64
		c-5.54,3.51-10.99,7.09-16.57,10.46c-4.22,2.55-8.77,4.58-12.88,7.29c-12.11,7.99-24.05,16.24-36.11,24.3
		c-3.87,2.58-7.92,4.88-11.71,7.19c0.99,5.09,2.49,6.39,6.59,7.23c5.09,1.05,9.89-0.1,14.06-2.56c5.92-3.5,11.44-7.72,17-11.79
		c6.04-4.43,5.89-5.43,13.43-2.27c3.89,1.63,8.27,2.65,12.49,2.85c9.35,0.43,18.35-1.66,26.98-5.34c4.98-2.12,10.03-4.07,15.07-6.11
		c-0.55-2.1-1.1-3.65-1.34-5.24C155.03,875.73,154.96,874.85,154.92,873.96z"
          />
          <path
            className="st3"
            d="M133.49,752.59c1.24,0.72,2.33,1.36,3.47,2.02c0,6.69,0.15,13.26-0.09,19.8c-0.07,2.04-1.2,4.03-1.84,6.05
		c-0.28-0.08-0.56-0.16-0.84-0.24c0.78,9.52,1.56,19.03,2.31,28.17c1.96,0.61,3.7,1.15,5.69,1.78c0,3,0,5.63,0,8.53
		c7.53-6.1,14.88-11.47,24.35-13.88c-8.37-13.08-11.86-27.31-12.34-42.32c-0.25-7.97,1.77-15.6,5.16-22.77
		c4.18-8.83,11-15.34,19.59-19.7c6.82-3.46,13.98-3.09,21.2,0.68c-0.81-4.43-1.55-8.51-2.43-13.3
		c-22.16,13.04-43.68,25.69-64.58,37.99C133.29,748.52,133.39,750.63,133.49,752.59z"
          />
          <path
            className="st4"
            d="M272.57,503.74c3.46-4.79,1.38-8.72-2.11-12.39c-4.22-4.43-7.78-9.24-8.86-15.59
		c-3.46,4.56,0.12,8.54,1.88,13.36c-2.01-1.28-3.15-2.01-4.22-2.69c-1.09,11.95,3.46,21.09,13.36,27.67
		c-0.72,1.17-1.29,2.11-2.17,3.55c-3.26-4.08-6.23-7.82-9.81-12.3c1.58,8.44,6.88,13.21,12.34,18.56c0.32-3.19-0.47-6.3,2.96-8.55
		c0,6.45,0,12.23,0,18.19c-3.01-3.18-3.39-8.68-8.7-10.17c-2.13,5.11-5.82,10.13-3.5,16.58c0.84,2.32,1.53,4.7,2.26,7.05
		c0.72,2.31,1.41,4.62,2.11,6.94c-0.24,0.11-0.48,0.22-0.72,0.32c-1.11-1.35-2.22-2.71-3.69-4.5c0.66,11.29,1.27,21.93,1.89,32.56
		c5.39,0.44,7.89,1.92,9.35,5.66c-3.21-1.15-6.32-2.26-9.63-3.45c-0.28,4.53,0.39,8.46,3.34,11.78c3.62,4.08,6.99,8.42,10.89,12.21
		c9.61,9.35,10.15,20.53,7.25,32.57c-0.04,0.17,0.16,0.41,0.34,0.84c0.79-0.36,1.58-0.71,2.51-1.14c0.2-3.89,0.7-7.68,0.53-11.44
		c-0.33-7.46-1.33-14.9-1.47-22.36c-0.08-4.54-1.39-8.54-3.14-12.54c-0.54-1.24-1.18-2.45-1.82-3.77c3.33-0.06,4.35-1.21,4.21-7.71
		c-4.51,5.98-6.85,6.66-9.71,2.84c2.3-0.53,4.64-1.07,7.47-1.73c-1.38-1.3-2.08-1.96-2.82-2.65c6.32-5.16,4.95-4.41,4.33-7.95
		c-0.45-0.82-0.9-1.63-1.36-2.45c-2.17-3.51-1.56-6.88,0.65-10.21c-1.62-2.13-3.21-4.21-4.79-6.3c0.11,2.84,0.29,5.69,0.42,8.53
		c0.13,2.84,0.22,5.68,0.14,8.52c-0.09,3.37-0.35,7.06-3.98,9.7c-1.02-1.18-2.23-2.14-2.85-3.4c-6.44-12.97-4.62-25.56,1.82-37.97
		c2.78-5.36,6.57-10.61,7.67-16.34c1.34-6.97,0.32-14.39,0.32-21.87c-1.73,2.38-3.22,4.44-4.72,6.5c-0.37-0.18-0.74-0.36-1.11-0.54
		c1.69-6.78,4.42-13.5,4.77-20.35c0.36-6.97-1.81-14-5.76-20.46c-1.21,0.71-2.27,1.33-3.21,1.87c4.59,14.45,2.64,28.26-1.03,42.41
		c-3.05-5.1-5.71-9.52-8.35-13.94C269.8,497.62,271.21,500.95,272.57,503.74z M288.52,606.79c-3.66-3.73-8.91-1.4-11.78-4.95
		C283.38,600.84,286.72,602.21,288.52,606.79z M279.35,594.92c0.26,0.28,0.53,0.57,0.79,0.85c-0.09,0.12-0.18,0.23-0.26,0.35
		c-0.37-0.23-0.73-0.46-1.1-0.69c-2.07-0.26-4.14-0.44-6.19-0.81c-1.25-0.22-2.45-0.72-3.57-1.47c1.19-0.36,2.37-0.66,3.51-0.79
		c0.46-0.05,0.91-0.08,1.35-0.08c0.44,0.01,0.88,0.04,1.31,0.12C276.69,592.67,278.1,593.42,279.35,594.92z M268.82,529.02
		c0.73,5.49,1.38,10.42,2,15.1C267.16,541.84,265.5,534.57,268.82,529.02z M281.28,510.81c0,4.32,0,8.55,0,13.31
		C277.11,519.45,277.25,512.81,281.28,510.81z M259.55,490.81c3.98-0.99,7.1,0.84,9.38,5.37
		C265.48,494.21,262.75,492.65,259.55,490.81z"
          />
          <path
            className="st7"
            d="M517.39,629.98c0.65-0.43,1.3-0.87,1.95-1.3c0.79,1.79,1.58,3.59,2.38,5.38c0.22,0.27,0.44,0.53,0.65,0.8
		c0,0,0,0,0,0c0.26,0.19,0.52,0.37,0.78,0.56c0,0,0.02,0.01,0.06,0.02c0.04,0.02,0.08,0.03,0.12,0.05c0.05,0.02,0.1,0.04,0.1,0.04
		l0.3,0c0.29,0.2,0.57,0.41,0.86,0.61c0,0,0.02,0.01,0.04,0.02c0.03,0.02,0.07,0.04,0.12,0.06c0.05,0.03,0.1,0.05,0.1,0.05
		l0.29-0.02c1.57,0.38,3.3,0.47,4.7,1.19c9.37,4.8,18.66,9.75,28,14.6c1.56,0.81,3.23,1.41,4.86,2.1c0.41-0.16,0.82-0.31,1.23-0.46
		c0.9-0.33,1.8-0.66,2.7-1c1.31-0.49,2.6-1,3.85-1.61c8.7-4.26,17.34-8.65,26.18-13.08c-0.51-0.62-0.79-1.19-1.25-1.5
		c-13.14-8.88-26.29-17.75-39.49-26.55c-0.85-0.57-2.44-1.02-3.17-0.61c-10.34,5.77-20.6,11.68-30.85,17.6
		C520.32,627.84,518.88,628.96,517.39,629.98z"
          />
          <path
            className="st7"
            d="M522.32,634.83c0,0,0.08,0.08,0.08,0.08c-0.01-0.01-0.02-0.03-0.03-0.04
		C522.35,634.85,522.34,634.84,522.32,634.83z"
          />
          <path
            className="st7"
            d="M522.37,634.86c0.01,0.01,0.02,0.03,0.03,0.04l0,0C522.39,634.89,522.38,634.88,522.37,634.86
		C522.37,634.86,522.37,634.86,522.37,634.86z"
          />
          <path
            className="st3"
            d="M207.8,600.75c-0.47-5.22-0.88-10.47-1.43-15.71c-1.36-12.95-3.08-25.88-1.4-38.94
		c0.11-0.85-0.4-1.78-0.58-2.68c-0.52-2.62-1.21-5.22-1.46-7.86c-0.35-3.66,0.93-7.29-0.59-11.1c-0.96-2.41-0.52-5.5-0.28-8.24
		c0.62-7.02,1.82-14,2.15-21.03c0.27-5.78-0.37-11.6-0.61-17.41c-0.24-5.69-0.44-11.37-0.76-17.06c-0.06-1.04-0.62-2.05-1.07-3.42
		c-5,3.48-9.51,6.61-14.02,9.75c-1.17,4.22-2.35,8.43-3.52,12.66c1.26,0.62,2.73,1.34,4.81,2.36c-0.68,2.99-0.87,6.16-2.16,8.79
		c-1.26,2.54-3.66,4.51-5.56,6.73c0.47-0.56,1.58-0.9,2.72-1.05c3.37-0.46,6.37,1.49,6.64,4.34c0.75,7.77,1.53,15.54,2.15,23.32
		c0.8,10.03,1.67,20.07,2.11,30.13c0.49,11.3,0.91,22.62,0.57,33.91c-0.11,3.75-2.45,7.56-4.31,11.06c-0.5,0.94-3.36,1.59-4.51,1.04
		c-2.43-1.17-4.45-3.2-6.63-4.88c0,1.77,0,3.55,0,5.55C189.33,603.4,198.49,604.57,207.8,600.75z"
          />
          <path
            className="st14"
            d="M158.05,842.99c1.33,1.89,2.59,3.66,4.42,6.26c0.24-2.45,0.38-3.77,0.58-5.78c1.4,1.08,2.42,1.86,3.7,2.84
		c0.53-0.46,1.26-0.87,1.62-1.49c0.35-0.61,0.32-1.44,0.48-2.29c1.22,0.64,2.12,1.11,3.1,1.62c13.06-6.75,22.78-17.03,31.87-27.89
		c-6.46-9.3-18.29-10.14-27.05-9.18c-2.4,0.26-4.83,0.31-7.25,0.46c-0.13-0.3-0.26-0.59-0.39-0.89c-0.99,0.84-1.92,1.78-2.99,2.49
		c-6.38,4.25-12.97,8.19-19.13,12.72c-5.64,4.15-10.07,9.35-9.98,17.01c0,0.15,0.2,0.3,0.34,0.49c0.7-0.65,1.3-1.21,2.47-2.32
		c0,2.86,0,4.97,0,7.07c0.12-0.01,0.24-0.02,0.36-0.02c0.45-1.35,0.9-2.71,1.34-4.06c0.18,0.01,0.35,0.02,0.53,0.03
		c0.36,2.24,0.73,4.48,1.09,6.72c0.22-0.04,0.43-0.07,0.65-0.11c0.25-2.01,0.49-4.02,0.74-6.02c0.3,0.01,0.59,0.01,0.89,0.02
		c0.42,2.51,0.83,5.01,1.25,7.52c0.22-0.01,0.43-0.02,0.65-0.03c0.24-1.6,0.48-3.21,0.79-5.32c2.98,1.64,0.77,6.63,4.86,6.71
		c-0.1-2.3-0.2-4.49-0.3-6.68c0.34-0.05,0.68-0.09,1.03-0.14c0.8,2.33,1.61,4.67,2.46,7.14
		C160.77,848.47,156.25,845.4,158.05,842.99z"
          />
          <path
            className="st12"
            d="M520.97,651.13c-5.23-1.48-9.6-6.02-14.51-9.3c-1.19,0.7-2.39,1.4-4.03,2.37c9.59,3.14,14.87,6.83,14.5,10.12
		c-2.48-1.06-4.89-2.1-7.31-3.14c-0.21,0.17-0.41,0.33-0.62,0.5c1.63,1.58,3.26,3.16,4.89,4.73c-0.23,0.3-0.45,0.6-0.68,0.9
		c-1.16-0.43-2.41-0.7-3.46-1.32c-3.01-1.75-6.21-3.34-8.84-5.56c-3.41-2.88-6.51-2.05-10.11-0.23c2.83,1.77,5.58,3.27,8.09,5.12
		c2.39,1.76,5.71,2.74,5.82,6.91c-5.2-3.62-10.4-7.24-15.65-10.9c-0.36,0.81-0.71,1.62-1.08,2.46c4.47,3.31,10.6,4.28,12.7,10.21
		c-4.7-1.89-8.99-4.39-13.39-6.65c-1.56-0.8-3.42-1.01-5.14-1.48c0,0.17-0.01,0.35-0.01,0.52c4.26,2.39,8.53,4.77,12.79,7.16
		c-3.27,2.91-4.79-0.35-7.07-0.34c-1.02,0.41-2.06,0.67-2.88,0.36c-2.59-1-5.03-2.4-7.66-3.29c-1.88-0.63-3.95-0.69-5.94-1
		c3.21,4.12,9.53,3.37,11.22,9.9c-2.37-0.25-4.12-0.43-5.55-0.58c0.08,1.36,0.15,2.58,0.23,4.02c-1.39-0.32-2.37-0.55-3.36-0.78
		c-0.26,0.2-0.51,0.39-0.77,0.59c0.2,1.27,0.39,2.53,0.67,4.29c-2.76-1.08-4.82-1.88-6.88-2.69c-0.17,0.35-0.34,0.69-0.51,1.04
		c9.52,5.47,19.04,10.94,28.92,16.62c15.81-8.64,32.03-17.5,48.88-26.71c-1.78-1.19-2.58-1.88-3.5-2.31
		C533.8,659.45,528.79,653.34,520.97,651.13z"
          />
          <path
            className="st12"
            d="M438.72,613.75c0.76,0.31,1.69,0.68,2.62,1.05c-0.08,0.17-0.15,0.34-0.23,0.51c-1.35,0.12-2.7,0.23-5.03,0.43
		c5.87,4.61,10.99,8.64,16.06,12.63c7.85-1.75,15.53-5.17,23.41-0.77c1.43,8.09-1.17,11.38-8.15,10.82
		c-5.64-0.46-11.03-1.38-15.29-4.87c-3.51,2.28-6.71,4.35-9.79,6.35c5.22,2.86,10.21,5.62,15.21,8.34
		c7.59,4.12,15.12,8.69,24.1,3.74c0.98-0.54,2.25-1.01,2.75-1.88c1.72-2.95,4.22-4.57,7.46-5.32c3.1-0.72,5.69-2.28,8.42-4.02
		c3.96-2.52,8.87-3.56,14.67-5.71c-20.94-11.88-44.39-17.16-62.16-31.68C447.58,607.2,443.05,610.55,438.72,613.75z"
          />
          <path
            className="st3"
            d="M211.41,718.26c-1.67,0.63-3.33,1.25-4.97,1.87c0.56,2.72,0.62,5.18,1.59,7.19c1,2.07,2.93,3.68,4.39,5.54
		c0.5,0.63,0.84,1.4,1.18,2.14c7.82,16.52,10.85,33.86,8.75,52.38c7.64-5.51,15.13-10.9,23.41-16.86c0-11.59,0-23.84,0-36.09
		c-9.78-6.02-19.55-12.03-29.33-18.05C214.76,717,213.08,717.63,211.41,718.26z"
          />
          <path
            className="st15"
            d="M671.45,360.26c-10.67,5.86-21.45,11.54-32.17,17.3c-8.11,4.36-16.4,8.42-24.24,13.22
		c-12.35,7.55-24.3,15.77-36.61,23.4c-9.52,5.9-19.41,11.2-29.01,16.99c-10.55,6.36-20.87,13.11-31.43,19.46
		c-13.2,7.94-26.54,15.64-39.82,23.44c1.31,0.95,2.62,1.89,3.93,2.84c0.38-0.29,0.71-0.67,1.13-0.87
		c18.54-8.64,35.02-20.8,52.67-30.97c10.82-6.23,21.56-12.6,32.45-18.68c5.66-3.16,11.74-5.6,17.37-8.8
		c12.85-7.3,25.49-14.95,38.3-22.31c15.49-8.9,31.07-17.66,46.63-26.43c2.16-1.22,4.48-2.15,6.72-3.22c0.23,0.28,0.47,0.56,0.7,0.84
		c-0.31,1.05-0.62,2.1-0.93,3.16c-1.39,0.71-2.79,1.42-4.19,2.13c-1.4,0.71-2.82,1.43-4.25,2.16c-0.22,3.81,2.18,7.7-0.64,11.23
		c3.65,1.18,7.3,2.36,10.46,3.39c0-3.86,0-7.92,0-11.97c-0.1,0.02-0.2,0.05-0.31,0.07c-0.19-2.16-0.38-4.33-0.56-6.49
		c0.55-0.6,1.58-1.22,1.56-1.79c-0.19-4.07-0.61-8.12-1.03-13.11C675.64,357.18,673.71,359.02,671.45,360.26z"
          />
          <path
            className="st7"
            d="M631.32,715.1c7.39,4.39,14.77,8.77,22.48,13.35c14.13-9.21,31.1-13.83,44.95-24.56
		c-1.14-0.84-2.08-1.53-3.02-2.22c-8.31-6.08-16.63-12.16-24.94-18.25c-8.59,6.47-17.29,12.79-25.73,19.45
		C640.26,706.67,635.89,711,631.32,715.1z"
          />
          <path
            className="st5"
            d="M644.99,668.37c-0.41-0.46-0.76-1.01-1.19-1.43c-0.14-0.14-0.3-0.27-0.46-0.37
		c-7.3-4.45-14.64-8.83-21.97-13.24c-4.11,2.21-8.13,4.61-12.35,6.6c-11.35,5.35-22.8,10.5-34.16,15.81
		c-0.69,0.32-1.04,1.38-1.55,2.1c4.45,2.77,8.91,5.54,13.38,8.33c4.47,2.79,8.97,5.58,13.51,8.41c1.92-1,4.22-2.2,6.52-3.39
		c4.92-2.54,9.86-5.03,14.76-7.61c4.75-2.5,9.46-5.1,14.18-7.65c1.69-0.81,3.38-1.63,5.07-2.44l0,0c0,0,0,0-0.01,0.01
		c0,0-0.01,0.01-0.01,0.01l0,0C642.13,671.8,643.56,670.09,644.99,668.37z"
          />
          <path
            className="st11"
            d="M736.8,581.01c3.19-3.42,5.68-7.27,7.03-12.01c0,0,0,0,0,0c-0.96,0.16-2.01,0.15-2.93,0.38
		c-0.31,0.08-0.6,0.18-0.87,0.33c-3.62,1.98-7.05,4.32-10.67,6.31c-4.09,2.24-4.89,1.92-7.41-2.03c-0.5-0.78-1.12-1.48-1.68-2.22
		c-0.03,0.53-0.05,1.06-0.08,1.63s-0.06,1.17-0.09,1.85c-1.43,0.75-2.94,1.55-4.67,2.46c-3.34-2.14-3.36-5.82-3.8-8.89
		c-0.89-6.14-1.09-12.38-1.58-18.57c-1.42-0.48-2.84-0.96-4.86-1.63c-1.77,7.05-3.46,13.79-5.15,20.53c2.06,1.33,3.95,3.17,6.2,3.85
		c3.18,0.97,3,3.73,3.38,5.75c0.19,1.01-2.12,2.55-3.38,3.76c-0.58,0.56-1.45,0.8-2.06,1.34c-8.73,7.63-18.91,12.79-29.34,17.6
		c-3.11,1.43-6.12,3.06-9.75,4.9c5.78,4.04,11.14,7.79,16.51,11.54c17.73-9.38,34.97-19.51,50.45-32.42
		C733.76,584.03,735.35,582.57,736.8,581.01z"
          />
          <path
            className="st12"
            d="M489.58,693.63c-10.8-6.56-21.42-13.05-32.1-19.45c-0.81-0.48-1.95-0.39-2.94-0.57
		c1.54,1.71,3.36,2.43,4.94,3.5c1.55,1.04,2.85,2.44,4.26,3.68c-0.27,0.47-0.54,0.95-0.81,1.42c-1.53-0.44-3.24-0.58-4.56-1.37
		c-2.88-1.71-5.5-3.87-8.39-5.58c-0.84-0.49-2.21-0.08-4.19-0.08c5.13,3.1,9.36,5.52,13.42,8.21c0.92,0.61,1.24,2.15,1.83,3.26
		c-0.29,0.2-0.57,0.4-0.86,0.59c-0.95-0.39-1.97-0.65-2.83-1.18c-4.04-2.49-7.99-5.12-12.09-7.51c-0.77-0.45-2.03-0.06-3.06-0.06
		c4.93,4.63,11.45,6.14,14.66,12.18c-1.5-0.22-2.93-0.62-4.15-1.36c-3.48-2.1-6.83-4.42-10.35-6.45c-0.96-0.55-2.35-0.37-3.54-0.53
		c3.42,3.57,8.4,4.73,10.8,9.76c-5.77-0.53-8.76-6.5-14.3-5.73c10.17,5.65,11.72,6.93,11.24,10.03c-1.76-0.51-3.5-1.01-5.32-1.53
		c0.77,1.8,1.38,3.22,2.23,5.2c-1.42-0.26-1.99-0.37-2.56-0.48c-0.14,0.86-0.27,1.6-0.39,2.36c-2.59-0.96-4.98-1.84-7.36-2.72
		c1.38,1.85,4.34,2.78,2.08,6.07c-2.88-1.42-5.47-2.69-8.05-3.95c-0.55-0.36-1.1-0.72-1.66-1.08c-0.2,0.16-0.4,0.33-0.6,0.49
		c0.55,0.36,1.1,0.72,1.65,1.08l0,0c0.81,1.6,1.62,3.19,2.43,4.79c-0.31,0.23-0.62,0.46-0.92,0.7c-1.43-0.42-2.87-0.84-4.36-1.28
		c-0.13,0.76-0.28,1.64-0.46,2.67c-1.91-0.55-3.61-1.04-5.3-1.53c-0.22,0.41-0.45,0.83-0.67,1.24c7.66,4.88,15.32,9.75,22.8,14.51
		C456.72,713.1,473,703.45,489.58,693.63z"
          />
          <path
            className="st5"
            d="M1140.27,399.5c-7.89-6.66-16.33-12.28-25.94-16.49c0.71,6.82,1.7,13.5,2.02,20.2
		c0.03,0.66,0.05,1.33,0.06,1.99c0.06,4.65-0.41,9.32-0.59,13.98c0,0,0,0,0,0c0,0,0,0,0,0c1.57,0.98,3.15,1.96,4.72,2.95
		c0.16-1.99,0.27-3.99,0.42-5.99s0.32-3.99,0.59-5.97c0.21-1.52-0.47-4.17,2.68-3.86c0.33-3.26,0.66-6.51,1.09-10.79
		c2.14,1.69,3.79,2.99,5.45,4.3l-0.02-0.02c0.72,0.48,1.45,0.97,2.17,1.45l-0.02-0.02c0.72,0.48,1.45,0.97,2.17,1.45l-0.02-0.02
		c0.72,0.49,1.44,0.97,2.16,1.46l-0.01-0.01c5.47,1.75,10.1,4.74,13.64,9.32c0,0-0.02-0.02-0.02-0.02c0.72,0.48,1.45,0.97,2.17,1.45
		l-0.02-0.02c0.49,0.24,0.97,0.48,1.46,0.73l0,0c-0.01-0.01-0.02-0.01-0.03-0.02c0.01,0.01,0.02,0.01,0.03,0.02
		c0.01,0,0.02,0.01,0.02,0.01c-0.01,0-0.02-0.01-0.02-0.01c0.49,0.33,0.98,0.67,1.46,1c0.63,0.47,1.22,1,1.86,1.44
		c0.21,0.15,0.43,0.28,0.66,0.4c5.66,2.96,10.92,6.11,8.22,13.98c-0.04,0.11,0,0.24-0.01,0.36c-0.39,19.63,0.88,39.29-1.35,58.89
		c-0.94,8.23-1.02,16.56-1.55,24.84c-0.04,0.68-0.52,1.34-0.8,2c-0.28-0.04-0.55-0.08-0.83-0.12c0-5.62,0-11.25,0-16.77
		c-0.97,1.17-1.97,2.38-2.82,3.41c-3.22-0.57-6.04-1.07-8.59-1.52c-0.8,3.67,2.64,7.87-1.59,10.79c-9.77-5.4-21.26-6.17-29.69-13.55
		c0.93,1.46,1.87,2.92,2.8,4.38c3.57,1.74,7.17,3.45,10.75,5.18c3.58,1.73,7.15,3.48,10.67,5.32c5.41,2.82,10.56,6.12,15.9,9.07
		c2.82,1.56,5.8,2.83,9.25,4.48c0-2.35,0-3.77,0-5.19c0-11.76,0-23.53,0-35.29c0-0.72,0.01-1.43,0.07-2.15
		c0.68-7.42,1.44-14.82,2.05-22.25c0.37-4.49,0.53-9,0.79-13.51c0.64-10.89,1.28-21.78,1.91-32.41c-2.63-1.33-4.44-2.53-6.44-3.22
		C1157.15,411.67,1148.26,406.25,1140.27,399.5z"
          />
          <path
            className="st5"
            d="M547.53,551.69c14.28-4.4,27.81-10.49,39.29-20.19c4.5-3.8,9.01-6.79,14.7-8.51c3.64-1.09,7.88-2.36,9.13-7
		c-0.96-0.81-2.36-1.43-2.8-2.46c-0.21-0.5-0.39-1.01-0.54-1.53c-0.46-1.56-0.73-3.21-1.12-4.8c-0.15,1.09-0.3,2.18-0.46,3.35
		c-0.05,0.39-0.11,0.8-0.17,1.21c-1.3,0.55-2.78,1.19-4.61,1.97c0.34-1.36,0.63-2.48,0.91-3.61c-6.34,2.95-12.72,5.82-19.02,8.85
		c-14.61,7.03-29.93,11.74-45.99,13.96c-2.11,0.29-4.35-0.39-6.53-0.63c-0.41,0.39-1.22,0.82-1.19,1.18
		c0.61,7.52,1.32,15.03,2.01,22.55C536.61,554.6,542.13,553.35,547.53,551.69z"
          />
          <path
            className="st9"
            d="M493.63,431.1c1.15-0.52,2.8-1.19,3.72-0.75c3.8,1.84,7.66,4.24,7.2,10.32c-0.05,0.7,0.07,1.41,0.1,2.11
		c1.15,0.48,2.3,0.95,3.45,1.43l0,0l0,0l0,0c0,0,0.03-0.03,0.03-0.03l0,0l0,0c-0.22-4.08,0.46-8.13-1.26-12.22
		c-2.11-5-1.54-10.37,2.02-14.5c4.12-4.79,9-8.92,14.16-13.94c-3.06-16.23-10.37-33.01-7.41-50.88c-3.65-1.95-6-4.2-5.8-7.74
		c0.28-4.92,1.06-9.8,1.63-14.78c-2.28-0.77-4.19-1.39-6.08-2.06c-1.89-0.68-3.75-1.42-5.62-2.13c0.41,3.36,0.87,6.72,1.22,10.09
		c0.97,9.35,1.92,18.7,2.82,28.06c0.58,5.98,1.26,11.97,1.53,17.97c0.27,6.01,0.44,12.07-0.05,18.05
		c-0.32,3.98-1.95,7.85-2.85,11.79c-1.79,7.81-7.14,12.03-14.35,14.37c-0.83,0.27-1.66,0.52-2.49,0.77
		c-2.46,0.73-4.93,1.39-7.4,2.09c1.82,0,3.66,0,5.75,0c0.39,0.67,0.83,1.43,1.27,2.19c1.68,0.72,3.36,1.44,5.04,2.16
		C491.38,432.66,492.41,431.66,493.63,431.1z"
          />
          <path
            className="st15"
            d="M1064.6,418.47c8.1,4.44,14.2-2.13,16.53-8.9c3.82-11.09,2.5-22-0.48-32.93c-1.74-6.4-4.52-12.31-9.16-17.11
		c-5.12-5.3-10.04-4.22-12.92,2.65c-0.64,1.53-1.09,3.14-1.63,4.71c1.55,2.01,2.37,4.13,2.47,6.13c0.01,0.29,0.02,0.57,0,0.85
		c-0.03,0.56-0.12,1.11-0.26,1.63c-0.44,1.58-1.4,3-2.88,4.09c0.22,5.51-1.95,11.82,5.85,14.94c-1.79,2.86-3.2,5.12-5.14,8.21
		c0,1.28-0.2,3.69,0.09,6.04c0.15,1.18,1.11,2.3,1.85,3.34c1.49,2.12,3.07,4.18,4.62,6.27
		C1063.89,418.41,1064.32,418.32,1064.6,418.47z"
          />
          <path
            className="st5"
            d="M611.44,717.29c1.79-1.83,3.27-3.95,4.89-5.93c-3.84-2.53-7.67-5.07-11.74-7.75
		c-1.36-0.89-2.74-1.81-4.15-2.74c-8.35,4.44-17.01,8.56-25.15,13.53c-6.48,3.95-12.33,8.93-18.47,13.46
		c0.23,1.71,0.45,3.42,0.68,5.13c0.47,0.43,0.94,0.86,1.42,1.29c1.83,0.99,3.67,1.98,5.5,2.97c1.03,0.5,2.06,1,3.09,1.5l0,0
		c-0.01-0.01-0.02-0.02-0.03-0.03c0.01,0.01,0.02,0.02,0.03,0.03c0.01,0.01,0.03,0.01,0.04,0.02c-0.01-0.01-0.03-0.01-0.04-0.02l0,0
		c0.49,0.47,0.98,0.94,1.47,1.41c0,0,0,0,0,0c0,0-0.02-0.02-0.02-0.02c0.72,0.48,1.45,0.97,2.17,1.45l-0.02-0.02
		c3.42,0.46,6.25,1.86,7.93,5.05c0.18,0.11,0.35,0.23,0.53,0.34c0.54,0.35,1.07,0.7,1.61,1.05
		C591.28,737.78,601.4,727.57,611.44,717.29z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor1}
              d="M308.07,480.93c-0.15,0.39-1.52,0.33-2.68,0.54c1.33,8.48,2.73,17.47,4.17,26.7c2.18-1.47,3.76-2.7,5.48-3.68
		c5.98-3.42,12-6.76,18.01-10.11c3.47-1.93,6.98-3.8,10.44-5.75c5.45-3.08,10.87-6.2,16.3-9.31c0-0.01,0-0.01,0-0.02
		c-1.02,0.18-2.04,0.36-3.25,0.57c-2.05-1.78-0.98-3.19,0.68-4.77c1.47-1.41,2.66-3.1,4.25-4.99c-3.36-1.62-5.8-2.8-8.4-4.05
		c1.56-2.2,2.63-3.72,4.11-5.81c-2.16-0.19-3.82-0.33-6.18-0.54c0.48-1,0.81-2.48,1.29-2.53c1.87-0.2,4.42-0.68,5.57,0.3
		c2.23,1.89,1.48,4.65,0.01,7.03c5.56,3.39,6.59,4.94,5.1,8.35c-0.83,1.89-2.1,3.59-3.12,5.41c-0.15,0.27-0.05,0.69-0.05,1.06
		c0.02,0,0.04-0.01,0.05-0.01c0,0-0.05,0.05-0.05,0.05c0,0,0,0,0,0c1.74-0.98,3.48-1.97,5.31-3c0.61-0.34,1.23-0.7,1.86-1.05
		c-1.37-10.23-2.71-20.26-4.09-30.55c-3.65,2.04-7.24,3.87-10.65,5.99c-10.62,6.59-21.29,13.12-31.71,20.03
		C316.08,473.7,310.25,475.1,308.07,480.93z M345.25,458.98c0.67-0.29,1.33-0.59,2-0.88c0.81,1.13,1.62,2.26,2.95,4.1
		c-2.21,0.86-3.32,1.3-4.62,1.8c-0.55,2.84,1.95,2.97,3.64,3.56c3.15,1.1,4.51,3.16,4.12,6.44c-0.37,3.17-1.54,5.63-5.58,6.1
		c-0.36-1.01-0.71-2.03-1.11-3.16c2.48-0.82,4.65-1.64,3.47-4.97c-2.91-1.67-7.78-1.83-8.01-6.39
		C342.02,463.44,344.14,461.18,345.25,458.98z M330.63,469.29c0.42-2.79,3.28-2.6,5.53-1.76c4.37,1.63,6.43,4.76,6.44,10.52
		c-0.03,0.22-0.01,1.57-0.46,2.73c-1.1,2.79-2.58,5.82-6.03,5.56c-3.14-0.24-4.71-3.16-5.13-5.97
		C330.43,476.72,330.08,472.89,330.63,469.29z M321.42,474.39c1.32,5.46,2.64,10.93,4.06,16.8c3.99-2.76,3.99-2.76,5.92-0.62
		c-2.03,1.07-3.95,2.08-6.31,3.33c-4.57-5.81-4.02-12.67-4.85-19.17C320.64,474.61,321.03,474.5,321.42,474.39z M310.56,484.76
		c0.35-2.24,1.5-4.35,2.36-6.7c4.11-0.61,4.61,1.6,4.63,4.84c-1.24-1.02-2.18-1.79-3.13-2.57c-3.33,5.78-2.33,11.43,2.96,16.19
		c0.98-0.46,2.02-0.95,4.09-1.93c-0.24,1.7-0.01,3.14-0.61,3.76c-1.4,1.44-3.38,2.83-5.22,0.81
		C311.96,495.13,309.68,490.38,310.56,484.76z"
            />
          </Link>
          <path
            className="st13"
            d="M786.79,627.06c-1.52,1.01-3.03,2.04-4.65,2.86c-14.61,7.48-29.27,14.89-43.91,22.32
		c8.75,4.34,17.5,8.68,26.18,12.98c6.75-3.86,13.48-7.71,20.21-11.56s13.44-7.69,20.16-11.53c0.24-2.18,0.49-4.36,0.73-6.55
		c-5.73-3.17-11.46-6.35-17.19-9.52l0,0h0C787.81,626.39,787.3,626.72,786.79,627.06z"
          />
          <path
            className="st5"
            d="M381.43,743.9c0.06-0.15,0.12-0.31,0.2-0.52c0.12-0.3,0.27-0.68,0.47-1.18c0.62,1.75,0.96,2.71,1.3,3.66
		c4.62-0.41,9.23-0.81,13.85-1.22c1.85-0.32,3.7-0.63,5.54-0.95l0,0h0c3.12-1.71,6.23-3.42,9.35-5.13c1.63-4.55,1.35-5.32-2.68-7.72
		c-2.32-1.39-4.65-2.78-6.92-4.26c-1.4-0.91-2.69-1.98-4.04-2.98c-1.08-0.13-2.15-0.27-3.26-0.4c0,0.81,0,1.53,0,2.34
		c-0.95-0.19-1.71-0.34-2.48-0.5c-0.49,0.26-0.97,0.51-1.46,0.77c1.05,1.41,2.1,2.83,3.53,4.76c-2.9-0.19-4.9-0.32-7.01-0.46
		c0,1.59,0,2.65,0,3.77c1.44,1.04,3.09,1.86,4.23,3.14c1.1,1.23,2.67,2.18,3.75,3.46c0.24,0.28,0.46,0.58,0.64,0.91
		c0.27,0.48,0.47,1.02,0.55,1.63c0.03,0.2,0.04,0.42,0.04,0.64c0,0.22-0.01,0.45-0.04,0.69c-0.29-0.13-0.59-0.25-0.89-0.37
		c-0.6-0.24-1.2-0.46-1.81-0.69c-1.51-0.57-3-1.17-4.28-2.08c-12.06-8.52-23.92-17.35-36.02-25.81
		c-6.08-4.25-12.84-7.56-18.75-12.03c-5.54-4.18-11.02-8.29-17.26-11.41c-1.92-0.96-3.51-2.59-5.73-4.28
		c-4.29,2.39-8.99,5.02-13.89,7.75c2.17,1.73,4.68,2.97,6.05,4.98c2.89,4.23,7.34,5.6,11.56,7.53c1.62,0.74,3.27,1.43,4.8,2.33
		c11.32,6.63,22.55,13.44,33.95,19.93c6.53,3.72,13.18,7.22,19.82,10.74c2.21,1.17,4.43,2.35,6.63,3.53
		C381.28,744.3,381.34,744.12,381.43,743.9z"
          />
          <path
            className="st3"
            d="M365.52,695.67l-0.27-0.03c-0.62-0.28-1.23-0.56-1.85-0.84c-1.15-0.91-2.31-1.82-3.46-2.74
		c-0.51-0.53-1.03-1.05-1.54-1.58c-0.19-0.19-0.39-0.39-0.58-0.58c-1.44-0.67-3.04-1.13-4.31-2.05c-6.53-4.7-12.98-9.51-19.46-14.29
		c-6.76,3.68-13.52,7.36-20.57,11.2c0.76,0.55,1.28,0.98,1.84,1.34c12.66,7.96,25.22,16.1,38.04,23.79
		c5.2,3.12,9.32,8.44,16.11,8.58c-0.06-0.3-0.13-0.6-0.19-0.9c3.23-0.9,6.47-1.8,9.81-2.73c-0.24-0.97-0.48-1.95-0.84-3.42
		c1.3-0.54,2.53-1.05,3.77-1.56c-3.83-3.07-7.71-6.07-11.46-9.22C368.76,699.15,367.19,697.35,365.52,695.67z"
          />
          <path
            className="st8"
            d="M605.49,698.84c8.79,5.53,17.31,10.89,25.83,16.25c4.57-4.09,8.94-8.43,13.74-12.22
		c8.44-6.66,17.14-12.99,25.73-19.45c-5.92-3.6-11.85-7.2-17.48-10.62C637.28,681.53,621.56,690.09,605.49,698.84z"
          />
          <path
            className="st15"
            d="M611.27,423.36c0,0,0,0.01-0.01,0.01c-1.2,0.89-2.4,1.79-3.75,2.79c-0.45,0.34-0.92,0.68-1.41,1.05
		c0.18,2.68,0.39,5.78,0.61,9.13c5.61-3.25,10.71-6.21,16.04-9.3c-1.9-0.74-3.86-1.35-5.68-2.26c-0.85-0.42-1.45-1.34-2.15-2.07
		c-0.01,0-0.01,0.01-0.02,0.01c-1.22,0.24-2.45,0.47-3.67,0.71C611.25,423.4,611.26,423.38,611.27,423.36
		C611.27,423.36,611.27,423.36,611.27,423.36C611.27,423.36,611.27,423.36,611.27,423.36z"
          />
          <path
            className="st15"
            d="M633.78,393.57c1.73,0.83,3.3,1.59,4.88,2.35c-9.09-1.41-13.57-0.72-14.8,15.47
		c-0.36-0.34-0.69-0.64-0.99-0.93c-0.83-0.78-1.49-1.4-2.14-2.01c-3.07-0.82-6.14-1.63-9.04-2.4c-2.28,5.19-2.62,9.84-0.07,14.52
		c-3.4-3.28-4.05-8.38-1.69-13.25c-2.35,1.19-4.27,2.16-6.22,3.15c0.7,5.07,1.38,10.01,2.08,15.13c0.49-0.2,0.96-0.39,1.43-0.58
		c1.4-0.57,2.73-1.11,4.07-1.66c0.08-0.21,0.17-0.41,0.24-0.6c0.05-0.12,0.09-0.23,0.13-0.32c0.11-0.27,0.18-0.45,0.19-0.46
		c3.15-1.51,5.91-2.83,8.75-4.19c-0.48-1.11-0.83-1.92-1.18-2.73c0.3-0.13,0.61-0.27,0.91-0.4c0.7,1.72,1.41,3.45,2.29,5.62
		c-2.46-1.12-3.95-0.27-5.28,0.77c-0.77,0.6-1.5,1.26-2.33,1.63c2.41,0.89,4.79,1.9,7.26,2.63c2.69,0.79,5.05,0.19,6.78-2.26
		c1.23-1.75,2.59-3.4,3.9-5.1c0.2,0.11,0.41,0.23,0.61,0.34c-0.19,0.61-0.38,1.22-0.57,1.82c-0.43,0.12-0.85,0.25-1.28,0.37
		c0.25,0.28,0.5,0.56,0.75,0.84c0.37-0.24,0.74-0.48,1.11-0.71c4.87-3.09,9.74-6.18,14.61-9.28c4.88-3.1,9.76-6.2,14.67-9.31
		c0.1-1.79,0.21-3.85,0.33-5.96c-2.65-1.51-5.33-3.55-4.43-6.82c1.06-3.85,4.85-3.85,7.84-3.87c-0.68-2.97-1.34-5.89-2.02-8.87
		C654.5,382.08,644.17,387.81,633.78,393.57z M639.6,396.94c0.08,0.36,0.16,0.72,0.23,1.09c-0.14,0.04-0.28,0.08-0.42,0.13
		c-0.16-0.32-0.31-0.63-0.47-0.95C639.17,397.11,639.39,397.02,639.6,396.94z M632.84,409.86c0-3.27,0-6.1,0-8.79
		c0.38,0.39,0.77,0.8,1.17,1.21s0.81,0.83,1.21,1.25c0.6-0.34,1.2-0.69,1.8-1.03c0.14,0.25,0.27,0.51,0.41,0.76
		c-0.57,0.29-1.15,0.58-1.72,0.87C634.82,405.92,633.92,407.71,632.84,409.86z M634.94,416.21c-0.24,0.59-0.48,1.18-0.72,1.77
		c-0.2-0.06-0.39-0.13-0.59-0.19c0.15-0.59,0.29-1.18,0.44-1.77C634.35,416.08,634.64,416.15,634.94,416.21z M653.21,399.16
		c-2.47-0.27-4.94-0.53-7.41-0.8c-0.08,0.28-0.15,0.57-0.23,0.85c2.09,0.79,4.19,1.58,6.28,2.37c-0.05,0.19-0.11,0.38-0.16,0.57
		c-2.95-0.78-5.9-1.56-8.84-2.34c-0.49,1.12-0.99,2.23-1.46,3.36c-1.42,3.42-2.83,6.85-4.25,10.27c-0.29,0.57-0.58,1.14-0.87,1.72
		c-0.25-0.14-0.51-0.28-0.76-0.42c0.35-0.6,0.69-1.2,1.04-1.8c0.97-2.09,2.02-4.15,2.94-6.26c0.31-0.7,0.6-1.41,0.88-2.13
		c0.28-0.73-0.44-1.86-0.71-2.8c-0.6,0.34-1.2,0.68-1.8,1.02c-0.14-0.25-0.27-0.5-0.41-0.76c0.58-0.29,1.15-0.58,1.73-0.87
		c0.69-0.39,1.38-0.78,2.07-1.18c0.39-0.22,0.78-0.45,1.18-0.67l0,0h0l0,0c0.81-0.43,1.63-0.86,2.44-1.29
		c-0.21-0.12-0.43-0.24-0.64-0.35c1.38-2.68,2.76-5.37,4.33-8.42c0.08,0.13,0.14,0.25,0.21,0.36c0.45,0.78,0.65,1.13,0.85,1.47
		c0.86-1.09,1.72-2.18,2.63-3.34c1.54,2.54-0.87,7.95-3.96,9.58c1.62,0.34,3.34,0.71,5.06,1.08
		C653.3,398.64,653.26,398.9,653.21,399.16z"
          />
          <path
            className="st15"
            d="M601.1,427.56c0-5.15,0-9.99,0-14.98c-19.79,8.96-38.8,18.79-55.48,32.28c0.51,3.9,1.02,7.84,1.56,11.96
		c4.6-1.92,9.37-3.97,14.2-5.9c2.4-0.96,5.04-2.31,6.14,1.55c3.67-1.41,6.95-2.92,10.39-3.94c8.43-2.5,17.5-3.45,23.92-10.72
		c0-2.62,0-5.57,0-9c-3.19,1.79-6.05,3.39-8.92,5c0.77,0.36,1.44,0.67,2.11,0.97c0.03,0.36,0.07,0.72,0.1,1.08
		c-3.57,0.99-7.14,1.99-10.71,2.98c-0.26-0.45-0.53-0.9-0.79-1.35c3.12-3.51,3.56-9,9.8-11.42c-1.32,2.95-2.29,5.1-3.56,7.94
		C594.11,431.57,597.71,429.5,601.1,427.56z"
          />
          <path
            className="st7"
            d="M533.94,273.28c1.89,1.82,3.81,3.62,5.69,5.45c1.69-1.21,3.38-2.42,5.07-3.63c-0.34-2.63-0.25-5.03,1.47-6.72
		c0.78-0.77,1.9-1.39,3.46-1.81c6.99,2.79,12.59,8.17,17.79,14.03c0.85,0.95,1.08,2.45,1.58,3.71c-0.13-1.34,0.18-2.15,0.82-2.61
		c0.65-0.47,1.65-0.58,2.85-0.53c1.34,0.05,2.7-0.4,4.96-0.78c-2.09-3.17-3.62-5.48-5.15-7.79c-5.26,3.5-11.76-0.09-14.97-8.22
		c2.22-3.03,5.27-3.16,8.65-2.24c0.73-0.95,1.46-1.89,2.19-2.84c-0.22-0.19-0.45-0.37-0.75-0.63c-0.3-0.25-0.69-0.57-1.23-1.03
		c4.04-1.42,7.51-2.64,11.53-4.05c-3.12-6.51-4.91-13.67-10.69-18.67c-1.97,1.1-3.97,2.13-5.92,3.25c-0.65,0.37-1.3,0.75-1.93,1.15
		c-9.6,5.97-19.15,12.01-28.71,18.05c-2.05,1.29-4.03,2.69-6.05,4.03c1.26,2.11,2.2,4.51,3.84,6.28
		C530.19,269.62,532.05,271.46,533.94,273.28z"
          />
          <path
            className="st3"
            d="M952.71,463.12c-2.91-4.01-6.48-7.75-6.11-13.35c0.15-2.26,0.92-3.36,3.38-3.53c4.94-0.34,8.32,2,10.96,5.79
		c1.4,2.01,2.62,4.15,3.97,6.3c1.02-7.51,2-14.69,2.98-21.87c-1.08-3.04-1.71-6.2-1.83-9.38c-0.08-2.29-0.51-3.95-2.95-4.51
		c0.23,3.92,1.63,7.93-0.67,11.69c-0.98,1.6-2.14,3.08-3.22,4.62c-0.57,0.21-1.15,0.42-1.72,0.63c-0.52-1.05-1.03-2.11-1.55-3.16
		c-0.03,0.59-0.21,1.23-0.19,1.8c0.01,0.19,0.03,0.37,0.1,0.54c1.18,3.25,0.34,4.93-4.14,6.25c-0.39-4.56-0.77-8.94-1.15-13.33
		c-0.43,0-0.87,0-1.3,0.01c-0.14,1.96-0.45,3.92-0.39,5.87c0.09,2.87-1.09,4.37-4.11,4.54c-1.51-2.41-2.22-4.52-0.59-7.41
		c1.05-1.87,1.76-5.07,0.86-6.7c-2.59-4.69-1.73-9.61-2.2-14.45c-5.25-2.64-10.5-5.28-16.71-8.41c5.02,17.29,5.05,34.27,5.53,51.22
		C938.32,459.63,943.8,466.61,952.71,463.12z"
          />
          <path
            className="st11"
            d="M647.53,634.91c9.82-4.83,19.61-9.73,29.42-14.59c-5.81-4.06-11.63-8.13-17.43-12.18
		c-16.59,13.51-36.75,20.09-54.48,31.49c5.25,3,10.39,5.93,15.53,8.87c0.45-0.37,0.85-0.84,1.36-1.09
		C630.46,643.22,639.01,639.1,647.53,634.91z"
          />
          <path
            className="st3"
            d="M867.67,214.36c7.17-1.11,13.44,1.33,19.42,4.88c3.33,0.99,6.66,1.98,10.88,3.23
		c-0.56,2.1-1.07,4.01-1.55,5.82c-0.48,1.81-0.94,3.53-1.4,5.25c-0.71-0.02-1.42-0.04-2.13-0.05c0.02,0.02,0.05,0.04,0.07,0.06
		c0,0,0,0,0,0c-0.48,0-0.97,0-1.45-0.01c-2.41-0.43-4.82-0.86-7.4-1.33c5.8,2.95,11.53,5.58,16.98,8.72
		c7.29,4.2,14.45,8.68,18.98,16.17h0c1.81,0,3.62,0,6.29,0c-2.1,1.67-3.41,2.7-4.71,3.73c0.19,0.73,0.39,1.45,0.58,2.18v0
		c0,0-0.01-0.01-0.01-0.01c-0.01-0.01-0.03-0.04-0.03-0.04c1.44,2.11,2.89,4.21,4.33,6.32c2.74-0.15,5.49-0.31,8.64-0.49
		c3.23-2.72,4.24-4.98,2.08-9.23c-3.18-6.24-6.88-12.25-10.74-18.11c-7.34-11.14-16.36-20.45-29.6-24.46
		c-4.66-1.41-9.41-2.62-14.21-3.41c-3.12-0.51-6.39-0.1-9.59-0.1c-0.09-0.61-0.17-1.22-0.26-1.83c1.9-0.58,3.82-1.68,5.7-1.63
		c0.9,0.02,1.79,0.09,2.69,0.18c2.64,0.26,5.27,0.76,7.9,1.11c-4.47-5.2-10.54-4.6-16.37-4.06
		C869.81,207.52,868.12,210.44,867.67,214.36z M895.96,236.22c-0.16-0.3-0.33-0.6-0.49-0.9c0.12-0.06,0.25-0.12,0.37-0.19
		c0.15,0.3,0.3,0.61,0.46,0.91C896.18,236.11,896.07,236.16,895.96,236.22z M909.36,232.14c5.21,2.42,9.57,5.81,13.41,9.79
		c1.28,1.33,2.5,2.72,3.67,4.16c0.39,0.48,0.78,0.97,1.16,1.46c1.14,1.48,2.25,3,3.32,4.57c0.23,0.37,0.46,0.74,0.69,1.11
		c-0.12,0.09-0.23,0.18-0.35,0.26c-0.29-0.27-0.57-0.53-0.86-0.8c0,0,0,0,0,0c-7.31-6.52-14.63-13.04-21.94-19.57
		C908.76,232.8,909.06,232.47,909.36,232.14z"
          />
          <path
            className="st3"
            d="M892.9,233.48L892.9,233.48c-0.01,0-0.02,0-0.03,0l0,0C892.88,233.48,892.89,233.48,892.9,233.48z"
          />
          <path
            className="st3"
            d="M892.9,233.48L892.9,233.48C892.9,233.48,892.9,233.48,892.9,233.48c-0.01,0-0.02,0-0.03,0
		C892.88,233.48,892.89,233.48,892.9,233.48z"
          />
          <path
            className="st3"
            d="M889.3,211.35c-0.02,0.03-0.04,0.06-0.04,0.06c0.06,0,0.12,0,0.18,0c-0.04-0.03-0.08-0.06-0.12-0.09l0,0
		c-0.05-0.01-0.1-0.01-0.15-0.02c0.03,0.04,0.07,0.07,0.1,0.11L889.3,211.35z"
          />
          <path
            className="st9"
            d="M1074.33,428.52c2.13,0.06,4.64-0.87,5.62,2.17c1.89-2.11,4.3-3.95,5.59-6.39c4.2-7.94,5.84-16.5,5.13-25.5
		c-0.35-4.41,0.11-8.97-0.84-13.23c-1.36-6.06-3.4-12.03-5.65-17.83c-5.39-13.87-15.73-22.83-29.25-28.85c0,1.52,0,2.69,0,3.86
		c6.48,1.45,6.92,10.48,0.09,16.4c0.29,1.16,0.6,2.42,0.93,3.73s0.66,2.66,1,4.02c0.54-1.57,0.99-3.18,1.63-4.71
		c2.88-6.88,7.79-7.95,12.92-2.65c4.64,4.8,7.42,10.71,9.16,17.11c2.97,10.93,4.29,21.84,0.48,32.93
		c-2.33,6.77-8.43,13.34-16.53,8.9c-0.28-0.16-0.71-0.06-1.06-0.08c-1.21,4.89-2.41,9.79-3.65,14.79c2.35,0.31,4.39,0.59,6.43,0.86
		C1068.75,431.86,1070.32,428.41,1074.33,428.52z"
          />
          <path
            className="st9"
            d="M895.32,346.05c1.35,0,2.7,0,4.05,0h0c1.9-3.55,4.26-6.93,5.62-10.68c4.15-11.4,5.08-23.24,3.61-35.26
		c-0.2-1.62-0.66-3.2-1.09-4.78c-0.14-0.53-0.29-1.06-0.42-1.59c-0.76-0.68-1.66-1.25-2.26-2.05c-4.11-5.52-8.27-7.1-15.13-2.83
		c0.19,0.38,0.37,0.73,0.53,1.08c0.83,1.7,1.33,2.99,1.47,4.13c0.08,0.68,0.04,1.31-0.15,1.94c-0.12,0.42-0.31,0.85-0.56,1.29
		c-0.63,1.11-1.66,2.33-3.13,3.94c0.37,0.3,0.71,0.61,1.02,0.94c0.62,0.65,1.12,1.36,1.51,2.1c0.19,0.37,0.36,0.75,0.5,1.14
		c1.71,4.65-0.3,10.35-3.7,13.78c0,4.53,0,9.05,0,13.7c3.86,1.04,4.44,4.03,4.34,7.4c1.49,1.48,2.98,2.95,4.48,4.43
		C895.79,345.18,895.56,345.61,895.32,346.05z"
          />
          <path
            className="st14"
            d="M643.58,728c-6.22-3.8-12.43-7.6-18.65-11.4c-0.9,1.21-1.66,2.56-2.72,3.6
		c-7.54,7.39-15.26,14.61-22.69,22.12c-3.31,3.35-6.09,7.22-9.11,10.86c2.4,1.4,4.8,2.8,7.2,4.21c1.29-0.84,2.58-1.68,4.12-2.68
		c0.5,0.54,0.94,1.02,1.35,1.46s0.79,0.85,1.16,1.26h0l0,0c2.69-1.42,5.46-2.7,8.05-4.27c4.75-2.87,9.49-5.77,14.22-8.68
		c4.73-2.91,9.46-5.82,14.19-8.72c0.05-0.04,0.09-0.08,0.14-0.12c0.19-0.17,0.39-0.33,0.58-0.5
		C642.14,732.75,642.86,730.37,643.58,728z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M301.34,554.74c28.24-19.09,57.6-35.43,86.72-51.03c0-4.21,0-7.69,0-11.61
		c-29.49,17.1-58.49,33.92-87.68,50.85C300.67,546.52,300.97,550.23,301.34,554.74z"
            />
          </Link>
          <path
            className="st5"
            d="M317.76,818.02c-0.78-1.01-1.34-1.73-2.41-3.11c0.64,2.59,1.14,4.35,1.52,6.14
		c1.44,6.84,4.04,12.94,9.41,17.84c6.26,5.71,16.92,5.31,22.41-1.16c0.72-0.85,2.19-1.39,3.35-1.46c1.76-0.11,3.61,0.68,5.34,0.46
		c5.45-0.72,9.95,0.99,13.84,4.69c1.41,1.35,2.89,2.63,4.53,4.13c-0.33-4.5-0.66-8.88-0.97-13.07c-2.08-1.7-3.84-3.55-5.95-4.8
		c-5.36-3.16-11.1-5.2-17.46-3.71c-3.28,0.77-5.41-1.09-5.16-4.56c0.17-2.42,0.25-4.91-0.14-7.28c-0.55-3.25-2.58-5.57-5.79-6.62
		c-3-0.99-5.07,0.61-6.72,2.81c-1.16,1.54-2.06,3.27-3.35,5.36c-1.2-1.07-2.18-1.95-2.96-2.65c-0.78,0.41-1.61,0.84-2.57,1.35
		c-0.89-2.62-1.64-4.84-2.67-7.87C319.73,809.53,323.69,815.26,317.76,818.02z M339.98,827.65c3.63,2.22,4.9,6.91,2.87,10.11
		c-0.58,0.91-2.14,1.98-3.02,1.8c-8.08-1.6-14.97-5.13-18.74-12.96c-0.62-1.29-0.61-2.88-0.87-4.19
		C328.29,820.34,334.15,824.08,339.98,827.65z"
          />
          <path
            className="st12"
            d="M1121.35,435.63c-1.27,0-2.54,0-4.25,0c0-2.25,0-4.64,0-7.03c-0.2-0.08-0.4-0.14-0.61-0.18
		c-0.98-0.67-1.96-1.35-2.94-2.02c-2.79-1.68-5.38-3.9-8.86-3.51c0.35,4.36,1.18,8.47,0.91,12.51c-0.67,10.04-1.77,20.05-2.84,30.06
		c-0.49,4.63-1.3,9.22-1.97,13.83c0.68,1.68,1.36,3.36,2.04,5.03c0.25,0.18,0.5,0.36,0.75,0.54c0.47,0.3,0.93,0.59,1.4,0.89
		c1.43-0.26,2.87-0.53,4.59-0.84c1.92,2.78,3.47,6.54,7.55,6.19c0.36,0.25,0.71,0.49,1.03,0.72c0.97,0.68,1.79,1.25,2.6,1.82l0,0
		c-0.3-2.17-0.77-4.34-1.06-6.51c-0.18-1.3-0.29-2.6-0.27-3.9c0.09-5.29,1.09-10.56,1.28-15.85
		C1121.06,456.79,1121.14,446.21,1121.35,435.63z M1114.52,433.41c-0.79,3.64-0.15,7.58-0.26,11.38c-0.05,1.81-0.4,3.61-1.24,5.4
		c-0.37-4.68-0.73-9.36-1.04-13.22c-1.31-1.2-2.98-2.04-2.95-2.83c0.08-2.22,0.85-4.41,1.53-7.44
		C1113.98,428.95,1115.22,430.21,1114.52,433.41z"
          />
          <path
            className="st8"
            d="M682.14,680.3c15.32-9.72,30.68-19.4,46.03-29.09c-4.39-2.77-8.78-5.54-12.89-8.13
		c-18.21,9.36-36.48,18.74-54.86,28.18c3.1,1.91,6.24,3.84,9.4,5.78c3.16,1.94,6.33,3.89,9.5,5.85
		C680.24,682.02,681.07,680.98,682.14,680.3z"
          />
          <path
            className="st5"
            d="M465.23,606.18c6.85,3.42,13.75,6.72,20.64,10.06c0,0,0,0,0,0c0.08-0.11,0.16-0.22,0.24-0.32
		c0.19-0.25,0.37-0.5,0.55-0.75c0.39-0.55,0.77-1.11,1.16-1.65c0.66-0.9,1.35-1.76,2.2-2.42c8.64-6.82,17.46-13.42,26.14-20.19
		c0.7-0.54,0.85-1.78,1.26-2.7c-2.1-1.32-4.22-2.6-6.32-3.91c-2.1-1.31-4.19-2.64-6.21-4.07c-2.79-1.97-4.87-1.63-7.5,0.34
		c-11.6,8.69-23.19,17.44-37.32,22.27C461.89,604.04,463.46,605.3,465.23,606.18z"
          />
          <path
            className="st3"
            d="M753.54,142.36c1.03,1.24,1.8,2.17,2.57,3.09c2.65-3.4,6.58-2.65,10.05-2.79c3.38-0.14,7.63-3.07,10,2.34
		c-0.62,1.44-1.29,3-1.92,4.47c12.41,1.21,25.08,0.28,35.87,7.89c-12.22-1.45-24.45-2.9-37.52-4.45c0.87,1.53,1.15,2.02,1.43,2.51
		c8.55,0.99,17.12,1.86,25.65,3.06c2.6,0.37,4.86,2.02,7.67,2.42c2.6,0.37,4.25,2.43,4.81,5.67c1.32,7.53,3.9,14.87,4.92,22.43
		c0.9,6.66,0.53,13.52,0.41,20.29c-0.06,3.52-0.74,7.03-1.21,10.53c-1,7.48-4.21,13.23-12.12,15.23c0,0.57,0,1.15,0,1.8
		c1.22-0.17,2.48-0.06,3.45-0.53c2.94-1.43,7.11-2.38,8.39-4.8c3.68-6.93,4.99-14.77,5.21-22.64c0.21-7.38,0.12-14.78,0.01-22.16
		c-0.06-3.67,0.69-7.99-0.96-10.85c-3.15-5.46-2.57-11.26-3.16-16.87c-0.94-8.98-7.83-16.25-17.33-17.14
		c-5.39-0.5-10.78-1.11-16.18-1.46c-7.76-0.5-15.54-0.81-23.3-1.2C758.19,140.16,756.12,141.14,753.54,142.36z"
          />
          <path
            className="st5"
            d="M724.66,489.69c4.78-4.17,9.56-8.34,13.24-11.55c15.39,0.65,29.67,1.31,43.96,1.78
		c1.2,0.04,3.09-0.9,3.56-1.91c1.56-3.33,3.81-6.63,2.38-11.22c-1.85-0.34-3.88-0.95-5.95-1.06c-6.64-0.35-13.29-0.51-19.93-0.79
		c-0.53-0.02-1.04-0.4-1.55-0.62c-1.5,0.22-3,0.6-4.51,0.62c-7.85,0.09-15.69,0.09-23.54,0.12l0,0h0c-2.1,2.33-4.2,4.67-6.3,7l0,0
		c-0.12,0.54-0.24,1.08-0.35,1.62c-0.34,1.62-0.67,3.25-1.13,4.84c-0.46,1.55-0.95,3.09-1.47,4.63c-0.34,1.02-0.69,2.05-1.03,3.07
		c-0.17,0.51-0.34,1.02-0.51,1.53C722.43,488.31,723.32,488.86,724.66,489.69z"
          />
          <path
            className="st15"
            d="M1020.22,387.87c1.72,10.13,4.39,19.58,10.88,27.61c1.91,2.36,4.57,4.1,6.88,6.13
		c0.2-0.27,0.41-0.53,0.61-0.8c-3.65-6.48-3.72-8.64-0.43-12.18c-3.21-9.13-2.82-11.78,2.31-15.3c-0.1-4.23,0.59-8.45-3.79-11.67
		c-1.53-1.12-1.66-4.56-1.86-6.99c-0.28-3.43,0.77-7.17-0.23-10.31c-1.27-4.01-1.64-7.72-0.58-11.68c-2.2-1.48-4.41-2.96-6.61-4.44
		C1022.05,360.9,1017.79,373.58,1020.22,387.87z"
          />
          <path
            className="st5"
            d="M767.82,395.06c2.23-0.79,4.47-1.58,6.7-2.37c-0.05-0.25-0.09-0.49-0.14-0.74c1.05,0.46,2.11,0.92,3.16,1.38
		c2.4-1.02,4.8-2.04,7.19-3.06c1.42-4.32,2.13-8.59,0.43-13.11c-1.32-3.52-1.97-7.29-3.14-10.87c-1.35-4.14-4.4-5.54-8.65-4.94
		c-2.6,0.37-5.32-0.08-7.99-0.16c-1.4-0.04-2.8-0.08-4.2-0.12l0,0h0c-1.73,2.12-3.46,4.24-5.18,6.36c0,0,0,0,0,0h0
		c-2.41,6.5-4.81,13-7.22,19.5c0.83,0.88,1.67,1.75,2.49,2.62s1.65,1.73,2.46,2.58c2.49-2.13,4.51-3.84,6.52-5.55
		c0.44,0.44,0.88,0.88,1.31,1.32c-1.6,2.1-3.21,4.2-5.13,6.71c3.6,0.35,5.8,4.42,9.7,2.18c0.45-0.81,0.9-1.62,1.35-2.43
		C767.6,394.59,767.71,394.82,767.82,395.06z M769.69,374.45c2.81,1.1,5.69,1.62,7.53,3.28c0.64,0.58-0.94,4.25-2.2,6
		c-0.56,0.78-3.17,0.91-4.3,0.31C766.9,381.99,768.4,378.72,769.69,374.45z"
          />
          <path
            className="st3"
            d="M326.27,668.13c-4.17-3.24-8.35-6.46-12.42-9.81c-4.17-3.43-8.12-7.14-12.17-10.72
		c-6.78,4.25-13.55,8.51-20.33,12.75c-1.28,0.8-2.6,1.54-3.9,2.31c11.09,6.94,22.19,13.89,33.36,20.88
		c3.32-2.06,6.61-4.09,9.88-6.12s6.51-4.03,9.76-6.04C329.05,670.29,327.66,669.21,326.27,668.13z"
          />
          <path
            className="st3"
            d="M932.42,400.02c5.09,2.22,10.08,4.41,15.48,6.77c-1.59-5.72-1.26-10.95,1.53-15.92
		c-0.7-0.78-1.42-1.57-1.92-2.12c0.35-2.26,1.3-4.23,0.71-5.46c-1.41-2.93-0.99-5.13,1.43-7.09c-7.82-11.47-9.95-24.29-10.51-37.56
		c-2.38,10.36-4.63,20.68-6.77,31.02c-0.87,4.22-3.26,5.55-6.93,3.42c-1.82-1.06-3.41-2.53-5.48-4.1c0.66,3.36,0.52,6.64,1.96,8.93
		c1.76,2.81,2.42,4.98,0.24,7.47C926.15,390.13,931.37,393.73,932.42,400.02z"
          />
          <path
            className="st5"
            d="M106.14,540.95c-2.15,12.01-4.34,24.01-6.42,36.03c-0.44,2.53-0.45,5.14-0.66,7.72
		c4.8-2.87,9.6-5.74,14.42-8.62c4.82-2.88,9.67-5.78,14.57-8.71c-2.46-11.76-4.81-22.95-7.29-34.78
		C115.69,535.49,110.92,538.22,106.14,540.95z"
          />
          <path
            className="st3"
            d="M82.42,530.19c-1.85,1-3.39,1.84-4.94,2.68c-6.09,3.32-11.94,7.27-18.34,9.77c-5.8,2.27-10.42,5.94-14.75,9.93
		c-2.01,1.85-4.63,4.57-2.89,8.68c1.46,3.45,1.76,7.39,2.66,11.49c13.86-8.3,27.42-16.42,41.02-24.56
		C84.26,542.13,83.36,536.28,82.42,530.19z"
          />
          <path
            className="st5"
            d="M250.21,553.97c0.01,1.03,0.01,2.05-0.04,3.08c-0.09,1.51-0.71,3.02-0.67,4.52
		c0.4,13.03,0.93,26.05,1.29,39.08c0.01,0.42-0.11,0.85-0.3,1.28c-0.31,0.71-0.79,1.43-1.09,2.14c0.02,9.76,0.03,19.53,0.05,29.29
		c0.02,0.18,0.02,0.37,0.03,0.55c0.01,0.18,0.04,0.36,0.12,0.5c5.34,9.21,4.22,18.32-0.37,27.4c-0.33,0.65-0.39,1.44-0.79,2.98
		c2.93-1.74,5.13-3.05,7.47-4.45c0-5.82,0.08-11.58-0.02-17.34c-0.18-10.83-0.6-21.65-0.65-32.48c-0.03-7.01,0.63-14.02,0.61-21.04
		c-0.02-8.81-0.52-17.61-0.64-26.42c-0.12-9.25,0.16-18.51-0.09-27.76c-0.2-7.34-0.89-14.67-1.42-22
		c-0.91-12.51-1.86-25.01-2.84-37.52c-0.09-1.2-0.5-2.38-0.75-3.56c0.02,26.22,0.03,52.44,0.05,78.65
		C250.16,551.92,250.19,552.94,250.21,553.97z"
          />
          <path
            className="st4"
            d="M69.57,718.28c1.7-3.43,2.89-7.56,8.54-8.43c-2.26,4.12-4.28,7.38-5.88,10.84c-2.58,5.55-1.43,7.88,4.43,9.88
		c0.59-4.91,1.57-9.66,5.6-13.2c0.1,1.4,0.07,2.81-0.49,3.97c-3.99,8.24,0.5,16.36,8.56,19.58c5.02-0.03,10.04-0.09,15.06-0.05
		c0.74,0.01,1.47,0.45,2.21,0.69l0,0c2.08,0.3,4.16,0.6,6.24,0.9c0.03,0.39,0.07,0.79,0.1,1.18c1.15-0.98,2.76-1.74,3.34-2.98
		c1.12-2.4,2.79-2.79,5.12-2.78c1.27,0.01,2.8-0.48,3.77-1.28c4.42-3.65,8.5-7.73,13.06-11.19c5.84-4.43,12.74-7.26,17.98-12.77
		c0.67-0.7,1.26-1.4,1.78-2.1c1.53-2.08,2.33-4.24,2.29-6.97c-1.12-0.13-2.25-0.25-3.52-0.39c0.39-3.75-0.65-6.27-4.21-7.38
		c1.46,5.25,0.13,9.71-2.98,13.92c-4.81,6.5-11,11.34-17.41,16.16c-10.01,7.53-20.64,12.11-33.4,10.66
		c-5.25-0.6-9.28-2.55-11.74-7.64c-2.1-4.34-0.15-7.83,1.45-11.54c-8.18-0.84-13.08-6.61-11.58-13.03
		c-5.61,2.97-11.42,6.05-17.2,9.11c7.05,5.16,8.05,11.85,2.8,18.43c2.46,1.1,4.95,2.22,7.44,3.34c0.09-0.8,0.17-1.6,0.26-2.4
		C66.39,727.81,66.04,725.42,69.57,718.28z"
          />
          <path
            className="st4"
            d="M161.27,703.58c0-0.02-0.01-0.05-0.01-0.07l0,0c0,0.02,0,0.05,0,0.07c0,0,0,0,0,0
		C161.26,703.58,161.27,703.58,161.27,703.58z"
          />
          <path
            className="st5"
            d="M816.25,219.81c0.47-3.5,1.15-7.01,1.21-10.53c0.11-6.77,0.48-13.62-0.41-20.29
		c-1.02-7.56-3.6-14.89-4.92-22.43c-0.57-3.24-2.22-5.3-4.81-5.67c-2.8-0.4-5.06-2.06-7.67-2.42c-8.52-1.2-17.09-2.07-25.65-3.06
		c0,1.36,0,2.72,0,4.37c2.77,0,5.09,0,7.58,0c0,1.04,0,1.78,0,2.39c-1.98,0.41-3.74,0.78-5.51,1.15c7.38,0.76,14.77,1.52,22.64,2.33
		c0.28,0.96,0.63,2.13,0.97,3.3c6.68,1.94,8.14,7.7,9.31,13.34c2.12,10.23,1.22,20.43-1.43,30.45c-0.84,3.17-2.12,6.27-3.56,9.22
		c-1.71,3.49-4.85,4.96-8.68,5.13c-4.98,0.22-9.96,0.54-14.94,0.82v0l0,0c0,0.88,0,1.76,0,2.64c0.44-0.13,0.88-0.29,1.32-0.43
		c0.44-0.14,0.89-0.27,1.34-0.32c5.28-0.6,10.62-1.9,15.83-1.5c5.76,0.45,9.25-1.67,9.52-7.46c0.12-2.59,0.78-5.16,1.19-7.74
		c0.48,0.06,0.96,0.11,1.44,0.17c-0.32,4.14-0.08,8.43-1.17,12.35c-0.69,2.48-3.4,4.39-5.71,7.17c0,0.1,0,1.17,0,2.23c0,0,0,0,0,0
		C812.05,233.05,815.25,227.29,816.25,219.81z"
          />
          <path
            className="st12"
            d="M1036.73,305.08c-0.46,0.08-0.94,0.11-1.41,0.12c-0.48,0.01-0.95,0.02-1.43,0.04
		c0.76,0.39,1.52,0.77,2.32,1.18c1.33,0.68,2.77,1.41,4.49,2.29c-6.09-0.45-11.38-0.85-16.67-1.24c0,0,0,0,0,0c0,0,0,0,0,0
		c-3.61,1.64-7.23,3.28-10.84,4.92c0.34,1.39,0.69,2.77,1.04,4.2s0.72,2.89,1.1,4.44c1.58-0.17,3.46-0.21,5.26-0.61
		c7.5-1.67,14.96-4.16,22.74-2.93c3.6,0.57,7.11,1.68,10.63,2.74c1.17,0.35,2.35,0.7,3.52,1.02v0c0.76,0.45,1.52,0.91,2.28,1.36
		c-0.12,0.22-0.24,0.44-0.36,0.66c-0.84-0.51-1.67-1.01-2.51-1.52c-2.96-0.54-5.93-1.08-8.89-1.62c-0.17,0.41-0.34,0.81-0.51,1.22
		c9.66,0.37,14.91,8.5,22.82,12.22c0.23-3.99,0.43-7.7,0.67-11.85c-2.89-3.1-6.11-6.55-9.74-10.44c5.16,2.02,8.99,4.96,12.27,9.17
		c1.12-0.39,2.35-0.82,4.16-1.45c-2.59-3.22-4.83-6.01-7.08-8.8c-4.22-1.45-8.51-2.72-12.65-4.38
		C1050.94,303,1043.86,303.86,1036.73,305.08z"
          />
          <path
            className="st3"
            d="M842.72,186.55c2.49-0.55,4.36,0.04,6.05,1.82c1.48,1.55,2.88,3.24,4.58,4.51c5.08,3.79,10.01,8.35,17.25,5.21
		c0-5.17,0-10.16,0-15.45c-13.01-7.73-26.1-15.5-39.33-23.36c0,2.07,0.09,4.1-0.03,6.11c-0.12,2.11-0.58,4.21-0.66,6.32
		c-0.11,3.01-0.72,6.26,0.17,8.99C832.16,184.98,838.08,187.57,842.72,186.55z"
          />
          <path
            className="st12"
            d="M404.8,576.94c-4.99,3.98-11.56,4.86-14.1,10.87c4.64,2.34,9.1,4.59,13.64,6.87
		c0.43-0.49,0.88-1.01,0.94-1.07c1.06,1.19,1.82,2.47,2.94,3.22c4.73,3.2,9.6,6.19,14.34,9.37c3.92,2.63,7.74,5.43,10.82,7.61
		c4.96-4.39,9.4-8.33,13.62-12.08c-7.22-4.27-14.24-8.45-21.27-12.59C418.73,585.04,411.72,580.98,404.8,576.94z"
          />
          <path
            className="st3"
            d="M1115.34,367.76c1.27,3.93,3.04,7.89,0.92,12.09c0.15,0.12,0.31,0.23,0.45,0.35c0.45,0.34,0.87,0.67,1.18,0.9
		c1.39-2.31,2.65-4.4,3.86-6.43c2.05,0.48,3.81,0.9,5.81,1.36c-0.37-10.25-1.21-20.21-0.9-30.14c0.17-5.52-1.63-8.69-6.23-11.26
		c-12.64-7.06-25.16-14.35-37.71-21.58c-3.69-2.13-7.32-4.37-10.98-6.56c1.45,3.03,3.17,5.62,5.01,8.13
		c2.85,3.88,5.92,6.98,11.57,6.12c2.86-0.43,5.3,1.22,6.07,4.68c0.28,1.28,1.68,2.87,2.91,3.28c8.99,3,17.34,14.03,16.94,24.43
		c-0.17,4.54-0.85,9.06-1.29,13.59C1113.78,367.08,1115.17,367.23,1115.34,367.76z"
          />
          <path
            className="st7"
            d="M621.21,496.88c9.72-6.1,19.39-12.28,29.04-18.48c0.76-0.49,1.28-1.36,1.92-2.05
		c-1.95-4.5-4.33-8.94-2.04-13.25c-6.07,0.81-12.41,1.65-18.74,2.49c-0.85,1.04-1.5,2.39-2.57,3.06c-2.85,1.79-5.88,3.29-8.83,4.9
		c-0.79,1.73-1.39,3.63-2.11,5.43c-0.36,0.9-0.75,1.77-1.2,2.58c-0.45,0.81-0.97,1.57-1.58,2.22c-3.64,3.9-4.42,7.74-3.1,12.54
		c0.46,1.67,0.58,3.44,0.86,5.16c0.7-0.38,1.4-0.76,2.11-1.13C617.07,499.25,619.19,498.15,621.21,496.88z"
          />
          <path
            className="st10"
            d="M522.49,271.51c0.69,0.92,1.34,1.88,2.07,2.76c2.25,2.7,2.9,6.99,7.52,7.4c0.34,0.03,0.65,0.36,0.95,0.77
		s0.6,0.9,0.9,1.25c3.83,3.39,7.7,6.73,11.51,10.15c1.27,1.14,2.53,2.28,3.78,3.44c3.59,3.33,8.42,3.28,12.56,5.06
		c3.15,1.35,6.27,2.75,9.41,4.12c0.46,0,0.92,0,1.38,0c0.25,0.02,0.5,0.03,0.75,0.05c11.01-0.2,22.01-0.29,33.01-0.63
		c10-0.32,20-0.91,30-1.39c2.19-0.31,4.39-0.61,6.58-0.92c0-0.2,0-0.4,0-0.6c-2.92-0.22-5.85-0.61-8.77-0.62
		c-11.21-0.07-22.49,0.74-33.61-0.26c-10.29-0.92-20.54-3.22-30.55-5.9c-6.67-1.79-13.14-4.84-19.21-8.21
		c-4.15-2.3-7.46-6.12-11.15-9.26c-1.88-1.83-3.8-3.63-5.69-5.45s-3.75-3.66-5.53-5.59c-1.63-1.77-2.58-4.17-3.84-6.28
		c-1.9,0.98-3.81,1.96-5.71,2.94l0,0h0C520.07,266.74,521.28,269.13,522.49,271.51z"
          />
          <path
            className="st3"
            d="M1034.12,296.44c-5.1,0.28-10.28-0.8-15.42-1.31c-0.38-0.04-0.75-0.25-1.12-0.38
		c0.02-0.29,0.05-0.59,0.07-0.88c4.58,0,9.16,0,13.73,0c0.03-0.51,0.06-1.03,0.08-1.54c-5.83-0.71-11.65-1.6-17.51-2.04
		c-3.02-0.23-6.1,0.36-9.16,0.54c-1.89,0.11-4.02-0.36-5.62,0.36c-2.85,1.29-5.81,2.88-7.94,5.1c-2.18,2.28-4.86,4.8-4.34,8.74
		c5.15-2.13,9.48-2.68,13.3-1.88c1.37,0.29,2.68,0.76,3.94,1.38c1.68,0.82,3.27,1.92,4.81,3.29c0.09,0.08,0.17,0.15,0.26,0.23
		c1.36,1.24,2.68,2.68,3.99,4.31c3.61-1.64,7.23-3.28,10.84-4.92c0,0,0,0,0,0c-3.5-0.39-7.01-0.68-10.49-1.13
		c-1.16-0.15-2.32-0.32-3.47-0.51c-1.46-0.25-3.08-1.39-3.97-2.6c-0.73-0.99-1.05-2.98-0.52-3.99c0.56-1.07,2.34-1.51,3.78-1.88
		c-2.67,4.61-1.81,6.65,3.62,7.07c6.95,0.54,13.94,0.58,20.92,0.83c0.47-0.02,0.95-0.03,1.43-0.04c0.48-0.01,0.95-0.04,1.41-0.12
		c7.13-1.22,14.21-2.07,21.24,0.74c4.14,1.66,8.43,2.93,12.65,4.38c-4.88-6.25-11.07-10.17-19-11.52
		C1045.81,297.68,1040.2,296.1,1034.12,296.44z"
          />
          <path
            className="st3"
            d="M437.5,559c-1.16-3.42-2.35-6.83-3.14-10.33c-3.73-16.58-7.16-33.22-10.81-49.82
		c-1.12-5.08-2.61-10.07-3.84-15.13c-0.3-1.23-0.23-2.55-0.33-3.83c-0.26-0.72-0.53-1.44-0.79-2.16c-0.47-0.7-0.93-1.4-1.4-2.1
		c0.21,11.52,0.43,23.03,0.64,34.55c0.01,0.78-0.02,1.56-0.03,2.33c-0.01,0.78,0,1.55,0.09,2.32c1.15,10.27,2.16,20.56,3.63,30.79
		c0.76,5.28,2.1,10.52,3.73,15.61c0.53,1.64,2.89,3.9,4.3,3.82c1.54-0.09,3.05-0.5,4.57-1.01c1.51-0.51,3.02-1.12,4.53-1.6
		C438.27,561.28,437.89,560.14,437.5,559z"
          />
          <path
            className="st3"
            d="M298.83,416.2c-0.23,0.36-0.47,0.73-0.7,1.09c0.12,0.1,0.24,0.19,0.37,0.29c0.28-0.26,0.56-0.53,0.84-0.79
		c3.64-2.83,7.28-5.67,10.69-8.32c-0.71-0.68-1.8-1.73-2.88-2.78c6,0.78,9.5-1.96,12.56-5.87c0.66-0.84,1.77-1.34,2.67-2
		c0.38,0.4,0.76,0.8,1.14,1.19c-2.6,3.22-5.19,6.45-7.63,9.47c1.56,2.06,3.27,4.33,4.86,6.43c4.41-0.81,6.55-3.23,7.44-6.97
		c-2.44-0.68-5.88,0.07-5.49-4.39c4.64,2.07,8.38,1.7,10.97-2.95c0.22-0.4,1.47-0.22,2.24-0.31c0.12,0.3,0.24,0.59,0.36,0.89
		c-1.57,1.88-3.14,3.76-4.59,5.48c3.56,3.05,7.36,2.63,10.93-0.15c2.95-2.3,2.43-5.52,1.5-8.71c3.11-0.87,6.48-1.09,8.93-2.71
		c2.39-1.58,3.83-4.58,6.15-7.56c-5.4-1.59-8.58,1.35-12.69,2.86c1.04-3.74,1.94-6.95,2.99-10.72c-3.06,2.15-5.41,3.8-8.5,5.98
		c-1.14-2.27-2.07-3.9-2.81-5.62c-1.38-3.23-3.06-3.67-6.23-1.38c2.33,4.8,3.18,9.68-1.47,13.62c-1.7,1.44-4.43,3.21-6.13,2.75
		c-2.75-0.73-3.34-3.6-1.93-6.45c0.45-0.92,0.54-2.02,0.74-3.05c0.03-0.17-0.16-0.39-0.27-0.65c-1.01,0-2.04,0-3.52,0
		c-0.62,3.25-1.89,6.47-1.7,9.61c0.3,5.11-5.79,11.23-12.13,9.35c-1.78-0.53-3.53-1.24-5.34-1.55c-2.65-0.45-5.43-1.17-8.76,1.26
		c4.19,1.74,7.63,3.17,11.09,4.6C301.24,410.96,300.04,413.58,298.83,416.2z M336.83,394.63c3.76-0.18,4.94-3.51,6.29-6.69
		c1.11,4.94-1.54,9.2-5.13,9.64c-2.8,0.34-4.28-1.14-4.82-4.37C334.6,393.76,335.62,394.16,336.83,394.63z"
          />
          <path
            className="st5"
            d="M114.46,611.05c3.36-2.16,6.72-4.31,9.97-6.61c1.35-0.96,3.02-2.65,3.06-4.05c0.27-9.36,0.14-18.74,0.14-28.29
		c-10.22,6.76-20.11,13.3-29.97,19.82c0.95,4.29,1.88,8.54,2.82,12.8c3.54,2.83,7.09,5.67,10.63,8.5
		C112.22,612.49,113.34,611.77,114.46,611.05z"
          />
          <path
            className="st9"
            d="M628.81,468.66c1.08-0.68,1.73-2.02,2.57-3.06c-4.4-3.01-4.88-5.12-1.43-7.33c1.27-0.81,3.09-1.2,4.62-1.11
		c7.08,0.43,13.4-1.43,18.76-6.05c2.72-2.35,5.11-5.09,7.65-7.65c-1.97-0.55-3.43-0.14-4.9-0.14c-2.01,0-4.51,0.6-4.89-2.53
		c-1.71,0.29-2.2-0.32-2-1.15c0.09-0.39,0.34-0.82,0.68-1.24c0.97-1.19,2.47-2.37,3.92-2.69c4.3-0.94,8.7-1.45,13.34-2.18
		c0.5-3.72,1.06-7.82,1.65-12.16c-16.88,10.69-33.18,21-49.11,31.08c0.32,4.8,0.75,9.46,0.89,14.13c0.05,1.74-0.18,3.48-0.39,5.23
		c-0.07,0.58-0.14,1.16-0.19,1.75C622.94,471.95,625.97,470.45,628.81,468.66z"
          />
          <path
            className="st9"
            d="M651.18,440.66c0,0.05,0.01,0.09,0.02,0.14c0.04-0.01,0.07-0.01,0.11-0.02
		C651.3,440.78,651.18,440.66,651.18,440.66z"
          />
          <path
            className="st5"
            d="M315.98,810.19c0.3-2.89,0.58-5.57,0.86-8.26c-0.34-0.12-0.68-0.25-1.02-0.37c-1.33,1.53-2.65,3.06-3.98,4.59
		c-0.34-0.09-0.69-0.18-1.03-0.27c-0.15-1.69-0.07-3.43-0.49-5.05c-1.1-4.29-2.08-8.67-3.84-12.69c-0.63-1.44-3.39-2.71-5.14-2.67
		c-1.53,0.04-3.09,1.94-4.47,3.19c-0.61,0.56-0.82,1.57-1.62,3.21c-1.46-1.63-3-2.59-3.4-3.9c-2.94-9.6-9.43-16.62-16.63-23.06
		c-1.81-1.61-4.37-2.38-6.95-3.72c0,3.04,0,5.27,0,7.87c5.27,0.65,7.75,4.08,10.08,7.99c5.23,8.78,7.05,18.69,9.69,28.31
		c1.52,5.54,4.76,9.76,8.96,13.36c4.15,3.56,7.55,3.12,10.85-1.15c1.01-1.31,2-2.65,2.93-3.87c-0.46-1-0.87-1.91-1.61-3.52
		C311.8,810.19,313.78,810.19,315.98,810.19z M306.9,815.07c-0.79,1.02-4.2,0.8-6.02,0.08c-6.57-2.62-9.74-8.46-13.41-15.37
		c2.47-0.09,4.25-0.56,5.8-0.15c5.61,1.51,11.11,3.64,13.98,9.09C308.14,810.42,308,813.65,306.9,815.07z"
          />
          <path
            className="st3"
            d="M240.69,782.28c8.07,5.29,16.15,10.58,24.22,15.86c-0.61-8.59-1.46-17.18-1.78-25.78
		c-0.32-8.57-0.13-17.17-0.16-25.75c-4-2.62-7.99-5.24-11.99-7.86c-0.15,6.16-0.3,12.32-0.45,18.42c-0.15,6.1-0.3,12.15-0.44,18.1
		c-1.66,1.24-3.25,2.42-4.8,3.58C243.73,780,242.21,781.14,240.69,782.28L240.69,782.28z"
          />
          <path
            className="st3"
            d="M88.95,546.02c12.31-6.88,24.52-13.7,36.98-20.66c-1.08-5.53-2.27-11.7-3.55-18.25c-2.23,1.37-4.07,2.6-6,3.68
		c-8.58,4.8-17.22,9.49-25.73,14.41c-1.14,0.66-2.27,2.44-2.3,3.72c-0.08,4.11,0.39,8.23,0.58,12.36
		C89.02,543.1,88.95,544.92,88.95,546.02z"
          />
          <path
            className="st7"
            d="M593.55,239.86c3.58-0.22,7.07-0.43,10.57-0.64c0.58-0.72,1.09-1.52,1.69-2.22c0.2-0.23,0.41-0.45,0.64-0.65
		c4.24-3.67,8.56-7.25,12.86-10.86c-1.09-1.49-2.18-2.99-3.73-5.09c-0.22-0.3-0.45-0.61-0.69-0.94c-3.49,4.82-6.35,8.78-9.5,13.14
		c-0.66-0.64-1.47-1.44-2.8-2.74c2.36-3.92,4.27-7.72,6.77-11.07c3.02-4.05,5.77-4.12,9.59-0.81c1.16,1,2.11,2.25,3.15,3.38
		c1.88-1.43,3.76-2.86,5.64-4.29c0,0,0,0,0,0c-2.25-2.77-4.64-5.37-7.42-7.49c-1.67-1.28-3.48-2.38-5.48-3.26
		c-1.04,0.46-2.16,0.81-3.12,1.4c-6.96,4.3-13.89,8.64-20.82,12.99c-3,1.88-5.98,3.81-8.97,5.72c2.73,4.64,5.45,9.27,8.03,13.66
		C591.16,240,592.36,239.93,593.55,239.86z"
          />
          <path
            className="st12"
            d="M375.14,603.52c-0.6-0.51-1.94-1.67-2.93-2.51c-8.06,4.05-15.6,7.84-22.55,11.33
		c3.77,3.52,7.37,6.88,10.73,10.02c5.67-4.19,11.28-8.34,16.89-12.48c0.6-0.35,1.2-0.69,1.81-1.04c0.14,0.25,0.28,0.51,0.41,0.76
		c-0.58,0.29-1.16,0.59-1.74,0.88c-4.78,4.14-9.57,8.28-14.04,12.15c4.74,2.65,9.39,5.25,13.45,7.52
		c7.15-4.74,14.48-9.6,22.01-14.59c-6.09-3.21-10.95-9.53-19.36-7.63c0.75-0.59,1.5-1.18,2.79-2.19
		C380.01,604.96,377.95,604.35,375.14,603.52z"
          />
          <path
            className="st4"
            d="M748.84,338.98c-0.72,2.37-1.45,4.73-2.22,7.25c-0.5-0.1-0.97-0.19-1.41-0.28c-1.33-0.26-2.41-0.47-3.5-0.68
		c-0.49,1.71-0.98,3.43-1.47,5.14c1.18,0.9,2.36,1.79,3.55,2.69c3.56,2.7,7.15,5.43,10.91,8.28c2.58-3.59,5.27-8.04,8.64-11.88
		c3.94-4.48,9.32-7.15,15.24-5.57c2.92,0.78,5.66,2.2,8.39,3.69c1.64,0.89,3.27,1.81,4.92,2.62c0,0,0,0,0,0
		c-0.52-0.73-1-1.49-1.51-2.23c-0.5-0.74-1.03-1.46-1.63-2.11c-1.44-1.56-3.13-2.88-4.71-4.3c0,0.01,0.01,0.02,0.01,0.03
		c-1.45-0.44-2.9-0.88-4.34-1.31c-0.76-0.72-1.52-1.45-2.28-2.17c-0.65,0-1.29-0.01-1.94-0.01l0,0c0,0,0,0,0,0
		c-0.13,0.17-0.25,0.33-0.42,0.55c-0.28,0.37-0.67,0.89-1.36,1.81c-0.72-2.49-1.51-4.13-1.57-5.79c-0.01-0.42,0.04-0.93,0.22-1.37
		c0.12-0.3,0.3-0.56,0.55-0.75c0.38-0.28,0.93-0.39,1.71-0.15c0.76,0.28,1.52,0.55,2.29,0.83c0.41,0,0.83,0,1.24,0.01
		c1.96-0.84,3.85-1.12,5.67-0.88s3.57,1.03,5.25,2.3c0.41,0.17,0.82,0.35,1.23,0.52c2.32,1.25,4.64,2.51,6.96,3.76
		c-0.52-1.2-1.09-2.34-1.72-3.43c-0.11-0.19-0.23-0.38-0.35-0.57c-0.82-1.33-1.73-2.56-2.75-3.64c-0.28-0.3-0.58-0.59-0.88-0.87
		c-0.91-0.84-1.89-1.58-2.96-2.21c-1.78-1.04-3.81-1.76-6.1-2.08c-7.24-0.99-13.75,1.19-19.28,6.55c-2.16,2.09-4.45,4.26-7.15,5.5
		c-0.6,0.28-1.22,0.51-1.87,0.68c-0.32,0.09-0.65,0.16-0.99,0.22c-0.67,0.12-1.37,0.17-2.09,0.15
		C750.38,339.25,749.63,339.16,748.84,338.98z"
          />
          <path
            className="st16"
            d="M719.44,641.12c3.34,1.95,6.68,3.91,10.02,5.86c15.04-7.99,30.09-15.97,45.11-24
		c1.32-0.71,2.48-1.73,3.71-2.6c-0.39-0.23-0.79-0.45-1.19-0.66c-0.8-0.44-1.61-0.86-2.41-1.29c-2.01-1.07-4.01-2.17-5.78-3.55
		c-3.36-2.62-6.3-2.26-9.58-0.21c-10.41,6.52-20.85,12.99-31.28,19.48c-0.22,0.19-0.45,0.37-0.67,0.56l0,0
		c0.22-0.19,0.45-0.37,0.67-0.56c-0.22,0.19-0.45,0.37-0.67,0.56c0,0,0,0,0.01,0c0,0,0,0-0.01,0c-0.02,0.01-0.04,0.03-0.05,0.04
		c0.02-0.01,0.04-0.03,0.05-0.04l0,0c-0.79,0.34-1.57,0.68-2.36,1.02c-0.9,0.39-1.79,0.77-2.69,1.16
		C721.35,638.3,720.39,639.71,719.44,641.12z"
          />
          <path
            className="st4"
            d="M426.15,854.81c-2.86-9.14-15.31-13.95-22.86-12.83c-1.53,0.23-3.12,0.03-4.68,0.03
		c-6.43,3.31-11.17,8.84-15.34,17.37c1.09,0.6,2.76,1.32,4.23,2.33c9.57,6.59,20,8.06,31.16,5.38
		C424.74,865.62,427.88,860.35,426.15,854.81z M389.84,860.83c-2.28-2.95-2.34-5.49-0.43-8.19
		C391.72,854.19,391.92,857.06,389.84,860.83z M412.51,861.9c0.06,0.67,0.12,1.24,0.25,2.67c-1.75-0.83-3.1-1.48-4.66-2.22
		c-0.1,0.38-0.33,1.29-0.75,2.87c-0.98-1.15-1.91-1.93-2.45-2.92c-1.43-2.63,0.28-7.62,3.11-9.45c3.35-2.17,9.14-0.77,12.72,3.16
		C419.06,859.29,417.31,862.45,412.51,861.9z"
          />
          <path
            className="st3"
            d="M198.04,800.76c-0.84-9.53-1.72-19.51-2.62-29.76c-6.41-4-10.8-2.21-15.43,3.26
		c-6.86,8.1-8.07,17.86-7.03,27.32c5.92,0.29,11.53,0.66,17.15,0.8C192.68,802.43,195.68,804.68,198.04,800.76z"
          />
          <path
            className="st12"
            d="M431.49,501.37c-3.85-7.25-8.12-14.31-12.12-21.49c0,0,0,0,0,0c0,0,0,0.01,0,0.01
		c0.01,0.04,0.03,0.08,0.04,0.11c-0.01-0.04-0.03-0.08-0.04-0.11c0.1,1.28,0.04,2.6,0.33,3.83c1.23,5.06,2.72,10.05,3.84,15.13
		c3.65,16.6,7.08,33.24,10.81,49.82c0.79,3.5,1.98,6.91,3.14,10.33c0.39,1.14,0.77,2.28,1.13,3.43v0c4.2-1.54,7.52-4.04,9.25-8.09
		c-0.52-1.44-1.05-2.61-1.37-3.83c-1.94-7.5-3.77-15.04-5.76-22.53c-1.72-6.47-2.77-13.29-5.58-19.27
		C434.01,506.23,432.77,503.79,431.49,501.37z"
          />
          <path
            className="st16"
            d="M536.85,532.94c16.05-2.22,31.37-6.93,45.99-13.96c6.3-3.03,12.68-5.9,19.02-8.85
		c0.16-3.62,0.32-7.24,0.5-11.23c-1.74-0.1-3.24-0.18-4.74-0.26c0,0,0.01-0.01,0.01-0.01c-0.48,0.24-0.96,0.48-1.44,0.72
		c0,0,0,0,0,0c0,0,0.06-0.04,0.06-0.04c-3.48,1.93-6.97,3.86-10.45,5.79c-0.6,0.48-1.17,1.01-1.76,1.51
		c-0.59,0.5-1.2,0.96-1.87,1.31c-2.23,1.14-4.6,2.71-6.93,2.75c-3.76,0.06-6.59,1.2-8.96,3.98c-2.89,3.39-6.51,5.63-11.04,5.53
		c-3.65-0.08-6.99,1.23-9.28,3.43c-4.54,4.37-9.8,6.95-15.64,8.72C532.5,532.54,534.75,533.23,536.85,532.94z"
          />
          <path
            className="st12"
            d="M422.79,740.02c5.79,4.04,11.59,8.08,17.38,12.12c5.79,4.04,11.59,8.07,17.4,12.09
		c2.2,1.52,4.48,1.44,5.69-1.21c1.31-2.88,2.45-5.91,3.13-8.99c0.26-1.18-0.59-3.13-1.58-3.95c-2.82-2.34-5.97-4.29-8.99-6.39
		c-6.22-4.32-12.44-8.64-18.65-12.96C432.37,733.83,427.58,736.92,422.79,740.02L422.79,740.02z"
          />
          <path
            className="st14"
            d="M750.71,227.59c-3.17-0.3-6.35-0.57-9.37-1.41c-2.09-0.58-4.28-2.6-5.39-4.56c-5.18-9.19-8-19.09-7.09-29.75
		c0.29-3.46,1.17-6.87,1.79-10.38c-0.92-0.37-2.06-0.82-3.19-1.27c0.12-0.48,0.23-0.91,0.34-1.32c0.11-0.41,0.21-0.8,0.31-1.19
		c-0.34-0.73-0.69-1.45-1.04-2.19c-0.35-0.74-0.71-1.5-1.08-2.28c4.23-1.18,8.18-2.29,12.13-3.39c-0.25-0.94-0.49-1.88-0.74-2.82
		l0,0l0,0v0c-0.82,0.31-1.63,0.63-2.46,0.95c-2.47,0.95-5.02,1.93-7.84,3.02c-0.3-0.39-0.87-1.11-2.26-2.9
		c9.97-2.7,19.03-5.15,28.05-7.6c-0.09-0.45-0.18-0.93-0.27-1.43c-0.1-0.5-0.19-1.01-0.29-1.53h0c-10.93,3.6-21.86,7.2-32.79,10.8
		l0,0c-0.44,3.39-0.87,6.78-1.31,10.17c1.14,8.1,2.27,16.2,3.41,24.29c0.59,4,1.47,7.91,2.58,11.75c1.84,6.4,4.3,12.6,7.06,18.69
		c0.7,1.55,2.99,2.39,4.54,3.56c0.41-0.39,0.83-0.78,1.24-1.17c-1.56-2.74-3.17-5.45-4.66-8.22c-1.94-3.6-3.77-7.26-5.65-10.89
		c0.36-0.18,0.71-0.37,1.07-0.55c1.29,2.07,2.64,4.11,3.87,6.21c2.12,3.61,4.87,6.03,9.34,6.42c4.31,0.37,8.57,1.29,12.85,1.97
		c0-0.88,0-1.76,0-2.64C752.82,227.8,751.76,227.69,750.71,227.59z"
          />
          <path
            className="st3"
            d="M921.57,422.54c-0.12-5.07-0.02-10.14-0.02-15.59c-2.54,2.1-3.48,0.8-4.47-1.13
		c-0.79-1.53-1.99-2.85-3.01-4.26c-0.25,0.19-0.5,0.37-0.76,0.56c0,1.11,0,2.23,0,3.85c-1.26-1.08-2.04-1.76-2.62-2.26
		c-0.67,0.94-1.3,1.81-2.17,3.03c-1.13-1.79-1.61-2.56-2.1-3.32c-2.41,0.34-4.81,0.68-7.33,1.04c2.6,7.1,5.92,14.02,5.23,21.88
		c0.62,0.7,1.25,1.4,1.88,2.1c1.89,2.09,3.8,4.16,5.52,6.39c2.19,2.84,1.61,5.22-1.33,7.17c-0.56,0.37-1.18,0.66-2.1,1.16
		c4.6,2.63,8.9,5.09,13.98,8.01c0-4.39,0.09-7.99-0.02-11.59C922.09,433.88,921.71,428.21,921.57,422.54z"
          />
          <path
            className="st14"
            d="M535.66,599.23c-1.19-0.35-2.39-0.7-3.54-1.17c-1.15-0.47-2.18-1.23-3.26-1.86c-0.74,0.84-1.34,1.86-2.23,2.5
		c-9.1,6.61-18.26,13.13-27.35,19.76c-0.87,0.63-1.32,1.84-1.96,2.78c2.57,1.2,5.14,2.39,7.66,3.56s4.98,2.32,7.33,3.41
		c1.84-1.66,3.14-3.23,4.78-4.23c8.79-5.36,17.65-10.61,26.53-15.82c2.75-1.61,2.97-2.95,0.25-4.69c-1.13-0.72-2.3-1.37-3.48-2
		c-0.91-0.48-1.82-0.96-2.73-1.44c-0.28-0.15-0.55-0.29-0.83-0.44C536.45,599.46,536.06,599.35,535.66,599.23z"
          />
          <path
            className="st5"
            d="M543.81,730.73c-1.93-1.11-3.65-2.1-5.26-3.02c-1.62-0.93-3.13-1.8-4.64-2.67c-1.59,0.74-3.17,1.5-4.77,2.22
		c-15.3,6.9-30.24,14.47-43.99,24.2c3.74,2.57,7.48,5.15,10.77,7.41C512.17,749.32,527.69,740.2,543.81,730.73z"
          />
          <path
            className="st3"
            d="M832.21,207.34c4.86,2.28,10.28,3.95,15.61,4.5c5.45,0.56,11.07-0.57,16.07-0.92c2.4-2.66,4.66-5.15,7.23-7.99
		c-11.87,1.19-18.31-6.28-25.1-12.99c-2.18-0.08-3.79-0.13-5.13-0.25c-0.76-0.07-1.43-0.16-2.06-0.29
		c-2.58-0.52-4.52-1.64-9.31-4.45c0,0,0,0,0,0c-0.61,3.53-1.17,7.07-1.84,10.59C826.73,200.46,827.59,205.17,832.21,207.34z"
          />
          <path
            className="st14"
            d="M734.56,386.09c-0.3-0.29-0.65-0.58-1.01-0.86c-1.08-0.85-2.24-1.67-2.47-2.7c-0.9-4.15,2.98-6.56,7.73-5.09
		c1.92-5.91,3.85-11.81,5.77-17.72c-3-0.83-5.99-1.65-8.99-2.48c-0.58-0.16-1.16-0.32-1.74-0.48c-0.46,2.42-0.92,4.84-1.38,7.26
		c-0.59-0.11-1.18-0.23-1.77-0.34c0.35-2.27,0.69-4.54,1.03-6.82c-0.77,0.23-1.53,0.46-2.3,0.69c-0.63,2.05-1.27,4.1-1.9,6.16
		c-0.39-0.01-0.79-0.01-1.18-0.02c-0.07-1.53-0.14-3.05-0.22-4.58l0,0v0c-0.83-0.08-1.66-0.16-2.49-0.24c0,0,0,0,0,0
		c-0.44,0.24-0.89,0.49-1.33,0.73c-0.2,0.24-0.4,0.48-0.6,0.72c-0.38,0.85-0.79,1.7-1.18,2.54c-0.39,0.85-0.76,1.7-1.07,2.58
		c-3.17,9.19,0.14,18.5-0.81,27.72c1.09,0.13,2.15,0.26,3.19,0.38c1.17,0.14,2.31,0.28,3.44,0.41c1.9,0.23,3.76,0.45,5.61,0.67
		C732.14,391.78,733.35,388.94,734.56,386.09z"
          />
          <path
            className="st14"
            d="M722.32,359.6c-0.01,0.01-0.02,0.01-0.02,0.01c0.01-0.01,0.02-0.01,0.03-0.02c0,0,0-0.01,0.01-0.01
		L722.32,359.6z"
          />
          <path
            className="st3"
            d="M258.85,672.66c0.11,0.08,0.21,0.16,0.32,0.24c0.49,0.36,0.97,0.72,1.46,1.09c1.05,0.81,2.1,1.62,3.15,2.42
		c0.82,0.62,1.65,1.23,2.5,1.81c0.85,0.58,1.71,1.13,2.6,1.63c8.02,4.51,16.26,8.64,24.24,12.83c4.52-2.81,8.93-5.55,13.4-8.33
		c-4.12-2.73-8.34-5.61-12.65-8.35c-6.38-4.05-12.84-7.99-19.26-11.97c-3.34,1.8-6.69,3.59-10.02,5.41
		C262.66,670.49,260.76,671.59,258.85,672.66z"
          />
          <path
            className="st3"
            d="M556.13,674.18L556.13,674.18c-0.72-0.45-1.45-0.89-2.17-1.34c-0.1,0.28-0.21,0.56-0.31,0.84
		c0.82,0.17,1.64,0.34,2.46,0.51c0.48,0.61,0.88,1.32,1.46,1.81c4.17,3.5,8.42,6.9,12.55,10.45c0.95,0.82,1.9,1.97,2.22,3.15
		c1.55,5.81-2.5,18.62-10.02,20.91c-2.15,0.65-4.26,1.47-6.33,2.35c-6.19,2.63-12.35,5.32-18.52,7.99c2.9,1.92,5.75,3.91,8.73,5.69
		c0.57,0.34,1.81,0.15,2.44-0.26c3.34-2.19,6.52-4.6,9.86-6.79c8.78-5.74,17.56-11.48,26.45-17.05c3.17-1.98,6.65-3.46,10.59-5.47
		c-2.16-1.22-3.57-2.02-4.97-2.81c-1.88,0.65-4.38,2.37-5.52,1.72c-2.83-1.61-4.7-4.48-4.54-8.13c-7.56-4.55-15.13-9.09-22.69-13.64
		C557.25,674.14,556.69,674.16,556.13,674.18z"
          />
          <path
            className="st12"
            d="M991.76,391.85c0.05,2.68,0.02,5.37-0.13,8.05c-0.03,0.53-0.8,1.02-1.22,1.52c3.31,2.53,6.83,2.49,10.71,0.85
		c-0.73-11.7-3.25-23.38-1.67-35.15c0.36-0.02,0.72-0.05,1.08-0.07c0.88,11.37,1.76,22.74,2.67,34.62c1.65-0.95,2.62-1.51,3.59-2.07
		c-0.34-0.05-0.68-0.11-1.02-0.16c-0.53-15.8-1.06-31.6-1.59-47.41c-2.83-0.29-5.72-0.58-7.79-0.79c-0.16,2.51,0,5.04-0.69,7.31
		c-0.09,0.29-0.84,0.38-1.78,0.42c-0.93,0.04-2.05,0.02-2.85,0.09h0C991.29,369.99,991.55,380.92,991.76,391.85z"
          />
          <path
            className="st3"
            d="M149.92,489.24c-8.19,5.49-15.86,10.63-23.69,15.88c0.98,5.83,2,11.86,3,17.83c7.95-1.6,15.48-3.11,22.97-4.62
		C151.45,508.72,150.7,499.18,149.92,489.24z"
          />
          <path
            className="st3"
            d="M198.17,833.6c-1.2,0.41-2.33,1.05-3.43,1.71c-8.95,5.39-16.53,12.47-23.96,19.7
		c-0.53,0.51-0.86,1.22-1.22,1.91c-0.12,0.23-0.24,0.46-0.37,0.68c0.93-0.11,1.86-0.26,2.79-0.38c0.93-0.12,1.86-0.22,2.79-0.25
		c1.74-0.04,3.49,0.26,4.65,0.35c3.31-1.43,6.2-2.68,9.15-3.96c0.99,1.66,1.51,2.53,2.16,3.62c4.18-1.28,5.68,2.39,8.07,4.56
		c0,0,0.11-0.11,0.11-0.11c0.19-0.15,0.39-0.29,0.58-0.44s0.39-0.29,0.58-0.44c1.01-1.08,1.88-2.22,2.63-3.41
		c0.75-1.19,1.38-2.42,1.92-3.69c0.36-0.85,0.67-1.71,0.95-2.58c1.12-3.49,1.69-7.17,2.15-10.82
		C208.41,834.69,203.54,831.78,198.17,833.6z"
          />
          <path
            className="st15"
            d="M607.84,445.95c2.41,0.91,4.67,1.76,7.06,2.66c13.9-11.59,30.26-19.29,44.94-27.64
		c-1.34-2.73-3.59-5.14-3.29-7.18c0.57-3.86,4.73-2.74,7.42-3.46c-0.18-1.51-0.36-3-0.47-4c-18.45,11.34-37.12,22.82-55.66,34.21
		C607.84,442.61,607.84,444.36,607.84,445.95z"
          />
          <path
            className="st12"
            d="M417.18,475.62c-1.21-4.14-4.15-6.7-8.09-8.96c0.27,1.65,0.59,2.9,0.65,4.16c0.3,6.27,1,12.57,0.67,18.82
		c-0.36,6.85-1.68,13.65-2.62,20.47c-0.91,6.59-2,13.15-2.75,19.76c-0.69,6.06-1.1,12.15-1.51,18.24c-0.17,2.6,0.56,4.82,3.79,5.35
		c-0.26-2.29-0.51-4.48-0.81-7.18c1.23-0.08,2.31-0.15,3.4-0.22c1.88-7.93,3.75-15.86,5.63-23.79c-0.09-2.42-0.15-4.84-0.23-7.27
		c-0.08-2.42-0.19-4.84-0.39-7.25c-0.4-4.77-1.17-9.5-1.79-14.25c-0.57-4.35-1.17-8.69-1.76-13.04c0.32-0.05,0.65-0.1,0.97-0.16
		c1.11,5.65,2.28,11.29,3.33,16.95c0.4,2.15,0.76,4.3,1.11,6.45c0.35,2.15,0.69,4.31,1.05,6.46
		C417.61,498.66,417.39,487.14,417.18,475.62z"
          />
          <path
            className="st7"
            d="M524.58,291.66c3.12-2.65,6.24-5.31,9.36-7.96c-0.31-0.35-0.6-0.84-0.9-1.25c-0.3-0.41-0.61-0.74-0.95-0.77
		c-4.62-0.42-5.27-4.7-7.52-7.4c-0.74-0.88-1.38-1.84-2.07-2.76c-4.25,3.17-8.68,6.14-12.72,9.56
		c-6.26,5.29-12.25,10.89-18.36,16.36c1.81,2.84,3.61,5.69,5.42,8.53c1.94-1.65,3.76-3.49,5.85-4.92
		C509.34,296.47,516.64,293.3,524.58,291.66z"
          />
          <path
            className="st9"
            d="M514.66,487.98c0,0-0.03-0.03-0.06-0.05L514.66,487.98z"
          />
          <path
            className="st9"
            d="M507.26,496.12c7.44,4.53,14.74,9.3,22.13,13.92c0.81,0.51,1.86,0.63,2.8,0.94c0.15-0.35,0.31-0.7,0.46-1.05
		c-2.39-1.93-4.78-3.86-7.17-5.79c0.2-0.26,0.4-0.52,0.6-0.78c2.56,1.7,5.12,3.4,7.73,5.12c0.42-0.54,0.83-1.09,1.37-1.79
		c-2.2-2.06-4.31-4.02-6.42-5.99c0.23-0.26,0.45-0.51,0.68-0.77c1.44,1.07,2.89,2.12,4.31,3.23c2.84,2.21,2.93,2.23,4.25,0.46
		c-1.79-1.52-3.56-3.02-5.34-4.53c-0.42-0.12-0.85-0.24-1.27-0.35c0.25-0.28,0.5-0.56,0.76-0.84c0.37,0.24,0.73,0.47,1.1,0.71
		c2.24,1.31,4.47,2.62,6.99,4.09c0.42-0.35,0.99-0.82,1.6-1.32c0.2-0.17,0.41-0.34,0.62-0.51c-1.98-1.08-3.96-2.16-5.94-3.24
		c0.22-0.36,0.43-0.72,0.65-1.08c1.93,1.24,3.86,2.49,5.79,3.73l0,0c3.78,0.24,7.57,0.48,11.35,0.72c0.08-0.32,0.16-0.64,0.25-0.95
		c-4.97-3.16-9.93-6.32-14.99-9.54c-0.26,0.94-0.44,1.56-0.72,2.57c-2.14-1.74-4-3.25-5.95-4.83c-0.68,0.1-1.52,0.22-3.02,0.43
		c0.77-1.02,1.15-1.52,1.51-2c-1.89-0.97-3.85-1.76-5.56-2.92c-4.43-2.99-9.2-3.93-14.21-2.22c-0.69,0.24-1.34,0.63-1.96,1.08
		c-0.26,0.19-0.51,0.39-0.77,0.6c-0.68,0.56-1.34,1.15-2.03,1.68c-0.05,0.04-0.1,0.08-0.15,0.11c0.04,0.05,0.07,0.1,0.11,0.15
		c0.44,0.18,0.89,0.36,1.33,0.54c2.13,0.75,4.26,1.5,6.39,2.24c0.01-0.01,0.01-0.03,0.02-0.04l0,0c0.02,0,0.03,0,0.05,0
		c-0.72-1.47-1.44-2.94-2.34-4.76c6.86-0.5,7.7,0.13,6.63,4.76c-1.34,0-2.81,0-4.29,0c0.01,0.03,0.03,0.05,0.04,0.08
		c-0.03-0.01-0.07-0.02-0.1-0.04c-0.38,1-0.76,2-1.17,3.08c-0.14,0.36-0.28,0.74-0.43,1.12c-1.48,0-2.99,0-5.04,0
		c1.19,1.3,2.03,2.22,2.93,3.22c-2.89,0.17-7.23-3.38-7.33-6.01c2.84,0.29,5.66,0.58,8.92,0.91c-1.92-1.61-3.38-2.84-4.85-4.07
		c-0.25-0.34-0.5-0.68-0.75-1.02c-0.07-0.03-0.13-0.05-0.2-0.08c0,0,0.08-0.08,0.08-0.08c0,0,0,0,0,0
		c-0.48,0.43-0.95,0.86-1.43,1.29c-0.8,0.72-1.6,1.44-2.43,2.18c-1.32,1.19-2.7,2.43-4.2,3.78
		C502.05,493.75,504.83,494.64,507.26,496.12z M533.01,490.19c0,1.6,0,2.6,0,3.38c-2.08,2.04-5.8,0.22-8.02-4.49
		C528.24,489.53,530.58,489.86,533.01,490.19z M514.18,495.26c3.67,0.83,7.34,1.65,11.05,2.49c-1.51-1.54-3.07-3.14-4.63-4.73
		c0.29-0.3,0.57-0.59,0.86-0.89c2.22,1.54,4.43,3.08,6.71,4.67c-0.33,1.07-0.62,2.03-1.04,3.4c-1.74-0.29-3.54-0.6-5.34-0.9
		c-0.62-0.24-1.24-0.49-1.86-0.73c-0.06,0.16-0.13,0.31-0.19,0.47c0.48,0.26,0.96,0.52,1.44,0.77c0.46,0.64,0.91,1.28,1.37,1.92
		c-0.23,0.27-0.46,0.54-0.69,0.82c-2.73-2.03-5.46-4.05-8.2-6.08C513.84,496.06,514.01,495.66,514.18,495.26z"
          />
          <path
            className="st3"
            d="M255.37,435.13c3.56-0.44,6.48-0.84,8.28,2.17c1.55,2.58,3.08,5.2,4.23,7.97c0.73,1.77,0.21,3.35-2.3,3.52
		c-2.7,0.19-5.38,0.65-7.96,0.97c-0.01-0.01,0.44,0.37,0.73,0.84c2.69,4.29,6.27,7.38,11.59,6.39c5.1-0.95,9.77-3.59,11.9-8.44
		c3.65-8.34,5.89-17.18,4.76-26.39c-0.39-3.16-0.49-6.94-4.06-8.78c-4.13,1.44-6.64,4.8-8,10.65c-1.82,7.81-6.45,9.53-12.42,4.56
		c-2.77-2.31-5.8-2.96-9.24-1.93c-2.05,5.79-1.59,11.23,1.2,16.49C254.52,440.38,254.96,437.7,255.37,435.13z M273.13,432.21
		c0.57-3,2.18-5.43,5.26-5.81c3.05-0.37,3.82,2.41,4.81,4.69c2.03,4.68-0.43,8.41-2.63,12.09c-0.34,0.56-2.71,0.6-3.57,0.03
		c-3.09-2.04-3.96-5.3-3.89-8.87C273.11,433.63,273,432.9,273.13,432.21z M269.37,442.35c-0.46-2.13-0.5-4.35-0.73-6.53
		c0.49-0.13,0.98-0.26,1.48-0.39c0.52,2.02,1.19,4.01,1.52,6.05c0.62,3.88,2.71,5.93,6.85,5.76c-0.77,1.36-1.37,2.42-2.12,3.76
		c-1.71-1.46-3.05-2.61-4.34-3.71c-1.4,2.19-2.79,4.36-4.48,7.01c-1.45-1.23-2.37-2.01-3.55-3.01
		C268.3,450.9,270.36,446.99,269.37,442.35z"
          />
          <path
            className="st10"
            d="M708.09,377.34c0.35,4.24,1.33,8.43,2.03,12.65c0.74-6.06,1.45-12.13,2.27-18.18
		c0.1-0.76,0.78-1.45,1.19-2.17c0-0.45,0-0.91,0-1.36c0-0.49,0-0.98,0-1.47c0.06-0.23,0.12-0.47,0.18-0.7
		c0.55-5.3,1.57-10.44,3.47-15.29c1.14-2.91,2.61-5.71,4.48-8.37c-0.49-1.87-0.5-3.37-0.03-4.41c0.47-1.05,1.4-1.64,2.79-1.7
		c-0.19,1.33-0.38,2.65-0.57,3.96c3.1-0.44,6.2-0.89,8.36-1.19c3.63,2.38,6.54,4.28,9.45,6.18c1.09,0.21,2.17,0.43,3.5,0.68
		c0.44,0.09,0.91,0.18,1.41,0.28c0.77-2.51,1.49-4.88,2.22-7.25c-0.89-0.5-1.79-0.99-2.69-1.47c-2.7-1.44-5.43-2.86-7.9-4.63
		c-5.73-4.11-16.36-2.81-20.86,1.87c-6.55,6.82-9.54,15.02-9.98,24.14C707.11,365.02,707.58,371.21,708.09,377.34z M719.58,337.65
		c-0.61,2.38-1.2,4.65-1.91,7.43C714.96,340.99,715.78,338.05,719.58,337.65z"
          />
          <path
            className="st3"
            d="M226.46,456.62c0.19,9.1,0.06,18.2,0.06,27.4c1.2-0.72,2.37-1.43,3.53-2.12c1.6-0.96,3.16-1.9,4.71-2.83
		c1.83-1.1,3.65-2.19,5.46-3.28c-0.74-1.93-1.15-3.86-1.35-5.81c-0.32-3.25-0.04-6.53,0.36-9.89c0.48-4.05-0.33-8.25-0.54-12.38
		c-0.27-5.2-0.51-10.41-0.77-15.61c-1.75,1.27-3.48,2.59-5.22,3.88c-1.74,1.29-3.51,2.54-5.34,3.69c-2.27,1.42-2.48,3.19-2.2,5.43
		C225.66,448.93,226.38,452.77,226.46,456.62z"
          />
          <path
            className="st7"
            d="M466.94,512.06c1.17,1,2.45,1.87,3.68,2.81c2.9-1.53,5.79-3.07,8.57-4.54c0.93-0.49,1.84-0.97,2.74-1.45
		c-0.74-8.66-1.48-17.29-2.26-26.38c-4.04,2.25-8.08,4.32-11.93,6.69c-1.32,0.81-2.62,1.64-3.92,2.47c-2.6,1.67-5.18,3.38-7.76,5.08
		c-1.29,0.85-2.58,1.7-3.88,2.55C457.11,503.55,462.01,507.82,466.94,512.06z"
          />
          <path
            className="st16"
            d="M565.33,669.31c-1.03,0.46-1.86,1.36-2.78,2.06c3.58,2.16,7.16,4.32,10.75,6.48c0.51-0.72,0.86-1.78,1.55-2.1
		c11.37-5.31,22.81-10.46,34.16-15.81c4.22-1.99,8.24-4.39,12.35-6.6c-3.08-1.9-6.16-3.79-9.24-5.69c-7.46,3.6-14.88,7.3-22.4,10.77
		C581.64,662.15,573.44,665.64,565.33,669.31z"
          />
          <path
            className="st8"
            d="M961.67,378.98c0.02,12.63,0.05,25.26,0.07,37.89c1.27,0.64,2.54,1.28,4.07,2.05
		c0.51,0.26,1.04,0.52,1.61,0.81c0.81-4.14,1.44-7.54,2.15-10.92c0.16-0.78,0.38-1.85,0.93-2.2c2.77-1.75,2.25-4.62,2.62-7.16
		c0.32-2.2,0.2-4.49,0.68-6.64c0.8-3.61,2.49-7.11,2.81-10.74c0.62-6.95,0.54-13.96,0.75-20.94c-4.11,5.42-8.15,10.89-12.38,16.22
		C964.28,378.23,962.79,378.46,961.67,378.98z"
          />
          <path
            className="st14"
            d="M774.56,622.99c-15.02,8.03-30.07,16.01-45.11,24c2.93,1.75,5.85,3.5,8.78,5.26
		c14.64-7.43,29.29-14.84,43.91-22.32c1.61-0.83,3.13-1.85,4.65-2.86c0.51-0.34,1.02-0.67,1.53-0.99h0
		c-2.65-1.5-5.29-2.99-7.94-4.49c-0.7-0.4-1.4-0.79-2.11-1.19C777.04,621.26,775.88,622.28,774.56,622.99z"
          />
          <path
            className="st13"
            d="M494.33,515.97c1.36,1.2,3.11,2.34,4.84,2.67c6.99,1.34,14.02,2.97,21.08,3.27c4.72,0.2,9.52-1.66,15.11-2.77
		c-2.06-1.64-3.08-2.44-4.09-3.24c-12.92-3.06-22.19-12.69-34.3-18.3c-1.38,2.08-3.38,4.58-4.81,7.36
		C489.84,509.44,490.52,512.63,494.33,515.97z"
          />
          <path
            className="st14"
            d="M565.33,669.31c8.12-3.67,16.31-7.16,24.4-10.9c7.52-3.47,14.94-7.17,22.4-10.77
		c-3.5-2.28-7.01-4.56-10.47-6.82c-5.81,3.08-11.32,6.08-16.9,8.93c-8.44,4.31-16.95,8.5-25.41,12.79c-1.9,0.97-3.65,2.19-3.03,4.86
		c2.1,1.34,4.17,2.65,6.24,3.97C563.47,670.67,564.3,669.77,565.33,669.31z"
          />
          <path
            className="st9"
            d="M626.39,352.93c-5.17-2.36-8.68-1.13-11.09,4.06c-1.07,2.3-1.41,4.99-1.81,7.54
		c-0.31,1.98-0.02,4.05-0.29,6.04c-0.42,3.04-1.1,6.05-1.81,9.85c2.55-1.58,4.01-2.9,5.72-3.45c4.96-1.61,9.96-3.16,15.06-4.2
		c1.94-0.4,4.18,0.65,6.29,1.03c1.46-0.88,2.92-1.76,4.38-2.64C639.92,362.77,634.47,356.61,626.39,352.93z"
          />
          <path
            className="st5"
            d="M375.03,805.77c-0.14-0.09-0.29-0.18-0.44-0.25c-13.68-6.51-26.58-14.33-38.8-23.27
		c-5.96-4.37-11.92-8.75-18.14-13.32c-1.66-0.16-3.87-0.05-5.86-0.65c-2.18-0.66-4.33-1.41-6.47-2.18
		c-2.11-0.76-4.22-1.55-6.33-2.31c0,0,0,0,0,0c4.4,3.19,8.74,6.46,13.17,9.62c1.48,1.06,2.97,2.1,4.48,3.13
		c3.31,2.25,7.35,3.53,10.36,6.08c2.43,2.06,4.24,4.7,7.21,6.4c5.42,3.11,10.39,7,15.74,10.25c8.67,5.27,17.49,10.3,26.24,15.43
		c0.03-2.67,0.06-5.33,0.1-8C375.86,806.39,375.46,806.05,375.03,805.77z"
          />
          <path
            className="st5"
            d="M298.9,763.73c0,0-0.02,0.02-0.02,0.02c0.03,0.01,0.06,0.02,0.09,0.03
		C298.95,763.77,298.93,763.75,298.9,763.73z"
          />
          <path
            className="st11"
            d="M457.54,314.49c-0.4,0.43-0.79,0.85-1.27,1.36c-0.13,0.14-0.27,0.29-0.41,0.44c-0.4,0.43-0.86,0.93-1.42,1.53
		c0.12,1.66,0.24,3.33,0.36,5.01c0.84,11.75,1.71,23.93,2.57,36.11c0.59-0.34,1.46-0.52,1.73-1.03c3.1-5.81,6.12-11.67,9.15-17.52
		c-7.4-5.34-7.83-16.89-1.57-24.3c5.16-6.11,10.93-3.61,14.86,1.33c0.43,0.54,0.97,0.98,1.47,1.46c0.98-0.98,1.96-1.96,2.95-2.94
		c-0.53-3.24-1.06-6.47-1.57-9.62c-7.47-2.17-13.1,1.42-18.93,4.54c-2.18,1.17-4.69,1.71-7.05,2.54c0,0-0.01,0.22-0.01,0.22
		l0.22,0.04C458.26,313.93,457.9,314.21,457.54,314.49z"
          />
          <path
            className="st8"
            d="M414.99,357.9c0.36,2.52,0.99,4.99,1.5,7.49c1.62-3.15,3.32-6.26,4.86-9.46c2.86-5.97,5.58-12,8.48-17.95
		c0.4-0.82,1.47-1.32,2.23-1.96c-0.22-3.64-0.44-7.29-0.64-10.68c-1.38-0.99-2.6-1.87-4.35-3.12c1.55-1.29,2.61-2.12,3.61-3.02
		c0.87-0.79,1.68-1.65,2.51-2.48c-0.2-0.21-0.4-0.41-0.6-0.62c-6.68,4.74-13.35,9.49-20.38,14.48c0,1.71-0.23,4.11,0.04,6.45
		C413.05,344,414,350.95,414.99,357.9z"
          />
          <path
            className="st5"
            d="M329,806.79c0.9-0.75,1.69-1.44,2.52-2.09c6.83-5.35,12.18-2.14,15.84,3.34c2.12,3.18,2.57,7.47,3.76,11.23
		c8.53-0.01,16.66,1.82,23.62,8.11c0-2.71,0-4.94,0-6.21c-11.95-8.45-23.32-16.67-34.88-24.61c-6.06-4.16-12.45-7.84-18.96-11.91
		c-1.34,8.13-0.24,14.35,2.81,16.79c0.54-1.39,1.06-2.72,1.69-4.38C329.8,799.36,325.53,804.45,329,806.79z"
          />
          <path
            className="st16"
            d="M516.16,590.91c-8.68,6.77-17.5,13.37-26.14,20.19c-0.84,0.67-1.54,1.52-2.2,2.42
		c-0.39,0.54-0.78,1.1-1.16,1.65c-0.18,0.25-0.36,0.5-0.55,0.75c-0.08,0.11-0.16,0.22-0.24,0.32c0,0,0,0,0,0
		c2.58,1.13,5.17,2.26,7.75,3.39c1.24,0.54,2.47,1.08,3.71,1.62c0.64-0.94,1.09-2.15,1.96-2.78c9.08-6.63,18.25-13.15,27.35-19.76
		c0.88-0.64,1.49-1.66,2.23-2.5c-0.69-0.77-1.39-1.55-2.08-2.32c0,0,0.01,0.02,0.01,0.02c-0.71-0.26-1.43-0.51-2.14-0.77l0,0
		c0.02,0.01,0.03,0.02,0.05,0.03c-0.02-0.01-0.03-0.02-0.05-0.03c-0.01,0-0.02-0.01-0.03-0.01c0.01,0,0.02,0.01,0.03,0.01
		c0,0,0,0,0,0c-1.45-1.02-2.9-2.03-4.35-3.05c-0.63-0.41-1.25-0.82-1.88-1.23c-0.33-0.22-0.66-0.43-1-0.65
		C517.02,589.12,516.86,590.36,516.16,590.91z"
          />
          <path
            className="st3"
            d="M393.92,355.88c0.89-0.1,1.86-0.47,2.67-0.25c3.75,1.01,6.3,3.98,6.09,7.96c-0.2,3.74-1.12,7.48-6.31,9.03
		c-1.32-1.96-3.35-3.95-4.09-6.35c-0.73-2.37-0.16-5.13-0.16-6.98c-2.17,0.18-4.33,0.97-5.93,0.35c-2.21-0.86-4.04-2.75-5.96-4.29
		c-3.37-2.68-6.29-1.82-7.42,2.35c-0.46,1.72-0.68,3.37-0.68,4.98c0.01,4.82,1.99,9.17,5.13,13.23c0,0,0,0,0,0
		c0.47,0.73,0.94,1.47,1.41,2.2c0.2-0.12,0.41-0.25,0.61-0.37c-0.5-0.79-1-1.59-1.5-2.38c-0.17-0.58-0.33-1.16-0.49-1.74
		c-1.11-4.07-1.89-8.16-1-12.42c3.86-0.44,6.31,0.71,7.87,3.97c2.72,5.68,2.14,10.59-3.07,14.55c0.39,0.45,0.56,0.8,0.84,0.96
		c3.04,1.8,5.26,5.26,9.44,4.79c4.02-0.46,6.15-3.28,7.84-6.51c4.62-8.86,6.25-18.38,5.68-28.26c-0.21-3.68-2.1-6.51-6.14-7.56
		c-0.49,0.79-1.01,1.44-1.31,2.17c-1.37,3.32-2.68,6.66-4.01,9.99C393.58,355.49,393.75,355.69,393.92,355.88z M396.29,377.77
		c-1.8,0.13-3.67-0.81-5.54-1.32c-0.42,0.49-1.33,1.55-2.24,2.61c-1.24,0.57-2.49,1.14-3.73,1.72c-0.12-0.27-0.23-0.53-0.35-0.8
		c1.17-0.48,2.35-0.96,3.52-1.44c0,0,0,0,0,0c0.34-2.69,0.83-5.38,0.96-8.08c0.09-1.86-0.31-3.75-0.07-5.71
		c2.48,3.44,1.71,8.54,6.38,10.83c0.84-0.5,1.8-1.07,2.76-1.63c0.27,0.2,0.54,0.41,0.8,0.61
		C397.96,375.68,397.2,377.7,396.29,377.77z"
          />
          <path
            className="st12"
            d="M847.02,305.11c1.01,9.45,3.26,18.47,8.7,26.36c2.62-0.82,5.08-1.59,7.65-2.39
		c-9.87-17.92-11.64-36.3-3.05-55.26c-2.55,0.21-5.12,0.33-7.64,0.7c-0.85,0.12-2.06,0.7-2.33,1.38
		c-2.41,6.03-5.22,12.02-4.64,18.77C846.01,298.15,846.64,301.62,847.02,305.11z"
          />
          <path
            className="st7"
            d="M816.69,265.3c0,17.67,0,35.14,0,52.36c2.54,1.48,4.62,2.69,6.7,3.9c-0.48-5.43-1.54-10.88-1.32-16.28
		c0.42-10.32,1.65-20.6,6.3-30.08c0.63-1.28,1.45-2.45,2.18-3.68c-0.51-1.02-1.03-2.04-1.52-3.07c-1.17-2.45-0.91-4.11,2.3-4.11
		c-0.93-2.64-1.87-5.28-2.8-7.93c-1.91,1.46-3.82,2.91-5.74,4.37C822.27,264.54,819.3,264.99,816.69,265.3z"
          />
          <path
            className="st11"
            d="M419.09,415.57c-1-3.63-3.13-6.94-4.78-10.39c-0.96-2-1.92-4-2.89-6c-5.5-2.05-11-4.11-16.81-6.28
		c-0.59,0.38-1.55,1.01-2.82,1.83c5.22,7.46,12.6,12.24,20.77,17.12c-6.94,0.53-11.42-3.56-17.16-6.1
		c3.91,7.61,10.45,9.38,17.38,11.06c-1.99,0.86-3.93,1.3-5.8,1.1c-2.16-0.22-4.25-1.01-6.38-1.55c2.13,5.72,4.26,11.45,6.39,17.17
		c0.51,0.2,1.01,0.41,1.52,0.61c1.44,0.45,2.88,0.89,4.32,1.34c0.48-2.37,0.95-4.75,1.43-7.12c-0.9-1.05-1.81-2.11-3.11-3.63
		c3.2,0,5.67,0,8.14,0C419.28,421.67,419.88,418.43,419.09,415.57z"
          />
          <path
            className="st11"
            d="M522.49,271.51c-1.21-2.38-2.42-4.76-3.63-7.15h0c-3.45,1.9-6.99,3.65-10.34,5.73
		c-9.13,5.67-18.16,11.5-27.25,17.23c-3.63,2.29-7.31,4.48-10.97,6.72c7.61-2.12,14.73-1.79,21.1,3.38
		c6.11-5.47,12.1-11.07,18.36-16.36C513.81,277.64,518.24,274.68,522.49,271.51z"
          />
          <path
            className="st9"
            d="M832.08,288.05c0.13-0.47,0.28-0.94,0.44-1.4c1.15-3.25,3.04-6.29,4.51-9.44c-2.16-1.89-4.32-3.79-6.48-5.68
		c-0.73,1.22-1.56,2.4-2.18,3.68c-4.65,9.49-5.88,19.76-6.3,30.08c-0.22,5.4,0.84,10.85,1.32,16.28c1.18,1.43,3.29,2.79,3.39,4.29
		c0.39,5.93,0.16,11.9,0.16,17.91c0.97,0,2.13,0,2.7,0c1.76-4.7,3.41-9.11,5.06-13.51c-0.66-0.88-1.65-1.67-1.92-2.65
		C829.2,314.49,828.45,301.25,832.08,288.05z"
          />
          <path
            className="st8"
            d="M470.63,514.87c-1.23-0.93-2.52-1.8-3.68-2.81c-4.93-4.24-9.84-8.51-14.75-12.77
		c-2.16,1.24-4.32,2.48-6.47,3.71c-2.15,1.23-4.28,2.45-6.39,3.66c2.13,7.81,4.15,15.27,6.25,22.96
		C454.03,524.64,462.33,519.75,470.63,514.87z"
          />
          <path
            className="st4"
            d="M256.56,505.87c0.55,9.97,0.85,19.95,1.01,29.93c0.34,20.48,0.51,40.96,0.75,61.45c0,0.36,0,0.72,0.02,1.07
		c0.62,14.47,1.28,28.94,1.82,43.41c0.17,4.57,0.03,9.16,0.03,13.9c1.31-0.09,2.01-0.14,2.17-0.16c0-9.67,0.09-19.03-0.02-28.39
		c-0.14-12.03-0.35-24.05-0.71-36.08c-0.34-11.27-1.29-22.53-1.33-33.79c-0.06-18.04-0.78-36.02-2.91-53.94
		c-1.01-8.52-1.56-17.09-2.32-25.64c-0.2,0.01-0.4,0.02-0.6,0.03c0,0.94-0.07,1.88,0.01,2.81
		C255.17,488.93,256.09,497.39,256.56,505.87z"
          />
          <path
            className="st4"
            d="M262.35,655.47L262.35,655.47C262.41,655.47,262.41,655.47,262.35,655.47z"
          />
          <path
            className="st8"
            d="M446.23,533.29c0.77,4.26,1.51,8.37,2.28,12.62c11.65-6.98,22.96-13.76,34.71-20.8
		c-0.24-3.91-0.51-8.23-0.81-13C470.15,519.29,458.33,526.21,446.23,533.29z"
          />
          <path
            className="st5"
            d="M185.13,609.19c0.19,4.31,0.45,8.41,0.54,12.51c0.13,6.17-0.94,12.28,1.26,18.49
		c1.39,3.93,0.92,8.53,1.28,12.82c3.69-0.32,7.29-0.62,11.03-0.94c-0.07-1.26-0.08-1.95-0.15-2.64c-0.65-6.64-1.56-13.27-1.88-19.92
		c-0.23-4.7,0.34-9.44,0.55-14.17c-0.18-1.85-0.36-3.69-0.55-5.54C193.24,609.6,189.27,609.4,185.13,609.19z"
          />
          <path
            className="st9"
            d="M445.68,383.15c-0.25-7.67-1.29-15.31-1.94-22.97c-0.32-3.78-0.54-7.56-0.8-11.35
		c-0.24-3.54-0.48-7.08-0.72-10.63c-0.97,0.61-1.95,1.21-2.93,1.82c-0.98,0.61-1.97,1.22-2.97,1.85c0.8,22.43,1.58,44.62,2.39,67.31
		c2.9,0.49,4.12-2.88,6.39-4.31C445.33,397.64,445.91,390.38,445.68,383.15z"
          />
          <path
            className="st16"
            d="M599.52,742.31c7.43-7.5,15.14-14.72,22.69-22.12c1.06-1.04,1.82-2.39,2.72-3.6
		c-2.86-1.75-5.73-3.49-8.59-5.24c-1.62,1.98-3.11,4.1-4.89,5.93c-10.04,10.28-20.16,20.49-30.25,30.72
		c0.93,0.49,1.87,0.98,2.8,1.47c1.83-0.21,3.65-0.42,5.91-0.69c-0.45,1.9-0.64,2.69-0.82,3.47c0.03,0.02,0.05,0.03,0.08,0.05
		c0.42,0.28,0.84,0.57,1.26,0.85C593.43,749.53,596.21,745.66,599.52,742.31z"
          />
          <path
            className="st16"
            d="M760.35,330.34c0.06-0.06,0.13-0.11,0.19-0.17c0.22-0.2,0.45-0.39,0.67-0.59
		C760.92,329.84,760.63,330.09,760.35,330.34c0.29-0.25,0.58-0.51,0.86-0.76c1.68-1.17,3.36-2.34,5.11-3.56
		c1.75-1.22,3.58-2.49,5.55-3.86c0.14-1.85,0.34-4.42,0.54-7c-1.39,0-2.79,0-4.45,0c0.37-0.99,0.69-1.87,1.02-2.75
		c-0.41,0.02-0.83-0.01-1.25-0.01c-0.42,0-0.83,0.02-1.21,0.13c-9.59,2.67-19.13,5.54-29.26,5.01c0.28,3.03,0.57,6.07,0.85,9.1
		c4.01,2.42,8.03,4.85,12.04,7.27c3.42-1.19,6.85-2.39,10.27-3.58c0,0-0.02,0.01-0.04,0.01
		C760.36,330.34,760.35,330.34,760.35,330.34C760.35,330.34,760.35,330.34,760.35,330.34z"
          />
          <path
            className="st5"
            d="M313.37,780.24c-7.53-4.62-14.11-10.75-21.41-15.77c-7.63-5.24-15.64-9.91-23.73-14.99c0,3.57,0,6.51,0,9.07
		c13.76,2.97,20.21,13.32,26.39,24.38c6.47-4.01,11.83-2.68,15.8,3.75c0.71,1.15,2,1.94,3.53,3.36c0.42,1.97,0.99,4.66,1.56,7.35
		c0.32-0.1,0.65-0.19,0.97-0.29c0.35-2.46,0.83-4.9,1-7.37C317.74,785.92,317.62,782.85,313.37,780.24z"
          />
          <path
            className="st10"
            d="M431.99,415.52c0.1-0.46,0.2-0.91,0.3-1.37c-0.25-1.66-0.65-3.32-0.72-4.99c-0.15-3.96-0.1-7.93-0.24-11.9
		c-0.01-0.36-0.77-0.7-1.19-1.05c-0.19,0.78-0.39,1.54-0.64,2.28c-0.75,2.21-1.9,4.17-4.04,5.54c2.71-7.55,2.57-14.88-0.61-22.14
		c-0.19-0.25-0.37-0.5-0.56-0.75c-1.71-0.23-3.41-0.46-5.12-0.69c0.21,0.76,0.36,1.54,0.6,2.29c0.08,0.25,0.17,0.49,0.27,0.73
		c3.42,7.97,0.58,10.56-4.82,14.18c-1.11,0.74-2.52,1.03-3.8,1.52c0.96,2,1.93,4,2.89,6c1.65,3.45,3.78,6.76,4.78,10.39
		c0.79,2.86,0.19,6.1,0.21,9.17c1.99-0.12,3.74-0.6,5.29-1.38c2.58-1.31,4.61-3.46,6.24-6.21c0.22-0.29,0.43-0.58,0.65-0.87l0,0
		c-0.22,0.29-0.43,0.58-0.65,0.87c0.22-0.29,0.43-0.58,0.65-0.87l-0.01,0.01C431.64,416.02,431.82,415.77,431.99,415.52z"
          />
          <path
            className="st12"
            d="M837.03,277.2c-1.47,3.16-3.36,6.2-4.51,9.44c-0.16,0.46-0.31,0.93-0.44,1.4
		c-3.62,13.19-2.88,26.43,0.69,39.55c0.27,0.99,1.26,1.77,1.92,2.65c1.92-1.5,3.64-3.5,5.82-4.36c1.85-0.73,4.22-0.15,6.16-0.15
		c-1.12-4.15-2.4-8.9-3.58-13.25c-0.4,1.9-0.86,4.14-1.33,6.37c-0.63-0.05-1.26-0.11-1.89-0.16c-2.03-13.04,0.84-26.2,0.09-39.27
		c-0.75-0.72-1.51-1.43-2.26-2.15C837.48,277.26,837.25,277.23,837.03,277.2z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M296.72,539.39c1.69-0.3,3.41-0.91,4.91-1.75c6.09-3.38,12.12-6.89,18.16-10.38
		c17.73-10.22,35.47-20.42,53.15-30.73c1.98-1.15,3.37-3.37,5.38-4.4c3.66-1.87,7.58-3.21,11.96-5c-1.82-0.97-2.92-1.56-3.96-2.12
		c-31.97,15.56-62.04,34.06-91.86,53.48C294.94,538.71,295.92,539.53,296.72,539.39z"
            />
          </Link>
          <path
            className="st5"
            d="M211.38,869.14c0.72-3.15,1.43-6.31,2.15-9.46h0l0,0c-2.6-0.22-5.2-0.44-7.8-0.65c-0.15,0.2-0.3,0.4-0.44,0.61
		c-0.43,0.62-0.83,1.29-1.26,1.92c-0.29,0.42-0.58,0.82-0.9,1.17c-0.32,0.36-0.67,0.67-1.06,0.93c-6.43,4.13-12.89,8.27-19.67,11.75
		c-5.07,2.6-10.65,4.18-16.02,6.23c0.51,1.83,1.01,3.59,1.49,5.32c0.48,1.73,0.96,3.42,1.43,5.11
		C183.33,884.42,197.36,876.78,211.38,869.14z"
          />
          <path
            className="st10"
            d="M450.8,348.19c0.31,15.63-0.85,31.28,2,46.85c1.27,6.9,1.15,14.06,1.55,21.11
		c0.41,7.18,6.12,12.25,12.73,11.61c7.11-0.68,13.95-2.27,20.48-5.07c6.16-2.65,11.49-6.2,12.69-13.55
		c0.23-8.85,0.5-17.7,0.65-26.55c0.02-1.17-0.58-2.34-0.88-3.52c-0.26,5.56-0.32,11.14-0.59,16.69c-0.06,1.23-0.13,2.46-0.21,3.69
		c-0.15,2.26-0.36,4.51-0.63,6.75c-0.77,6.2-3.7,11.44-11.07,12.93c-6.46,1.31-12.7,3.67-19.06,5.47c-3.9,1.1-8.21-0.07-9.7-3.19
		c-1.43-2.98-2.73-6.35-2.81-9.58c-0.19-8.19,0.61-16.4,0.49-24.59c-0.11-7.9-0.82-15.79-1.38-23.68
		c-0.41-5.85-1.01-11.68-1.52-17.53c-0.89,0.24-1.78,0.48-2.66,0.71C450.86,347.24,450.79,347.71,450.8,348.19z"
          />
          <path
            className="st12"
            d="M409.47,730.84c4.03,2.41,4.31,3.18,2.68,7.72c5.8-3.29,11.59-6.59,17.55-9.97c1.98-1.13,3.99-2.27,6.01-3.42
		c-7.62-5.03-14.44-9.53-21.25-14.03c-0.12,0.17-0.23,0.35-0.35,0.52c0.58,1.22,1.15,2.44,2.21,4.67c-2.74-1.06-4.41-1.71-6.34-2.46
		c-0.16,2.14-0.31,4.09-0.48,6.35c-1.47-0.57-2.83-1.09-4.19-1.62c-0.28,0.78-0.53,1.48-0.78,2.18c-0.85-0.31-1.46-0.54-2.22-0.82
		c-0.19,0.98-0.35,1.77-0.5,2.55c-1.25-0.34-2.2-0.6-3.29-0.9c0,0.81,0,1.4,0,1.98c1.34,1,2.64,2.07,4.04,2.98
		C404.82,728.06,407.15,729.45,409.47,730.84z"
          />
          <path
            className="st11"
            d="M429.82,337.97c-2.9,5.95-5.62,11.99-8.48,17.95c-1.53,3.19-3.23,6.31-4.86,9.46c-0.38,3.08-0.21,5.75,3.9,6
		c0.72,0.04,1.49,0.66,2.09,1.19c3.45,3.1,6.86,6.25,10.38,9.47c0.25-4.75,0.79-9.52,0.71-14.28c-0.2-10.59,1.17-21.27-1.5-31.75
		C431.29,336.66,430.22,337.15,429.82,337.97z"
          />
          <path
            className="st3"
            d="M213.58,615.57c-0.26-3.35-0.82-6.67-1.19-9.55c-5.27,1.31-10.23,2.54-15.18,3.78
		c0.18,1.85,0.36,3.69,0.55,5.54c0.12-1.64,0.24-3.27,0.36-4.91c0.39-0.01,0.77-0.01,1.16-0.02c0.47,3.05,1.3,6.1,1.34,9.16
		c0.07,5.08-0.53,10.16-0.51,15.25c0.01,1.61,0.67,4.19,1.74,4.64c3.68,1.55,7.7,1.93,12.35,0.82c-0.71-3.54-1.34-6.68-2.05-10.27
		c2.05-1.57,3.57-4,1.28-7.36c-0.13-0.19-0.05-0.55-0.04-0.83C213.46,619.75,213.74,617.64,213.58,615.57z"
          />
          <path
            className="st15"
            d="M669.11,440.84c1.87-7.2,3.74-14.4,5.6-21.58c1.19-0.16,2.32-0.31,3.68-0.49c0-1.43,0-2.63,0-3.95
		c-1.37,0-2.53,0-4.35,0c0-2.04-0.28-3.97,0.05-5.8c1.71-9.66,9.76-14.2,16.85-19.78c-0.4,1.31-0.81,2.62-1.14,3.68
		c0.54,1.29,1.01,2.41,1.47,3.52c4.21-1.17,1.89-5.76,4.16-8.07c3.2,0.59,6.37,1.17,9.65,1.77c0-0.58,0-1.37,0-2.68
		c-3.46-0.39-6.84-0.77-10.23-1.15c-0.23,0.43-0.45,0.85-0.68,1.28c-0.63-1.4-1.77-2.79-1.81-4.21c-0.18-7.41-4.01-8.75-11.54-9.39
		c2.42,2.42,5.05,4.56,7.86,6.45c2.45,1.65,2.72,5.28,0.43,7.06c-3.01,2.33-5.92,4.78-9.35,7.57c-4.84-3.72-10.41-7.31-18.04-4.91
		c4.3,5.38,9.84,5.45,16.05,5.29c-3.43,6.24-7.05,11.87-6.58,18.83c-1.68,0.46-3.18,0.88-4.88,1.35c-0.6-0.71-1.26-1.47-2.15-2.51
		c-1.78,0.4-3.71,0.83-5.94,1.33c3.4,4.91,8.57,2.89,12.84,4.3c-1.15,6.33-0.1,12.77-3.37,18.58c-0.02-0.01-0.04-0.01-0.06-0.02
		c0.26,1.66,0.4,2.57,0.54,3.47C668.48,440.79,668.79,440.82,669.11,440.84z"
          />
          <path
            className="st15"
            d="M660.65,440.29c2.21-0.89,4.34-1.96,6.74-3.06c-5.33-1.92-10.02-0.52-14.64,0.87
		c-0.09,0.03-0.17,0.07-0.24,0.14c-0.27,0.23-0.48,0.69-0.67,1.2c-0.1,0.26-0.19,0.52-0.29,0.77c-0.07,0.19-0.15,0.37-0.23,0.54
		C654.51,442.51,657.61,441.51,660.65,440.29z"
          />
          <path
            className="st15"
            d="M667.6,437.13c-0.07,0.03-0.14,0.06-0.21,0.1c0.08,0.03,0.16,0.05,0.23,0.08
		C667.61,437.24,667.61,437.19,667.6,437.13z"
          />
          <path
            className="st15"
            d="M651.32,440.74c-0.05-0.03-0.1-0.05-0.15-0.08c0,0,0.13,0.12,0.13,0.12
		C651.31,440.77,651.31,440.75,651.32,440.74z"
          />
          <path
            className="st5"
            d="M399.21,508.52c0.21,6.17,0.44,12.34,0.75,18.5c0.06,1.1,0.54,2.18,1.01,3.94c0.89-6.3,1.77-11.76,2.42-17.25
		c0.64-5.39,1.59-10.82,1.42-16.21c-0.29-9.45-1.3-18.88-2.08-28.32c-0.23-2.82-0.69-5.62-1.03-8.43
		c-0.83-6.79-2.01-13.56-2.38-20.37c-0.25-4.57-1.09-8.37-5.75-10.94c0,1.52-0.15,2.83,0.02,4.09c1.35,10.16,3.33,20.27,4.03,30.47
		C398.63,478.8,398.71,493.68,399.21,508.52z"
          />
          <path
            className="st5"
            d="M190.73,856.99c-0.65-1.09-1.17-1.96-2.16-3.62c-2.95,1.27-5.84,2.53-9.15,3.96c-1.16-0.1-2.91-0.4-4.65-0.35
		c-0.93,0.02-1.86,0.12-2.79,0.25c-0.93,0.12-1.86,0.27-2.79,0.38h0c-1.4,3.13-2.79,6.26-4.19,9.38c1.79,1.46,3.57,2.91,5.6,4.57
		c-2.34,1.92-3.96,3.25-5.57,4.57c0.94,0.38,2.02,1.28,2.78,1.06c2.81-0.83,5.54-1.98,8.25-3.11c2.01-0.84,4.01-1.68,6-2.56
		c5.96-2.63,11.72-5.61,16.74-9.96C196.41,859.38,194.91,855.71,190.73,856.99z"
          />
          <path
            className="st3"
            d="M142.7,707.19c-2.5-0.19-5.02-0.04-7.27-0.04c-0.9,1.1-1.62,1.98-2.25,2.76c-1.86-0.83-3.52-1.57-5.93-2.65
		c-0.27,5.76-3.81,6.18-7.85,5.54c-0.42,1.02-0.74,1.79-0.99,2.42c2.81,1.16,5.35,2.2,7.97,3.28c-0.66,1.05-1.03,1.63-1.48,2.34
		c-6.91-3.5-7.59-3.26-11,2.65c-0.47,0.82-2.3,1.61-3.15,1.33c-3.72-1.2-6.59-0.51-8.85,3.64c-0.47-1.78-0.76-2.86-1.04-3.91
		c-1.17,0.51-2.33,1.03-3.77,1.66c0.93,1.01,1.79,1.94,2.38,2.58c3.31,0,7.05-1.14,9,0.25c3.4,2.42,6.03,1.68,9.09,0.34
		c2.78-1.21,5.67-2.27,8.3-3.76c7.98-4.54,16.23-8.81,22.07-16.23c1.75-2.23,3.08-4.8,4.71-7.39c-3.29-1.24-4.45,0.36-5.26,2.31
		C146.47,706.44,145.07,707.36,142.7,707.19z M128.82,717.45c0.52-1.38,0.75-3.76,1.67-4.05c1.96-0.63,4.26-0.19,6.43-0.19
		c0.06,0.49,0.12,0.99,0.19,1.48C134.57,715.53,132.04,716.37,128.82,717.45z"
          />
          <path
            className="st16"
            d="M756,367.43L756,367.43c-0.92,0.5-1.78,0.71-2.58,0.67c-1.34-0.06-2.51-0.82-3.52-2.09
		c-1.65-2.07-3.15-4.26-4.72-6.4c-0.2,0.03-0.39,0.06-0.59,0.11c0,0,0,0,0,0c-1.92,5.91-3.85,11.81-5.77,17.72
		c1.86,1.22,2.59,2.51,1.63,4.94c-1.16,2.92-3.01,3.92-5.88,3.71c-1.21,2.85-2.42,5.69-3.63,8.54c0.11,0.05,0.22,0.09,0.33,0.11
		c0.11,0.02,0.23,0.03,0.35,0.02c1.63-0.56,3.27-1.11,4.9-1.67c0.19-0.4,0.38-0.81,0.56-1.21c0.81,1.45,1.62,2.89,2.43,4.34
		c0.47,0.19,0.93,0.38,1.4,0.56c1.39,0.54,2.64,0.68,3.81,0.54c0.31-0.04,0.61-0.1,0.91-0.17c0.86-0.22,1.68-0.59,2.47-1.07
		c1.25-0.76,2.43-1.8,3.61-2.92c-1.7-1.85-3.41-3.71-5.47-5.95c0.93-0.11,1.53-0.18,2.04-0.24c0.17-0.02,0.33-0.04,0.5-0.06
		C751.19,380.43,753.6,373.93,756,367.43z"
          />
          <path
            className="st3"
            d="M920.75,364.72c1.24,1.36,2.42,2.41,3.54,3.14c2.46,1.62,4.62,1.71,6.55,0.29c-0.17-0.29-0.45-0.56-0.5-0.87
		c-2.15-14.04-3.75-28.12-2.99-42.36c0.06-1.18,1.04-2.32,1.59-3.48c4.57,2.36,6.06,8.17,3.72,11.71c-0.78-2.87-1.62-5.93-2.46-8.99
		c-0.37,0.06-0.74,0.13-1.1,0.19c0.64,10.49,1.28,20.97,1.92,31.46c0.38-0.02,0.76-0.04,1.14-0.06c1.75-13.64,7.16-26.76,5.81-39.92
		c-4.15,0.07-8.15,0.14-12.14,0.21c-0.47,13.74-0.77,27.5-1.56,41.22C924.11,359.82,921.97,362.25,920.75,364.72z"
          />
          <path
            className="st12"
            d="M348.22,644.25c2.04,0.77,4.83,1.09,6.76,0.31c3.39-1.35,6.35-3.74,9.54-5.61c3.67-2.16,7.39-4.23,11.15-6.38
		c-7.14-7.82-12.83-8.89-21.99-4.27c-5.61,2.83-11.16,5.74-16.74,8.64c-0.33,0.17-0.6,0.49-1.62,1.35c2.19-0.31,3.45-0.49,5.05-0.72
		c-0.68,0.76-1.08,1.2-1.51,1.68C342.11,641.03,345.01,643.04,348.22,644.25z"
          />
          <path
            className="st12"
            d="M790.3,335.21c1.37,2.46,2.74,4.92,4.11,7.38c-0.41,0.33-0.82,0.66-1.23,0.99c-1.64-1.9-3.24-3.84-4.94-5.69
		c-1.46-1.59-0.34-2.37,0.84-3.21c-1.68-1.27-3.43-2.05-5.25-2.3c-1.82-0.25-3.71,0.03-5.67,0.88c0.52,2.35,1.04,4.7,1.57,7.05
		c1.45,0.44,2.9,0.88,4.34,1.31c0,0-0.05-0.06-0.05-0.06c0,0,0.01-0.01,0.01-0.01c-1.12-2.14-2.25-4.28-3.73-7.08
		c4.77,1.51,6.03,4.7,3.73,7.08c0.01,0.01,0.02,0.03,0.02,0.04c1.58,1.42,3.27,2.74,4.71,4.3c0.6,0.65,1.13,1.37,1.63,2.11
		c0.5,0.74,0.99,1.5,1.51,2.23c5.49,7.41,7.34,16,7.78,24.93c0.3,6.12,0.06,12.28,0.06,18.68c0.3,0.19,1.05,0.67,2.7,1.72
		c0.32-4.76,0.43-8.95,0.92-13.11c1.16-9.91,1.01-19.74-1.52-29.42c-0.62-2.38-1.38-4.73-2.18-7.07c-0.8-2.34-1.63-4.67-2.4-7.02
		C794.94,337.71,792.62,336.46,790.3,335.21z"
          />
          <path
            className="st5"
            d="M46.7,760.15c-7.18,1.76-13.17,5.76-19,10.05c-2.43,1.79-3.1,3.79-1.37,6.72c2.26,3.8,4.67,5.93,7.51,4.95
		c5.46-1.89,10.71-4.45,16.22-6.16c4.86-1.51,6.99-4.75,7.79-9.37c-2.27,0.24-3.97,0.23-5.37-0.17c-0.47-0.13-0.9-0.31-1.31-0.54
		C49.52,764.72,48.25,763.01,46.7,760.15z"
          />
          <path
            className="st3"
            d="M1080.12,524.48c-2.56-5.2-4.81-9.78-6.95-14.13c-0.5,0.57-1.53,1.84-2.63,3.03
		c-2.04,2.21-3.99,2.43-5.68-0.41c-0.9,3.95-1.73,7.92-2.72,11.84c-0.16,0.62-0.33,1.25-0.51,1.87c-0.54,1.86-1.15,3.7-1.71,5.55
		l0,0c0.14,0.06,0.28,0.12,0.42,0.19c0.44,0.2,0.88,0.39,1.33,0.57c0.35,0.15,0.71,0.3,1.06,0.46c0.33,0.15,0.65,0.29,0.97,0.45
		c0.33,0.16,0.66,0.34,0.98,0.52c0.38,0.22,0.75,0.44,1.09,0.7c1.69,1.24,2.89,2.99,2.96,5.95c3.55,1.9,7.16,3.84,11.38,6.1
		C1075.84,538.11,1075.98,534.11,1080.12,524.48z"
          />
          <path
            className="st10"
            d="M627.74,217.07C627.74,217.07,627.74,217.07,627.74,217.07C627.74,217.07,627.74,217.07,627.74,217.07
		c1.93,2.17,3.86,4.34,5.79,6.51c3.88-0.26,7.76-0.56,11.64-0.84c3.88-0.28,7.76-0.52,11.65-0.68c9.61-0.39,19.23-0.49,28.85-0.67
		c3.67-0.07,7.33-0.03,11-0.04c1.16,0,2.33,0,3.49,0c-1.38-0.81-2.8-1.33-4.24-1.39c-7.6-0.31-15.21-0.62-22.81-0.73
		c-8.35-0.12-16.7,0.06-25.05-0.1c-1.46-0.03-3.13-0.82-4.3-1.76c-2.47-2-4.6-4.4-6.98-6.51c-3.43-3.03-6.97-5.94-10.46-8.91
		c-3.83,1.45-7.66,2.9-11.49,4.35c2,0.88,3.81,1.98,5.48,3.26C623.1,211.7,625.49,214.29,627.74,217.07z"
          />
          <path
            className="st15"
            d="M866.32,290.71c0.37-3.98,0.64-6.88,0.79-8.56c-1.16-3.42-1.98-5.85-2.75-8.12
		c-7.85,7.64-11.02,35.35,0.73,50.47c0.37-0.58,0.75-1.17,1.13-1.77c0.96-1.5,1.96-3.06,2.96-4.62c0,0,0,0,0,0c0,0,0.01,0,0.01-0.01
		c0-0.01,0-0.02,0.01-0.03c0,0,0,0,0,0c0,0,0,0,0,0l-0.06,0.05c0.49-2.51,1.28-5.02,1.4-7.55c0.01-0.32,0.02-0.63,0.02-0.95
		c-0.03-2.22-0.48-4.45-0.7-6.68C865.64,298.88,864.11,293.72,866.32,290.71z"
          />
          <path
            className="st12"
            d="M916.63,353.67c0.05,2.98,0.69,5.97,2.25,8.58c0.52,0.87,1.14,1.7,1.87,2.48c1.22-2.48,3.36-4.9,3.51-7.45
		c0.79-13.73,1.09-27.48,1.56-41.22c-2.85-1.5-5.7-2.99-8.55-4.49c-0.48,3.57-0.95,7.14-1.43,10.71c0.37,0.51,1.07,1.03,1.06,1.54
		C916.83,333.77,916.47,343.72,916.63,353.67z"
          />
          <path
            className="st3"
            d="M240.38,643.44c-2.03-2.83-4.23-5.68-6.88-7.9c-4.79-4-10.62-1.62-16.4-2.25c0.36,5.01,0.69,9.58,1.03,14.46
		c5.36,2.97,10.88,6.03,16.9,9.36c1.81-3.42,3.62-6.53,5.1-9.78C240.65,646.2,240.98,644.28,240.38,643.44z"
          />
          <path
            className="st12"
            d="M414.09,625.08c-4.2-2.6-8.22-5.08-12.36-7.64c-6.47,5.44-15.25,7.64-19.95,14.31
		c4.84,2.4,9.55,4.74,15.05,7.46c5.86-2.99,12.46-6.36,19.77-10.1c-3.06-0.95-4.88-1.51-6.7-2.08c0.03-0.31,0.07-0.62,0.1-0.93
		C411.31,625.78,412.62,625.45,414.09,625.08z"
          />
          <path
            className="st12"
            d="M920.07,257.09c-4.53-7.49-11.69-11.97-18.98-16.17c-5.45-3.13-11.18-5.77-16.98-8.72
		c2.59,0.46,5,0.9,7.4,1.33c-5.92-3.69-12.4-5.52-19.53-5.68c9.46,6.33,19.86,11.39,27.01,20.55c0.41-1.67,0.77-3.16,1.39-5.69
		c1.17,2.21,2.04,3.44,2.5,4.81c0.63,1.86,1.12,3.76,1.62,5.66c0.17,0.63,0.33,1.27,0.51,1.9l0,0c0,0,0,0,0,0
		c1.77,1.37,3.21,2.93,4.41,4.63c0.24,0.34,0.47,0.68,0.7,1.03c0.45,0.7,0.86,1.42,1.25,2.15c1.55,2.94,2.66,6.14,3.75,9.35
		c0.94-0.25,1.87-0.49,2.82-0.74c0.94-0.25,1.89-0.5,2.86-0.75c-1.14-2.22-2-3.96-2.58-5.38c-0.29-0.71-0.52-1.34-0.67-1.91
		c-0.08-0.28-0.14-0.55-0.19-0.81c-0.09-0.51-0.11-0.97-0.07-1.4c0.04-0.43,0.15-0.83,0.32-1.21c0.17-0.38,0.41-0.76,0.71-1.14
		c0.15-0.19,0.32-0.38,0.5-0.58C919.16,257.94,919.59,257.53,920.07,257.09z"
          />
          <path
            className="st4"
            d="M204.87,678.67c-11.82-0.81-24.85,1.32-29.14-14.53c-4.71-1.86-9.94-3.38-14.61-5.94
		c-3.98-2.19-6.85-2.17-9.03,0.77c17.48,11.17,35.09,22.43,52.77,33.74C204.87,688.16,204.87,683.54,204.87,678.67z"
          />
          <path
            className="st3"
            d="M249.39,604.07c-0.11,0-0.22,0-0.34,0c-0.8-0.03-1.67-0.19-2.27,0.14c-2.55,1.38-4.83,3.4-7.5,4.42
		c-6.69,2.56-12.98,5.63-18.13,11.03c7.03,0.3,13.81,1.36,19.45,5.81c0.77,0.61,1.53,1.25,2.27,1.89c2.23,1.95,4.36,4.02,6.57,6l0,0
		C249.43,623.6,249.41,613.83,249.39,604.07z"
          />
          <path
            className="st12"
            d="M761.18,361.07c1.4,0.04,2.8,0.08,4.2,0.12c2.67,0.08,5.38,0.52,7.99,0.16c4.25-0.6,7.3,0.81,8.65,4.94
		c1.17,3.59,1.82,7.35,3.14,10.87c1.7,4.51,0.98,8.78-0.43,13.11c2.84,0.01,5.68,0.02,8.52,0.03c0.65-1.4,1.3-2.8,1.94-4.2
		c0-0.44,0-0.87,0-1.31c-0.4-1.72-0.8-3.44-1.21-5.16c-1.91,1.56-3.81,3.12-5.72,4.69c-0.27-0.27-0.54-0.54-0.8-0.81
		c1.96-1.95,3.91-3.91,5.87-5.86c-0.25-0.99-0.51-1.97-0.76-2.96c-1.47,1.06-2.95,2.13-4.42,3.19c-0.25-0.32-0.51-0.63-0.76-0.95
		c1.51-1.67,3.02-3.34,4.53-5c-0.86-8.89-6.3-14.48-14.59-17.71c-4.9-1.91-8.4,0.64-12.52,1.19
		C763.6,357.29,762.39,359.18,761.18,361.07L761.18,361.07z M791.29,385.01c0.24-0.2,0.62-0.23,0.95-0.34
		c0.11,0.22,0.21,0.44,0.32,0.66c-0.38,0.16-0.76,0.32-1.14,0.48C791.36,385.53,791.18,385.1,791.29,385.01z M788.3,387.26
		C788.3,387.26,788.3,387.26,788.3,387.26c0.62-0.48,1.24-0.97,1.85-1.45c0.24,0.27,0.47,0.54,0.71,0.8
		c-0.54,0.41-1.08,0.82-1.63,1.23c-0.55,0.2-1.09,0.41-1.64,0.61c-0.08-0.15-0.16-0.3-0.24-0.44
		C787.68,387.77,788,387.51,788.3,387.26z"
          />
          <path
            className="st3"
            d="M400.41,836.75c2.74-0.54,5.67-0.09,8.67-0.09c0-3.93,0-7.75,0-11.96c-0.64,2.07-1.18,3.79-1.74,5.59
		c-10.05,0.6-19.34-0.83-28.43-5.66c0.67,10.28,1.3,19.93,1.96,30.04c1.04-1.68,1.74-3.03,2.65-4.23
		C388.02,844.49,392.46,838.31,400.41,836.75z"
          />
          <path
            className="st12"
            d="M320.5,629.75c4.64,2.39,8.45,4.36,12.14,6.26c8.49-4.41,16.93-8.81,25.76-13.4
		c-3.53-3.06-7.11-6.16-10.68-9.26C338.3,619.02,329.59,624.27,320.5,629.75z"
          />
          <path
            className="st14"
            d="M808.75,467.82c-1.46-0.36-2.84-1.06-4.25-1.6c0.09-0.61,0.18-1.21,0.26-1.82c2.12-0.32,4.24-0.65,6.92-1.06
		c0.35-3.69,0.74-7.9,1.13-12.12c-1.68,5.62-4.46,9.17-8.46,9.37c-5.39,0.26-6.53-4.55-9.23-8.91c-0.43,4.63-0.74,8.4-1.13,12.16
		c-0.41,3.97-0.96,7.93-1.29,11.9c-0.09,1.1,0.01,2.54,0.64,3.34c2.82,3.55,6.57,5.67,10.88,6.64c-0.46-2.76-0.87-5.24-1.29-7.81
		c-1.28-1.64-4.97-1.32-4.41-5.06c1.51,0.49,2.82,0.92,4.13,1.34c0.23-3.54,2.39-4.41,7.06-2.83c2.8,0.59,4.5,1.82,5.47,3.47
		c1.25,2.12,1.29,4.95,0.94,8.02c0.96-1.62,1.69-3.16,2.37-4.73c1.64-3.83,0.02-7.94-3.87-9.25
		C812.77,468.24,810.68,468.29,808.75,467.82z"
          />
          <path
            className="st5"
            d="M442.27,793.26c-8.57-5.2-15.89-11.04-25.34-14.43c3,10.62,5.64,20.65-1.95,30.16
		c0.53,0.37,1.06,0.75,1.58,1.12C424.35,803.7,434.34,800.78,442.27,793.26z"
          />
          <path
            className="st12"
            d="M557.82,652.05c-9.34-4.86-18.63-9.8-28-14.6c-1.4-0.72-3.12-0.81-4.7-1.19c4.72,3.22,9.43,6.45,14.15,9.67
		c-0.24,0.46-0.47,0.91-0.71,1.37c-2.3-0.83-4.66-1.53-6.87-2.54c-2.32-1.05-4.49-2.43-6.75-3.61c-2.08-1.09-4.2-2.1-6.3-3.15
		c-0.2,0.4-0.41,0.8-0.61,1.2c4.81,3.07,10.48,5.07,14.59,10.68c-2.15-0.39-3.12-0.56-4.09-0.74c5.59,4.06,11.26,7.7,17.05,11.16
		c1.08,0.64,2.88,1.12,3.88,0.66c3.37-1.55,6.65-3.31,9.93-5.07c1.09-0.58,2.19-1.17,3.29-1.75
		C561.06,653.46,559.39,652.86,557.82,652.05z"
          />
          <path
            className="st12"
            d="M365.87,648.96c0.39,0.03,0.79,0.05,1.18,0.08c0.3,1.6,0.59,3.2,0.98,5.28c8.51-4.59,16.83-9.08,25.51-13.76
		c-2.05-1.42-3.79-3.21-5.91-3.95c-3.17-1.12-7.32-3.05-9.79-1.91c-8.01,3.71-15.44,8.67-23.28,13.27c3.52,1.73,6.69,3.28,9.73,4.77
		C364.89,651.3,365.38,650.13,365.87,648.96z"
          />
          <path
            className="st3"
            d="M227.9,487.7c-0.66,0.48-1.29,1.51-1.31,2.31c-0.13,4.41-0.06,8.83-0.06,13.25c0.35,0.04,0.7,0.09,1.04,0.13
		c0.93-4.89,1.85-9.78,2.74-14.48c4.81-0.41,6.98,0.81,7.57,4.67c1.62,10.53,3.42,21.08,4.24,31.69c0.54,7-0.52,14.13-0.86,21.27
		c1.07,0.12,2.91,0.33,5.75,0.65c-1.77-22.89-3.52-45.65-5.34-69.13C236.64,481.56,232.22,484.57,227.9,487.7z"
          />
          <path
            className="st5"
            d="M996.02,511.85c-0.14,4.96-0.56,9.92-0.65,14.89c-0.15,8.3-3.28,12.08-11.32,13.48
		c-2.34,0.41-4.55,1.11-5.04,3.63c1.45,2.98,3.81,2.97,6.26,2.75c0.29-0.2,0.57-0.41,0.86-0.61c0.09,0.14,0.18,0.28,0.27,0.41
		c-0.3,0.16-0.6,0.33-0.9,0.5c0,0,0,0,0,0c-1.43,1.73-2.87,3.45-4.87,5.85c-0.29,0.34-0.58,0.7-0.89,1.07
		c2.7-0.3,4.85,0.1,6.04-0.78c3.19-2.36,6.37-4.96,8.81-8.05c4.75-5.99,6.64-13.19,7.22-20.75c0-5.15,0-10.3,0-15.93
		c-0.95-0.56-2.44-1.45-3.87-2.3c-0.98,0.94-1.81,1.74-2.64,2.54l0,0C995.56,509.66,996.06,510.77,996.02,511.85z"
          />
          <path
            className="st3"
            d="M88.41,695.61c2.2,0.15,4.53,0.31,7.25,0.49c-4.65-13.4-8.79-25.48,2.29-37.08c0.93,4.6,1.83,9.07,2.74,13.53
		c2.33,0,4.54,0,7.43,0c-1.21-4.5-2.11-8.72-3.51-12.77c-1.19-3.45-3.93-5.86-7.59-6c-3.4-0.13-6.14,1.94-7.42,5.13
		c-2.77,6.9-4.37,14-3.08,21.56C87.37,685.41,87.78,690.41,88.41,695.61z"
          />
          <path
            className="st8"
            d="M980.43,456.65c1.74-7.41,2.54-15.1,7.88-22.3c-3.84-0.76-6.92-1.37-9.96-1.97c-0.16,0.76-0.34,1.62-0.59,2.79
		c-0.98-0.51-1.65-0.87-1.68-0.88c-1.69,0.6-2.9,1.02-4.15,1.46c-1.07,8.08-2.14,16.18-3.27,24.74c1.47,0.36,2.58,0.64,3.69,0.91
		C978,462.78,979.11,462.25,980.43,456.65z"
          />
          <path
            className="st3"
            d="M227.49,554.55c5.52-0.35,8-3.94,9.78-8.22c3.1-7.49,3.54-15.2,2.23-23.2c-1.5-9.11-2.36-18.33-3.79-27.46
		c-0.3-1.9-0.28-4.78-3.84-4.9c-2.51,5.04,0.96,12.17-4.87,15.5c0,2.99,0,5.5,0,8.01c0.39,0.22,0.77,0.45,1.16,0.67
		c1.71-1.67,3.42-3.34,5.15-5.03c1.42,1.29,1.3,2.1-1.49,5.97c-1.58-0.07-3.3-0.15-5.15-0.23c0,1.18,0,2.59,0,4.07
		c1.49-0.34,2.9-1.08,4.18-0.89c5.85,0.84,9.59,6.52,8.52,12.43c-1.36,7.52-3.14,14.69-11.88,16.56
		C227.49,550.32,227.49,552.38,227.49,554.55z"
          />
          <path
            className="st7"
            d="M760.27,139.19c-7.4,0.12-14.58,1.24-21.79,3.19c-8.72,2.36-15.46,8.32-17.19,16.43
		c9.72-2.77,19.79-5.63,30.51-8.68c1.01-1.1,2.66-2.88,4.31-4.67c-0.77-0.92-1.53-1.85-2.57-3.09
		C756.12,141.14,758.19,140.16,760.27,139.19z"
          />
          <path
            className="st16"
            d="M709.25,437.33c0.54,0.17,1.09,0.28,1.63,0.28c0.99-0.01,1.96-1.22,2.94-1.89c-1.44-2.48-3.63-4.83-4.13-7.49
		c-0.71-3.8-0.22-7.83-0.12-11.76c0.01-0.54,0.7-1.06,1.65-2.41c0.95,7.8,1.8,14.71,2.64,21.61c0.7-0.29,1.41-0.58,2.11-0.87
		c-0.31-1.54-0.66-3.07-0.99-4.6c-0.23-1.05-0.44-2.1-0.63-3.15c-0.15-0.86-0.28-1.73-0.37-2.6c-0.23-2.15,0.39-4.38,0.63-6.58
		c0.46-0.04,0.91-0.08,1.37-0.12c0.49,5.25,0.98,10.49,1.47,15.74h0l0,0c1.65,0.68,3.29,1.36,4.94,2.04
		c-1.14-2.09-1.98-4.28-2.47-6.5c-0.08-0.37-0.15-0.74-0.22-1.11c-0.19-1.11-0.28-2.22-0.29-3.32c0-0.37,0.01-0.73,0.03-1.09
		c0.04-0.73,0.12-1.45,0.25-2.16c0.19-1.06,0.48-2.11,0.88-3.11c0.9,6.43,1.67,11.86,2.43,17.29c1.45-1.17,2.9-2.34,4.35-3.51
		c-1.17-3.32-2.33-6.65-3.5-9.97c0.38-0.16,0.76-0.32,1.13-0.47c0.47,0.65,0.95,1.29,1.31,1.78c0.7-2.36,1.41-4.77,2.4-8.1
		c-8.24-0.6-16.08-1.17-25.16-1.83c0.27,2.1,0.54,4.12,0.79,6.1c0.27,2.09,0.53,4.11,0.79,6.1c0.47,3.65,0.93,7.17,1.39,10.69
		c0,0,0,0,0,0c0.54,0.18,1.09,0.41,1.64,0.62C708.53,437.08,708.89,437.22,709.25,437.33z"
          />
          <path
            className="st3"
            d="M1096.52,540.62c-0.33-1.99-1.36-4.19,2.03-5.2c-3.4-3.85-6.38-7.5-9.65-10.87c-4.25-4.37-7.58-9.23-8.7-15.29
		c-1.03-5.57,0.48-10.73,3.06-16.71c1.36,1.62,2.26,2.42,2.83,3.42c1.61,2.84,2.94,5.84,4.64,8.61c1.43,2.34,4.18,3.73,6.78,3.79
		c0.87,0.02,1.72-0.1,2.5-0.39c-1.43-0.12-2.94-0.79-4.28-1.78c-2.25-1.64-4.05-4.17-4.31-6.51c-4.76-4.33-7.43-9.71-8.05-16.11
		c-0.64,0.71-1.28,1.41-1.92,2.12c0,0,0,0,0,0c0,0,0,0,0,0c-1.15,5.57-2.51,11.1-3.4,16.71c-0.94,5.93-0.73,11.7,3.82,16.52
		c5.41,5.72,9.79,12.09,12.62,19.58c2.81,7.43,3.1,15,1.02,22.36c-0.24,0.87-0.52,1.73-0.82,2.58c-2.09,5.92-5.49,11.32-9.78,16.08
		c1.02,0.07,2.06,0.13,3.54,0.23c2.38,7.15,1.55,15.41,7.13,22.3c1-0.54,2.88-0.94,3.24-1.91c0.4-1.11-0.3-2.85-0.99-4.06
		c-0.74-1.29-2.05-2.25-3.05-3.4c-5.11-5.85-5.77-15-2.24-21.99c2.86-5.66,7-11.05,5.97-18.03
		C1097.92,548.65,1097.18,544.64,1096.52,540.62z"
          />
          <path
            className="st3"
            d="M1084.81,579.53c0,0,0.06,0.05,0.06,0.06c0.02-0.02,0.03-0.03,0.04-0.05
		C1084.88,579.53,1084.85,579.53,1084.81,579.53C1084.81,579.53,1084.81,579.53,1084.81,579.53z"
          />
          <path
            className="st12"
            d="M406.09,440.62c0.65,4.57,1.43,8.8,3.2,12.57c2.68,5.72,7.29,7.59,12.25,6.4c9.6-2.32,11.51-12.54,6.55-20.67
		c-6.03,0.95-11.89,1.87-17.28,2.72c4.12,4.05,10.82,0.2,16.57,3.15C419.22,447.89,411.99,449.11,406.09,440.62z"
          />
          <path
            className="st3"
            d="M99.73,576.98c2.08-12.02,4.27-24.02,6.42-36.03c-4.78,2.98-9.62,5.88-14.29,9.02
		c-0.75,0.51-1.23,2.16-1.08,3.16c1.2,7.86,2.55,15.69,3.94,23.52c0.59,3.34,1.4,6.64,2.22,10.48c0.53-0.6,0.93-1.06,1.27-1.44
		c0.34-0.38,0.6-0.69,0.87-0.99C99.28,582.12,99.29,579.52,99.73,576.98z"
          />
          <path
            className="st4"
            d="M890.65,208.54c0.32,0.39,0.65,0.77,0.98,1.14c2.35,2.57,5.07,4.52,8.67,5.37c2.23,0.53,4.32,1.69,6.46,2.59
		c8.01,3.34,13.31,10.01,19.33,15.78c0.87,0.83,1.93,1.47,2.9,2.2c-3.18-5.05,0.83-9.57,0.54-14.47c-1.81-0.82-3.65-2.24-5.63-2.47
		c-6.94-0.81-12.42-4.13-17.73-8.47c-3.99-3.26-8.82-5.48-13.27-8.17C892.15,204.21,891.4,206.37,890.65,208.54z"
          />
          <path
            className="st9"
            d="M580.35,490.49c-2.33-0.32-4.16-0.58-5.99-0.84c-0.03,0.28-0.06,0.55-0.09,0.83c0.89,0.23,1.78,0.46,3.62,0.93
		c-3.85,2.49-6.78,4.78-10.05,6.41c-4.3,2.13-8.19,6.01-13.79,4.1c-0.6-0.2-1.61,0.64-2.37,1.1c-1.17,0.7-2.26,1.54-3.43,2.24
		c-4.58,2.73-9.18,5.43-14.25,8.43c2.32,1.19,4.04,2.07,6.04,3.09c18.34-8.41,35.79-17.92,52.51-29.12
		C585.92,481.64,583.51,488.31,580.35,490.49z"
          />
          <path
            className="st3"
            d="M934.36,237.92c2.65,2.12,5.3,4.25,7.95,6.37c0.38,0.32,0.75,0.64,1.13,0.96c0,0,0.1,0.11,0.1,0.11
		c0.77,0.11,1.64,0.03,2.29,0.37c6.03,3.21,12.02,6.5,17.77,9.63c0.98-2.23,1.99-4.53,2.99-6.82c0.27-1.73-0.47-2.43-1.46-2.81
		c-0.33-0.13-0.68-0.22-1.04-0.3c-0.24-0.06-0.47-0.11-0.7-0.16c-0.23-0.06-0.44-0.11-0.64-0.19c-6.72-2.41-12.83-5.61-17.59-11.13
		c-2.92-3.39-6.18-6.49-9.28-9.72C935.38,228.79,934.87,233.36,934.36,237.92z"
          />
          <path
            className="st3"
            d="M280.03,645.7c-5.59-12.14-8.31-24.22-3.9-36.67c-2.26-2.61-4.22-4.89-6.14-7.11
		c-1.73,1.64-3.28,3.1-4.77,4.51c0,5.18-0.03,10.19,0.03,15.2c0.01,0.9,0.17,1.89,0.58,2.67c2.31,4.33,4.71,8.6,7.08,12.9
		c0.26,0.28,0.53,0.57,0.79,0.85c-0.09,0.12-0.18,0.23-0.27,0.35c-0.37-0.23-0.73-0.46-1.1-0.7h0c-1.06-0.99-2.12-1.98-3.16-2.96
		c-1.05-0.98-2.08-1.94-3.09-2.88c0,2.54,0,5.22,0,7.96c0.61-0.32,1.09-0.56,1.07-0.55c2.59,3.23,5.01,6.23,7.4,9.21
		C276.59,647.45,278.22,646.62,280.03,645.7z M270.5,609.48c-2.45,6.57,0.95,11.69,2.97,17.63
		C267.1,622.08,265.4,612.97,270.5,609.48z"
          />
          <path
            className="st5"
            d="M976.35,341.85c-7.44,0.49-12.96-3.86-11.75-8.86c2.76-2.98,5.73-4.84,6.62-7.42
		c1.72-4.99,0.19-14.57-5.34-18.28c-0.21,1.46-0.42,2.92-0.63,4.39l0,0l-0.04-0.05c0,0,0,0,0,0c0,0,0.04,0.04,0.04,0.05
		c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c0.25,0.72,0.5,1.43,0.75,2.15l-0.03-0.04c3.49,6.2,1.11,12.86-6.14,16.73
		c0.64,2.68,0.97,5.65,2.06,8.3c2.12,5.13,8.37,4.29,11.73,7.41c0.39,6.4-0.76,12.19-2.92,17.58c-0.54,1.35-1.15,2.67-1.81,3.97
		c-1.32,2.6-2.86,5.11-4.56,7.57c-0.85,1.23-1.74,2.44-2.65,3.64c-0.96,0.51-1.91,1.02-2.87,1.53c-0.23,1.5-0.65,2.99-0.66,4.49
		c-0.07,9.23-0.04,18.45-0.05,27.68c0.85,0.97,1.7,1.94,2.54,2.92c0.37,0.42,0.74,0.85,1.11,1.27c-0.02-12.63-0.05-25.26-0.07-37.89
		c1.12-0.52,2.62-0.76,3.31-1.63c4.23-5.33,8.28-10.8,12.38-16.22c0.14-3.45-0.87-6.96-0.58-10.4c0.13-1.56,0.53-3.11,1.44-4.63
		C977.57,344.65,976.94,343.19,976.35,341.85z"
          />
          <path
            className="st3"
            d="M154.77,866.14c0.13-0.92,0.26-1.83,0.39-2.74c0.38-2.73,0.76-5.39,1.09-7.78
		c-5.91-1.94-11.54-3.78-17.52-5.74c-3.15,8.27-2.02,16.94,1.2,25.55C144.88,872.33,149.83,869.24,154.77,866.14z"
          />
          <path
            className="st3"
            d="M778.7,121.86c5.19,2.59,9.97,6.02,14.88,9.16c7.83,5.01,15.62,10.07,24.07,15.53c0-2.46-0.16-4.03,0.04-5.55
		c0.31-2.45-0.69-4-2.78-5.15c-4.07-2.24-8.11-4.55-12.14-6.89c-8.46-4.91-16.9-9.85-25.36-14.78c-1.3-0.76-2.62-1.47-3.8-2.13
		C772.05,117.27,774.44,119.74,778.7,121.86z"
          />
          <path
            className="st7"
            d="M668.09,460.53c2.56-2.53,5.11-5.07,8.14-8.08c-3.05-0.75-6.21,0.64-6.82-2.98c2.59-3.85,6.63-2.98,10.89-3.25
		c0.89-2.03,1.87-4.25,2.84-6.47c-1-1.31-1.87-2.65-2.59-4.06c-0.54-1.06-1.01-2.15-1.39-3.28c-0.25-0.75-0.47-1.53-0.65-2.32
		c-0.62-2.78-0.78-5.81-0.41-9.2c-1.36,1.24-2.17,1.62-2.29,2.15c-0.39,1.83-0.41,3.74-0.81,5.57c-1.69,7.6-3.37,15.07-9.14,21.11
		c-2.52,2.64-3.97,6.66-4.97,10.31c-0.79,2.87-0.14,6.14-0.13,9.23c2.37-1.64,4.73-3.27,7.1-4.91
		C665.54,462.95,666.85,461.75,668.09,460.53z"
          />
          <path
            className="st5"
            d="M801.6,314.48c-2.97-1.41-5.94-2.83-8.9-4.25c-0.94-0.45-2.44-0.94-2.6-1.65c-1.11-4.88-5.89-5.44-8.67-8.31
		c-2.05,3.62-3.87,6.83-5.81,10.26c5.77,1.15,9.97,2.91,14.28,6.36c6.41,5.15,14.58,8.1,22,11.99c-0.47-3.39-0.93-6.77-1.4-10.16
		C807.54,317.31,804.57,315.9,801.6,314.48z"
          />
          <path
            className="st4"
            d="M246.5,657.62c0.26-0.4,0.5-0.83,0.74-1.26c0.07-0.14,0.16-0.26,0.23-0.4c1.29-2.55,2.06-5.52,2.25-8.51
		c0-0.07,0.01-0.14,0.02-0.21c0.07-1.21,0.04-2.42-0.09-3.61c-0.33-2.99-1.29-5.82-2.97-8.06c-7.3-9.76-17.73-12.35-29.36-12.23
		c0,2.04,0,3.68,0,5.35c6.77-1.18,13.11-0.07,18.26,4.24c11.32,9.48,10.06,17.81,2.48,25.44c1.8,0.94,3.53,1.85,5.27,2.76
		C244.55,660.19,245.61,658.99,246.5,657.62z"
          />
          <path
            className="st5"
            d="M862.17,244.37c3.29,0.21,6.65,0.11,9.86,0.74c2.91,0.57,6.11,1.35,8.39,3.07c4.22,3.19,7.88,7.13,11.71,10.82
		c0.43,0.41,0.49,1.21,0.72,1.83c0.49,0.56,0.97,1.13,1.46,1.69c0.84,0.87,1.67,1.73,2.51,2.6c2.24,0,4.48,0,6.72-0.01c0,0,0,0,0,0
		c-0.62-1.14-1.15-2.4-1.69-3.64c-0.9-2.06-1.86-4.06-3.42-5.29c-8.2-6.48-17.06-12.12-25.46-18.36c-4.09-3.04-7.95-3.03-11.98-1.02
		C861.41,239.52,861.79,241.94,862.17,244.37z"
          />
          <path
            className="st3"
            d="M819.52,238.88L819.52,238.88c-0.26-0.97-0.51-1.93-0.77-2.9c-0.18,0.03-0.35,0.06-0.53,0.09
		c0.23,1.12,0.46,2.25,0.69,3.37c0.71,3.25,2.78,6.55-0.13,9.25c1.41,4.26,2.71,8.18,4,12.1c1.91-1.46,3.82-2.91,5.74-4.37
		c-0.15-0.92-0.15-1.9-0.48-2.75c-2.41-6.27-2.5-12.8-1.78-19.29c0.93-8.28,3.43-15.9,11.12-19.8c-3.77-2.77-7.52-5.51-11.08-8.13
		c-1.41,10.86-2.88,22.19-4.46,34.4c-0.31-0.26-0.58-0.49-0.81-0.69C820.33,239.56,819.93,239.22,819.52,238.88z"
          />
          <path
            className="st3"
            d="M216.74,608.88c4.58-2.39,8.93-4.87,13.46-6.97c4.53-2.1,9.24-3.82,13.36-5.51
		c-0.59-7.59-1.13-14.46-1.64-21.03c-4.12,1.21-8.57,2.51-13.42,3.93c0.25,1.82,0.57,4.15,0.86,6.31c3.59-0.6,6.38-4.21,10.85-0.58
		c-1.85,0.99-3.15,2.08-4.62,2.4c-5.36,1.19-6.24,1.84-8.12,7.3c2.34-0.78,4.48-1.74,6.71-2.17c1.92-0.37,4.24-3.3,6.19,0.54
		c-7.49,5.42-17.68,4.07-24.71,10.59C216.02,605.42,216.39,607.18,216.74,608.88z"
          />
          <path
            className="st5"
            d="M86.33,753.73c1.97,0.72,4.06,1.19,6.26,1.43c10.33-3.58,16.5-7.42,16.76-10.45
		c-13.34,3.49-25.06,3.57-31.82-11.09c-1.25-0.16-2.36-0.3-3.39-0.44c-1.03-0.13-2-0.26-2.96-0.38c-0.09,0.8-0.17,1.6-0.26,2.4
		c0,0,0,0,0,0l0.01-0.05c0.01-0.03,0.01-0.05,0.01-0.05c0.26,0.51,0.53,1.03,0.79,1.54c-0.05,0.12-0.06,0.23-0.04,0.34
		c0.02,0.11,0.06,0.22,0.14,0.32c1.16,2.83,2.32,5.65,3.48,8.48c0.72,0.73,1.45,1.47,2.17,2.2
		C80.08,750.64,83.05,752.53,86.33,753.73z"
          />
          <path
            className="st8"
            d="M1061.34,480.41c-0.02-2.26,3-5.63,5.48-6.05c0.06,0.94,0.12,1.86,0.18,2.78c0.85-3.24,1.69-6.48,2.53-9.67
		c-1.22-0.52-2.28-0.98-3.26-1.4c-0.49-0.21-0.96-0.41-1.43-0.61c-0.46-0.2-0.91-0.39-1.37-0.59c-0.29,0.53-0.57,1.06-0.86,1.59
		c0.31,4.38,0.65,8.77-4.2,11.33c-1.57-0.51-1.57-1.31-1.29-2.18c0.06-0.19,0.14-0.39,0.22-0.58c0.2-0.49,0.4-0.98,0.39-1.43
		c-1.75,0.7-3.49,1.39-5.24,2.09c-0.43,0.21-0.86,0.42-1.29,0.63c-0.28,0.27-0.56,0.54-0.85,0.81c-0.96,0.76-1.92,1.52-2.98,2.36
		c3.77,7.05,7.43,13.88,11.03,20.61c3.17-4.7,6.35-9.96,6.09-17.15c0.53-0.57,1.49-1.6,2.56-2.74c0-1.01,0-2.08,0-3.15
		c-1,1.49-2,2.97-3.09,4.59C1062.91,481.15,1062.1,480.77,1061.34,480.41z"
          />
          <path
            className="st5"
            d="M942.82,413.48c0.07-1.16,0.14-2.32,0.21-3.42c-5.03-3.09-9.84-6.03-14.28-8.76c-1.21,1.02-2.37,1.99-4.2,3.54
		c0.47,15.69,0.97,32.03,1.46,48.56c1.87,0.96,3.75,1.93,5.62,2.89c-0.48-16.94-0.51-33.93-5.53-51.22
		C932.32,408.2,937.57,410.84,942.82,413.48z"
          />
          <path
            className="st3"
            d="M1103.81,338.95c-6.35,2.44-6.37,3.06-6.55,9.61c-0.21,7.28,0.86,14.59,1.38,21.88
		c1.4,5.22,2.78,10.44,4.21,15.65c0.14,0.52,0.52,0.98,0.79,1.47c-0.19-3.57-0.37-7.14-0.56-10.71
		c-0.56-10.73-1.13-21.55-1.71-32.68c5.25,8.65,5.34,17.96,5.93,27.27C1112.31,358.89,1113.52,350.55,1103.81,338.95z"
          />
          <path
            className="st4"
            d="M614.87,460.42c1.58-3.57,0.27-6.32-3.38-7.51c-3.32-1.08-6.84-2.03-10.29-2.12
		c-5.9-0.15-11.82,0.42-17.73,0.68c-0.14,0.47-0.28,0.93-0.41,1.4c8.91,4.44,17.83,8.88,26.72,13.31c-0.41,1.88-0.83,3.8-1.25,5.71
		C610.68,468.09,613.1,464.4,614.87,460.42z"
          />
          <path
            className="st12"
            d="M764.44,428.32c-1.82,0.29-3.65,0.58-5.64,0.9c0.42,1.9,0.89,4.03,1.46,6.61c6.87-1.34,13.48-2.62,19.86-3.86
		c-1.69-4.96-3.32-9.75-4.97-14.57c-5.36,0.42-11.11,0.87-17.13,1.34c-0.23,2.14-0.47,4.34-0.73,6.72c2.55,0.71,4.79,1.33,7.04,1.95
		C764.37,427.7,764.4,428.01,764.44,428.32z"
          />
          <path
            className="st16"
            d="M899.09,404.45c2.52-0.36,4.92-0.7,7.33-1.04c-0.82-5-1.63-10-2.45-14.99c0.37-0.14,0.74-0.28,1.11-0.42
		c-2.42-1.43-4.84-2.86-7.39-4.36c-0.77,2.27-1.33,3.93-1.88,5.55c1.77,2.08,3.11,3.71,4.07,5.02c2.87,3.94,2.25,5.05-0.88,6.98
		c-1.89-0.7-3.78-1.4-6.08-2.26c-0.77-0.28-1.58-0.59-2.46-0.91c0,1.85-0.31,3.36,0.06,4.67c0.87,3.02,2.29,5.89,3.09,8.92
		c1.15,4.36,3.04,8.13,6,11.12c0.81,0.82,1.69,1.58,2.66,2.28c0.65,0.47,1.33,0.91,2.05,1.32
		C905.01,418.46,901.69,411.54,899.09,404.45z"
          />
          <path
            className="st5"
            d="M64.11,573.3c4.52-2.74,8.83-5.84,13.14-8.9c3.45-2.45,6.78-5.08,10.16-7.63c-0.45-1.16-0.89-2.33-1.43-3.72
		c-3.73,1.99-7.22,3.71-10.57,5.67c-8.94,5.25-17.77,10.68-26.74,15.88c-2.96,1.72-4.33,3.63-4.1,6.35c0.08,0.91,0.33,1.9,0.76,3.01
		C51.61,580.42,57.96,577.02,64.11,573.3z"
          />
          <path
            className="st10"
            d="M587.04,240.97c-3.03,1.74-5.2,2.98-8.26,4.73c0.81,0.4,1.49,0.73,2.08,1.02c0.99,0.48,1.73,0.85,2.48,1.21
		c1.25-0.39,2.51-0.78,3.99-1.25c0.49-0.15,1.02-0.32,1.57-0.49c1.73,5.4,3.16,10.64,5.09,15.69c1.85,4.82,0.26,10.47,3.78,14.86
		c2.51-11.01-1.63-20.94-5.26-31.08c4.98-1.26,9.66-2.44,14.33-3.62c-0.03-0.39-0.07-0.78-0.1-1.17c-0.87-0.55-1.75-1.1-2.62-1.64
		c-3.49,0.21-6.99,0.42-10.57,0.64c-1.19,0.07-2.39,0.14-3.61,0.22c-2.58-4.38-5.3-9.02-8.03-13.66c-1.21,0.7-2.42,1.4-3.62,2.1l0,0
		c0.72,1.02,1.44,2.05,2.16,3.08C582.62,234.68,584.8,237.78,587.04,240.97z"
          />
          <polygon
            className="st10"
            points="583.34,247.93 583.34,247.93 583.34,247.93 	"
          />
          <path
            className="st5"
            d="M743.61,431.84c0.05-0.06,0.09-0.13,0.12-0.21c0.09-0.23,0.12-0.51,0.2-0.75c-2.19-1.72-4.38-3.44-6.57-5.16
		c0.25-0.38,0.5-0.75,0.75-1.13c2.68,1.53,5.37,3.06,8.05,4.59c1.16,0.72,2.32,1.44,3.63,2.25c0.92-0.52,1.8-1.02,2.69-1.52
		c-2.19-1.05-4.38-2.09-6.57-3.14c0.19-0.44,0.38-0.87,0.57-1.31c2.24,0.87,4.49,1.74,6.73,2.61c0,0,0.14-0.05,0.14-0.05
		c0.4,0.14,0.81,0.27,1.21,0.41c-0.17-0.28-0.33-0.56-0.5-0.84c0-3.01,0-6.02,0-9.01c-7.25-0.82-14.35-1.62-21.76-2.46
		c-0.76,2.21-1.42,4.16-2,5.84c2.55,3.06,4.87,5.86,7.2,8.66c-0.4,0.39-0.79,0.78-1.19,1.17c-2.04-1.84-4.08-3.68-6.27-5.66
		c0.78,2.42,1.57,4.86,2.36,7.31C736.54,435.81,740.39,436.01,743.61,431.84z"
          />
          <path
            className="st5"
            d="M1101.21,410.85c4.75,2.63,6.93,1.94,8.97-3.06c0.2-0.49,0.38-0.98,0.55-1.48c0.52-1.5,0.93-3.03,1.42-4.54
		c0,0,0,0,0,0c-0.12-2.55-0.27-5.09-0.4-7.64c-0.13-2.55-0.23-5.1-0.26-7.64c-0.02-2.64,0.53-5.29,0.86-8.24
		c0.97,0.4,1.7,0.7,2.31,0.95c0.61,0.25,1.1,0.45,1.59,0.65c2.13-4.2,0.35-8.16-0.92-12.09c-0.17-0.54-1.56-0.68-2.39-1.01
		c-1.24,3.16-2.55,6.3-3.69,9.5c-0.84,2.37-2.21,4.88-2.02,7.22c0.35,4.35-0.19,8.43-1.27,12.35c-1.39,5.04-3.68,9.81-6.1,14.57
		c0,0,0,0,0,0C1100.33,410.54,1100.81,410.63,1101.21,410.85z"
          />
          <path
            className="st9"
            d="M500.91,382.59c-0.15,8.85-0.42,17.7-0.65,26.55c3-5.63,2.62-11.9,2.68-17.86c0.1-9.81-0.73-19.64-1.31-29.45
		c-0.34-5.76-0.7-11.54-1.49-17.25c-1.48-10.62-3.14-21.23-5.01-31.79c-0.75-4.24-0.81-8.96-4.95-11.87
		c0.23,2.19,0.46,4.38,0.69,6.57c0.34,2.22,0.68,4.43,1.02,6.65c0.46,3.02,0.93,6.05,1.42,9.06c0.38,2.38,0.87,4.74,1.31,7.11
		c0.16,1.68,0.33,3.37,0.49,5.05v0c0,0-0.06-0.04-0.06-0.04l0,0c0.75,3.49,1.84,6.94,2.2,10.46c0.36,3.5,0.62,7.02,0.85,10.54
		c0.23,3.52,0.43,7.04,0.67,10.55c0,0,0,0,0,0c0,0,0,0,0,0c0.2,1.9,0.4,3.79,0.6,5.69c0.12,1.02,0.24,2.05,0.36,3.07
		c0.13,1.14,0.27,2.29,0.4,3.43v0c0,0-0.12,0-0.12,0h0C500.34,380.25,500.93,381.42,500.91,382.59z"
          />
          <path
            className="st4"
            d="M66.54,747.97c-3.99,0.54-8.07,0.8-12.08,0.61c-2.3-0.11-4.55-1.34-7.25-2.21c1.05,3.98,2.27,7.6,2.9,11.32
		c0.51,2.97,2.26,4.35,4.8,4.8c3.8,0.67,7.68,1.26,11.52-0.8c-0.14-0.85-0.28-1.69-0.3-1.81c3.16-2.55,5.94-4.8,8.72-7.04
		C73.53,748.94,70.14,747.48,66.54,747.97z M69.24,751.91c0.48,1-0.69,2.8-1.12,4.25c-0.56,0.02-1.12,0.04-1.68,0.06
		c-0.93-1.89-1.87-3.79-2.98-6.04C66.53,748.75,68.37,750.11,69.24,751.91z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("click");
              }}
              className={classNameDoor}
              d="M337.15,446.02c-2.13,0.67-4.12,1.29-6.64,2.08c-5.01,6.91-10.53,14.5-16.04,22.1c0.2,0.19,0.4,0.38,0.59,0.58
		c12.12-7.5,24.24-14.99,36.52-22.6C346.36,447.4,341.61,446.68,337.15,446.02z"
            />
          </Link>
          <path
            className="st16"
            d="M680.31,446.22c-4.26,0.27-8.3-0.6-10.89,3.25c0.61,3.61,3.77,2.22,6.82,2.98c-3.03,3.01-5.58,5.55-8.14,8.08
		c-1.24,1.23-2.56,2.42-0.21,3.83c4.47-1.4,8.95-2.81,13.64-4.28c-0.05-0.08,0.32,0.38,0.55,0.91c0.23,0.54,0.3,1.14,0.44,1.71
		c0.4-0.24,0.8-0.49,1.21-0.73c0.45-1.41,0.8-2.86,1.39-4.2c0.42-0.96,0.89-1.91,1.36-2.85s0.97-1.87,1.44-2.81v0
		c-0.05-0.4-0.13-0.8-0.2-1.2c-0.23-1.2-0.5-2.4-0.38-3.56c0.18-1.79-0.06-3.31-0.75-4.56c-0.34-0.63-0.8-1.19-1.37-1.7
		c-0.57-0.5-1.25-0.95-2.05-1.33l0,0C682.18,441.97,681.21,444.19,680.31,446.22z"
          />
          <path
            className="st3"
            d="M409.91,546.06c0.73,4.38,1.37,8.77,2.23,13.13c1.09,5.48,3.87,7.28,9.43,6.54c-1-4.28-2.4-8.54-2.93-12.91
		c-0.63-5.08-1.15-10.17-1.64-15.26c-0.49-5.09-0.95-10.19-1.45-15.29C413.66,530.2,411.79,538.13,409.91,546.06z"
          />
          <path
            className="st11"
            d="M455.3,485.5L455.3,485.5c-1.85,1.06-3.7,2.12-5.8,3.33c-0.7,0.4-1.43,0.82-2.19,1.26
		c-0.17-0.66-0.33-1.27-0.48-1.86c-0.45-1.75-0.83-3.22-1.21-4.69l0,0c0,0,0,0,0,0c-1.54,0.12-2.19-0.47-2.47-1.32
		c-0.22-0.66-0.21-1.48-0.23-2.24c-0.1-3.55-0.07-7.11-0.09-10.67c-1.37-0.73-2.74-1.46-4.11-2.19c0.05,1.7,0.1,3.39,0.15,5.09
		c0.06,1.8,0.11,3.6,0.17,5.4c0.1,3.5,0.19,6.99,0.23,10.49c0.03,2.66,0.5,5.98-0.84,7.85c-2.23,3.12-0.21,5.03,0.09,7.84
		c1.75-1.46,2.97-2.6,4.3-3.57c5.28-3.85,10.59-7.64,15.89-11.46C457.59,487.67,456.45,486.58,455.3,485.5L455.3,485.5z"
          />
          <path
            className="st3"
            d="M174.65,658.3c-2.85-2.01-5.7-4.03-8.55-6.04c4.77,0.18,8.21,4.17,13.53,3.19c-1.37-1.98-2.25-3.26-3.59-5.19
		c2.93,1.8,5.15,3.16,7.9,4.84c0.66-6.83,1.82-12.33-0.81-17.88c-8.74,6.13-17.06,11.97-25.52,17.91
		c5.88,1.45,11.37,2.79,16.85,4.14C174.52,658.94,174.58,658.62,174.65,658.3z"
          />
          <path
            className="st14"
            d="M718.09,461.38c-0.7,0.23-1.41,0.47-2.11,0.7c-0.17,0.49-0.33,0.99-0.53,1.55c-0.19,0.56-0.41,1.2-0.67,1.97
		c-1.18-1.31-1.87-2.06-2.55-2.82c-0.41,0-0.82,0-1.22,0v0h0l0,0c-0.3,0.61-0.6,1.21-0.91,1.84s-0.63,1.28-0.98,1.98
		c-1.02-0.66-1.79-1.15-2.56-1.64c-2.12,0.47-4.24,0.94-6.36,1.41c1.45,3.13,2.89,6.26,4.34,9.4c2.36,0.22,4.73,0.45,7.09,0.67
		c1.16,0.43,2.31,0.85,3.47,1.28c2.9,1.56,3.09,3.26,0.74,6.69l0,0c0.25,0.2,0.49,0.39,0.73,0.57c0.26,0.2,0.51,0.4,0.72,0.57
		c0.14,0.11,0.27,0.21,0.38,0.3c1.19-3.1,2.33-6.27,3.61-9.39C723.2,471.79,721.69,464.48,718.09,461.38z"
          />
          <path
            className="st3"
            d="M209.05,662.61c0,8.44,0,16.94,0,26.29c6.83-4.96,12.95-9.41,19.35-14.05
		C221.58,670.53,215.17,666.47,209.05,662.61z"
          />
          <path
            className="st3"
            d="M132.05,681.33c-4.92,1.56-6.09,2.42-7.15,6.93c-1.44,6.1-2.54,12.29-3.62,18.47c-0.14,0.8,0.71,1.78,1.1,2.67
		c1.47-2.1,2.62-3.74,3.75-5.35c0.6-4.7-0.19-9.78,3.81-14.71c1.59,1.01,3.68,1.65,4.54,3.05c1.93,3.12,2.51,6.68,1.18,10.22
		c-0.35,0.08-0.71,0.16-1.06,0.24c-0.96-3.38-1.92-6.77-2.88-10.15c-0.52,0-1.04-0.01-1.56-0.01c-0.68,3.8-1.35,7.6-2.22,12.47
		c4.74-1.02,8.55-1.84,12.38-2.67C139.24,694.77,139.38,686.55,132.05,681.33z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M369.81,536.3c4.7-1.83,8.8-3.43,12.61-4.91c-0.74-6.45-1.44-12.51-2.18-18.9c-4.79,2.8-9.55,5.57-14.66,8.56
		C366.94,525.96,368.31,530.89,369.81,536.3z"
            />
          </Link>
          <path
            className="st7"
            d="M904.85,291.69c0.6,0.8,1.5,1.37,2.26,2.05c-0.56-3.69-1.51-7.18-2.81-10.46c-0.43-1.09-0.9-2.17-1.4-3.21
		s-1.04-2.07-1.61-3.07c-1.71-3-3.73-5.79-5.97-8.35c-2.7,1.48-4.95,2.72-7.36,4.04c0,5.11,0,10.44,0,15.85
		c0.31,0.06,0.61,0.11,0.9,0.17c0.29,0.06,0.58,0.11,0.87,0.16C896.58,284.59,900.74,286.17,904.85,291.69z"
          />
          <path
            className="st16"
            d="M734.65,405.68c-0.42,2.48-0.62,5.01-0.92,7.57c5.27,0.72,10.63,1.35,15.95,2.19
		c4.56,0.73,6.31-0.67,6.33-5.44c0.01-2.01,0-4.01,0-5.61c-5.68-0.87-10.76-1.54-15.79-2.45
		C737.19,401.4,735.19,402.51,734.65,405.68z"
          />
          <path
            className="st15"
            d="M599.77,474.37c-6.77-7.56-13.01-14.53-19.71-22c-2.57,1.28-5.36,2.67-8.15,4.06
		c-0.04,0.4-0.08,0.81-0.12,1.21c3.15,1.29,6.3,2.58,9.45,3.86c0.22,1.06,0.44,2.12,0.68,3.28c-0.26,0.11-0.74,0.31-1.59,0.67
		c1.32,2.26,2.57,4.42,3.9,6.7c-1.07,1.01-2.03,1.92-3,2.83c1.19,0.88,2.29,2.19,3.61,2.48c1.96,0.43,4.09,0.11,6.15,0.11
		c-0.04-2.28,0.04-4.58-0.17-6.84c-0.09-0.98-0.76-1.91-1.17-2.87C593.28,469.51,593.56,475.21,599.77,474.37z M581.34,461.53
		c0,0-0.09-0.08-0.09-0.08c0.02-0.02,0.03-0.04,0.05-0.06c-0.26-0.91-0.53-1.85-1.01-3.52c1.46,0.42,2.28,0.66,3,0.87
		c-0.76,1-1.37,1.82-1.99,2.64C581.31,461.43,581.32,461.48,581.34,461.53z M585.39,467.8c-1.1-0.71-2.21-1.41-3.37-2.16
		c0.68-0.74,1.1-1.19,1.85-1.99c0.77,1.07,1.55,2.16,2.34,3.25C585.93,467.2,585.66,467.5,585.39,467.8z M587.2,464.59
		c-0.36-0.57-0.73-1.13-1.09-1.7c0.22-0.14,0.44-0.29,0.66-0.43c0.39,0.55,0.78,1.1,1.16,1.65
		C587.69,464.27,587.45,464.43,587.2,464.59z M589.12,467.38c-0.27-0.59-0.53-1.17-0.8-1.76c0.31-0.05,0.63-0.1,0.94-0.15
		c0.17,0.54,0.34,1.08,0.52,1.63C589.56,467.19,589.34,467.29,589.12,467.38z"
          />
          <path
            className="st15"
            d="M581.24,474.98c0,0,0.01-0.01,0.01-0.01c-0.02-0.02-0.05-0.04-0.07-0.05L581.24,474.98z"
          />
          <path
            className="st8"
            d="M519.49,538.01c0-1.37,0-2.74,0-3.88c-5.83-0.46-11.31-0.89-17.01-1.33c0,6.75,0,13.85,0,20.94
		c5.15-3.96,10.3-7.92,15.51-11.93C518.42,540.71,518.96,539.36,519.49,538.01z"
          />
          <path
            className="st13"
            d="M488.83,518.42c1.05,1.84,1.54,3.99,2.86,4.79c3.19,1.93,6.66,3.54,10.2,4.73c7.85,2.66,15.95,1.25,23.9,0.65
		c4.35-0.33,8.6-1.91,12.86-2.92c0.14-1.21,0.27-1.9,0.28-2.6c0.01-0.69-0.12-1.38-0.19-2.08
		C521.95,526.29,505.64,525.54,488.83,518.42z"
          />
          <path
            className="st11"
            d="M741.71,345.27c-2.91-1.9-5.81-3.8-9.45-6.18c-2.16,0.31-5.26,0.75-8.36,1.19c-0.73,0.72-1.46,1.44-2.19,2.15
		c-1.87,2.66-3.34,5.46-4.48,8.37c-1.91,4.84-2.93,9.99-3.47,15.29c1.14-2.7,2.09-5.5,3.45-8.08c3.45-6.57,8.93-9.94,14.48-9.08
		c1.43,0.22,2.86,0.47,4.28,0.72c1.42,0.25,2.85,0.51,4.27,0.76C740.73,348.7,741.22,346.98,741.71,345.27z"
          />
          <path
            className="st16"
            d="M730.67,412.33c0.78-3.08,1.68-6.61,2.63-10.37c-9.42-5.54-22.67-1.82-25.49,6.33
		C715.08,409.58,722.43,410.88,730.67,412.33z"
          />
          <path
            className="st3"
            d="M218.32,650.74c-0.49,1.97-0.93,3.78-1.48,6.02c-4.92-3.78-2.21-9.03-3.64-13.03
		c-3.98-0.52-7.74-1.01-11.75-1.53c0,0.63-0.12,1.23,0.02,1.77c0.42,1.68,0.91,3.35,1.43,5c1.27,4.06,0.7,5.43-3.04,7.15
		c0.07,0.97,0.14,1.94,0.22,2.91c1.09-0.08,2.17-0.17,3.26-0.25c7.62-0.58,15.3-1.17,23.52-1.8
		C223.54,654.56,220.92,652.64,218.32,650.74z"
          />
          <path
            className="st5"
            d="M1061.63,526.68c0.18-0.62,0.35-1.24,0.51-1.87c0.98-3.93,1.82-7.89,2.72-11.84
		c-0.21-3.07-0.43-6.14-0.64-9.21c-0.53-0.77-1.06-1.54-1.59-2.32c-0.7,1.14-1.46,2.25-2.19,3.37c-0.73,1.13-1.41,2.27-1.96,3.48
		c-2.36,5.18-4.43,10.5-6.63,15.76c-0.62,1.48-1.24,2.96-2.03,4.83c0.89,0.29,1.76,0.58,2.61,0.86c1.71,0.57,3.38,1.12,5.03,1.66
		c0.82,0.27,1.65,0.54,2.47,0.82C1060.48,530.38,1061.09,528.54,1061.63,526.68z"
          />
          <path
            className="st12"
            d="M1009.8,415.19c-0.89-3.12-1.89-6.65-2.95-10.39c-1.55,0.65-3.39,2.12-5.11,1.99
		c-3.05-0.22-6.06-0.87-9.08-1.49c-1.01-0.21-2.01-0.41-3.02-0.6c0.24,1.9,0.47,3.8,0.71,5.7c5.86,3.55,7.83,9.52,9.57,15.27
		C1003.18,422.22,1006.54,418.64,1009.8,415.19z"
          />
          <path
            className="st3"
            d="M938.63,293.1c-0.26-1.35-0.4-2.04-0.62-3.21c3.48,1.5,5.32,0.12,6.92-2.46c2.1-3.38,0.75-6.37-0.74-9.3
		c-1.85-3.64-2.61-3.64-6.35-1.86c-2.71,1.29-5.62,2.17-8.43,3.24c0.72,4.94,1.43,9.88,2.15,14.82
		C933.9,293.93,936.25,293.52,938.63,293.1z"
          />
          <path
            className="st12"
            d="M914.95,291.86c0.16,0.11,0.31,0.23,0.47,0.35c1.08,0.87,2.14,2.04,3.28,2.12c4.27,0.28,8.57,0.04,12.86,0.01
		c-0.72-4.94-1.43-9.88-2.15-14.82c-4.28-0.07-8.56-0.14-12.84-0.2C916.02,283.5,915.48,287.68,914.95,291.86z"
          />
          <path
            className="st4"
            d="M843.49,427.88c0.29-2.2,0.39-4.43,0.57-6.64c-0.64,0.7-1.27,1.4-1.91,2.1c-0.42,2.48-0.81,4.97-1.53,7.33
		c-0.2,0.67-0.43,1.33-0.7,1.98c-0.13,0.32-0.27,0.64-0.42,0.96c-0.3,0.63-0.64,1.24-1.02,1.84c-0.77,1.19-1.72,2.29-2.92,3.28
		c-0.88,0.73-2.32,0.77-3.5,1.13c-0.7,0.03-1.41,0.06-2.11,0.09l0,0c-0.84,0.8-1.68,1.6-2.52,2.4c-2.51,2.39-4.97,4.73-7.24,6.9
		c1.58-0.15,3.88-0.36,6.71-0.62c-1.4,4.7-3.53,8.78-0.3,12.77c4.51-1.94,5.41-3.75,4.14-8.3c-0.44-1.57-1.07-3.08-1.56-4.63
		c-0.71-2.23-0.42-3.76,1.99-5.11c3.12-1.74,5.81-4.24,8.76-6.31c0.69-0.48,1.68-0.51,2.28-0.68c0.64,5.33,1.22,10.21,1.81,15.09
		c0.44,0.05,0.88,0.1,1.32,0.14c0.57-0.81,1.24-1.56,1.68-2.43c1.76-3.51-0.08-6.6-1.39-9.66
		C844.02,435.76,842.93,432,843.49,427.88z"
          />
          <path
            className="st16"
            d="M798.36,306.5c4.44,2.25,8.72,4.42,13.79,6.98c0.28-5.98,0.52-11.22,0.78-16.78
		c-4.12-1.46-8.37-2.97-12.86-4.56C799.47,297.09,798.92,301.71,798.36,306.5z"
          />
          <path
            className="st16"
            d="M1059.92,333.74c-0.46-0.22-0.91-0.48-1.37-0.72s-0.92-0.45-1.4-0.58c-5.78-1.58-11.89-2.52-16.44-6.8
		c-2.04-1.92-3.79-1.86-6.06-1.02c-5.56,2.06-11.29,3.05-17.36,2.5c-0.85,2.15-1.76,4.43-2.66,6.71c3.02,0.51,6.05,1.02,9.07,1.53
		c0.66-0.45,1.35-0.87,1.98-1.37c3.01-2.39,6.1-4.59,10.25-3.7c1.56,0.34,3.1,1.41,4.59,1.32c2.42-0.15,4.75,0.04,7.02,0.48
		c0.76,0.15,1.5,0.32,2.24,0.52c0.37,0.1,0.74,0.2,1.11,0.31c0.74,0.22,1.47,0.46,2.19,0.73c0.88,0.32,1.75,0.67,2.62,1.03
		c0.92,0.39,1.84,0.8,2.76,1.23l0,0C1058.95,335.19,1059.44,334.46,1059.92,333.74z"
          />
          <path
            className="st4"
            d="M973.95,260c1.97,1.22,3.97,2.39,5.95,3.6c1.98,1.21,3.92,2.46,5.77,3.84c1.15,0.85,1.26,3.1,1.84,4.71
		c2.54-0.46,5.07-0.91,7.6-1.36c2.52-0.45,5.04-0.9,7.52-1.35c-9.66-6.08-19.18-12.07-29.53-18.58c0.22,2.36,0.39,4.29,0.56,6.07
		C973.76,257.99,973.86,258.99,973.95,260L973.95,260L973.95,260z"
          />
          <path
            className="st12"
            d="M892.9,233.48c0.71,0.02,1.42,0.04,2.13,0.05C894.32,233.52,893.61,233.5,892.9,233.48L892.9,233.48z"
          />
          <path
            className="st12"
            d="M892.9,233.48c0.71,0.02,1.42,0.04,2.13,0.05c0.46-1.72,0.91-3.43,1.4-5.25c0.48-1.81,0.99-3.72,1.55-5.82
		c-4.22-1.25-7.55-2.24-10.88-3.23c1.64,1.29,3.27,2.58,4.91,3.86c0.43,0.11,0.86,0.22,1.29,0.32c-0.26,0.29-0.53,0.58-0.79,0.87
		c-0.37-0.24-0.74-0.48-1.1-0.72c-7.18-2.36-13.89-6.65-22.31-5.47c1.05,2.42,2,4.61,2.94,6.8c0.18-0.3,0.37-0.6,0.55-0.89
		c4.79,1.58,9.68,2.89,14.31,4.84c0.85,0.36,1.64,0.88,2.39,1.48C890.52,231.31,891.67,232.49,892.9,233.48L892.9,233.48z"
          />
          <path
            className="st12"
            d="M892.9,233.48c-0.01,0-0.02,0-0.03,0c0,0,0,0,0,0c0,0,0.02,0.01,0.05,0.03c0.02,0.01,0.04,0.03,0.05,0.03
		c0,0,0,0,0,0C892.94,233.52,892.92,233.5,892.9,233.48z"
          />
          <path
            className="st10"
            d="M579.26,258.06c3.3,6.54,2.83,14.49,7.48,20.55c0.99-7.43-2.43-13.92-3.86-20.99
		c1.35-0.54,2.74-1.1,4.09-1.64c0.89-3.34,0.29-4.1-2.74-3.49c-1.7,0.34-3.67,1.38-3.79-1.72v0c0,0,0,0,0,0
		c-3.1-5.27-6.19-10.55-9.32-15.8c-0.34-0.58-0.97-0.99-1.46-1.47c-0.83,0.48-1.65,0.95-2.48,1.43c5.78,5.01,7.57,12.16,10.69,18.67
		c-4.02,1.41-7.49,2.63-11.53,4.05c0.54,0.45,0.93,0.77,1.23,1.03s0.53,0.44,0.75,0.63C571.94,258.9,575.55,258.49,579.26,258.06z"
          />
          <path
            className="st4"
            d="M885.65,436.34c-1.45,0.14-2.91,0.31-4.31,0.11c-4.04-0.58-8.01-1.74-12.06-2.19
		c-3.45-0.38-6.7,0.48-8.82,3.58c2.54,4.08,3.57,4.26,7.28,2.55c2.38-1.1,5.02-1.64,7.55-2.43c0.22,0.39,0.44,0.78,0.66,1.18
		c-0.58,1.11-1.12,2.24-1.75,3.32c-2.84,4.88-2.1,8.98,2.28,12.19c1.5,1.1,3.01,2.26,4.68,3.06c4.39,2.11,8.87,3.91,13.84,0.9
		c-3.62-1.57-7.42-2.42-11.07-3.71c-2.86-1.02-6.16-1.98-8.14-4.04c-3.06-3.18-1.56-8.58,4.8-10.21c2.91-0.75,5.84-1.42,8.76-2.13
		c-0.75-0.76-1.5-1.51-2.24-2.27C886.62,436.25,886.13,436.29,885.65,436.34z"
          />
          <path
            className="st12"
            d="M1044.88,480L1044.88,480c-0.51-2.12-0.88-4.3-1.57-6.36c-1.62-4.87-1.34-10.36-4.95-14.61
		c-2.67-3.14-5.98-4.46-9.97-4.06c-3.69,0.37-4.84,3.3-5.79,6.25c-0.6,1.84-0.84,3.81-1.52,5.61c-0.19,0.51-1.33,0.66-2.03,0.98
		c-0.29,0-0.57-0.01-0.86-0.01c-0.21,0.02-0.41,0.02-0.62,0c-1.95,0.26-3.89,0.52-5.84,0.78c1.21,0.68,2.43,1.36,3.25,1.82
		c-2.13,3.74-4.2,7.37-6.27,11.01c1.07,0.59,2.15,1.18,3.22,1.78c2.43-7.77,6.43-11.38,13.38-11.96c-2.38-3.59-1.54-7.41,0.18-10.61
		c0.83-1.54,3.99-2.81,5.9-2.57c3.53,0.44,5.91,3.16,6.89,6.67c0.65,2.32,0.83,4.76,1.38,7.11c0.7,2.99,1.56,5.93,2.35,8.9
		C1042.98,480.48,1043.93,480.24,1044.88,480L1044.88,480z"
          />
          <path
            className="st12"
            d="M788.4,411.6c1.52,0.09,3.03,0.18,4.52,0.27c1.32,0.08,2.62,0.16,3.93,0.24h0c-0.01-0.1-0.03-0.2-0.04-0.31
		c-0.19-1.43-0.38-2.85-0.57-4.28c-1.01-0.68-2.05-1.31-3.07-1.98c-1.02-0.66-2.01-1.36-2.93-2.14c-0.9-0.78-1.2-2.26-1.77-3.42
		c1.13-0.09,2.26-0.2,3.4-0.27c0.71-0.05,1.42-0.04,2.12-0.07c-2.25-3.36-5.58-2.86-8.89-2.51c-0.83,0.09-1.68-0.08-2.52-0.13
		c-1.89,0.88-3.78,1.77-6.05,2.84c1.11,4.05,2.19,8.02,3.06,11.21C782.62,411.25,785.54,411.43,788.4,411.6z"
          />
          <path
            className="st7"
            d="M426.72,461.48c2.54,7.95,5.17,16.16,7.79,24.36c0.28-0.04,0.56-0.09,0.84-0.13c0-17.76,0-35.51,0-52.95
		c-1.25,1.44-2.66,3.06-4.28,4.92C435.76,449.15,433.25,456.37,426.72,461.48z"
          />
          <path
            className="st12"
            d="M301.68,647.6c4.05,3.58,8,7.29,12.17,10.72c4.08,3.35,8.25,6.58,12.42,9.81c1.39,1.08,2.78,2.16,4.16,3.24
		l0,0c1.22-0.67,2.45-1.34,3.74-2.04c1.29-0.7,2.64-1.44,4.12-2.25c-1.66-1.2-2.78-2.01-3.81-2.75c-2.36,1.28-4.42,3.5-7.92,1.71
		c1.83-1.52,3.22-2.68,4.86-4.04c-3.89-2.15-7.44-4.27-11.13-6.1c-2.3-1.15-4.81-1.87-7.97-3.07c-0.21-1.28-0.77-2.31-1.56-3.18
		c-1.75-1.92-4.66-3.04-7.52-4.22C302.71,646.15,302.2,646.88,301.68,647.6z"
          />
          <polygon
            className="st12"
            points="998.25,313.03 998.25,313.03 998.25,313.03 998.25,313.03 	"
          />
          <polygon
            className="st12"
            points="998.25,313.03 998.25,313.03 998.25,313.03 998.25,313.03 	"
          />
          <polygon
            className="st12"
            points="998.25,313.03 998.25,313.03 998.25,313.03 998.25,313.03 	"
          />
          <rect x="998.25" y="313.03" className="st12" width="0" height="0" />
          <path
            className="st12"
            d="M997.55,311.62C997.55,311.62,997.55,311.62,997.55,311.62c0.23,0.47,0.47,0.94,0.7,1.41
		c0.46,0.72,0.92,1.44,1.38,2.16l0,0v0c-0.19,0.18-0.37,0.39-0.55,0.6c-0.54,0.64-1.06,1.32-1.73,1.54
		c-2.92,0.98-5.14,2.33-4.92,5.86c3.83,3.43,7.65,6.86,11.48,10.3c1.46-4.92,2.93-9.84,4.39-14.77c-0.56-1.18-1.11-2.18-1.66-3.03
		c-2.39-3.65-4.87-4.35-9.02-4.09c0,0-0.02,0-0.04-0.01c0.02,0,0.04,0.01,0.04,0.01C997.6,311.61,997.57,311.62,997.55,311.62z"
          />
          <path
            className="st15"
            d="M681.78,349.68c-1.35,6.57,2.3,12.91-0.21,18.43c3.64,1.68,7.28,3.36,10.92,5.04l0,0
		c0.23,0.48,0.46,0.97,0.7,1.45c0.2-0.08,0.4-0.16,0.6-0.25c-0.24-0.63-0.47-1.27-0.71-1.9c0.24-5.39,0.49-10.78,0.73-16.26
		C689.69,353.97,685.75,351.83,681.78,349.68z"
          />
          <path
            className="st4"
            d="M765.14,129.3c0-2.1,0-4.55,0-7.31c-10.95,8.33-24.64,9.49-35.97,15.59c0.2,1.47,0.37,2.72,0.48,3.54
		C741.82,138.6,753.53,134.55,765.14,129.3z"
          />
          <path
            className="st11"
            d="M1039.12,500.88c2.51-2.8,5.02-5.6,7.56-8.43c0.59,1.43,1.12,2.72,1.69,4.11c-0.94,1.38-1.91,2.81-2.88,4.23
		c1.17-0.02,2.33-0.05,3.5-0.07c0.75,0.26,1.5,0.52,2.25,0.78c1.2,0.72,2.39,1.44,3.59,2.15c-0.31-1.25-0.48-2.57-0.84-3.8
		c-0.12-0.41-0.26-0.81-0.43-1.2c-1.4-3.13-2.84-6.24-4.3-9.34c-1.45-3.1-2.92-6.2-4.37-9.31l0,0c-0.95,0.24-1.9,0.48-2.85,0.71
		c-0.29,0.86-0.35,1.94-0.92,2.54c-2.76,2.93-5.8,5.61-8.44,8.64c-1.43,1.65-3.31,3.73-0.17,6.58c2.18-1.73,4.59-3.2,6.41-5.21
		c1.82-2.01,3.04-4.57,4.53-6.88c0.31,0.13,0.62,0.25,0.93,0.38c-0.41,1.76-0.43,3.74-1.31,5.22c-1.36,2.31-2.97,4.46-4.57,6.63
		c-0.53,0.72-1.06,1.44-1.58,2.17C1037.64,500.82,1038.38,500.85,1039.12,500.88z"
          />
          <path
            className="st12"
            d="M838.32,379.08c1.31-2.05,2.71-4.24,4.08-6.39c-5.55-4.32-8.64-6.27-5.3-14.16c-1.61-1.08-3.3-2.22-5.09-3.42
		C821.61,364.95,829.12,376.92,838.32,379.08z"
          />
          <path
            className="st12"
            d="M1039.38,428.08c-8.99-5.39-14.16-12.85-18.37-20.74c-3.07,0.98-6.04,1.92-8.9,2.83
		c0.49,1.9,1.01,3.88,1.52,5.88c9.34,1.16,10.42,4.59,14.49,14.82C1031.55,430.02,1034.98,429.17,1039.38,428.08z"
          />
          <path
            className="st12"
            d="M393.26,410.52c-2.77-3.27-4.85-7.13-7.3-10.68c-0.44-0.64-1.27-1.01-1.92-1.5
		c-0.36,0.32-0.73,0.63-1.09,0.95c2.76,6.23,5.12,12.67,8.37,18.62c2.72,4.99,6.16,9.46,10.74,12.77c1.52,1.1,3.17,2.08,4.97,2.9
		c0,0,0,0,0,0c0-0.01-0.01-0.03-0.01-0.04c-0.01,0-0.02-0.01-0.03-0.01c0.01,0,0.02,0.01,0.03,0.01
		c-2.13-5.72-4.26-11.45-6.39-17.17C398.13,414.44,395.25,412.86,393.26,410.52z"
          />
          <path
            className="st5"
            d="M877.22,408.42c-3.41-0.99-6.82-1.97-10.23-2.96c-0.59,0.58-1.19,1.15-1.79,1.73
		c-1.79,1.74-3.62,3.51-5.56,5.39c-1.38-1.25-2.32-1.91-3.03-2.77c-1.93-2.33-2.08-4.61-1.53-6.87c0.3-1.25,0.81-2.5,1.36-3.74
		c0.22-0.5,0.44-0.99,0.65-1.49c-2.36-1.47-4.72-2.95-7.08-4.42c0.14,0.86,0.29,1.72,0.44,2.59c0.45,2.59,0.92,5.17,1.18,7.77
		c0.18,1.8,0.23,3.91-0.54,5.43c-0.75,1.49-2.62,2.42-4.04,3.63c6.7,2.91,13.15,3.27,19.32,1.4
		C870.09,413,873.7,411.08,877.22,408.42L877.22,408.42z"
          />
          <path
            className="st12"
            d="M797.39,422.55C797.39,422.55,797.39,422.55,797.39,422.55c-1.43-1.09-2.86-2.18-4.28-3.27
		c0.07-0.26,0.14-0.51,0.22-0.77c1.19,0,2.37,0,3.56,0c-0.01-0.25-0.02-0.51-0.02-0.76c-1.38-0.97-2.79-1.73-4.23-2.28
		c-0.45-0.17-0.91-0.32-1.37-0.44c-1.25-0.35-2.53-0.54-3.85-0.55c-0.74-0.01-1.5,0.04-2.27,0.14c-1.93,0.25-3.94,0.85-6.06,1.81
		c1.63,4.61,3.3,9.33,5.1,14.43c4.21-1.48,7.71-2.72,11.22-3.96C796.06,425.45,796.72,424,797.39,422.55z"
          />
          <path
            className="st11"
            d="M901.07,364.75c0,0.47-0.01,0.93-0.03,1.4c-0.21,5.13-1.53,10.16-2.46,15.2c2.22,0.8,4.26,1.54,6.13,2.22
		c0.37-2.84,0.66-5.7,1.16-8.53c0.25-1.42,1.22-2.75,1.29-4.14c0.25-4.86,0.13-9.73,0.38-14.58c0.29-5.67,0.82-11.33,1.24-17
		c-0.16-0.02-0.32-0.04-0.49-0.06c-1.58,3.2-3.17,6.4-4.75,9.61c3.33,0.79,3.85,3.11,2.24,5.5c-1.28,1.9-3.48,3.18-5.27,4.74
		c0.08,0.39,0.15,0.78,0.21,1.17c0.02,0.14,0.05,0.28,0.07,0.41c0.2,1.36,0.29,2.71,0.29,4.05
		C901.07,364.73,901.07,364.74,901.07,364.75z"
          />
          <path
            className="st10"
            d="M458.41,313.4c2.36-0.82,4.87-1.37,7.05-2.54c5.83-3.12,11.46-6.71,18.93-4.54c0.51,3.15,1.04,6.39,1.57,9.62
		c0.46,1.8,1.03,3.58,1.38,5.4c0.83,4.34,1.65,8.69,2.46,13.04c0.81,4.35,1.62,8.69,2.43,13.04c0,0,0,0,0,0
		c-0.1-1.7-0.18-3.41-0.26-5.11c-0.09-1.7-0.19-3.41-0.33-5.11c-0.68-8.44-1.22-16.91-2.25-25.31c-0.36-2.94-0.01-6.99-4.07-8.02
		c-1.24-0.32-2.49-0.54-3.75-0.66c-0.63-0.06-1.26-0.11-1.89-0.12C471.48,302.85,463.37,306.47,458.41,313.4z"
          />
          <path
            className="st3"
            d="M1064.21,503.77c1.4-2.13,2.74-4.33,4.35-6.23c0.23-0.27,0.47-0.54,0.71-0.8c0.48-0.52,0.99-1,1.54-1.46
		c1.63-1.35,3.57-2.37,6.02-2.82c0.95-5.06,2.24-9.85-0.42-14.9c-1,1.47-1.77,2.62-2.54,3.76c-0.28-0.07-0.57-0.13-0.85-0.2
		c0-3.57,0-7.15,0-10.72c-0.39-0.06-0.77-0.11-1.16-0.17c-0.66,2.7-1.2,5.43-2,8.08c-2.35,7.73-4.81,15.43-7.23,23.14v0c0,0,0,0,0,0
		C1063.15,502.22,1063.68,502.99,1064.21,503.77z"
          />
          <path
            className="st3"
            d="M836.03,220.79c-2.24,2.59-4.12,5.69-5.4,8.88c-3.77,9.41,0,18.29,2.54,27.24c0.77-6.66,1.28-13.32,1.78-19.98
		c0.51-6.87,4.89-12.11,8.47-17.68c-0.5-0.46-0.99-0.92-1.49-1.39C839.92,218.8,837.36,219.26,836.03,220.79z"
          />
          <path
            className="st3"
            d="M245.06,480.02c0.53,6.71,1.04,13.41,1.58,20.12c0.69,8.47,1.51,16.94,2.05,25.42
		c0.28,4.4-0.14,8.84,0.1,13.24c0.11,2.02,0.33,4.04,0.59,6.05c0.26,2.01,0.54,4.02,0.78,6.04c-0.02-26.22-0.03-52.44-0.05-78.65
		c-0.4,0.05-0.8,0.09-1.2,0.14C248.27,475.25,248.35,478.48,245.06,480.02z"
          />
          <path
            className="st16"
            d="M770.05,301.36c0.36-0.84,0.72-1.67,1.09-2.51c-1.73,0.44-3.43,1-5.13,1.58c-0.42,0.14-0.85,0.29-1.27,0.44
		c-0.85,0.29-1.69,0.59-2.54,0.87c-3.82,1.27-7.7,2.31-11.87,2.12c-4.96-0.23-9.92-0.59-14.88-0.89c0.88,1.49,1.76,2.97,2.64,4.46
		c0.44,0.18,0.89,0.36,1.33,0.54c0.54,0.01,1.09,0.02,1.64,0.02c0.55,0,1.1,0,1.65,0c1.09,0,2.18-0.01,3.27-0.01
		c1.09,0.01,2.18,0.03,3.26,0.09c1.08,0.06,2.16,0.16,3.23,0.32c6.79,1,13.12-0.22,19.39-2.56c-0.38-1.53-0.75-3.06-1.12-4.59
		C770.51,301.26,770.28,301.31,770.05,301.36z"
          />
          <path
            className="st3"
            d="M892.84,260.8c0.01,0.01,0.01,0.01,0.02,0.02c-0.23-0.62-0.29-1.41-0.72-1.83
		c-3.83-3.69-7.49-7.62-11.71-10.82c-2.28-1.73-5.48-2.51-8.39-3.07c-3.21-0.63-6.57-0.52-9.86-0.74c0,2.8,0,5.61,0,8.45
		c4.53,0.28,8.77,0.32,12.94,0.87c3.29,0.44,6.64,1.17,9.7,2.4c1.78,0.72,3.44,1.76,5.08,2.83c0.4,0.26,0.8,0.52,1.19,0.78
		c0.59,0.38,1.17,0.76,1.77,1.12c0,0,0,0,0,0c0,0,0,0,0,0l0,0C892.85,260.82,892.84,260.81,892.84,260.8z"
          />
          <path
            className="st3"
            d="M800.54,328.27c-0.29,1.87-0.57,3.74-0.86,5.61l0,0c0.93,2.61,1.86,5.23,2.8,7.86
		c0.77,2.17,1.55,4.36,2.34,6.57c0.58,1.63,1.17,3.28,1.76,4.95c0.9,0.33,2.27,0.84,3.53,1.3c0.59-6.99,1.15-13.69,1.71-20.35
		C808.01,332.2,804.27,330.24,800.54,328.27z"
          />
          <path
            className="st12"
            d="M357.82,689.91c-1.72-3.24-0.99-6.22,1.32-9.09c-1.72-1.07-3.24-2.01-4.77-2.95
		c-0.52,0.99-0.93,1.78-1.86,3.56c-0.81-2.36-1.34-3.91-2.19-6.37c-1.78-0.18-4.27-0.43-7.29-0.73c0.73-0.79,1.38-1.49,2.17-2.34
		c-1.37-0.96-2.86-2.01-4.33-3.04c-2.43,1.65-4.63,3.14-6.83,4.63c6.48,4.77,12.93,9.59,19.46,14.29
		C354.78,688.78,356.37,689.24,357.82,689.91z"
          />
          <path
            className="st15"
            d="M794.53,245.89c-1.39,0.22-2.73,0.44-4.07,0.65c0.01-0.02,0.01-0.05,0.02-0.07
		c-2.37,0.02-4.74,0.05-7.11,0.07c0.58,1.34,1.17,2.67,1.75,4.01c0.26-0.12,0.52-0.24,0.77-0.35c-0.13,0.65-0.27,1.31-0.4,1.96
		c1.12-0.03,2.24-0.06,3.35-0.09c0.54-1.84,1.07-3.69,1.61-5.53c0,0,0,0,0,0c0.83-0.01,1.68-0.08,2.52-0.14
		c1.42-0.1,2.83-0.16,4.13,0.21c1.87,0.52,3.95,1.87,4.97,3.47c1.64,2.55,0.53,4.23-2.51,4.96c-0.88,0.21-1.73,0.58-2.6,0.88
		c-0.36,1.22-0.72,2.44-1.17,3.95c-2.23,0.5-4.47,1.01-6.71,1.51c1.03,0.05,2.07,0.09,3.11,0.13c2.2,0.08,4.41,0.15,6.61,0.29
		c1.08,0.07,2.15,0.15,3.23,0.25c4.65,0.45,6.36-0.46,6.83-5.03c0.23-2.24-0.27-4.64-0.87-6.85c-0.85-3.12-2.1-6.14-3.16-9.12
		c-1.28,0.16-2.77,0.34-4.26,0.52c-0.6,1.36-1.2,2.72-1.59,3.62C797.4,245.43,795.93,245.67,794.53,245.89z"
          />
          <path
            className="st9"
            d="M442.84,469.29c0.52-12.15,1.03-24.3,1.55-36.45c-1.04-0.46-2.08-0.92-3.28-1.45
		c-0.72-0.32-1.5-0.66-2.37-1.05c0,6.1,0,12.22,0,18.35c0,6.13,0,12.27,0,18.42C440.1,467.84,441.47,468.57,442.84,469.29z"
          />
          <path
            className="st5"
            d="M1129.18,513.55c-0.39,0.18-0.78,0.36-1.17,0.54c1.2,20.57,2.41,41.13,3.61,61.7
		c1.19-0.59,1.88-1.35,1.92-2.13c0.33-6.39,1.07-12.83,0.67-19.18c-0.8-12.6-2.23-25.17-3.51-37.74
		C1130.6,515.62,1129.71,514.6,1129.18,513.55z"
          />
          <path
            className="st12"
            d="M314.57,510.73c0.02-0.01,0.03-0.02,0.05-0.03C314.61,510.71,314.59,510.72,314.57,510.73
		c-0.01,0.01-0.03,0.03-0.04,0.04c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.22-0.06,0.45-0.12,0.68-0.17c0.68-0.16,1.37-0.3,2-0.6
		c13.19-6.21,26.37-12.45,39.55-18.69c-0.52-0.96-1.04-1.91-1.64-3.03c-0.2-0.37-0.41-0.76-0.64-1.17
		c-12.2,6.8-23.92,13.28-35.56,19.88c-0.62,0.35-1.18,0.79-1.73,1.27C316.29,509.05,315.46,509.94,314.57,510.73L314.57,510.73z"
          />
          <path
            className="st14"
            d="M738.03,244.65c1.74,3.62,3.52,7.3,5.29,10.99c0.31-0.29,0.61-0.58,0.92-0.87c1.11,0.94,2.22,1.87,3.81,3.21
		c4.81,0.37,10.33,0.8,15.84,1.23c0-0.63,0-1.25,0-1.88c-4.43-0.63-8.85-1.26-14.18-2.02c-1.13-3.57-2.42-7.62-3.95-12.47
		c7.04,0,13.31,0,19.57,0c-0.01-0.43-0.01-0.86-0.02-1.29c-3.06-0.14-6.11-0.27-9.13-0.41c-5.02-0.23-9.93-0.45-14.53-0.65
		C740.13,242.23,739.14,243.37,738.03,244.65z"
          />
          <path
            className="st8"
            d="M964.99,468.91c0.05,0.36,0.09,0.72,0.12,1.08c0.14,1.69,0.14,3.39,0.2,5.09c0.23-0.35,0.45-0.73,0.69-1.11
		c0.08-0.12,0.15-0.25,0.23-0.37c0.24-0.37,0.48-0.73,0.75-1.04c0.81-0.95,1.83-1.55,3.39-1.03c1.6,0.53,2.78,2.3,4.15,3.51
		c2.07,4.79,0.04,10.86,4.44,14.93c0.45-2.87,0.64-5.67,0.14-8.28c-0.13-0.65-0.3-1.29-0.52-1.92s-0.49-1.24-0.82-1.84
		c-0.33-0.6-0.71-1.18-1.16-1.75c-0.22-0.28-0.46-0.56-0.72-0.84c-0.26-0.27-0.53-0.54-0.82-0.81c-0.1-0.48-0.19-0.96-0.29-1.42
		c-0.09-0.47-0.19-0.92-0.27-1.35c4.6-0.92,8.34-2.93,9.92-6.8c2.24-5.51,3.57-11.38,5.29-17.1c0.18,0.09,0.35,0.18,0.53,0.27
		c-0.25-1.32-0.51-2.65-0.76-3.97c-0.63-0.04-1.26-0.07-1.89-0.11c-0.93,3.15-1.95,6.27-2.84,9.42c-0.3,1.05-0.58,2.11-0.84,3.17
		c-1.97,8.06-5.31,10.15-13.37,8.53c-0.48-0.1-0.97-0.13-1.47-0.11c-0.5,0.01-1.01,0.07-1.52,0.14c-1.02,0.15-2.06,0.36-3.07,0.46
		C964.65,466.75,964.85,467.83,964.99,468.91z"
          />
          <path
            className="st5"
            d="M346.31,845.64c-6.11,1.74-12.15,3.69-17.31,0.24c-4.77-3.19-8.54-7.86-12.82-11.95
		c-1.28,0.26-3.24,0.67-5.29,1.09c3.48,2.45,7.02,4.75,10.34,7.34c2.36,1.84,4.77,3.6,7.22,5.28c7.37,5.04,15.16,9.35,23.42,12.88
		c0,0,0,0,0,0c0.35,0.15,0.7,0.29,1.05,0.44c0.04-0.1,0.07-0.2,0.11-0.3c-0.28-0.19-0.55-0.37-0.83-0.56
		C348.84,855.92,344.77,852.05,346.31,845.64z"
          />
          <path
            className="st11"
            d="M407.03,395.13c4.24,2.49,9.37,0.81,11.6-3.9c-1.07-1.66-2.4-3.22-3.14-5.02c-0.25-0.61-0.63-1.34-0.74-2.04
		c-0.02-0.1-0.03-0.2-0.03-0.3s0-0.2,0-0.29c0.03-0.29,0.12-0.57,0.31-0.83c0.13-0.17,0.3-0.33,0.52-0.48
		c0.11-0.07,0.23-0.14,0.37-0.21c0.14-0.07,0.29-0.13,0.46-0.19l0,0c-1.04-1.17-2.08-2.34-3.18-3.58
		c-2.75,7.01-9.85,8.26-14.81,12.09C401.44,392.05,404.28,393.51,407.03,395.13z"
          />
          <path
            className="st5"
            d="M963.6,255.37c-5.75-3.13-11.74-6.42-17.77-9.63c-0.65-0.34-1.52-0.26-2.29-0.37
		c5.72,10.19,15.29,14.96,25.45,18.9c0.36-0.23,0.73-0.47,1.09-0.7c-1.16-5.01-2.32-10.02-3.49-15.03
		C965.58,250.84,964.57,253.14,963.6,255.37z"
          />
          <path
            className="st4"
            d="M777.33,354.21c8.28,3.23,13.73,8.82,14.59,17.71c0.22,0.92,0.44,1.84,0.66,2.76
		c0.25,0.99,0.51,1.97,0.76,2.96c0.22,0.66,0.43,1.33,0.65,1.99c0.4,1.72,0.8,3.44,1.21,5.16c-0.04-1-0.08-2-0.11-3
		c-0.1-3-0.2-6.01-0.42-9c-0.44-5.91-1.81-11.65-5.36-16.48c-6.91-9.38-18.7-9.82-24.49-0.9
		C768.94,354.85,772.43,352.3,777.33,354.21z"
          />
          <path
            className="st15"
            d="M759.46,403.42c0,4.17,0,7.67,0,11.68c5.19-0.8,10.56-1.62,15.85-2.44c-0.91-4.24-1.73-8.1-2.57-12.03
		c-2.11,0.64-4.18,1.44-6.33,1.89C764.21,402.98,761.94,403.11,759.46,403.42z"
          />
          <path
            className="st11"
            d="M770.36,295.77c0.72-0.1,1.43-0.22,2.15-0.32c0.72-0.1,1.43-0.19,2.15-0.24c0.11-0.01,0.22,0.08,0.33,0.2
		c0.19,0.21,0.39,0.53,0.58,0.74l0,0c-1.07,0.65-2.14,1.3-3.22,1.96c-0.41,0.25-0.81,0.49-1.22,0.74c-0.36,0.84-0.72,1.67-1.09,2.51
		c0.23-0.05,0.45-0.1,0.68-0.15c0.38,1.53,0.75,3.06,1.12,4.59c2.28-1.69,4.57-3.38,6.85-5.07c-0.5-0.24-0.99-0.49-1.49-0.73
		c-0.33-1.09-0.65-2.18-0.98-3.26c0.47-1.16,1.02-2.29,1.4-3.47c1.98-6.14-1.77-12.02-8.02-12.3c-4.69-0.21-9.4-0.04-13.82-0.04
		c0.1,1.04,0.24,2.52,0.31,3.3c4.17,1.52,8.01,2.93,11.85,4.33c-0.08,0.31-0.15,0.62-0.23,0.93c1.12-0.81,2.23-1.61,3.13-2.26
		c-0.72-1.46-1.32-2.68-1.93-3.9c0.25-0.29,0.49-0.58,0.74-0.87c1.53,1.06,3.06,2.12,3.96,2.73c-0.2,5.4-3.81,6.36-6.96,7.8
		c-1.63,0.74-3.27,1.44-4.91,2.17C764.63,295.36,767.5,295.57,770.36,295.77z"
          />
          <path
            className="st5"
            d="M238.7,447.71c0.21,4.13,1.02,8.33,0.54,12.38c-0.4,3.36-0.68,6.65-0.36,9.89c0.19,1.95,0.61,3.88,1.35,5.81v0
		c0.27-0.19,0.54-0.39,0.84-0.61c0.2-0.15,0.42-0.3,0.66-0.48c0.37-0.26,0.79-0.57,1.3-0.94c0.19,0.6,0.38,1.18,0.56,1.73
		c0.53,1.65,1,3.09,1.46,4.53c3.29-1.54,3.21-4.77,3.85-7.65c-1.17-0.14-2.35-0.28-4.41-0.53c-3.49-13.31-3.6-27.18-3.74-41.54
		c-0.97,0.62-1.64,1.04-2.23,1.43c-0.2,0.13-0.39,0.25-0.58,0.37C238.19,437.3,238.44,442.51,238.7,447.71z"
          />
          <path
            className="st11"
            d="M815.54,335.54c-0.74,6.93-0.55,13.96-0.69,20.96c-0.01,0.5,0.47,1.01,0.76,1.59
		c0.92-0.49,1.74-0.92,2.57-1.36c-0.25-0.27-0.5-0.53-0.75-0.8c1.67-3.73,3.34-7.45,5.62-12.54c0-4.23,0-10.12,0-16.27
		C819.32,329.13,815.96,331.62,815.54,335.54z"
          />
          <path
            className="st4"
            d="M1118.14,491.82c-0.32-0.23-0.67-0.47-1.03-0.72c-4.08,0.35-5.63-3.41-7.55-6.19
		c-1.72,0.32-3.15,0.58-4.59,0.84c2.55,1.72,5.12,3.42,7.66,5.16c4.85,3.31,9.68,6.65,14.52,9.97c-0.53-1.38-1.07-2.77-1.88-4.87
		c3.19,1.29,5.67,2.3,8.55,3.46c-0.55,1.13-1.09,2.25-1.62,3.36c4.99,2.03,9.98,4.05,14.87,6.04c0-2.23,0-4.47,0-6.83
		c-2.08-0.78-3.99-1.65-5.99-2.21c-6.37-1.79-12.01-6.41-19.24-4.97c-0.14,0.03-0.32-0.17-0.51-0.43c-0.07-0.09-0.14-0.19-0.21-0.29
		c-0.13-0.18-0.25-0.36-0.37-0.5C1119.93,493.06,1119.11,492.5,1118.14,491.82z"
          />
          <path
            className="st3"
            d="M305.57,744.51c1.45,4.55,4.19,8.6,8.37,11.36c2.42-4.32,4.83-8.65,7.25-12.97c0,0,0,0,0,0
		c-0.02-0.01-0.03-0.02-0.05-0.02c-1.53-0.78-3.07-1.55-4.63-2.34c-0.95-0.48-1.91-0.97-2.89-1.46c-2.59-1.31-5.3-2.68-8.22-4.16
		C305.4,738.33,304.67,741.68,305.57,744.51z"
          />
          <path
            className="st7"
            d="M541.86,523.12c1.86-1.28,3.4-2.47,5.07-3.44c1.24-0.72,2.66-1.62,3.99-1.6c5.51,0.05,9.95-2.3,14.01-5.68
		c2.07-1.72,4.1-3.48,6.09-5.18c7.6,2.9,14.58-3.29,16.82-10.81c-15.35,8.06-30.49,16.01-45.98,24.15
		C541.86,520.77,541.86,521.71,541.86,523.12z"
          />
          <path
            className="st11"
            d="M979.05,358.01c1.05,3.27,1.36,6.77,1.86,10.21c0.07,0.49,0.15,0.98,0.23,1.47c0.66,0,1.32,0,1.99-0.01
		c-0.32-1.35-0.68-2.68-1.01-4.03s-0.64-2.69-0.84-4.05c-0.59-3.92-0.73-7.84,2.58-11.46c0,2.95-0.21,5.42,0.06,7.85
		c0.14,1.25,0.39,2.48,0.67,3.71c0.28,1.23,0.6,2.46,0.86,3.69c1.65-2.16,3.29-4.33,4.94-6.49c-1.56-1.64-3.12-3.28-4.68-4.92
		c0.2-0.17,0.4-0.34,0.6-0.51c1.56,0.66,3.13,1.31,4.69,1.97v0l0,0c0.02-2.21,0.05-4.41,0.07-6.62c-2.28-2.02-4.56-4.04-7.09-6.28
		c-1.2,1.67-2.57,3.36-3.69,5.21C978.35,350.95,977.82,354.2,979.05,358.01z"
          />
          <path
            className="st3"
            d="M985.6,503.34c-1.38,2.13-2.87,2.53-4.98,1.74c-1.25-0.47-2.49-0.95-3.72-1.44c-1.24-0.49-2.47-0.99-3.71-1.47
		l0,0c-1.03-0.71-2.06-1.42-3.09-2.12c-5.81-3.93-8.04-9.52-6.33-16.97c0.82,1.56,1.41,2.67,2.09,3.97
		c-0.26-3.2-0.51-6.21-0.75-9.22c-1.91,1.62-1.52,5.53-5.27,5.33c1.34,4.97,2.54,9.98,3.95,14.92c0.47,1.65,0.96,3.29,1.49,4.92
		c1.91,5.87,3.58,11.71,2.8,17.9c-0.5,3.99-1.55,7.92-2.37,11.92c5.57-7.9,8.03-16.28,4.56-25.76c-0.53-1.46-0.58-3.1-0.94-5.11
		c1.51,0.33,2.4,0.53,3.3,0.73c1.13,0.8,2.17,1.79,3.41,2.38c10.84,5.18,8.18,4.18,8.61,14.42c0.13,3.11,0.35,6.33,3.5,8.64
		c0.76-1.12,2.21-2.28,2.06-3.18c-0.93-5.66-2.22-11.26-3.47-17.29c1.45-0.15,3.72,0.12,5.45-0.72c1.76-0.85,3-2.79,4.48-4.26
		C992.62,498.82,988.27,499.2,985.6,503.34z"
          />
          <path
            className="st5"
            d="M507.24,556.97c3.05-1.66,5.92-3.63,8.12-5.01c2.66,2.38,4.81,4.31,6.97,6.24c-0.11-1.28-0.14-2.57-0.33-3.84
		c-0.41-2.73-0.82-5.45-1.24-8.18c-0.42-2.72-0.85-5.45-1.27-8.17c-0.53,1.35-1.07,2.7-1.5,3.8c-5.22,4.01-10.36,7.97-15.51,11.93
		c-0.53,1.5-1.05,3.01-1.58,4.51v0C503.47,560.15,505.33,558.01,507.24,556.97z"
          />
          <path
            className="st7"
            d="M571.12,234.97c3.14,5.25,6.22,10.53,9.32,15.8c0,0,0,0,0,0c0.96-0.95,1.93-1.9,2.89-2.84c0,0,0,0,0,0
		c-0.75-0.37-1.5-0.73-2.48-1.21c-0.59-0.29-1.27-0.62-2.08-1.02c3.06-1.75,5.23-2.99,8.26-4.73c-2.24-3.19-4.42-6.29-6.58-9.37
		c-0.72-1.03-1.44-2.05-2.16-3.08v0c-1.83,1.05-3.65,2.11-5.48,3.16c-1.05,0.61-2.11,1.21-3.16,1.82
		C570.16,233.99,570.78,234.4,571.12,234.97z"
          />
          <path
            className="st3"
            d="M983.97,470.24c-1.53,1.16-3.62,2.76-4.9,3.74c1.52,5.23,2.93,10.08,4.37,15.03c4.07,2.31,8.68,4.92,12.71,7.2
		C992.1,487.57,987.93,478.67,983.97,470.24z"
          />
          <path
            className="st4"
            d="M837.98,417.96c-1.6-6.51-6-11.74-11.61-16.44c4.89,9.16,5.76,18.87,4.4,28.97c-0.09,0.66,1.23,1.52,1.89,2.28
		c1.67-1.22,3.35-2.44,5.02-3.65l0.01-0.01c0.2-0.47,0.4-0.95,0.6-1.42c0.23-1.42,0.34-2.79,0.35-4.11
		C838.65,421.61,838.42,419.74,837.98,417.96z"
          />
          <path
            className="st15"
            d="M1039.44,365.93c5.25,7.26,12.65,11.04,18.06,9.22c-1.37-4.39-2.8-5.3-6.76-8
		c-5.46-3.71-12.1-6.79-11.88-15.39C1035.17,356.68,1035.4,360.35,1039.44,365.93z"
          />
          <path
            className="st12"
            d="M842.88,253.62c0.42-6.48,0.42-13.23,1.49-19.81c0.5-3.1,1.06-5.78-0.59-8.52
		c-5.61,8.85-7.98,21.71-6.38,35.23C839.31,258.11,841.12,255.83,842.88,253.62z"
          />
          <path
            className="st4"
            d="M251.96,700.62c1.05,0.53,2.11,1.06,3.17,1.57l0,0c0.37,0.23,0.74,0.46,1.11,0.7
		c0.09-0.12,0.18-0.23,0.27-0.35c-0.26-0.28-0.52-0.57-0.78-0.85h0c-3.78-4.05-3.8-5.78-0.11-10.78c-1.66-0.84-3.31-1.68-4.06-2.06
		c-2.07,1.77-3.59,4.12-5.49,4.48c-4.19,0.79-7.48-1.38-9.51-5.26c-0.24,0.26-0.48,0.52-0.72,0.78c-1.25-1.21-2.51-2.41-4.04-3.89
		c-3.23,0.65-7,1.4-10.76,2.16c7.22,2.99,14.35,5.84,21.38,8.9C245.65,697.42,248.8,699.03,251.96,700.62z"
          />
          <path
            className="st12"
            d="M861.8,359.5c1.35-4.74,2.99-9.46,3.61-14.31c0.2-1.53-2.45-3.43-3.79-5.17c-4.61,3.07-2.95,7.34-3.05,11.04
		c-0.1,3.73,0.4,7.47,0.64,11.21c-0.44,0.08-0.88,0.16-1.33,0.24c-0.66-1.36-1.33-2.72-1.89-3.87c-1.94,1.38-3.96,2.81-6.82,4.84
		c-0.71,4.76,0.17,7.13,3,7.23c0.24-2.51,0.48-5,0.78-8.12c2.89,1.22,5.1,2.16,6.72,2.85C860.46,363.25,861.26,361.41,861.8,359.5z"
          />
          <path
            className="st12"
            d="M1081.44,485.71c0.64-0.71,1.28-1.41,1.92-2.12c4.06-10.11-3.36-14.7-10.43-19.82
		c1.46-0.18,2.04-0.26,2.62-0.33c-0.47-0.72-0.94-1.43-1.42-2.15c-3.8,0.65-6.81-0.78-9.54-2.67c-1.64-1.14-3.17-2.44-4.73-3.57
		c-4.22-3.08-6.94-7.81-7.55-13.99c-0.81,2.09-1.39,3.57-1.81,4.65c2.4,7.08,7.09,11.89,13.01,15.1c5.47,2.98,9.5,7.37,14.01,11.37
		c3.5,3.1,4.98,6.56,4.53,10.62C1081.96,483.73,1081.75,484.7,1081.44,485.71C1081.44,485.71,1081.44,485.71,1081.44,485.71z"
          />
          <path
            className="st8"
            d="M1001.74,476.71c-2.9-2.69-5.54-5.6-6.75-9.59c-0.57-1.88-0.76-3.87-1.13-5.82c-1.43,0.54-2.87,1.07-4.2,1.57
		c-0.44,0.17-0.88,0.33-1.3,0.48c-0.95,10.7,5.78,18.57,15.45,20.02c0.29-1.33,0.58-2.67,0.87-4.01l0,0v0
		C1003.7,478.48,1002.71,477.61,1001.74,476.71z"
          />
          <path
            className="st8"
            d="M783.11,296.66c4.07,2.49,7.72,4.71,11.58,7.07c0.48-4.73,0.93-9.11,1.38-13.53
		c-4.01-1.77-7.82-3.44-12.41-5.47C783.47,289.02,783.29,292.84,783.11,296.66z"
          />
          <path
            className="st12"
            d="M812.06,401.84c2.11,0.77,2.64,2.33,2.92,4c0.06,0.34,0.1,0.68,0.15,1.01c0.05,0.34,0.09,0.68,0.15,1.01
		c0.06,0.33,0.13,0.66,0.22,0.97c0.42,1.42,0.51,2.94,0.75,4.42c3.15,0.56,6.3,1.12,9.44,1.67c-0.07-0.57-0.04-1.62-0.34-2.57
		c-2.17-6.91-4.33-13.85-11.3-17.71c-1.79,0.34-3.59,0.68-5.53,1.05C809.7,397.74,810.88,399.79,812.06,401.84z"
          />
          <path
            className="st12"
            d="M1052.53,381.9c-3.25-1.66-6.49-3.33-9.74-4.99c0,7.54,0,15.08,0,22.26c3.14-2.26,6.24-4.49,9.74-7
		C1052.53,389.28,1052.53,385.59,1052.53,381.9z"
          />
          <path
            className="st5"
            d="M797.77,272.41c-4.83,0-9.06,0-13.68,0c0.19,3.16,0.37,6.03,0.57,9.23c3.69,1.68,7.71,3.52,12.21,5.57
		C797.19,281.91,797.48,277.27,797.77,272.41z"
          />
          <path
            className="st12"
            d="M313.94,755.87c2.38,0.13,4.9,0.82,7.12,0.28c5.86-1.44,10.27-9.36,9.87-15.98
		c-1.64,0.96-3.49,2.38-5.58,3.13c-0.59,0.21-1.28,0.16-2,0.02c-0.32-0.06-0.65-0.13-0.97-0.2c-0.08-0.02-0.15-0.03-0.23-0.05
		c-0.33-0.07-0.65-0.13-0.97-0.17C318.77,747.22,316.35,751.55,313.94,755.87z"
          />
          <path
            className="st5"
            d="M1129.71,340.77c-0.05-0.01-0.13,0.14-0.37,0.4c0.4,12.12,0.81,24.42,1.22,36.63
		c1.61,0.79,2.82,1.38,5.07,2.48c-1.03-13.64-1.98-26.33-2.94-39.01C1131.59,341.07,1130.65,340.91,1129.71,340.77z"
          />
          <path
            className="st3"
            d="M204.55,823.18c-2.18,2.39-4.2,4.61-6.22,6.83c4.76-2.26,7.64,1.54,10.59,2.79c6.27,9.96,8.02,20.12,6.3,30.99
		c1.1,0,2.19,0,3.07,0C220.39,847.89,216.4,834.3,204.55,823.18z"
          />
          <path
            className="st14"
            d="M728.13,287.36L728.13,287.36L728.13,287.36c-0.79,0.5-1.57,1-2.37,1.5s-1.61,1.02-2.44,1.54
		c3.71,5.64,8.11,8.66,13.67,8.98c7.03,0.41,14.14,0.44,21.14-0.16c2.07-0.18,4.11-0.74,6.14-1.41c0.68-0.23,1.37-0.46,2.05-0.7
		c1.34-0.47,2.69-0.94,4.04-1.34c-2.87-0.21-5.73-0.41-8.6-0.62c-0.59,0.05-1.2,0.03-1.78,0.17c-8.45,2.06-16.77,3.02-25.39,0.09
		c-4.19-1.43-5.89-3.8-6.51-7.67c0.08-0.06,0.12-0.12,0.13-0.19C728.22,287.5,728.19,287.43,728.13,287.36z"
          />
          <path
            className="st16"
            d="M802.01,272.17C802.01,272.17,802.01,272.17,802.01,272.17C802.01,272.17,802.01,272.17,802.01,272.17
		c-0.48,4.34-0.96,8.68-1.43,13.02c4.24-0.25,8.47-0.5,12.71-0.75c-0.01-2.16-0.01-4.31-0.02-6.47c-0.01-2.17-0.01-4.34-0.02-6.52
		C809.5,271.7,805.75,271.94,802.01,272.17z"
          />
          <path
            className="st5"
            d="M270.85,806.06c1.28-3.16,1.96-6.39,2.32-9.7s0.4-6.72,0.38-10.22c1.22-0.84,2.82-1.94,4.41-3.03
		c-2.96-8.21-4.97-10.59-8.34-10.48c0,9.99,0,19.95,0,29.92C270.03,803.71,270.44,804.88,270.85,806.06z"
          />
          <path
            className="st5"
            d="M1034.43,511.22c-2.87-2.48-5.74-4.96-8.61-7.44c0.27-0.28,0.53-0.55,0.8-0.83c2.32,1.65,4.64,3.3,6.79,4.83
		c1.57-1.9,2.98-3.6,4.35-5.25c0.46-0.55,0.91-1.1,1.36-1.64c-0.74-0.03-1.48-0.06-2.22-0.1c-2.25,0.71-3.68,1.14-4.73,1.2
		c-1.74,0.09-2.4-0.87-3.96-3.39c-0.89-0.24-1.79-0.48-2.68-0.71c-0.34,0.48-0.67,0.96-1.03,1.47c-0.36,0.51-0.74,1.06-1.17,1.67
		c-1.33-0.33-2.76-0.69-4.21-1.05c-0.13,2.63-0.26,5.32-0.42,8.57c4.87,1.28,9.89,2.61,14.9,3.93
		C1033.88,512.05,1034.16,511.63,1034.43,511.22z"
          />
          <path
            className="st9"
            d="M590.87,459.57c-0.14,0.3-0.29,0.6-0.43,0.89c3.6,4.36,7.2,8.72,11.39,13.79c-4.25,3.04-8.42,6.01-12.7,9.07
		c1.91,0.91,3.76,1.79,5.35,2.54c5-5.79,11.17-10.52,12.13-18.67C601.38,464.67,596.13,462.12,590.87,459.57z"
          />
          <path
            className="st16"
            d="M795.81,259.87c0.45-1.51,0.81-2.73,1.17-3.95c-1.76-0.23-3.77,0.09-5.39-0.43
		c-1.26-0.41-2.28-1.32-2.74-3.41c-1.12,0.03-2.24,0.06-3.35,0.09c-1.57,4.04-4.57,5.35-8.79,5.1c-4.25-0.25-8.54,0.02-12.81,0.06
		c0,0.63,0,1.25,0,1.88c8.4,0.72,16.81,1.45,25.21,2.17C791.34,260.87,793.58,260.37,795.81,259.87z"
          />
          <path
            className="st8"
            d="M1078.48,335.05c-1.23,5.27,2.89,8.17,12.54,8.17c0.33-1.83,1.08-3.85,0.97-5.83
		c-0.18-3.37-0.73-6.75-1.49-10.04c-0.22-0.96-1.72-1.63-2.63-2.43c-0.23,0.23-0.46,0.47-0.7,0.7c0.16,2.89,0.32,5.78,0.48,8.67
		c-0.53,0.15-1.06,0.29-1.59,0.44c-1.08-2-2.16-4-3.24-6c-0.46,1.21-0.92,2.42-1.38,3.64c-0.71,1.05-1.42,2.11-2.13,3.16
		C1079.03,335.38,1078.76,335.22,1078.48,335.05z"
          />
          <path
            className="st7"
            d="M732.96,262.08c-0.57-0.59-1.14-1.18-1.71-1.77c-0.56-0.59-1.12-1.19-1.68-1.79
		c-0.26,0.62-0.52,1.24-0.78,1.86c-0.26,0.62-0.52,1.24-0.78,1.86c0.46,0.42,0.92,0.85,1.37,1.29c0.48,0.47,0.96,0.93,1.45,1.39
		c0.94,0.89,1.91,1.74,2.97,2.46c1.13,0.77,2.18,1.59,3.15,2.45c6.81,6.05,9.9,14.29,12.29,23c1.12,0,2.24,0,3.63,0
		c0.46,0,0.96,0,1.49,0c-1.04-4.96-2.41-9.57-2.89-14.26c-0.49-4.81-0.11-9.72-0.11-14.58c0.36-0.09,0.72-0.17,1.08-0.26
		c-0.79-0.32-1.59-0.64-2.68-1.09c-0.04,0.65-0.08,1.2-0.11,1.71c-0.03,0.51-0.06,0.97-0.09,1.42c0.47,0.66,1.35,1.32,1.33,1.97
		c-0.12,4.64-0.43,9.28-0.68,13.92c0.58,2.68,1.17,5.37,1.75,8.05c-3.34-5.51-5.69-11.52-9.29-16.65c-1.4-1.99-2.96-3.85-4.61-5.66
		C736.4,265.6,734.68,263.85,732.96,262.08z"
          />
          <path
            className="st15"
            d="M570.89,465.21c0.86-2.06,1.46-3.49,2.23-5.31c-1.81-1.43-3.78-2.99-5.95-4.7c-0.93,0.98-1.59,1.69-2.11,2.24
		c-0.76-0.14-1.42-0.26-1.04-0.19c-2.06,1.78-3.56,3.06-5.15,4.43c-0.62-2.18-1.02-3.61-1.45-5.09c0.21,0,0.82,0,1.38,0
		c0.19,1.26,0.36,2.32,0.7,4.51c1.24-2.06,1.89-3.14,2.54-4.23c-1.14-1.06-1.98-1.85-2.89-2.7c-3.75,1.8-7.38,3.56-11.25,5.42
		c0.79,1.13,1.33,1.92,2.03,2.92c1.84-1.14,3.39-2.11,4.85-3.02c0,1.3,0,2.66,0,4.02c1.05,0.36,2.11,0.72,3.27,1.11
		c0.2,0.7,0.46,1.6,0.71,2.49c0.2-0.01,0.39-0.03,0.59-0.04c0.2-0.85,0.4-1.7,0.61-2.55c0.15,0.17,0.29,0.35,0.44,0.52
		c1.72-1.43,3.45-2.86,4.93-4.09c0.11,0.12,0.26,0.28,0.42,0.46c0.48,0.53,1.16,1.28,1.84,2.03
		C568.66,464.03,569.76,464.61,570.89,465.21z"
          />
          <path
            className="st15"
            d="M554.7,463.49l0.06,0.06c0-0.01,0-0.03,0-0.04c0,0,0,0,0,0C554.74,463.5,554.72,463.49,554.7,463.49z"
          />
          <path
            className="st15"
            d="M567.63,463.52c-0.02-0.02-0.04-0.04-0.06-0.07c0,0,0,0,0,0L567.63,463.52z"
          />
          <path
            className="st3"
            d="M49.5,741.82c8.14,5.8,16.97,4.65,19.36-2.34C63.89,732.14,52.99,735.65,49.5,741.82z"
          />
          <path
            className="st3"
            d="M954.52,324.8c2.4-3.56,2.64-0.46,3.83,0.94c1.77-1.18,4.53-2,5.05-3.55c1.55-4.69-0.52-8.81-4.63-12.49
		c0.17-0.02,0.32-0.03,0.46-0.05c0.85-0.09,1.26-0.13,1.6-0.17c-1.01-0.54-2.03-1.08-3.25-1.73c-0.34,3.86-0.63,7.17-0.88,10.1
		c2.61,1.1,4.6,1.94,6.59,2.77c-0.13,0.37-0.25,0.73-0.38,1.1c-1.95-0.46-4.08-0.59-5.77-1.52c-1-0.55-1.81-2.35-1.82-3.6
		c-0.04-2.52,0.53-5.04,0.87-7.76c-5.47-0.32-4.68,3.96-5.91,6.5c0.81,0.74,1.53,1.4,2.5,2.28c-1.09,0.36-1.74,0.57-2.93,0.96
		C951.42,320.7,952.89,322.64,954.52,324.8z"
          />
          <path
            className="st3"
            d="M960.98,309.47c-0.05,0.01-0.1,0.01-0.15,0.02c0.03,0.02,0.06,0.03,0.09,0.05
		C960.92,309.53,960.98,309.47,960.98,309.47z"
          />
          <polygon
            className="st3"
            points="960.98,309.47 960.98,309.47 960.98,309.47 	"
          />
          <path
            className="st1"
            d="M445.09,335.86c-0.63,0.52-1.15,0.94-1.61,1.32c-0.46,0.38-0.86,0.71-1.27,1.04
		c0.24,3.54,0.48,7.08,0.72,10.63c0.26,3.78,0.48,7.57,0.8,11.35c0.65,7.66,1.69,15.3,1.94,22.97c0.23,7.23-0.35,14.48-0.58,21.73
		c2.24-0.13,2.16-1.73,2.11-3.33c-0.25-8.07-0.52-16.15-0.71-24.22c-0.28-11.66-0.46-23.33-0.75-34.99
		C445.7,340.3,445.35,338.27,445.09,335.86z"
          />
          <path
            className="st3"
            d="M215.15,662.19c6.25,3.42,12.15,6.65,18.15,9.94c2.74-1.85,5.43-3.66,8.3-5.59
		C233.65,660.49,224.68,661.01,215.15,662.19z"
          />
          <path
            className="st3"
            d="M843.12,409.13c-0.76-4.06-1.55-8.28-2.24-11.95c-1.37-0.53-3.15-0.7-4.09-1.68
		c-3.73-3.88-1.69-8.27-0.52-12.29c-2.97-1.83-5.72-3.53-8.43-5.2C827.88,387.91,836.63,405.6,843.12,409.13z"
          />
          <path
            className="st4"
            d="M415.2,790.52c-3.37,7.87-10.51,11.85-16.45,16.57c0.25,2.64,0.47,5.03,0.71,7.52
		C410.55,808.38,416.12,800.03,415.2,790.52z"
          />
          <path
            className="st1"
            d="M442.84,469.29c0.02,3.56-0.01,7.11,0.09,10.67c0.02,0.76,0.02,1.58,0.23,2.24c0.28,0.85,0.93,1.44,2.47,1.32
		c0,0,0,0,0,0c0.37-12.82,0.73-25.63,1.1-38.51c0.12-4.29,0.24-8.59,0.37-12.91c-0.48,0.13-0.94,0.26-1.38,0.38
		c-0.45,0.12-0.89,0.24-1.33,0.36c0,0,0,0,0,0C443.87,445,443.36,457.15,442.84,469.29z"
          />
          <path
            className="st3"
            d="M797.11,137.68c0.15-0.31,0.29-0.63,0.44-0.94c-7.29-4.56-14.54-9.16-21.89-13.62
		c-1.01-0.61-2.45-0.51-3.79-0.75c0,2.36,0,3.9,0,5.47c4.65,2.57,9.25,5.13,14.91,8.26C789.32,136.49,793.21,137.08,797.11,137.68z"
          />
          <path
            className="st3"
            d="M51.17,725.67c5.59,0.89,7.12-0.14,9.24-4.72c-2.5-2.08-5.08-4.22-6.95-5.78c-2.73,0.86-4.58,1.41-6.39,2.04
		c-0.63,0.22-1.57,0.54-1.72,1.03c-0.33,1-0.83,1.74-1.44,2.32c-1.34,1.27-3.23,1.73-5.04,2.35
		C42.51,725.71,47.03,725.01,51.17,725.67z"
          />
          <path
            className="st3"
            d="M38.86,722.9c-0.02-0.02-0.05-0.03-0.07-0.05l0.05,0.05C38.84,722.9,38.85,722.9,38.86,722.9z"
          />
          <path
            className="st12"
            d="M1023.99,485.22c4.96-1.51,9.64-2.93,14.65-4.46c-0.71-3.1-1.32-5.76-1.8-7.88
		c-5.28,1.22-10.41,2.4-15.78,3.64C1021.95,479.16,1022.89,481.95,1023.99,485.22z"
          />
          <path
            className="st15"
            d="M991.63,399.9c0.15-2.68,0.18-5.37,0.13-8.05c-0.21-10.93-0.46-21.85-0.7-32.78
		c-0.22-0.09-0.44-0.14-0.68-0.16c-1.65,2.16-3.29,4.33-4.94,6.49c0.02,0.23,0.02,0.46,0.04,0.7c0.01,0.23,0.04,0.46,0.11,0.68
		c2.72,8.69,6.3,17.34,2.52,26.59c-1.43,3.49,0.06,5.79,2.3,8.05C990.83,400.91,991.6,400.43,991.63,399.9z"
          />
          <path
            className="st5"
            d="M192.7,690.83c-14.76-9.91-29.51-19.82-44.3-29.76c-0.81,0.57-1.46,1.02-2.89,2.01
		c16.38,9.02,30.3,20.81,46.47,28.9C192.22,691.6,192.46,691.21,192.7,690.83z"
          />
          <path
            className="st3"
            d="M986.82,316.74c-0.25,1.51-0.34,3.15-0.01,4.63c0.85,3.75,1,8.02,5.58,9.65c0.75-0.51,1.51-1.01,2.26-1.52
		c-0.59-0.75-1.19-1.51-1.8-2.29c-0.57-0.72-1.14-1.46-1.77-2.26c-0.43-0.55-0.89-1.13-1.37-1.74c1.04,0,1.67,0,2.2,0
		c0.18,0,0.34,0,0.51,0c-0.22-3.53,2-4.89,4.92-5.86c0.67-0.23,1.2-0.91,1.73-1.54c0.18-0.21,0.36-0.42,0.55-0.6v0
		c-0.46-0.72-0.92-1.44-1.38-2.16l0,0c0.01,0.02,0.02,0.04,0.03,0.06c-0.01-0.02-0.02-0.04-0.03-0.06c0,0,0,0,0,0l0,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.23-0.47-0.47-0.94-0.7-1.41c0,0,0,0,0,0c-1.06,0.01-2.13,0.16-3.16,0
		C990.12,310.95,987.52,312.59,986.82,316.74z"
          />
          <path
            className="st3"
            d="M997.54,311.6c0,0.01,0.01,0.01,0.01,0.02l0,0C997.55,311.61,997.55,311.6,997.54,311.6z"
          />
          <path
            className="st3"
            d="M997.55,311.62c0.02,0,0.04,0,0.06,0c0,0-0.02,0-0.04-0.01c-0.02,0-0.03-0.01-0.03-0.01
		C997.55,311.6,997.55,311.61,997.55,311.62C997.55,311.62,997.55,311.62,997.55,311.62z"
          />
          <path
            className="st3"
            d="M372.79,376.05c1.57,6.38,0.11,13.57,5.59,19.16c4.17-1.95,8.12-3.8,11.86-5.55
		C383.52,386.21,377.28,382.37,372.79,376.05z"
          />
          <path
            className="st1"
            d="M728.02,262.24c0.26-0.62,0.52-1.24,0.78-1.86c0.26-0.62,0.52-1.24,0.78-1.86
		c-5.49-5.96-10.97-11.92-16.46-17.88c-3.36,0.7-2.54,2.92-1.56,4.87c1.03,2.07,2.42,3.97,3.65,5.95c0,0,0,0,0,0
		c1.2,1.01,2.41,2.03,3.61,3.04C721.89,257.09,724.95,259.67,728.02,262.24L728.02,262.24z"
          />
          <path
            className="st12"
            d="M884.45,375.97c-0.03-1.63,0.1-3.17,0.34-4.64c-1.33,0.72-2.18,1.18-2.63,1.43
		C882.84,373.71,883.64,374.84,884.45,375.97z"
          />
          <path
            className="st12"
            d="M883.72,351.69c-1.42,1.82-2.7,3.45-4.34,5.56c1.66,1.22,3.1,2.28,4.88,3.6c-0.98,0.64-1.41,0.92-1.76,1.15
		c0.41,1.04,0.82,2.07,1.23,3.1c-0.25,0.22-0.5,0.45-0.74,0.67c-1.67-0.97-3.34-1.93-5.17-2.99c0.07,1.2,0.14,2.23,0.15,2.55
		c2.46,2.04,4.46,3.7,6.88,5.71c1.02-5.72,3.97-10.38,7.89-14.62C889.74,354.85,886.82,353.32,883.72,351.69z"
          />
          <path
            className="st12"
            d="M885.03,371.2c-0.07-0.06-0.13-0.11-0.19-0.16c-0.02,0.1-0.04,0.2-0.05,0.29
		C884.87,371.28,884.94,371.24,885.03,371.2z"
          />
          <path
            className="st8"
            d="M755.71,166.53c-0.37,0.14-0.74,0.3-1.11,0.42c-0.01,0.67-0.01,1.33-0.02,2c4.86-0.56,9.7-1.41,14.58-1.62
		c6.35-0.27,12.74-0.22,19.1,0.06c3.82,0.17,7.61,1.03,11.42,1.58c-0.34-1.17-0.69-2.34-0.97-3.3c-7.87-0.81-15.26-1.57-22.64-2.33
		c-0.2,0.1-0.41,0.18-0.62,0.24c-6.2,0.87-12.41,1.73-18.61,2.64C756.46,166.27,756.08,166.39,755.71,166.53z"
          />
          <path
            className="st11"
            d="M1072,340.31c-1.88-0.85-3.85-1.51-5.65-2.51c-0.55-0.31-1.1-0.63-1.64-0.96c-0.72-0.45-1.44-0.91-2.15-1.38
		c-0.61-0.4-1.21-0.8-1.82-1.2c-0.27-0.18-0.55-0.36-0.82-0.54c-0.48,0.72-0.97,1.45-1.46,2.17c0.19,0.1,0.37,0.21,0.55,0.32
		c0.96,0.55,1.91,1.11,2.84,1.69c0.57,0.35,1.13,0.71,1.69,1.07c2.36,1.54,4.63,3.17,6.77,4.93c0.61,0.5,1.21,1.01,1.8,1.52
		c1.18,1.03,2.32,2.11,3.42,3.22c1.1,1.11,2.17,2.27,3.19,3.47c0.51,0.6,1.01,1.21,1.5,1.83c1.96,2.48,3.76,5.13,5.37,7.98v0
		c0.53-0.78,1.06-1.55,1.58-2.33c0,0,0,0,0,0c-0.6-1.26-1.18-2.53-1.77-3.8c-0.56-1.21-1.13-2.42-1.71-3.62
		c-0.4-0.82-0.8-1.64-1.21-2.45c-0.57-1.12-1.42-2.15-2.32-3.04c-1.75-1.73-3.58-3.38-5.47-4.95
		C1073.93,341.12,1072.93,340.74,1072,340.31z"
          />
          <path
            className="st3"
            d="M1159.39,422.22c-0.78-0.6-1.57-1.17-2.4-1.69c-4.95-3.07-10.06-5.9-14.91-9.1
		c-4.32-2.85-8.38-6.09-12.38-9.03c0,2.35,0,4.71,0,6.68c3.42,1.75,6.86,3.37,10.18,5.21c6.21,3.44,12.32,7.05,18.55,10.45
		c0.8,0.43,2.05,0.03,3.09,0.01c0.07-0.25,0.13-0.49,0.2-0.74C1160.94,423.43,1160.17,422.81,1159.39,422.22z"
          />
          <path
            className="st12"
            d="M880.42,348.2c-2.23-0.15-5.15-0.35-8.01-0.55c-1-1.15-2.13-2.43-3.29-3.76c-0.76,5.51-1.44,10.41-2.13,15.36
		c1.71,0.65,3.83,1.46,6.05,2.31C875.49,357.13,877.99,352.6,880.42,348.2z"
          />
          <path
            className="st3"
            d="M958.13,385c0.01-1.5,0.43-3,0.66-4.49c-1.16,0-2.32,0-3.12,0c-0.94,2.28-1.72,4.17-2.44,5.94
		c2.04,3.75,1.94,7.26-0.8,10.78c5.39,3.62-1.09,6.98-0.38,9.91c0.58,0.54,1.14,1.04,1.67,1.53c1.59,1.46,2.98,2.74,4.37,4.01
		C958.09,403.46,958.06,394.23,958.13,385z"
          />
          <path
            className="st5"
            d="M286.53,560.85c-2.21,3.33-2.82,6.7-0.65,10.21c2-1.8,2.5-4.03,2.3-6.66c-0.39-5.16-0.57-10.33-0.84-15.5
		c-1.07-0.87-1.93-1.89-1.99-2.95c-0.05-0.97,0.02-1.95,0.12-2.94c0.1-0.98,0.24-1.97,0.33-2.95c-0.43,0.05-0.86,0.1-1.29,0.14l0,0
		c-0.22,0.22-0.44,0.44-0.67,0.66c-2.48,2.64-4.38,5.5-3.6,9.38c0.25,0.71,0.49,1.42,0.74,2.13c0.25,0.72,0.5,1.45,0.75,2.17
		C283.33,556.63,284.91,558.72,286.53,560.85z"
          />
          <path
            className="st12"
            d="M1049.38,452.94c0.72,8.02-6.66,13.9-2.43,22.02c2.09-1.97,4.33-3.99,6.47-6.11
		c3.73-3.68,3.73-6.74,0.27-10.42C1052.36,457,1051.27,455.36,1049.38,452.94z"
          />
          <path
            className="st5"
            d="M221.35,714.67c-0.34-0.06-0.67-0.09-1-0.08c-0.33,0.01-0.65,0.05-0.98,0.13c-0.32,0.08-0.65,0.19-0.97,0.34
		c-0.65,0.3-1.29,0.73-1.96,1.31c9.78,6.02,19.55,12.03,29.33,18.05c0-1.54,0-3.07,0-4.6c-7.13-4.55-14.33-8.85-21.17-13.65
		C223.43,715.35,222.36,714.85,221.35,714.67z"
          />
          <path
            className="st15"
            d="M888.99,312.18c1.72-2.13,1.2-4.1-0.91-5.79c-0.36-0.29-0.36-1.02-0.74-2.25c-0.13,1.76-0.21,2.78-0.29,3.88
		c-0.95,0.56-1.84,1.08-2.42,1.42c-8.17-2.66-11.83-9.44-15.68-16.03c-0.3,0.16-0.59,0.32-0.89,0.49c0.63,3.03,1.25,6.07,1.88,9.1
		c0.37,0.32,0.81,0.6,1.13,0.94c0.11,0.12,0.21,0.25,0.29,0.39c2.35,4.52,6.41,7.08,10.8,9.03
		C884.42,314.37,887.15,314.48,888.99,312.18z"
          />
          <path
            className="st15"
            d="M869.86,302.94c0,0,0.01,0.01,0.02,0.02L869.86,302.94C869.86,302.94,869.86,302.94,869.86,302.94
		C869.86,302.95,869.86,302.95,869.86,302.94c0.03,0.03,0.05,0.06,0.08,0.09c0-0.01,0-0.02,0-0.02c0,0,0,0,0,0
		C869.92,302.98,869.89,302.96,869.86,302.94z"
          />
          <path
            className="st4"
            d="M308.23,421.99c1.2-0.96,2.34-2.14,3.6-2.89c0.18-0.11,0.36-0.21,0.55-0.29c0.37-0.17,0.76-0.31,1.16-0.38
		c1.21-0.22,2.56,0.09,4.2,1.34c0.15-0.26,0.3-0.52,0.46-0.77c-1.21-2.28-2.42-4.57-4.14-7.81c-2.53,1.4-5.18,2.49-7.36,4.17
		c-4.42,3.41-8.6,7.15-12.87,10.75c-0.32-0.2-0.63-0.4-0.95-0.6c0.34,1.45,0.68,2.89,1.02,4.34
		C297.02,424.22,302.93,423.66,308.23,421.99z"
          />
          <path
            className="st4"
            d="M926.54,308.18c-0.24,1.36-0.48,2.72-0.72,4.08c4.07,0.51,7.2-0.06,9.67-1.37c3.18-1.7,5.28-4.64,6.92-8.18
		c1.49-3.21,0.82-7.03-1.57-8.69c0.65,3.37-0.79,5.92-3.54,7.05c-2.41,1-5.25,0.95-7.91,1.36c-0.23,0.88-0.47,1.76-0.7,2.65
		c1.69,0.24,3.38,0.48,5.44,0.76C932.06,310.45,929.22,308.96,926.54,308.18z"
          />
          <path
            className="st4"
            d="M95.67,698.07c1.03,3.9,1.87,7.08,2.65,10.05c4.24-2.07,8.4-4.09,12.25-5.97c-1.25-2.47-2.5-4.96-4-7.92
		C103.47,695.33,99.83,696.61,95.67,698.07z"
          />
          <path
            className="st3"
            d="M162.19,711.52c1.08-1.38,2.66-2.68,3.32-4.35c2.74-6.92-1.13-13.88-8.83-16.46
		c-4.16-1.39-8.27-2.92-12.79-4.53c0.66,0.8,1.14,1.84,1.95,2.26c2.05,1.07,4.35,1.68,6.37,2.81c2.96,1.65,5.6,3.72,6.89,7.07
		c0.68,1.78,1.49,3.51,2.23,5.27c0,0-0.08-0.08-0.08-0.08c0,0,0,0,0,0c0,0.02,0.01,0.05,0.01,0.07
		C161.56,706.08,161.85,708.58,162.19,711.52z"
          />
          <path
            className="st8"
            d="M951.32,548.73c-0.19-1.08,0.47-2.31,0.64-3.08c-4-1.92-7.91-0.61-11.71,1.67c-1.39,0.84-3.07,1.2-4.46,2.05
		c-1.46,0.89-2.86,1.98-4,3.23c-0.43,0.47-0.07,1.67-0.07,2.48c6.71-1.58,10.78-7.08,17.67-8.97c0.12,7.72-0.64,14.43,3.79,20.2
		c0.48-1.87,0.88-3.79,0.59-5.59C953.12,556.7,952.01,552.75,951.32,548.73z"
          />
          <path
            className="st15"
            d="M1057.59,416.11c-2.96-1.91-6.06-3.64-8.85-5.78c-4.02-3.09-7.9-6.42-9.76-11.66c0,1.69-0.19,3.4,0.04,5.06
		c0.64,4.79,3.74,7.88,7.62,10.27c1.64,1.01,3.81,1.35,5.17,2.6c2.37,2.19,4.29,4.85,6.42,7.34
		C1060.7,422.25,1060.47,417.96,1057.59,416.11z"
          />
          <path
            className="st9"
            d="M478.17,474.07c-7.62,3.81-15.25,7.62-22.87,11.43h0c1.14,1.09,2.29,2.17,3.43,3.25
		c7.79-3.95,15.58-7.9,23.37-11.85C480.79,475.96,479.48,475.01,478.17,474.07z"
          />
          <path
            className="st3"
            d="M247.28,554.24c-0.53-3.33-2.75-4.67-8.19-4.75c-1.78,3.87-5.92,7-2.85,11.96c0.55-1.61,1.09-3.2,1.93-5.65
		c0.56,3.53,0.96,6.02,1.35,8.51c0.28,0,0.57,0,0.85,0c0.38-2.32,0.77-4.65,1.15-6.97c0.45,0.02,0.9,0.04,1.34,0.05
		c0.44,2.14,0.88,4.29,1.32,6.42C247.36,562.75,248.21,560.05,247.28,554.24z"
          />
          <path
            className="st7"
            d="M752.32,154.17c-5.03,1.33-9.63,2.4-14.12,3.79c-4.42,1.37-8.63,3.68-13.13,4.46
		c-1.94,0.34-3.17,1.04-3.99,2.05c-0.82,1.01-1.24,2.32-1.55,3.87c0,0,0,0,0,0c10.93-3.6,21.86-7.2,32.79-10.8
		C752.32,156.65,752.32,155.77,752.32,154.17z"
          />
          <path
            className="st15"
            d="M703,336.03c-0.48-0.02-1-0.1-1.51-0.17c-0.85-0.12-1.68-0.2-2.3,0.12c-4.71,2.51-9.26,5.3-14.48,8.35
		c4.37,1.72,7.98,3.14,11.52,4.53c0.51-0.49,0.98-0.93,1.42-1.36c0.45-0.43,0.87-0.83,1.29-1.23c0.23-0.16,0.46-0.31,0.69-0.47
		c0,0,0.07-0.05,0.13-0.1c0.07-0.05,0.13-0.1,0.13-0.1c0.47-2.06,0.83-4.14,1.44-6.16C701.69,338.23,702.43,337.16,703,336.03z"
          />
          <path
            className="st4"
            d="M291.61,702c-6.87-0.8-13.83-1.61-21.44-2.5c0.29,3.33,0.62,7.07,0.96,10.91
		C278.28,707.47,284.91,704.75,291.61,702z"
          />
          <path
            className="st16"
            d="M812.06,401.84c-1.18-2.05-2.36-4.1-3.53-6.14c1.94-0.37,3.73-0.71,5.53-1.05c-2.4-2.34-4.67-0.94-6.58-0.09
		c-0.64,3.4-1.21,6.43-1.77,9.42c0.8,4.43,1.62,9.03,2.35,13.11c1.28-0.67,2.96-1.54,4.64-2.42
		C812.49,410.39,812.28,406.11,812.06,401.84z"
          />
          <path
            className="st4"
            d="M829.03,268.45c0.49,1.03,1.02,2.05,1.52,3.07c2.16,1.89,4.32,3.79,6.48,5.68l0,0
		c0.22,0.03,0.45,0.06,0.67,0.08c0.92-0.24,1.85-0.48,2.77-0.73l0,0h0l0,0c0.04-0.47,0.09-0.93,0.13-1.4
		c0.09-0.94,0.17-1.88,0.26-2.85s0.18-1.97,0.28-3c0.14-1.56,0.3-3.21,0.46-5.01c-2.97,0.87-5.26,1.54-7.8,2.29
		c-0.28-0.25-0.67-0.6-1.1-0.98c-0.43-0.39-0.91-0.82-1.4-1.25C828.12,264.35,827.86,266.01,829.03,268.45z"
          />
          <path
            className="st15"
            d="M570.27,489.57c-4.27-2.78-7.94-5.16-11.14-7.25c-1.54,0.42-2.58,0.91-3.63,0.93
		c-1.34,0.03-2.69-0.31-4.29-0.53c0.58,1.12,0.98,1.88,1.35,2.59c-2.06,0.43-3.88,0.81-5.71,1.19c0,0,0,0,0,0
		c0.19,0.31,0.38,0.63,0.6,0.99c0.23,0.38,0.49,0.81,0.81,1.33c-1.85-0.38-3.27-0.67-4.79-0.98c0.08,1.32,0.15,2.42,0.22,3.53
		c3.7,2.04,7.42,4.09,11.14,6.14c0.13-0.24,0.26-0.47,0.38-0.71c-2.38-1.8-4.77-3.6-7.19-5.44c0.37-0.36,0.75-0.69,1.14-1
		c5.72-4.66,12.44-4.44,16.99,0.48c0,0,0.01,0,0.01,0c0.31-0.1,0.62-0.19,0.93-0.29C568.03,490.27,569.03,489.96,570.27,489.57z"
          />
          <polygon
            className="st15"
            points="566.22,490.94 566.22,490.93 566.22,490.93 	"
          />
          <path
            className="st15"
            d="M546.82,486.46l-0.07,0.07c0.03-0.01,0.06-0.01,0.1-0.02C546.84,486.5,546.83,486.48,546.82,486.46z"
          />
          <path
            className="st5"
            d="M1136.68,392.34c11.77,8.38,22.22,18.89,36.75,22.77C1162.48,405.38,1148.83,400.11,1136.68,392.34z"
          />
          <path
            className="st3"
            d="M982.06,501.39C982.06,501.39,982.06,501.39,982.06,501.39c1.33-1.12,2.65-2.25,4.06-3.45
		c-1.51-1.22-2.92-2.36-4.4-3.55c0.12-0.72,0.24-1.41,0.41-2.39c-1.54,0.98-2.8,1.77-3.97,2.51c-3.13-2.49-6.02-4.79-9.3-7.39
		c-0.74,1.59-1.03,3.03-0.88,4.35C968.44,495.47,972.99,498.46,982.06,501.39z"
          />
          <path
            className="st12"
            d="M1012.24,434.68c-2.16-2.19-6.43-2.23-6.04-7.59c1.24,0.49,2.38,0.93,3.46,1.36
		c1.09,0.43,2.13,0.83,3.16,1.24c0.12,0.43,0.23,0.86,0.35,1.29c0.29-0.26,0.57-0.51,0.86-0.77c-0.24-0.37-0.48-0.75-0.72-1.12
		c-2-1.42-3.99-2.84-5.99-4.26c0.12-0.23,0.24-0.46,0.36-0.69c2.75,0.87,5.5,1.75,8.23,2.62c1.39-1.17,2.7-2.28,3.98-3.36
		c-2.73-4.23-8.66-5.81-11.15-2.84c-2.69,3.2-4.81,6.86-7.16,10.3c3.85,1.89,6.99,3.43,10.14,4.97
		C1011.89,435.48,1012.13,434.95,1012.24,434.68z"
          />
          <path
            className="st10"
            d="M703.54,398.73c-5.94,1.46-8.79,6.01-11.25,10.99c-0.94,1.91-1.9,3.81-2.86,5.71
		c1.88,0.25,3.76,0.49,5.64,0.74c2.04-6.1,6.02-10.82,10.69-15.04c0,0,0.03-0.02,0.03-0.02c0.24-0.22,0.47-0.45,0.71-0.67
		c-0.24,0.22-0.47,0.45-0.71,0.67c0.24-0.22,0.47-0.45,0.71-0.67c0.48-0.61,0.97-1.22,1.58-2c0.2-0.26,0.42-0.54,0.66-0.84
		c-1.24-0.16-2.28-0.3-3.28-0.43c-0.33-0.04-0.66-0.09-0.99-0.13C704.17,397.61,703.97,398.62,703.54,398.73z"
          />
          <path
            className="st12"
            d="M375.23,689.04c-3.38-3.07-6.49-5.9-9.48-8.62c-5.11,2.82-7.03,5.84-5.49,8.19
		C363.22,693.11,368.36,693.39,375.23,689.04z"
          />
          <path
            className="st12"
            d="M867.82,383.26c5.85-1.56,11.53-3.07,17.5-4.66c-2.71-1.87-4.97-3.43-7.26-5.01
		c-2.44,0.35-5.05,0.77-7.67,1.1c-3.6,0.46-4.55,1.93-3.48,5.48C867.13,380.93,867.35,381.69,867.82,383.26z"
          />
          <path
            className="st3"
            d="M43.45,729.8c-0.31,0.38-1.32,0.18-1.98,0.25c-0.92-2.47-1.79-4.81-2.66-7.15c-0.64-0.11-1.3-0.21-2.35-0.38
		c0,2.44,0,4.89,0,7.99c4.4,0.36,8.31,0.77,12.24,0.97c3.02,0.15,6.05,0.03,9.63,0.03c1.29-2.14,6.67-2.7,4.79-7.11
		c-3.47,1.19-6.8,2.73-10.29,3.42C49.7,728.43,46.09,726.52,43.45,729.8z"
          />
          <path
            className="st3"
            d="M38.79,722.85c0.01,0.02,0.01,0.03,0.02,0.05c0.01,0,0.02,0,0.03,0.01C38.84,722.9,38.79,722.85,38.79,722.85z
		"
          />
          <path
            className="st10"
            d="M427.13,434.76c0.25-0.19,0.51-0.38,0.76-0.57c0.6-2.79,1.19-5.59,1.79-8.38c0,0,0,0,0,0c0,0,0-0.01,0-0.01
		c0,0,0,0,0,0c-4.8,2.89-9.67,5.35-15.42,2.56c-0.48,2.37-0.95,4.75-1.43,7.12C417.64,436.03,422.48,437.13,427.13,434.76z"
          />
          <path
            className="st15"
            d="M543.18,484.37c0.67,0.34,1.35,0.69,2.02,1.03c0.09-0.19,0.18-0.38,0.26-0.58c-0.57-0.32-1.15-0.64-1.72-0.96
		c-0.22-0.32-0.43-0.64-0.66-0.98c-0.23-0.34-0.47-0.69-0.73-1.09c0.32,0.05,0.62,0.09,0.89,0.13c0.82,0.12,1.45,0.2,2.08,0.29l0,0
		c0.37,0.24,0.73,0.48,1.1,0.71c0.25-0.28,0.51-0.56,0.76-0.84c-0.42-0.12-0.85-0.24-1.27-0.36c-0.03,0.02-0.06,0.05-0.09,0.07
		c0.03-0.02,0.06-0.05,0.09-0.07c0.14-0.85,0.28-1.7,0.42-2.54c1.86,0.37,3.15,0.63,4.23,0.85c-0.32-1.53-0.59-2.85-0.87-4.18
		c-2.79-1.87-5.76-3.87-8.95-6.01c-0.97,0.47-2.07,1.01-3.18,1.55c2.5,1.26,5.01,2.51,7.51,3.77c-4.24,3.11-7.76,5.68-11.57,8.48
		c-0.7-0.33-1.72-0.82-2.74-1.3c-1.99-1.54-3.98-3.08-5.96-4.61c-0.84,0.48-1.48,0.85-2.7,1.56c2.97,1.37,5.53,2.55,8.1,3.73
		c1.98,0.89,3.96,1.78,5.94,2.67l0,0c0,0,0,0,0,0l0,0c0,0-0.02,0.01-0.04,0.03l-0.05,0.03c0.04,0.03,0.07,0.05,0.11,0.07
		c0,0,0,0,0,0c0.01,0.07,0.02,0.11,0.02,0.13c0.01,0.04,0-0.03-0.02-0.13c0.04,0.03,0.06,0.04,0.09,0.06
		c-0.02-0.01-0.04-0.03-0.09-0.06c0-0.02-0.01-0.04-0.01-0.07c0.89,0.07,1.79,0.1,2.67,0.23c1.05,0.15,2.08,0.4,3.12,0.61
		c0.02-0.18,0.03-0.36,0.05-0.54c-1.06-0.74-2.11-1.48-3.17-2.21c0.1-0.19,0.2-0.39,0.3-0.58
		C540.47,483.63,541.82,484,543.18,484.37z"
          />
          <path
            className="st15"
            d="M537.53,471.41c0.01,0,0.02-0.01,0.03-0.01c-0.03-0.01-0.05-0.03-0.08-0.04L537.53,471.41z"
          />
          <path
            className="st7"
            d="M1027.4,348.23c2.2,1.48,4.41,2.96,6.61,4.44c1.71-1.55,3.43-3.1,5.55-5.02c0-2.75,0-6.35,0-10.46
		C1034.59,340.35,1030.21,343.4,1027.4,348.23z"
          />
          <path
            className="st3"
            d="M1044.97,569.73c-0.14,0.91,0.28,1.91,0.76,2.92c0.29,0.61,0.6,1.22,0.81,1.81l0,0
		c0.25-0.11,0.52-0.21,0.8-0.31c0.84-0.28,1.72-0.53,2.1-1.11c4.3-6.58,6.82-13.75,7.03-22.08c-3.16,4.74-6.04,8.95-8.78,13.25
		C1046.59,565.94,1045.28,567.79,1044.97,569.73z"
          />
          <path
            className="st3"
            d="M1136.22,387.2c13.95,7.73,27.5,15.24,40.89,22.65C1176.27,405.06,1145.29,387.48,1136.22,387.2z"
          />
          <path
            className="st13"
            d="M710.12,320.86c3.49,2.09,6.7,2.08,9.99-0.68c2.05-1.72,4.49-3.02,6.88-4.26c0.52-0.27,0.97-0.55,1.36-0.86
		c0.77-0.61,1.23-1.33,1.23-2.28c0-0.47-0.11-1-0.36-1.6c-0.99-0.34-1.98-0.67-2.98-1c-4.89,2.79-9.81,5.53-14.64,8.41
		C710.91,319,710.6,320.09,710.12,320.86z"
          />
          <path
            className="st14"
            d="M857.09,397.71c1.15-0.81,2.29-1.61,3.41-2.39c1.12-0.78,2.2-1.55,3.23-2.26c-2.53-7.17-8.28-6.87-14.64-5.77
		c0.16,1.05,0.32,2.06,0.47,3.06c0.15,1,0.3,1.98,0.45,2.96C852.37,394.77,854.73,396.24,857.09,397.71z"
          />
          <path
            className="st5"
            d="M765.33,242.83c4.24,0.26,8.52,0.26,12.71,0.89c1.88,0.28,3.56,1.84,5.33,2.82c2.37-0.02,4.74-0.05,7.11-0.07
		c-0.01,0.02-0.01,0.05-0.02,0.07c1.34-0.21,2.67-0.43,4.07-0.65c1.4-0.22,2.87-0.46,4.45-0.71c0.4-0.9,1-2.26,1.59-3.62
		c-1.86,0.15-3.72,0.26-5.58,0.45c-2.22,0.22-4.63,1.2-6.6,0.63c-2.87-0.83-5.75-1.25-8.63-1.43c-1.44-0.09-2.88-0.12-4.33-0.11
		c-0.01,0-0.01,0-0.02,0c-0.94,0.01-1.88,0.03-2.82,0.06c-0.03,0-0.07,0-0.1,0c-0.92,0.03-1.84,0.08-2.76,0.13
		c-0.05,0-0.1,0.01-0.15,0.01c-0.91,0.05-1.82,0.1-2.73,0.15c-0.06,0-0.11,0.01-0.17,0.01c-0.45,0.03-0.91,0.05-1.36,0.08
		C765.32,241.97,765.33,242.4,765.33,242.83z"
          />
          <path
            className="st12"
            d="M884.19,324.57c-3.25,0.02-6.5,0.04-9.74,0.06c0,3.36,0,6.71,0,10.6c3.75,0.41,6.57,0.72,9.74,1.06
		C884.19,332.1,884.19,328.34,884.19,324.57z"
          />
          <path
            className="st7"
            d="M656.39,451.32c-0.15,0.19-0.31,0.38-0.46,0.57c0.29-0.02,0.57-0.04,0.86-0.06c0.14-0.02,0.28-0.04,0.43-0.06
		c0.46-0.06,1.02-0.14,1.99-0.27c-5.72,6.95-9.22,13.69-3.75,22.7c3.17-5.51,0.35-11.3,2.61-16.06c2.26-4.74,4.8-9.34,7.25-14.07
		C660.92,444.42,659.88,449.51,656.39,451.32z"
          />
          <path
            className="st4"
            d="M162.28,853.6c-2.38,0.57-3.19,1.83-3.15,4.17c0.1,5.36-0.11,10.72,0.1,16.07c0.08,2.04,0.99,4.06,1.52,6.08
		c0.44-0.07,0.87-0.14,1.31-0.21c-0.08-10.01-1.62-20.4,6.7-28.5C166.61,852.03,164.51,853.07,162.28,853.6z"
          />
          <path
            className="st16"
            d="M737.39,167.03c0.25,0.94,0.49,1.88,0.74,2.82h0c3.99-0.82,7.97-1.69,11.97-2.45
		c1.48-0.28,3.01-0.31,4.51-0.45c0.37-0.13,0.74-0.28,1.11-0.42c0.37-0.14,0.74-0.26,1.12-0.32c6.2-0.91,12.41-1.77,18.61-2.64
		c-0.99-0.31-1.99-0.9-2.98-0.88c-6.49,0.15-13.02,0.04-19.45,0.8c-3.3,0.39-6.55,1.2-9.79,2.05c-1.3,0.34-2.59,0.69-3.89,1.02
		C738.69,166.72,738.04,166.88,737.39,167.03L737.39,167.03z"
          />
          <path
            className="st12"
            d="M418.33,470.87c1.52,3.05,3.05,6.04,4.51,9.06c3.59,7.44,7.16,14.89,10.73,22.33c0.1,0.41,0.2,0.82,0.3,1.23
		c0.16-0.04,0.31-0.09,0.47-0.13c-0.11-0.49-0.22-0.98-0.33-1.48c-0.71-11.85-7.1-21.33-13.14-31.02
		C420.08,470.87,419.1,470.87,418.33,470.87z"
          />
          <path
            className="st5"
            d="M146.06,878.53c3.25-0.04,4.83-1.9,6.29-4.32c0.48-0.8,1.15-1.5,1.79-2.21c0.22-0.24,0.43-0.47,0.63-0.71l0,0
		l0,0c0-1.72,0-3.43,0-5.15c-4.95,3.09-9.9,6.19-14.84,9.29c0.71,1.71,1.42,3.43,2.13,5.14
		C143.39,879.85,144.72,878.55,146.06,878.53z"
          />
          <path
            className="st4"
            d="M727.37,432.02c-1.45,1.17-2.9,2.34-4.35,3.51l-0.31,0.06c0,0-0.31-0.06-0.31-0.06c0,0,0,0,0,0
		c-1.65-0.68-3.29-1.36-4.94-2.04l0,0c-0.02,0.14-0.03,0.27-0.05,0.41c-0.05,0.42-0.1,0.88-0.17,1.51
		c-0.48-0.23-0.88-0.42-1.27-0.61c-0.7,0.29-1.41,0.58-2.11,0.87c0,0-0.01,0.01-0.02,0.02s-0.02,0.02-0.02,0.02
		c-0.98,0.67-1.95,1.88-2.94,1.89c-0.54,0.01-1.09-0.11-1.63-0.28c-0.36-0.11-0.73-0.25-1.09-0.4c-0.55-0.22-1.09-0.44-1.64-0.62
		c0,0,0,0,0,0c0.45,1.66,0.91,3.31,1.36,4.95c0.45,1.64,0.89,3.25,1.32,4.82c2.71-2.03,5.08-3.8,7.45-5.58
		c4.01-1.63,8.03-3.26,12.04-4.88C728.26,434.42,727.82,433.22,727.37,432.02z"
          />
          <path
            className="st5"
            d="M965.92,266.4c-1.04-0.18-1.96-0.33-2.88-0.49c-0.3,2.57-0.59,5.13-0.92,7.99c-2.08-3.95-4.1-7.77-6.11-11.6
		c-0.42-0.15-0.83-0.31-1.41-0.52c2.64,6.05,5.37,11.88,7.7,17.86c0.94,2.4,2.15,3.76,5.33,3.91c-1.39-5.74-3.11-11.49,1.92-16.54
		C968.12,266.78,966.96,266.58,965.92,266.4z"
          />
          <path
            className="st3"
            d="M1014.13,491.97c-1.01,4.83-1.96,9.67-3.03,14.48c-1.13,5.1-0.84,9.93,2.55,14.38
		c0.63-9.66,3.32-19.21,1.36-28.91C1014.72,491.94,1014.42,491.96,1014.13,491.97z"
          />
          <path
            className="st3"
            d="M415.25,883.59c-5.79-0.55-6.15-5.1-7.05-9.4c-2.15-0.43-4.32-0.87-6.49-1.31c0,0,0,0,0,0
		c-0.28-0.01-0.56-0.02-0.84-0.03c0.15,0.18,0.3,0.37,0.45,0.55c0.56,3.45,1.12,6.9,1.75,10.77c4.33,0.74,8.74,1.5,14.26,2.45
		C416.2,884.93,415.79,883.64,415.25,883.59z"
          />
          <path
            className="st3"
            d="M1108.28,554.4c1.35,2.19,3.37,5.27,7.2,3.02c0.8-3.15-0.67-5.55-2.69-7.55c-0.95-0.93-1.9-1.86-2.87-2.77
		c-2.9-2.74-5.86-5.42-8.78-8.14c0,0,0,0,0,0c-0.3-0.28-0.6-0.55-0.9-0.83c-0.13,0.1-0.26,0.2-0.38,0.31
		c0.26,0.36,0.52,0.73,0.77,1.09c0.31,0.62,0.62,1.25,0.93,1.87C1103.7,545.8,1105.72,550.27,1108.28,554.4z"
          />
          <path
            className="st9"
            d="M989.64,404.7c-1.92-1.63-3.84-3.27-5.56-4.73c-0.21,1.81-0.48,4.12-0.82,7.15c2.42,2.18-0.29,3.94-1.95,5.68
		c1.88,5.89,3.7,11.57,5.6,17.52c1.33,0.3,2.94,0.66,3.78,0.85c-1.88-3.91-4.22-7.9-5.75-12.19c-2.1-5.88-0.25-8.47,5.41-8.59
		C990.11,408.5,989.88,406.6,989.64,404.7L989.64,404.7z"
          />
          <path
            className="st4"
            d="M785.09,397.14c3.31-0.35,6.64-0.85,8.89,2.51c3.41,1.44,5.06,3.84,4.12,7.88c-0.58,0-1.22,0-1.86,0
		c0.19,1.43,0.38,2.85,0.57,4.28c0.01,0.1,0.03,0.2,0.04,0.31c1.42,1.57,2.84,3.15,4.25,4.72c0.04-4.03,0.08-8.05,0.13-12.08
		c-0.14-0.32-0.29-0.63-0.43-0.95c-0.59-1.31-1.19-2.63-1.83-4.03c-0.26-0.58-0.53-1.17-0.81-1.78c-6.73-4.92-8.47-5.24-15.6-0.99
		C783.41,397.06,784.27,397.23,785.09,397.14z"
          />
          <path
            className="st3"
            d="M1060.12,554.07c-1.31,2.74,1.13,18.65,3.36,21.89C1069.27,571.31,1067.66,560.78,1060.12,554.07z"
          />
          <path
            className="st9"
            d="M888.92,292.93c-8.72,2.01-15.03-1.32-19.24-8.9c-2.6,1.43-2,3.27-0.87,5.38c2.92,5.47,11.5,9.45,17.44,7.85
		C888.21,296.74,890.14,295.9,888.92,292.93z"
          />
          <path
            className="st13"
            d="M1047.03,336.17c-0.76-0.23-1.51-0.46-2.32-0.7c-0.8-0.24-1.66-0.5-2.61-0.79c0,6.74,0,12.95,0,19.46
		c1.02-0.57,1.97-1.09,2.88-1.6c0.91-0.51,1.79-1,2.67-1.49C1047.45,346.09,1047.24,341.13,1047.03,336.17z"
          />
          <path
            className="st9"
            d="M895.76,375.35c-3.45-0.81-3.9-3.8-4.1-6.06c-0.21-2.35,0.84-4.8,1.38-7.22c1.47-0.58,2.41-0.95,3.74-1.48
		c-0.99-0.68-1.64-1.13-2.5-1.72c-0.43,1.1-0.73,1.87-1.23,3.12c0.01-0.04,0.02-0.07,0.03-0.11c-2.57,3.39-4.22,7-3.82,11.3
		c0.18,1.89,0.22,3.84-0.04,5.71c-0.15,1.11-1.03,2.11-1.69,3.37c-1.04-0.82-1.65-1.3-1.87-1.47c-2.44,1.29-4.64,2.45-5.85,3.08
		c3.6,0.65,7.91,1.42,12.57,2.26c0.19-0.35,0.68-1.23,0.76-1.37c-1.11-1.85-1.94-3.24-2.75-4.58
		C892.97,378.41,896.31,378.85,895.76,375.35z"
          />
          <path
            className="st4"
            d="M921.95,213.84c-10.42-6.65-20.78-13.26-31.06-19.83C891.98,199.75,915.28,214.77,921.95,213.84z"
          />
          <path
            className="st16"
            d="M521.96,533.26c1.41,8.75,2.77,17.14,4.13,25.53c0.58,0,1.17,0,1.75,0c0.92-8.49-0.08-16.92-1.33-25.53
		C524.98,533.26,523.78,533.26,521.96,533.26z"
          />
          <path
            className="st12"
            d="M305.47,509.42c-1.33-7.14-2.48-14.32-3.73-21.48c-0.22-1.26-0.71-2.48-1.07-3.72
		c-0.42,0.02-0.84,0.04-1.26,0.07c-0.17,1.36-0.66,2.77-0.46,4.08c1.09,7.44,2.22,14.87,3.64,22.25c0.37,1.95,1.79,3.69,2.67,5.43
		c1.75-0.8,2.7-1.23,3.66-1.66c-0.5-1.16-1-2.33-1.5-3.49C306.74,510.41,305.58,510.02,305.47,509.42z"
          />
          <path
            className="st5"
            d="M981.63,271.6c0.84,0.39,1.82,0.49,2.83,0.52c0.25,0.01,0.51,0.01,0.76,0.01c0.26,0,0.51,0,0.77,0
		c0.51,0,1.02,0,1.52,0.04c0,0,0,0,0,0c-0.59-1.61-0.7-3.86-1.84-4.71c-1.85-1.38-3.8-2.63-5.77-3.84c-1.98-1.21-3.98-2.38-5.95-3.6
		v0c-0.4,2.07-0.8,4.13-1.2,6.17C975.78,268.07,978.55,270.17,981.63,271.6z"
          />
          <path
            className="st9"
            d="M981.14,369.69c-0.07,0.7-0.07,1.42-0.11,2.13c-0.04,0.71-0.12,1.4-0.35,2.05c-2.4,6.67-0.69,12.23,4.48,17.16
		c0.8-3.26,1.14-6.45,0.99-9.57c-0.19-4.02-1.18-7.93-3.02-11.77C982.46,369.69,981.8,369.69,981.14,369.69z"
          />
          <path
            className="st5"
            d="M254,671.76c0.82,0.15,1.63,0.3,2.44,0.45s1.6,0.3,2.4,0.45c1.91-1.08,3.82-2.17,5.74-3.22
		c3.33-1.82,6.68-3.61,10.02-5.41c-0.73-1.13-1.45-2.27-2.18-3.4c1.67,0.68,3.34,1.35,5.01,2.03c1.3-0.77,2.62-1.5,3.9-2.31
		c6.78-4.24,13.56-8.5,20.33-12.75c0.52-0.72,1.03-1.45,1.55-2.17c-2.44-1.21-4.88-2.41-7.22-3.57c-1.38,1.59-2.12,2.91-3.25,3.65
		c-5.9,3.86-11.94,7.52-17.83,11.4c-0.15,0.1-0.28,0.2-0.42,0.32c-0.94,0.8-1.6,2.01-2.42,2.99c0,0,0,0,0,0
		c-0.78,0.84-1.56,1.67-2.34,2.51c-0.13-0.27-0.25-0.54-0.38-0.81c-5.39,2.2-10.78,4.39-16,6.52
		C253.65,669.88,253.82,670.79,254,671.76z"
          />
          <path
            className="st3"
            d="M252.12,446.08c0.81,5.96,1.62,11.93,2.46,18.12c2.53-0.62,4.45-0.97,6.29-1.57c1.86-0.61,3.64-1.46,5.89-2.38
		C258.92,458.06,256.32,451.42,252.12,446.08z"
          />
          <path
            className="st12"
            d="M450.09,342.29c0.01,1.49,0.52,2.98,0.8,4.47c0.89-0.24,1.78-0.48,2.66-0.71
		c-0.44-6.33-1.03-12.65-1.25-18.98c-0.12-3.37,1.18-6.95,0.44-10.11c-0.82-3.46,0.48-5.31,2.55-7.34c0.39-0.38,0.54-1.02,0.8-1.53
		c-3.35,1.96-6.35,3.74-6.14,8.45C450.33,325.1,450.02,333.7,450.09,342.29z"
          />
          <path
            className="st3"
            d="M1082.86,525.98c-4.61,7-2.77,13.66,0.6,20.72C1087.97,543.84,1087.47,531.43,1082.86,525.98z"
          />
          <path
            className="st8"
            d="M400.71,383.66c2.82-1.83,5.64-3.66,8.28-5.37c1.1-7.86,0.1-19.35-1.85-21.67
		C407.27,366.12,404.43,374.81,400.71,383.66z"
          />
          <path
            className="st3"
            d="M292.21,709.22c-0.06-0.29-0.12-0.59-0.18-0.88c-7.08,1.82-14.13,3.7-20.25,7.4c0.35,2.49,0.61,4.41,0.95,6.8
		C279.43,717.97,285.82,713.6,292.21,709.22z"
          />
          <path
            className="st4"
            d="M134.83,868.53c-1.1-6.67-1.71-13.33,1.15-19.84c0.46-1.04-0.13-2.55-0.27-4.2c-0.76,0.56-1.05,0.65-1.13,0.84
		c-4.22,9.78-5.09,19.8-1.56,29.93c0.1,0.28,0.31,0.52,0.6,0.73c0.83,0.62,2.27,1.02,3.3,1.57
		C136.2,874.56,135.33,871.58,134.83,868.53z"
          />
          <path
            className="st4"
            d="M137.02,877.62c-0.03-0.02-0.08-0.04-0.11-0.06c0,0,0,0,0,0c0.01,0.04,0.02,0.08,0.03,0.11
		c0.02,0.02,0.05,0.03,0.07,0.05c0,0,0,0,0,0C137.02,877.7,137.02,877.66,137.02,877.62L137.02,877.62z"
          />
          <path
            className="st12"
            d="M894.38,421.21c-1.84-5.95-3.68-11.9-5.53-17.84c-0.37,0.07-0.75,0.14-1.12,0.21
		c0.08,0.8-0.08,1.72,0.26,2.38c3.52,6.82,3.05,14.2,2.57,21.41c-0.15,2.26-1.34,4.45-2.44,6.65c-0.37,0.73-0.72,1.47-1.02,2.2
		c0.75,0.76,1.5,1.51,2.24,2.27c1.18-1.9,2.69-3.68,3.46-5.73c0.85-2.29,1.03-4.83,1.5-7.26
		C894.32,424.07,894.35,422.64,894.38,421.21z"
          />
          <path
            className="st7"
            d="M890.68,389.81c-4.1-0.04-7.48-0.06-10.85-0.09c-4.38-0.04-8.95,1.32-12.82-2.16
		c-0.6-0.54-2.11-0.08-3.19-0.08c-0.12,0.37-0.23,0.74-0.35,1.11c6.15,2.81,12.3,5.63,18.08,8.27c-0.53-1.38-1.16-3-2-5.17
		c3.66,1.02,6.43,1.78,9.35,2.59C889.4,392.98,889.81,391.96,890.68,389.81z"
          />
          <path
            className="st5"
            d="M284.38,739.35c-2.17-2.52-4.3-4.45-7.56-5.4c-1.47-0.43-2.58-2.12-3.85-3.24c-0.54,0.72-1.09,1.43-1.6,2.11
		c-1.27-0.52-2.38-0.97-3.48-1.42c-0.27,0.31-0.54,0.62-0.8,0.93c0.79,0.91,1.41,2.12,2.39,2.67c6.59,3.67,13.25,7.23,19.94,10.71
		c0.39,0.2,0.88,0.21,1.39,0.17c0.51-0.04,1.06-0.13,1.55-0.14C289.66,743.65,286.57,741.89,284.38,739.35z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M374.62,485.61c0.24-1.66,0.46-3.13,0.76-5.19c-5.93,2.65-11.41,5.11-17.02,7.62c0.87,2,1.6,3.71,2.46,5.7
		C365.6,490.92,370.14,488.24,374.62,485.61z"
            />
          </Link>
          <path
            className="st16"
            d="M765.93,234.15c-6.29-0.42-12.54-1.55-18.79-2.38c0,1.14,0,2.42,0,3.62c10.81,0.51,21.54,1.02,32.36,1.54
		c0.03-0.65,0.05-1.17,0.08-1.62c0.03-0.71,0.06-1.22,0.08-1.72C775.07,233.81,770.47,234.45,765.93,234.15z"
          />
          <path
            className="st12"
            d="M997.21,459.76c-0.34,0.15-0.65,0.3-0.95,0.44c-0.44,0.2-0.83,0.38-1.22,0.56c-0.41,0.19-0.8,0.37-1.19,0.54
		c0.36,1.94,0.56,3.94,1.13,5.82c1.21,3.99,3.85,6.9,6.75,9.59c0.97,0.9,1.96,1.77,2.95,2.66v0c1.14-1.61,2.28-3.22,3.46-4.89
		C1001.27,471.89,998,466.8,997.21,459.76z"
          />
          <path
            className="st12"
            d="M887.53,337.46c-3.06,0.99-5.99,2.78-8.81,2.61c-2.96-0.17-5.8-2.22-8.73-3.46
		c-0.18,0.45-0.53,1.29-0.93,2.27c1.08,1.15,1.9,2.04,2.74,2.93c3.16,3.33,9.73,4.35,13.53,1.86
		C887.49,342.25,889.28,340.38,887.53,337.46z"
          />
          <path
            className="st5"
            d="M78.04,694.83c-4.86,3.09-9.68,6.26-14.33,9.66c-2.09,1.53-3.72,3.67-5.56,5.53
		c8.79-4.78,17.57-9.55,26.46-14.39c-0.15-0.3-0.29-0.57-0.42-0.83c-0.26-0.51-0.49-0.96-0.7-1.38c-0.11-0.21-0.21-0.42-0.32-0.62
		C81.45,693.47,79.56,693.86,78.04,694.83z"
          />
          <path
            className="st5"
            d="M262.97,746.6c0.37-8.36,0.14-8.71-7.59-12.18c-1.39-0.62-2.76-1.26-4.4-2.01c0,1.75,0,3.31,0,4.83
		c0,0.51,0,1,0,1.5C254.97,741.36,258.97,743.98,262.97,746.6z"
          />
          <path
            className="st12"
            d="M826.71,425.45c-1.15-4.7-3.04-6.72-7.17-6.99c-1.05-0.07-2.69,0.47-3.17,1.26
		c-1.81,3.05-0.94,6.62,2.02,9.53C821.02,428.04,823.72,426.81,826.71,425.45z"
          />
          <path
            className="st15"
            d="M563.39,465.75c0.83,0.4,1.63,0.8,1.66,0.81c-0.15,3.26-0.26,5.76-0.37,8.17c5.5-2.41,10.63-4.65,15.45-6.76
		c-2.34-2.52-4.45-4.8-6.57-7.09c-0.22,0.13-0.45,0.27-0.67,0.4c0.61,1.33,1.23,2.67,2.16,4.7c-1.76,4.49-5.86,6.02-8.79,2.39
		c5.4,0.62,5.78,0.48,6.71-2.49c-4.2,1.25-4.95,0.94-5.4-2.35c-0.65,0.12-1.36,0.12-2.01,0.22
		C564.57,463.9,563.7,464.3,563.39,465.75z"
          />
          <path
            className="st15"
            d="M563.3,465.71l0.04,0.04C563.33,465.73,563.3,465.71,563.3,465.71c0,0.02,0,0.05,0,0.07c0,0,0,0,0,0
		c0.02,0,0.05,0,0.07,0l0,0c0-0.01,0.01-0.02,0.01-0.04c0,0,0,0,0,0C563.36,465.74,563.33,465.72,563.3,465.71z"
          />
          <path
            className="st15"
            d="M567.63,463.52c0,0-0.06-0.07-0.06-0.07c0,0,0,0,0,0c0,0.03,0.01,0.05,0.01,0.08
		C567.6,463.53,567.61,463.53,567.63,463.52z"
          />
          <path
            className="st3"
            d="M1090.02,534.68c-0.31,6.28,1.01,12.32-5.57,15.29c2.83,1.84,5.63,3.66,9.12,5.94
		C1095.24,548.09,1093.53,541.73,1090.02,534.68z"
          />
          <path
            className="st15"
            d="M517.91,461.18c0.6,2.7,1.15,5.3,1.75,7.89c0.59,2.56,1.21,5.11,1.84,7.73c1.38-1.11,2.89-2.33,4.29-3.45
		c-0.8-5.31-1.53-10.21-2.31-15.37C521.69,459.01,519.91,460.03,517.91,461.18z"
          />
          <path
            className="st5"
            d="M1118.39,516.09c0.05,0.82,0.18,1.62,0.36,2.38c0.37,1.53,1,2.94,1.87,4.19c0.44,0.63,0.94,1.22,1.49,1.76
		c0.28,0.27,0.57,0.54,0.88,0.79c0.62,0.5,1.3,0.96,2.03,1.37v0c0-4.84,0-9.68,0-14.84c-1.92-0.75-3.86-1.5-5.8-2.26
		C1118.53,511.81,1118.25,514.04,1118.39,516.09z"
          />
          <path
            className="st3"
            d="M965.79,356.75c-6.07-2.26-11.99-4.46-18.68-6.95C953.85,361.1,952.31,363.35,965.79,356.75z"
          />
          <path
            className="st13"
            d="M798.73,444.95c-0.59,0.17-1.27,0.36-2.73,0.77c1.34,3.14,2.28,6.24,3.94,8.9c0.9,1.44,3.26,3.19,4.51,2.9
		c1.85-0.44,3.99-2.27,4.78-4.05c1.37-3.09-0.64-5.44-3.79-7.87c-0.98,1.63-1.77,2.96-2.61,4.35c1.8,0.78,2.83,1.23,4.9,2.12
		c-2.15,0.96-3.55,2.1-4.83,1.97c-0.92-0.09-1.81-1.78-2.5-2.88c-1.63-2.63,0.23-4.38,1.74-6.18c-1.05-0.03-2.11-0.06-3.16-0.09
		C798.89,444.9,798.81,444.93,798.73,444.95z"
          />
          <path
            className="st15"
            d="M524.91,457.01c1.27,5.15,2.47,9.97,3.65,14.72c1.7-0.92,3.42-1.86,4.61-2.51
		c-1.02-5.31-2.01-10.44-3.07-15.91C528.09,454.76,526.4,455.95,524.91,457.01z"
          />
          <path
            className="st16"
            d="M877.22,408.42c2.02-1.66,4.04-3.32,6.54-5.37c-1.27-0.68-2.28-1.23-3.21-1.73c-0.55-0.3-1.07-0.58-1.59-0.86
		c-3.48,0.72-6.97,1.44-10.45,2.16c-0.3,0.56-0.6,1.12-0.9,1.68c-0.21,0.38-0.41,0.77-0.62,1.15
		C870.4,406.45,873.81,407.44,877.22,408.42z"
          />
          <path
            className="st3"
            d="M1075.56,463.43c6.89,0.38,13.17-0.86,17.83-6.57c2.77-3.39,1.84-7.62-2.05-8.98c1,4.67-3.42,11.82-7.89,12.65
		c-0.43,0.08-0.97-0.43-1.47-0.66c-2.62,0.47-5.23,0.94-7.85,1.41C1074.61,462,1075.09,462.72,1075.56,463.43z"
          />
          <path
            className="st3"
            d="M966.82,243.48c-7.95-7.63-17.77-12.72-26.58-19.12C947.81,232.83,949.58,237.38,966.82,243.48z"
          />
          <path
            className="st15"
            d="M532.06,452.26c0.95,5.58,1.86,10.94,2.79,16.36c1.31-0.45,2.22-0.76,3.03-1.03c-0.96-1.5-1.85-2.9-2.79-4.38
		c1.88-1.14,3.32-2.02,4.48-2.73c-0.59-4.17-1.12-7.85-1.67-11.69C535.94,449.95,533.88,451.17,532.06,452.26z"
          />
          <path
            className="st5"
            d="M541.28,782.37c-2.43-0.88-4.87-1.74-7.34-2.49c-1.96-0.59-3.98-1-5.98-1.48c1.62,1.31,3.12,2.8,4.87,3.88
		c2.42,1.5,4.87,2.95,7.32,4.4c2.45,1.44,4.92,2.87,7.37,4.32C547.47,785.25,546.86,784.38,541.28,782.37z"
          />
          <path
            className="st15"
            d="M834.8,338.83c-1.31,2.26-2.18,4.53-0.29,7.37c0.32-2.62,0.57-4.71,0.82-6.8c2.61-3.32,5.85-2.34,8.78-1.38
		c2.16-1.45,4.14-2.77,6.2-4.15c-1.86-1.55-4.01-3.35-6.16-5.13C839.06,330.41,836.15,333.9,834.8,338.83z"
          />
          <path
            className="st7"
            d="M689.43,415.42c0.95-1.9,1.92-3.8,2.86-5.71c2.46-4.98,5.32-9.53,11.25-10.99c0.42-0.1,0.63-1.11,0.93-1.7l0,0
		c-1.47,0.57-2.85,1.14-4.15,1.73s-2.52,1.17-3.66,1.78c-6.81,3.66-10.63,8-11.41,14.89C686.66,415.42,688.04,415.42,689.43,415.42
		C689.43,415.42,689.43,415.42,689.43,415.42z"
          />
          <path
            className="st12"
            d="M1035.06,451.68c0.55-1.44,1.05-2.74,1.94-5.08c2.24,5.5,3.01,10.73,7.42,13.39
		c0.49-0.33,0.97-0.66,1.46-0.99c-2.41-3.78-4.82-7.56-7.38-11.58c4.71-1.23,5.91,1.83,7.46,4.7c2.08-3.96,1.08-4.68-3.79-7.19
		c-3.17-1.63-8.29-0.38-8.66,1.99C1033.26,448.51,1032,451.15,1035.06,451.68z"
          />
          <path
            className="st3"
            d="M819.15,361.06c-1.17,0.58-2.31,1.15-3.9,1.94c0,4.41,0,9.04,0,13.91c1.77-1.05,3.44-2.04,5.08-3.02
		C820.68,369.52,822.81,365.07,819.15,361.06z"
          />
          <path
            className="st7"
            d="M634.24,458.62c-1.42-0.22-3.07,1.02-4.89,1.69c1.92,3.98,5.08,2.71,7.15,2.38
		c7.09-1.14,14.63-1.84,18.25-9.82C648.68,457.81,641.98,459.83,634.24,458.62z"
          />
          <path
            className="st8"
            d="M1046.21,422l0.02-0.02C1046.23,421.97,1046.21,422,1046.21,422c-0.1-0.05-0.2-0.1-0.29-0.16
		c0.1,0.05,0.21,0.09,0.31,0.14c0,0.01,0,0.02,0,0.03c1.68,0.71,3.36,1.43,5.03,2.14c0.98-0.08,1.96-0.17,3.43-0.29
		c-2.81-4.5-7.81-4.55-11.06-7.38c0,6.28,0,12.29,0,18.27c1.13,0.2,2.12,0.37,3.32,0.57c0-0.63,0-1.2,0-1.76
		c-0.24-3.85-0.48-7.69-0.72-11.54C1046.23,422,1046.22,422,1046.21,422z"
          />
          <path
            className="st15"
            d="M525.72,476.64c2.81,1.85,5.11,3.36,7.53,4.96c2.83-2.07,5.61-4.11,8.04-5.89c-1.26-1.44-2.51-2.87-3.76-4.3
		c-2.86,1.27-5.73,2.53-8.74,3.87C527.78,475.73,526.76,476.18,525.72,476.64z"
          />
          <path
            className="st15"
            d="M537.53,471.41c0,0-0.05-0.06-0.05-0.06C537.5,471.38,537.52,471.4,537.53,471.41
		C537.53,471.41,537.53,471.41,537.53,471.41z"
          />
          <path
            className="st4"
            d="M701.66,445.33c-2.28-2.05-4.09-1.19-5.72,0.67c-1.97,2.24-0.42,3.51,1.53,4.78c0.94-1.22,1.78-2.31,2.75-3.57
		c2.47,0.73,2.43,1.94,1.51,3.77c-1.83,3.64-3.73,4.49-7.75,3.11c-0.19,0.25-0.39,0.5-0.58,0.75c1.85,1.4,3.69,2.81,5.38,4.09
		C705.9,453.49,706.52,449.7,701.66,445.33z"
          />
          <path
            className="st3"
            d="M98.11,681.61c-0.35-5.36-0.65-9.99-0.95-14.61c-0.36,0.02-0.71,0.03-1.07,0.05
		c-3.61,6.89-1.27,13.91-0.23,21.71c2.93-1.87,5.35-3.41,7.39-4.71C101.36,683.15,99.43,682.24,98.11,681.61z"
          />
          <path
            className="st3"
            d="M223.67,700.69c0.04-0.65,0.08-1.3,0.12-1.95c0.12-1.97,0.24-4,0.37-6.3c-1.06-0.58-2.72-1.63-4.51-2.38
		c-0.8-0.33-2.14-0.39-2.75,0.07c-0.97,0.72-1.88,1.52-2.77,2.35c-0.89,0.82-1.77,1.67-2.66,2.49
		C215.53,696.87,219.6,698.78,223.67,700.69z"
          />
          <path
            className="st16"
            d="M780.38,227.93L780.38,227.93c-8.84,0-17.68,0-26.51,0v0c0,0.88,0,1.76,0,2.64c8.84,0,17.68,0,26.52,0
		C780.38,229.69,780.38,228.81,780.38,227.93z"
          />
          <path
            className="st8"
            d="M800.57,285.2c0,1.05,0,2.1,0,3.69c4.05,1.27,8.2,2.57,12.71,3.99c0-2.62,0-4.67,0-6.57c0-0.63,0-1.25,0-1.87
		C809.04,284.7,804.81,284.95,800.57,285.2z"
          />
          <path
            className="st15"
            d="M989.48,444.17c1.53-4.43,5.55-4.59,9.27-5.25c-0.19-0.3-0.39-0.59-0.58-0.89c0.58-0.37,1.16-0.75,2.26-1.46
		c-0.39,1.84-0.6,2.86-0.91,4.32c2.94-1.42,5.31-2.56,7.67-3.7c-3.01-1.35-6.3-1.47-9.24-2.56c-3.43-1.26-5.59,0.21-7.23,2.55
		c-0.18,0.25-0.34,0.51-0.5,0.78c-1.1,1.87-1.74,4.07-2.63,6.09C988.22,444.1,988.85,444.13,989.48,444.17z"
          />
          <path
            className="st12"
            d="M368.32,593.86c-1.96-1.16-3.84-2.47-5.88-3.46c-5.63-2.72-11.32-5.3-16.99-7.93
		c-7.4-2.42-14.8-4.84-22.2-7.26c0,0,0,0,0,0c2.93,1.33,5.85,2.7,8.78,4.04c2.93,1.34,5.87,2.67,8.84,3.91
		c2.23,0.93,4.46,1.86,6.7,2.77c6.71,2.74,13.45,5.4,20.17,8.11C367.92,593.97,368.12,593.91,368.32,593.86z"
          />
          <path
            className="st4"
            d="M183.17,669.85c4.96,3.95,10.89,5.21,16.98,5.67c1.64,0.12,3.36-0.9,5.04-1.39c-0.22-0.32-0.45-0.65-0.67-0.97
		c-4.44,0.6-8.77,0.7-12.91-1.75c-3.87-2.29-7.17-5.01-8.7-9.45C179.83,664.52,179.94,667.28,183.17,669.85z"
          />
          <path
            className="st1"
            d="M717.61,158.35c1.62-3.18,3.33-6.52,5.1-9.97c-0.17-2-0.37-4.24-0.61-7.02c-3.17,1.54-6.03,2.92-8.59,4.16
		C717.87,149.03,715.61,154.53,717.61,158.35z"
          />
          <path
            className="st9"
            d="M488.56,478.1c0.82,6.13,1.62,12.12,2.44,18.23c1.45-0.91,2.52-1.58,3.52-2.21
		c-0.63-6.15-1.25-12.22-1.9-18.62C491.21,476.39,489.98,477.19,488.56,478.1z"
          />
          <path
            className="st9"
            d="M486.3,506.34c3.98-8.77,0.6-17.84,0.34-27.19C482.82,481.35,482.89,490.59,486.3,506.34z"
          />
          <path
            className="st14"
            d="M904.86,276.55c0.29,0.49,0.59,0.98,0.88,1.47c1.42-0.01,2.85-0.02,4.27-0.02l0,0
		c0.02-0.39,0.09-0.82,0.16-1.24c0.11-0.7,0.18-1.38-0.1-1.84c-1.02-1.68-2.1-3.32-3.21-4.94c-1.1-1.63-2.23-3.24-3.33-4.87h0
		c-2.24,0-4.48,0-6.72,0.01C899.5,268.93,902.18,272.74,904.86,276.55z"
          />
          <path
            className="st14"
            d="M905.74,278.02c-0.02,0-0.03,0-0.05,0l0,0C905.71,278.02,905.73,278.02,905.74,278.02
		C905.74,278.02,905.74,278.02,905.74,278.02z"
          />
          <path
            className="st12"
            d="M1093.25,372.43c-0.45,1.06-1.33,2.32-1.08,3.23c1.49,5.38,3.26,10.68,4.93,16c0.36-0.07,0.71-0.15,1.07-0.22
		c-0.64-8.54-1.28-17.09-1.93-25.73C1095.2,368.05,1094.19,370.23,1093.25,372.43z"
          />
          <path
            className="st7"
            d="M823.05,436.68c0.86-1.01,1.29-2.52,1.95-3.77c-1.01,0.24-2.19,0.27-2.97,0.84c-3.02,2.22-5.54,0.77-7.58-1.22
		c-1.89-1.84-3.25-4.02-3.74-6.54c0.18,1.73,0.48,3.45,0.84,5.15c0.29,1.37,0.96,2.68,1.13,4.06c0.38,3.01,2.34,4.35,4.96,4.15
		c1.74-0.13,3.65-1.09,5-2.25C822.79,436.96,822.92,436.83,823.05,436.68z"
          />
          <path
            className="st7"
            d="M825.02,432.86c-0.01,0.02-0.02,0.04-0.03,0.06c0.04-0.01,0.08-0.01,0.12-0.02L825.02,432.86z"
          />
          <path
            className="st7"
            d="M810.71,425.99c-0.09-0.89-0.17-1.79-0.18-2.7C810.47,424.22,810.54,425.12,810.71,425.99z"
          />
          <path
            className="st3"
            d="M388.65,357.33c1.97-4.51,3.69-8.44,5.7-13.04c-5.06,2.41-9.48,4.51-14.35,6.82
		C382.92,353.2,385.47,355.04,388.65,357.33z"
          />
          <path
            className="st5"
            d="M957.49,439.51c0.57-0.21,1.15-0.42,1.72-0.63c1.08-1.54,2.24-3.02,3.22-4.62c2.31-3.75,0.91-7.76,0.67-11.69
		c-0.54-0.43-1.08-0.86-1.82-1.45c-0.8,8.19-2.05,8.99-5.74,11.35c0.07,0.68,0.13,1.33,0.2,1.97c0.06,0.64,0.13,1.27,0.19,1.9
		C956.45,437.4,956.97,438.45,957.49,439.51z"
          />
          <path
            className="st5"
            d="M884.82,196.22c-2.25-1.11-4.43-2.18-7.13-3.51c0,4.03,0,7.51,0,11.56c2.06,0.19,4.47,0.42,7.13,0.67
		C884.82,201.62,884.82,199.16,884.82,196.22z"
          />
          <path
            className="st3"
            d="M399.89,883.25c-0.33-3.77-0.65-7.36-0.96-10.89c-2.33-0.84-4.51-1.63-7.13-2.57
		c0.37,3.74,0.71,7.12,1.05,10.57C395.19,881.31,397.44,882.24,399.89,883.25z"
          />
          <path
            className="st11"
            d="M773.15,266.57c8.36,0.34,16.72,1.01,25.08,1.28c2.34,0.07,4.71-0.79,7.06-1.22
		c-0.05-0.3-0.11-0.61-0.16-0.91c-16.66,1.85-33.2,0.33-49.93-1.44c0.95,1.22,2.11,2.14,3.3,2.19
		C763.39,266.63,768.28,266.37,773.15,266.57z"
          />
          <path
            className="st5"
            d="M1163.47,492.12c0-22.16,0-44.31,0-67.26c-0.73,2.88-1.76,5.13-1.81,7.41c-0.18,9.62-0.17,19.26-0.05,28.88
		c0.11,8.7,0.45,17.39,0.68,26.08c0.04,1.63,0.01,3.26,0.01,4.89C1162.69,492.12,1163.08,492.12,1163.47,492.12z"
          />
          <path
            className="st5"
            d="M1053.69,513.18c-7.41,2.68-13.74,6.47-21.63,3.79c2.22,2.08,3.87,3.62,5.94,5.55
		C1042.97,520.23,1050.53,521.44,1053.69,513.18z"
          />
          <path
            className="st12"
            d="M959.79,483.06c0,0,0.03,0.11,0.03,0.11c3.76,0.2,3.36-3.71,5.27-5.33c0.07-0.92,0.14-1.84,0.21-2.75
		c-0.06-1.7-0.06-3.4-0.2-5.09c-0.03-0.36-0.07-0.72-0.12-1.08c-0.14-1.08-0.34-2.16-0.49-3.24c0,0,0,0,0,0
		c-0.17,0.21-0.34,0.42-0.53,0.63c-0.18,0.21-0.37,0.41-0.57,0.62c-0.58,0.62-1.18,1.23-1.67,1.87s-0.88,1.3-1.04,2.01
		c-0.31,1.38-0.49,2.78-0.61,4.2c-0.08,0.95-0.13,1.9-0.18,2.85c-0.07,1.43-0.13,2.87-0.24,4.29
		C959.7,482.45,959.75,482.76,959.79,483.06z"
          />
          <path
            className="st4"
            d="M263.06,715.01c0.01,0.01,0.01,0.01,0.02,0.02c0.25,0.29,0.51,0.59,0.79,0.92c0.18,0.21,0.36,0.42,0.57,0.66
		c0.83-0.61,1.65-1.22,2.94-2.18c-2.3-1.23-4-2.14-5.69-3.05c-0.37-0.23-0.74-0.46-1.11-0.7c-0.09,0.12-0.18,0.23-0.27,0.35
		c0.26,0.28,0.52,0.57,0.78,0.85C261.75,712.93,262.41,713.97,263.06,715.01L263.06,715.01c0,0-0.02-0.02-0.04-0.04
		c-0.02-0.02-0.03-0.04-0.03-0.04c0,0.02,0.01,0.04,0.01,0.07c0,0,0,0,0,0c0,0,0,0,0,0C263.02,715,263.04,715,263.06,715.01
		L263.06,715.01z"
          />
          <path
            className="st4"
            d="M261.26,714.63c-0.19-0.06-0.37-0.13-0.54-0.23c-3.12-1.72-6.19-3.53-9.41-5.39c-0.75,4.76,0.94,7.4,5.2,9.97
		c3.11,1.87,5,0.52,6.93-1.58c-0.15-0.85-0.29-1.62-0.43-2.4C262.43,714.86,261.82,714.8,261.26,714.63z"
          />
          <path
            className="st15"
            d="M511.94,465.3c1.09,4.51,2.11,8.73,3.15,13.05c1.49,0,3.27,0,4.7,0c-0.59-2.73-1.17-5.29-1.68-7.87
		c-0.53-2.69-0.98-5.4-1.5-8.32C514.83,463.36,513.24,464.43,511.94,465.3z"
          />
          <path
            className="st12"
            d="M471.75,633.86c0.45-1.4,0.83-2.57,1.26-3.9c-6.12-2.42-11.89-0.75-18.17,0.61
		C460.36,634.27,465.94,634.38,471.75,633.86z"
          />
          <path
            className="st11"
            d="M441.45,331.89c0-4.28,0-8.48,0-13.41c-2.48,1.56-4.1,2.58-5.62,3.54c0.3,4.24,0.58,8.25,0.91,12.78
		C438.49,333.72,439.94,332.82,441.45,331.89z"
          />
          <path
            className="st5"
            d="M753.96,156.86C753.96,156.86,753.96,156.86,753.96,156.86c0.08,0.27,0.17,0.54,0.25,0.82
		c0.03,0.09,0.05,0.18,0.08,0.26c0.17,0.55,0.34,1.11,0.53,1.72c0.11,0.34,0.22,0.7,0.33,1.08c5.59-0.37,11.05-0.73,16.71-1.11
		c-0.36-1.75-0.62-3-0.88-4.25C765.31,155.88,759.63,156.37,753.96,156.86z"
          />
          <path
            className="st15"
            d="M560.93,465.79c-0.63,1.16-1.12,2.07-1.81,3.36c-1.32-1.75-2.25-2.97-3.38-4.46
		c-0.98,2.41-1.78,4.39-2.62,6.48c3.47,2.05,6.7,3.95,10.18,6.01c0-4.06,0-7.72,0-11.38c-0.66,0-1.32,0-1.86,0
		C561.25,465.79,561.08,465.79,560.93,465.79z"
          />
          <path
            className="st11"
            d="M975.48,425.31c0.62,1.31,1.14,2.4,1.78,3.75c0.15-1.36,0.26-2.38,0.49-4.4c1.13,1.76,1.68,2.62,2.24,3.48
		c0.23-0.06,0.46-0.11,0.69-0.17c-0.4-4.77-0.81-9.54-1.22-14.37c-1.12-0.19-2.18-0.38-3.37-0.58c-0.8,4.55-1.59,9.05-2.47,14.06
		C974.48,426.26,974.86,425.89,975.48,425.31z"
          />
          <path
            className="st15"
            d="M1047.66,351.05c0.82-0.16,1.64-0.32,2.46-0.48c0.82-0.16,1.63-0.32,2.42-0.47c0-4.25,0-8.03,0-12.18
		c-1.85-0.59-3.68-1.17-5.51-1.76C1047.24,341.13,1047.45,346.09,1047.66,351.05z"
          />
          <path
            className="st15"
            d="M1021.11,455.03l0.08,0.08C1021.19,455.11,1021.11,455.03,1021.11,455.03
		C1021.11,455.03,1021.11,455.02,1021.11,455.03c-0.93-1.07-1.86-2.13-2.42-2.76c-0.57,2.71-1.25,5.92-1.93,9.13
		c-0.36,0.15-0.72,0.3-1.08,0.44c-1.55-1.72-2.91-3.7-4.71-5.1c-1.93-1.49-4.29-2.43-6.68-3.73c-0.24,3.39,0.96,6.05,2.89,6.78
		c3.93,1.5,7.9,2.9,11.27,4.14C1019.26,461.21,1020.18,458.12,1021.11,455.03z"
          />
          <path
            className="st3"
            d="M227.67,544.08c1.22-0.53,2.46-1.07,3.31-1.44c0-2.91,0-5.36,0-7.67c-1.68-0.38-2.76-0.63-3.83-0.87
		c0.04-0.28,0.07-0.56,0.11-0.83c0.97-0.5,1.95-1.01,2.79-1.44c-0.59-3.56-1.13-6.86-1.69-10.23
		C226.16,523.23,225.64,540.43,227.67,544.08z"
          />
          <path
            className="st14"
            d="M810.51,318.72c0.47,3.39,0.93,6.77,1.4,10.16c1.14-0.11,2.56,0.18,3.38-0.39c2.53-1.78,4.84-3.88,7.47-6.06
		c-2.05-0.62-4.1-1.24-6.14-1.86C814.57,319.95,812.54,319.34,810.51,318.72z"
          />
          <path
            className="st12"
            d="M1102.85,386.09c-1.43-5.21-2.81-10.43-4.21-15.65c0.81,9.36,0.72,18.83,2.22,28.11
		c0.53-0.02,1.05-0.03,1.58-0.05c0.4-3.64,0.8-7.29,1.2-10.93C1103.37,387.08,1102.99,386.62,1102.85,386.09z"
          />
          <path
            className="st9"
            d="M661.18,347.11c-1.47-3.25-3.42-6.29-5.16-9.42c-0.29,0.16-0.58,0.32-0.87,0.48c0.82,2.46,1.77,4.88,2.44,7.38
		c1.02,3.85,1.96,7.72,2.91,11.6c0.31,1.29,0.63,2.58,0.95,3.87c1.44-0.68,2.88-1.37,4.32-2.06
		C664.25,355,662.92,350.96,661.18,347.11z"
          />
          <path
            className="st5"
            d="M306.72,640.92c3.82-3.04,7.69-6.04,11.54-9.04c-0.55-0.19-1.19-0.53-1.77-0.79
		c-0.59-0.25-1.12-0.41-1.44-0.22c-4.94,2.87-9.71,6.02-14.8,9.26c0.49,0.24,0.96,0.47,1.41,0.68c0.87,0.42,1.67,0.81,2.45,1.19
		c0.05,0.03,0.11,0.05,0.17,0.08c0.37,0.18,0.74,0.36,1.1,0.53C305.82,642.03,306.18,641.35,306.72,640.92z"
          />
          <path
            className="st12"
            d="M878.51,288.81c1.78,0.58,3.55,1.15,5.67,1.84c0-5.59,0-10.52,0-15.45c-1.39,0.69-2.78,1.39-4.18,2.08
		C879.5,281.12,879.01,284.96,878.51,288.81z"
          />
          <path
            className="st11"
            d="M597.62,498.63c1.5,0.08,3,0.17,4.74,0.26c-0.18,3.99-0.34,7.61-0.5,11.23c-0.28,1.12-0.57,2.25-0.91,3.61
		c1.83-0.78,3.31-1.41,4.61-1.97c0.06-0.42,0.11-0.82,0.17-1.21c0.16-1.18,0.31-2.27,0.46-3.35c-1.03-4.88-2.05-9.76-3.01-14.28
		c-1.79,1.83-3.67,3.77-5.55,5.7c0.02-0.01,0.04-0.02,0.06-0.03c-0.02,0.01-0.04,0.02-0.06,0.03
		C597.63,498.62,597.62,498.63,597.62,498.63z"
          />
          <path
            className="st8"
            d="M845.81,252.34c-0.1-0.04-0.18-0.15-0.27-0.23c0,0,0,0,0,0c-0.17,0.27-0.33,0.54-0.5,0.82
		c2.54,1.12,2.29,2.9,1.38,5.04c-0.33,0.77-0.05,1.79-0.05,2.7c0.28,0.17,0.57,0.33,0.85,0.5c1.13-1.29,2.72-2.4,3.29-3.9
		c2.24-5.86,0.85-11.47-1.85-16.87c-0.15,4.12-0.12,8.28-2.52,11.93C846.1,252.37,845.91,252.37,845.81,252.34z"
          />
          <path
            className="st15"
            d="M710.12,320.86c0.48-0.77,0.79-1.85,1.48-2.27c4.84-2.88,9.75-5.62,14.64-8.41
		c-0.73-0.67-1.46-1.34-2.16-1.98c-6.43,3.41-12.63,6.69-19.35,10.25C707,319.46,708.56,320.16,710.12,320.86z"
          />
          <path
            className="st4"
            d="M331.15,762.36c0.94-0.51,1.83-1.07,2.65-1.67c0.33-0.24,0.65-0.49,0.95-0.75c2.34-2,3.93-4.56,4.21-8.04
		c0.08-0.99-0.75-2.05-1.46-3.86c-2.8,6.33-6.88,9.83-12.06,12.18C327.34,760.94,329.24,761.65,331.15,762.36z"
          />
          <path
            className="st5"
            d="M375.5,859.08c0-2.81,0-5.62,0-9.33c-1.36,0.97-2.44,1.32-2.63,1.95c-1.11,3.58-2.02,7.23-2.98,10.85
		c-0.37,1.4-0.72,2.81-1.05,4.09c-2.87-0.42-4.95-0.73-7.04-1.04c-0.04,0.15-0.09,0.3-0.13,0.45c2.63,1.3,5.25,2.59,7.88,3.89
		C370.1,865.54,372.49,862.14,375.5,859.08L375.5,859.08z"
          />
          <path
            className="st3"
            d="M213.53,859.68L213.53,859.68c0.04-0.69,0.06-1.37,0.08-2.05c0.13-4.78-0.22-9.5-2.03-14.05
		c-1.01,2.66-1.99,5.26-2.96,7.82c-0.97,2.56-1.93,5.1-2.89,7.63C208.33,859.25,210.93,859.46,213.53,859.68z"
          />
          <path
            className="st4"
            d="M383.25,876.9c2.38,5.23-1.45,12.55,6.56,15.21C389.97,886.03,391.44,879.76,383.25,876.9z"
          />
          <path
            className="st13"
            d="M773.3,451.36c-0.53,1.48-1.07,2.96-1.6,4.44c-0.3,0-0.61,0.01-0.91,0.01c-0.41-1.24-0.81-2.48-1.22-3.72
		c-1.12,0.01-2.23,0.02-3.35,0.02c-0.29,1.44-0.57,2.87-0.86,4.31c-0.35,0.02-0.69,0.04-1.04,0.06c-0.64-1.23-1.28-2.45-1.92-3.68
		c-0.67,0.23-1.33,0.47-2,0.7c0,1.37,0,2.74,0,4.7c5.98-0.8,11.55-1.54,17.51-2.34c-0.78-2.2-1.32-3.71-1.86-5.23
		C775.14,450.88,774.22,451.12,773.3,451.36z"
          />
          <path
            className="st13"
            d="M704.37,333.22c1.66,1.03,3.32,2.05,5.3,3.28c1.77-3.23,5.65-4.84,5.42-8.91c-0.54-0.28-1.06-0.55-1.56-0.81
		c-1.49-0.77-2.82-1.46-4.14-2.14C707.72,327.5,706.04,330.36,704.37,333.22z"
          />
          <path
            className="st3"
            d="M228.01,699.2L228.01,699.2c-0.37-0.23-0.73-0.46-1.1-0.7c-0.09,0.12-0.18,0.23-0.27,0.35
		c0.26,0.28,0.53,0.57,0.79,0.85c3.75,1.93,7.5,3.86,11.49,5.92c0.82-2.25,1.37-3.74,1.99-5.44c-4.9-1.72-8.96-5.15-14.22-5.54
		c2.1,1.44,5.26,1.33,5.55,4.89c-1.14-0.09-2.19-0.18-3.21-0.26C228.68,699.26,228.35,699.23,228.01,699.2z"
          />
          <path
            className="st12"
            d="M1079.95,430.69c-0.98-3.04-3.49-2.11-5.62-2.17c-4.01-0.11-5.58,3.34-8.02,5.52c2.1,0.79,4.2,1.58,6.62,2.5
		C1075.22,434.64,1077.58,432.66,1079.95,430.69z"
          />
          <path
            className="st4"
            d="M268.5,427.69c0.47-1.09,0.92-2.14,1.36-3.16c1.31-3.06,2.51-5.84,3.71-8.63c0,0,0,0,0,0
		c0.02-0.28,0.04-0.56,0.05-0.84c-0.19,0.15-0.37,0.29-0.56,0.44c-3.98,2.14-7.96,4.28-12.12,6.51c1.69,1.23,2.84,2.05,3.97,2.89
		C265.96,425.69,266.98,426.51,268.5,427.69z"
          />
          <path
            className="st13"
            d="M605.02,488.61c6.38-5.31,10.17-11.7,11.58-20.11c-4.23,5.5-8.08,10.51-12.04,15.66
		C604.68,485.32,604.84,486.79,605.02,488.61z"
          />
          <path
            className="st14"
            d="M695.07,416.16c1.76,0.78,3.51,1.56,4.94,2.19c1.48-4.43,2.91-8.72,4.33-12.98c0.47-1.42,0.95-2.84,1.42-4.25
		C701.09,405.34,697.11,410.06,695.07,416.16z"
          />
          <path
            className="st15"
            d="M494.98,474.76c0.29,2.5,0.58,5.06,0.88,7.62c0.29,2.46,0.61,4.92,0.97,7.88c1.62-1.68,2.84-2.94,3.9-4.04
		c-0.62-4.73-1.17-8.94-1.75-13.37C497.54,473.53,496.43,474.06,494.98,474.76z"
          />
          <path
            className="st12"
            d="M1034.57,440.42c1.17-2.57,1.18-4.86-1.17-7.01c-4.7,1.6-6.21,11.78-2.96,16.75
		C1031.73,447.08,1033.07,443.71,1034.57,440.42z"
          />
          <path
            className="st13"
            d="M1023.71,335.36c-3.03-0.51-6.05-1.02-9.07-1.53c-1.31,2.49-3.01,4.88-1.97,7.94
		c1.41,0.25,2.83,0.5,4.24,0.75C1019.17,340.13,1021.44,337.75,1023.71,335.36z"
          />
          <path
            className="st3"
            d="M814.87,381.47c-0.61,2.37-1.17,4.56-1.62,6.32c2.94,2.23,5.37,4.07,7.39,5.6c-0.33-4.58-0.67-9.41-1.01-14.27
		C818.26,379.8,816.52,380.66,814.87,381.47z"
          />
          <path
            className="st4"
            d="M111.11,613.21c-3.54-2.83-7.09-5.67-10.63-8.5c1.06,4.65,2.11,9.31,3.13,13.8c1.28-0.91,2.54-1.8,3.79-2.68
		S109.88,614.08,111.11,613.21z"
          />
          <path
            className="st12"
            d="M899.38,346.05c-1.35,0-2.7,0-4.05,0c0.23-0.44,0.47-0.87,0.7-1.31c-1.49-1.48-2.98-2.95-4.48-4.43
		c-1.57,3.16-3.14,6.32-4.71,9.47c0.28,0.27,0.56,0.54,0.84,0.81c1.02-0.25,2.07-0.41,3.12-0.58c0.53-0.08,1.05-0.16,1.57-0.25
		c0.52-0.09,1.04-0.2,1.55-0.32c1.02-0.26,2-0.6,2.92-1.14c0.46-0.27,0.91-0.58,1.33-0.95S899,346.55,899.38,346.05z"
          />
          <path
            className="st13"
            d="M910.52,437.65c-1.93-2.14-3.78-4.35-5.81-6.39c-1.69-1.7-3.41-3.38-5.14-5.05c-1.73-1.67-3.47-3.33-5.19-5
		c-0.03,1.43-0.05,2.86-0.08,4.29c1.34,0.97,2.9,1.74,3.99,2.95c2.67,2.96,5.01,6.22,7.71,9.16
		C906.99,438.69,908.53,440.39,910.52,437.65z"
          />
          <path
            className="st12"
            d="M849.45,382.32c3.06,0,5.85,0,8.82,0c0.31-2.07,0.59-3.99,0.89-6c-3.37-0.31-6.58-0.61-9.71-0.9
		C849.45,377.93,849.45,380,849.45,382.32z"
          />
          <path
            className="st5"
            d="M359.41,853.31c2.43,1,5.1,3.25,7.97,0.98c-5.86-3.73-11.25-7.16-16.56-10.54
		C350.14,847.97,351.96,850.26,359.41,853.31z"
          />
          <path
            className="st4"
            d="M275.63,688.63c-4.19-3.97-6.37-3.96-13.02,0.66c3.66,1.87,6.84,3.49,9.62,4.91
		C273.48,692.15,274.54,690.41,275.63,688.63z"
          />
          <path
            className="st12"
            d="M987.35,414.08c2.07,6,2.42,12.42,9.17,15.14C996.91,423.2,992.32,415.34,987.35,414.08z"
          />
          <path
            className="st3"
            d="M394.21,893.32c2.32,0.46,4.22,0.84,6.27,1.25c0-2.81,0-5.27,0-7.69c-2.55-1.19-4.95-2.31-7.63-3.56
		C393.33,886.84,393.78,890.11,394.21,893.32z"
          />
          <path
            className="st9"
            d="M431.34,397.26c0.14,3.96,0.09,7.93,0.24,11.9c0.06,1.67,0.47,3.33,0.72,4.99c4.14-8.83,2.25-17.5-0.42-26.66
		c-0.32,1.64-0.62,3.14-0.9,4.57c-0.28,1.43-0.55,2.8-0.82,4.16C430.56,396.56,431.33,396.9,431.34,397.26z"
          />
          <path
            className="st5"
            d="M442.83,786.28c-4.19-2.09-8.51-3.86-12.42-6.3c-3.56-2.22-7.17-3.01-11.21-2.53
		c7.87,4.05,15.75,8.11,23.62,12.16C442.83,788.55,442.83,787.49,442.83,786.28z"
          />
          <path
            className="st12"
            d="M802.66,474.19c1.24,1.66,2.48,3.32,3.72,4.97c1.43,1.1,2.86,2.19,4.29,3.29c0.12-0.12,0.24-0.23,0.35-0.35
		c0.23-0.23,0.45-0.47,0.65-0.71c0.5-0.59,0.88-1.2,1.04-1.84c0.26-1.01-0.04-2.09-1.32-3.29c-0.86-0.8-1.7-1.63-2.49-2.49
		c-0.47-0.51-0.84-1.11-1.25-1.67c0.69-0.25,1.39-0.5,2.08-0.75C805.06,469.78,802.9,470.65,802.66,474.19z"
          />
          <path
            className="st8"
            d="M755.78,149.99c4.94,0,9.95,0,14.83,0c0.67-1.73,1.17-3.01,1.82-4.67C766.4,146.27,759.46,142.7,755.78,149.99
		z"
          />
          <path
            className="st15"
            d="M868.48,321.04c-0.24,1.78-0.36,3.57-0.53,5.36c0.4,0.15,0.81,0.31,1.21,0.46c1.98-5.57,6.26-8.28,11.5-9.61
		c-0.55-1.3-0.98-2.35-1.64-3.92c-1.46,0.69-2.72,1.28-3.98,1.89c-1.95,0.95-3.89,1.92-5.84,2.88c0,0,0,0,0,0
		c-0.19,0.73-0.43,1.46-0.59,2.19C868.56,320.54,868.51,320.79,868.48,321.04z"
          />
          <path
            className="st3"
            d="M351.32,384.36c2.98,0,5.81,0,8.07,0c1.27-0.8,2.36-1.49,3.55-2.25c-2.58-3.08-5.06-1.13-7.63-0.43
		c-0.49-3.33-0.96-6.48-1.43-9.63c-0.39-0.04-0.78-0.07-1.17-0.11c-0.7,1.3-1.39,2.59-2.11,3.93
		C353.75,378.46,351.82,381.54,351.32,384.36z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M382.64,510.7c0.51,3.87,1,7.54,1.48,11.13c2.15-0.18,3.86-0.33,5.7-0.48c-1.27-4.38-2.47-8.52-3.75-12.93
		C384.66,509.35,383.79,509.93,382.64,510.7z"
            />
          </Link>
          <path
            className="st11"
            d="M710.12,389.98c0.28,1.84,0.56,3.69,0.91,5.94c1.62-1.01,2.57-1.6,3.53-2.2c-0.32-0.08-0.65-0.16-0.97-0.24
		c0-7.95,0-15.9,0-23.85c-0.41,0.72-1.09,1.41-1.19,2.17C711.57,377.85,710.86,383.92,710.12,389.98z"
          />
          <path
            className="st3"
            d="M383.59,874.74c2.08,1.2,4.07,2.34,6.29,3.62c-0.34-3.75-0.65-7.14-0.95-10.45c-1.77-1.01-3.47-1.98-5.34-3.05
		C383.59,868.36,383.59,871.53,383.59,874.74z"
          />
          <path
            className="st3"
            d="M242.35,573.1c-0.37-2.01-0.68-3.69-1.07-5.83c-4.24,1.41-8.41,2.8-12.47,4.15c0,1.85,0,3.13,0,4.48
		C233.45,575.44,236.98,570.83,242.35,573.1z"
          />
          <path
            className="st12"
            d="M997.45,446.25c-0.94,3.64-1.87,7.27-2.81,10.91c0.1-0.03,0.2-0.05,0.31-0.08c0.07-0.02,0.13-0.04,0.2-0.05
		c0.25-0.07,0.49-0.13,0.72-0.19c0.64-0.17,1.18-0.31,1.46-0.39c1.91-3.38,3.23-6.51,5.26-9.07c1.92-2.42,4.59-4.25,7.5-6.84
		c-2.61,1.15-4.56,1.99-6.49,2.86C1001.54,444.33,999.5,445.3,997.45,446.25z"
          />
          <path
            className="st3"
            d="M389.16,750.26c0.67,0.45,1.98,0.69,2.59,0.33c1.88-1.09,3.72-2.24,5.56-3.4c1.84-1.16,3.66-2.34,5.5-3.5l0,0
		c-1.85,0.32-3.7,0.63-5.54,0.95c-4.62,0.41-9.23,0.81-13.85,1.22c-0.34-0.95-0.68-1.91-1.3-3.66c-0.2,0.5-0.35,0.88-0.47,1.18
		c-0.08,0.2-0.15,0.37-0.2,0.52c-0.09,0.22-0.16,0.39-0.23,0.57c0.74,0.47,1.47,0.94,2.21,1.41c0,0,0,0,0,0c0,0,0-0.01,0-0.01
		c1.43,1.11,2.82,2.27,4.27,3.34C388.16,749.57,388.65,749.92,389.16,750.26z"
          />
          <path
            className="st3"
            d="M315.39,395.38c0.46-3.43,1.13-6.83,1.72-10.34c-3.38,0.96-4.2,3.98-5.32,6.84c-1.16,2.98-2.52,5.88-3.67,8.54
		C312.57,400.62,314.89,399.13,315.39,395.38z"
          />
          <path
            className="st7"
            d="M598.22,490.44c-0.15-0.28-0.31-0.56-0.46-0.84c-3.3,1.99-6.6,3.98-9.89,5.96c9.98,1.62,15.24-2.4,13.51-9.99
		C600.18,487.4,599.2,488.92,598.22,490.44z"
          />
          <path
            className="st8"
            d="M857.56,403.46c1.07,1.37,1.91,2.45,2.87,3.68c4.96-3,5.26-8.04,6.2-12.95
		C862.33,396.05,860.17,399.94,857.56,403.46z"
          />
          <path
            className="st4"
            d="M375.83,864.38c-0.07-1.2-0.15-2.38-0.22-3.55c-0.04-0.59-0.07-1.17-0.11-1.76c-3.01,3.06-5.4,6.46-5.96,10.86
		c2.09,1.19,4.18,2.39,6.87,3.93c-0.13-2.04-0.25-3.97-0.36-5.83C375.98,866.8,375.9,865.58,375.83,864.38z"
          />
          <path
            className="st4"
            d="M808.8,240.18c1.33,7.47,5.47,14.54,1.6,22.52c4.66,0.2,5.35-0.65,4.43-4.71c-1.33-5.91-2.58-11.83-3.88-17.81
		C810.11,240.18,809.37,240.18,808.8,240.18z"
          />
          <path
            className="st3"
            d="M327.66,731.7c-0.99-1.37-1.28-2.15-1.84-2.47c-4.1-2.39-8.3-2.86-12.66-0.54c-1.42,0.76-2.98,1.25-4.06,1.69
		c0.93,1.04,2.22,2.48,3.61,4.04c0-1.04,0-1.98,0-3.81C317.52,730.95,322.1,731.29,327.66,731.7z"
          />
          <path
            className="st4"
            d="M116.44,749.41c6.9-3.71,13.86-7.35,20.65-11.25c0.86-0.49,1.7-1.02,2.53-1.57c2.45-1.61,4.8-3.41,7.21-5.08
		c-0.32-0.01-0.65-0.06-0.89,0.07c-8.09,4.46-16.17,8.92-24.23,13.43c-2.61,1.46-5.14,3.05-7.71,4.57
		C114.99,749.89,115.78,749.77,116.44,749.41z"
          />
          <path
            className="st4"
            d="M147,731.52c0.04-0.03,0.09-0.06,0.13-0.09c0,0,0,0,0,0c0.06,0,0.09-0.01,0.13-0.01
		c0.13-0.01,0.19-0.01,0.19-0.01c0,0-0.14,0.01-0.32,0.02c0.02-0.01,0.03-0.02,0.04-0.03c-0.02,0.01-0.03,0.02-0.04,0.03
		c-0.06,0-0.12,0.01-0.19,0.01l0,0c-0.04,0.03-0.08,0.05-0.11,0.08c0,0,0,0,0,0C146.89,731.52,146.95,731.52,147,731.52z"
          />
          <path
            className="st9"
            d="M562.02,489.37c-2.65-0.83-5.31-2.42-7.97,0.16c-0.73,0.71-1.8,1.05-2.69,1.55c2.15,1.47,4.16,2.84,6.39,4.37
		c1.3-0.71,2.67-1.46,4.08-2.23c1.39-0.76,2.82-1.54,4.26-2.33c0,0,0,0,0,0C564.74,490.37,563.4,489.8,562.02,489.37z"
          />
          <path
            className="st9"
            d="M566.14,490.86c-0.02,0.01-0.03,0.02-0.05,0.03c0.04,0.02,0.08,0.03,0.12,0.05c0,0,0,0,0,0c0,0,0,0,0,0
		C566.22,490.94,566.15,490.85,566.14,490.86C566.15,490.86,566.15,490.86,566.14,490.86z"
          />
          <path
            className="st16"
            d="M698.92,424.02c-1.04,5.83-2.94,10.63-9.58,11.03c1.86,4.26,3.68,5.29,5.89,3.66
		C699.04,435.9,700.92,428.97,698.92,424.02z"
          />
          <path
            className="st9"
            d="M1005.02,344.36c-1.26,0.83-2.39,1.35-3.62,1.58c-2.04,0.38-4.34-0.03-7.9-1.17c0,0,0,0,0,0
		c-0.54,0-1.08,0-1.62,0c-0.01,0.17-0.01,0.33-0.02,0.5c0.42,0.05,0.83,0.1,1.25,0.14c1.94,1.22,3.99,2.03,5.9,2.47
		c0.38,0.09,0.76,0.16,1.13,0.21c1.11,0.17,2.16,0.19,3.09,0.09c2.18-0.25,3.72-1.22,3.97-2.88c-0.79-0.88-1.45-1.61-1.98-2.2
		c0.7-1.52,1.22-2.68,1.84-4.01c-2.82,0.24-5.14,0.44-7.45,0.64c0,1.11,0,2.22-0.01,3.33C1001.31,343.46,1003,343.87,1005.02,344.36
		z"
          />
          <path
            className="st8"
            d="M1022.26,496.31c0.41-0.05,1.07-1.41,0.93-2.01c-0.85-3.6-1.89-7.15-2.87-10.72c-1.36,0-2.72,0-4.08,0
		c-1.22,4.71,2.51,7.9,3.72,11.86C1020.12,495.96,1021.52,496.41,1022.26,496.31z"
          />
          <path
            className="st5"
            d="M369.95,847.34c-3.37-5.91-8.66-6.49-14.94-5.24C361.4,847.13,367.71,849.42,369.95,847.34z"
          />
          <path
            className="st5"
            d="M368.36,792.84c1.95-0.44,2.27-1.57,3.03-4.53c0,0,0,0,0,0c-2.22,0.79-4.39,1.12-6.52,1.12
		c-1.28,0-2.54-0.13-3.79-0.35c-0.42-0.07-0.83-0.16-1.25-0.25c-0.83-0.18-1.65-0.41-2.48-0.66c-2.05-0.62-4.09-1.43-6.11-2.28
		c-0.11,0.15-0.23,0.3-0.35,0.44c3.92,2.26,7.84,4.53,11.76,6.79C365.44,793.12,367.19,793.11,368.36,792.84z"
          />
          <path
            className="st3"
            d="M422.03,872.42c-1.08,3.75-2.14,7.46-3.21,11.17c-0.28-0.08-0.57-0.16-0.85-0.24c0.33,0.95,0.65,1.9,1.02,2.97
		c2.16-0.33,4.22-0.65,6.46-1C424.4,880.72,424.54,875.86,422.03,872.42z"
          />
          <path
            className="st8"
            d="M723.75,358.82c-0.03,0.02-0.06,0.03-0.09,0.05c0.83,0.08,1.66,0.16,2.49,0.24c1.1-0.52,2.2-1.04,3.3-1.56
		c0.77-0.23,1.53-0.46,2.3-0.69c0.44-0.07,0.88-0.14,1.32-0.21c0.26,0.03,0.52,0.06,0.78,0.09l0.02,0.01
		c0.58,0.16,1.16,0.32,1.74,0.48c3,0.83,5.99,1.65,8.99,2.48c0.19-0.04,0.39-0.08,0.59-0.11c-4.64-4.69-10.41-6.11-16.63-4.88
		c-0.23,0.05-0.45,0.12-0.67,0.22c-0.31,0.15-0.62,0.36-0.91,0.59c-0.22,0.17-0.43,0.36-0.65,0.57c-0.7,0.67-1.37,1.48-2.06,2.18
		c-0.2,0.2-0.4,0.4-0.6,0.58l0,0h0C723.69,358.85,723.72,358.83,723.75,358.82z"
          />
          <path
            className="st4"
            d="M852.87,251.12c1.74,1.36,2.99,2.33,4.49,3.5c0-4.1,0-8.2,0-12.47c-1.6,0-3.06,0-4.49,0
		C852.87,245.76,852.87,248.85,852.87,251.12z"
          />
          <path
            className="st15"
            d="M620.67,408.41c-0.02-0.02-0.05-0.04-0.05-0.04c0,0,0.01,0.01,0.01,0.01c0,0-0.01-0.01-0.01-0.01
		c0.87,0.21,1.73,0.42,2.59,0.63c0.58-3.51,1.14-6.93,1.76-10.72c-4.04,2.36-7.77,4.53-11.08,6.47c2.32,1.26,4.57,2.48,6.82,3.71
		c0,0,0,0,0,0c0,0,0,0,0,0C620.72,408.46,620.69,408.44,620.67,408.41z"
          />
          <path
            className="st15"
            d="M692.71,420.59c-1.47,0-2.99,0-4.46,0c-2.65,3.53-2.2,4.94,2.64,8.58
		C695.36,426.5,695.77,424.85,692.71,420.59z"
          />
          <path
            className="st3"
            d="M416.3,889.84c-2.07-0.35-4.14-0.69-6.28-1.05c-2.14-0.36-4.36-0.73-6.71-1.12c0.37,2.34,0.69,4.38,1.01,6.42
		c2.41-0.33,4.98-0.23,7.28-0.87C413.38,892.72,415.01,891.77,416.3,889.84z"
          />
          <path
            className="st12"
            d="M1045.37,400.35c-0.39,0.82-0.06,2-0.06,3.17c4.04-2.22,7.35-3.91,10.49-5.85c0.97-0.6,1.51-1.91,2.56-3.32
		C1052.25,393.87,1047.32,396.29,1045.37,400.35z"
          />
          <path
            className="st4"
            d="M1006.91,506.29c-3.42,5.74-2.38,16.79,0.78,21.98c0.47-2.56,1.33-4.73,1.17-6.81
		C1008.46,516.38,1007.59,511.34,1006.91,506.29z"
          />
          <path
            className="st3"
            d="M232.43,557.63c-1.29,0.23-2.81,0.5-4.13,0.74c0,3.62,0,7.03,0,10.49c2.33-1.21,4.36-2.26,6.31-3.27
		C233.9,562.98,233.17,560.33,232.43,557.63z"
          />
          <path
            className="st4"
            d="M911.36,262.89c-0.39-0.74-0.8-1.45-1.25-2.15c-0.22-0.35-0.46-0.69-0.7-1.03c-1.21-1.7-2.65-3.26-4.41-4.63
		c0,0,0,0,0,0c2.52,6.01,5.05,12.03,7.75,18.46c0.47-0.26,0.88-0.48,1.27-0.69c0.39-0.21,0.74-0.41,1.1-0.6
		C914.02,269.03,912.91,265.84,911.36,262.89z"
          />
          <path
            className="st10"
            d="M738.03,307.39c0.02,0.01,0.04,0.02,0.06,0.02c-0.88-1.49-1.76-2.97-2.64-4.46
		c-3.41-1.4-6.82-2.81-10.23-4.21c-0.13-0.2-0.25-0.39-0.38-0.59c-0.1,0.06-0.19,0.12-0.29,0.18c0.13,0.25,0.27,0.5,0.4,0.75
		c0.47,4.02,2.66,6.61,6.6,7.5c1.07,0.24,2.16,0.39,3.26,0.51c1.1,0.12,2.2,0.21,3.29,0.35l0,0c0,0,0,0,0,0
		c-0.01-0.01-0.01-0.02-0.02-0.03l0,0C738.07,307.4,738.05,307.4,738.03,307.39z"
          />
          <path
            className="st4"
            d="M962.05,516.04c3.59-2.12,2.15-10.24-2.68-16.65C960.14,505.14,957.38,511.03,962.05,516.04z"
          />
          <path
            className="st8"
            d="M1009.59,496.55c0.82-4.65,1.58-9.01,2.35-13.37c-1.07-0.59-2.15-1.18-3.22-1.78
		C1005.63,486.6,1005.83,491.8,1009.59,496.55z"
          />
          <path
            className="st4"
            d="M351.39,400.89c6.98,0.73,9.72-4.53,13.32-8.77c-0.51-1.72-1.05-3.52-1.8-6.04
		C359.09,391.73,358.52,398.86,351.39,400.89z"
          />
          <path
            className="st15"
            d="M500.88,471.82c0.62,4.04,1.23,8.08,1.86,12.21c1.48-0.99,2.9-1.94,4.17-2.79
		c-0.69-3.77-1.36-7.46-2.08-11.34C503.53,470.54,502.31,471.13,500.88,471.82z"
          />
          <path
            className="st3"
            d="M423.78,871.03c1.53,4.53,3.04,9,4.69,13.9c1.21-6.09,1.49-11.73-0.61-17.49
		C426.23,868.87,424.94,870.01,423.78,871.03z"
          />
          <path
            className="st5"
            d="M876.75,263.6c2.43,0.11,4.91-0.32,7.34-0.72c0.82-0.13,1.52-0.92,2.28-1.41c-0.02-0.43-0.04-0.87-0.05-1.3
		c-3.83-0.93-7.66-1.86-12.15-2.95C874.7,260.33,872.63,263.4,876.75,263.6z"
          />
          <path
            className="st4"
            d="M809.78,375.36c0-5.39,0-10.79,0-16.72c-0.65-0.3-1.94-0.9-3.28-1.53c0.69,6.22,1.36,12.25,2.02,18.28
		C808.95,375.39,809.37,375.38,809.78,375.36z"
          />
          <path
            className="st15"
            d="M1056.29,434.57c0.28-2.7,0.56-5.4,0.83-8.02c-5.69-1.5-6.45,3.61-7.18,11.1c1.8-1.19,3.63-2.4,5.46-3.6
		C1055.69,434.22,1055.99,434.39,1056.29,434.57z"
          />
          <path
            className="st3"
            d="M389.78,809.83c0.35,2.51,0.68,4.9,1.05,7.49c2.37-0.75,4.71-1.48,7.12-2.24c-0.65-2.62-1.18-4.78-1.71-6.94
		C393.86,808.75,391.97,809.25,389.78,809.83z"
          />
          <path
            className="st16"
            d="M780.04,279.34c-0.17-2.73-0.32-5.07-0.48-7.54c-3.25-0.3-6.02-0.55-8.56-0.78
		C770.48,277.13,775.63,277.28,780.04,279.34z"
          />
          <path
            className="st9"
            d="M1045.24,358.61c6.21,0.68,13.7-5.21,13.56-10.41c-1.04-0.36-2.11-0.72-3.35-1.15c0,1.57,0,3.08,0,4
		C1052.19,354.17,1047.29,354.2,1045.24,358.61z"
          />
          <path
            className="st4"
            d="M361.5,376.58c-0.37-3.92-0.72-7.53-1.17-12.33c-2.64,1.83-4.56,3.17-5.68,3.95c1.49,3.27,2.59,5.69,3.81,8.38
		C358.88,376.58,359.88,376.58,361.5,376.58z"
          />
          <path
            className="st12"
            d="M443.92,426.29c0.97-0.43,1.84-0.82,2.3-1.02c0-5.19,0-9.95,0-15.13c-1.59,1.25-2.75,2.17-4.07,3.2
		C442.72,417.53,443.3,421.8,443.92,426.29z"
          />
          <path
            className="st12"
            d="M905.77,354.35c1.61-2.39,1.1-4.72-2.24-5.5c-0.87,0.96-1.73,1.93-2.64,2.93c-0.9,1.01-1.85,2.06-2.87,3.19
		c0.5,0.83,1.13,1.88,1.79,2.99c0.22,0.37,0.45,0.75,0.67,1.13C902.29,357.54,904.49,356.25,905.77,354.35z"
          />
          <path
            className="st3"
            d="M1001.74,308.35c3.57,1.15,6.45,4.44,9.44,6.65c-0.52-4.55-4.33-7.28-8.44-9.1
		c-4.39-1.94-8.89-0.63-13.01,1.76C993.78,308.09,998.1,307.17,1001.74,308.35z"
          />
          <path
            className="st12"
            d="M856.39,338.9c-1.05,0.49-2.2,1.03-3.31,1.55c-0.32,2.4-0.5,4.51-0.9,6.57c-0.41,2.13-1.03,4.22-1.57,6.37
		c1.56,1.17,3.09,2.32,4.3,3.22c0-3.12-0.19-6.17,0.06-9.19C855.2,344.56,855.9,341.74,856.39,338.9z"
          />
          <path
            className="st7"
            d="M878.51,288.81c0.5-3.85,0.99-7.69,1.49-11.54c-1.88,0.49-3.76,0.97-5.65,1.46c-0.03,1.4,0.07,2.82-0.13,4.19
		C873.72,286.35,875.33,288.12,878.51,288.81z"
          />
          <path
            className="st5"
            d="M1045.49,500.79c-2.63,2.9-5.25,5.81-7.88,8.71c0.18,0.34,0.36,0.68,0.54,1.02c1.27-0.14,2.68,0.03,3.79-0.47
		c1.76-0.78,3-1.97,3.98-3.37c1.26-1.8,2.1-3.93,3.05-5.96v0C1047.82,500.74,1046.66,500.77,1045.49,500.79z"
          />
          <path
            className="st9"
            d="M427.89,434.19C427.89,434.19,427.89,434.19,427.89,434.19C427.89,434.19,427.89,434.19,427.89,434.19
		L427.89,434.19C427.89,434.19,427.89,434.19,427.89,434.19c6.46-2.1,9.41-8.34,5.87-12.79c-1.03,1.14-2.04,2.27-3.04,3.39
		c-0.34,0.34-0.69,0.68-1.03,1.02C429.09,428.6,428.49,431.4,427.89,434.19z"
          />
          <path
            className="st9"
            d="M429.68,425.81C429.68,425.81,429.68,425.81,429.68,425.81C429.68,425.81,429.68,425.8,429.68,425.81
		C429.68,425.8,429.68,425.8,429.68,425.81z"
          />
          <path
            className="st5"
            d="M800.54,328.27c-4.54-2.55-9.08-5.09-13.63-7.64c-0.37,0.51-0.74,1.03-1.11,1.54
		c1.79,2.22,5.23,2.52,7.17,4.57c2.25,2.37,4.47,4.76,6.71,7.13C799.97,332.01,800.25,330.14,800.54,328.27z"
          />
          <path
            className="st4"
            d="M246.49,713.52c0.22-3.3-0.79-7.19,4.44-8.14c-2.35-1.17-4.27-2.13-5.24-2.62c-1.72,1.63-3.25,3.09-4.73,4.5
		c-0.49,0.47-0.98,0.94-1.47,1.4C241.83,710.29,244.16,711.9,246.49,713.52z"
          />
          <path
            className="st3"
            d="M848.82,407.82c-1.09-6.7-2.1-12.96-3.29-20.25C842.9,397.76,844.08,405.48,848.82,407.82z"
          />
          <path
            className="st13"
            d="M698.2,375.76c-0.31,5.72,0.84,7.09,6.28,7.18c-0.33-3.33-0.65-6.63-1.01-10.23
		C701.57,373.81,699.88,374.79,698.2,375.76z"
          />
          <path
            className="st3"
            d="M338.72,744.32c4.46-2.68,5.49-5.02,3.85-8.73c-1.41-3.19-4.57-4.53-8.34-3.31c0.37,1.08,0.73,2.16,0.99,2.92
		c1.63,0.07,2.91,0.13,4.36,0.2c0.07,1.48,0.26,2.55,0.13,3.57C339.48,740.75,339.06,742.52,338.72,744.32z"
          />
          <path
            className="st8"
            d="M1007.45,332.74c4.25-4.34,3.43-9.16,0.85-14.01c-1.46,4.92-2.93,9.84-4.39,14.77
		C1005.11,333.26,1006.75,333.46,1007.45,332.74z"
          />
          <path
            className="st13"
            d="M495.49,531.54c0.94,6.87,1.88,13.74,2.82,20.61c0.39-0.04,0.77-0.08,1.16-0.12c0-5.61,0-11.22,0-17.19
		c-0.82-0.88-2-2.16-3.18-3.43C496.02,531.44,495.75,531.49,495.49,531.54z"
          />
          <path
            className="st4"
            d="M238.61,675.42c1.93,1.19,3.51,2.15,4.8,2.95c2.34-1.96,4.48-3.77,6.61-5.56c-1.12-0.75-2.24-1.51-4.1-2.77
		C244.19,671.32,241.52,673.29,238.61,675.42z"
          />
          <path
            className="st11"
            d="M915.4,309.52c-2.82,6.52-3.31,14.97-1.41,18.89c0.55-1.83,1.2-3.98,1.85-6.13
		c0.48-3.57,0.95-7.14,1.43-10.71C916.67,310.92,916.07,310.26,915.4,309.52z"
          />
          <path
            className="st15"
            d="M506.77,468.79c0.79,3.82,1.55,7.51,2.29,11.1c1.66-0.31,3.09-0.57,3.92-0.73c-0.86-3.9-1.72-7.82-2.7-12.28
		C509.13,467.5,507.96,468.14,506.77,468.79z"
          />
          <path
            className="st10"
            d="M490.19,300.92c-5.39-3.99-9.64-4.54-15.73-1.58c4.22,0.66,7.46,1.07,9.96,1.86
		c0.71,0.23,1.37,0.48,1.97,0.78c0.3,0.15,0.58,0.31,0.86,0.49c0.54,0.35,1.04,0.76,1.48,1.23c0.89,0.95,1.6,2.17,2.16,3.78
		C490.65,305.3,490.42,303.11,490.19,300.92z"
          />
          <path
            className="st15"
            d="M926.54,308.18c-4.06-2.16-8.13-4.31-12.14-6.44c0.72,2.88,2.27,4.99,4.29,6.63s4.51,2.83,7.13,3.89
		C926.07,310.9,926.31,309.54,926.54,308.18z"
          />
          <path
            className="st5"
            d="M947.3,255.75c4.23,9.08,8.18,17.57,12.17,26.13C960.56,278.53,951.56,258.73,947.3,255.75z"
          />
          <path
            className="st5"
            d="M123.34,531.6c-0.13,0.11-0.33,0.24-0.49,0.36c-0.16,0.12-0.28,0.23-0.26,0.3c0.44,1.63,1.1,3.22,1.46,4.87
		c1,4.65,1.89,9.32,2.82,13.98c0.24-0.03,0.48-0.07,0.72-0.1c0-4.33,0-8.66,0-12.99c-0.45-1.48-0.67-3.11-1.44-4.4
		C125.6,532.71,124.3,532.25,123.34,531.6z"
          />
          <path
            className="st12"
            d="M1064.67,442.2c-2.24-1.07-4.42-2.12-6.84-3.28c-0.67,1.58-1.25,2.95-1.78,4.22c1.44,2.18,2.71,4.1,3.95,5.97
		C1061.6,446.73,1063.12,444.49,1064.67,442.2z"
          />
          <path
            className="st3"
            d="M351.71,365.9c-1.98,2.18-3.88,4.47-5.33,7.02c-1.63,2.88-2.85,5.98-4.04,8.53
		C346.46,380.75,352.2,372.43,351.71,365.9z"
          />
          <path
            className="st4"
            d="M288.63,694.99c-3.2-1.66-6.39-3.31-9.45-4.89c-1.43,1.93-2.65,3.58-4.54,6.12c4.77-0.21,9.29-0.41,13.81-0.6
		C288.51,695.4,288.57,695.2,288.63,694.99z"
          />
          <path
            className="st3"
            d="M953.72,425.54c0,0.01-0.01,0.01-0.01,0.02c0,0,0.01-0.09,0.01-0.09l0,0c-0.29,0.1-0.57,0.2-0.83,0.29
		c-0.79,0.26-1.45,0.45-2.02,0.53c-0.19,0.03-0.37,0.05-0.54,0.06c-0.34,0.02-0.64-0.01-0.91-0.09c-1.22-0.35-1.8-1.68-2.41-4.4
		c-0.56-2.46-0.68-5.02-0.99-7.54c-0.51,0.02-1.02,0.05-1.52,0.07c0,2.4-0.23,4.83,0.05,7.19c0.15,1.26,0.5,2.41,1.02,3.43
		c0.39,0.76,0.87,1.45,1.44,2.05c0.37,0.4,0.78,0.76,1.22,1.08c1.54,1.11,3.44,1.7,5.5,1.62c0,0,0,0,0,0
		C953.72,428.35,953.72,426.95,953.72,425.54z"
          />
          <path
            className="st3"
            d="M953.72,425.54c0-0.02,0-0.05,0-0.07C953.72,425.49,953.72,425.52,953.72,425.54L953.72,425.54z"
          />
          <polygon
            className="st3"
            points="953.72,429.76 953.72,429.76 953.72,429.76 	"
          />
          <path
            className="st12"
            d="M1016.65,446.92c1.31-5.86,2.69-7.41,9.11-8.11c-1.34-2.07-2.2-4.69-3.29-4.79c-1.5-0.14-4.18,1.34-4.57,2.65
		C1016.94,439.93,1017,443.49,1016.65,446.92z"
          />
          <path
            className="st12"
            d="M728.7,435.62c-4.01,1.63-8.03,3.26-12.04,4.88c4.92,0.33,9.84,0.66,14.75,0.98c0-0.01,0-0.01-0.01-0.02
		C730.5,439.52,729.6,437.57,728.7,435.62z"
          />
          <path
            className="st12"
            d="M731.46,441.59c0.09-0.03,0.18-0.06,0.27-0.09c-0.06,0-0.13-0.01-0.19-0.01c0,0-0.01,0.02-0.02,0.02
		l0.01-0.02c-0.04,0-0.08-0.01-0.12-0.01C731.43,441.52,731.45,441.56,731.46,441.59L731.46,441.59z"
          />
          <path
            className="st12"
            d="M1024.97,441.99c-1.87,1.16-2.83,1.76-3.78,2.34c0,3.64,0,7.13,0,10.62c1.59-1.4,3.2-2.81,5.27-4.64
		C1026.05,448.06,1025.57,445.35,1024.97,441.99z"
          />
          <path
            className="st12"
            d="M1021.19,455.11c0-0.05,0-0.1,0-0.16c-0.03,0.02-0.05,0.05-0.08,0.07c0,0,0,0,0,0
		C1021.11,455.03,1021.19,455.11,1021.19,455.11z"
          />
          <path
            className="st3"
            d="M252.2,683.19c1.9-1.75,3.7-3.41,6.14-5.67c-5.43-2.42-8.76,0.34-13.04,1.75
		C247.93,680.76,249.92,681.89,252.2,683.19z"
          />
          <path
            className="st5"
            d="M783.11,322.19c0-1.72,0-3.22,0-4.97c-2.15-1.13-4.31-2.26-6.59-3.46c0,3.14,0,5.78,0,8.43
		C778.76,322.19,780.86,322.19,783.11,322.19z"
          />
          <path
            className="st5"
            d="M293.89,429.85c0.36,0.19,0.75,0.59,1.06,0.53c2.8-0.47,5.3-1.4,7.52-2.79c2.21-1.4,4.14-3.26,5.76-5.59
		C302.93,423.66,297.02,424.22,293.89,429.85z"
          />
          <path
            className="st5"
            d="M125.41,607.48c-3.56,2.65-7.11,5.3-10.67,7.94c1.17,0.45,2.34,0.9,3.52,1.35c2.18-1.31,4.36-2.61,6.53-3.92
		C124.99,611.06,125.2,609.27,125.41,607.48z"
          />
          <path
            className="st3"
            d="M824.36,386.88c-1.71,3.32-0.31,5.78,1.77,8.18c1.31,1.51,2.64,3.01,3.96,4.52c0,0,0,0,0,0
		c0.29,0.27,0.57,0.53,0.86,0.8c0.12-0.09,0.23-0.18,0.35-0.26c-0.23-0.37-0.46-0.74-0.69-1.11l0,0
		c-1.71-5.44-3.41-10.89-5.33-17.02C824.92,384.06,824.99,385.65,824.36,386.88z"
          />
          <path
            className="st13"
            d="M1085.61,361.94c0.96,2.74,1.91,5.48,3.16,9.05c1.74-3.42,3.01-5.9,4.29-8.42c-1.1-0.55-2.11-1.06-3.07-1.55
		c-0.96-0.48-1.88-0.95-2.8-1.41C1086.66,360.39,1086.13,361.16,1085.61,361.94z"
          />
          <path
            className="st3"
            d="M246.84,592.87c0-8.64,0-17.29,0-25.8c-1.23-0.34-1.99-0.55-2.79-0.77c0.86,9.03,1.7,17.82,2.54,26.62
		C246.67,592.9,246.76,592.88,246.84,592.87z"
          />
          <path
            className="st3"
            d="M302.42,753.69c-0.65-3.74-1.29-7.48-1.94-11.22c-0.4-0.07-0.81-0.14-1.21-0.21
		c-1.28,2.13-2.57,4.26-3.85,6.38C297.75,750.33,300.08,752.01,302.42,753.69z"
          />
          <path
            className="st3"
            d="M229.98,521.14c0.62,3.3,1.21,6.47,1.81,9.65c1.68-0.35,3.21-0.66,4.69-0.97
		C236.65,523.98,235.57,522.53,229.98,521.14z"
          />
          <path
            className="st3"
            d="M925.61,396.24c-1.42-2.21-2.38-3.71-3.06-4.78c-2.3,0.13-4.05,0.23-6.33,0.36c1.08,3.24,2.18,6.54,3.28,9.84
		c0.21-0.07,0.42-0.13,0.62-0.2c-0.23-1.22-0.45-2.45-0.79-4.25c1.5,0.89,2.35,1.39,3.64,2.16c-0.29-1.8-0.5-3.1-0.79-4.88
		C923.4,395.12,924.17,395.51,925.61,396.24z"
          />
          <path
            className="st3"
            d="M872.38,466.88c0.91-3.24,1.75-6.19,2.53-8.98c-1.32-1.16-2.27-2-3.24-2.86
		C868.31,460.04,868.31,464.2,872.38,466.88z"
          />
          <path
            className="st4"
            d="M945.26,471c3.77,2.46,9.52,1.1,11.92-2.96C951.66,466.31,947.48,467.39,945.26,471z"
          />
          <path
            className="st5"
            d="M585.05,695.88c1.15,0.65,3.64-1.08,5.52-1.72c-3.36-2.13-6.71-4.27-10.07-6.4
		C580.35,691.4,582.22,694.28,585.05,695.88z"
          />
          <path
            className="st12"
            d="M1095.72,506.2c1.35,0.99,2.86,1.66,4.28,1.78l0,0c0.69-0.38,1.38-0.76,2.83-1.56
		c-1.87-1.53-3.18-2.93-4.79-3.83c-2.1-1.17-4.42-1.95-6.65-2.89C1091.67,502.04,1093.48,504.56,1095.72,506.2z"
          />
          <path
            className="st4"
            d="M1148.37,563.78c-1.72-0.87-2.95-1.49-4.09-2.07c-3.91,2.37-3.77,6-2.46,10.31
		C1143.98,569.31,1146.14,566.59,1148.37,563.78z"
          />
          <path
            className="st3"
            d="M1002.86,488.06c-2.37-0.98-4.91-2.04-7.45-3.09c-0.37-0.23-0.74-0.46-1.1-0.7c-0.09,0.12-0.18,0.23-0.27,0.35
		c0.26,0.28,0.52,0.57,0.78,0.85c2.89,3.63,5.78,7.25,8.66,10.88c0,0,0,0,0,0c0.29,0.26,0.58,0.52,0.86,0.77
		c0.13-0.09,0.25-0.17,0.38-0.26c-0.24-0.38-0.48-0.76-0.71-1.14C1003.63,493.21,1003.25,490.7,1002.86,488.06z"
          />
          <path
            className="st5"
            d="M961.73,461.45c-0.57-1.32-1.08-2.51-1.59-3.7c-0.43-0.47-0.85-0.94-1.28-1.41c-0.77-0.38-1.63-0.66-2.3-1.17
		c-1.96-1.52-3.16-1.32-3.48,1.32c0.51,0.62,1.02,1.24,1.53,1.86c1.02,1.24,2.05,2.5,3.11,3.78c0.53,0.64,1.06,1.29,1.61,1.96
		C960.46,462.84,961.08,462.16,961.73,461.45z"
          />
          <path
            className="st13"
            d="M704.28,336.76c-0.44-0.25-0.86-0.49-1.28-0.73c-0.57,1.13-1.31,2.21-1.67,3.4c-0.61,2.01-0.97,4.1-1.44,6.16
		c2.86-1.83,5.71-3.66,9.36-5.99C707.09,338.36,705.6,337.51,704.28,336.76z"
          />
          <path
            className="st4"
            d="M971.52,280.05c3.31-1.82,6.1-3.35,9.32-5.13c-2.83-1.79-5.12-3.25-7.36-4.67
		C972.82,273.55,972.22,276.56,971.52,280.05z"
          />
          <path
            className="st8"
            d="M753.96,156.25c0,0.21,0,0.41,0,0.62c5.67-0.49,11.35-0.98,17.03-1.47c0-0.64,0-1.29,0-2.31
		c-5.9,0.38-11.46,0.73-17.03,1.09C753.96,154.96,753.96,155.62,753.96,156.25z"
          />
          <path
            className="st5"
            d="M314.61,765.08C314.61,765.08,314.61,765.08,314.61,765.08c1.77-0.02,3.53-0.02,5.29-0.1
		c0.35-0.02,0.7-0.03,1.05-0.06c0.7-0.04,1.4-0.1,2.09-0.19c1.04-0.12,2.07-0.29,3.1-0.53c0.68-0.16,1.36-0.35,2.03-0.57
		c0.48-0.16,0.95-0.35,1.42-0.54c0.52-0.22,1.04-0.46,1.55-0.73c-1.9-0.71-3.81-1.43-5.71-2.14c-2.87,1.07-5.74,2.14-8.62,3.2
		c0,0,0,0,0,0c0,0,0,0,0,0C316.08,763.98,315.35,764.53,314.61,765.08z"
          />
          <path
            className="st3"
            d="M285.38,447.72c1.37-0.24,2.74-0.48,4.1-0.72c0-6.07,0-12.14,0-17.68
		C288.18,435.15,286.78,441.43,285.38,447.72z"
          />
          <path
            className="st12"
            d="M867.84,226.77c-3.74-9.74-3.74-9.74-6.42-8.44C861.26,225,863.93,228.72,867.84,226.77z"
          />
          <path
            className="st12"
            d="M1081.32,436.27c-3.31,2.5-6.63,5-9.94,7.5c0.21,0.26,0.48,0.6,0.81,1.01s0.69,0.87,1.08,1.35
		c0.26,0.32,0.53,0.66,0.79,0.99C1079.19,445.32,1080.13,440.71,1081.32,436.27z"
          />
          <path
            className="st4"
            d="M381.2,812.41c-0.19-1.46-0.67-2.89-1.08-4.59c-0.6-0.18-1.23-0.36-1.87-0.55c-0.64-0.19-1.3-0.38-1.95-0.57
		c-0.03,2.67-0.06,5.33-0.1,8c1.6,0.75,3.2,1.5,5.05,2.36C381.23,815.18,381.37,813.78,381.2,812.41z"
          />
          <path
            className="st7"
            d="M711.56,245.53c-0.97-1.96-1.8-4.17,1.56-4.87c-1.6-0.94-3.19-1.88-4.84-2.85c-0.4,0.86-0.61,1.77-0.64,2.71
		c-0.02,0.63,0.04,1.26,0.16,1.9c0.06,0.32,0.15,0.64,0.24,0.96c0.59,1.91,1.79,3.79,3.41,5.38c0.05,0.05,0.11,0.1,0.17,0.15
		c0.51,0.48,1.05,0.94,1.64,1.36c0.61,0.44,1.27,0.85,1.95,1.2C713.98,249.5,712.6,247.6,711.56,245.53z"
          />
          <path
            className="st5"
            d="M930.5,238.94c-0.28,0.33-0.55,0.66-0.83,0.99c5.37,4.58,10.75,9.15,16,13.62
		C945.04,249.93,937.36,242.42,930.5,238.94z"
          />
          <path
            className="st12"
            d="M458.82,740.79c-5.95-4.12-11.9-8.25-17.89-12.4c-0.94,0.65-1.65,1.15-2.37,1.66
		c0.82,0.46,1.64,0.94,2.47,1.38C446.95,734.56,452.89,737.67,458.82,740.79z"
          />
          <path
            className="st3"
            d="M953.71,490.85c-3.97-1.77-8.24-3.68-12.7-5.67C942.31,491.75,949.4,494.4,953.71,490.85z"
          />
          <path
            className="st11"
            d="M889.07,268.18c1.08-0.34,2.08-1.01,2.89-2.37c-1.46-1.06-2.75-2-3.7-2.69c-0.59,0.41-1.32,1.09-2.18,1.46
		c-0.62,0.27-1.27,0.48-1.92,0.68c-0.65,0.2-1.31,0.38-1.96,0.59v0c-0.06,1.23-0.13,2.46-0.2,3.69l0,0
		c0.18-0.13,0.36-0.24,0.55-0.34c0.16-0.08,0.33-0.15,0.5-0.21c0.03-0.01,0.05-0.02,0.08-0.03c1.38-0.46,2.99-0.32,4.55-0.5
		C888.15,268.41,888.61,268.32,889.07,268.18z"
          />
          <path
            className="st4"
            d="M383.56,817.73c1.57,0.18,3.09,0.35,4.53,0.52c-0.31-3.33-0.58-6.27-0.85-9.2c-1.56,0-2.56,0-3.68,0
		C383.56,811.89,383.56,814.57,383.56,817.73z"
          />
          <path
            className="st11"
            d="M822.13,352.27c-1.23,2.76-1.12,5.65,1.77,8.18c0.68-1.25,1.22-2.24,1.47-2.71
		c-0.43-0.98-0.74-1.86-0.93-2.66c-0.82-3.44,0.68-5.16,5.23-5.1c0-0.78,0-1.56,0-2.45C825.87,347.32,823.46,349.26,822.13,352.27z"
          />
          <path
            className="st3"
            d="M377.75,363.77c1.52,4.39,2.78,8.04,4.19,12.11C385.62,370.92,383.82,365.3,377.75,363.77z"
          />
          <path
            className="st10"
            d="M497.15,385.55c-0.21-2.98-0.77-5.93-1.17-8.89c-0.25,10.6-0.49,21.2-0.74,31.8
		C497.69,400.97,497.69,393.27,497.15,385.55z"
          />
          <path
            className="st12"
            d="M988.92,338.76c3.57,1.43,7.13,2.86,10.7,4.29c0-1.11,0-2.22,0.01-3.33c-3.81-1.46-7.63-2.93-11.44-4.39
		c0,0.95-0.01,1.9-0.01,2.85C988.42,338.37,988.67,338.57,988.92,338.76z"
          />
          <polygon
            className="st12"
            points="988.16,338.17 988.16,338.17 988.17,338.18 	"
          />
          <polygon
            className="st12"
            points="988.16,338.17 988.16,338.17 988.16,338.17 	"
          />
          <path
            className="st4"
            d="M737.43,282.4c-0.21,0.07-0.43,0.13-0.64,0.19c-0.22,0.06-0.43,0.12-0.65,0.17c-1.31,0.33-2.67,0.6-3.91,1.06
		c-0.21,0.08-0.41,0.16-0.61,0.25c-1.41,0.62-2.64,1.57-3.48,3.28l0,0c0.06,0.07,0.09,0.14,0.08,0.21
		c-0.01,0.07-0.05,0.13-0.13,0.19c1.36-0.14,2.73-0.28,4.02-0.41c-0.47,1.14-0.87,2.12-1.27,3.1c0.25,0.21,0.51,0.42,0.76,0.63
		c0.72-0.53,1.55-0.96,2.15-1.6c1.06-1.16,1.99-2.45,2.97-3.69C736.95,284.65,737.19,283.53,737.43,282.4z"
          />
          <path
            className="st14"
            d="M884.18,275.19c-0.05-0.95-0.09-1.91-0.15-3.21c-3.61,1.17-6.68,2.16-9.67,3.13c0,1.51,0,2.56,0,3.61
		c1.88-0.49,3.76-0.97,5.65-1.46C881.39,276.58,882.78,275.89,884.18,275.19z"
          />
          <path
            className="st7"
            d="M997.45,446.25c-4.72,3-5.31,8.37-7.35,13.03c1.52-0.71,3.03-1.42,4.55-2.13
		C995.58,453.52,996.51,449.89,997.45,446.25z"
          />
          <path
            className="st13"
            d="M741.06,454.95c0,0.91,0,1.82,0,2.74c-0.41,0.04-0.83,0.08-1.24,0.13c-0.59-0.95-1.18-1.91-1.77-2.86
		c-1.39,0-2.77,0-4.16,0c0,0.56,0,1.12,0,1.7c0,0.58,0,1.17,0,1.8c4.23,0.23,8.17,0.45,12.98,0.71c-1.17-1.82-1.72-2.69-2.28-3.55
		C743.41,455.4,742.23,455.18,741.06,454.95z"
          />
          <path
            className="st5"
            d="M379.66,592.58c-2.23,1.48-4.42,3.03-6.84,4.7c0.26,0.09,0.5,0.18,0.74,0.26c0.72,0.25,1.36,0.48,2.01,0.71
		c3.58-2.4,7.17-4.8,10.75-7.19c0-0.81,0-1.62,0-2.76C383.91,589.85,381.76,591.18,379.66,592.58z"
          />
          <path
            className="st13"
            d="M82.6,701.57c2.65,4.08,5.53,3.95,10.03-0.47C89.05,697.66,85.85,699.54,82.6,701.57z"
          />
          <path
            className="st3"
            d="M878.22,429.38c2.69-0.81,5.01-1.51,7.31-2.2c0-1.42,0-2.54,0-3.69c-3.44,0.75-6.65,1.46-10.45,2.3
		C876.56,427.47,877.4,428.44,878.22,429.38z"
          />
          <path
            className="st3"
            d="M398.03,368.39c2.92-2.29,2.16-5.3,1.45-7.9c-0.32-1.16-2.36-1.85-3.62-2.76
		C396.08,361.9,394.7,365.8,398.03,368.39z"
          />
          <path
            className="st12"
            d="M1066.34,450.49c1.55-0.56,3.1-1.13,4.89-1.78c-0.93-1.48-1.91-3.03-3.11-4.93c-2.13,3.5-3.86,6.33-5.74,9.43
		c1.34,0.84,2.47,1.55,3.96,2.49C1066.34,453.65,1066.34,452.25,1066.34,450.49z"
          />
          <path
            className="st4"
            d="M1119.37,500.73c-1.68-1.52-3.36-3.03-5.31-4.8c-0.96,7.85-0.79,7.53,5.07,8.48c0.51,0.08,1.02,0.2,1.53,0.32
		c0.51,0.12,1.01,0.26,1.52,0.37C1121.24,503.65,1120.31,502.19,1119.37,500.73z"
          />
          <path
            className="st4"
            d="M815.5,408.84c-0.09-0.31-0.16-0.64-0.22-0.97c-0.06-0.33-0.11-0.67-0.15-1.01c-0.05-0.34-0.09-0.68-0.15-1.01
		c-0.28-1.68-0.8-3.24-2.92-4c0.21,4.28,0.43,8.55,0.64,12.83c1.18-0.47,2.36-0.94,3.54-1.41h0
		C816.01,411.78,815.91,410.26,815.5,408.84z"
          />
          <path
            className="st4"
            d="M129.97,777.04c-0.4-6.81-0.75-12.79-1.12-19.13C125.39,760.36,125.75,766.02,129.97,777.04z"
          />
          <path
            className="st4"
            d="M706.56,464.97c-0.5-2.06-1-4.11-1.4-5.76c-2.6,1.3-4.98,2.48-7.33,3.65c0.51,0.75,1.11,1.65,1.74,2.58
		c0.21,0.31,0.42,0.62,0.63,0.94C702.32,465.91,704.44,465.44,706.56,464.97z"
          />
          <path
            className="st5"
            d="M743.13,445.48c-1.66,0.64-2.81,1.08-4.06,1.56c0.35,1.4,0.69,2.73,1.01,4.04c0.33,1.31,0.65,2.59,0.97,3.87
		c1.18,0.22,2.36,0.45,3.54,0.67C744.12,452.34,743.65,449.05,743.13,445.48z"
          />
          <path
            className="st10"
            d="M498.78,366.88c-0.24-3.52-0.44-7.04-0.67-10.55c-0.23-3.52-0.49-7.03-0.85-10.54
		c-0.36-3.53-1.44-6.98-2.2-10.46c0.13,1.58,0.25,3.17,0.38,4.75c0.37,4.75,0.72,9.5,1.25,14.24c0.23,2.11,0.58,4.2,0.95,6.29
		C498.02,362.7,498.43,364.79,498.78,366.88C498.78,366.88,498.78,366.88,498.78,366.88z"
          />
          <path
            className="st12"
            d="M916.65,298.44c2.98,4.62,7.61,5.42,12.05,6.64c0.23-0.88,0.47-1.76,0.7-2.65
		C925.15,301.11,920.9,299.77,916.65,298.44z"
          />
          <path
            className="st11"
            d="M977.33,386.1c-0.22,0.05-0.44,0.09-0.66,0.14c-0.67,6.33-1.34,12.65-2.01,19.04
		c1.02,0.21,1.84,0.38,2.67,0.56C977.33,399.12,977.33,392.61,977.33,386.1z"
          />
          <path
            className="st4"
            d="M283.85,540.87c-0.45-0.33-1.23-0.6-1.3-0.99c-0.18-1.08-0.06-2.2-0.06-3.31c-0.21-0.01-0.41-0.01-0.62-0.02
		c-1.68,3-3.36,5.99-5,8.91c0.58,0.82,1.15,1.62,1.71,2.42c0.56,0.79,1.12,1.58,1.67,2.37C279.47,546.36,281.38,543.5,283.85,540.87
		z"
          />
          <path
            className="st3"
            d="M265.05,698.9c0-1.06,0-2.08,0-3.2c-1.8-0.69-3.5-1.33-5.03-1.92c-1.06,1.25-1.99,2.36-2.99,3.54
		C259.46,699.71,261.9,701.12,265.05,698.9z"
          />
          <path
            className="st5"
            d="M756.1,448.46c0-1.65,0-3.36,0-5.19c-1.81,0.36-3.07,0.61-4.49,0.89c0.86,3.69,1.6,6.88,2.34,10.07
		c0.71-0.31,1.43-0.63,2.14-0.94C756.1,451.7,756.1,450.11,756.1,448.46z"
          />
          <path
            className="st4"
            d="M294.63,412.96c-0.22,1.38-0.46,2.9-0.7,4.43c0.11,0.03,0.23,0.06,0.34,0.1c1.06-2.4,2.13-4.8,2.93-6.6
		c-2.76-2.22-5.33-4.28-7.55-6.06C288.76,408.9,289.03,409.44,294.63,412.96z"
          />
          <path
            className="st15"
            d="M706.68,323.05c-1.18,1.93-2.36,3.87-3.54,5.8c0.41,1.46,0.82,2.91,1.23,4.37c1.68-2.86,3.35-5.72,5.03-8.58
		C708.49,324.11,707.58,323.58,706.68,323.05z"
          />
          <path
            className="st3"
            d="M114.74,615.42c-3.53,1.91-7.06,3.81-10.76,5.8c0.07,0.54,0.17,1.3,0.29,2.29c1.21-0.58,2.41-1.16,3.59-1.73
		c2.36-1.14,4.67-2.25,6.96-3.36c1.15-0.55,2.29-1.1,3.43-1.65C117.08,616.32,115.91,615.87,114.74,615.42z"
          />
          <path
            className="st10"
            d="M482.23,401.87c-3.07,0.55-6.13,1.1-9.2,1.65l0,0c-1.78,1.72-3.57,3.43-5.35,5.15
		c0.23,0.42,0.46,0.83,0.7,1.25c1.32-0.31,2.65-0.61,3.97-0.92c-0.32-0.8-0.63-1.6-1.34-3.4c4.02-0.64,7.7-1.22,11.29-1.79
		c1.2-0.19,2.39-0.38,3.57-0.57C484.66,402.78,483.44,402.33,482.23,401.87z"
          />
          <path
            className="st4"
            d="M732.32,465.06L732.32,465.06c-0.63-0.13-1.26-0.27-1.9-0.4c-1.28-0.27-2.58-0.55-3.95-0.84
		c-0.69-0.15-1.39-0.29-2.12-0.45c0.48,2.48,0.89,4.61,1.29,6.66c0.13,0.68,0.26,1.36,0.39,2.03
		C728.12,469.73,730.22,467.4,732.32,465.06z"
          />
          <path
            className="st12"
            d="M1091.34,447.89c-0.71,0.55-1.94,1.01-2.04,1.65c-0.71,4.72-3.74,7.7-7.32,10.33
		c0.49,0.24,1.03,0.75,1.47,0.66C1087.92,459.71,1092.34,452.56,1091.34,447.89z"
          />
          <path
            className="st4"
            d="M1052.53,378.9c-3.3-1.66-6.46-3.26-9.74-4.91c0,1.06,0,1.99,0,2.92c3.25,1.66,6.49,3.33,9.74,4.99
		C1052.53,380.85,1052.53,379.8,1052.53,378.9z"
          />
          <path
            className="st4"
            d="M884.19,318.61c-3.75,2.32-6.75,4.17-9.74,6.02c3.25-0.02,6.5-0.04,9.74-0.06
		C884.19,322.98,884.19,321.39,884.19,318.61z"
          />
          <path
            className="st12"
            d="M846.58,343.21c-0.34,0-0.68,0-1.02,0c-0.79,2.84-1.58,5.67-2.37,8.49c2.22,0.86,3.66,1.42,5.96,2.31
		C848.18,349.93,847.38,346.57,846.58,343.21z"
          />
          <path
            className="st14"
            d="M749.55,265.78c-0.21,0.34-0.41,0.68-0.59,1.02c-0.36,0.68-0.64,1.35-0.86,2.02
		c-0.33,1.01-0.52,2.01-0.59,3.01c-0.02,0.33-0.03,0.66-0.03,1c0,0.33,0.02,0.66,0.05,0.99c0.24,2.64,1.25,5.25,2.66,7.85
		c0.25-4.64,0.56-9.28,0.68-13.92C750.9,267.1,750.01,266.43,749.55,265.78z"
          />
          <path
            className="st15"
            d="M718.22,178.51c-0.22,5.19,0.09,10.31,1.03,15.34c0.37,2.01,0.85,4.01,1.43,5.99
		c0.29,0.99,0.61,1.98,0.95,2.96C720.49,194.71,719.35,186.61,718.22,178.51z"
          />
          <path
            className="st7"
            d="M757.7,270.24c-0.23-0.04-0.45-0.06-0.64-0.07c-0.49-0.01-0.87,0.08-1.16,0.29c-0.19,0.14-0.33,0.34-0.44,0.61
		c-0.37,0.92-0.26,2.6,0.1,5.33c2.41,0.23,4.75,0.45,7.08,0.68c-0.98-2.12-1.97-4.24-2.95-6.35
		C758.91,270.49,758.25,270.32,757.7,270.24z"
          />
          <path
            className="st4"
            d="M957.5,418.32c-2.01-2.6-3.99-5.24-6.11-7.76c-0.16-0.19-1.27,0.42-1.93,0.65c-0.15,0.18-0.29,0.36-0.44,0.55
		c0.28-0.04,0.56-0.08,0.84-0.13c0.57-0.04,1.14-0.09,2.12-0.17c-0.9,1.34-1.5,2.24-2.02,3.02c0.96,1.07,1.87,2.09,2.75,3.08
		s1.74,1.96,2.6,2.92l0,0C956.04,419.76,956.77,419.04,957.5,418.32z"
          />
          <path
            className="st5"
            d="M783.12,442.71c-0.09-0.76-0.18-1.53-0.28-2.32c-1.5,0.52-2.67,0.93-3.67,1.28c0.26,2.96,0.49,5.64,0.72,8.31
		c1.34-0.25,2.68-0.5,4.02-0.75C783.65,447.1,783.39,444.97,783.12,442.71z"
          />
          <path
            className="st5"
            d="M261.73,724.45L261.73,724.45c-3.28-0.88-6.56-1.75-9.84-2.63c-1.8-2.77-3.6-5.53-5.4-8.3
		c-2.33-1.62-4.67-3.24-7-4.86h0c-1.47-0.37-2.92-0.78-4.33-1.26s-2.79-1.02-4.12-1.66c-2.67-1.27-5.15-2.9-7.37-5.06
		c-4.07-1.91-8.14-3.82-12.2-5.73c-0.15,0.3-0.3,0.61-0.44,0.91c-0.44,0.9-0.87,1.79-1.25,2.57c8.72,4.49,16.93,8.43,24.85,12.89
		c1.46,0.82,2.9,1.69,4.32,2.58c4.26,2.68,8.39,5.6,12.61,8.35c0,0,0,0,0,0c1.88,1.24,3.77,2.48,5.64,3.71
		c1.87,1.23,3.73,2.46,5.56,3.66c1.37-0.99,2.3-1.66,3.24-2.33C264.58,726.34,263.15,725.4,261.73,724.45L261.73,724.45z"
          />
          <path
            className="st4"
            d="M718.09,461.38c-1.17-4.6-2.34-9.19-3.5-13.79c-0.82,1.81-1.68,3.95-1.36,5.89c0.3,1.84,0.93,3.62,1.59,5.4
		c0.31,0.84,0.63,1.67,0.92,2.51c0.08,0.23,0.17,0.46,0.24,0.7C716.68,461.85,717.38,461.62,718.09,461.38z"
          />
          <path
            className="st12"
            d="M1057.75,473.6L1057.75,473.6c1.62-2.38,3.24-4.76,4.86-7.13c0.29-0.53,0.57-1.06,0.86-1.59
		c-0.56-0.31-1.12-0.63-1.73-0.96c-0.6-0.33-1.24-0.69-1.96-1.09c0.01,2.24-0.52,4.11-1.37,5.77c-0.28,0.55-0.6,1.08-0.95,1.58
		c-0.17,0.25-0.35,0.5-0.54,0.75c-0.74,0.97-1.56,1.87-2.42,2.75c-0.01,0.01-0.02,0.02-0.03,0.03c-0.65,0.66-1.32,1.32-1.97,1.97
		C1054.25,474.99,1056,474.3,1057.75,473.6z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M358.55,529.96c2.2-0.5,4.2-0.95,6.25-1.41c-0.57-2.33-1.02-4.14-1.51-6.13c-1.87,1.11-3.29,1.95-4.74,2.82
		C358.55,526.83,358.55,528.33,358.55,529.96z"
            />
          </Link>
          <path
            className="st16"
            d="M877.17,415.12c3.53-1.2,7.05-2.39,10.72-3.64c-0.61-1.7-1.13-3.16-1.7-4.74c-3.38,2.9-6.29,5.38-9.2,7.87
		C877.06,414.78,877.12,414.95,877.17,415.12z"
          />
          <path
            className="st9"
            d="M581.26,485.07c-1.74-2.79-3.33-5.34-5.04-8.09c-0.97,0.41-1.96,0.84-3.04,1.3c2.19,3.33,4.21,6.4,6.35,9.65
		C580.19,486.84,580.78,485.86,581.26,485.07z"
          />
          <path
            className="st12"
            d="M910.84,395.12c0-1.66,0-2.97,0-4.57c0.72,0.1,1.3,0.19,1.9,0.27c0-1.22,0-2.45,0-3.56
		c-1.54,0.23-2.89,0.43-4.57,0.68c0,2.94,0,5.59,0,8.19c0.09-0.6,0.17-1.23,0.27-1.9C909.45,394.61,910.06,394.83,910.84,395.12z"
          />
          <path
            className="st12"
            d="M908.18,398.16c0-0.67,0-1.34,0-2.02c-0.1,0.68-0.19,1.33-0.28,1.97
		C907.99,398.12,908.08,398.14,908.18,398.16z"
          />
          <path
            className="st5"
            d="M804.42,418.8c0.37-0.23,0.74-0.45,1.1-0.68c-1.43-4.45-2.86-8.91-4.29-13.36c0,0,0,0,0,0
		c-0.04,4.03-0.08,8.05-0.13,12.08C802.21,417.49,803.32,418.14,804.42,418.8z"
          />
          <path
            className="st5"
            d="M762.41,442.53c-1.63,0.44-2.8,0.75-3.99,1.08c0.18,0.88,0.35,1.75,0.52,2.59c0.51,2.54,0.99,4.92,1.47,7.31
		c0.67-0.23,1.33-0.47,2-0.7C762.41,449.53,762.41,446.26,762.41,442.53z"
          />
          <path
            className="st5"
            d="M105.38,740.86c-5.02-0.03-10.04,0.02-15.06,0.05c3.03,0.66,6.09,1.22,9.1,1.99c1.11,0.29,2.19,0.41,3.23,0.36
		c0.42-0.02,0.84-0.08,1.25-0.16c0.78-0.15,1.54-0.4,2.28-0.76c0.48-0.23,0.95-0.48,1.41-0.8
		C106.86,741.31,106.12,740.86,105.38,740.86z"
          />
          <path
            className="st4"
            d="M262.87,446.8c-1.26-3.48-2.49-6.85-3.78-10.4c-2.75,2.27-2.09,5.02-1,7.61
		C258.9,445.95,260.2,447.72,262.87,446.8z"
          />
          <path
            className="st8"
            d="M839.64,383.82c-1.52,3.2-3.14,6.15-0.05,10.42c1.41-3.48,2.49-6.17,3.65-9.03
		C841.68,384.61,840.74,384.24,839.64,383.82z"
          />
          <path
            className="st12"
            d="M1125.27,496c0.81,2.11,1.35,3.49,1.88,4.87c1.68,0.65,3.36,1.3,5.04,1.96c0.54-1.11,1.08-2.23,1.62-3.36
		C1130.94,498.3,1128.46,497.3,1125.27,496z"
          />
          <path
            className="st4"
            d="M1057.01,535.29c-1.67,0.69-3.33,1.38-5,2.07c0,0,0,0,0,0c1.27,1.19,3.7,2.01,6.21,2.29
		c0.63,0.07,1.26,0.11,1.87,0.11c0.62,0,1.22-0.03,1.8-0.11c1.15-0.15,2.19-0.45,2.96-0.94c-1.39-0.61-2.71-1.19-4.01-1.76
		C1059.55,536.4,1058.28,535.84,1057.01,535.29z"
          />
          <path
            className="st4"
            d="M112.7,712.61c0,1.37,0,3.52,0,5.92c1.85-3.82,3.59-7.4,5.32-10.97c0.32,0.14,0.64,0.27,0.97,0.41
		c-0.35-1.71-0.7-3.42-1.19-5.77C115.95,705.97,114.43,709.06,112.7,712.61z"
          />
          <path
            className="st5"
            d="M878.46,190.46c2.27,0.88,4.55,1.73,6.85,2.6c0.37-0.77,0.72-1.49,1.17-2.42c-2.94-1.72-5.61-3.28-8.55-5
		c0,1.77,0,3.05,0,4.28C878.14,190.15,878.27,190.39,878.46,190.46z"
          />
          <path
            className="st11"
            d="M875.69,267.88c-0.02,0.02-0.04,0.03-0.06,0.05c0,0,0.04-0.08,0.04-0.08h0c-0.4,0.26-0.77,0.51-1.13,0.75
		c-1.07,0.73-1.97,1.39-2.71,2c-2.96,2.45-3.43,4.11-2.49,6.31c1.4-0.99,2.81-2,4.25-3.02c1.43-1.02,2.88-2.04,4.32-3.07
		C877.18,269.84,876.43,268.86,875.69,267.88z"
          />
          <path
            className="st3"
            d="M152.08,722.93c-0.23-0.44-0.47-0.88-0.71-1.32c-5.16,1.4-9.5,4.37-13.56,7.86c0.19,0.37,0.38,0.75,0.57,1.12
		C142.95,728.04,147.52,725.48,152.08,722.93z"
          />
          <path
            className="st12"
            d="M857.12,334.61c-0.06,0.37-0.12,0.74-0.18,1.11c3.04,0.74,6.07,1.48,9.42,2.3c0.41-0.95,0.86-1.98,1.4-3.23
		C863.96,330.75,860.63,335.53,857.12,334.61z"
          />
          <path
            className="st13"
            d="M606.74,498.21c2.7,2.84,0.03,7.73,4.29,11.25c-0.5-5.53-0.92-10.13-1.38-15.25
		C608.49,495.8,607.74,496.83,606.74,498.21z"
          />
          <path
            className="st5"
            d="M799.93,233.27c-2.33,0.4-4.45,0.77-6.66,1.15c0.09,1.35,0.16,2.24,0.23,3.27c2.78,0,5.34,0,8.18,0
		C801.1,236.26,800.57,234.91,799.93,233.27z"
          />
          <path
            className="st4"
            d="M956.55,455.17c0.67,0.52,1.53,0.79,2.3,1.17c0-0.01-0.01-0.01-0.01-0.02c0,0.01,0.01,0.01,0.01,0.02
		c0,0,0,0,0,0c0,0,0,0,0,0c-0.48-1.25-1-2.3-1.58-3.16s-1.24-1.52-1.98-2c-0.37-0.24-0.77-0.43-1.19-0.58
		c-1.26-0.44-2.76-0.47-4.59-0.11c0.95,1.61,1.83,3.09,2.69,4.54c0.29,0.48,0.57,0.97,0.86,1.45
		C953.39,453.85,954.59,453.65,956.55,455.17z"
          />
          <path
            className="st4"
            d="M261.95,680c-1.58,1.79-3.12,3.54-5.04,5.72c0.69,0.43,1.64,1.03,2.27,1.42c2.52-1.62,4.62-2.98,7.18-4.63
		C264.75,681.59,263.39,680.82,261.95,680z"
          />
          <path
            className="st5"
            d="M302.42,753.69c-2.34-1.68-4.67-3.36-7.01-5.04c1.07,6.26,2.62,7.94,8.41,8.97
		C303.34,756.26,302.88,754.97,302.42,753.69z"
          />
          <path
            className="st15"
            d="M542.14,446c-1.05,0.82-1.76,1.37-2.39,1.86c0.61,4.21,1.14,7.93,1.71,11.85c0.89-0.59,1.72-1.14,2.21-1.47
		C543.15,454.06,542.67,450.23,542.14,446z"
          />
          <path
            className="st4"
            d="M1083.18,581.38c-4.01,2.82-8.03,5.64-11.87,8.34C1074.66,591.39,1080.54,587.87,1083.18,581.38z"
          />
          <path
            className="st5"
            d="M738.05,454.95c-0.53-2.36-1.07-4.71-1.63-7.2c-1.46,0.07-2.6,0.13-4.26,0.21c0.31,1.27,0.61,2.46,0.89,3.61
		c0.28,1.15,0.56,2.27,0.83,3.38C735.28,454.95,736.66,454.95,738.05,454.95z"
          />
          <path
            className="st8"
            d="M971.33,426.82c0.55-4.6,1.07-8.99,1.59-13.38c-0.45-0.07-0.9-0.14-1.35-0.22c-1.12,5.37-2.23,10.73-3.35,16.1
		c0.36,0.07,0.71,0.15,1.07,0.22c0.17-1.05,0.34-2.11,0.53-3.3C970.6,426.54,971.08,426.73,971.33,426.82z"
          />
          <path
            className="st10"
            d="M487.71,411.35c-2.01,0.74-4.09,1.28-6.14,1.91c-1.22,1.01-2.44,2.01-3.66,3.02c0.23,0.31,0.47,0.61,0.7,0.92
		c4.8-1.8,9.61-3.59,14.41-5.39C491.43,409.57,489.6,410.65,487.71,411.35z"
          />
          <path
            className="st5"
            d="M933.27,237.39c0.04,0.06,0.12,0.11,0.22,0.16c0.05,0.03,0.1,0.05,0.16,0.08c0.23,0.1,0.51,0.2,0.71,0.3
		c0.51-4.57,1.02-9.13,1.53-13.7c-0.26-0.36-0.53-0.73-1.02-1.41c-2.21,4.87-2.05,9.67-1.64,14.49
		C933.23,237.33,933.25,237.36,933.27,237.39z"
          />
          <path
            className="st14"
            d="M1097.14,406.95c2.58-3.71,1.95-6.2-2.83-11c0,2.05,0,4.04,0,6.02v0
		C1095.26,403.63,1096.2,405.29,1097.14,406.95z"
          />
          <path
            className="st11"
            d="M916.55,279.31c-0.54-0.49-1.09-0.97-1.68-1.5c-1.64,2.7-2.22,5.52-1.79,8.5c0.13,0.89,0.35,1.8,0.66,2.73
		c0.1,0.31,0.22,0.62,0.34,0.93c0.25,0.62,0.53,1.25,0.86,1.89C915.48,287.68,916.02,283.5,916.55,279.31z"
          />
          <path
            className="st3"
            d="M198.63,662.11c-0.6,0.95-1.2,1.9-1.81,2.85c1.28,0.27,2.55,0.54,3.87,0.82c1.32,0.28,2.68,0.57,4.12,0.87
		c0.39-1.71,0.69-3.04,1.03-4.55C203.08,662.11,200.86,662.11,198.63,662.11z"
          />
          <path
            className="st11"
            d="M878.96,400.47c-1.41-0.77-2.81-1.55-4.23-2.33c-1.42-0.78-2.84-1.56-4.28-2.36
		c-0.29,1.02-0.55,1.94-0.8,2.81c-0.41,1.45-0.78,2.74-1.14,4.03C871.99,401.91,875.47,401.19,878.96,400.47z"
          />
          <path
            className="st4"
            d="M964.58,347.7c-2.33-4.07-4.41-7.72-6.66-11.65c-2.74,4.56,4,7.21,1.95,11.65
		C961.7,347.7,963.35,347.7,964.58,347.7z"
          />
          <path
            className="st5"
            d="M416.3,889.84c-1.28,1.93-2.92,2.87-4.71,3.37c-2.3,0.64-4.87,0.55-7.28,0.87c0.47,0.49,0.97,1.44,1.39,1.41
		c3.92-0.28,7.84-0.74,12.07-1.17C417.15,892.42,416.72,891.13,416.3,889.84C416.3,889.84,416.3,889.84,416.3,889.84z"
          />
          <path
            className="st3"
            d="M963.04,265.91c-2.34-1.2-4.69-2.4-7.03-3.61c2.01,3.82,4.03,7.64,6.11,11.6
		C962.44,271.05,962.74,268.48,963.04,265.91z"
          />
          <path
            className="st14"
            d="M700.17,478.01c-1.27,0.47-2.55,0.94-3.1,1.14c0.12,1.21,0.24,2.3,0.34,3.31c0.07,0.67,0.14,1.31,0.2,1.93
		c0.1,0.93,0.19,1.81,0.28,2.69c-0.12,0.21-0.24,0.42-0.36,0.63c0.26-0.07,0.52-0.14,0.77-0.21l0,0c1-0.56,2.01-1.12,2.58-1.44
		c0-1.77,0.09-3.05-0.03-4.33C700.74,480.48,700.41,479.25,700.17,478.01z"
          />
          <path
            className="st5"
            d="M769.57,452.09c-0.33-3.02-0.66-6.05-1.02-9.31c-1.08,0.09-2.07,0.16-3.07,0.24c0.13,1.62,0.26,3.16,0.38,4.66
		c0.12,1.5,0.24,2.97,0.36,4.43C767.34,452.1,768.46,452.1,769.57,452.09z"
          />
          <path
            className="st5"
            d="M803.06,443.41c-1.28-2.02-2.55-4.03-4.09-6.46c0,3.08,0,5.51,0,7.93v0c1.05,0.03,2.11,0.06,3.16,0.09
		c1.49-0.61,2.98-1.22,4.58-1.88c-0.75-1.43-1.47-2.81-2.5-4.76c-0.18,2.19-0.29,3.6-0.4,5.01
		C803.56,443.36,803.31,443.38,803.06,443.41z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M294.6,541.65c0.6,5.6,1.05,9.9,1.51,14.19c0.42-0.01,0.85-0.01,1.27-0.02c0-3.99,0.08-7.99-0.09-11.98
		C297.27,543.27,296.01,542.75,294.6,541.65z"
            />
          </Link>
          <path
            className="st9"
            d="M845.45,368.68c0.43-9.31,0.11-10.26-3.76-10.86c0.49,2.21,0.88,4.18,1.36,6.12c0.42,1.69,0.95,3.34,1.44,5.01
		C844.81,368.87,845.13,368.78,845.45,368.68z"
          />
          <path
            className="st5"
            d="M953.72,429.76L953.72,429.76c0.83-0.59,1.54-1.15,2.13-1.71c0.02-0.02,0.04-0.04,0.06-0.06
		c0.31-0.3,0.58-0.6,0.83-0.91c0.14-0.17,0.26-0.34,0.38-0.51c0.22-0.32,0.42-0.64,0.57-0.98c0.87-1.88,0.73-4.08-0.19-7.28
		c-0.73,0.72-1.46,1.45-2.19,2.17c-0.27,1.21-0.54,2.42-0.82,3.64c-0.26,0.47-0.52,0.95-0.78,1.42l0,0
		C953.72,426.95,953.72,428.35,953.72,429.76L953.72,429.76L953.72,429.76z"
          />
          <path
            className="st5"
            d="M953.72,425.54c0-0.02,0-0.05,0-0.07c0,0-0.01,0.09-0.01,0.09C953.72,425.55,953.72,425.55,953.72,425.54z"
          />
          <path
            className="st5"
            d="M776.06,442.21c-1.56,0-2.71,0-3.81,0c0.18,1.57,0.36,3.11,0.53,4.63c0.17,1.52,0.35,3.02,0.52,4.52
		c0,0,0,0,0,0c0.92-0.24,1.84-0.48,2.76-0.72C776.06,447.85,776.06,445.06,776.06,442.21z"
          />
          <path
            className="st3"
            d="M97.53,720.24c1.05-1.34,1.9-2.42,2.59-3.31c-0.38-1.64-0.72-3.12-1.15-5c-2.04,2.38-3.96,4.61-6.05,7.05
		C94.8,719.5,96.22,719.89,97.53,720.24z"
          />
          <path
            className="st3"
            d="M971.46,474.79c-3.85-0.64-4.21-0.13-3.88,5.06c1.4,0.52,2.9,1.06,5.24,1.93
		C972.26,478.87,971.85,476.76,971.46,474.79z"
          />
          <path
            className="st14"
            d="M297.21,824.02c-0.61-0.29-1.2-0.58-1.8-0.87c-0.83,0.29-1.66,0.58-2.49,0.87c2.16,1.34,4.32,2.68,6.69,4.16
		c1.42,0.88,2.92,1.82,4.54,2.82c-0.64-2.3-0.97-3.47-1.17-4.2C300.89,825.79,299.02,824.89,297.21,824.02z"
          />
          <path
            className="st3"
            d="M165,866.98c0.01,3.05,0.02,6.09,0.03,9.14c1.61-1.32,3.23-2.65,5.57-4.57
		C168.57,869.89,166.79,868.43,165,866.98z"
          />
          <path
            className="st4"
            d="M136.28,811.91c0.29,4.07,0.56,7.87,0.88,12.29c3.53-3.95,1.11-8.22,2.41-12.44
		C138.03,811.83,137.26,811.87,136.28,811.91z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor1}
              d="M395.73,566.34c3.56,1.97,1.54,5.39,2.05,8.2c0.95-0.56,1.9-1.12,2.34-1.39c-0.63-3.88-1.18-7.29-1.74-10.7
		c-0.6-0.54-1.21-1.09-1.85-1.66C396.25,562.78,396,564.5,395.73,566.34z"
            />
          </Link>
          <path
            className="st15"
            d="M583.02,483.91c0.47-0.19,0.93-0.37,1.4-0.56c-0.21-1.07-0.26-2.2-0.67-3.19c-0.73-1.77-1.68-3.46-2.54-5.18
		c-1.19,0.4-2.39,0.8-4.1,1.37C579.26,479.1,581.14,481.5,583.02,483.91z"
          />
          <path
            className="st15"
            d="M581.24,474.98c0,0-0.06-0.07-0.06-0.07c0.01,0.02,0.03,0.05,0.04,0.07
		C581.22,474.98,581.23,474.98,581.24,474.98z"
          />
          <path
            className="st12"
            d="M1046.24,422c0.24,3.85,0.48,7.69,0.72,11.54c1.44-3.13,2.87-6.27,4.31-9.4
		C1049.59,423.43,1047.91,422.72,1046.24,422z"
          />
          <path
            className="st12"
            d="M1046.23,421.97l-0.02,0.02c0.01,0,0.02,0.01,0.02,0.01c0,0,0,0,0,0
		C1046.24,421.99,1046.24,421.98,1046.23,421.97z"
          />
          <path
            className="st3"
            d="M944.81,336.2c-3.51,5.3-3.32,7.21,1.38,9.08c-1.68-3.24-1.23-5.79,2.42-7.24
		C946.89,337.21,945.85,336.7,944.81,336.2z"
          />
          <path
            className="st5"
            d="M746.2,445.31c0.93,3.72,1.77,7.11,2.62,10.49c0.47-0.09,0.94-0.18,1.41-0.28c-0.34-3.68-0.68-7.37-1.06-11.61
		C747.72,444.6,746.87,445,746.2,445.31z"
          />
          <path
            className="st12"
            d="M704.54,475.78c0.68,0.46,1.36,0.92,2.05,1.38c0.71,0.48,1.42,0.95,2.13,1.42c-0.58,0.72-1.16,1.44-1.75,2.15
		c-0.57,0.7-1.15,1.4-1.72,2.1c1.41,0.73,2.82,1.46,4.23,2.2c-0.25-0.6-0.39-1.17-0.46-1.72c-0.03-0.23-0.05-0.46-0.05-0.69
		c0-0.19,0.01-0.38,0.02-0.56s0.04-0.37,0.07-0.55c0.09-0.49,0.24-0.97,0.43-1.44c0.09-0.22,0.18-0.44,0.29-0.66
		c0.17-0.34,0.35-0.67,0.55-1c0.4-0.66,0.85-1.31,1.3-1.95C709.26,476.22,706.9,476,704.54,475.78z"
          />
          <path
            className="st4"
            d="M1012.66,341.77c-0.79,4-1.58,8-2.41,12.24c0.59-1.02,1.17-2.02,1.74-3c1.7-2.94,3.31-5.71,4.91-8.49
		C1015.49,342.27,1014.08,342.02,1012.66,341.77z"
          />
          <path
            className="st3"
            d="M925.16,274.94c0.15,0.5,0.31,1,0.46,1.5c3.81-1.1,7.62-2.21,11.43-3.31
		C932.67,272.17,928.4,271.52,925.16,274.94z"
          />
          <path
            className="st8"
            d="M1028.22,498.59c0.19-3.21,0.39-6.42,0.6-9.95c-0.87,0.18-2.48,0.51-3.28,0.67c0,2.4,0,4.51,0,6.55
		c0,0.68,0,1.35,0,2.02C1026.43,498.11,1027.32,498.35,1028.22,498.59z"
          />
          <path
            className="st12"
            d="M839.03,348.17c0.25,0.01,0.5,0.01,0.74,0.02c0.98-2.48,1.97-4.97,3.37-8.49c-2.2,0.38-3.75,0.64-5.88,1.01
		C837.95,343.6,838.49,345.88,839.03,348.17z"
          />
          <path
            className="st5"
            d="M874.97,200.45c0.28-4.73,0.58-9.46,0.82-14.2c0.03-0.69-0.2-1.39-0.31-2.08c-0.48,0.1-0.97,0.21-1.45,0.31
		c0,6.49,0,12.99,0,19.48c0.23,0.03,0.46,0.06,0.69,0.09C874.8,202.85,874.9,201.65,874.97,200.45z"
          />
          <path
            className="st3"
            d="M943.42,348.42c-0.17,0.07-0.34,0.14-0.5,0.21c0.63,2.91,1.25,5.82,1.87,8.69c1.58,0.81,3.12,1.6,4.66,2.39
		c0.37,0.23,0.74,0.46,1.11,0.7c0.09-0.12,0.18-0.23,0.26-0.35c-0.27-0.29-0.53-0.57-0.8-0.86
		C947.82,355.61,945.62,352.01,943.42,348.42z"
          />
          <path
            className="st15"
            d="M543.24,469.18c5.02,2.91,9.66,5.6,14.29,8.29c0.17-0.27,0.33-0.54,0.5-0.8c-4.21-2.94-8.42-5.88-12.7-8.88
		C544.68,468.21,544.06,468.63,543.24,469.18z"
          />
          <path
            className="st4"
            d="M407,560.34c-1.12,1.43-2.24,2.85-3.36,4.28c-0.04,0.57-0.07,1.15-0.12,1.87c2.17,0.51,4.18,0.97,6.79,1.58
		C408.89,564.76,407.94,562.55,407,560.34z"
          />
          <path
            className="st5"
            d="M199.86,656.13c-3.94,0.37-7.88,0.74-12.37,1.17c2.95,1.67,5.85,1.9,8.71,1.86c1.3-0.02,2.59-0.09,3.88-0.12
		C200,658.07,199.93,657.1,199.86,656.13z"
          />
          <path
            className="st5"
            d="M198.63,662.11c-3.27-0.28-6.55-0.57-9.82-0.85c-0.08,0.38-0.17,0.76-0.25,1.14c0.53,0.62,1.12,1.08,1.76,1.42
		c0.32,0.17,0.65,0.31,0.99,0.43c1.7,0.59,3.64,0.58,5.52,0.71C197.43,664.01,198.03,663.06,198.63,662.11z"
          />
          <path
            className="st16"
            d="M759.69,270.72c0.98,2.12,1.97,4.24,2.95,6.35c0.85,0,1.69,0,2.98,0c-0.48-2.33-0.88-4.3-1.3-6.35
		c-1.35,0-2.48,0-3.56,0C760.39,270.72,760.04,270.72,759.69,270.72z"
          />
          <path
            className="st3"
            d="M1124.21,406.31c0.75,0.41,1.51,0.83,2.46,1.35c0-3.29,0-6.21,0-9.33c1.52,0.55,2.8,1.02,4.08,1.49
		c-1.65-1.31-3.31-2.61-5.45-4.3C1124.87,399.8,1124.54,403.05,1124.21,406.31z"
          />
          <path
            className="st5"
            d="M718.13,447.4c2.03,2.59,0.17,6.58,5.08,7.82c-0.46-3.22-0.81-5.6-1.19-8.21
		C720.85,447.13,719.87,447.23,718.13,447.4z"
          />
          <path
            className="st3"
            d="M963.34,364.97c1.23-2.22,2.22-3.99,3.47-6.24c-3.74,1.56-6.81,2.84-10.15,4.23
		C958.98,363.66,961.04,364.28,963.34,364.97z"
          />
          <path
            className="st4"
            d="M815.12,238.23c-0.77,0.21-1.53,0.42-2.42,0.66c1.01,2.83,1.96,5.51,2.97,8.33
		C818.39,244.42,818.19,242.24,815.12,238.23z"
          />
          <path
            className="st5"
            d="M892.9,202.04c-0.82-0.65-1.63-1.3-2.44-1.94c-0.52,2.12-0.94,3.83-1.42,5.75c-0.91-0.65-1.28-0.91-1.65-1.17
		c-0.22,0.31-0.45,0.61-0.67,0.92c1.31,0.98,2.61,1.95,3.92,2.93C891.4,206.37,892.15,204.21,892.9,202.04z"
          />
          <path
            className="st11"
            d="M424.29,381.14C424.29,381.14,424.28,381.14,424.29,381.14C424.28,381.14,424.29,381.14,424.29,381.14
		C424.29,381.14,424.29,381.14,424.29,381.14c-2.08-2.26-4.17-4.53-6.46-7.02c-0.73,1.41-1.08,2.11-1.49,2.9
		c0.95,1.15,1.89,2.29,2.84,3.43C420.87,380.68,422.58,380.91,424.29,381.14z"
          />
          <path
            className="st12"
            d="M858.03,227.95c-0.39-3.13-0.74-5.91-1.08-8.7c-1.12,0.24-2.24,0.48-3.36,0.72
		C852.87,223.91,855.13,226.01,858.03,227.95z"
          />
          <path
            className="st4"
            d="M366.64,357.14c-0.46,6.19,2.39,12.03,2.55,18.15c0.41-0.04,0.81-0.07,1.22-0.11
		c-0.93-6.05-1.87-12.1-2.8-18.15C367.28,357.07,366.96,357.11,366.64,357.14z"
          />
          <path
            className="st10"
            d="M415.92,382.05c-0.14,0.07-0.26,0.14-0.37,0.21c-0.22,0.15-0.39,0.31-0.52,0.48
		c-0.19,0.26-0.28,0.53-0.31,0.83c-0.01,0.1-0.01,0.19,0,0.29c0.01,0.1,0.02,0.2,0.03,0.3c0.12,0.7,0.49,1.43,0.74,2.04
		c0.74,1.8,2.06,3.36,3.14,5.02c0.54-1.37,0.68-2.64,0.52-3.84c-0.05-0.4-0.14-0.79-0.25-1.18c-0.3-1-0.79-1.95-1.41-2.87
		c-0.2-0.3-0.41-0.59-0.64-0.88c-0.05-0.07-0.1-0.13-0.16-0.2c-0.1-0.13-0.21-0.26-0.32-0.39
		C416.21,381.92,416.06,381.99,415.92,382.05z"
          />
          <path
            className="st14"
            d="M698.89,473.29c-1.8-2.7-3.47-5.19-5.13-7.69c0.24,3.4,0.48,6.8,0.72,10.2
		C695.87,475.01,697.25,474.23,698.89,473.29z"
          />
          <path
            className="st5"
            d="M725.62,447.96c0.78,2.95,1.42,5.37,2.09,7.9c1.04-0.22,1.71-0.37,2.42-0.52c-0.33-2.6-0.63-5-0.92-7.38
		C728.1,447.96,727.18,447.96,725.62,447.96z"
          />
          <path
            className="st16"
            d="M882.19,265.85L882.19,265.85c-1.95,0.49-3.9,0.98-5.85,1.47c-0.22,0.19-0.43,0.37-0.65,0.56
		c0.74,0.98,1.48,1.96,2.22,2.94c0.48-0.15,0.97-0.3,1.45-0.46c0.88-0.27,1.75-0.55,2.63-0.82h0l0,0
		C882.06,268.31,882.13,267.08,882.19,265.85z"
          />
          <path
            className="st16"
            d="M875.63,267.93c0.02-0.02,0.04-0.03,0.06-0.05l0,0C875.67,267.9,875.65,267.92,875.63,267.93z"
          />
          <path
            className="st16"
            d="M875.63,267.93c0.02-0.02,0.04-0.03,0.06-0.05c-0.01-0.01-0.02-0.02-0.02-0.03
		C875.67,267.85,875.63,267.93,875.63,267.93z"
          />
          <path
            className="st12"
            d="M1032.81,464.23c-2.36,1.85-4.44,3.48-6.52,5.11c0.26,0.37,0.52,0.74,0.78,1.11
		C1032.74,470.63,1034.14,469.04,1032.81,464.23z"
          />
          <path
            className="st3"
            d="M1137.17,404.11c4.55,3.11,9.09,6.21,13.64,9.32c0,0,0,0,0,0C1147.27,408.86,1142.65,405.86,1137.17,404.11z"
          />
          <path
            className="st4"
            d="M1117.1,435.63c1.71,0,2.98,0,4.25,0c0.1-0.46,0.15-0.89,0.15-1.29s-0.02-0.79-0.09-1.14
		c-0.21-1.07-0.75-1.94-1.51-2.69c-0.76-0.74-1.73-1.35-2.8-1.91C1117.1,430.99,1117.1,433.38,1117.1,435.63z"
          />
          <path
            className="st10"
            d="M737.25,317.54c-1.03-0.34-2.05-0.67-3.81-1.24c0.97,3.46,1.65,6.28,2.6,9c0.11,0.31,0.44,0.53,0.84,0.74
		c0.4,0.2,0.86,0.39,1.21,0.61C737.81,323.61,737.53,320.57,737.25,317.54z"
          />
          <path
            className="st1"
            d="M446.2,305.96c-2.97,2.23-5.67,4.26-8.37,6.28C443.31,312.72,446.38,310.2,446.2,305.96z"
          />
          <path
            className="st5"
            d="M1049.08,510.08c0.91-0.58,1.75-1.2,2.66-1.69c0.86-0.47,1.79-0.82,3.09-1.4c0-0.98,0-2.16,0-3.34
		c-1.2-0.72-2.39-1.44-3.59-2.15C1050.53,504.32,1049.82,507.15,1049.08,510.08z"
          />
          <path
            className="st5"
            d="M366.95,860.01c-4.18-1.38-7.74-2.56-11.61-3.83C357.76,860.2,362.52,861.94,366.95,860.01z"
          />
          <path
            className="st5"
            d="M1042.02,528.8c0.71-0.62,1.67-0.94,2.61-1.31c1.57-0.62,3.1-1.39,3.38-3.92c-3.68,1.31-7.03,2.5-10.39,3.7
		C1039.08,527.78,1040.55,528.29,1042.02,528.8z"
          />
          <path
            className="st12"
            d="M1040.65,439.5c2.57,1.42,5,2.76,7.42,4.1c0,0,0,0,0,0c0.59-1.32,1.18-2.65,1.55-3.5
		c-2.13-0.68-4.07-1.31-6.67-2.15C1042.41,438.32,1041.52,438.92,1040.65,439.5z"
          />
          <path
            className="st5"
            d="M423.22,888.61c-1.25,0.09-2.47,0.46-4.11,0.8c0.32,1.46,0.6,2.71,1.05,4.75c2.59-1.87,4.56-3.29,7.03-5.07
		C425.2,888.83,424.2,888.54,423.22,888.61z"
          />
          <path
            className="st13"
            d="M783.9,449.22c-1.34,0.25-2.68,0.5-4.02,0.75c-0.07,1.59-0.15,3.18-0.24,5.24c2.2-0.72,3.91-1.27,5.49-1.79
		c-0.23-0.78-0.44-1.5-0.64-2.19C784.29,450.55,784.1,449.89,783.9,449.22z"
          />
          <path
            className="st4"
            d="M414.2,881.06c0.4-0.27,0.8-0.54,1.2-0.8c-0.51-2.33-1.01-4.66-1.57-7.22c-1.58,0.54-2.66,0.91-4.28,1.46
		C411.2,876.82,412.7,878.94,414.2,881.06z"
          />
          <path
            className="st15"
            d="M1039.8,379.06c-0.42-3.94,1.24-7.87-2.93-10.9C1037.3,372.12,1035.61,376.06,1039.8,379.06z"
          />
          <path
            className="st4"
            d="M134.62,679.34c2.15,3.33,4.52,7,6.51,10.08C141.71,683.37,138.81,679.03,134.62,679.34z"
          />
          <path
            className="st7"
            d="M596.25,499.3c-2.33,0-4.66,0-6.91,0c-0.64,1.04-1.24,2.02-1.83,2.98s-1.15,1.88-1.72,2.81
		C589.28,503.16,592.77,501.23,596.25,499.3z"
          />
          <path
            className="st7"
            d="M980.5,403.24c0-4.69,0-9.37,0-14.06c-0.29,0.01-0.59,0.02-0.88,0.03c0,4.69,0,9.37,0,14.06
		C979.91,403.26,980.21,403.25,980.5,403.24z"
          />
          <path
            className="st7"
            d="M864.38,268.39c-0.11-0.37-0.21-0.74-0.32-1.11c-3.42,0.52-6.84,1.03-10.31,1.55
		c-0.45,0.75-0.93,1.55-1.88,3.16C856.64,270.62,860.51,269.5,864.38,268.39z"
          />
          <path
            className="st9"
            d="M1009.25,463.57c-0.26,0.3-0.51,0.6-0.77,0.9c1.09,1.37,2.17,2.74,3.26,4.12c1.95-0.26,3.89-0.52,5.84-0.78
		C1014.8,466.39,1012.02,464.98,1009.25,463.57z"
          />
          <path
            className="st4"
            d="M1147.53,574.11c1.17-2.15,1.86-4.57,2.72-6.89c-0.35-0.13-0.69-0.26-1.04-0.4c-1.69,2.18-3.38,4.35-5.71,7.36
		C1145.29,574.18,1147.25,574.6,1147.53,574.11z"
          />
          <path
            className="st3"
            d="M1120.53,422.13c-1.57-0.98-3.15-1.96-4.72-2.95c0,0,0,0,0,0c-0.31,1.38-0.62,2.76-0.89,4.01
		c2.18,1.39,3.89,2.48,5.9,3.76c-0.03-0.46-0.05-0.89-0.08-1.31C1120.66,424.37,1120.59,423.25,1120.53,422.13
		C1120.53,422.13,1120.53,422.13,1120.53,422.13L1120.53,422.13z"
          />
          <path
            className="st7"
            d="M643.58,728c-0.72,2.38-1.44,4.75-2.16,7.13v0c0.01-0.01,0.02-0.02,0.03-0.03c-0.01,0.01-0.02,0.02-0.03,0.03
		c0,0.01-0.01,0.02-0.01,0.03c2.45-1.4,4.9-2.8,7.77-4.44c-2.01-0.97-3.52-1.71-4.98-2.41C643.99,728.2,643.79,728.1,643.58,728z"
          />
          <path
            className="st9"
            d="M838.94,355.87c0.04-3.89-2.31-6.54-5.3-6.74c-0.3,0.82-0.59,1.63-0.96,2.64
		C834.71,353.1,836.61,354.35,838.94,355.87z"
          />
          <path
            className="st12"
            d="M678.78,404.14c-0.86,2.37-1.72,4.74-2.75,7.58c1.82,0,2.92,0,4.75,0c-0.39-2.76-0.73-5.18-1.07-7.6
		C679.4,404.13,679.09,404.13,678.78,404.14z"
          />
          <path
            className="st3"
            d="M285.81,540.06L285.81,540.06l0.04-0.02c0.25-0.44,0.5-0.88,0.76-1.32l0,0c0-0.18,0-0.36,0-0.54
		c0.01-0.36,0.02-0.72,0.05-1.09c0.14-2.37,0.42-4.79-1.73-6.84c-0.7,1.36-1.28,2.47-1.86,3.59c0.27,0,0.53,0.01,0.8,0.01
		c0.22,2.12,0.43,4.23,0.65,6.35C284.95,540.16,285.38,540.11,285.81,540.06z"
          />
          <path
            className="st12"
            d="M715.1,477.73c-1.44,2.26-2.15,3.51-2.05,4.4c0.05,0.43,0.29,0.78,0.72,1.12c0.46,0.37,1.14,0.73,2.07,1.17
		C718.19,480.98,718,479.29,715.1,477.73z"
          />
          <path
            className="st5"
            d="M403.62,556.49c-0.38,0.22-0.76,0.43-1.14,0.65c0.38,2.5,0.77,4.99,1.15,7.49c1.12-1.43,2.24-2.85,3.36-4.28
		C405.87,559.06,404.74,557.77,403.62,556.49z"
          />
          <path
            className="st5"
            d="M295.4,823.15c-3.91-1.82-7.83-3.64-11.74-5.46c-0.18,0.38-0.36,0.75-0.54,1.13c3.27,1.73,6.53,3.47,9.8,5.2
		C293.75,823.73,294.57,823.44,295.4,823.15z"
          />
          <path
            className="st15"
            d="M671.57,399.03c-1.8-0.25-3.39-0.47-5.08-0.7c0.67,2.99,1.22,5.42,1.94,8.63
		C669.65,403.9,670.54,401.64,671.57,399.03z"
          />
          <path
            className="st12"
            d="M846.37,245.75c-0.28,2.15-0.55,4.26-0.83,6.36c0.09,0.08,0.17,0.19,0.27,0.23c0.1,0.04,0.29,0.04,0.33-0.02
		c2.41-3.64,2.38-7.8,2.52-11.93c-0.39-0.35-0.79-0.71-1.43-1.28C846.93,241.4,846.64,243.59,846.37,245.75z"
          />
          <path
            className="st4"
            d="M332.1,386.43c-0.65-2.64-1.29-5.27-1.94-7.91c-0.28-0.1-0.55-0.2-0.83-0.3c-0.75,1.74-1.5,3.49-2.09,4.86
		c1.78,1.5,2.94,2.47,4.09,3.44C331.59,386.49,331.84,386.46,332.1,386.43z"
          />
          <path
            className="st4"
            d="M86.22,712.14c0.33-0.32,0.65-0.65,0.98-0.97c-2.21-2.14-4.43-4.28-7.32-7.09
		C79.99,709.46,83.05,710.85,86.22,712.14z"
          />
          <path
            className="st4"
            d="M774.38,391.95c0.05,0.25,0.09,0.49,0.14,0.74c-2.23,0.79-4.47,1.58-6.7,2.37c-0.11-0.23-0.22-0.47-0.33-0.7
		c-0.45,0.81-0.9,1.62-1.35,2.43c3.8-1.15,7.6-2.31,11.4-3.46C776.48,392.87,775.43,392.41,774.38,391.95z"
          />
          <path
            className="st3"
            d="M92.25,719.92c-0.86,1.79-1.39,2.91-2.05,4.28c2.35,0.56,4.43,1.06,6.52,1.56c0.32-0.33,0.63-0.65,0.95-0.98
		C95.93,723.23,94.19,721.66,92.25,719.92z"
          />
          <path
            className="st4"
            d="M883.37,300.27c-2.22-0.32-4.33-0.62-6.43-0.92c-0.14,0.34-0.28,0.69-0.43,1.03c2.07,1.3,4.15,2.6,6.86,4.3
		C883.37,302.5,883.37,301.49,883.37,300.27z"
          />
          <path
            className="st12"
            d="M1082.83,328.74c-0.09-1.48-0.17-2.95-0.27-4.66c-1.32,0-2.29,0-3.57,0c0.85,2.89,1.65,5.59,2.45,8.29
		C1081.91,331.17,1082.37,329.96,1082.83,328.74C1082.83,328.74,1082.83,328.74,1082.83,328.74z"
          />
          <path
            className="st14"
            d="M171.07,782.59c-1.77,6.67-3.89,12.41-1.56,18.96C170.02,795.26,170.51,789.37,171.07,782.59z"
          />
          <path
            className="st5"
            d="M882.88,418.41c-1.22-0.43-2.34-0.54-3.37-0.44c-0.11,0.01-0.22,0.03-0.32,0.04c-0.38,0.06-0.74,0.14-1.1,0.25
		c-0.32,0.1-0.64,0.21-0.95,0.34c-2.17,0.93-4.07,2.66-6.11,4c0.85,0.35,1.58,0.24,2.25,0c3.23-1.17,6.45-2.38,9.67-3.58
		C882.95,418.81,882.93,418.61,882.88,418.41z"
          />
          <path
            className="st16"
            d="M900.22,399.78c-1.42-5.32-4.53-6.62-7.69-3.79C894.92,397.17,897.15,398.26,900.22,399.78z"
          />
          <path
            className="st4"
            d="M1037.61,527.26c-1.25,0.32-2.51,0.63-4.31,1.09c1.98,2.31,3.67,2.72,5.23,2.32c1.21-0.31,2.35-1.1,3.48-1.87
		C1040.55,528.29,1039.08,527.78,1037.61,527.26z"
          />
          <path
            className="st4"
            d="M238.67,688.3c2.06,0.54,4.11,1.27,6.21,1.52c0.58,0.07,1.33-1.26,2.68-2.66c-3.61-0.16-6.1-0.27-8.59-0.38
		C238.86,687.28,238.76,687.79,238.67,688.3z"
          />
          <path
            className="st5"
            d="M311.93,827.58c-0.1,0.89-0.2,1.8-0.31,2.75c0.47-0.11,0.88-0.27,1.23-0.48c0.18-0.1,0.34-0.22,0.49-0.35
		c0,0,0,0,0,0c0.68-0.58,1.08-1.38,1.23-2.31c0.28-1.77-0.37-4.04-1.89-6.24c0,0,0,0,0,0.01
		C312.43,823.11,312.19,825.27,311.93,827.58z"
          />
          <path
            className="st5"
            d="M1057.01,535.29c-2.33-0.88-4.66-1.75-8.01-3.01c0.52,0.87,0.95,1.61,1.34,2.27c0.65,1.09,1.16,1.96,1.67,2.82
		c0,0,0,0,0,0C1053.68,536.67,1055.35,535.98,1057.01,535.29z"
          />
          <path
            className="st5"
            d="M330.48,767.42c-2.34,0.32-4.68,0.64-7.94,1.09c1.35,0.78,2.5,1.45,3.55,2.05c0.43,0.25,0.84,0.48,1.24,0.72
		c0.19,0.11,0.38,0.22,0.56,0.33c0.38,0.22,0.76,0.44,1.14,0.66l0,0v0C329.51,770.66,330,769.04,330.48,767.42z"
          />
          <path
            className="st15"
            d="M547.43,463.66c-0.21,0.19-0.66,0.59-1.12,0.99l0,0c2.1,2.31,5.06,2.39,7.12,0.47
		c0.46-0.43,0.88-0.96,1.23-1.58c-2.18,0-4.37,0-7.11,0C547.54,463.56,547.5,463.59,547.43,463.66z"
          />
          <path
            className="st15"
            d="M554.76,463.55C554.76,463.55,554.76,463.55,554.76,463.55c0,0-0.06-0.06-0.06-0.06
		c-0.01,0.02-0.03,0.04-0.04,0.06C554.7,463.55,554.73,463.55,554.76,463.55z"
          />
          <path
            className="st4"
            d="M1000.19,264.39c0.25-0.44,0.5-0.89,0.75-1.33c-4.23-2.21-8.47-4.43-12.7-6.64l0,0c-0.85-0.5-1.7-1-2.56-1.5
		c-0.12,0.23-0.23,0.46-0.35,0.68c0.77,0.45,1.54,0.9,2.32,1.35C991.83,259.43,996.01,261.91,1000.19,264.39z"
          />
          <path
            className="st12"
            d="M807.73,379.72c0,3.2,0,5.8,0,8.5c0.93,0,1.71,0,3.9,0C809.09,385.52,811.05,381.99,807.73,379.72z"
          />
          <path
            className="st4"
            d="M964.8,369.04c2.15-4.11,4.29-8.22,6.44-12.33c-0.44-0.3-0.88-0.6-1.31-0.9c-2.02,4.25-4.05,8.51-6.07,12.76
		C964.17,368.73,964.48,368.88,964.8,369.04z"
          />
          <path
            className="st8"
            d="M806.76,270.69c-1.15,0.46-2.36,0.79-3.56,1.14c-0.4,0.11-0.8,0.23-1.2,0.35c0,0,0,0,0,0
		c3.75-0.24,7.49-0.47,11.24-0.71h0c0-1.3,0-2.59,0-3.68C811.12,268.75,808.98,269.8,806.76,270.69z"
          />
          <path
            className="st15"
            d="M540.15,466.3c1.93-0.66,3.54-1.21,5.21-1.79c-0.34-1-0.7-2.04-1.07-3.14c-2.27,1.12-4.42,2.18-6.54,3.23
		C538.61,465.21,539.52,465.86,540.15,466.3z"
          />
          <path
            className="st12"
            d="M991,355.45c1.1,0.5,2.2,1.01,3.3,1.51c0.31-0.32,0.61-0.65,0.92-0.97c-1.38-2.39-2.77-4.77-4.15-7.16v0
		C991.05,351.04,991.02,353.24,991,355.45L991,355.45z"
          />
          <path
            className="st7"
            d="M647.86,609.97c0.98-0.66,1.95-1.33,3-2.04c1.05-0.71,2.17-1.48,3.44-2.34c-1.08-0.51-1.98-0.94-2.78-1.32
		c-0.81-0.38-1.53-0.72-2.24-1.06C648.81,605.47,648.34,607.72,647.86,609.97z"
          />
          <path
            className="st4"
            d="M306.34,396.16c-0.09-0.36-0.17-0.72-0.26-1.07c-3.51,0.77-7.02,1.54-10.53,2.3c0.07,0.32,0.14,0.63,0.22,0.95
		C299.46,398.31,303.28,398.78,306.34,396.16z"
          />
          <path
            className="st12"
            d="M882.95,419.02c1.77,0.34,3.54,0.68,5.37,1.03c0-1.83,0-3.15,0-5.37c-1.11,0.76-2.06,1.41-2.95,2.02
		s-1.69,1.16-2.49,1.71c0,0,0,0,0,0C882.93,418.61,882.95,418.81,882.95,419.02z"
          />
          <path
            className="st9"
            d="M897.06,369.46c0.14-1.83-0.24-3.7-0.41-5.56c-0.55,0.03-1.11,0.05-1.66,0.08c-0.29,2.29-0.57,4.58-0.86,6.88
		c0.3,0.18,0.59,0.36,0.89,0.55C895.73,370.76,897,370.16,897.06,369.46z"
          />
          <path
            className="st7"
            d="M604.25,757.41c-0.38-0.41-0.75-0.81-1.16-1.26s-0.85-0.92-1.35-1.46c-1.54,1-2.83,1.84-4.12,2.68
		c1.16,0.69,2.33,1.38,3.7,2.19c0.47-0.35,0.96-0.71,1.45-1.07C603.25,758.14,603.75,757.78,604.25,757.41L604.25,757.41z"
          />
          <path
            className="st4"
            d="M450.26,482.69c2.01-1.7,3.42-2.9,4.83-4.09c-1.61-0.74-3.22-1.49-4.83-2.23
		C450.26,478.28,450.26,480.19,450.26,482.69z"
          />
          <path
            className="st4"
            d="M359.91,402.44c-0.05,0.06-0.11,0.12-0.16,0.18c0.01,0,0.01,0,0.02,0l0,0c0.01-0.01,0.02-0.01,0.03-0.02
		c-0.01,0.01-0.02,0.01-0.03,0.02c0,0,0,0,0,0c2.16-0.47,4.33-0.94,6.49-1.42c-0.34-1.58-0.69-3.15-1.04-4.79
		c-0.97,1.11-1.9,2.16-2.81,3.19C361.56,400.55,360.73,401.5,359.91,402.44z"
          />
          <path
            className="st9"
            d="M740.39,281.53c-0.96,0.17-2.01,0.69-2.96,0.87c-0.24,1.12-0.48,2.25-0.73,3.37c2.09-0.57,4.18-1.14,6.54-1.79
		c-0.24-1.04-0.58-1.68-1.01-2.06c-0.21-0.19-0.44-0.31-0.68-0.38C741.2,281.44,740.8,281.45,740.39,281.53z"
          />
          <path
            className="st3"
            d="M262.29,724.12c-0.19,0.11-0.37,0.22-0.56,0.33h0c1.42,0.95,2.85,1.9,4.27,2.85c0.4-1.76,0.79-3.52,1.38-6.13
		C265.03,722.53,263.6,723.36,262.29,724.12z"
          />
          <path
            className="st11"
            d="M905.74,278.02c0,0,0,0.01,0.01,0.01C905.75,278.03,905.75,278.02,905.74,278.02c-0.02,0-0.03,0-0.05,0
		c0,0,0,0,0,0c1.08,2.74,2.16,5.48,3.33,8.44c0.27-2.27,0.51-4.37,0.75-6.42c0.08-0.68,0.16-1.36,0.24-2.04l0,0
		C908.59,278.01,907.17,278.01,905.74,278.02z"
          />
          <path
            className="st9"
            d="M668.8,350.12c0.24,2.93,0.39,4.8,0.54,6.67c1.43-0.95,2.87-1.9,4.3-2.85
		C672.18,352.78,670.71,351.63,668.8,350.12z"
          />
          <path
            className="st12"
            d="M1017.82,476.32c-0.23,0.07-0.45,0.14-0.68,0.22c-0.3,2.35-0.61,4.69-0.91,7.04c1.36,0,2.72,0,4.08,0
		C1019.49,481.16,1018.66,478.74,1017.82,476.32z"
          />
          <path
            className="st7"
            d="M804.78,642.13c0.47-0.31,0.94-0.63,1.42-0.94c1.43-0.95,2.9-1.93,4.56-3.03c-0.93-0.46-1.81-0.89-2.68-1.32
		c-0.87-0.43-1.71-0.84-2.56-1.26C805.27,637.77,805.02,639.95,804.78,642.13z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor}
              d="M390.6,490.72c0.51,3.33,1.02,6.67,1.61,10.57C394.04,497.13,393.77,494.63,390.6,490.72z"
            />
          </Link>
          <path
            className="st5"
            d="M789.94,237.53c-1.95-2.39-4.49-2.59-7.96-2.29C784.26,239.37,787.43,237.22,789.94,237.53z"
          />
          <path
            className="st4"
            d="M894.91,435.23c1.83,0.99,3.47,1.89,5.12,2.78c0.18-0.22,0.36-0.43,0.54-0.65c-1.48-2.16-2.95-4.33-4.5-6.6
		C895.68,432.24,895.35,433.53,894.91,435.23z"
          />
          <path
            className="st12"
            d="M417.7,465.6c-2.7-1.78-5.05-3.34-7.93-5.24C411.87,465.29,413.91,466.67,417.7,465.6z"
          />
          <path
            className="st3"
            d="M233.23,539.88c0.38,0.05,0.75,0.09,1.13,0.14c0.72-2.43,1.44-4.87,2.23-7.54c-1.43,0.46-2.47,0.79-3.71,1.18
		C233.01,535.84,233.12,537.86,233.23,539.88z"
          />
          <path
            className="st4"
            d="M273,810.43c0,0-0.02-0.03-0.02-0.03c-0.2-0.27-0.4-0.54-0.59-0.81c0,0,0,0,0,0
		c-0.51-1.18-1.03-2.35-1.54-3.53c-0.41-1.17-0.81-2.35-1.22-3.52c0,2.71,0,5.41,0,8.13c1.18,0.74,2.35,1.47,3.51,2.2
		C273.09,812.05,273.04,811.24,273,810.43z"
          />
          <path
            className="st13"
            d="M800.38,424.1c0.01,0.02,0.03,0.04,0.04,0.07C800.4,424.15,800.39,424.12,800.38,424.1L800.38,424.1
		L800.38,424.1z"
          />
          <path
            className="st13"
            d="M796.88,418.51c1.16,1.86,2.33,3.73,3.49,5.59c0.23-0.12,0.46-0.24,0.7-0.37c0.25-0.13,0.51-0.27,0.8-0.43
		c0.59-0.31,1.3-0.69,2.25-1.21c-1.09-0.65-2.08-1.24-3.01-1.79c-1.54-0.92-2.9-1.73-4.27-2.55
		C796.87,418,796.87,418.26,796.88,418.51z"
          />
          <path
            className="st4"
            d="M382.44,720.56c2.96,1.75,5.92,3.51,8.88,5.26c0.49-0.26,0.97-0.51,1.46-0.77
		C389.45,723.33,386.74,720.27,382.44,720.56z"
          />
          <path
            className="st7"
            d="M423.5,469.66c1.32,2.31,2.52,4.42,3.72,6.52c0.25-0.01,0.51-0.03,0.76-0.04c-0.68-2.75-1.36-5.5-2.14-8.64
		C424.77,468.48,424.12,469.09,423.5,469.66z"
          />
          <path
            className="st16"
            d="M769.62,609.99c2.18,1.06,4.36,2.13,6.53,3.19C775.89,608.92,773.37,607.68,769.62,609.99z"
          />
          <path
            className="st9"
            d="M510.8,439.53c0.74,3.08,1.2,4.99,1.67,6.91c1.18-0.74,2.36-1.48,3.54-2.22
		C514.51,442.87,513.02,441.53,510.8,439.53z"
          />
          <path
            className="st1"
            d="M725.83,146c0.64-0.64,1.28-1.28,2.04-2.03c-0.56-1.95-1-3.5-1.53-5.37c-3.31,2.31-1.11,4.72-1.06,6.9
		C725.28,145.61,725.53,145.73,725.83,146z"
          />
          <path
            className="st4"
            d="M702.9,327.12c0.08,0.58,0.15,1.16,0.23,1.73c1.18-1.93,2.36-3.87,3.54-5.8c-1.35-0.48-2.7-0.95-4.52-1.6
		C702.43,323.56,702.67,325.38,702.9,327.12z"
          />
          <path
            className="st5"
            d="M308.96,762.28c-0.49-0.23-0.97-0.46-1.46-0.69c-2.44-0.95-4.88-1.9-7.33-2.85c-0.15,0.39-0.31,0.77-0.46,1.16
		c3.08,1.27,6.16,2.55,9.24,3.82C308.95,763.24,308.95,762.76,308.96,762.28z"
          />
          <path
            className="st13"
            d="M205.58,810.82c0.37-2.32,0.7-4.38,1.21-7.53c-2.07,1.83-3.33,2.95-4.24,3.76
		C203.76,808.56,204.61,809.62,205.58,810.82z"
          />
          <path
            className="st4"
            d="M270.68,651.96c1.19-1.13,1.82-1.72,2.44-2.32c-0.62,0.43-1.25,0.85-1.87,1.28c-1.39-1.64-2.78-3.29-4.17-4.93
		C268.21,647.86,269.37,649.79,270.68,651.96z"
          />
          <path
            className="st4"
            d="M266.02,644.72c0.36,0.42,0.71,0.84,1.07,1.26c-0.35-0.58-0.69-1.15-1.04-1.72
		C266.04,644.42,266.03,644.57,266.02,644.72z"
          />
          <path
            className="st4"
            d="M737.08,391.89c-0.19,0.4-0.38,0.81-0.56,1.21c-1.63,0.56-3.27,1.11-4.9,1.67c2.64,0.5,5.27,0.99,7.91,1.49
		c0-0.01-0.01-0.01-0.01-0.02c0,0-0.01,0-0.01-0.01c0,0,0.01,0,0.01,0.01C738.7,394.78,737.89,393.33,737.08,391.89z"
          />
          <path
            className="st13"
            d="M204.5,782.5c-0.32,0.07-0.64,0.13-0.96,0.2c0.86,4.36,1.72,8.72,2.59,13.07c0.41-0.05,0.81-0.11,1.22-0.16
		C206.39,791.24,205.45,786.87,204.5,782.5z"
          />
          <path
            className="st5"
            d="M284,452.69c0.75-0.68,1.61-1.14,2.42-1.55c0.32-0.17,0.64-0.32,0.94-0.49c0.3-0.16,0.58-0.33,0.83-0.52
		c0.76-0.56,1.29-1.28,1.32-2.49c0.01-0.2,0-0.42-0.03-0.65c-1.37,0.24-2.74,0.48-4.1,0.72C285,449.09,284.62,450.46,284,452.69z"
          />
          <path
            className="st4"
            d="M117.96,697.77c-0.13-0.46-0.32-1.09-0.38-1.3c-2.69-0.24-4.9-0.43-7.25-0.64
		C111.89,698.37,116.35,699.58,117.96,697.77z"
          />
          <path
            className="st5"
            d="M786.77,440.42c0.59,2.82-0.16,6.18,4.29,8.34c-1.32-3.4-2.34-6.05-3.37-8.7
		C787.38,440.18,787.08,440.3,786.77,440.42z"
          />
          <path
            className="st5"
            d="M1130.78,388.73c0.22-0.35,0.43-0.7,0.65-1.05c-2.85-1.85-5.71-3.71-8.56-5.56c-0.27,0.43-0.54,0.86-0.81,1.3
		C1124.97,385.19,1127.87,386.96,1130.78,388.73z"
          />
          <path
            className="st4"
            d="M972.37,349.58c-0.98-0.76-1.95-1.51-2.31-1.8c-1.07,1.83-1.92,3.27-2.76,4.71
		C969.16,353.6,969.16,353.6,972.37,349.58z"
          />
          <path
            className="st14"
            d="M834.85,436.35c1.18-3.01,2-5.12,2.83-7.23c-1.67,1.22-3.35,2.44-5.02,3.65
		C833.22,433.69,833.78,434.6,834.85,436.35z"
          />
          <path
            className="st12"
            d="M862.96,376.17c-0.23-0.06-0.46-0.11-0.69-0.17c-0.69,2.25-1.37,4.5-2.16,7.09c1.6,0,2.46,0,3.22,0
		C863.19,380.66,863.08,378.42,862.96,376.17z"
          />
          <path
            className="st9"
            d="M848.76,217.58c-2.38,2.65-1.85,6.13,1.45,9.99C849.72,224.2,849.24,220.88,848.76,217.58z"
          />
          <path
            className="st13"
            d="M750.14,333.91c1.12,0.46,2.13,0.6,3.06,0.53c0.47-0.04,0.92-0.13,1.35-0.26c2.17-0.67,3.95-2.43,5.86-3.85
		c0,0,0,0,0,0C756.99,331.52,753.57,332.71,750.14,333.91z"
          />
          <path
            className="st5"
            d="M792.89,438.91c0.63,3.12,1.26,6.24,1.89,9.36c0.38-0.06,0.76-0.12,1.14-0.18c-0.45-3.15-0.9-6.3-1.35-9.45
		C794.01,438.73,793.45,438.82,792.89,438.91z"
          />
          <path
            className="st13"
            d="M520.92,508.78c1.1,0.6,2.2,1.21,3.3,1.81c0.13-0.24,0.26-0.49,0.39-0.73c-1.01-0.54-2.02-1.08-3.03-1.63
		c-3.48-2.06-6.95-4.13-10.43-6.19c0,0,0,0,0,0c-0.25,0.2-0.5,0.41-0.76,0.61C513.9,504.7,517.41,506.74,520.92,508.78z"
          />
          <path
            className="st4"
            d="M119.02,690.06c-0.06-0.46-0.12-0.92-0.19-1.39c-2.78-0.2-5.66-0.78-7.35,2.83c1.19-0.42,2.38-0.84,3.3-1.16
		C116.29,690.25,117.65,690.15,119.02,690.06z"
          />
          <path
            className="st14"
            d="M806.39,479.16c-0.61,3.53,1.35,5.04,4.47,5.87c-0.05-0.76-0.09-1.39-0.13-1.99c-0.01-0.2-0.03-0.4-0.04-0.59
		C809.25,481.36,807.82,480.26,806.39,479.16z"
          />
          <path
            className="st7"
            d="M936.88,296.56c-3.62,0.5-7.23,1-10.85,1.5c0.05,0.35,0.09,0.69,0.14,1.04c3.62-0.41,7.25-0.82,10.87-1.23
		C936.99,297.43,936.94,297,936.88,296.56z"
          />
          <path
            className="st5"
            d="M969.76,278.84c0.77-3.21,1.43-5.97,2.09-8.74C969.5,272.54,966.99,274.94,969.76,278.84z"
          />
          <path
            className="st3"
            d="M265.04,704.39c-2.09,0-3.67,0-6.21,0c2.78,1.66,4.8,2.87,7.54,4.51
		C265.76,706.82,265.36,705.48,265.04,704.39z"
          />
          <path
            className="st8"
            d="M528.86,596.21c1.08,0.63,2.11,1.39,3.26,1.86c1.15,0.47,2.34,0.81,3.54,1.17c0.4,0.12,0.8,0.24,1.19,0.36
		c-3.36-1.9-6.71-3.8-10.07-5.7c0,0,0,0,0,0C527.47,594.66,528.17,595.43,528.86,596.21z"
          />
          <path
            className="st4"
            d="M951.25,424.43c0.67-0.74,1.34-1.48,2.58-2.83c-1.62-1.29-2.79-2.22-4.75-3.78c0.5,2.79,0.82,4.58,1.14,6.37
		C950.56,424.27,950.91,424.35,951.25,424.43z"
          />
          <path
            className="st13"
            d="M753.95,454.24c-0.25,1.31-0.49,2.63-0.77,4.13c1.48,0,2.63,0,3.99,0c-0.38-1.79-0.73-3.43-1.08-5.07
		C755.38,453.61,754.67,453.92,753.95,454.24z"
          />
          <path
            className="st4"
            d="M556.83,727.86c-1.43,0.78-2.86,1.57-4.94,2.71c0.59,0.25,1.13,0.49,1.64,0.71s0.99,0.43,1.44,0.62
		c0.23,0.1,0.45,0.19,0.66,0.29c0.65,0.28,1.26,0.54,1.88,0.81c0,0,0,0,0,0c0,0,0,0-0.01-0.01c0,0,0,0,0.01,0.01
		C557.29,731.28,557.06,729.57,556.83,727.86z"
          />
          <path
            className="st7"
            d="M745.03,270.68c0.27-0.98,0.54-1.95,0.8-2.93c-0.26,0.09-0.51,0.17-0.77,0.26c-0.73-2.05-1.46-4.1-2.19-6.15
		c-0.34,0.13-0.67,0.26-1.01,0.39c0.7,2.88,1.39,5.77,2.09,8.65C744.32,270.82,744.68,270.75,745.03,270.68z"
          />
          <path
            className="st14"
            d="M965.23,311.68L965.23,311.68c-0.34,0.05-0.68,0.1-1.02,0.15c-0.35,0.05-0.71,0.11-1.07,0.16
		c0.87,3.09,1.66,5.91,2.45,8.73c0.12,0,0.24,0.01,0.36,0.01c0-2.32,0-4.63,0-6.95l0.03,0.04
		C965.73,313.11,965.48,312.4,965.23,311.68z"
          />
          <path
            className="st14"
            d="M965.25,311.68c0,0-0.04-0.05-0.04-0.05l0,0c0.01,0.02,0.01,0.03,0.02,0.05h0
		C965.24,311.68,965.25,311.68,965.25,311.68z"
          />
          <path
            className="st5"
            d="M967.08,295.68c-0.17-1.74-0.32-3.31-0.48-4.88c-1.13,1.71-2.26,3.43-3.4,5.14
		C964.24,295.87,965.27,295.8,967.08,295.68z"
          />
          <path
            className="st3"
            d="M409.05,331.57c-1.29,0.46-2.34,0.83-3.89,1.39c1.24,1.71,2.28,3.13,3.89,5.35
		C409.05,335.3,409.05,333.57,409.05,331.57z"
          />
          <path
            className="st15"
            d="M565.13,451.17c-1.53,0.77-2.75,1.38-3.97,1.99c-0.01,0.5-0.02,1-0.02,1.5c1.67,0.07,3.34,0.15,5.9,0.26
		C566.03,452.93,565.6,452.09,565.13,451.17z"
          />
          <path
            className="st12"
            d="M509.61,651.19c-2.54-2.24-5.07-4.49-8.49-4.82c-0.15,0.3-0.29,0.61-0.44,0.91c2.77,1.47,5.54,2.94,8.3,4.41
		C509.2,651.52,509.4,651.35,509.61,651.19z"
          />
          <path
            className="st4"
            d="M800.34,424.12c-0.98-0.52-1.96-1.05-2.95-1.57c-0.67,1.45-1.33,2.9-2,4.35
		C797.04,425.98,798.69,425.05,800.34,424.12z"
          />
          <path
            className="st3"
            d="M126.45,529.14c-0.89,0.7-1.66,1.31-2.39,1.89c-0.24,0.19-0.49,0.38-0.73,0.57c0.96,0.66,2.27,1.11,2.81,2.02
		c0.77,1.29,0.99,2.92,1.44,4.4C127.27,535.15,128.64,532.04,126.45,529.14z"
          />
          <path
            className="st11"
            d="M767.96,315.16c1.66,0,3.06,0,4.45,0c0-1.14,0-2.27,0-3.84c-0.67,0.21-1.27,0.4-1.83,0.58
		c-0.56,0.18-1.08,0.34-1.6,0.51C768.65,313.29,768.32,314.18,767.96,315.16z"
          />
          <path
            className="st14"
            d="M705.25,482.83c0.65,1.75,1.31,3.5,1.96,5.26c0.39,0.07,0.77,0.15,1.16,0.22c0.37-1.09,0.74-2.18,1.1-3.28
		C708.06,484.29,706.66,483.56,705.25,482.83z"
          />
          <path
            className="st12"
            d="M992.39,331.01c2.19,1.33,4.37,2.67,6.56,4c0.2-0.32,0.41-0.65,0.61-0.97c-1.64-1.52-3.27-3.03-4.91-4.55
		C993.9,330,993.14,330.51,992.39,331.01z"
          />
          <path
            className="st8"
            d="M589.9,748.8c-2.26,0.26-4.08,0.48-5.91,0.69c1.7,0.93,3.39,1.86,5.09,2.79
		C589.26,751.48,589.45,750.7,589.9,748.8z"
          />
          <path
            className="st5"
            d="M273.14,812.86L273.14,812.86c2.06,1.28,4.13,2.56,6.19,3.83c0.19-0.29,0.37-0.57,0.56-0.86
		c-2.3-1.8-4.59-3.61-6.89-5.41C273.04,811.24,273.09,812.05,273.14,812.86z"
          />
          <path
            className="st15"
            d="M574.11,483.91c-0.84-1.72-1.67-3.43-2.52-5.17c-0.91,0.68-1.79,1.34-2.97,2.22
		c1.81,1.31,3.36,2.43,4.91,3.55C573.72,484.3,573.92,484.1,574.11,483.91z"
          />
          <path
            className="st4"
            d="M126.27,606.73c-0.29,0.25-0.55,0.48-0.81,0.71c-0.02,0.02-0.04,0.03-0.05,0.05
		c-0.21,1.79-0.42,3.59-0.62,5.38c1.12-0.58,2.24-1.16,3.3-1.71c-0.24-1.76-0.45-3.32-0.73-5.37
		C126.93,606.15,126.58,606.45,126.27,606.73z"
          />
          <path
            className="st12"
            d="M849.32,355.33c-1.28,0.38-2.39,0.71-3.53,1.05c1.09,1.22,2.02,2.25,2.86,3.19
		C851.14,358.45,852.14,357.22,849.32,355.33z"
          />
          <path
            className="st9"
            d="M913.88,355.72c-0.94-2.5-1.87-4.99-2.81-7.49c-0.52,0.22-1.04,0.44-1.56,0.65c1.1,2.43,2.19,4.86,3.29,7.29
		C913.16,356.03,913.52,355.87,913.88,355.72z"
          />
          <path
            className="st1"
            d="M844.05,421.24c-0.81-2.8-1.62-5.6-2.43-8.4c-0.14,0.06-0.28,0.12-0.42,0.18c0.32,3.44,0.63,6.88,0.95,10.31v0
		C842.78,422.63,843.42,421.94,844.05,421.24z"
          />
          <path
            className="st13"
            d="M829.95,439.93c0.7-0.03,1.41-0.06,2.11-0.09c-0.59-1.73-1.18-3.46-2.06-6.05c-1.01,2.04-1.54,3.12-2.02,4.08
		c0.36,0.38,0.7,0.73,1.02,1.07C829.33,439.28,829.64,439.61,829.95,439.93L829.95,439.93L829.95,439.93z"
          />
          <path
            className="st3"
            d="M853.59,219.97c1.12-0.24,2.24-0.48,3.36-0.72c0.22-1.15,0.44-2.3,0.7-3.64
		C854.72,215.93,852.93,216.85,853.59,219.97z"
          />
          <path
            className="st4"
            d="M640.7,673.52c0.55-0.27,1.1-0.54,1.66-0.82c1.69-0.83,3.49-1.72,5.75-2.84c-0.6-0.29-1.08-0.52-1.49-0.71
		c-0.68-0.32-1.15-0.55-1.63-0.78C643.56,670.09,642.13,671.8,640.7,673.52z"
          />
          <path
            className="st4"
            d="M71.83,737.3c0.21,2.54,0.41,5.09,0.61,7.45c0.29,0.1,0.56,0.2,0.81,0.29c0.77,0.27,1.42,0.5,2.07,0.73
		C74.16,742.96,72.99,740.13,71.83,737.3z"
          />
          <path
            className="st4"
            d="M711.01,462.79c0.41,0,0.82,0,1.22,0c-0.94-3.62-1.87-7.24-2.81-10.85c-0.29,0.07-0.59,0.13-0.88,0.2
		C709.36,455.69,710.19,459.24,711.01,462.79L711.01,462.79z"
          />
          <path
            className="st3"
            d="M308.97,762.28c0,0-0.01,0-0.01-0.01c0,0.48,0,0.97-0.01,1.45c1.89,0.45,3.77,0.9,5.66,1.35
		c0.73-0.55,1.47-1.1,2.2-1.65c0,0,0,0,0,0c-2.59-0.38-5.18-0.77-7.76-1.15c-0.03,0-0.06-0.01-0.09-0.01h0c0,0,0,0.01,0,0.01l0,0
		C308.96,762.28,308.96,762.28,308.97,762.28z"
          />
          <path
            className="st12"
            d="M510.59,641.11c0.39,0.17,0.77,0.33,1.13,0.49c2.58,1.12,4.68,2.03,6.79,2.94c0,0,0,0,0,0
		c0.54,0.05,1.08,0.09,1.62,0.14c0.01-0.17,0.02-0.34,0.04-0.51c-0.42-0.04-0.85-0.08-1.27-0.12
		C516.69,642.06,514.62,639.64,510.59,641.11z"
          />
          <path
            className="st5"
            d="M288.99,815.45c-2.45-1.1-4.89-2.19-7.34-3.29c-0.14,0.3-0.29,0.59-0.43,0.89c2.39,1.21,4.78,2.42,7.17,3.64
		C288.59,816.28,288.79,815.87,288.99,815.45z"
          />
          <path
            className="st4"
            d="M571.13,741.56c2.64,1.68,5.28,3.37,7.93,5.05C577.37,743.42,574.55,742.02,571.13,741.56z"
          />
          <path
            className="st3"
            d="M984.34,332.62c-0.29-0.27-0.57-0.53-0.86-0.8c-0.12,0.09-0.23,0.18-0.35,0.27c0.23,0.37,0.46,0.74,0.69,1.11
		c1.45,1.66,2.89,3.32,4.34,4.98l0,0c0,0,0,0,0.01,0l0,0c0-0.95,0.01-1.9,0.01-2.85C986.9,334.42,985.62,333.52,984.34,332.62
		C984.34,332.62,984.34,332.62,984.34,332.62z"
          />
          <polygon
            className="st3"
            points="988.16,338.17 988.17,338.18 988.17,338.18 	"
          />
          <path
            className="st17"
            d="M926.53,269.27c-1.44-2.11-2.89-4.21-4.33-6.32"
          />
          <path
            className="st4"
            d="M368.78,352.86c1.05-0.97,2.02-1.87,3-2.77c0,0,0,0,0,0c0.56-0.37,1.13-0.75,1.69-1.12
		c5.47-2.68,10.94-5.36,16.4-8.04c-0.21-0.43-0.42-0.87-0.63-1.3c-1.4,0.65-2.8,1.3-4.19,1.98c-4.17,2.03-8.26,4.22-12.16,6.83
		c0,0,0,0,0,0c-0.56,0.38-1.13,0.75-1.69,1.13c-1.84,1.06-3.67,2.11-5.7,3.28c1.03,0.48,1.73,0.81,2.2,1.02
		C368.07,353.51,368.43,353.18,368.78,352.86z"
          />
          <path
            className="st3"
            d="M722.31,636.9C722.31,636.9,722.31,636.9,722.31,636.9C722.31,636.9,722.31,636.9,722.31,636.9
		C722.31,636.9,722.31,636.9,722.31,636.9c0,0,0.01-0.01,0.01-0.01c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c-0.4,0.2-0.8,0.4-1.21,0.6
		c-1.21,0.6-2.47,1.22-3.9,1.93c0.85,0.66,1.54,1.19,2.22,1.73C720.39,639.71,721.35,638.3,722.31,636.9z"
          />
          <path
            className="st4"
            d="M795.2,386.1c-0.65,1.4-1.3,2.8-1.94,4.2C796.86,390.27,795.03,387.72,795.2,386.1z"
          />
          <path
            className="st4"
            d="M1100.79,479.28c0.19,1.02-0.33,2.33-0.18,3.37c0.03,0.23,0.1,0.45,0.21,0.65c0.17,0.3,0.45,0.55,0.89,0.74
		c0.29,0.13,0.66,0.22,1.11,0.28l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
		C1102.15,482.64,1101.47,480.96,1100.79,479.28z"
          />
          <path
            className="st18"
            d="M1174.64,525.95c0.08-2.24,0.16-4.48,0.24-6.73"
          />
          <path
            className="st9"
            d="M840.48,276.55c-0.92,0.24-1.85,0.48-2.77,0.73c0.75,0.72,1.51,1.43,2.26,2.15
		C840.14,278.47,840.31,277.51,840.48,276.55L840.48,276.55z"
          />
          <path
            className="st19"
            d="M524.7,593.16c-0.02-0.01-0.03-0.02-0.05-0.03"
          />
          <line
            className="st19"
            x1="524.65"
            y1="593.13"
            x2="524.65"
            y2="593.13"
          />
          <path
            className="st19"
            d="M520.3,590.08c1.45,1.02,2.9,2.03,4.35,3.05"
          />
          <path
            className="st5"
            d="M348.14,784.4c-1.32-0.92-2.63-1.84-3.95-2.76c-0.31,0.03-0.63,0.06-0.94,0.09
		C344.88,782.62,346.51,783.51,348.14,784.4z"
          />
          <path
            className="st5"
            d="M348.15,784.4c0.06,0.04,0.12,0.08,0.18,0.13l0,0l0,0C348.27,784.49,348.2,784.44,348.15,784.4
		C348.14,784.4,348.14,784.4,348.15,784.4C348.14,784.4,348.15,784.4,348.15,784.4z"
          />
          <path
            className="st20"
            d="M635.65,675.94c1.69-0.81,3.38-1.63,5.07-2.44"
          />
          <path
            className="st12"
            d="M862.97,367.2c-0.33,0.11-0.67,0.21-1,0.32c0.45,1.23,0.9,2.47,1.35,3.7c0.28-0.1,0.56-0.19,0.85-0.29
		C863.77,369.68,863.37,368.44,862.97,367.2z"
          />
          <line
            className="st6"
            x1="727.36"
            y1="634.71"
            x2="727.36"
            y2="634.71"
          />
          <line
            className="st6"
            x1="727.36"
            y1="634.71"
            x2="727.36"
            y2="634.71"
          />
          <path
            className="st6"
            d="M727.36,634.71c-0.79,0.34-1.57,0.68-2.36,1.02"
          />
          <path
            className="st6"
            d="M724.99,635.73c-0.9,0.39-1.79,0.77-2.69,1.16"
          />
          <line className="st6" x1="722.31" y1="636.9" x2="722.31" y2="636.9" />
          <path
            className="st21"
            d="M499.38,372.57c0.08,0.69,0.16,1.39,0.25,2.08"
          />
          <line
            className="st21"
            x1="500.15"
            y1="379.07"
            x2="500.15"
            y2="379.07"
          />
          <path
            className="st21"
            d="M499.63,374.66c0.17,1.47,0.35,2.94,0.52,4.41"
          />
          <line
            className="st21"
            x1="495.13"
            y1="335.37"
            x2="495.13"
            y2="335.37"
          />
          <path
            className="st21"
            d="M494.64,330.32c0.16,1.68,0.33,3.37,0.49,5.05"
          />
          <path
            className="st20"
            d="M1116.48,428.42c-0.98-0.67-1.96-1.35-2.94-2.02"
          />
          <path
            className="st5"
            d="M352.66,853.99c-0.13-0.16-0.26-0.31-0.4-0.47c0.06,0.24,0.11,0.48,0.17,0.71c0.63,0.65,1.25,1.29,1.88,1.94
		c0.19-0.24,0.38-0.49,0.56-0.73C354.14,854.95,353.4,854.47,352.66,853.99L352.66,853.99z"
          />
          <path
            className="st7"
            d="M882.74,398.62c0.75,0.52,1.5,1.04,2.25,1.56c0.19-0.28,0.38-0.56,0.58-0.84c-0.81-0.45-1.62-0.9-2.43-1.35
		C883.01,398.2,882.88,398.41,882.74,398.62z"
          />
          <path className="st20" d="M567.52,738.74c-1.03-0.5-2.06-1-3.09-1.5" />
          <path
            className="st20"
            d="M567.57,738.76c-0.01-0.01-0.03-0.01-0.04-0.02"
          />
          <line
            className="st20"
            x1="567.52"
            y1="738.74"
            x2="567.52"
            y2="738.74"
          />
          <path
            className="st17"
            d="M314.57,510.73c-0.92,0.51-1.85,1.01-2.77,1.52"
          />
          <path
            className="st17"
            d="M314.57,510.73c0.02-0.01,0.03-0.02,0.05-0.03"
          />
          <line
            className="st17"
            x1="314.57"
            y1="510.73"
            x2="314.57"
            y2="510.73"
          />
          <path
            className="st13"
            d="M527.31,511.55c-0.77-0.37-1.54-0.74-2.31-1.1c-0.14,0.26-0.28,0.51-0.42,0.77c0.81,0.35,1.62,0.71,2.43,1.06
		C527.11,512.03,527.21,511.79,527.31,511.55z"
          />
          <path
            className="st6"
            d="M1130.73,399.8c0.72,0.48,1.45,0.97,2.17,1.45"
          />
          <path
            className="st20"
            d="M571.14,741.58c-0.72-0.48-1.45-0.97-2.17-1.45"
          />
          <path
            className="st12"
            d="M425.55,700.26c-0.76-0.52-1.52-1.04-2.28-1.56c0,0,0,0,0,0c-0.19,0.25-0.39,0.5-0.58,0.74l0,0
		c0.28,0.16,0.55,0.32,0.83,0.48c0.48,0.28,0.96,0.55,1.43,0.83C425.15,700.59,425.35,700.43,425.55,700.26z"
          />
          <path
            className="st13"
            d="M508.94,500.69c-1.12-0.59-2.25-1.18-3.37-1.77c0,0,0,0,0,0c-0.51-0.09-1.02-0.18-1.53-0.27
		c-0.03,0.16-0.06,0.32-0.1,0.48c0.41,0.1,0.82,0.21,1.23,0.31l0,0c1.09,0.65,2.17,1.3,3.26,1.94c0,0,0,0,0,0
		c0.66,0.42,1.31,0.84,1.97,1.26c0.25-0.2,0.5-0.41,0.76-0.61l0,0C510.41,501.6,509.68,501.14,508.94,500.69z"
          />
          <path
            className="st18"
            d="M527.9,778.32c-0.89-0.52-1.78-1.04-2.67-1.56"
          />
          <path
            className="st5"
            d="M348.45,784.59c0.81,0.58,1.63,1.17,2.44,1.75c0.12-0.14,0.24-0.29,0.35-0.44
		C350.31,785.46,349.38,785.03,348.45,784.59z"
          />
          <path
            className="st6"
            d="M1135.03,402.67c0.72,0.49,1.44,0.97,2.16,1.46"
          />
          <path
            className="st12"
            d="M398.13,714.48c0.13-0.23,0.25-0.46,0.38-0.7c-0.77-0.46-1.54-0.91-2.31-1.37c-5.46-4.39-5.46-4.39-7.04-1.94
		c2.15,0.83,4.28,1.65,6.41,2.46C396.42,713.45,397.28,713.97,398.13,714.48z"
          />
          <path
            className="st15"
            d="M666.5,443.02c0.23,0.08,0.45,0.15,0.68,0.23c0.25-0.66,0.49-1.32,0.74-1.97c-0.26-0.09-0.53-0.18-0.79-0.27
		C666.91,441.67,666.7,442.34,666.5,443.02z"
          />
          <path
            className="st5"
            d="M297.88,762.94c-0.33-0.27-0.67-0.54-1-0.81l0,0c0,0-0.01,0.01-0.01,0.02c0.01-0.01,0.01-0.02,0.01-0.02
		c-1.41-2.44-2.77-4.91-4.26-7.3c-0.87-1.39-1.7-3.05-2.99-3.89c-4.74-3.07-9.69-5.82-14.51-8.76c-2.69-1.63-5.3-3.4-8.14-5.23
		c0.23,3.37,0.43,6.27,0.61,9.01c2.46,1.38,4.91,2.76,7.35,4.13c7.32,4.11,14.55,8.18,21.79,12.25l0,0c0,0,0.07-0.08,0.11-0.14
		c-0.05,0.06-0.11,0.14-0.11,0.14l0,0c0.66,0.42,1.32,0.84,1.99,1.26c0,0,0,0,0,0C298.43,763.38,298.16,763.16,297.88,762.94z"
          />
          <path
            className="st5"
            d="M298.7,763.6c0.06,0.05,0.12,0.1,0.19,0.15c0,0,0.02-0.02,0.02-0.02C298.84,763.69,298.77,763.64,298.7,763.6z
		"
          />
          <path
            className="st4"
            d="M961.58,373.94c0.28-0.7,0.56-1.39,0.83-2.09c0.62-0.86,1.24-1.71,1.86-2.57c-0.23-0.15-0.46-0.29-0.69-0.44
		c-0.51,0.88-1.03,1.76-1.54,2.65c0,0,0,0,0,0c-0.37,0.72-0.75,1.43-1.12,2.15C961.14,373.74,961.36,373.84,961.58,373.94z"
          />
          <path
            className="st12"
            d="M423.27,698.7L423.27,698.7c-0.6-0.31-1.2-0.62-1.79-0.93c-0.2,0.16-0.41,0.32-0.61,0.48
		c0.61,0.4,1.21,0.8,1.82,1.2l0,0C422.88,699.2,423.07,698.95,423.27,698.7z"
          />
          <path
            className="st6"
            d="M1132.88,401.24c0.72,0.48,1.45,0.97,2.17,1.45"
          />
          <path
            className="st15"
            d="M559.71,478.51c0.09-0.22,0.17-0.44,0.26-0.65c-0.5-0.25-1.01-0.51-1.51-0.76c-0.18,0.26-0.37,0.51-0.55,0.77
		C558.5,478.08,559.11,478.3,559.71,478.51z"
          />
          <path
            className="st20"
            d="M567.49,738.71c0.01,0.01,0.02,0.02,0.03,0.03"
          />
          <line
            className="st20"
            x1="568.99"
            y1="740.15"
            x2="568.99"
            y2="740.15"
          />
          <path
            className="st20"
            d="M568.99,740.15c-0.49-0.47-0.98-0.94-1.47-1.41"
          />
          <line
            className="st20"
            x1="567.52"
            y1="738.74"
            x2="567.52"
            y2="738.74"
          />
          <path
            className="st6"
            d="M1150.79,413.41c0.72,0.48,1.45,0.97,2.17,1.45"
          />
          <path
            className="st12"
            d="M440.78,695.2c0.15-0.28,0.29-0.56,0.44-0.85c-0.61-0.29-1.22-0.59-1.83-0.88v0c-2.5-2.93-5.69-4.61-9.14-5.8
		c-0.16,0.28-0.32,0.57-0.48,0.85c3.04,1.85,6.09,3.7,9.13,5.55C439.53,694.45,440.15,694.82,440.78,695.2z"
          />
          <path
            className="st19"
            d="M524.65,593.13c-0.01,0-0.02-0.01-0.03-0.01"
          />
          <line
            className="st19"
            x1="524.65"
            y1="593.13"
            x2="524.65"
            y2="593.13"
          />
          <path
            className="st19"
            d="M524.65,593.13c0.71,0.26,1.43,0.52,2.14,0.77"
          />
          <line
            className="st20"
            x1="557.51"
            y1="732.98"
            x2="557.51"
            y2="732.99"
          />
          <path
            className="st20"
            d="M558.93,734.27c-0.47-0.43-0.94-0.86-1.42-1.29"
          />
          <line className="st17" x1="922.24" y1="263" x2="922.24" y2="263" />
          <path
            className="st17"
            d="M921.66,260.82c0.19,0.73,0.39,1.45,0.58,2.18"
          />
          <path
            className="st6"
            d="M892.84,260.8c0.01,0.01,0.01,0.01,0.02,0.02"
          />
          <path
            className="st6"
            d="M892.85,260.82c0.49,0.56,0.97,1.13,1.46,1.69"
          />
          <path
            className="st14"
            d="M962.35,310.12c-0.42-0.17-0.83-0.34-1.25-0.52c0.3,0.34,0.6,0.69,0.89,1.03
		C962.12,310.47,962.23,310.3,962.35,310.12z"
          />
          <path
            className="st14"
            d="M961.1,309.61C961.1,309.61,961.1,309.61,961.1,309.61c-0.04-0.05-0.08-0.09-0.12-0.14h0c0,0,0,0,0,0l0,0
		c0,0-0.06,0.07-0.06,0.07C960.98,309.56,961.04,309.58,961.1,309.61z"
          />
          <path
            className="st20"
            d="M958.84,456.33c0,0.01,0.01,0.01,0.01,0.02"
          />
          <path
            className="st20"
            d="M960.13,457.75c-0.43-0.47-0.85-0.94-1.28-1.41"
          />
          <path
            className="st5"
            d="M279.16,811.56c0.45,0,0.91,0,1.36,0c0.01-0.12,0.01-0.24,0.02-0.35c-0.4-0.07-0.8-0.15-1.2-0.22
		C279.28,811.17,279.22,811.36,279.16,811.56z"
          />
          <path
            className="st17"
            d="M419.37,479.89c0.01,0.04,0.03,0.08,0.04,0.11"
          />
          <path
            className="st17"
            d="M419.37,479.89c-0.26-0.72-0.53-1.44-0.79-2.16"
          />
          <path
            className="st5"
            d="M1132,388.91c-0.06,0.21-0.12,0.42-0.19,0.62c0.46,0,0.91,0,1.37,0c0.01-0.13,0.01-0.25,0.02-0.38
		C1132.8,389.07,1132.4,388.99,1132,388.91z"
          />
          <path
            className="st5"
            d="M1134.19,390.4c-0.08,0.2-0.16,0.4-0.24,0.59c0.48,0,0.95,0,1.42,0c0.01-0.11,0.02-0.22,0.02-0.34
		C1134.99,390.57,1134.59,390.48,1134.19,390.4z"
          />
          <path
            className="st15"
            d="M562.2,480.34c0.11-0.22,0.22-0.45,0.33-0.67c-0.61-0.21-1.22-0.42-1.84-0.63c-0.07,0.19-0.13,0.38-0.2,0.57
		C561.07,479.84,561.63,480.09,562.2,480.34z"
          />
          <path
            className="st17"
            d="M359.94,692.07c-0.51-0.53-1.03-1.05-1.54-1.58"
          />
          <path
            className="st17"
            d="M365.25,695.65c-0.62-0.28-1.23-0.56-1.85-0.84"
          />
          <path className="st20" d="M739.51,396.23c0,0-0.01,0-0.01-0.01" />
          <path
            className="st20"
            d="M739.51,396.23c0.47,0.19,0.93,0.38,1.4,0.56"
          />
          <path
            className="st4"
            d="M308.96,393.97c-1.38,0.32-1.74,0.4-2.11,0.49c0.09,0.2,0.19,0.41,0.28,0.61
		C307.43,394.89,307.73,394.71,308.96,393.97z"
          />
          <path
            className="st12"
            d="M420.37,696.96c-0.29,0.32-0.59,0.64-0.88,0.96c0.46,0.11,0.92,0.22,1.38,0.32l0,0
		c0.2-0.16,0.41-0.32,0.61-0.48C421.1,697.5,420.73,697.23,420.37,696.96z"
          />
          <path
            className="st5"
            d="M1075.79,496.1c-0.19,0.28-0.37,0.55-0.56,0.83c-1.42,0.69-2.72,1.62-3.83,2.67
		c-0.55,0.53-1.06,1.08-1.51,1.66c-2.26,2.87-3.13,6.15-1.59,8c1.42-0.6,2.84-1.19,4.33-1.82c0.5-0.21,1-0.42,1.52-0.64
		c0.24-1.52,0.49-3.1,0.75-4.69c0.25-1.6,0.51-3.22,0.77-4.84c0,0,0,0,0,0c0.15-0.35,0.29-0.7,0.44-1.05
		C1075.99,496.17,1075.89,496.13,1075.79,496.1z"
          />
          <path
            className="st5"
            d="M274.91,807.36c0-0.13,0-0.26-0.01-0.4c-0.27,0.03-0.53,0.07-1.82,0.22
		C274.37,807.31,274.64,807.33,274.91,807.36z"
          />
          <path
            className="st15"
            d="M545.72,485.48c-0.12,0.23-0.24,0.46-0.36,0.69c0.43,0.08,0.86,0.17,1.3,0.25l0,0
		C546.34,486.11,546.03,485.8,545.72,485.48z"
          />
          <path
            className="st15"
            d="M546.65,486.43c0.03,0.03,0.07,0.07,0.1,0.1c0,0,0.07-0.07,0.07-0.07
		C546.77,486.45,546.71,486.44,546.65,486.43z"
          />
          <path
            className="st5"
            d="M276.17,809.29c0.39,0.12,0.79,0.25,1.18,0.37c0,0,0,0,0,0c0.34,0.25,0.69,0.5,1.03,0.75
		c0.09-0.15,0.18-0.29,0.28-0.44c-0.35-0.22-0.69-0.44-1.04-0.66c-0.42-0.18-0.85-0.36-1.27-0.54
		C276.28,808.95,276.23,809.12,276.17,809.29z"
          />
          <path
            className="st12"
            d="M412.53,710.03c-0.06,0.19-0.13,0.39-0.19,0.58c0.3,0.13,0.6,0.26,0.91,0.39c0.07-0.19,0.23-0.54,0.2-0.55
		C413.16,710.28,412.84,710.16,412.53,710.03z"
          />
          <path
            className="st12"
            d="M472.63,671.25c-0.27,0.31-0.55,0.63-0.82,0.94c0.44,0.08,0.89,0.16,1.33,0.24l0,0
		c0.26-0.2,0.51-0.39,0.77-0.59C473.48,671.64,473.06,671.44,472.63,671.25z"
          />
          <path
            className="st3"
            d="M997.49,496.52c-0.13,0.14-0.26,0.28-0.39,0.42c0.37,0.34,0.75,0.67,1.12,1.01c0.17,0.14,0.35,0.29,0.52,0.43
		c-0.1-0.24-0.2-0.48-0.3-0.72C998.11,497.27,997.8,496.89,997.49,496.52z"
          />
          <path
            className="st12"
            d="M409.95,713.24c-0.38-0.09-0.75-0.18-1.13-0.27c-0.04,0.14-0.09,0.27-0.13,0.41
		c0.32,0.17,0.64,0.34,0.96,0.51C409.74,713.68,409.84,713.46,409.95,713.24z"
          />
          <path
            className="st4"
            d="M165.52,713.64c-0.06,0.04-0.11,0.08-0.15,0.11c0.01,0,0.01-0.01,0.02-0.01
		C165.43,713.71,165.47,713.68,165.52,713.64c-0.23,0.01-0.41,0.02-0.3,0.02C165.27,713.66,165.36,713.65,165.52,713.64z"
          />
          <path
            className="st4"
            d="M165.25,713.83c0.02-0.01,0.05-0.04,0.12-0.08c-0.02,0.02-0.05,0.03-0.07,0.05
		C165.28,713.81,165.26,713.82,165.25,713.83z"
          />
          <path
            className="st4"
            d="M165.25,713.83c-0.01,0.01-0.02,0.01-0.02,0.01C165.22,713.85,165.23,713.84,165.25,713.83z"
          />
          <path
            className="st21"
            d="M738.09,307.41c-0.02-0.01-0.04-0.02-0.06-0.02"
          />
          <path
            className="st21"
            d="M738.09,307.41c0.44,0.18,0.89,0.36,1.33,0.54"
          />
          <path
            className="st12"
            d="M457.61,306.56c-0.2,0.02-0.56-0.01-0.58,0.07c-0.09,0.31-0.09,0.65-0.12,0.98c0.33-0.2,0.66-0.4,0.99-0.6
		C457.81,306.87,457.71,306.72,457.61,306.56z"
          />
          <path
            className="st6"
            d="M1154.39,415.57c-0.49-0.24-0.97-0.48-1.46-0.73"
          />
          <path
            className="st6"
            d="M1154.39,415.57c0.01,0,0.02,0.01,0.02,0.01"
          />
          <line
            className="st6"
            x1="1154.39"
            y1="415.57"
            x2="1154.39"
            y2="415.57"
          />
          <path
            className="st22"
            d="M597.63,498.62c0.02-0.01,0.04-0.02,0.06-0.03"
          />
          <path
            className="st22"
            d="M597.63,498.62c-0.48,0.24-0.96,0.48-1.44,0.72"
          />
          <line
            className="st22"
            x1="596.19"
            y1="499.34"
            x2="596.19"
            y2="499.34"
          />
          <path
            className="st6"
            d="M1154.39,415.57c-0.01-0.01-0.02-0.01-0.03-0.02"
          />
          <path
            className="st6"
            d="M1154.39,415.57c0.49,0.33,0.98,0.67,1.46,1"
          />
          <line
            className="st6"
            x1="308.96"
            y1="762.28"
            x2="308.97"
            y2="762.28"
          />
          <path
            className="st6"
            d="M308.96,762.28c-0.49-0.23-0.97-0.46-1.46-0.69"
          />
          <path className="st6" d="M889.75,211.41c0,0-0.09,0-0.31,0" />
          <path
            className="st3"
            d="M889.68,211.58c-0.04-0.03-0.11-0.08-0.24-0.17c0,0,0,0,0,0C889.63,211.54,889.77,211.64,889.68,211.58z"
          />
          <path
            className="st3"
            d="M342.55,781c0.07,0.07,0.14,0.14,0.2,0.21c0.17,0.17,0.33,0.35,0.5,0.52c0.32-0.03,0.63-0.06,0.94-0.09
		c-0.37-0.4-0.74-0.79-1.11-1.19C342.9,780.63,342.73,780.81,342.55,781C342.55,781,342.55,781,342.55,781z"
          />
          <line
            className="st23"
            x1="905.75"
            y1="278.03"
            x2="905.74"
            y2="278.02"
          />
          <line
            className="st23"
            x1="905.74"
            y1="278.02"
            x2="905.74"
            y2="278.02"
          />
          <path
            className="st23"
            d="M904.86,276.55c0.29,0.49,0.59,0.98,0.88,1.47"
          />
          <path
            className="st15"
            d="M1039.27,422.5c0.11-0.06,0.21-0.12,0.32-0.17c-0.15-0.3-0.29-0.6-0.44-0.9c-0.12,0.06-0.24,0.12-0.36,0.18
		C1038.94,421.9,1039.11,422.2,1039.27,422.5z"
          />
          <path
            className="st6"
            d="M954.5,424.12c-0.26,0.47-0.52,0.95-0.78,1.42"
          />
          <path className="st6" d="M953.71,425.56c0-0.01,0.01-0.01,0.01-0.02" />
          <line
            className="st6"
            x1="953.72"
            y1="425.54"
            x2="953.72"
            y2="425.54"
          />
          <path
            className="st15"
            d="M562.67,481.01c0.35,0.05,0.7,0.11,1.06,0.16c0.02-0.11,0.05-0.22,0.07-0.33c-0.32-0.14-0.64-0.28-0.96-0.43
		C562.78,480.62,562.73,480.81,562.67,481.01z"
          />
          <path
            className="st5"
            d="M1161.52,424.75c0.41,0.04,0.81,0.09,1.22,0.13c0.03-0.13,0.07-0.26,0.1-0.39c-0.37-0.16-0.74-0.32-1.12-0.48
		C1161.66,424.26,1161.59,424.5,1161.52,424.75z"
          />
          <path
            className="st21"
            d="M430.71,424.79c-0.34,0.34-0.69,0.68-1.03,1.02"
          />
          <line
            className="st21"
            x1="429.68"
            y1="425.81"
            x2="429.68"
            y2="425.81"
          />
          <path
            className="st13"
            d="M704,347.71L704,347.71c-2.46,1.97-4.91,3.95-6.81,5.48c0,6.5,0,12.5,0,19.07c2.41-1.62,4.1-2.76,5.28-3.55
		c0.71-7.15,1.37-13.81,2.03-20.48c0.17-0.38,0.34-0.76,0.52-1.13c-0.12-0.07-0.24-0.13-0.35-0.2
		C704.45,347.18,704.22,347.45,704,347.71z"
          />
          <path
            className="st12"
            d="M1025.79,342.46c-0.36,0.23-0.73,0.46-1.09,0.68c-1.41,0-2.81,0-4.03,0c-6.78,9.54-9.95,20.01-10.9,31.09
		c-0.56,6.55-0.09,13.18-0.11,19.77c0,1.78-0.15,3.57-0.1,5.35c0.05,2.05,0.24,4.09,0.4,6.64c3.67-1.09,6.4-1.9,8.54-2.54
		c-1.09-7.5-2.43-14.39-3.02-21.34c-0.71-8.41,1.86-16.45,3.76-24.55c0.59-2.51,1.19-4.99,2.09-7.35c0.89-2.35,2.08-4.58,3.83-6.58
		l0,0c0.37-0.2,0.73-0.41,1.1-0.61C1026.11,342.84,1025.95,342.65,1025.79,342.46z"
          />
          <path
            className="st24"
            d="M837.69,429.11c0.2-0.47,0.4-0.95,0.6-1.42"
          />
          <path
            className="st4"
            d="M147.18,731.39C147.53,731.14,147.4,731.23,147.18,731.39L147.18,731.39z"
          />
          <path className="st23" d="M713.58,368.27c0-0.49,0-0.98,0-1.47" />
          <path
            className="st18"
            d="M200.08,860.56c-0.39,0.29-0.78,0.59-1.17,0.88"
          />
          <path
            className="st5"
            d="M1054.11,512.16c0.01,0.18,0.1,0.35,0.15,0.53c0.14-0.12,0.28-0.24,0.41-0.36c0,0,0,0,0,0
		c-0.12-0.14-0.23-0.28-0.35-0.42C1054.24,512,1054.1,512.08,1054.11,512.16z"
          />
          <path
            className="st17"
            d="M408.52,434.15c-0.51-0.2-1.01-0.41-1.52-0.61"
          />
          <path
            className="st17"
            d="M406.97,433.52c0.01,0,0.02,0.01,0.03,0.01"
          />
          <path
            className="st3"
            d="M392.72,356.4c0.12,0.09,0.23,0.18,0.35,0.27c0.28-0.26,0.57-0.53,0.85-0.79v0c-0.17-0.19-0.34-0.39-0.51-0.58
		C393.18,355.67,392.95,356.03,392.72,356.4z"
          />
          <path
            className="st3"
            d="M246.92,598.2c-0.37,0.23-0.74,0.46-1.11,0.69c-8.5,3.83-16.95,7.8-25.54,11.42c-3.5,1.47-4.56,3.51-3.33,7.25
		c0.88-0.64,1.59-1.23,2.37-1.71c4.56-2.79,9.02-5.74,13.72-8.26c1.15-0.62,2.35-1.16,3.56-1.69c3.61-1.59,7.26-3.12,9.79-6.49
		c0,0,0,0,0,0c0.27-0.29,0.53-0.57,0.8-0.86C247.1,598.44,247.01,598.32,246.92,598.2z"
          />
          <path
            className="st20"
            d="M71.74,736.64c-0.26-0.51-0.53-1.03-0.79-1.54"
          />
          <path
            className="st4"
            d="M900.76,438.83c0.35,0.1,0.71,0.19,1.06,0.29c0.05-0.14,0.11-0.28,0.16-0.43c-0.3-0.17-0.59-0.33-0.89-0.5
		C900.98,438.4,900.87,438.61,900.76,438.83z"
          />
          <path
            className="st4"
            d="M1084.03,580.77c0.2,0.07,0.4,0.14,0.6,0.2c0.05-0.42,0.11-0.84,0.16-1.26
		C1084.53,580.07,1084.28,580.42,1084.03,580.77z"
          />
          <path
            className="st4"
            d="M1084.81,579.53c-0.01,0.06-0.02,0.12-0.02,0.19c0.03-0.04,0.06-0.09,0.09-0.13c0,0,0,0,0,0
		C1084.87,579.58,1084.82,579.53,1084.81,579.53C1084.81,579.53,1084.81,579.53,1084.81,579.53z"
          />
          <path
            className="st3"
            d="M939.33,223.45c-0.31-0.16-0.62-0.32-0.93-0.48c-0.06,0.11-0.12,0.23-0.18,0.34c0.3,0.16,0.61,0.33,0.91,0.49
		C939.19,223.69,939.26,223.57,939.33,223.45z"
          />
          <path
            className="st18"
            d="M525.22,776.75c-0.47-0.25-0.93-0.5-1.4-0.75"
          />
          <path
            className="st4"
            d="M608.33,472.48c0.01-0.05,0.02-0.12,0.03-0.19v0C608.35,472.37,608.34,472.43,608.33,472.48z"
          />
          <path
            className="st4"
            d="M608.37,472.28c-0.24,0.29-0.24,0.29-0.15,0.18C608.26,472.41,608.31,472.35,608.37,472.28L608.37,472.28
		L608.37,472.28z"
          />
          <path
            className="st4"
            d="M608.33,472.48C608.31,472.61,608.3,472.65,608.33,472.48L608.33,472.48z"
          />
          <path
            className="st4"
            d="M608.37,472.28L608.37,472.28c0.03-0.04,0.06-0.07,0.09-0.11l0,0v0l0,0v0c0.02-0.09,0.04-0.19,0.06-0.28
		c-0.04,0.07-0.08,0.14-0.12,0.21C608.39,472.17,608.38,472.23,608.37,472.28z"
          />
          <path
            className="st4"
            d="M935.69,248.6c-0.07,0.05-0.15,0.1-0.22,0.16c0.06,0.03,0.12,0.07,0.18,0.1
		C935.66,248.77,935.68,248.69,935.69,248.6z"
          />
          <path
            className="st15"
            d="M1015.82,457.02c0.13-0.1,0.25-0.19,0.38-0.29c-0.23-0.38-0.47-0.77-0.7-1.15c-0.2-3.44-1.5-6.19-5.42-8.3
		c0.36,1.2,0.61,2.37,0.9,3.46c0.11,0.44,0.23,0.86,0.37,1.26c0.27,0.81,0.6,1.56,1.08,2.22c0.24,0.33,0.51,0.64,0.82,0.92
		c0.03,0.03,0.07,0.05,0.1,0.08c0.23,0.2,0.49,0.38,0.77,0.55c0.27,0.16,0.56,0.32,0.88,0.46l0,0
		C1015.27,456.49,1015.54,456.76,1015.82,457.02z"
          />
          <path
            className="st5"
            d="M313.56,470.94c0,0.26,0,0.53,0,0.79c0.09,0,0.19-0.01,0.28-0.01c0-0.26,0-0.52,0-0.78
		C313.75,470.94,313.66,470.94,313.56,470.94z"
          />
          <path
            className="st18"
            d="M286.61,538.72c-0.25,0.44-0.5,0.88-0.76,1.32"
          />
          <path
            className="st18"
            d="M942.31,244.3c0.38,0.32,0.75,0.64,1.13,0.96"
          />
          <path
            className="st3"
            d="M1098.43,536.06c0.11,0.33,0.22,0.66,0.33,0.99c0.16-0.06,0.32-0.12,0.48-0.17c-0.13-0.32-0.26-0.65-0.38-0.97
		C1098.72,535.96,1098.58,536.01,1098.43,536.06z"
          />
          <path
            className="st19"
            d="M722.3,359.62c0.01-0.01,0.02-0.01,0.03-0.02"
          />
          <line
            className="st19"
            x1="723.66"
            y1="358.87"
            x2="723.66"
            y2="358.87"
          />
          <path
            className="st19"
            d="M723.66,358.87c-0.44,0.24-0.89,0.49-1.33,0.73"
          />
          <path
            className="st19"
            d="M723.66,358.87c0.03-0.02,0.06-0.03,0.09-0.05"
          />
          <path
            className="st9"
            d="M1019.05,467.81c-0.18-0.18-0.37-0.36-0.55-0.54c-0.1,0.18-0.21,0.35-0.31,0.53
		C1018.48,467.8,1018.77,467.81,1019.05,467.81z"
          />
          <line
            className="st6"
            x1="988.17"
            y1="338.18"
            x2="988.16"
            y2="338.17"
          />
          <line
            className="st6"
            x1="988.16"
            y1="338.17"
            x2="988.16"
            y2="338.17"
          />
          <path
            className="st6"
            d="M988.92,338.76c-0.25-0.19-0.5-0.39-0.75-0.58"
          />
          <path
            className="st4"
            d="M137.42,878.01c-0.14-0.09-0.27-0.19-0.41-0.28c-0.01,0.13-0.02,0.26-0.04,0.4
		C137.13,878.09,137.27,878.05,137.42,878.01z"
          />
          <line
            className="st19"
            x1="722.33"
            y1="359.6"
            x2="722.34"
            y2="359.59"
          />
          <path
            className="st19"
            d="M721.74,360.32c0.2-0.24,0.4-0.48,0.6-0.72"
          />
          <path
            className="st22"
            d="M640.7,735.74c0.24-0.2,0.48-0.41,0.72-0.61"
          />
          <path
            className="st22"
            d="M641.42,735.12c0.01-0.01,0.02-0.02,0.03-0.03"
          />
          <path
            className="st16"
            d="M779.78,233.46c-0.03,0.02-0.08,0.05-0.12,0.08c0,0,0.01,0.01,0.02,0.01c-0.01-0.01-0.02-0.01-0.02-0.01
		c0,0.02,0,0.03,0,0.05c0.02,0,0.04,0,0.06,0C779.73,233.54,779.76,233.49,779.78,233.46z"
          />
          <path
            className="st16"
            d="M779.78,233.46C779.82,233.37,779.81,233.4,779.78,233.46C779.83,233.42,779.86,233.4,779.78,233.46z"
          />
          <path
            className="st12"
            d="M732.6,441.55c0.01,0,0.03,0,0.04,0c4.63-2.77,9.7-3.92,14.9-4.66c3.12-0.44,6.28-0.74,9.42-1.15
		c-0.97-2.94-1.69-5.12-2.42-7.31c-0.4-0.14-0.81-0.27-1.21-0.41c-0.29,0.63-0.58,1.26-0.87,1.89c-0.89,0.5-1.78,1-2.69,1.52
		c-1.31-0.81-2.47-1.53-3.63-2.25c-0.74,0.56-1.48,1.13-2.22,1.69c-0.08,0.24-0.11,0.52-0.2,0.75c-0.03,0.08-0.07,0.15-0.12,0.21
		c-3.21,4.17-7.07,3.97-11.23,1.61c-0.01,2.62-0.02,5.23-0.02,7.85c-0.15,0.05-0.3,0.1-0.45,0.15c-0.06,0.02-0.12,0.04-0.17,0.06
		C732.02,441.52,732.31,441.53,732.6,441.55z"
          />
          <path
            className="st17"
            d="M1051.22,476.31c-0.28,0.27-0.56,0.54-0.85,0.81"
          />
          <line
            className="st25"
            x1="427.9"
            y1="434.19"
            x2="427.89"
            y2="434.19"
          />
          <path
            className="st25"
            d="M427.13,434.76c0.25-0.19,0.51-0.38,0.76-0.57"
          />
          <path
            className="st7"
            d="M824.74,432.48c0.1,0.11,0.19,0.21,0.29,0.32c0.04-0.19,0.09-0.37,0.13-0.55
		C825.02,432.32,824.88,432.4,824.74,432.48z"
          />
          <path
            className="st7"
            d="M825.01,432.85c0.01,0,0.1,0.04,0.1,0.04c-0.03-0.03-0.06-0.06-0.08-0.09
		C825.02,432.82,825.02,432.83,825.01,432.85z"
          />
          <path
            className="st23"
            d="M875.69,267.88c0.22-0.19,0.43-0.37,0.65-0.56"
          />
          <path
            className="st23"
            d="M875.63,267.93c0.02-0.02,0.04-0.03,0.06-0.05"
          />
          <path
            className="st23"
            d="M424.29,381.14c0.19,0.25,0.37,0.5,0.56,0.75"
          />
          <line
            className="st23"
            x1="424.29"
            y1="381.14"
            x2="424.28"
            y2="381.14"
          />
          <path
            className="st15"
            d="M865.31,272.37c-0.07,0.07-0.14,0.14-0.22,0.21c0.1,0.08,0.19,0.16,0.29,0.24c0.04-0.07,0.08-0.14,0.11-0.22
		C865.44,272.52,865.37,272.44,865.31,272.37z"
          />
          <path
            className="st3"
            d="M959.66,482.14c-0.04,0.11-0.12,0.22-0.1,0.32c0.03,0.1,0.06,0.2,0.11,0.3c0.04,0.1,0.09,0.2,0.13,0.3
		C959.75,482.76,959.7,482.45,959.66,482.14z"
          />
          <path
            className="st17"
            d="M521.72,634.06c0.22,0.27,0.44,0.53,0.65,0.8"
          />
          <line className="st17" x1="522.4" y1="634.9" x2="522.4" y2="634.9" />
          <path
            className="st17"
            d="M522.4,634.9c-0.01-0.01-0.02-0.03-0.03-0.04"
          />
          <path
            className="st6"
            d="M727.36,634.71c-0.02,0.01-0.04,0.03-0.05,0.04"
          />
          <path
            className="st6"
            d="M728.03,634.15c-0.22,0.19-0.45,0.37-0.67,0.56"
          />
          <line
            className="st20"
            x1="1102.83"
            y1="484.32"
            x2="1102.83"
            y2="484.32"
          />
          <path
            className="st20"
            d="M1102.83,484.32c0.25,0.18,0.5,0.36,0.75,0.54"
          />
          <path
            className="st17"
            d="M522.32,634.83c0.02,0.01,0.03,0.02,0.05,0.03"
          />
          <path
            className="st17"
            d="M523.15,635.42c-0.26-0.19-0.52-0.37-0.78-0.56"
          />
          <line
            className="st17"
            x1="522.37"
            y1="634.86"
            x2="522.37"
            y2="634.86"
          />
          <path
            className="st17"
            d="M524.58,636.15c-0.29-0.2-0.57-0.41-0.86-0.61"
          />
          <path
            className="st25"
            d="M430.83,417.14c0.22-0.29,0.43-0.58,0.65-0.87"
          />
          <line
            className="st25"
            x1="431.47"
            y1="416.27"
            x2="431.47"
            y2="416.27"
          />
          <path
            className="st25"
            d="M431.47,416.28c0.18-0.25,0.35-0.5,0.53-0.76"
          />
          <path
            className="st4"
            d="M166.63,713.16c8.14-4.97,16.84-9.11,24.21-15.26c-16.86-10.15-33.06-19.91-48.91-29.45
		c-9.35,5.26-18.19,10.23-27.39,15.4c2.92,0.62,5.35,1.14,7.9,1.68c2.59-3.62,4.31-8.15,9.59-9.04c5.31-0.9,9.26,0.65,11.26,6.19
		c1.9-3.02,3.47-5.54,7.1-4.92c2.9,0.5,3.86,2.65,4.55,5.09c-0.98,1.15-1.87,2.19-2.86,3.35c7.1,0.99,11.51,3.31,14.23,7.17
		c3.16,4.48,3.66,8.92,2.47,13.35c-0.22,0.81-0.49,1.61-0.81,2.42c-0.16,0.4-0.33,0.8-0.52,1.21c-0.37,0.8-0.79,1.61-1.25,2.41l0,0
		c-0.16,0.26-0.31,0.51-0.47,0.77C166.04,713.4,166.34,713.28,166.63,713.16z"
          />
          <path
            className="st4"
            d="M165.75,713.52c-0.05,0.02-0.09,0.04-0.14,0.06l0,0c-0.03,0.02-0.06,0.04-0.09,0.06
		c0.05,0,0.11-0.01,0.16-0.01C165.71,713.6,165.73,713.56,165.75,713.52z"
          />
          <path
            className="st18"
            d="M272.38,809.58c0.2,0.27,0.4,0.54,0.59,0.81"
          />
          <path
            className="st15"
            d="M536.24,485.89C536.29,485.93,536.29,485.92,536.24,485.89L536.24,485.89z"
          />
          <path
            className="st21"
            d="M458.62,313.65c-0.36,0.28-0.72,0.56-1.08,0.84"
          />
          <path
            className="st20"
            d="M358.74,403.35c0.34-0.25,0.68-0.49,1.03-0.74"
          />
          <path
            className="st20"
            d="M359.77,402.61c0.01-0.01,0.02-0.01,0.03-0.02"
          />
          <path
            className="st24"
            d="M706.5,400.42c-0.24,0.22-0.47,0.45-0.71,0.67"
          />
          <path
            className="st26"
            d="M760.35,330.34c0.29-0.25,0.58-0.51,0.86-0.76"
          />
          <path
            className="st26"
            d="M698.94,346.26c0.23-0.16,0.46-0.31,0.69-0.47"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch}
              d="M898.26,670.19c-3.6,3.35-5.47,8.55-8.07,12.81c-2.39-1.88-5.13-4.32-8.14-6.35
		c-6.55-4.43-13.75-7.99-21.56-8.7c-8.59-0.78-15.6,3.33-20.55,10.56c-0.61,1.83-1.21,3.66-1.72,5.18
		c-3.31-1.07-6.57-2.13-9.82-3.19c-6.64,0.13-13.27,0.31-18.62,5.14c-5.58,5.9-8.82,13.02-10.88,20.77
		c-3.2,9.58-6.4,19.17-9.59,28.71c-3.1-0.31-5.33-0.9-7.49-0.7c-8.15,0.78-15.62,3.54-21.36,9.55c-11.12,11.64-15.95,26.14-18,41.72
		c-0.74,5.61-0.72,11.32-1.61,16.9c-1.73,10.81-3.84,21.55-3.47,32.59c0.17,5.24,0.02,10.48-0.05,15.72
		c-0.23,17.5-0.49,34.99-0.74,52.49c3.75,3.07,7.49,6.15,11.21,9.2c1.32-1.25,1.98-1.93,2.69-2.55c2.02-1.77,3.03-3.52,0.65-5.89
		c-0.61-0.61-0.49-1.97-0.7-2.98c-0.79-13.52,0.19-21.19,7.66-35.37c5.8,3.39,11.7,6.6,17.3,10.27c1.24,0.81,1.72,2.76,2.42,4.4
		c0.1,0.23,0.2,0.46,0.31,0.68c1.17,1.95,2.38,3.28,3.73,3.97c0.67,0.34,1.38,0.53,2.13,0.55c0.5,0.01,1.02-0.04,1.57-0.17
		c1.36-0.33,2.86-1.11,4.56-2.35c2.89,5.32,6.76,10.25,8.33,15.82c2.86,10.15,0.2,19.96-4.92,29.05c-2.06,3.66-4.68,7.06-6.33,10.88
		c-1.06,2.48-0.82,5.53-1.16,8.32c0.79,2.18,1.67,3.92,2.64,5.21c0.56,0.75,1.16,1.33,1.78,1.77c0.45,0.32,0.91,0.58,1.39,0.74
		c2.29,0.78,4.94-0.24,7.96-3.06c3.61,0.86,6.63,0.12,8.62-3.44c0.34-0.61,1.23-0.98,1.95-1.3c3.42-1.52,5.34-5.4,4.53-9.28
		c-1.36-1.45-2.72-2.9-4.35-4.65c10.82-11.47,20.9-23.6,15.95-40.88c7.14-1.8,14.19-3.44,21.16-5.35
		c11.7-3.19,19.59-11.63,27.36-20.19c4.04-4.45,7.5-9.94,12.45-12.95c2.91-1.77,6.15-2.99,9.49-4.06c3.34-1.07,6.77-1.98,10.05-3.13
		c4.07-1.43,8.16-2.82,12.24-4.23c4.08-1.41,8.15-2.85,12.19-4.37c6.22-2.34,11.3-6.23,15.38-11.63
		c3.99-5.28,8.17-10.53,12.96-15.05c2.73-2.57,6.72-4.03,10.39-5.28c2.62-0.89,5.66-0.46,8.47-0.88c6.32-0.94,11.23,1.65,15.55,5.88
		c0.34,0.33,0.65,0.7,1.02,1c10.08,8.38,9.04,12.74,0.31,24.08c-2.21,2.86-4.87,5.37-7.33,8.04c-0.27,0.41-0.54,0.81-0.81,1.22
		c-0.2,0.47-0.39,0.93-0.59,1.4c0.2-0.47,0.39-0.93,0.59-1.4c-0.2,0.47-0.39,0.93-0.59,1.4c0,0,0.08-0.02,0.08-0.02
		c0.81,2.26,1.25,4.76,2.52,6.71c1.37,2.11,3.76,3.73,6.38,2.16c3.99-2.38,7.73-5.17,12.02-8.1c4.16-0.2,6.03-1.8,7.01-6.49
		c-0.63-1.7-1.26-3.4-1.93-5.21c2.39-3.87,4.94-7.99,7.49-12.11c-0.02-5.46,0.54-11.01-0.21-16.37c-0.87-6.24-4.78-11.22-9.04-15.77
		c-1.81-0.74-3.61-1.47-5.6-2.28c0.48-2.89,0.95-5.7,1.42-8.5c-1.02-5.78-1.8-11.64-7.06-16.37c6.95-2.58,12.66-5.93,14.06-13.28
		c1.3-6.85-2.72-12.84-9.9-15.09c-0.96-5.09-4.26-8.22-9.08-9.74c-3.52-1.11-7.12-2.52-10.73-2.67
		c-10.46-0.44-15.73-7.44-20.32-15.3c-1.42-2.43-2.08-5.41-2.59-8.23c-0.51-2.82,0.12-5.89-0.64-8.6
		c-1.56-5.52-3.63-10.9-5.68-16.27c-0.7-1.83-1.71-3.7-3.06-5.08c-5.12-5.24-10.47-10.22-18.21-11.26
		c-0.24,0.03-0.49,0.13-0.71,0.08C919.37,656.33,907.92,661.2,898.26,670.19z M746.81,854.91c-3.56,15.63-5.04,30.8-4.17,46.2
		c0.18,0.38,0.36,0.75,0.53,1.13c-0.12,0.1-0.24,0.2-0.37,0.29c-0.27-0.25-0.54-0.49-0.81-0.74c0,0,0,0,0,0
		c-0.28-3.44-0.8-6.88-0.87-10.31c-0.01-0.49-0.01-0.98,0-1.47c0.13-5.3,1.08-10.58,1.23-15.88c0.22-7.96,0.17-15.94,0.01-23.9
		c-0.08-4.25-0.61-8.49-0.7-12.83c3.93,8.31,10.5,13.97,18.1,18.49c2.78,1.65,5.96,2.73,9.07,3.72c5.68,1.81,7.72,4.39,7.78,10.58
		C767.23,864.45,758.08,858.27,746.81,854.91z M823.31,906.47c-0.19,1.13-0.5,2.24-0.93,3.32c-0.29,0.72-0.63,1.43-1.03,2.13
		c-0.6,1.05-1.34,2.08-2.21,3.08c-4.74,5.29-9.46,10.59-14.23,15.85c-1.44,1.58-3.15,2.93-4.47,4.6c-0.86,1.09-1.22,2.57-1.67,3.57
		c2.31,1.99,4.12,3.55,6.45,5.57c-1.65,1.54-3.15,2.93-4.89,4.55c-1.08-4.08-2.2-5.51-4.04-5.1c-0.42,0.1-0.89,0.29-1.39,0.57
		c-0.09,0.39-0.02,0.76,0.13,1.13c0.64,1.59,2.78,3.07,0.36,4.9c-0.89-0.01-2.03,0.33-2.61-0.1c-2.93-2.14-3.61-5.26-2.25-9.07
		c8.28-11.85,12.89-24.96,12.77-39.45c-0.03-4.04-1.52-8.07-2.6-13.36c1.96-2.05,4.94-5.17,7.95-8.32c1.9,0.85,3.61,1.83,5.13,2.94
		c0.76,0.56,1.47,1.15,2.14,1.77c0.33,0.31,0.66,0.63,0.97,0.96c0.62,0.66,1.2,1.35,1.73,2.08c0.26,0.36,0.52,0.74,0.76,1.12
		c1.45,2.28,2.5,4.88,3.16,7.8C823.5,900.3,823.82,903.46,823.31,906.47z M796.02,849.11c18.11-7.46,31.45-18.36,31.67-38.97
		c6.67,2.29,14.09,4.8,21.47,7.44c0.22,0.08,0.41,0.2,0.6,0.35c0.14,0.11,0.27,0.25,0.4,0.39c0.38,0.41,0.74,0.89,1.12,1.28
		c0.9-0.01,1.69,0.15,2.38,0.42c0.52,0.21,1,0.49,1.43,0.82c0.44,0.33,0.84,0.71,1.21,1.13c0.75,0.83,1.41,1.79,2.08,2.74
		c0.79,3.07,1.58,6.13,2.37,9.19c0.75,2.71-0.33,7.01-2.22,10.7c-0.84,1.64-1.84,3.15-2.92,4.34c-0.81,0.9-1.65,1.62-2.5,2.07
		c-1.71,0.92-3.31,2.06-5.03,3.15c-0.37-3.48-0.75-7.14-1.14-10.8c-1.41-2.89-2.83-5.78-4.24-8.68c-0.29,0.56-0.79,1.18-1.08,1.74
		c-0.17,0.33-0.27,0.65-0.19,0.91c0.59,1.99,1.59,3.88,2.58,5.73c5.3,9.95-1.68,23.24-12.99,24.55c-3.1,0.36-6.23,0.46-9.42,0.21
		c1.86-2.83,2.99-5.64,3.56-8.54c0.34-1.74,0.49-3.5,0.46-5.32c-0.25-0.67-0.39-1.42-0.56-2.15c-0.03-0.15-0.07-0.29-0.11-0.43
		c-0.08-0.29-0.16-0.56-0.27-0.83c-0.41-1.05-1.12-1.9-2.69-2.19c-0.05,1.87-0.1,3.73-0.16,5.6c0.61,3.93-0.35,7.48-2.41,10.84
		c-0.42,0.69-0.33,1.7-0.48,2.58c-6.77,0.3-11.5-4.14-16.26-9.22C803.43,853.6,799.68,851.46,796.02,849.11z M783.28,838.27
		c-3.74,0-7.47,0-11.24,0c-4.74-15.11-1.53-27.64,8.34-38.13c8.31-8.83,18.44-9.23,29.09-5.3c0.2,0.07,0.37,0.22,0.53,0.4
		c0.11,0.13,0.21,0.29,0.31,0.46c0.06,0.11,0.12,0.22,0.18,0.33c0.17,0.33,0.33,0.69,0.51,1.01c0.03,0.06,0.06,0.11,0.09,0.16
		c0.04,0.07,0.08,0.14,0.12,0.21c0.47,0.47,0.94,0.94,1.41,1.41c0,0,0,0,0,0c0.92,0.22,1.76,0.46,2.54,0.72
		c1.39,0.47,2.54,1.04,3.5,1.75c0.92,0.67,1.66,1.46,2.24,2.42c1.18,1.98,1.69,4.69,1.73,8.5c0.11,12.3-10.41,24.87-22.18,26.76
		c-2.18,0.62-4.35,1.23-6.52,1.53c-1.4,0.2-2.8,0.26-4.18,0.14c-0.13-0.01-0.26-0.03-0.39-0.05c-0.98-0.12-1.96-0.33-2.93-0.69
		c-0.09-0.03-0.17-0.07-0.26-0.11c-0.56-0.22-1.12-0.48-1.67-0.79C784.1,838.8,783.69,838.55,783.28,838.27
		C783.28,838.27,783.28,838.27,783.28,838.27z M766.12,832.48c0.23,0.31,0.46,0.63,0.69,0.94c-0.28,0.26-0.56,0.53-0.84,0.79
		c-0.11-0.42-0.23-0.84-0.34-1.26c0,0,0,0,0,0c-3.77-1.98-5.6-4.88-6.02-8.9c-0.06-0.57-0.09-1.17-0.09-1.79
		c-0.04-7.4,0.71-14.62,2.99-21.64c1.21-3.71,3.08-7.2,4.2-10.93c1.59-5.26,2.74-10.65,4.13-15.97c0.17-0.64,0.65-1.21,1.92-1.92
		c1.37,6.13,2.73,12.25,4.13,18.53c5.28-3.29,3.86-15.78-2.53-23.21c1.91-2.4,3.9-4.89,5.92-7.43c7.73,6.33,4.99,16.15,9.23,23.2
		c5.49-2.81,1.79-19-5.57-24.88c2.27-1.03,4.37-1.98,6.7-3.03c4.34,4.78,6.58,10.26,6.97,16.58c0.15,2.35,0.35,4.73,0.86,7.01
		c0.27,1.19,1.25,2.23,1.91,3.33c0.36-0.04,0.71-0.09,1.07-0.13c0.53-1.57,1.07-3.13,1.54-4.52c1.41,3.75,2.91,7.75,4.5,11.95
		c-6.29-1.77-12.57-2.59-18.73-0.51c-11.14,3.76-17.26,12.22-20.49,23.08C766.26,818.53,765.63,825.45,766.12,832.48z
		 M822.72,703.02c1.24-0.99,2.51-2.01,3.86-3.09c1.77,2.87,3.24,5.27,4.96,8.05c0.3-0.36,0.53-0.71,0.69-1.05
		c1.16-2.42-0.68-4.55-0.6-6.65c0.4-0.05,0.79-0.07,1.16-0.05c2.85,0.08,5,2.03,6.93,5.85c0.5,1,0.99,2.12,1.48,3.38
		c-17.09,10.18-31.45,22.73-37.6,43.08c-0.09-1.5-0.5-3.07-0.21-4.5c3-15.13,7.91-29.48,17.58-42.16c1.01,2.4,1.97,4.66,3.09,7.31
		C826.36,709.23,824.88,706.22,822.72,703.02z M858.19,687.88c4.86-2.13,7.32,2.71,11.24,2.86c0.34-0.76,0.69-1.53,1.49-3.33
		c4.62,4.31,8.82,8.22,13.26,12.36c-10.33,1.73-19.85,3.32-30.05,5.03c0.63-4.7,1.23-9.25,1.84-13.81c0.28-0.08,0.56-0.16,0.83-0.24
		c1.46,2.35,2.93,4.71,4.73,7.62C862.55,693.9,862.51,690.44,858.19,687.88z M858.46,709.81c7.95-1.67,16.08-2.78,24.18-3.43
		c2.2-0.18,4.32-0.04,6.38,0.36c0.82,0.16,1.63,0.37,2.43,0.61c1.78,0.54,3.49,1.29,5.15,2.21c0.35,0.19,0.69,0.39,1.04,0.6
		c1.33,0.8,2.62,1.71,3.86,2.72c3.97,2.07,7.25,4.71,9.9,7.65c0.43,0.47,0.83,0.95,1.23,1.44c0.55,0.68,1.08,1.38,1.57,2.09
		c0.28,0.4,0.54,0.79,0.8,1.2c0.51,0.8,0.99,1.62,1.43,2.44c0.22,0.41,0.43,0.82,0.63,1.24c0.4,0.83,0.77,1.66,1.1,2.49
		c0.33,0.83,0.63,1.67,0.89,2.5c0.53,1.66,0.92,3.32,1.18,4.92c0.6,3.62,0.56,7.01-0.03,9.81c-1.62,0-3.36,0-5.09,0
		c-4.38,3.31-9.32,6.09-13.02,10.03c-10.93,11.64-24.05,19.25-39.35,23.35c-0.45,0.12-0.91,0.18-1.38,0.23
		c-0.47,0.05-0.94,0.08-1.41,0.14l0,0h0c0,0,0,0,0,0c-8.29,2.38-16.59,4.76-24.89,7.14c-2.93,0.84-6.03,1.29-8.73,2.6
		c-2.05,1-3.58,3.07-6.11,5.37c-1.97-2.06-4.96-4.28-6.75-7.22c-5.08-8.38-8.51-17.68-7.69-27.45c0.51-6.07,3.18-12.25,6.05-17.78
		c6.8-13.09,17.95-22.19,30.35-29.55C847.05,712.62,852.86,710.99,858.46,709.81z M906.44,686.73c2.26-2.22,4.35-4.29,6.05-5.96
		c9.48,4.13,9.15,12.89,12.5,20.03c3.5-5.59,1.46-14.38-3.99-20.62c1.77,0,3.31,0,4.61,0c6.98,5,6.11,13.38,8.68,20.42
		c3.17-2.67,3.17-3.34,0.31-16.31c6.26,2.81,10.23,11.94,11.98,28.12c-5.13-5.78-10.96-8.76-18.36-8.17
		c-7.04,0.56-12.91,3.36-17.75,8.54c-3.41-2.43-6.76-4.82-10.51-7.49c1.62-4.91,3.28-9.97,4.98-15.14
		c5.65,3.91,4.3,11.34,8.51,16.32C916.39,699.59,913.79,692.06,906.44,686.73z M933.03,682.7c0.19-0.17,0.39-0.35,0.58-0.52
		c0.32,0.32,0.63,0.64,0.95,0.96c-0.23,0.23-0.46,0.45-0.7,0.68C933.59,683.44,933.31,683.07,933.03,682.7z M981.14,765.8
		c2.37,2.22,4.74,4.45,7.52,7.05c-2.18,1.86-4.89,4.19-7.18,6.15c-0.27-1.02-0.57-2.15-0.88-3.33c-0.21-0.79-0.42-1.6-0.64-2.44
		c-0.11-0.42-0.22-0.84-0.33-1.26c-0.22-0.85-0.45-1.7-0.67-2.56c-1.42-2.03-2.85-4.06-4.27-6.09c-0.37-0.53-0.74-1.05-1.11-1.58h0
		c-2.92-2.16-5.84-4.33-8.76-6.49c-0.21,0.28-0.42,0.57-0.63,0.85c2.29,2.73,4.57,5.45,6.86,8.18c1.67,3.16,3.34,6.32,5.01,9.48
		c2.78,8.07-1.52,17.99-11.38,18.84c0.21-2.27,0.42-4.55,0.63-6.84c0.21-2.29,0.42-4.59,0.63-6.89c-1.21-3.07-2.42-6.14-3.63-9.21
		c-0.02-0.05-0.04-0.11-0.06-0.16l0,0c-1.57-1.4-3.06-2.96-5.01-3.75c-0.2-0.08-0.4-0.15-0.6-0.21c-0.41-0.12-0.84-0.21-1.3-0.26
		c-0.68-0.07-1.42-0.05-2.23,0.1c0.45,0.54,0.89,1.06,1.32,1.58c1.29,1.55,2.52,3.01,3.75,4.48c1.15,2.91,2.3,5.83,3.44,8.74
		c0.3,4.61-0.12,8.99-2.43,13.24c-1.92,3.54-4.76,4.46-7.84,4.67c0.38-5.05,0.76-10.06,1.14-15.06c-0.48-2.59-0.95-5.18-1.43-7.77
		c-1.87-5.12-4.95-9.36-8.65-13.24c-1.23-1.29-2.53-2.55-3.88-3.78c11.72-0.78,20.08-6.39,26.04-17.19
		c4.47,1.57,9.28,2.97,13.8,5.02c1.32,0.6,2.33,2.95,2.56,4.63c0.18,1.34,0.17,2.7,0.11,4.07s-0.19,2.75-0.23,4.12
		C980.89,761.19,981.02,763.5,981.14,765.8z M925.97,785.26c0.21,0.53,0.39,0.98,0.56,1.43c2.18,2.62,4.35,5.23,6.53,7.85
		c4.77,8.03,2.78,21.69-12.19,23.47c-0.22-4.01-0.45-8.04-0.67-12.06c-0.38-1.57-1.07-2.9-1.94-4.08c-0.58-0.79-1.25-1.51-1.96-2.2
		c-1.06-1.03-2.23-1.98-3.37-2.95l0,0c-0.43,0-0.85,0-1.38,0c-0.17,0-0.36,0-0.56,0c-0.07,0.8-0.13,1.46-0.19,2.11
		c1.41,2.06,3.18,3.95,4.18,6.19c0.5,1.12,0.81,2.33,0.78,3.7c-0.01,0.27-0.02,0.55-0.06,0.84c-0.18,2.21-0.24,4.45-0.45,6.66
		c-0.12,1.32-0.3,2.64-0.59,3.92c-0.23,1.04-2.52,1.91-4.02,2.33c-8.16,2.27-16.36,4.37-25.07,6.67c0-2.88,0-6.12,0-9.35
		c-2.37-2.65-4.75-5.31-7.12-7.96c-1.43-0.83-2.87-1.67-4.3-2.5c-0.26,0.26-0.52,0.52-0.78,0.78c0.53,1.03,1.06,2.05,1.59,3.08
		c2.13,3.1,4.25,6.19,6.38,9.29c0.36,2.89,0.73,5.77,1.09,8.66c-0.38-0.18-0.75-0.35-1.13-0.53c-6.52,7.3-12.84,14.79-19.62,21.83
		c-3.68,3.81-8.15,6.86-12.27,10.25c-0.41-0.45-0.81-0.9-1.22-1.36c2.16-2.17,3.98-4.98,6.55-6.4c7.89-4.36,10.27-11.44,10.88-19.64
		c0.09-1.19,0.09-2.38,0.14-3.57v0c0,0,0,0,0,0c-0.74-2.61-1.48-5.22-2.22-7.82c-0.26-0.93-0.53-1.87-0.79-2.8
		c-1.61-2.36-3.54-4.14-5.68-5.53c-0.71-0.46-1.45-0.89-2.21-1.27c-0.76-0.39-1.54-0.73-2.34-1.05s-1.62-0.61-2.45-0.88
		c-1.67-0.54-3.39-0.98-5.15-1.39c-6.01-1.41-11.84-3.59-17.75-5.43c-0.16-0.38-0.32-0.77-0.48-1.15c2.02-1.59,3.8-3.84,6.09-4.66
		c11.82-4.21,23.68-8.36,35.73-11.82c3.05-0.88,6.84,0.82,10.3,1.32c0.32,0.05,0.65,0.01,1.88,0.01c-1.61-1.95-2.81-3.41-4.14-5.02
		c2.41-1.27,5.08-2.27,7.29-3.9c6.44-4.76,12.67-9.8,19.03-14.68c3.07-2.36,6.09-4.86,9.39-6.85c4.49-2.71,9.13-2.86,14.15-0.67
		c7.74,3.37,13.91,8.4,18.6,15.32c0.39,0.57,0.67,1.22,0.92,1.88c0.25,0.66,0.48,1.35,0.75,2c0.4,2.26,0.79,4.51,1.19,6.77
		c0.07,0.4,0.14,0.79,0.21,1.19c0.32,2.26,0.64,4.53,0.95,6.79c-0.26-0.05-0.51-0.1-0.77-0.15c-2.7,4.34-5.41,8.69-7.96,12.78
		c-1.05-4.13-2.08-8.14-3.1-12.14c-3.11-2.63-6.22-5.27-9.33-7.9c-0.65,0.03-1.3,0.06-2.14,0.09
		C925.83,784.9,925.9,785.08,925.97,785.26z M953.72,744.57c-4.23,3.71-9.01,6.6-15.04,6.88c0,0,0,0,0,0
		c-2.21,0.18-4.41,0.36-6.62,0.54c-2.21,0.18-4.43,0.36-6.66,0.54c-0.21-2.64-0.4-4.94-0.57-7.11c-0.11-1.31-0.21-2.57-0.31-3.83
		c-0.37-2.95-0.94-5.81-1.76-8.59c-0.16-0.56-0.33-1.11-0.52-1.66c-0.73-2.2-1.61-4.34-2.67-6.43c-1.59-3.13-3.58-6.14-6.02-9.03
		c2.07-1.44,4.14-2.79,6.28-3.87c0.71-0.36,1.44-0.69,2.17-0.98c2.21-0.87,4.52-1.4,7.02-1.4c0.03,0,0.07,0,0.1,0
		c0.8,0.01,1.62,0.06,2.47,0.18c0.45,0.05,0.89,0.13,1.31,0.21c4.04,0.84,6.86,3.2,9.23,6.1c0.22,0.27,0.44,0.54,0.65,0.82
		c1.1,1.43,2.12,2.97,3.13,4.5c0.38,0.38,0.78,0.84,1.17,1.35c0.87,1.12,1.75,2.51,2.57,4.11c0.72,1.39,1.4,2.93,1.99,4.54
		C953.23,735.75,954.19,740.56,953.72,744.57z M803.56,755.21c-0.4,3.75-0.84,7.88-1.23,11.53c-1.86-3.53-3.94-7.49-6.13-11.65
		c1.28,0.09,2.69,0.24,4.11,0.27C801.45,755.38,802.59,755.26,803.56,755.21z M1016.16,812.83c1.1,8.05-2.09,14.58-7.03,20.45
		c-2.23,2.65-4.93,4.91-7.41,7.34c-0.2-0.05-0.39-0.09-0.59-0.14c-0.53,1.34-1.05,2.69-1.6,4.08c-1.4,0.11-2.68,0.21-4.5,0.36
		c0.66,1.4,1.17,2.49,1.89,4c-1.06,0.8-2.2,1.67-3.78,2.87c-1.18-1.78-2.11-3.18-3.09-4.67c0.69-0.88,1.06-1.77,1.71-2.11
		c9.89-5.16,15.16-13.28,14.89-24.42c-0.07-2.89-2.03-5.88-3.61-8.53c-1.07-1.78-3-3.03-5.2-5.15c2.13-4.31,4.31-8.72,6.84-13.84
		C1011.03,798.97,1015.09,804.96,1016.16,812.83z M1001.98,774.03c0.39,1.36,0.96,3.38,1.5,5.32c-3.07,1.68-5.87,3.08-8.54,4.7
		c-1.93,1.17-2.44,2.78-0.38,5.18c2.55-1.25,5.11-2.49,8.29-4.04c-0.79,1.65-1.36,2.85-1.54,3.24c-4.23,2.27-7.78,4.17-11.45,6.14
		c0.79,1.68,1.21,2.58,1.88,4.02c-3.16,0.6-6.09,1.3-9.07,1.67c-4.56,0.57-9.17,0.71-13.71,1.4c-7.26,1.11-12.19,5.8-16.56,11.31
		c-5.82,7.34-11.44,15.12-20.25,19.08c-6.39,2.88-13.25,4.72-19.94,6.92c-10.47,3.43-21.39,5.79-30.59,12.25
		c-2.36,1.66-4.1,4.2-6.11,6.35c-1.12,1.2-2.22,2.44-3.32,3.66c-2.02,2.14-3.98,4.35-5.97,6.52c-1.99,2.17-4.03,4.3-6.2,6.27
		c-7.29,6.65-16.68,8.4-26,10.08c-2.08,0.37-4.17,0.63-6.58,0.24c1.02-0.56,2.04-1.11,3.06-1.67c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
		c0.13-0.26,0.25-0.51,0.39-0.79c0.14-0.28,0.29-0.58,0.46-0.93c-1.44-0.49-2.74-0.94-4.02-1.37c1.46-1.08,2.94-2.17,4.56-3.36
		c-4.46-2.06-5.19-2-8.75,0.72c0.59,0.86,1.2,1.73,1.86,2.7c-3.68,1.01,0.01,3.66-0.65,5.5c-4.49-2.97-8.86-5.87-13.53-8.96
		c-0.44-2.02-1-4.61-1.44-6.6c5.36,0.99,11,2.73,16.67,2.88c5.99,0.15,12.3,0.13,17.47-4.29c1.23-1.05,3.62-0.6,5.36-1.19
		c7.55-2.59,12.95-7.99,18.04-13.87c4.95-5.7,9.93-11.42,15.42-16.58c2.04-1.91,5.49-2.52,8.42-3.22c7.08-1.7,14.28-2.89,21.31-4.74
		c2.58-0.68,4.83-2.63,7.05-3.9c3.55-0.99,7.09-1.77,10.48-2.98c3.88-1.39,6.35-4.34,7.78-8.21c0.31-0.84,0.91-1.68,1.58-2.27
		c3.12-2.74,6.31-5.4,10.08-8.59c4.77,1.55,9.71,0.57,12.98-5.01c9.97,0.81,12.96-0.41,17.99-11.72c4.47-1.37,9.6-2.32,14.12-4.54
		C997.18,779.84,999.35,776.57,1001.98,774.03z M936.12,666.38c0.55,0.18,1.08,0.42,1.6,0.7c0.78,0.42,1.52,0.93,2.27,1.45
		c0.74,0.52,1.48,1.05,2.24,1.52c2.13,2.3,4.89,4.3,6.52,6.84c0.23,0.36,0.44,0.74,0.62,1.12c3.57,7.62,7.41,15.41,9.06,23.55
		c1.41,6.93,3.49,13.12,7.42,18.81c0.33,0.48,0.68,0.95,1.04,1.41c0.43,0.55,0.87,1.09,1.32,1.63c0.68,0.81,1.36,1.62,2.03,2.44
		c4.31,1.47,8.62,2.95,12.94,4.42c1.91,0.65,3.82,1.3,5.73,1.96c0.48,0.09,0.96,0.18,1.42,0.29c1.1,0.25,2.15,0.55,3.12,0.98
		c0.32,0.14,0.63,0.31,0.94,0.48c0.34,0.19,0.66,0.4,0.97,0.63c1.36,1.04,2.39,2.52,2.93,4.71c0.16,0.65,0.52,1.25,0.92,1.85
		c0.41,0.59,0.87,1.17,1.24,1.77c0.08,0.04,0.16,0.07,0.24,0.1c0.1,0.04,0.2,0.09,0.31,0.13c0.41-0.29,0.81-0.58,1.23-0.88
		c0.42-0.3,0.85-0.6,1.3-0.93c6.79,3.69,7,10.01,0.11,14.51c-0.35-1.24-0.68-2.43-1.01-3.61c-0.98-1.18-1.96-2.35-2.94-3.53
		c-0.94-0.21-1.88-0.43-2.82-0.64c-0.07,0.2-0.13,0.39-0.19,0.59c0.4,0.98,0.65,2.05,1.23,2.91c2.42,3.62,1.7,6.84-0.64,9.49
		c-2.02-2.51-4.18-4.96-6.03-7.62c-0.2-0.29-0.36-0.6-0.5-0.94c-0.41-1-0.59-2.15-0.93-3.2l-0.04-0.04
		c-2.02,2.91-0.9,5.75,0.69,8.19c2.24,3.44,0.95,5.91-1.29,8.34c-3.2,0.24-4.07-1.98-4.42-4.19c-0.32-2.08-0.42-4.38,0.13-6.38
		c2.1-7.6-2.25-12.85-8.25-15.05c-4.26-1.57-8.6-2.95-12.93-4.32c-3.06-0.96-5.33-2.42-6.44-5.79c-0.9-2.74-2.41-5.43-4.22-7.69
		c-3.67-4.58-2.82-10.1-3.42-15.2c-1.14-9.75-3.12-19.03-9.59-26.8c-0.8-0.96-1.73-1.8-2.6-2.7c0,0,0,0,0,0h0
		c0,0-0.04-0.02-0.04-0.02c-0.27-0.2-0.54-0.39-0.81-0.59c0,0,0.01,0,0.01,0c-0.42-0.29-0.84-0.57-1.26-0.86l-0.04-0.04
		c-4.01-0.66-8.01-1.52-12.04-1.93c-9.14-0.93-15.41,3.76-20.37,10.82c-3.39,4.82-5.17,10.18-5.73,16
		c-0.08,0.78-0.43,1.53-0.69,2.43c-5.19-0.49-6.74-4.61-8.88-8.08l-0.12-0.16c-0.33-0.34-0.66-0.69-1-1.03
		c-4.37-4.86-8.67-9.8-15.09-12.11c-2.57-0.24-5.13-0.66-7.7-0.69c-10.25-0.12-11.75,4.24-12.97,11.38
		c-0.71,4.18-0.75,8.47-1.14,13.31c-0.5,0.26-1.46,0.76-2.22,1.15c-2.46-3.28-4.42-6.54-7.01-9.2c-4.37-4.48-14.67-6.62-20.48,1.65
		c-1.42,2.01-2.83,4.03-4.25,6.04c-0.49,1.05-0.89,2.16-1.49,3.15c-6.8,11.1-11.96,22.86-14.28,35.74c-0.31,1.7-0.04,3.51-0.04,5.36
		c-12.8-2.87-25.4,4.82-31.38,18.13c-3.17,7.05-4.42,14.95-6.66,22.43c-0.99,3.32-2.3,6.54-3.46,9.81
		c-0.64,2.81-1.33,5.61-1.98,8.41c-0.66,2.8-1.29,5.61-1.84,8.44c-1.84,9.53-0.33,15.56,8.56,21.76c5.05,3.52,10.41,5.56,16.73,5.92
		c5.22,0.29,8.85,4.14,11.37,8.61c0.61,1.09,1.1,2.25,1.54,3.43c0.45,1.18,0.86,2.38,1.33,3.54c0.25,0.3,0.5,0.61,0.75,0.91
		c1.11-0.39,2.22-0.77,3.34-1.16c-0.53,0.3-1.06,0.6-1.59,0.9c2.99,1.73,5.99,3.46,9.24,5.34c-2.05,2.58-3.11,3.92-3.92,4.94
		c-0.88-0.96-1.63-1.77-2.31-2.5c-0.68-0.74-1.29-1.4-1.9-2.06c-0.96,0.03-1.92,0.07-2.88,0.1c0.02,0.46,0.03,0.92,0.05,1.38
		c0.01,0.23,0.02,0.46,0.02,0.69c0.93,1.3,1.41,2.52,1.4,3.73c0,0.2-0.02,0.4-0.05,0.6c-0.27,1.8-1.66,3.58-4.34,5.48
		c0-2.15,0-3.79,0-5.43c-0.47-0.48-0.93-0.96-1.4-1.44c-0.22-0.05-0.44-0.1-0.67-0.15c-0.99-0.23-1.99-0.45-2.98-0.68
		c0.28,1.21,0.56,2.42,0.84,3.64c1.64,3.5-0.93,4.77-4.14,6.92c-0.88-2.69-2.08-4.93-2.27-7.24c-0.35-4.43,0.1-8.85-2.12-13.06
		c-1.93-3.66-4.85-5.47-8.6-6.7c-3.48-1.13-6.79-2.8-10.17-4.24c-1.96-1.38-3.92-2.76-5.88-4.14c-0.34-0.35-0.68-0.71-1.02-1.06
		c0,0-0.08-0.05-0.08-0.05c-0.48-0.68-0.97-1.35-1.45-2.03c0,0-0.03-0.03-0.03-0.03c-1.61-1.24-2.79-2.7-3.69-4.3
		c-1.5-2.67-2.21-5.73-2.84-8.78c-1.93-9.36-1.01-18.67,0.76-27.98c1-5.3,1.39-10.72,2.07-16.09c1.1-8.66,3.23-17.01,7.41-24.74
		c0.07-0.13,0.4-0.12,0.61-0.18c0,0,0.04-0.04,0.04-0.04c0.8-2.12,1.71-4.15,2.83-6.03c0.74-1.25,1.58-2.43,2.53-3.52
		c0.48-0.55,0.98-1.07,1.52-1.57c1.08-1,2.29-1.89,3.66-2.66l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.28-0.18,0.56-0.36,0.84-0.54l0.03-0.02
		c0.01-0.01,0.02-0.02,0.02-0.02c0.24-0.19,0.47-0.38,0.71-0.57c0.22-0.12,0.43-0.24,0.65-0.36s0.43-0.24,0.65-0.36
		c0.27-0.25,0.54-0.48,0.81-0.71c0.83-0.68,1.7-1.27,2.61-1.77c3.63-2.02,7.77-2.71,11.59-2.43c1.78,0.13,3.13-0.1,4.23-0.59
		c0.37-0.16,0.71-0.36,1.03-0.57c0.56-0.38,1.05-0.84,1.52-1.35c0.14-0.16,0.28-0.32,0.42-0.48c0.45-0.54,0.89-1.12,1.35-1.74
		c1.92-5.53,3.81-11.07,5.73-16.6c1.91-5.53,3.86-11.05,5.89-16.54c1.83-4.94,3.81-10.1,8.63-13.05c3.44-2.11,7.08-3.13,10.86-3.22
		c0.75-0.02,1.51,0,2.28,0.06c0.76,0.06,1.53,0.15,2.31,0.28c0.77,0.13,1.55,0.3,2.33,0.5c1.77,0.58,3.56,1.11,5.29,1.76
		c3.77,1.42,4.69,0.94,5.52-2.94c0.09-0.4,0.44-0.74,0.67-1.1l0,0v0c0-0.33,0.03-0.67,0.09-1c0.24-1.33,0.94-2.66,1.95-3.9
		s2.34-2.38,3.83-3.36c2.6-1.7,5.69-2.88,8.45-3.08c6.72-0.49,12.37,2.3,17.89,5.32c6.38,3.49,11.94,8.13,16.46,13.91h0
		c0.14,0.22,0.28,0.45,0.42,0.67c0.41,0.66,0.81,1.29,1.13,1.8c4.25-2.41,4.41-3.76,1.59-8.01c1.47-2.88,2.98-5.81,4.48-8.74
		c0.34-0.49,0.68-0.99,1.02-1.48c0,0,0,0-0.01,0c0.85-1.01,1.76-1.94,2.69-2.83c1.58-1.5,3.25-2.85,5.01-4
		c0.7-0.46,1.42-0.89,2.15-1.29c1.46-0.8,2.96-1.49,4.49-2.06c1.38-0.51,2.8-0.92,4.23-1.25c0.95-0.22,1.91-0.4,2.88-0.53
		C926.3,664.39,931.3,664.81,936.12,666.38z"
            />
          </a>

          <path
            className="st2"
            d="M1088.27,951.98c2.16-8.09,4.8-16.15,3.73-24.68c-0.35-2.81-0.97-6.12-2.71-8.11
		c-3.14-3.6-7.21-6.38-10.96-9.56c3.41-6.34,7.66-12.89,6.73-21.57c-1.04-9.72-8.09-19.08-17.71-21.09
		c-12.76-2.67-25.75-2.99-38.09,1.27c-14.4,4.97-28.76,10.42-42.39,17.17c-13.14,6.51-25.33,14.93-37.92,22.53
		c-5.72,3.46-11.22,7.35-17.16,10.38c-6.09,3.11-12.63,5.3-18.86,8.14c-1.59,0.73-2.74,2.41-4.54,4.07
		c2.36,1.08,3.77,1.72,5.45,2.49c-2.9,3.4-5.77,6.42-8.25,9.74c-7.99,10.66-13.67,22.11-11.96,36.04c0.6,4.89,2.51,8.79,7.3,11.17
		c-4.06,6.49-2.4,12.47,1.51,18.37c3.13,4.73,7.78,7.36,12.6,9.85c0.73,0.56,1.56,1.03,2.18,1.69c2.04,2.15,2.08,5.55-0.1,7.2
		c-2.38,1.81-4.31,0.49-6.52-1.75c1.57,0.31,2.55,0.49,3.53,0.68c-0.01-1.15,0.41-2.62-0.15-3.34c-0.58-0.75-2.1-0.76-3.22-1.1
		c-0.97,0.73-1.94,1.46-2.91,2.19c-1.93,4.66-1.06,7.78,3.06,10.72c4.95-0.86,9.53-2.19,11.7-7.65c1.26-3.16,1.6-5.87-1.06-8.45
		c-0.49-0.47-0.79-1.14-1.11-1.79c-0.11-0.22-0.21-0.43-0.33-0.64c-1.13-0.95-2.26-1.89-3.38-2.84c-1.13-0.95-2.25-1.9-3.37-2.86
		c-5.34-4.63-5.93-9.11-2.7-15.43c13.2-1.17,25.87-2.3,38.38-3.41c-2.99,7.35,0.21,13.48,4.86,19.26c0.66,0.82,1.37,1.6,2.06,2.4
		c3.42,2.95,6.6,5.97,5.98,11.15c-0.1,0.83,1.06,1.74,1.16,2.67c0.31,2.99-0.23,5.72-3.38,7.12c-2.22,0.99-4.46,1.95-7.08,3.09
		c-0.17-1.91-0.3-3.28-0.42-4.66c-3.27-2.28-4.57-1.86-5.78,1.87c-1.42,4.38-0.57,6.33,3.61,8.45c0.39,0.2,0.63,0.69,0.94,1.04
		c0.69,0.13,1.4,0.38,2.09,0.56c0.7,0.18,1.38,0.29,2,0.15c0.95-0.21,1.91-0.52,2.86-0.9c0.95-0.38,1.88-0.84,2.79-1.36
		c0.45-0.26,0.9-0.54,1.34-0.84c0.88-0.59,1.72-1.25,2.5-1.96c0.79-0.71,1.52-1.47,2.18-2.28c0.54-0.66,1.02-1.35,1.45-2.06
		c0.83-1.36,1.46-2.79,1.82-4.29c0.05-0.21,0.1-0.42,0.14-0.64c0.17-0.89,0.25-1.79,0.21-2.7c0,0,0,0,0,0
		c0.09-6.57-2.08-12.33-5.73-17.76c-0.68-1.01-1.08-2.22-1.39-3.48c-0.32-1.26-0.56-2.57-0.93-3.79c-0.11-1.21-0.26-2.42-0.39-3.62
		c-0.13-1.21-0.22-2.42-0.21-3.62c0.02-2.31,0.47-4.63,0.72-6.89c3.56-1.27,6.96-2.48,10.66-3.8c2.58,3.35,1.66,6.97,0.66,10.61
		c-0.34,3.15-0.75,6.29-0.96,9.44c-0.07,1.06,0.02,2.73,0.64,3.1c1.01,0.6,2.73,0.84,3.78,0.38c3.12-1.38,6.02-3.23,9.06-4.78
		c12.49-6.37,25.19-12.35,37.44-19.15c3.43-1.91,6.78-3.96,10.09-6.08c5.52-3.53,10.93-7.26,16.44-10.8
		c2.24-1.42,4.46-2.89,6.69-4.33c1.44-0.93,2.89-1.86,4.36-2.76c1.24-0.76,2.5-1.5,3.77-2.2c4.52-2.5,5.04-6.12,4.8-10.73
		c-0.36-7.05-0.09-14.14-0.09-21.88c2.81,1.13,6.28,1.42,7.17,3.09c2.31,4.33,4.6,9.22,4.83,13.99c0.27,5.44-1.67,11-2.68,16.49
		c-0.17,0.91-0.37,1.82-0.57,2.72s-0.42,1.81-0.62,2.71c0.67,1.39,1.35,2.78,2.01,4.15c0.71,0.05,1.36,0.1,1.98,0.15
		c0.57,0.04,1.12,0.08,1.67,0.13v0c0,0,0,0,0,0h0c2.35-0.21,4.94-1,7.19-2.38c2.89-1.77,5.21-4.52,5.74-8.29
		c-3.07-0.37-6.13-0.75-9.32-1.13C1087.45,955.56,1087.8,953.75,1088.27,951.98z M932.24,922.71c6.08-2.48,12.16-4.96,17.03-6.95
		c5.74,4.46,10.55,8.31,15.49,12c2.8,2.1,5.62,4.16,8.45,6.21s5.67,4.09,8.49,6.15c0.9,0.58,1.8,1.16,2.73,1.77
		c1.55,1.01,3.19,2.07,5.08,3.3c-0.91,0.32-1.78,0.62-2.64,0.92c-2.56,0.89-4.91,1.72-7.27,2.54c-5.95,1.91-11.91,3.83-17.86,5.74
		c-1.12,0.55-2.25,1.1-3.42,1.67c-1.96,0.96-4.08,2-6.62,3.23c-5.2-4.88-10.78-10.24-16.51-15.44
		c-5.65-5.12-11.45-10.07-17.73-15.58C922.09,926.54,927.16,924.63,932.24,922.71z M973.91,927.65c3.33,1.95,6.66,3.89,9.99,5.84
		c0.51,0.23,1.02,0.46,1.53,0.69c-0.01-0.02-0.02-0.04-0.03-0.06c0,0,0,0,0,0l0,0c1.48,0.24,2.49,1.03,3.34,2.01
		c0.43,0.49,0.81,1.02,1.2,1.55c0.91,1.24,1.82,2.47,3.25,3.1c0.51,0.39,1.02,0.78,1.53,1.17c-0.06,0.31-0.11,0.63-0.17,0.94
		c-1.12-0.1-2.52,0.19-3.33-0.36c-3.94-2.73-7.78-5.62-11.54-8.58c-2.19-1.72-4.18-3.69-6.26-5.55
		C973.58,928.16,973.75,927.9,973.91,927.65z M972.81,928.1c-2.19-1.54-4.39-3.09-6.58-4.63c0.22-0.32,0.43-0.64,0.65-0.96
		c2.26,1.53,4.53,3.05,6.79,4.58C973.39,927.43,973.1,927.76,972.81,928.1z M973.86,922.09c-2.26-1.46-4.53-2.92-6.81-4.4
		c-2.29-1.48-4.6-2.97-6.95-4.49c3.71-0.63,6.96-1.18,10.21-1.74c4.59-1.04,9.19-2.08,13.61-3.08c4.42,7.43,9.04,15.18,13.65,22.92
		c2.35,3.16,4.69,6.33,7.15,9.64c-2.4,0.95-4.23,1.67-5.38,2.13c-1.14-2.05-2.21-3.96-3.28-5.88
		C988.66,932.16,981.26,927.13,973.86,922.09z M965.92,921.74c-0.11,0.26-0.23,0.52-0.34,0.79c-1.07-0.67-2.14-1.34-3.2-2.01
		c0.12-0.21,0.24-0.43,0.36-0.64C963.8,920.5,964.86,921.12,965.92,921.74z M901.53,961.57c3.38-8.67,8.74-16.15,15.19-22.86
		c0.72-0.75,1.27-1.68,2.23-2.96c1.3,1.02,2.71,1.94,3.9,3.08c6.81,6.5,13.53,13.09,20.36,19.56c1.35,1.28,3.09,2.14,4.57,3.3
		c2.97,2.34,5.74,2.55,8.45-0.9c4.71,2.53,9.16,4.92,14.78,7.94c-9,2.93-17.04,5.78-25.21,8.15c-10.39,3.01-20.92,5.52-31.85,5.34
		c-3.77-0.06-7.56-0.57-10.51-3.37C897.39,973.12,897.58,971.7,901.53,961.57z M899.54,981.25c0.12-0.04,0.24-0.08,0.35-0.12
		c0.11,0.28,0.23,0.55,0.34,0.83c-0.13,0.05-0.27,0.1-0.4,0.14C899.73,981.82,899.63,981.53,899.54,981.25z M902.85,1002.65
		c0-3.64,0-7.28,0-11.13C905.73,992.47,905.73,992.47,902.85,1002.65z M957.76,1007.86c-0.27-0.25-0.54-0.5-0.81-0.74c0,0,0,0,0,0
		c-1.92-3.27-3.84-6.55-6.07-10.36c2-0.49,3.19-0.78,4.77-1.17c0.68,3.8,1.31,7.33,1.94,10.85c0.18,0.37,0.36,0.75,0.55,1.12
		C958.01,1007.66,957.88,1007.76,957.76,1007.86z M971.11,981.38c-8.68,2.23-17.07,4.96-25.67,6.43
		c-8.47,1.45-17.15,2.06-25.75,2.19c-6.35,0.1-12.58-1.59-17.88-5.56c-0.4-0.3-0.67-0.79-1.59-1.9c12.19,6.09,23.61,2.94,34.73-0.06
		c12.06-3.26,23.83-7.63,36.16-11.65C971.11,973.86,971.11,977.22,971.11,981.38z M1064.02,917.05c0,11.55,0,23.11,0,34.65
		c-25.89,20.87-57.43,33.21-86.58,50.97c0-6.29-0.93-12.04,0.25-17.32c1.34-5.98,0.22-11.59,0.05-17.37
		c-0.02-0.76,0.42-1.54,0.86-3.04c-3.52-2.18-7.07-4.39-11.46-7.11c8.25-3.34,15.79-6.39,23.33-9.44c0.02,0.18,0.04,0.35,0.06,0.53
		c2.79-0.38,5.58-0.76,8.37-1.14l0,0c0.49-0.2,0.98-0.41,1.47-0.61c0.71-0.26,1.42-0.52,2.13-0.78c2.19-1.17,4.39-2.33,6.58-3.5
		c-3.63-5.82-7.36-11.59-10.88-17.48c-4.17-6.97-8.16-14.04-11.97-20.62c-5.68,0.76-11.33,1.47-16.97,2.28
		c-5.31,0.77-10.09,4.96-15.97,2.52c6.96-4.39,13.62-9.19,20.82-12.96c10.5-5.51,21.55-9.95,32.03-15.49
		c5.48-2.9,9.6-0.29,15.09,1.59c-6.84,1.65-11.55,4.75-14.72,10.45c-3.21,5.77-3.43,11.28-0.97,16.58c-2.13,2.5-4.44,4.53-5.86,7.05
		c-1.87,3.31-0.61,6.49,2.55,8.48c6.79,4.28,14.29,3.43,21.71,2.94c2.43-0.16,4.84-0.66,7.27-1.01c4.16-1.9,8.43-3.6,12.41-5.82
		c1.31-0.73,1.85-2.83,2.74-4.31c0.02-4.61-2.29-7.95-6.12-10.43c3.32-2.11,6.35-4.04,9.89-6.29c2.33,1.44,5.35,3.31,8.37,5.18
		C1063.09,907.08,1064.4,909.79,1064.02,917.05z M1039.24,892.24c-0.26-0.18-1.96,1.68-2.99,2.6c0.56,0.45,1.46,0.81,1.62,1.38
		c0.36,1.31,0.36,2.72,0.44,3.52c-1.24,1.87-3.31,3.57-3.36,5.32c-0.17,6-4.01,8.16-8.77,9.75c-9.39,2.33-18.48-5.96-18.39-16.76
		c7.69,2.43,15.05,1.78,21.99-2.38c1.41-1.48,2.82-2.97,4.24-4.45c0-1.29,0-2.59,0-3.88c1.49,3.14,3.68,3.77,7.05,3.37
		c3.35-0.4,3.82,2.98,4.36,5.39c0.5,2.19-0.04,4.24-3.44,4.56c0.84-1.15,1.45-1.99,2.62-3.6
		C1042.87,895.42,1041.2,893.63,1039.24,892.24z M1012.38,919.75c-2.17-0.59-5.36-1.41-4.3-5.69c4.3,3.62,8.83,5.26,14.01,5.19
		c5.14-0.06,9.5-2.12,13.65-4.85c0.28-0.27,0.57-0.53,0.85-0.8c0.12,0.09,0.23,0.18,0.35,0.26c-0.23,0.37-0.47,0.74-0.7,1.12
		c-0.77,0.95-1.34,2.32-2.33,2.77C1027,920.84,1019.73,921.74,1012.38,919.75z M1012.89,924.68c-3.26-1.26-6.39-2.47-9.6-3.71
		c0-1.88,0-3.06,0-3.37C1006.15,919.71,1009.38,922.1,1012.89,924.68z M1019.73,890.03c-3.48,0.98-7.74,1.24-9.82,6.46
		c-0.49-4.17,2.84-8.4,6.33-9.49c7.95-2.49,13.03-1.98,15.06,1.9C1027.33,889.23,1023.36,889.02,1019.73,890.03z M1064.12,902.03
		c-4.68-1.8-9.37-3.59-13.8-5.29c-2.15-9.19-4.36-11-11.02-9.1c-2.1-3.78-4.59-7.22-8.59-4.88c-5.47-2.09-10.1-3.86-14.67-5.61
		c0.27-0.1,0.63-0.22,1.03-0.36c0.4-0.14,0.84-0.3,1.28-0.46c2.84-1.31,5.72-2.45,8.64-3.39c1.17-0.38,2.35-0.72,3.53-1.03
		c1.18-0.31,2.38-0.59,3.58-0.83c4.2-0.85,8.48-1.28,12.86-1.22c6.57,0.1,13.27,0.2,19.66,1.52c10.33,2.14,16.61,17.1,12.47,23.96
		c-2.58,4.28-6.06,8.02-9.22,12.11C1067.64,905.35,1065.88,903.69,1064.12,902.03z M1083.36,932.82c-1.12-4.03-1.86-8.65-7.12-10.39
		c0.57-0.49,1.07-0.92,1.55-1.33c0.47-0.41,0.91-0.79,1.35-1.17c0.16-0.48,0.32-0.96,0.5-1.47c0.17-0.51,0.36-1.05,0.56-1.66
		c4.9,2.61,7.86,6.13,7.7,11.73c1.11,6.08-0.6,11.72-2.42,17.31C1084.81,941.45,1084.53,937.01,1083.36,932.82z"
          />
          <path
            className="st5"
            d="M987.37,845.5c0.27-0.41,0.54-0.81,0.81-1.22c0.58-2.23,1.17-4.46,2-7.65c-2.74,0-4.94-0.58-6.64,0.11
		c-5.7,2.29-11.51,4.56-16.73,7.73c-4.89,2.97-9.9,2.34-15,2.4c-3.74,0.04-7.49-0.06-11.23,0.09c-10.42,0.4-20.84,0.92-31.26,1.27
		c-0.47,0.02-0.96-0.37-1.45-0.8c-0.29-0.25-0.58-0.52-0.88-0.72h0l0,0c-3.29,1.15-6.72,2.06-10.05,3.13
		c-3.34,1.07-6.58,2.29-9.49,4.06c-4.95,3.01-8.41,8.49-12.45,12.95c-7.78,8.57-15.67,17-27.36,20.19
		c-6.97,1.91-14.02,3.55-21.16,5.35c4.95,17.28-5.13,29.4-15.95,40.88c1.63,1.75,2.99,3.2,4.35,4.65
		c7.04-3.43,14.07-6.88,21.13-10.27c9.95-4.78,20-9.37,29.88-14.3c10.7-5.35,21.41-10.71,31.83-16.58
		c13.62-7.67,26.86-16.03,40.45-23.75c13.09-7.44,26.41-14.49,39.66-21.65c2.95-1.6,6.02-2.97,9.03-4.45c0,0-0.08,0.02-0.08,0.02
		C986.98,846.43,987.18,845.97,987.37,845.5z"
          />
          <path
            className="st5"
            d="M787.19,938.92c1.64-3.83,4.26-7.22,6.33-10.88c5.12-9.09,7.78-18.9,4.92-29.05
		c-1.57-5.57-5.44-10.5-8.33-15.82c-1.7,1.25-3.2,2.03-4.56,2.35c-0.54,0.13-1.06,0.19-1.57,0.17c-0.75-0.02-1.46-0.21-2.13-0.55
		c-1.35-0.69-2.56-2.02-3.73-3.97c-6.44,4.72-12.87,9.45-19.33,14.14c-2.75,2-5.57,3.92-8.36,5.88c0.21,1.01,0.09,2.37,0.7,2.98
		c2.38,2.37,1.37,4.12-0.65,5.89c-0.71,0.62-1.37,1.3-2.69,2.55c-3.72-3.05-7.47-6.13-11.21-9.2c-6.91,5.17-14.01,10.1-20.66,15.58
		c-4.44,3.67-4.73,8.1-1.93,13.08c1.2,2.14,2.46,4.26,3.42,5.91c-0.21,2.04-0.99,3.93-0.41,5.2c1.69,3.73,3.37,7.72,6.06,10.7
		c6.46,7.16,15.4,6.67,24.09,6.67c3.33,0,6.65,0.53,9.98,0.69c1.25,0.06,2.62,0.03,3.77-0.4c5.49-2.1,10.91-4.38,16.38-6.54
		c2.06-0.82,4.08-1.67,5.73-2.98c0.37-0.29,0.71-0.61,1.04-0.95c0.16-0.17,0.32-0.35,0.47-0.53c0.61-0.74,1.12-1.59,1.51-2.61v0
		C786.37,944.45,786.13,941.4,787.19,938.92z"
          />
          <path
            className="st5"
            d="M1083.53,968.76c-0.55-0.04-1.1-0.08-1.67-0.13c-0.62-0.05-1.27-0.1-1.98-0.15c-0.66-1.37-1.33-2.76-2.01-4.15
		c-3.68,2.13-7.37,4.26-11.28,6.53c0.17-2.03,0.3-3.63,0.43-5.14c-3.87-3.28-7.71-2.05-11.55-0.62c-0.88,0.33-1.76,0.68-2.63,1.04
		s-1.74,0.72-2.61,1.08c0,0,0,0,0,0c0,0,0,0,0,0c-5.51,3.54-10.92,7.26-16.44,10.8c-3.31,2.12-6.66,4.17-10.09,6.08
		c-12.24,6.81-24.95,12.78-37.44,19.15c-3.04,1.55-5.94,3.41-9.06,4.78c-1.05,0.46-2.77,0.22-3.78-0.38
		c-0.62-0.37-0.71-2.04-0.64-3.1c0.21-3.15,0.63-6.3,0.96-9.44c-3.81,2.44-7.63,4.89-11.44,7.33c0.37,1.22,0.61,2.53,0.93,3.79
		c0.32,1.26,0.71,2.47,1.39,3.48c3.65,5.44,5.82,11.19,5.73,17.76c1.92-0.65,3.89-1.19,5.79-1.89c0.63-0.23,1.26-0.49,1.87-0.77
		c3.32-1.52,6.48-3.4,9.43-4.98c1.48,1.06,2.62,1.87,3.69,2.64c11.16-6.02,22.2-11.78,33.05-17.88
		c15.63-8.79,31.17-17.74,46.63-26.83c0.56-0.33,1.11-0.67,1.65-1.02c1.09-0.7,2.16-1.43,3.21-2.19c1.58-1.13,3.14-2.31,4.7-3.49
		C1081.43,970.29,1082.48,969.51,1083.53,968.76L1083.53,968.76z"
          />
          <path
            className="st5"
            d="M950.04,1042.76c-4.18-2.12-5.03-4.07-3.61-8.45c1.21-3.73,2.52-4.15,5.78-1.87c0.13,1.38,0.25,2.75,0.42,4.66
		c2.62-1.14,4.85-2.1,7.08-3.09c3.15-1.4,3.69-4.12,3.38-7.12c-0.1-0.93-1.26-1.84-1.16-2.67c0.62-5.18-2.56-8.21-5.98-11.15
		c-4.73-0.07-5.69-1.15-7.84-8.98c-3.64-0.47-7.27-0.87-10.78,1.35c-5.69,3.59-11.48,7.02-17.23,10.52c0.12,0.2,0.23,0.42,0.33,0.64
		c0.32,0.65,0.62,1.32,1.11,1.79c2.66,2.57,2.31,5.29,1.06,8.45c-2.17,5.47-6.75,6.8-11.7,7.65c-4.12-2.94-4.99-6.06-3.06-10.72
		c-5.8,3.83-11.69,7.53-17.33,11.58c-1.26,0.9-2.26,3.02-2.3,4.61c-0.13,4.74,5.3,10.92,10.16,11.83
		c9.94,1.87,19.87,0.61,29.59-1.24c3.91-0.74,7.75-1.85,11.57-3.06c3.82-1.21,7.62-2.52,11.46-3.67
		C950.68,1043.45,950.44,1042.95,950.04,1042.76z"
          />
          <path
            className="st2"
            d="M1169.11,631.22c-3.13-3.19-6.44-6.21-9.44-9.52c-4.84-5.34-9.54-10.8-14.19-16.31
		c-2.25-2.67-6.44-4.17-8.99-3.23c-11.61,4.29-23.26,8.47-35.3,12.85c-0.45,1.88-1.04,4.34-1.6,6.69c0.85,1.05,1.67,2.05,2.48,3.05
		c9.43,10.02,18.85,20.04,28.31,30.04c0.96,1.01,2.1,1.86,2.86,2.53c1.34-0.75,2.25-1.42,3.27-1.8c9.06-3.35,18.15-6.65,27.23-9.97
		c1.53-0.81,3.06-2.58,4.25-4.63c1.53-2.64,2.49-5.76,2.14-7.94C1170.03,632.35,1169.57,631.69,1169.11,631.22z M1130.98,608.21
		c4.49,1.45,8.57,1.88,9.22-4.8c1.52,1.72,2.51,2.83,4.03,4.56c-4.76,1.66-9.14,3.19-13.77,4.81
		C1130.66,610.97,1130.8,609.73,1130.98,608.21z M1114.42,613.93c2.31,0.47,4.64,0.94,6.97,1.41c0.39-1.44,0.78-2.87,1.18-4.37
		c2.05,0.62,4.27,1.28,6.53,1.96c-2.54,1.04-5.08,2.08-7.62,3.12c-2.32,0.64-4.64,1.27-7.06,1.93
		C1114.42,616.56,1114.42,615.07,1114.42,613.93z M1105.14,616.51c0.15-0.35,0.3-0.69,0.45-1.04c2.55,1.07,5.09,2.14,7.99,3.35
		c-1.33,0.76-2.68,1.54-4.15,2.38C1107.82,619.44,1106.48,617.97,1105.14,616.51z M1112.78,628.89c-0.14-0.3-0.29-0.6-0.43-0.9
		c0.11-0.06,0.22-0.11,0.34-0.17c0.15,0.3,0.3,0.59,0.45,0.89C1113.01,628.78,1112.89,628.83,1112.78,628.89z M1117.44,633.77
		c-1.01-0.97-2.02-1.93-3.04-2.9l0,0c-0.31-0.38-0.61-0.76-0.92-1.14c0.12-0.13,0.24-0.26,0.35-0.38c0.43,0.33,0.85,0.67,1.28,1
		c0.97,0.92,1.93,1.84,2.9,2.76C1117.83,633.33,1117.64,633.55,1117.44,633.77z M1133.2,651.45c-4.79-5.22-9.51-10.37-14.24-15.52
		c0.34-0.32,0.68-0.64,1.02-0.96c4.78,5.06,9.56,10.12,14.3,15.23C1134.31,650.24,1133.7,650.87,1133.2,651.45z M1133.13,645.85
		c-2.86-3.05-6.22-6.63-9.57-10.21c-0.46-0.29-0.91-0.59-1.37-0.88c-0.23-0.24-0.47-0.47-0.7-0.71c-2.42-2.62-4.83-5.24-7.25-7.86
		c-0.14-0.13-0.28-0.25-0.42-0.38c-0.24-0.22-0.49-0.45-0.76-0.69c-0.7-0.63-1.5-1.36-2.59-2.35c3.51-1.21,6.7-2.3,9.76-3.36
		c1.23-0.42,2.43-0.84,3.63-1.25c0.6-0.21,1.2-0.41,1.8-0.62c0,0,0,0,0,0c0,0,0,0,0,0c4.07-1.44,8.14-2.88,12.22-4.32
		c2.52-1.1,5.05-2.21,7.59-3.32c1.47,1.79,3,3.67,4.54,5.54c1.01,1.18,2.03,2.35,3.04,3.53c3.05,3.36,6.1,6.72,9.14,10.08l0,0
		c0.34,0.43,0.68,0.86,1.02,1.3c1.03,1.31,2.12,2.7,3.42,4.36c-4.73,1.46-9.18,2.83-13.63,4.2c0,0,0,0,0,0c0,0,0,0,0,0
		c-1.61,0.56-3.21,1.12-4.83,1.68C1143.33,642.3,1138.4,644.02,1133.13,645.85z M1138.37,648.85c-0.18-0.54-0.36-1.07-0.54-1.61
		c4.65-2.94,10.39-2.62,15.28-4.81c0.13,0.34,0.26,0.68,0.39,1.01C1148.46,645.25,1143.42,647.05,1138.37,648.85z"
          />
          <path
            className="st5"
            d="M1136.51,655.51c-1.02,0.38-1.94,1.05-3.27,1.8c-0.77-0.67-1.9-1.51-2.86-2.53
		c-9.45-10-18.87-20.02-28.31-30.04c-0.65,0.11-1.3,0.23-2.63,0.46c3.77,4.18,7.11,8,10.58,11.7c6.75,7.19,13.59,14.28,20.36,21.45
		c3.52,3.74,7.16,4.01,11.64,1.41c6.51-3.79,13.35-7.01,19.93-10.69c0.36-0.2,0.62-0.58,0.84-1.03c0.36-0.76,0.6-1.73,0.96-2.49
		C1154.66,648.86,1145.58,652.16,1136.51,655.51z"
          />
          <path
            className="st2"
            d="M89.71,911.95c-1.57-0.78-3.77-0.29-6.22-0.4c-2.79-2.47-12.83,3.35-15.34,9.78c2.38,2.81,3.45,3.6,7.2,2.8
		c4.74-1.02,9.54-2.33,13.89-4.4C92.73,918.07,92.8,913.49,89.71,911.95z M70.94,920.78c3.15-4.97,11.68-7,17.84-6.2
		C86.99,919.6,75.44,923.74,70.94,920.78z"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M1014.81,841.17c4.06-2.51,7.05-11.81,5.56-17.32c-2.55,4.12-5.09,8.24-7.49,12.11
		C1013.54,837.77,1014.18,839.47,1014.81,841.17z"
            />
          </a>

          <path
            className="st2"
            d="M91.31,897.7c-3.42,0.83-5.38,3.7-4.89,7.13c3.59,1.39,8.97-0.05,11.1-2.97
		C96.02,897.74,94.73,896.87,91.31,897.7z M90.1,903.76c-0.26-0.37-0.51-0.73-0.77-1.1c0.82-0.88,1.5-2.17,2.5-2.51
		c1.04-0.36,2.4,0.2,3.62,0.35c0.01,0.57,0.01,1.14,0.02,1.71C93.68,902.73,91.89,903.24,90.1,903.76z"
          />
          <path
            className="st5"
            d="M913.97,1022.68c0.56,0.72,0.14,2.2,0.15,3.34c-0.98-0.19-1.96-0.38-3.53-0.68c2.21,2.24,4.14,3.56,6.52,1.75
		c2.18-1.66,2.13-5.05,0.1-7.2c-0.63-0.66-1.45-1.13-2.18-1.69c-1.42,1.13-2.85,2.26-4.27,3.39
		C911.86,1021.91,913.39,1021.93,913.97,1022.68z"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M1011.11,791.7c-1.39-3.6-2.79-7.19-4.18-10.79c-0.47,2.81-0.94,5.61-1.42,8.5
		C1007.5,790.23,1009.3,790.97,1011.11,791.7z"
            />
          </a>
          <path
            className="st2"
            d="M51.01,855.54c0.32,2.14,1.13,4.23,3.96,3.78c2.82-0.45,4.01-2.79,3.42-5.77c-0.35-1.76-0.64-3.69-0.27-5.4
		c1.19-5.58,2.55-11.13,4.11-16.61c2.62-9.16-1.98-14.21-7.62-16.51c-3.3-1.35-7.4,1.23-7.15,4.49c0.26,3.28,0.63,6.55,0.96,9.83
		c-0.39,0.05-0.77,0.1-1.16,0.15C48.5,838.18,49.69,846.87,51.01,855.54z M60.15,825.79c-0.46,5.19-2.1,10.26-3.1,15.41
		c-0.89,4.63-1.6,9.3-2.42,14.14c-0.41-1.62-1.46-3.52-1.23-5.26c0.74-5.65,2.17-11.21,2.91-16.85c0.72-5.49,2.03-11.36-4.79-14.82
		C56.14,817.59,60.57,821.16,60.15,825.79z"
          />
          <path
            className="st2"
            d="M1143.61,535.16c0.19,1.47,0.84,2.61,1.77,3.54c0.93,0.93,2.13,1.67,3.42,2.34c0,0-0.06-0.05-0.07-0.06
		c0.01,0.01,0.07,0.06,0.07,0.06c0,0,0,0,0,0c-3.93-0.13-6.57,1.09-8.1,3.79c-0.22,0.39-0.42,0.8-0.59,1.25
		c-1.13,2.88-0.07,5.21,1.97,7.22c2.51,2.46,6.77,2.87,10.82,0.98c-0.04-0.79-0.08-1.66-0.13-2.63c-7.18,2.98-10.16,2.05-11.94-3.53
		c3.38-4.35,5.1-4.98,8.18-3.43c0.17,1.58,0.32,2.88,0.46,4.18c0.5,0.01,1,0.02,1.49,0.03c0.51-1.89,1.02-3.78,1.47-5.45
		c-1.64-1.08-2.68-1.77-3.71-2.46c0,0,0,0,0,0c1.45-0.71,2.9-1.43,4.36-2.14c-0.52-0.44-0.96-1.11-1.56-1.29
		c-1.39-0.43-3.18-0.23-4.2-1.04c-1.09-0.86-2.11-2.67-1.98-3.94c0.11-1.05,1.95-1.91,3.02-2.86c1.35,1.69,2.17,2.71,3.37,4.22
		c0.25-1.42,0.54-2.2,0.5-2.96c-0.11-2.06-0.41-3.82-3-4.54c-2.71-0.75-3.79,0.77-4.59,2.63c-0.64,1.47-0.73,3.17-1.07,4.77
		C1143.54,534.29,1143.56,534.74,1143.61,535.16z"
          />
          <path
            className="st11"
            d="M839.94,678.52c-3.87-1.35-9.61-0.35-11.54,2c3.26,1.06,6.51,2.11,9.82,3.19
		C838.73,682.17,839.33,680.35,839.94,678.52z"
          />
          <path
            className="st2"
            d="M799.1,509.45c0,0-0.02-0.02-0.04-0.03L799.1,509.45z"
          />
          <path
            className="st2"
            d="M710.05,545.79c0,1.37,0,2.91,0,4.44c0.49,6.2,0.69,12.44,1.58,18.57c0.45,3.07,0.47,6.76,3.8,8.89
		c1.73-0.91,3.24-1.7,4.67-2.46c0.03-0.68,0.06-1.28,0.09-1.85s0.05-1.1,0.08-1.63c0-0.3,0-0.6,0-0.9c0-0.45,0-0.9,0.01-1.35l0,0
		c0-2.11,0-4.21,0-6.28c0-3.46,0-6.82,0-9.95c5.45,1.29,11.6,2.75,17.76,4.21c3.36,0,6.73,0,10.09,0.01
		c6.6-0.04,13.2-0.09,19.79-0.13c5.35-0.82,10.69-1.63,16.04-2.45c5.77-1.58,11.53-3.15,18.07-4.94c0.45,4.78,0.87,9.29,1.29,13.8
		c0,0,0,0,0,0c0.47,1.81,0.88,3.65,1.33,5.47c0.45,1.82,0.95,3.62,1.61,5.37c0.63,1.68,2.19,3.58,3.76,4.14
		c1.24,0.44,3.15-1.04,4.76-1.67c-0.25-0.26-0.5-0.51-0.75-0.77c0.28-1.75,0.56-3.5,0.83-5.25c0.03-0.56,0.05-1.12,0.06-1.67
		c0.06-3.91-0.36-7.83-0.62-11.84c-0.3-4.57-0.38-9.25,0.69-14.11c6.71,0.9,12.95,1.74,19.18,2.58c2.96,0.36,5.75,1.45,8.58,2.51
		c1.62,0.61,3.25,1.21,4.93,1.67c0.84,0.23,1.7,0.42,2.57,0.56c1.31,0.21,2.66,0.29,4.06,0.2c-0.34-0.96-0.52-1.49-0.71-2.01
		c-4.84-13.47-9.66-26.94-14.52-40.4c-1.44-3.98-5.8-6.15-10.54-5.35c-2.4-4.98-2.4-4.98-9.18-5.44c-1.99-3.94-5.52-5.22-9.57-5.57
		c-4-0.34-8.04-0.4-12-0.97c-6.58-0.95-13.08-2.95-19.66-3.15c-8.62-0.27-17.28,1.06-25.92,1.17c-11.02,0.14-21.34,3.07-31.26,7.36
		c-8.39,3.63-16.55,7.92-21.27,16.45C694.44,526.69,702.03,536.23,710.05,545.79z M716.35,570.94c-1.25-3.31-1.82-6.71-2.14-10.15
		c-0.08-0.86-0.15-1.72-0.2-2.58c-0.03-0.43-0.05-0.86-0.08-1.29c-0.13-2.16-0.22-4.32-0.39-6.47c-0.18-0.37-0.36-0.74-0.53-1.12
		c0.12-0.1,0.24-0.2,0.37-0.3c0.27,0.25,0.55,0.49,0.82,0.73c0,0,0,0,0,0c0.71,0.97,1.93,1.91,2.25,2.89
		c0.05,0.14,0.07,0.28,0.08,0.42C716.76,559.01,716.63,564.96,716.35,570.94z M725.93,550.24c-0.61-0.06-1.22-0.13-1.83-0.19
		c0.05-0.19,0.11-0.37,0.16-0.56c0.57,0.23,1.14,0.46,1.72,0.69l0,0c5.76,0.83,11.52,1.66,17.28,2.49
		C737.28,553.35,731.54,552.26,725.93,550.24z M810.19,571.24c-1.82-7.66-3.52-14.83-5.27-22.22c1.64-0.56,3.26-1.12,5.27-1.82
		C810.19,555.23,810.19,562.96,810.19,571.24z M835.42,511.73c1.8,5.41,4.05,10.67,6.01,16.03c2.15,5.87,4.18,11.79,6.45,18.23
		c-4.08-0.91-7.83-1.99-11.66-2.55c-8.24-1.21-16.51-2.99-24.77-3.02c-12.04-0.05-24.09,1.24-36.13,1.93
		c-1.66,0.09-3.34,0.01-5.36,0.01c-0.31-1-0.58-1.83-1.04-3.3c8.3-0.81,16.3-1.58,24.81-2.41c-1.12-1.55-1.85-2.57-2.84-3.95
		c1.79-0.27,3.31-0.5,4.88-0.74c0.71,1.48,1.49,3.12,2.11,4.42c3.5-1.26,6.68-2.41,9.99-3.6c0.39,0.9,0.67,1.53,1,2.3
		c-1.29,0.06-2.45,0.12-3.91,0.19c-0.03,0.63-0.06,1.25-0.12,2.61c4.64-4.99,6.94,0.97,10.53,1.37c0-1.45,0-2.92,0-5.24
		c0.05,0.06-0.55-0.66-1.14-1.37c0.21-0.21,0.42-0.41,0.63-0.62c2.2,2.54,4.41,5.08,6.75,7.77c0.89-0.7,1.51-1.19,2.07-1.64
		c1.46,0.52,2.9,1.04,5.04,1.8c-0.93-2.57-1.55-4.28-2.28-6.29c2.53,0.6,4.97,1.18,8.15,1.93c-2.2,0.27-3.64,0.45-5.48,0.67
		c1.76,3.57,4.23,4.78,7.81,4.01c-1.27-0.65-2.54-1.3-4.09-2.09c1.23-0.1,2.2-0.19,3.88-0.33c-0.67-1.55-1.2-2.77-1.75-4.04
		c0.98-0.27,2.51-0.68,4.46-1.22c-7.77-6.4-5.56-16.66-10.35-24.43C832.52,507.56,834.36,508.54,835.42,511.73z M756.88,504.93
		c7.86-0.96,15.73-1.92,23.66-2.89c0.48,1.2,0.86,2.13,1.32,3.27c-0.86,0.63-1.66,1.42-2.63,1.88c-0.81,0.39-1.79,0.4-3.08,0.65
		c-0.99-0.18-2.55-0.65-4.09-0.62c-0.44,0.01-0.84,1.5-1.42,2.63c1.58,0.18,2.61,0.29,3.6,0.41c0.61,1.87,1.21,3.68,1.81,5.49
		c0.31-0.05,0.62-0.1,0.93-0.14c0-1.91,0-3.82,0-5.67c1.5,1.59,3.07,3.25,4.63,4.91c0.3-0.2,0.61-0.41,0.91-0.61
		c0-2.19,0-4.38,0-6.57c0.39-0.1,0.78-0.21,1.17-0.31c2.61,6.96,5.21,13.92,7.97,21.3c-11.07-0.26-21.64-2.66-32.17,2.65
		c-3.03-10.44-6.11-21.03-9.34-32.12c2.93-0.43,5.73-1.03,8.56-1.21c5.9-0.37,11.81-0.62,17.72-0.68c1.15-0.01,2.31,1.1,4.29,2.11
		c-8.76,1.94-16.7,1.71-24.05,4.28C756.74,504.1,756.81,504.52,756.88,504.93z M787.31,533.08c-8.97,1.82-17.82,3.62-26.83,5.46
		c-0.04-0.7-0.12-2.08-0.2-3.37C766.36,530.03,783.95,528.84,787.31,533.08z M816.77,532.13c3.58,0.42,6.1,0.72,8.69,1.02
		c0,0.87,0,1.57,0,2.65c-1.31-0.73-2.46-1.37-3.71-2.07c-0.48,0.75-0.97,1.5-1.55,2.4C819.18,534.95,818.37,534,816.77,532.13z
		 M823.99,501.72c3.09,9.84,6.1,19.45,9.21,29.37c-4.01-0.51-8.46-1.11-12.91-1.64c-7.33-0.88-13.25,0.11-20.18,3.39
		c-0.35-0.88-0.69-1.76-1.1-2.79c0.76-0.86,1.53-1.74,2.38-2.71c0.66,1.07,1.21,1.98,2.15,3.51c0.33-1.52,0.5-2.36,0.7-3.28
		c0.78,0.43,1.55,0.86,2.74,1.53c0.96-1.31,1.86-2.54,2.78-3.81c0.84,0.93,1.78,1.97,2.72,3.01c0.24-0.14,0.48-0.29,0.73-0.43
		c-0.06-1.08-0.13-2.17-0.19-3.25c0.2-0.05,0.4-0.09,0.61-0.14c0.46,1.11,0.91,2.21,1.81,4.37c0.32-2.29,0.51-3.58,0.78-5.49
		c0.96,1.63,1.57,2.67,2.3,3.91c0.99-0.06,2-0.11,3.43-0.19c-0.17-1.46-0.31-2.63-0.45-3.8c0.18-0.11,0.35-0.23,0.53-0.34
		c0.83,1.49,1.66,2.99,2.49,4.48c0.21-0.08,0.43-0.17,0.64-0.25c-0.24-1.25-0.48-2.5-0.75-3.88c0.35-0.18,0.86-0.44,1.62-0.82
		c0.72,1.87,1.42,3.71,2.13,5.54c0.32-0.12,0.64-0.24,0.97-0.36c-1.44-9.19-6.7-17.03-10.1-25.93
		C820.95,501.72,822.57,501.72,823.99,501.72z M811.9,496.38c1.68,0.59,2.56,1.21,3.22,3.03c2.39,6.57,5.16,13.01,8.1,20.26
		c-5.18,0.78-10.35,2.27-15.46,2.09c-4.35-0.15-7.59,0.5-10.19,4c-0.66,0.89-1.66,1.52-1.98,1.8c-3.79-8.66-7.61-17.38-11.41-26.04
		c2.1-0.56,4.78-1.27,7.71-2.05c-0.52,5.45,2.06,8.63,7.11,9.96c0.01-0.01,0.01-0.02,0.02-0.04c0,0,0,0,0,0c0,0,0.01-0.01,0.01-0.01
		c-1-1.34-2.02-2.68-3.01-4.03c-0.58-0.79-1.11-1.61-1.67-2.41c0.3-0.38,0.61-0.76,0.91-1.14c2.38,1.18,4.76,2.37,7.35,3.66
		c-1.24,1.36-2.41,2.64-3.58,3.92c0.02,0.03,0.04,0.05,0.06,0.08c-0.03-0.01-0.06-0.02-0.09-0.03c-0.51,0.87-0.9,1.84-1.56,2.58
		c-2.62,2.97-2.69,3.88-0.06,7.68c1.76-0.3,3.69-0.64,5.41-0.93c0.78-1.74,1.43-3.21,2.15-4.81c3.77,1.04,7.46,4.2,12.23,0.05
		c-2.11-2.33-4.18-4.6-6.35-6.99c2.19-3.69,2.21-4.81-0.21-8.42c-1.16,0.33-2.38,0.68-3.21,0.92c-1.27,1.82-2.35,3.36-3.55,5.08
		c-2.93-3.08-5.47-6.28-10.1-5.85c4.27-0.37,8.55-0.74,12.82-1.11c-8.24-2.51-15.91,0.57-23.94,2.44c-0.6-0.94-1.21-1.92-2.1-3.33
		C791.24,495.18,801.48,492.73,811.9,496.38z M813.49,512.41c-3.33,3.25-5.46-1.44-9.19-0.66c-1.2,1.39-3.16,3.67-5.11,5.94
		c-0.35-0.32-0.71-0.64-1.06-0.96c3.66-5.23,7.33-10.46,11.16-15.93c0.43,0.77,0.77,1.37,0.92,1.65c-1.28,1.94-2.41,3.66-3.7,5.62
		C808.94,509.59,811.05,510.9,813.49,512.41z M718.15,506.19c7.18,0,14.13,0,21.1,0c1.47,7.07,2.95,14.24,4.44,21.41
		c-4.02-2.26-1.56-6.88-3.9-10.39c-0.36,1.17-0.63,2.02-0.9,2.88c0.24,0.6,0.49,1.19,0.73,1.79c-0.14,0.06-0.29,0.12-0.43,0.18
		c-0.27-0.47-0.55-0.94-0.82-1.41c0,0,0,0,0,0c-0.78,0.24-1.57,0.49-2.31,0.72c-0.05-0.28-0.23-1.18-0.46-2.39
		c-1.78-0.14-3.45-0.26-5.74-0.44c0.01,0.02,0.02,0.02,0,0l0,0c-0.06-0.08-0.43-0.6-1.01-1.4c-0.43,1.3-0.76,2.3-1.15,3.5
		c-0.54-1.13-1.05-2.19-1.73-3.63c-0.43,0.75-0.71,1.26-1.1,1.93c-0.25-0.6-0.48-1.18-0.79-1.94c-0.71,1.43-1.35,2.69-2.16,4.33
		C718.21,516.65,717.86,511.72,718.15,506.19z M747.35,528.11l0.06-0.05C747.41,528.05,747.35,528.11,747.35,528.11
		c-0.05-0.39-0.09-0.79-0.14-1.18c-0.41-2.26-0.82-4.51-1.22-6.77c-1.37-5.38-2.74-10.77-4.11-16.15c0.61-0.14,1.23-0.27,1.84-0.41
		c0.28,0.64,0.51,1.29,0.7,1.95c1.35,4.61,0.83,9.64,1.94,14.31c0.29,2.37,0.57,4.74,0.86,7.11c0.07,0.36,0.13,0.73,0.2,1.09
		c0.01,0.09,0.01,0.16,0.01,0.25c0.68,1.92,1.37,3.83,2.11,5.9c-8.47,0.48-16.66,0.95-25.01,1.42c-0.22-1.25-0.43-2.41-0.72-4.03
		c1.87,0,3.6,0.06,5.32-0.01c5.09-0.21,10.18-0.45,15.27-0.72c1.68-0.09,3.21-0.43,3.04-2.55
		C747.4,528.25,747.37,528.18,747.35,528.11z M710.49,507.37c1.59-1.18,3.12-2.43,5.06-3.95c0,9.41,0,18.41,0,27.79
		c1.7,0,3.37,0,5.14,0c0.19,1.82,0.4,3.77,0.63,5.92c10.78,0,21.22,0,32.44,0c-2.82-12.37-5.41-23.68-8.03-35.19
		c-9.42,0-17.87,0-26.24,0c12.38-9.37,43.61-11.31,63.54-8.39c-11.42,0.88-22.85,1.76-35.1,2.7c0.41,3.65,0.41,6.59,1.11,9.34
		c2.76,10.77,5.82,21.46,8.62,32.22c0.79,3.04,1.97,4.65,5.29,3.12c0.83-0.38,1.78-0.52,3.26-0.94c-0.3,1.45-0.54,2.59-0.78,3.75
		c1.22,3.06,3.9,2.23,6.13,2.08c6.11-0.42,12.21-1.04,18.34-1.3c-7.87,3.43-16.04,5.06-24.6,4.33c-8.36-0.72-16.72-1.48-25.08-2.22
		c-11.7-1.04-22.53-4.25-31.74-12.03C700.48,527.85,701.38,514.12,710.49,507.37z"
          />
          <path
            className="st2"
            d="M855.33,522.62c0.21,0.55,0.28,1.23,0.66,1.62c3.52,3.6,6.99,7.25,10.7,10.66c1,0.92,2.73,1.04,4.3,1.09
		c2.32,4.06,5.39,6.71,10.92,6.5c3.68-0.14,7.4,1.13,11.75,1.88c0.58-1.29,1.24-2.78,1.7-3.8c2.01,1.18,4.31,2.52,6.64,3.89
		c1.24-1.59,1.96-2.5,2.34-2.99c4.47,1.55,8.69,3.01,12.91,4.47l0,0c-0.17-1.96-0.34-3.93-0.51-5.89l0,0
		c2.19,1.7,4.56,2.19,6.91,1.33c0.34-0.12,0.68-0.28,1.01-0.46c1.16-1.46,2.63-3.47,4.01-3.41c4.15,0.18,6.51-0.94,5.91-5.31
		c1.93,0,3.45,0,5.46,0c0.41-1.74,0.85-3.61,1.09-4.62c2.14-1.09,5.02-1.75,5.18-2.81c0.3-2.1-0.89-4.42-1.61-7.19
		c-0.28-3.21-0.62-7.1-0.96-10.98c-1.26-2.29-2.53-4.58-3.79-6.87c0,0,0.16-0.2,0.16-0.2c-1.85-1-3.7-2-5.55-3
		c-1.14-3.15-1.86-6.54-3.51-9.39c-5.7-9.82-17.22-12.86-27.77-7.35c-11.93-9.36-18.7-9.24-31.67-4.34
		c-7.36,2.78-15.21,6.2-17.01,15.59c-4.49-2.44-9.73-0.08-9.72,4.48c0.02,5.12-0.16,10.24,0.09,15.35c0.08,1.7,1.21,3.35,1.87,5.01
		c0.53,1.35,1.08,2.7,1.62,4.05c0.5,1.25,1.01,2.49,1.52,3.74c1.76,0,3.53,0,5.29,0C855.29,523.31,855.31,522.97,855.33,522.62z
		 M871,533c-4.72-1.66-7.09-6.12-6.54-11.75c2.54,2.36,5.14,4.77,7.53,6.98C871.66,529.78,871.35,531.28,871,533z M864.69,516.32
		c0.02,0.03,0.04,0.07,0.06,0.1c0.08-0.32,0.15-0.63,0,0C864.73,516.38,864.71,516.35,864.69,516.32
		C864.59,516.15,864.52,516.04,864.69,516.32z M873.82,529.53c1.05,0.33,1.99,0.63,2.11,0.67c1.04,3.38,1.9,6.18,2.73,8.87
		C875.38,538.06,875.15,537.62,873.82,529.53z M878.89,531.51c1.87,0.89,3.4,1.62,4.89,2.34c0.71,2.06,1.4,4.08,2.24,6.55
		C881.72,538.85,879.77,535.93,878.89,531.51z M891.51,541.61c-1.8-1.81-3.6-3.62-5.39-5.43c0.22-0.29,0.45-0.58,0.67-0.88
		c1.84,0.57,3.68,1.14,5.88,1.83c0.05,1.19,0.11,2.4,0.16,3.62C892.39,541.03,891.95,541.32,891.51,541.61z M916.38,540.01
		c0.02,0,0.06-0.01,0.12-0.02c-0.28-0.02-0.18-0.01,0.01,0c0.07-0.01,0.15-0.03,0.23-0.04c0,0-0.05,0.04-0.05,0.05
		c-0.06,0-0.12-0.01-0.18-0.01C916.46,540,916.41,540.01,916.38,540.01C916.35,540.02,916.35,540.02,916.38,540.01z M882.08,477.09
		c8.68-0.67,15.88,2.25,21.97,8.22c5.49-7.29,17.18-6.8,23.11,0.72c2.83,3.58,4.56,7.65,4.38,12.27c-0.1,2.48,1.2,3.68,3.16,4.57
		c5.04,2.31,7.07,9.07,4.1,13.71c-1.34,2.28-2.69,4.57-3.46,5.87c-2.29-0.09-4.17-0.6-5.78-0.14c-5.61,1.62-11.01,4.27-16.71,5.26
		c-12.17,2.13-23.47-0.2-32.91-8.83c-2.15-1.47-4.29-2.94-6.44-4.41c0.11-0.21,0.22-0.42,0.33-0.63c3,0.5,5.99,1.01,8.99,1.51
		c0.12,0.09,0.25,0.17,0.38,0.25c0.92,0.57,2.06,1.04,2.4,1.85c2.05,4.84,5.59,7.74,10.6,9.01c3.79,0.95,10.09-1.34,12.17-5.28
		c-1.95,0.31-3.22,0.58-4.49,0.71c-2.97,0.31-6.06,1.2-8.89,0.69c-4.61-0.83-5.87-2.66-8.58-9.28c-1.18-0.07-2.46-0.14-3.76-0.21
		c-0.43-0.02-0.87-0.05-1.3-0.07c-4.09-0.49-7.78-1.85-10.69-4.91h0c-2.41-2.42-4.46-5.06-6.11-7.93c-0.92-1.6-1.72-3.27-2.38-5.03
		c-0.26-0.7-0.5-1.42-0.72-2.15c-0.18-0.84-0.36-1.69-0.55-2.54c-0.18-0.86-0.37-1.72-0.56-2.61
		C866.65,481.79,873.43,477.76,882.08,477.09z M861.07,504.54c8.34,10.97,17.86,20.54,31.41,24.66c13.54,4.11,26.52,0.98,39.27-4.16
		c-12.87,15.25-55.54,10.57-65.38-6.92c0.95-0.11,1.79-0.22,3.35-0.41c-3.34-4.34-6.44-8.37-9.53-12.39
		C860.48,505.06,860.77,504.8,861.07,504.54z M860.82,515.99c0.29-0.01,0.59-0.01,0.88-0.02c0.48,0.9,0.95,1.8,1.26,2.39
		c-0.83,2.04-1.56,3.86-2.29,5.67c-0.18,0.02-0.37,0.03-0.55,0.05C860.36,521.38,860.59,518.68,860.82,515.99z M849.88,500.78
		c0.02,0,0.05-0.01,0.07-0.01c0.23-1.95,1.11-2.62,2.27-2.77c0.26-0.03,0.54-0.04,0.83-0.03c0.29,0.01,0.59,0.03,0.9,0.07
		c0.46,0.05,0.94,0.12,1.41,0.17c0.03,0.7,0.06,1.35,0.08,1.76c-1.98,0.29-3.74,0.55-5.5,0.8c0,0.03-0.01,0.06-0.01,0.09l0,0
		c-0.04,0-0.07,0-0.11,0c-0.09,0.15-0.18,0.29-0.27,0.44c-0.05-0.15-0.1-0.3-0.15-0.45c0.14,0,0.28,0.01,0.42,0.01
		C849.85,500.83,849.86,500.8,849.88,500.78z M847.83,512.6c-1.75-3.88,2.42-9.59,6.53-9.29
		C852.26,506.3,850.06,509.43,847.83,512.6z M851.03,520.52c-1.23-5.8,0.02-9.05,5.94-15.15c0.4,0.08,0.81,0.16,1.21,0.24
		c-0.36,1.37-0.29,3.24-1.17,4.02c-2.61,2.34-3.15,5.12-2.75,8.21C853.1,518.81,852.03,519.69,851.03,520.52z"
          />
          <path
            className="st5"
            d="M802.04,550c-6.53,1.79-12.3,3.36-18.07,4.94c-1.68,0.84-3.3,2.22-5.05,2.39c-3.63,0.36-7.33,0.07-11,0.05
		c-6.6,0.04-13.2,0.09-19.79,0.13c0.71,2.2,1.27,4.4,1.46,6.63c0.1,1.11,0.1,2.23,0,3.36c-0.11,1.12-0.33,2.26-0.7,3.4
		c4.44,0.71,8.86,1.7,13.3,2.29c1.48,0.2,2.96,0.35,4.45,0.43c8.46,0.47,16.98,0.17,25.47,0.09c4.9-0.04,8.08-2.13,9.87-5.77
		c0.3-0.61,0.56-1.26,0.78-1.95s0.41-1.42,0.56-2.2C802.91,559.29,802.49,554.78,802.04,550z"
          />
          <path
            className="st5"
            d="M862.87,541.15c1.29,2.45-0.18,6.44,3.94,7.58c0.58-0.92,1.13-1.77,1.2-1.9c7.48,2.6,14.52,5.6,21.85,7.47
		c6.62,1.69,12.66,0.94,18.15-1.69c1.1-0.53,2.17-1.13,3.22-1.8c2.1-1.35,4.11-2.98,6.03-4.87c-4.22-1.46-8.44-2.92-12.91-4.47
		c-0.38,0.49-1.1,1.4-2.34,2.99c-2.33-1.37-4.63-2.71-6.64-3.89c-0.46,1.02-1.12,2.51-1.7,3.8c-4.35-0.75-8.08-2.01-11.75-1.88
		c-5.53,0.2-8.6-2.45-10.92-6.5c-1.57-0.05-3.3-0.17-4.3-1.09c-3.7-3.4-7.18-7.05-10.7-10.66c-0.38-0.39-0.44-1.07-0.66-1.62
		c-0.02,0.34-0.04,0.69-0.07,1.03c-1.76,0-3.53,0-5.29,0c-0.31,4.24,1.91,7.42,4.3,10.58c0.82,1.08,1.65,2.26,2.06,3.53
		C857.65,541.91,858.58,542.49,862.87,541.15z"
          />
          <path
            className="st5"
            d="M815.01,543.45c-1.07,4.86-0.98,9.54-0.69,14.11c0.26,4,0.68,7.93,0.62,11.84c-0.01,0.56-0.03,1.12-0.06,1.67
		c1.17-0.94,3.24-1.78,3.37-2.84c0.63-5.19,4.6-5.47,8.31-6.62c1.8-0.56,3.79-1.81,4.82-3.34c1.53-2.26,2.39-4.77,2.73-7.4
		c0.2-1.58,0.22-3.2,0.08-4.84v0C827.96,545.19,821.72,544.35,815.01,543.45z"
          />
          <path
            className="st16"
            d="M749.6,567.5c0.11-1.12,0.1-2.24,0-3.36c-0.19-2.23-0.75-4.43-1.46-6.63v0c-3.36,0-6.73,0-10.09-0.01
		c-5.92,4.01-11.84,8.02-17.76,12.03v0c0,0,0,0,0,0l0,0c0,0.45,0,0.9-0.01,1.35c0,0.3,0,0.6,0,0.9c0.56,0.74,1.19,1.44,1.68,2.22
		c2.52,3.95,3.32,4.27,7.41,2.03c3.62-1.98,7.05-4.32,10.67-6.31c0.27-0.15,0.56-0.25,0.87-0.33c0.92-0.23,1.97-0.22,2.93-0.38l0,0
		c1.69,0.63,3.38,1.26,5.07,1.88v0C749.26,569.75,749.49,568.62,749.6,567.5z"
          />
          <path
            className="st2"
            d="M844.23,465.37c-3.38,5.08-6.78,9.87-9.81,14.9c-1.16,1.94-2.6,4.19-0.79,6.77c3.07,4.38,8.23,4.11,11.04-0.87
		C848.68,479.09,847.4,472.1,844.23,465.37z M837.39,487.47c-0.71-0.69-1.58-1.55-2.55-2.5c3.41-4.9,6.29-9.04,9.22-13.25
		C844.71,477.87,844.68,483.68,837.39,487.47z"
          />
          <path
            className="st12"
            d="M943.74,506.59c-0.02-0.55-0.01-1.12,0.01-1.69c0.01-0.38,0.02-0.76,0.01-1.13c0-0.01,0-0.02,0-0.02
		c-0.01-0.29-0.02-0.57-0.06-0.85c-0.19-1.65-0.96-2.97-3.76-3.18c0,0,0,0,0,0C941.22,502.01,942.48,504.3,943.74,506.59z"
          />
          <path className="st17" d="M934.56,496.53c1.85,1,3.7,2,5.55,3" />
          <path
            className="st12"
            d="M324.44,562.94c0.38,0.38,0.77,0.73,1.23,0.92c11.47,4.87,22.97,9.69,34.49,14.44
		c2.85,1.17,5.8,2.08,8.71,3.11c0.04-0.19,0.09-0.37,0.14-0.56c-6.42-3.04-12.84-6.09-19.27-9.13c-8.81-3.31-17.61-6.62-26.42-9.94
		C323.71,562.14,324.07,562.56,324.44,562.94z"
          />
          <path
            className="st2"
            d="M345.44,582.47c3.66,0.94,7.33,1.89,10.99,2.83c0.02-0.08,0.03-0.17,0.05-0.25
		c-18.45-6.52-36.9-13.05-55.36-19.57c-0.09,0.19-0.19,0.37-0.28,0.56c6.78,2.88,13.55,5.76,20.33,8.64
		c0.22,0.06,0.45,0.13,0.67,0.19c0.48,0.14,0.97,0.27,1.45,0.41c0,0-0.01-0.01-0.01-0.02c-0.02-0.02-0.04-0.05-0.04-0.05
		C330.65,577.63,338.04,580.05,345.44,582.47z"
          />
          <path
            className="st2"
            d="M349.74,571.72c3.93,1.36,7.87,2.72,11.8,4.08c-14.69-7.29-29.96-13.02-45.53-17.95
		c-0.15,0.41-0.31,0.82-0.46,1.22c2.59,0.91,5.18,1.81,7.78,2.72C332.13,565.1,340.93,568.41,349.74,571.72z"
          />
          <path
            className="st12"
            d="M347.77,562.36c3.28,1.22,6.57,2.44,9.86,3.67c0.56,0.23,1.13,0.46,1.69,0.69c0.06-0.16,0.12-0.32,0.18-0.49
		c-0.54-0.19-1.07-0.37-1.61-0.56c-8.28-3.08-16.06-7.52-24.73-9.5c1.5,1.08,3.11,1.88,4.77,2.51
		C341.2,559.91,344.48,561.13,347.77,562.36z"
          />
          <path
            className="st2"
            d="M360.82,562.6c-6.61-2.21-13.22-4.42-19.84-6.63c-0.1,0.31-0.2,0.62-0.31,0.93
		c6.62,2.18,13.24,4.37,19.86,6.55C360.63,563.17,360.73,562.89,360.82,562.6z"
          />
          <path
            className="st17"
            d="M323.3,575.27c-0.71-0.2-1.42-0.4-2.12-0.59"
          />
          <path
            className="st1"
            d="M644.93,211.36c0.5,0.17,1.01,0.34,1.51,0.51c0.68,0,1.37,0,2.05,0l0,0c0.39-0.26,0.77-0.65,1.16-0.92
		c0.23-0.16,0.47-0.27,0.71-0.29c14.58-0.78,29.16-1.46,43.74-2.22c3.43-0.18,6.84-0.59,10.26-0.89c-0.02-0.33-0.04-0.67-0.06-1
		c-20.37,0.71-40.73,1.42-61.68,2.16c0.35,0.92,0.56,1.47,0.72,1.88c0.09,0.24,0.17,0.44,0.24,0.63
		C644.03,211.27,644.48,211.31,644.93,211.36z"
          />
          <path
            className="st2"
            d="M661.6,223.55c4.12-0.26,8.24-0.52,12.36-0.72c7.08-0.36,14.18-0.49,21.27-0.77c0.12,0,0.24-0.04,0.37-0.08
		c0.24-0.1,0.48-0.26,0.72-0.41c0.12-0.08,0.24-0.15,0.36-0.21c-3.67,0.01-7.33-0.03-11,0.04c-9.62,0.18-19.24,0.28-28.85,0.67
		c-3.89,0.16-7.77,0.41-11.65,0.68c-3.88,0.28-7.76,0.58-11.64,0.84c0.48,0.49,0.96,0.99,1.44,1.48c4.76-0.23,9.52-0.47,14.28-0.7
		C653.37,224.09,657.48,223.81,661.6,223.55z"
          />
          <path
            className="st2"
            d="M688.35,210.95c-1.72-0.31-3.45-0.36-5.17-0.3c-11.56,0.4-23.13,0.81-34.69,1.23c-0.68,0-1.37,0-2.05,0
		c-0.5-0.17-1.01-0.34-1.51-0.51c-0.45-0.05-0.91-0.1-1.36-0.14l0,0c-0.5,0.27-1.01,0.53-1.51,0.8
		C657.68,214.52,673.02,213.13,688.35,210.95z"
          />
          <path
            className="st9"
            d="M548.41,377.66c-5.12-5.92-11.31-4.78-15.11,1.77c-0.3,0.51-0.63,1-0.95,1.51
		c-4.22,6.75-4.85,14.35-5.65,22.44c12.79,0.67,25.11,1.31,37.28,1.95c-1.29-3.13-2.46-7.04-4.43-10.48
		C556.16,388.92,552.84,382.78,548.41,377.66z"
          />
          <path
            className="st9"
            d="M559.46,306.47c3.91,0,7.83,0,11.74,0c-3.14-1.38-6.26-2.77-9.41-4.12c-4.15-1.78-8.97-1.73-12.56-5.06
		c-1.25-1.16-2.51-2.3-3.78-3.44c-3.81-3.41-7.68-6.76-11.51-10.15c-3.12,2.65-6.24,5.31-9.36,7.96c1.12,0.21,2.42,0.9,3.33,0.56
		c4.23-1.59,7.6,0.36,11.03,2.26c1.24,0.69,2.42,1.48,3.68,2.15c7.67,4.06,8.21,12.69,1.27,17.61c-1.76,1.25-2.87,3.43-4.27,5.18
		c3.22-0.13,6.44-0.26,9.65-0.38c-1.16-0.77-2.33-1.53-3.49-2.3c0.15-0.39,0.31-0.78,0.46-1.17c1.58-0.35,3.15-0.94,4.74-1
		c8.33-0.32,16.66-0.71,24.99-0.68c11.67,0.03,23.35,0.38,35.02,0.66c2.48,0.06,4.95,0.44,7.42,0.67c-0.21-0.09-0.42-0.21-0.63-0.32
		c-0.17-0.09-0.35-0.18-0.52-0.25c-0.13-0.05-0.26-0.09-0.39-0.11c-5.05-0.76-10.12-1.42-15.16-2.22c-1.85-0.3-3.64-0.91-5.54-1.39
		c-0.79,0.75-1.54,1.45-2.49,2.34c-4.41-1.7-8.8-3.39-13.19-5.08c-7.02-0.13-14.05-0.25-21.07-0.38
		C559.44,307.36,559.45,306.91,559.46,306.47z"
          />
          <path
            className="st2"
            d="M504.55,440.67c0.47-6.08-3.39-8.48-7.2-10.32c-0.92-0.45-2.57,0.22-3.72,0.75c-1.22,0.56-2.25,1.56-3.36,2.36
		c-2.74,2.83-4.23,6.1-3.65,10.14c0.52,3.63,1.76,6.77,5.32,8.5c3.02,1.46,6.05,0.98,8.07-1.32c2-2.27,3.13-5.3,4.64-7.99
		C504.61,442.08,504.49,441.37,504.55,440.67z M502.22,439.93c0.12,3.69-2.32,7.71-5.55,8.92c-2.7,1.01-4.71-0.28-5.71-2.62
		c-2.4-5.58-2.28-6.47,2.09-11.42c1.3-0.5,3.01-1.79,3.82-1.36C499.42,434.79,503.09,435.63,502.22,439.93z"
          />
          <path
            className="st2"
            d="M610.02,318.35c1.03-0.08,2.05-0.16,3.07-0.27c1.06-0.12,2.09-0.43,3.14-0.65
		c-21.97,0.45-43.94,0.89-65.91,1.35c-0.35,0.01-0.69,0.16-1.04,0.25c-3.22,0.13-6.44,0.26-9.65,0.38c-0.64,0.44-1.57,0.09-2.41,0
		c-0.1-0.01-0.21-0.02-0.31-0.02c-0.1,0-0.2,0-0.3,0.02c-0.1,0.01-0.19,0.03-0.28,0.06c-0.45,0.15-0.8,0.56-0.97,1.48
		c0.24,0.04,0.48,0.08,0.72,0.12c1.42,0,2.84,0,4.25,0c2.1-0.28,4.2-0.77,6.3-0.79c8.2-0.1,16.41,0.11,24.61-0.09
		c11.45-0.27,22.89-0.8,34.34-1.22c0.45-0.13,0.9-0.27,1.34-0.4c0,0,0,0,0,0l0,0C607.96,318.49,608.99,318.42,610.02,318.35z"
          />
          <path
            className="st9"
            d="M508.28,444.36c0.04,0.04,0.06,0.07,0.07,0.08C508.34,444.43,508.31,444.4,508.28,444.36z"
          />
          <path
            className="st9"
            d="M508.27,444.35c-0.04-0.05-0.09-0.11-0.14-0.16l0,0C508.19,444.26,508.24,444.31,508.27,444.35z"
          />
          <path
            className="st9"
            d="M508.1,444.21L508.1,444.21C508.18,444.5,508.26,444.77,508.1,444.21z"
          />
          <path
            className="st9"
            d="M508.27,444.35C508.27,444.35,508.27,444.35,508.27,444.35C508.27,444.35,508.27,444.35,508.27,444.35z"
          />
          <path
            className="st9"
            d="M508.35,444.44C508.36,444.45,508.36,444.45,508.35,444.44L508.35,444.44z"
          />
          <path
            className="st9"
            d="M633.53,223.58c-1.93-2.17-3.86-4.34-5.79-6.51c0,0,0,0,0,0c-1.88,1.43-3.76,2.86-5.64,4.29
		c7.32,9.76,10.89,20.83,12.27,32.88c0.56,4.89-0.67,8.84-3.25,12.63c-2.09,3.06-4.79,3.32-7.55,0.77c-2.43-2.24-4.96-4.5-5.07-8.5
		c0.88-0.45,1.78-0.9,2.78-1.4c1.87,2.36,3.59,4.54,5.48,6.92c4.4-3.63,5.24-8.07,4.5-12.78c-1.39-8.8-5.23-16.74-9.48-24.46
		c-0.46-0.83-1.62-1.28-2.45-1.91c-4.29,3.61-8.61,7.19-12.86,10.86c-0.23,0.2-0.44,0.42-0.64,0.65c-0.61,0.69-1.11,1.49-1.69,2.22
		c0.87,0.55,1.75,1.1,2.62,1.64c0.03,0.39,0.07,0.78,0.1,1.17c-4.67,1.18-9.34,2.36-14.33,3.62c3.63,10.14,7.77,20.07,5.26,31.08
		c-3.52-4.39-1.93-10.04-3.78-14.86c-1.94-5.05-3.37-10.29-5.09-15.69c-0.55,0.17-1.08,0.34-1.57,0.49
		c-1.48,0.46-2.74,0.85-3.99,1.25c0,0,0,0,0,0c-0.96,0.95-1.93,1.9-2.89,2.84c0.12,3.1,2.09,2.07,3.79,1.72
		c3.03-0.61,3.63,0.14,2.74,3.49c-1.35,0.54-2.74,1.1-4.09,1.64c1.43,7.07,4.85,13.56,3.86,20.99c-4.65-6.06-4.17-14-7.48-20.55
		c-3.72,0.42-7.32,0.83-10.93,1.25c-0.73,0.95-1.46,1.89-2.19,2.84c5.69,2.86,9.59,7.59,13.01,12.75c2.81,4.23,2.7,7.3-0.14,9.91
		c-3.1,2.85-5.88,2.75-9.99-0.37c-0.06,3.13-1.95,4.88-4.79,6.31c-0.91-1.08-1.77-2.09-2.76-3.25c1.28-1.15,2.21-1.99,3.86-3.47
		c-2.58-2.72-5.08-5.36-7.69-8.1c-2.28,4.15-6.22,4.58-10.26,1.48c-0.94-0.72-1.8-1.54-2.69-2.31c-1.69,1.21-3.38,2.42-5.07,3.63
		c3.69,3.14,7,6.96,11.15,9.26c6.07,3.37,12.54,6.42,19.21,8.21c10,2.68,20.26,4.98,30.55,5.9c11.12,1,22.4,0.18,33.61,0.26
		c2.92,0.02,5.85,0.41,8.77,0.62c0,0.2,0,0.4,0,0.6c-2.19,0.31-4.39,0.61-6.58,0.92c-1.6,0.23-3.2,0.45-4.79,0.71
		c-1.27,0.2-2.52,0.46-3.78,0.69c1.56,0.2,3.13,0.41,4.69,0.61c3.13,0.41,6.28,0.82,9.46,1.23c1.59,0.21,3.19,0.42,4.81,0.63
		c7.91-14.55,19.13-26.86,22.76-43.82c-6.55-7.86-13.11-15.97-19.93-23.84c-4.67-5.39-9.71-10.45-14.59-15.65
		C634.49,224.56,634.01,224.07,633.53,223.58z M601.62,249.24c1.47-2.09,4.71-3.54,7.38-4.01c2.89-0.51,4.63,1.99,5.23,4.81
		c0.41,1.95,0.61,3.95,0.91,5.93c0.19,0.12,0.38,0.24,0.57,0.37c-1.12,2.9-1.93,5.98-3.45,8.64c-1.58,2.76-4.13,2.93-6.7,1.08
		C601.39,263.07,598.69,253.42,601.62,249.24z M646.53,287.32c2.48,0,4.95,0,7.43,0c0.01,0.33,0.02,0.66,0.03,0.99
		c-15.81,0.85-31.61,1.7-47.42,2.55c-0.02-0.42-0.05-0.84-0.07-1.25c1.13-0.1,2.26-0.2,3.39-0.3c0,0,0,0,0,0c0,0,0,0,0,0
		c0.37-0.17,0.73-0.37,1.1-0.58c1.1-0.63,2.2-1.34,3.3-1.35C625.04,287.22,635.78,287.31,646.53,287.32z"
          />
          <path
            className="st9"
            d="M694.27,301.17c1.68-2.01,1.12-3.71-0.48-5.61c-3.89-4.62-7.6-9.39-11.37-14.12
		c-3.16-3.97-6.29-7.95-10.03-12.67c-4.83,15.87-14.18,28.21-21.54,41.55c9.08,2.34,17.98,4.72,26.95,6.83
		c1.21,0.28,3.2-0.39,4.04-1.33C686.14,311.06,690.17,306.08,694.27,301.17z"
          />
          <path
            className="st9"
            d="M717.39,285.79c-4.62-2.38-8.22-4.66-12.12-6.16c-4.95-1.9-10.94-1.45-14.27-6.78
		c-0.2-0.32-0.83-0.53-1.27-0.54c-2.87-0.05-5.73-0.02-8.98-0.02c5.83,7.49,11.6,14.9,17.32,22.24
		C704.48,291.63,710.37,288.96,717.39,285.79z"
          />
          <path
            className="st9"
            d="M613.09,318.08c-1.02,0.11-2.05,0.2-3.07,0.27c-1.03,0.08-2.05,0.14-3.08,0.22l0,0
		c0.23,0.26,0.42,0.72,0.68,0.75c6,0.89,9.94,4.66,13.3,9.33c1.96,2.73,4.06,5.35,6.75,8.86c1.95-5.36,3.61-9.75,5.13-14.18
		c1.07-3.11,3.13-4.26,6.39-4.62c5.38-0.59,12.61,6.93,13.29,12.88c0.73-2.19,1.32-3.94,1.87-5.58c-3.49-4.39-7.11-8.13-12.87-8.45
		c-3.86-0.22-7.73-0.19-11.59-0.26c-4.55,0.04-9.11,0.09-13.66,0.13C615.18,317.65,614.15,317.96,613.09,318.08z"
          />
          <path
            className="st1"
            d="M611,314.54c-11.67-0.28-23.35-0.63-35.02-0.66c-8.33-0.02-16.66,0.36-24.99,0.68c-1.59,0.06-3.16,0.66-4.74,1
		c-0.15,0.39-0.31,0.78-0.46,1.17c1.16,0.77,2.33,1.53,3.49,2.3l0,0c0.35-0.09,0.69-0.24,1.04-0.25c21.97-0.46,43.94-0.9,65.91-1.35
		c4.55-0.04,9.11-0.09,13.66-0.13c-0.56-0.45-1.09-1.22-1.7-1.29c-1.62-0.18-3.25-0.32-4.88-0.44c-1.63-0.12-3.26-0.23-4.89-0.35
		C615.95,314.98,613.48,314.6,611,314.54z"
          />
          <path
            className="st9"
            d="M650.92,313.68c3.06,5.41,24.05,21.94,27.97,22.44c-0.74-5.05-1.48-10.15-2.24-15.37
		C668.25,318.45,659.85,316.14,650.92,313.68z"
          />
          <path
            className="st2"
            d="M631.55,305.2c1.59-0.26,3.2-0.47,4.79-0.71c-10,0.47-19.99,1.07-30,1.39c-11,0.35-22.01,0.43-33.01,0.63
		c-0.25-0.02-0.5-0.03-0.75-0.05c-0.46,0-0.92,0-1.38,0c-3.91,0-7.83,0-11.74,0c-0.01,0.45-0.01,0.89-0.02,1.34
		c7.02,0.13,14.05,0.25,21.07,0.38c15.75-0.76,31.51-1.52,47.26-2.29C629.03,305.66,630.29,305.41,631.55,305.2z"
          />
          <path
            className="st2"
            d="M349.06,426.94c3.8,1.56,7.65,3.03,11.4,4.71c4.83,2.17,9.58,4.53,14.36,6.8c0.39-0.11,0.78-0.21,1.16-0.32
		c0.07-0.27,0.14-0.54,0.2-0.81c-11.44-5.11-22.88-10.22-34.32-15.32c0.74,0.93,1.48,1.86,2.22,2.78c1.66,0.72,3.32,1.45,4.99,2.17
		C349.07,426.95,349.07,426.94,349.06,426.94z"
          />
          <path
            className="st12"
            d="M374.82,438.45c-4.78-2.28-9.53-4.63-14.36-6.8c-3.75-1.69-7.59-3.15-11.4-4.71c0,0.01,0.01,0.01,0.01,0.02
		c0.01,0,0.02,0.01,0.03,0.01c-0.01,0-0.02-0.01-0.03-0.01c0.23,0.33,0.4,0.8,0.72,0.95c8.32,4.04,16.66,8.04,24.99,12.06
		c1.76,0.98,3.51,1.95,5.27,2.93c0.13-0.26,0.27-0.52,0.4-0.79c-1.72-0.88-3.44-1.77-5.16-2.65
		C375.14,439.12,374.98,438.79,374.82,438.45z"
          />
          <path
            className="st2"
            d="M351.21,420.58c4.55,2.61,9.11,5.22,13.66,7.82l0,0c0,0-0.05-0.03-0.05-0.03c2.86,1.01,5.73,2.02,8.59,3.04
		c0,0-0.04-0.06-0.04-0.06c0.01,0,0.01,0,0.02,0.01c-0.1-0.18-0.19-0.38-0.29-0.57c-0.11-0.2-0.22-0.38-0.39-0.47
		c-6.69-3.4-13.4-6.78-20.13-10.1C352.25,420.06,351.67,420.44,351.21,420.58z"
          />
          <path
            className="st12"
            d="M351.21,420.58c-0.68-0.09-1.35-0.17-2.03-0.26c-0.12,0.34-0.24,0.68-0.36,1.02
		c5.35,2.35,10.7,4.71,16.05,7.06C360.32,425.79,355.76,423.18,351.21,420.58z"
          />
          <path
            className="st12"
            d="M341.87,422c-2.45-0.82-4.9-1.63-7.35-2.45c-0.15,0.39-0.3,0.77-0.45,1.16c3.35,1.37,6.69,2.74,10.04,4.11
		c-0.01-0.01-0.02-0.02-0.03-0.03c-0.02-0.01-0.03-0.01-0.05-0.02c0.02,0.01,0.03,0.01,0.05,0.02
		C343.35,423.86,342.61,422.93,341.87,422z"
          />
          <path
            className="st12"
            d="M373.36,431.34c0,0,0.04,0.06,0.04,0.06c-2.86-1.01-5.73-2.02-8.59-3.04c3.81,1.89,7.62,3.78,11.44,5.67
		c0.16-0.3,0.32-0.61,0.48-0.91c-0.65-0.4-1.31-0.8-1.96-1.2c-0.47-0.19-0.93-0.38-1.4-0.57
		C373.37,431.35,373.37,431.34,373.36,431.34z"
          />
          <path
            className="st17"
            d="M349.07,426.95c-1.66-0.72-3.32-1.45-4.99-2.17"
          />
          <path
            className="st17"
            d="M344.04,424.76c0.02,0.01,0.03,0.01,0.05,0.02"
          />
          <path
            className="st17"
            d="M349.07,426.95c0.01,0,0.02,0.01,0.03,0.01"
          />
          <path className="st27" d="M373.38,431.35c-0.01,0-0.01,0-0.02-0.01" />
          <path
            className="st27"
            d="M374.78,431.92c-0.47-0.19-0.93-0.38-1.4-0.57"
          />
          <path
            className="st2"
            d="M86.8,837c0.71,3.2,2.68,4.75,5.46,4.11c2.33-0.54,3.48-3.28,2.46-6.2c-0.78-2.25-0.71-4.12,1.01-6.07
		c1.6-1.81,2.96-3.92,3.98-6.11c1.37-2.91,2.31-6.01,3.01-7.88c-0.47-6.95-2.36-11.72-7.62-14.42c-1.56-0.8-3.98-1.83-5.05-1.18
		c-1.36,0.83-2.33,3.23-2.42,5c-0.29,5.82-0.13,11.67-0.1,17.51c0.01,1.47,0.76,3.25,0.18,4.36C85.84,829.71,85.97,833.26,86.8,837z
		 M92.2,803.11c0.37,0.23,0.73,0.46,1.1,0.7c7.44,2.74,6.67,12.49,3.84,17.25c-1.53,2.58-3.38,4.97-5.6,7.32
		c0.49-1.52,0.7-3.23,1.53-4.54c4.4-7.01,4.36-12.81-0.34-19.53c-0.26-0.28-0.53-0.57-0.79-0.85
		C92.02,803.35,92.11,803.23,92.2,803.11z M91.19,809.36c1.92,3.28,1.79,6.56,0,10.86C91.19,815.93,91.19,812.64,91.19,809.36z"
          />
          <path
            className="st10"
            d="M536.82,335.2c0.23,3.23,2.24,4.93,5.85,5.75c0.95-7.02-1.41-13.38-2.33-19.87c-1.42,0-2.84,0-4.25,0
		C536.32,325.79,536.48,330.5,536.82,335.2z"
          />
          <path
            className="st2"
            d="M168.35,560.5c-1.7-0.14-3.95,1.45-5.23,2.9c-1.01,1.14-0.69,3.36-1.37,4.91c-1.93,4.39-1.5,5.83,3.33,7.93
		c2.43-2.17,5.31-4.29,7.57-6.95c1.16-1.37,2.21-4.37,1.51-5.43C173,562.15,170.44,560.67,168.35,560.5z M166.9,569.51
		c-0.43-0.21-0.85-0.42-1.28-0.64c-0.09-1.63-0.17-3.26-0.28-5.33c2.78,0.37,4.69,0.62,6.59,0.88c0.13,0.36,0.27,0.71,0.4,1.07
		C170.52,566.83,168.71,568.17,166.9,569.51z"
          />
          <path
            className="st2"
            d="M82.6,588.79c-2.78,0.01-5.17,2.83-5.11,6.02c0.07,3.14,2.98,6.21,5.98,6.29c3.31,0.09,5.49-2.75,5.48-7.18
		C88.95,590.26,87.11,588.77,82.6,588.79z M83.64,598.66c-1.59-1.1-2.88-1.98-4.08-2.8c1.52-4.5,3.75-5.23,7.32-2.21
		C85.85,595.25,84.85,596.78,83.64,598.66z"
          />
          <path
            className="st2"
            d="M393.95,681.71c1.18,0.75,2.36,1.5,3.53,2.26c0.17-0.25,0.33-0.5,0.5-0.75c-1.12-0.8-2.23-1.6-3.35-2.41
		C394.41,681.11,394.18,681.41,393.95,681.71z"
          />
          <path
            className="st2"
            d="M401.41,686.31c-0.91-0.77-1.81-1.55-2.72-2.32c-0.23,0.31-0.45,0.62-0.68,0.93c0.92,0.64,1.84,1.27,2.76,1.91
		l0,0C400.98,686.66,401.2,686.48,401.41,686.31L401.41,686.31z"
          />
          <path
            className="st2"
            d="M393.41,680.73c0.13-0.27,0.27-0.54,0.4-0.81c-0.57-0.26-1.14-0.52-1.72-0.78c-0.55-0.36-1.11-0.73-1.66-1.09
		c-0.2,0.16-0.39,0.33-0.59,0.49c0.55,0.36,1.11,0.73,1.66,1.09C392.13,680,392.77,680.36,393.41,680.73z"
          />
          <path
            className="st4"
            d="M501.82,805.33c-0.33,0.18-0.66,0.36-0.99,0.54c-1.82,1.01-3.64,2.03-5.68,3.17c0.81,0.77,1.36,1.29,2.11,2
		c1.42-1.68,2.69-3.19,3.96-4.71c0,0,0,0,0,0c0.28-0.2,0.57-0.41,0.86-0.61C501.99,805.6,501.9,805.46,501.82,805.33z"
          />
          <path
            className="st2"
            d="M1022.28,283.23c-0.53-0.79-1.06-1.59-1.58-2.38c-0.18,0.13-0.37,0.27-0.55,0.4c0.5,0.8,1.01,1.61,1.51,2.41
		C1021.86,283.52,1022.07,283.38,1022.28,283.23z"
          />
          <path
            className="st2"
            d="M1019.97,280.52c-0.22-0.57-0.44-1.15-0.66-1.72c-0.19,0.07-0.38,0.13-0.57,0.2c0.22,0.57,0.44,1.15,0.66,1.72
		C1019.59,280.65,1019.78,280.59,1019.97,280.52z"
          />
          <path
            className="st2"
            d="M1023.22,284.75c-0.09-0.35-0.18-0.69-0.27-1.04c-0.17,0.04-0.33,0.09-0.5,0.13c0.1,0.34,0.19,0.69,0.29,1.03
		C1022.89,284.83,1023.05,284.79,1023.22,284.75z"
          />
          <path
            className="st2"
            d="M1014.62,283.31c-0.09-0.35-0.18-0.69-0.27-1.04c-0.17,0.04-0.33,0.09-0.5,0.13c0.1,0.34,0.19,0.69,0.29,1.03
		C1014.29,283.4,1014.46,283.36,1014.62,283.31z"
          />
          <path
            className="st2"
            d="M1127.63,434.56c0.45,1.07,0.62,2.5,1.41,3.15c2.81,2.3,5.7,4.55,8.8,6.43c4.52,2.75,9.23,5.18,13.9,7.68
		c0.49,0.26,1.24,0.04,1.87,0.04c0.09-0.2,0.19-0.41,0.28-0.61c-8.46-5.67-16.92-11.34-25.38-17.01
		C1128.22,434.34,1127.93,434.45,1127.63,434.56z"
          />
          <path
            className="st2"
            d="M1156.04,439.3c1.67-4.35-0.26-9.19-4.65-10.96c-2.59,6.05,0.33,10.87,3.87,16.74
		C1155.59,442.46,1155.48,440.75,1156.04,439.3z"
          />
          <path
            className="st2"
            d="M1126.01,486.89c3.54-1.91,5.21-23.66,2.71-27.37C1127.79,468.96,1126.9,477.92,1126.01,486.89z"
          />
          <path
            className="st2"
            d="M1143.1,464.36c-0.44,0.03-0.87,0.05-1.31,0.08c0,8.02,0,16.03,0,24.05c0.34,0.14,0.68,0.27,1.02,0.41
		c0.53-1.31,1.51-2.63,1.5-3.93c-0.05-5.79-0.37-11.58-0.65-17.37C1143.6,466.51,1143.29,465.44,1143.1,464.36z"
          />
          <path
            className="st2"
            d="M1150.08,457.67c-7.27-5.2-13.9-9.94-20.54-14.69C1129.17,446.15,1145.54,457.27,1150.08,457.67z"
          />
          <path
            className="st2"
            d="M1136.39,479.63c0.65-3.09,0.56-6.35,0.54-9.54c-0.01-3.04-0.34-6.08-0.53-9.13
		c-0.28,0.01-0.57,0.01-0.85,0.02c0,8.92,0,17.84,0,27.2C1139.16,485.56,1135.82,482.34,1136.39,479.63z"
          />
          <path
            className="st2"
            d="M1129.57,426.51c1.31-1.15,2.62-2.3,4.22-3.71c-2.61-3.18-3.88-4.74-5.16-6.3c-1.29,0.73-2.06,1.18-3.47,1.98
		c0.83,2.43,1.66,4.9,2.5,7.37C1128.3,426.06,1128.94,426.29,1129.57,426.51z"
          />
          <path
            className="st2"
            d="M1129.98,450.28c-0.38,0.53-0.76,1.06-1.14,1.59c4.15,2.68,8.29,5.38,12.5,7.98c0.23,0.15,1-0.56,2.39-1.4
		C1138.74,455.49,1134.36,452.88,1129.98,450.28z"
          />
          <path
            className="st2"
            d="M1145.77,487.72c2.52,4.45,7.57,6.17,9.77,3.34C1151.09,487.1,1147.87,486,1145.77,487.72z"
          />
          <path
            className="st2"
            d="M1147.38,467.2c-0.14,4.49,1.96,5.94,7.94,4.65C1152.71,470.32,1150.1,468.79,1147.38,467.2z"
          />
          <path
            className="st2"
            d="M1146.19,479.94c1.22,0.83,2.44,1.66,3.73,2.54c0.95-0.81,1.64-1.4,3.09-2.64c-2.58-0.54-4.44-0.92-6.29-1.31
		C1146.55,479.01,1146.37,479.47,1146.19,479.94z"
          />
          <path
            className="st2"
            d="M1145.8,484.59c2.62,0.38,4.48,3.58,7.59,2.13c0.09-0.44,0.17-0.88,0.26-1.32c-2.51-0.67-5.03-1.34-7.54-2.01
		C1146,483.79,1145.9,484.19,1145.8,484.59z"
          />
          <path
            className="st2"
            d="M1152.7,477.07c-1.83-1.53-3.45-2.88-5.82-4.86C1147.71,477.03,1149.21,478.15,1152.7,477.07z"
          />
          <path
            className="st2"
            d="M212.18,473.82c0.31-3.53-0.9-7.23,1.85-10.48c0.73-0.86,0.11-2.86,0.11-4.22c-3.82-1.77-5.21,0.94-6.55,2.32
		c1.24,6.4,2.31,11.95,3.38,17.5C211.73,477.24,212.03,475.53,212.18,473.82z"
          />
          <path
            className="st2"
            d="M194.83,734.47c-3.39-2.43-7.16-2.12-9.47,1.33c-0.62,0.93-1.24,1.86-2.45,3.67
		c-0.15-2.39-0.23-3.64-0.33-5.35c-2.56,0.4-4.82,0.75-6.82,1.07c-0.98,2.14-2.23,3.78-2.45,5.55c-0.42,3.39-1.38,7.36-0.1,10.19
		c2.96,6.54,8.88,9.56,16.08,9.98c3.81,0.22,6.46-1.51,8.75-4.4C202.36,751.06,201.11,738.97,194.83,734.47z M190.42,756.81
		c-2.95-3.49-9.01-0.95-11.15-6.2c-1.7-4.17-2.72-8.09-0.68-13.07c0.81,1.26,1.75,2.12,1.94,3.13c0.46,2.45,1.02,5.2,3.9,5.01
		c3.01-0.2,4.09-3.13,4.76-5.79c0.25-0.99,0.04-2.1,0.04-3.13c4.24-0.13,6.97,2.14,7.67,7.57
		C197.67,750.25,198.32,753.26,190.42,756.81z"
          />
          <path
            className="st2"
            d="M476.53,362.4c-0.01,0.01-0.02,0.02-0.02,0.03c1.13-0.85,2.25-1.7,3.51-2.65c0.31,4.02,0.56,7.81,0.91,11.59
		c0.08,0.84,0.49,1.65,0.77,2.47c0.7-0.91,1.98-1.82,1.96-2.71c-0.08-3.93-0.34-7.86-0.6-11.8c-0.09-1.31-0.17-2.62-0.25-3.93v0
		c-0.17-2.03-0.35-4.07-0.52-6.09s-0.35-4.03-0.52-6.01c-11.72-0.58-18.19,7.79-13.73,16.84c0.27,0.3,0.55,0.6,0.82,0.91
		c0.2,0.2,0.4,0.4,0.6,0.6C471.82,361.9,474.17,362.15,476.53,362.4z M470.83,350.92c2.17-1.59,4.33-3.18,6.5-4.77
		c0.8-0.36,1.59-0.73,2.69-1.23c0,4.18,0,7.91,0,12.1c-2.83,0.8-5.77,1.62-8.65,2.43C469.55,356.19,468.29,353.62,470.83,350.92z"
          />
          <path
            className="st2"
            d="M482.23,401.87c1.45-1.2,2.9-2.39,4.35-3.59c0.98-3.38,1.78-6.66-0.78-9.86c-0.68-0.85-0.49-2.43-0.61-3.68
		c-0.26-2.79-1.39-4.51-4.55-4.34c-2.84,0.15-5.69-0.06-8.53,0.07c-1.17,0.05-2.31,0.57-3.81,0.97c0.58,1.14,0.93,1.83,1.28,2.52
		l0.3-0.01l0.28,0.12c0.46,0.02,0.91,0.04,1.37,0.06h0c1.66-0.12,3.31-0.61,4.96-0.99c2.12-0.5,4.26-0.82,6.45,0.04
		c0.21,0.42,0.43,0.84,0.64,1.26c-1.83,1.29-3.66,2.59-5.49,3.88c-0.03,0.01-0.06,0.02-0.1,0.03c-1.59,0.55-3.19,1.1-4.79,1.65
		c0,0,0,0,0,0c-0.01-0.01-0.1-0.12-0.1-0.12c-0.49,0.36-0.98,0.71-1.56,1.13c0.9,2.51,2.87,2.54,5.01,2.19
		c2.41-0.66,4.83-1.32,7.73-2.12c0.09,2.1,0.16,3.56,0.24,5.33c-4.84,1.91-9.54,3.77-14.25,5.63c0.01,0.24,0.01,0.49,0.02,0.73
		c0.91,0.25,1.82,0.5,2.73,0.74C476.1,402.97,479.16,402.42,482.23,401.87z"
          />
          <path
            className="st2"
            d="M477.42,339.74c6.37-4.51,10.03-12.64,5.59-20.85c-0.49-0.49-1.04-0.93-1.47-1.46
		c-3.93-4.95-9.71-7.44-14.86-1.33c-6.25,7.41-5.83,18.96,1.57,24.3C471.39,341.34,474.38,341.9,477.42,339.74z M464.5,329.84
		c-0.7-4.67,1.46-8.31,3.9-11.71c2.11-2.93,5.48-2.51,8.48-2.02c2.78,0.45,5.85,4.91,6.19,7.84c0.68,6-1.69,10.71-6.6,13.71
		c-1.82,1.11-4.99,1.02-7.16,0.3C465.68,336.75,463.6,333.89,464.5,329.84z"
          />
          <path
            className="st10"
            d="M472.1,380.47c2.84-0.13,5.7,0.07,8.53-0.07c3.17-0.16,4.3,1.55,4.55,4.34c0.12,1.25-0.07,2.83,0.61,3.68
		c2.56,3.2,1.76,6.48,0.78,9.86c0.91-0.42,2.32-0.6,2.66-1.3c1.68-3.42,0.24-8.51-2.85-11c0.08-1.06,0.21-2.11,0.23-3.17
		c0.1-4.24-1.05-5.52-5.18-5.97c-5.62-0.61-10.41,1.27-15.53,5.27c1.55,0.78,2.61,1.32,3.67,1.85c-0.35-0.69-0.7-1.39-1.28-2.52
		C469.79,381.05,470.94,380.53,472.1,380.47z"
          />
          <path
            className="st10"
            d="M483.35,356.06c-0.18-0.22-0.39-0.44-0.55-0.66c0,0,0,0,0,0c0.08,1.31,0.16,2.62,0.25,3.93
		c0.26,3.93,0.51,7.86,0.6,11.8c0.02,0.89-1.26,1.81-1.96,2.71c-0.28-0.82-0.69-1.63-0.77-2.47c-0.36-3.78-0.61-7.57-0.91-11.59
		c-1.25,0.95-2.38,1.8-3.51,2.65c0.01-0.01,0.02-0.02,0.02-0.03c-2.35-0.25-4.71-0.5-7.06-0.75c0.88,1.56,1.95,2.51,3.05,2.81
		c0.31,0.09,0.63,0.12,0.94,0.1c0.79-0.05,1.56-0.43,2.27-1.14c0.27-0.27,0.53-0.6,0.77-0.96c0.36-0.01,0.66,0.02,0.91,0.07
		c1.8,0.39,1.28,2.2,1.33,3.64c0.02,0.55,0.01,1.11,0.01,1.67c0,0.37-0.01,0.75,0,1.11s0.03,0.73,0.07,1.08
		c0.04,0.35,0.11,0.7,0.21,1.03c0.05,0.17,0.11,0.33,0.17,0.49c0.4,0.95,1.14,1.77,2.5,2.35c0.39,0.75,0.78,1.49,1.17,2.24
		c0.36-0.03,0.71-0.07,1.07-0.1c0.65-1.82,1.96-3.68,1.83-5.44c-0.34-4.64-1.28-9.24-2.04-13.85
		C483.68,356.51,483.53,356.28,483.35,356.06z"
          />
          <path
            className="st10"
            d="M471.55,391.01c0.57-0.41,1.06-0.77,1.56-1.13c-1.47,0.4-2.95,0.8-4.37,1.19c1.09,5.35,3.24,5.76,7.83,2.13
		C474.42,393.56,472.45,393.52,471.55,391.01z"
          />
          <path
            className="st21"
            d="M478.1,388.33c-0.03,0.01-0.06,0.02-0.1,0.03"
          />
          <path
            className="st21"
            d="M473.21,390.01c1.6-0.55,3.19-1.1,4.79-1.65"
          />
          <line
            className="st21"
            x1="471.53"
            y1="384.14"
            x2="471.53"
            y2="384.14"
          />
          <path
            className="st21"
            d="M471.53,384.14c-0.46-0.02-0.91-0.04-1.37-0.06"
          />
          <path
            className="st21"
            d="M468.04,360.14c0.27,0.3,0.55,0.6,0.82,0.91"
          />
          <path
            className="st2"
            d="M270.82,544.12c-0.62-4.68-1.27-9.61-2-15.1C265.5,534.57,267.16,541.84,270.82,544.12z"
          />
          <path
            className="st2"
            d="M281.28,510.81c-4.03,2-4.17,8.64,0,13.31C281.28,519.36,281.28,515.13,281.28,510.81z"
          />
          <path
            className="st2"
            d="M276.74,601.84c2.87,3.55,8.12,1.22,11.78,4.95C286.72,602.21,283.38,600.84,276.74,601.84z"
          />
          <path
            className="st2"
            d="M272.53,592.36c-1.14,0.13-2.32,0.43-3.51,0.79c1.12,0.75,2.32,1.25,3.57,1.47c2.05,0.36,4.13,0.55,6.19,0.81
		c0.37,0.23,0.73,0.46,1.1,0.69c0.09-0.12,0.18-0.23,0.26-0.35c-0.26-0.28-0.53-0.57-0.79-0.85c-1.26-1.5-2.66-2.25-4.16-2.52
		c-0.43-0.08-0.87-0.11-1.31-0.12C273.43,592.28,272.98,592.31,272.53,592.36z"
          />
          <path
            className="st2"
            d="M259.55,490.81c3.21,1.84,5.93,3.4,9.38,5.37C266.65,491.65,263.53,489.82,259.55,490.81z"
          />
          <path
            className="st2"
            d="M336.1,486.33c3.45,0.26,4.93-2.77,6.03-5.56c0.46-1.16,0.43-2.51,0.46-2.73c-0.01-5.76-2.07-8.89-6.44-10.52
		c-2.25-0.84-5.1-1.03-5.53,1.76c-0.55,3.6-0.2,7.43,0.34,11.07C331.39,483.17,332.96,486.08,336.1,486.33z M333.27,469.98
		c3.95,0.78,6.77,2.77,6.97,6.29c0.13,2.27-1.66,4.65-2.68,7.21C331.79,479.69,333.63,474.77,333.27,469.98z"
          />
          <path
            className="st2"
            d="M359.84,478.25c1.02-1.82,2.3-3.51,3.12-5.41c1.49-3.41,0.45-4.96-5.1-8.35c1.47-2.37,2.21-5.14-0.01-7.03
		c-1.15-0.98-3.69-0.49-5.57-0.3c-0.48,0.05-0.8,1.53-1.29,2.53c2.36,0.21,4.02,0.35,6.18,0.54c-1.48,2.09-2.56,3.61-4.11,5.81
		c2.6,1.25,5.04,2.43,8.4,4.05c-1.59,1.89-2.78,3.59-4.25,4.99c-1.66,1.58-2.73,2.99-0.68,4.77c1.21-0.21,2.23-0.39,3.25-0.57
		C359.79,478.95,359.69,478.53,359.84,478.25z"
          />
          <path
            className="st2"
            d="M350.13,471.98c1.17,3.33-1,4.15-3.47,4.97c0.4,1.13,0.75,2.14,1.11,3.16c4.05-0.47,5.21-2.93,5.58-6.1
		c0.38-3.29-0.97-5.34-4.12-6.44c-1.69-0.59-4.19-0.72-3.64-3.56c1.3-0.51,2.41-0.94,4.62-1.8c-1.32-1.84-2.14-2.97-2.95-4.1
		c-0.67,0.29-1.33,0.59-2,0.88c-1.11,2.21-3.24,4.46-3.13,6.61C342.35,470.15,347.22,470.31,350.13,471.98z"
          />
          <path
            className="st2"
            d="M320.87,498.35c0.6-0.62,0.37-2.06,0.61-3.76c-2.08,0.98-3.11,1.47-4.09,1.93
		c-5.29-4.76-6.29-10.41-2.96-16.19c0.94,0.77,1.89,1.55,3.13,2.57c-0.02-3.24-0.53-5.46-4.63-4.84c-0.85,2.36-2.01,4.47-2.36,6.7
		c-0.88,5.62,1.4,10.37,5.09,14.4C317.49,501.18,319.47,499.8,320.87,498.35z"
          />
          <path
            className="st2"
            d="M331.41,490.56c-1.94-2.14-1.94-2.14-5.92,0.62c-1.42-5.87-2.74-11.33-4.06-16.8
		c-0.39,0.11-0.78,0.22-1.18,0.33c0.83,6.5,0.28,13.36,4.85,19.17C327.45,492.65,329.38,491.64,331.41,490.56z"
          />
          <path
            className="st2"
            d="M652.25,387.73c-0.91,1.16-1.77,2.25-2.63,3.35c0,0,0,0,0,0c-0.2-0.34-0.4-0.69-0.85-1.47
		c-0.06-0.11-0.13-0.23-0.21-0.36c-1.57,3.05-2.95,5.74-4.33,8.42c0.21,0.12,0.43,0.24,0.64,0.35c-0.81,0.43-1.63,0.86-2.44,1.29
		l0,0c0.15,0.16,0.29,0.33,0.43,0.5c2.95,0.78,5.9,1.56,8.84,2.34c0.05-0.19,0.11-0.38,0.16-0.57c-2.09-0.79-4.19-1.58-6.28-2.37
		c0.08-0.28,0.15-0.57,0.23-0.85c2.47,0.27,4.94,0.53,7.41,0.8c0.05-0.26,0.09-0.51,0.14-0.77c-1.72-0.37-3.44-0.73-5.06-1.08
		C651.38,395.68,653.79,390.27,652.25,387.73z"
          />
          <path
            className="st2"
            d="M642.43,399.3c-0.39,0.22-0.78,0.45-1.18,0.67c-0.69,0.39-1.38,0.78-2.07,1.18l0,0
		c-0.58,0.29-1.15,0.58-1.73,0.87c0.14,0.25,0.27,0.5,0.41,0.76c0.6-0.34,1.2-0.68,1.8-1.02l0,0c0.26,0.94,0.99,2.06,0.71,2.8
		c-0.27,0.72-0.57,1.42-0.88,2.13c-0.92,2.11-1.97,4.17-2.94,6.26c-0.35,0.6-0.69,1.2-1.04,1.8c0.25,0.14,0.51,0.28,0.76,0.42
		c0.29-0.57,0.58-1.14,0.87-1.72c1.41-3.42,2.82-6.85,4.25-10.27c0.47-1.13,0.97-2.24,1.46-3.36
		C642.72,399.63,642.58,399.47,642.43,399.3L642.43,399.3z"
          />
          <path
            className="st2"
            d="M637.44,403.26c-0.14-0.25-0.27-0.51-0.41-0.76c-0.6,0.34-1.2,0.69-1.8,1.03c-0.41-0.42-0.81-0.84-1.21-1.25
		s-0.79-0.82-1.17-1.21c0,2.69,0,5.53,0,8.79c1.08-2.16,1.97-3.95,2.87-5.73C636.29,403.84,636.86,403.55,637.44,403.26z"
          />
          <path
            className="st2"
            d="M634.21,417.98c0.24-0.59,0.48-1.18,0.72-1.77c-0.29-0.06-0.58-0.13-0.87-0.19c-0.15,0.59-0.29,1.18-0.44,1.77
		C633.82,417.85,634.01,417.92,634.21,417.98z"
          />
          <path
            className="st2"
            d="M639.84,398.02c-0.08-0.36-0.16-0.72-0.23-1.09c-0.22,0.09-0.43,0.18-0.65,0.26c0.16,0.32,0.31,0.63,0.47,0.95
		C639.56,398.1,639.7,398.06,639.84,398.02z"
          />
          <path
            className="st10"
            d="M547.39,277.41c4.04,3.09,7.98,2.66,10.26-1.48c2.61,2.75,5.11,5.39,7.69,8.1c-1.65,1.48-2.58,2.32-3.86,3.47
		c0.99,1.16,1.84,2.17,2.76,3.25c2.84-1.43,4.72-3.18,4.79-6.31c4.11,3.12,6.88,3.22,9.99,0.37c2.84-2.61,2.95-5.68,0.14-9.91
		c-3.42-5.16-7.32-9.89-13.01-12.75c-3.37-0.92-6.43-0.8-8.65,2.24c3.21,8.13,9.71,11.71,14.97,8.22c1.53,2.31,3.06,4.62,5.15,7.79
		c-2.25,0.38-3.61,0.83-4.96,0.78c-1.2-0.05-2.19,0.06-2.85,0.53c-0.64,0.46-0.94,1.27-0.82,2.61c0,0,0,0,0,0
		c-0.5-1.26-0.73-2.76-1.58-3.71c-5.19-5.86-10.8-11.24-17.79-14.03c-1.56,0.42-2.68,1.04-3.46,1.81c-1.72,1.69-1.81,4.09-1.47,6.72
		C545.6,275.87,546.46,276.69,547.39,277.41z M561.82,265.78c4.06-2.27,4.25,2.9,6.75,3.28
		C565.19,270.4,563.71,268.03,561.82,265.78z M548.28,269.35c1.94,2.19,3.47,3.92,5,5.66c-0.27,0.35-0.54,0.7-0.81,1.06
		c-1.87-0.94-3.74-1.88-5.82-2.93C547.22,271.83,547.56,271.02,548.28,269.35z"
          />
          <path
            className="st2"
            d="M930.41,252.7c0.29,0.27,0.57,0.53,0.86,0.8c0.12-0.09,0.23-0.18,0.35-0.26c-0.23-0.37-0.46-0.74-0.69-1.11
		c-1.07-1.56-2.17-3.09-3.32-4.57c-0.38-0.49-0.77-0.98-1.16-1.46c-1.17-1.45-2.4-2.84-3.67-4.16c-3.84-3.98-8.2-7.37-13.41-9.79
		c-0.3,0.33-0.6,0.66-0.9,0.99C915.78,239.65,923.09,246.17,930.41,252.7C930.41,252.7,930.41,252.7,930.41,252.7z"
          />
          <path className="st27" d="M892.87,233.48c0.01,0,0.02,0,0.03,0" />
          <path
            className="st27"
            d="M895.03,233.53c-0.71-0.02-1.42-0.04-2.13-0.05"
          />
          <path
            className="st2"
            d="M895.47,235.32c0.16,0.3,0.33,0.6,0.49,0.9c0.11-0.06,0.22-0.11,0.33-0.17c-0.15-0.3-0.3-0.61-0.46-0.91
		C895.71,235.2,895.59,235.26,895.47,235.32z"
          />
          <path
            className="st2"
            d="M339.83,839.56c0.88,0.17,2.44-0.89,3.02-1.8c2.03-3.2,0.76-7.89-2.87-10.11c-5.83-3.57-11.69-7.31-19.76-5.24
		c0.26,1.32,0.25,2.91,0.87,4.19C324.86,834.44,331.75,837.97,339.83,839.56z M333.5,828.79c2.87,1.5,6.27,2.01,6.16,6.81
		c-2.83-0.92-5.28-1.71-8.36-2.71C332.22,831.18,332.8,830.09,333.5,828.79z"
          />
          <path
            className="st2"
            d="M1110.56,426.71c-0.68,3.02-1.46,5.22-1.53,7.44c-0.03,0.79,1.64,1.63,2.95,2.83
		c0.3,3.85,0.67,8.53,1.04,13.22c0.84-1.78,1.19-3.59,1.24-5.4c0.11-3.81-0.53-7.75,0.26-11.38
		C1115.22,430.21,1113.98,428.95,1110.56,426.71z"
          />
          <path
            className="st2"
            d="M770.71,384.03c1.13,0.6,3.74,0.47,4.3-0.31c1.26-1.75,2.84-5.42,2.2-6c-1.84-1.66-4.72-2.18-7.53-3.28
		C768.4,378.72,766.9,381.99,770.71,384.03z"
          />
          <path
            className="st2"
            d="M769.17,167.33c-4.87,0.21-9.72,1.05-14.58,1.62c-9.38,1.25-18.45,3.45-26.48,8.76
		c-0.1,0.39-0.2,0.78-0.31,1.19c-0.11,0.41-0.22,0.85-0.34,1.32c1.13,0.45,2.27,0.91,3.19,1.27c-0.62,3.5-1.5,6.92-1.79,10.38
		c-0.91,10.66,1.91,20.56,7.09,29.75c1.1,1.96,3.3,3.99,5.39,4.56c3.02,0.84,6.2,1.1,9.37,1.41c1.06,0.1,2.11,0.21,3.16,0.34
		c8.84,0,17.68,0,26.51,0c4.98-0.28,9.96-0.6,14.94-0.82c3.84-0.17,6.98-1.65,8.68-5.13c1.45-2.95,2.73-6.05,3.56-9.22
		c2.65-10.03,3.55-20.23,1.43-30.45c-1.17-5.64-2.64-11.39-9.31-13.34c-3.81-0.55-7.6-1.41-11.42-1.58
		C781.91,167.11,775.52,167.06,769.17,167.33z M801.14,219.15c-1.22,2.81-2.79,3.06-7.51,2.86c0,0,0,0,0,0c0,0,0,0,0,0
		c2.94-2.23,5.88-4.46,8.71-6.61C801.91,216.99,801.55,218.21,801.14,219.15z M802.95,180.58c-2.26-2.63-4.41-5.12-6.58-7.66
		C801.67,171.89,801.14,173.51,802.95,180.58z M794.07,174.63c1.1,0.58,2.13,1.26,3.08,2.04c1.9,1.57,3.47,3.55,4.6,6.03
		c4.74,10.39,4.63,20.93-2.01,30.76c-3.59,5.32-9.24,7.45-15.06,9.22c-8.35,0-16.7,0-25.06,0c-0.51,0.1-1.02,0.18-1.51,0.24
		c-0.49,0.06-0.98,0.1-1.46,0.11c-1.9,0.07-3.66-0.18-5.3-0.7c-2.86-0.91-5.36-2.64-7.62-4.89c-0.65-0.64-1.27-1.33-1.88-2.05
		c-0.3-0.36-0.61-0.73-0.9-1.11c-0.42-0.61-0.83-1.23-1.25-1.84c-0.55-0.81-1.1-1.62-1.65-2.43v0c-0.24-0.31-0.47-0.62-0.69-0.94
		c-1.7-2.49-2.75-5.31-3.23-8.25c-0.02-0.12-0.04-0.23-0.06-0.35c-0.1-0.67-0.17-1.35-0.21-2.04c0-0.04-0.01-0.09-0.01-0.13
		c-0.15-2.66,0.14-5.36,0.81-7.94c0.19-0.74,0.41-1.47,0.66-2.19c0.25-0.72,0.53-1.43,0.84-2.11c1.54-3.45,3.79-6.47,6.56-8.62
		c3.14-2.43,6.35-2.56,9.71-3.23c9.63-0.99,19.26-2.02,28.91-2.91c0.66-0.06,1.34,0.07,2.02,0.24c0.68,0.18,1.37,0.4,2.05,0.54
		c2.69,0.34,5.24,0.99,7.53,2.02C793.33,174.26,793.7,174.44,794.07,174.63z"
          />
          <path
            className="st2"
            d="M1040.71,308.7c-1.72-0.88-3.16-1.61-4.49-2.29c-0.8-0.41-1.56-0.79-2.32-1.18c0,0,0,0,0,0
		c-6.97-0.25-13.97-0.28-20.92-0.83c-5.43-0.42-6.29-2.46-3.62-7.07c-1.44,0.37-3.22,0.8-3.78,1.88c-0.53,1.01-0.2,3.01,0.52,3.99
		c0.9,1.21,2.51,2.36,3.97,2.6c1.15,0.2,2.31,0.36,3.47,0.51c3.49,0.45,7,0.75,10.49,1.13
		C1029.33,307.86,1034.62,308.25,1040.71,308.7z"
          />
          <path
            className="st25"
            d="M573.33,306.51c-0.25-0.02-0.5-0.03-0.75-0.05"
          />
          <path
            className="st2"
            d="M337.99,397.58c3.59-0.44,6.24-4.71,5.13-9.64c-1.35,3.18-2.53,6.51-6.29,6.69c-1.22-0.47-2.23-0.87-3.66-1.42
		C333.71,396.44,335.19,397.92,337.99,397.58z"
          />
          <path
            className="st2"
            d="M293.27,799.63c-1.55-0.42-3.33,0.05-5.8,0.15c3.68,6.91,6.85,12.75,13.41,15.37
		c1.82,0.73,5.24,0.94,6.02-0.08c1.1-1.42,1.24-4.65,0.34-6.35C304.37,803.27,298.88,801.14,293.27,799.63z M295.94,808.48
		c-1.45-1.58-3.15-3.43-4.85-5.29c0.2-0.35,0.4-0.71,0.6-1.06c2.54,0.79,5.09,1.58,7.51,2.34
		C298.06,805.86,297.11,807.03,295.94,808.48z M304.53,813.28c-1.78-0.65-3.27-1.2-4.74-1.74c0.44-1.93,0.83-3.65,1.28-5.63
		C303.14,807.92,306.61,809.02,304.53,813.28z"
          />
          <path
            className="st10"
            d="M621.77,227.41c4.25,7.73,8.09,15.66,9.48,24.46c0.74,4.71-0.09,9.16-4.5,12.78
		c-1.89-2.38-3.61-4.56-5.48-6.92c-1,0.5-1.89,0.96-2.78,1.4c0.12,3.99,2.64,6.26,5.07,8.5c2.76,2.54,5.47,2.29,7.55-0.77
		c2.58-3.78,3.81-7.74,3.25-12.63c-1.38-12.05-4.95-23.12-12.27-32.88c-1.04-1.13-1.99-2.37-3.15-3.38
		c-3.82-3.31-6.57-3.24-9.59,0.81c-2.5,3.35-4.4,7.15-6.77,11.07c1.33,1.31,2.14,2.1,2.8,2.74c3.15-4.35,6.01-8.31,9.5-13.14
		c0.24,0.33,0.47,0.64,0.69,0.94c1.54,2.11,2.64,3.6,3.73,5.09c0,0,0,0,0,0C620.15,226.13,621.31,226.58,621.77,227.41z"
          />
          <path
            className="st2"
            d="M788.23,337.89c1.7,1.85,3.3,3.79,4.94,5.69c0.41-0.33,0.82-0.66,1.23-0.99c-1.37-2.46-2.74-4.92-4.11-7.38
		c-0.41-0.17-0.82-0.35-1.23-0.52C787.9,335.52,786.77,336.3,788.23,337.89z"
          />
          <path
            className="st2"
            d="M772.92,332.58c-0.25,0.19-0.43,0.45-0.55,0.75c-0.18,0.44-0.23,0.96-0.22,1.37c0.06,1.66,0.85,3.3,1.57,5.79
		c0.69-0.92,1.09-1.44,1.36-1.81c0.17-0.22,0.29-0.39,0.42-0.55c0,0,0,0,0,0c-0.29-1.9-0.58-3.8-0.87-5.7
		C773.84,332.2,773.3,332.3,772.92,332.58z"
          />
          <path
            className="st2"
            d="M779.72,340.31c-0.52-2.35-1.04-4.7-1.57-7.05c-0.41,0-0.83,0-1.24-0.01c0.18,1.63,0.35,3.26,0.53,4.89
		C778.2,338.87,778.96,339.59,779.72,340.31z"
          />
          <path
            className="st12"
            d="M776.91,333.26c-0.76-0.28-1.52-0.55-2.29-0.83c0.29,1.9,0.58,3.8,0.87,5.7c0.65,0,1.29,0.01,1.94,0.01
		C777.26,336.51,777.09,334.88,776.91,333.26z"
          />
          <path
            className="st2"
            d="M408.01,852.86c-2.83,1.83-4.54,6.82-3.11,9.45c0.54,0.99,1.47,1.77,2.45,2.92c0.41-1.59,0.65-2.49,0.75-2.87
		c1.56,0.75,2.91,1.39,4.66,2.22c-0.13-1.44-0.19-2.01-0.25-2.67c4.8,0.54,6.54-2.61,8.22-5.88
		C417.15,852.09,411.36,850.69,408.01,852.86z M409.73,859.05c-0.14-0.69-0.29-1.37-0.43-2.06c2.29-0.34,4.57-0.68,6.86-1.03
		c0.09,0.48,0.19,0.96,0.28,1.45C414.2,857.96,411.97,858.51,409.73,859.05z"
          />
          <path
            className="st2"
            d="M389.41,852.65c-1.91,2.69-1.84,5.24,0.43,8.19C391.92,857.06,391.72,854.19,389.41,852.65z"
          />
          <path
            className="st7"
            d="M754.59,168.95c0.01-0.67,0.01-1.33,0.02-2c-1.51,0.14-3.03,0.17-4.51,0.45c-4,0.76-7.98,1.63-11.97,2.45h0
		c-3.95,1.1-7.91,2.21-12.13,3.39c0.37,0.78,0.73,1.54,1.08,2.28c0.35,0.74,0.69,1.47,1.04,2.19
		C736.14,172.4,745.21,170.2,754.59,168.95z"
          />
          <path
            className="st2"
            d="M731.09,382.53c0.22,1.03,1.39,1.86,2.47,2.7c0.36,0.28,0.71,0.57,1.01,0.86l0,0
		c2.87,0.21,4.72-0.79,5.88-3.71c0.97-2.44,0.23-3.73-1.63-4.94C734.06,375.97,730.19,378.38,731.09,382.53z"
          />
          <path
            className="st2"
            d="M733.85,356.74c-0.26-0.03-0.52-0.06-0.78-0.09c-0.44,0.07-0.88,0.14-1.32,0.21
		c-0.34,2.27-0.69,4.54-1.03,6.82c0.59,0.11,1.18,0.23,1.77,0.34c0.46-2.42,0.92-4.84,1.38-7.26L733.85,356.74z"
          />
          <path
            className="st2"
            d="M726.16,359.11L726.16,359.11c0.07,1.53,0.14,3.05,0.22,4.58c0.39,0.01,0.79,0.01,1.18,0.02
		c0.63-2.05,1.27-4.1,1.9-6.16C728.35,358.07,727.25,358.59,726.16,359.11z"
          />
          <path
            className="st2"
            d="M522.56,501.73c-0.46-0.64-0.91-1.28-1.37-1.92c-0.48-0.26-0.96-0.52-1.44-0.77c0.06-0.16,0.13-0.31,0.19-0.47
		c0.62,0.24,1.24,0.49,1.86,0.73c1.8,0.3,3.6,0.61,5.34,0.9c0.42-1.37,0.71-2.33,1.04-3.4c-2.28-1.58-4.49-3.12-6.71-4.67
		c-0.29,0.3-0.57,0.59-0.86,0.89c1.56,1.59,3.12,3.18,4.63,4.73c-3.72-0.84-7.38-1.66-11.05-2.49c-0.17,0.4-0.34,0.8-0.5,1.2
		c2.73,2.03,5.46,4.05,8.2,6.08C522.1,502.27,522.33,502,522.56,501.73z"
          />
          <path
            className="st2"
            d="M514.6,487.93c0.03,0.02,0.06,0.05,0.06,0.05c-0.01-0.03-0.03-0.05-0.04-0.08c-0.02,0-0.03,0-0.05,0l0,0
		c-0.01,0.01-0.01,0.03-0.02,0.04c0.03,0.01,0.07,0.02,0.1,0.04L514.6,487.93z"
          />
          <path
            className="st2"
            d="M533.01,493.58c0-0.79,0-1.78,0-3.38c-2.43-0.34-4.77-0.66-8.02-1.11
		C527.21,493.8,530.93,495.61,533.01,493.58z"
          />
          <path
            className="st2"
            d="M512.28,483.14c0.9,1.82,1.62,3.29,2.34,4.76c1.48,0,2.95,0,4.29,0C519.98,483.27,519.14,482.64,512.28,483.14
		z"
          />
          <path
            className="st2"
            d="M531.39,498.73c0.42,0.12,0.85,0.24,1.27,0.35c0.2-0.16,0.39-0.33,0.58-0.49c-0.37-0.24-0.73-0.47-1.1-0.71
		C531.89,498.17,531.64,498.45,531.39,498.73z"
          />
          <path
            className="st2"
            d="M507.58,486.18c1.47,1.23,2.93,2.46,4.85,4.07c-3.26-0.33-6.08-0.62-8.92-0.91c0.1,2.64,4.44,6.18,7.33,6.01
		c-0.91-0.99-1.75-1.92-2.93-3.22c2.06,0,3.57,0,5.04,0c0.15-0.38,0.29-0.76,0.43-1.12c0.41-1.07,0.79-2.08,1.17-3.08
		c-2.13-0.75-4.26-1.5-6.39-2.24c-0.44-0.18-0.89-0.36-1.33-0.54C507.08,485.5,507.33,485.84,507.58,486.18z"
          />
          <path
            className="st2"
            d="M277.01,443.21c0.87,0.57,3.23,0.53,3.57-0.03c2.2-3.67,4.66-7.41,2.63-12.09c-0.99-2.28-1.76-5.07-4.81-4.69
		c-3.08,0.38-4.7,2.81-5.26,5.81c-0.13,0.69-0.02,1.42-0.02,2.13C273.04,437.9,273.91,441.16,277.01,443.21z M277.31,429.8
		c2.18,3.56,1.22,7.1,0.52,11.46C275.01,437.05,274.36,433.68,277.31,429.8z"
          />
          <path
            className="st2"
            d="M264,451.28c1.17,1,2.09,1.78,3.55,3.01c1.69-2.64,3.08-4.82,4.48-7.01c1.29,1.1,2.63,2.25,4.34,3.71
		c0.75-1.33,1.35-2.39,2.12-3.76c-4.14,0.17-6.22-1.88-6.85-5.76c-0.33-2.05-1-4.04-1.52-6.05c-0.49,0.13-0.98,0.26-1.48,0.39
		c0.23,2.18,0.28,4.4,0.73,6.53C270.36,446.99,268.3,450.9,264,451.28z"
          />
          <path
            className="st2"
            d="M719.58,337.65c-3.8,0.4-4.62,3.34-1.91,7.43C718.38,342.3,718.97,340.03,719.58,337.65z"
          />
          <path
            className="st2"
            d="M723.9,340.29c0.19-1.31,0.38-2.62,0.57-3.96c-1.39,0.06-2.32,0.65-2.79,1.7c-0.47,1.05-0.46,2.54,0.03,4.41
		C722.44,341.73,723.17,341.01,723.9,340.29z"
          />
          <path
            className="st2"
            d="M395.22,375.56c-4.67-2.29-3.9-7.39-6.38-10.83c-0.25,1.96,0.16,3.84,0.07,5.71c-0.13,2.7-0.62,5.39-0.96,8.08
		c0,0,0,0,0,0c-1.17,0.48-2.35,0.96-3.52,1.44c0.12,0.27,0.23,0.53,0.35,0.8c1.24-0.57,2.49-1.14,3.73-1.72
		c0.91-1.06,1.82-2.11,2.24-2.61c1.87,0.51,3.75,1.45,5.54,1.32c0.91-0.07,1.67-2.09,2.49-3.22c-0.27-0.2-0.53-0.41-0.8-0.61
		C397.02,374.5,396.07,375.06,395.22,375.56z"
          />
          <path
            className="st2"
            d="M130.48,713.4c-0.91,0.29-1.15,2.67-1.67,4.05c3.22-1.08,5.75-1.92,8.28-2.77c-0.06-0.49-0.12-0.99-0.19-1.48
		C134.74,713.2,132.44,712.76,130.48,713.4z"
          />
          <path
            className="st2"
            d="M780.3,334.48c1.47,2.8,2.6,4.94,3.73,7.08C786.34,339.18,785.07,335.99,780.3,334.48z"
          />
          <path
            className="st2"
            d="M784.03,341.56c0,0-0.01,0.01-0.01,0.01c0,0,0.05,0.06,0.05,0.06c0-0.01-0.01-0.02-0.01-0.03
		C784.04,341.59,784.03,341.57,784.03,341.56z"
          />
          <path
            className="st2"
            d="M787.39,376.93c0.25,0.32,0.51,0.63,0.76,0.95c1.47-1.06,2.95-2.13,4.42-3.19c-0.22-0.92-0.44-1.84-0.66-2.76
		C790.41,373.59,788.9,375.26,787.39,376.93z"
          />
          <path
            className="st2"
            d="M787.47,383.5c0.27,0.27,0.54,0.54,0.8,0.81c1.91-1.56,3.81-3.12,5.72-4.69c-0.22-0.66-0.43-1.33-0.65-1.99
		C791.38,379.59,789.42,381.55,787.47,383.5z"
          />
          <path
            className="st5"
            d="M789.24,387.85c0.54-0.41,1.09-0.82,1.63-1.23c-0.24-0.27-0.47-0.54-0.71-0.8c-0.62,0.48-1.24,0.96-1.86,1.44
		c0,0,0,0,0,0c-0.31,0.25-0.62,0.51-0.94,0.76c0.08,0.15,0.16,0.3,0.24,0.44C788.15,388.26,788.69,388.06,789.24,387.85z"
          />
          <path
            className="st5"
            d="M792.55,385.33c-0.11-0.22-0.21-0.44-0.32-0.66c-0.32,0.1-0.71,0.13-0.95,0.34c-0.11,0.09,0.07,0.52,0.12,0.8
		C791.79,385.65,792.17,385.49,792.55,385.33z"
          />
          <path
            className="st3"
            d="M986.4,546.4c-0.09-0.14-0.18-0.28-0.27-0.41c-0.29,0.2-0.57,0.41-0.86,0.61c0.08,0.1,0.16,0.2,0.23,0.3
		C985.81,546.73,986.11,546.57,986.4,546.4z"
          />
          <path
            className="st2"
            d="M711.22,414.06c-0.96,1.35-1.64,1.87-1.65,2.41c-0.1,3.93-0.59,7.96,0.12,11.76c0.5,2.66,2.69,5,4.13,7.49
		c0,0,0.01-0.01,0.02-0.02s0.02-0.02,0.02-0.02C713.02,428.76,712.18,421.86,711.22,414.06z"
          />
          <path
            className="st2"
            d="M715.99,417.75c-0.46,0.04-0.91,0.08-1.37,0.12c-0.24,2.2-0.86,4.43-0.63,6.58c0.09,0.87,0.22,1.74,0.37,2.6
		c0.18,1.05,0.4,2.1,0.63,3.15c0.33,1.53,0.68,3.06,0.99,4.6c0.4,0.19,0.79,0.38,1.27,0.61c0.07-0.63,0.12-1.09,0.17-1.51
		c0.02-0.14,0.03-0.28,0.05-0.41C716.97,428.25,716.48,423,715.99,417.75z"
          />
          <path
            className="st2"
            d="M719.71,421.36c-0.13,0.71-0.21,1.43-0.25,2.16c-0.02,0.36-0.03,0.73-0.03,1.09c0,1.1,0.1,2.21,0.29,3.32
		c0.06,0.37,0.13,0.74,0.22,1.11c0.49,2.21,1.33,4.41,2.47,6.5c0,0,0.31,0.06,0.31,0.06l0.31-0.06c-0.76-5.43-1.53-10.86-2.43-17.29
		C720.19,419.25,719.9,420.29,719.71,421.36z"
          />
          <path
            className="st2"
            d="M270.5,609.48c-5.1,3.49-3.4,12.61,2.97,17.63C271.45,621.16,268.05,616.05,270.5,609.48z"
          />
          <path
            className="st27"
            d="M965.22,311.63c0.01,0.02,0.01,0.03,0.02,0.05"
          />
          <line
            className="st27"
            x1="965.23"
            y1="311.68"
            x2="965.23"
            y2="311.68"
          />
          <path
            className="st27"
            d="M965.23,311.68c0.25,0.72,0.5,1.43,0.75,2.15"
          />
          <path
            className="st2"
            d="M1057.35,475.03c-0.08,0.2-0.16,0.39-0.22,0.58c-0.29,0.87-0.28,1.67,1.29,2.18c4.85-2.56,4.51-6.95,4.2-11.33
		c-1.62,2.38-3.24,4.76-4.86,7.13C1057.76,474.05,1057.55,474.54,1057.35,475.03z"
          />
          <path
            className="st2"
            d="M1066.82,474.36c-2.48,0.42-5.51,3.79-5.48,6.05c0.76,0.36,1.57,0.73,2.64,1.24c1.09-1.62,2.09-3.1,3.09-4.59
		c0,0-0.07,0.08-0.07,0.08C1066.94,476.22,1066.88,475.3,1066.82,474.36z"
          />
          <path
            className="st2"
            d="M737.35,425.72c2.19,1.72,4.38,3.44,6.57,5.16c0.74-0.56,1.48-1.13,2.22-1.69c-2.68-1.53-5.37-3.06-8.05-4.59
		C737.85,424.97,737.6,425.34,737.35,425.72z"
          />
          <path
            className="st2"
            d="M753.19,428.08c-2.24-0.87-4.49-1.74-6.73-2.61c-0.19,0.44-0.38,0.87-0.57,1.31c2.19,1.05,4.38,2.09,6.57,3.14
		c0.29-0.63,0.58-1.26,0.87-1.89c0,0,0,0,0,0C753.34,428.03,753.19,428.08,753.19,428.08z"
          />
          <path
            className="st2"
            d="M63.47,750.17c1.11,2.25,2.04,4.14,2.98,6.04c0.56-0.02,1.12-0.04,1.68-0.06c0.43-1.44,1.6-3.24,1.12-4.25
		C68.37,750.11,66.53,748.75,63.47,750.17z"
          />
          <path
            className="st2"
            d="M582.01,465.64c1.17,0.75,2.27,1.45,3.37,2.16c0.27-0.3,0.54-0.6,0.81-0.9c-0.78-1.09-1.57-2.18-2.34-3.25
		C583.12,464.45,582.7,464.91,582.01,465.64z"
          />
          <path
            className="st2"
            d="M580.28,457.87c0.48,1.67,0.75,2.61,1.01,3.52c0.61-0.82,1.23-1.64,1.99-2.64
		C582.56,458.53,581.74,458.29,580.28,457.87z"
          />
          <path
            className="st2"
            d="M581.34,461.53c-0.01-0.05-0.03-0.1-0.04-0.14c-0.02,0.02-0.03,0.04-0.05,0.06
		C581.25,461.45,581.34,461.53,581.34,461.53z"
          />
          <path
            className="st2"
            d="M588.32,465.62c0.27,0.59,0.53,1.17,0.8,1.76c0.22-0.09,0.44-0.18,0.66-0.28c-0.17-0.54-0.34-1.08-0.52-1.63
		C588.95,465.52,588.64,465.57,588.32,465.62z"
          />
          <path
            className="st2"
            d="M586.12,462.89c0.36,0.57,0.73,1.13,1.09,1.7c0.25-0.16,0.49-0.32,0.74-0.48c-0.39-0.55-0.78-1.1-1.16-1.65
		C586.56,462.6,586.34,462.75,586.12,462.89z"
          />
          <path
            className="st2"
            d="M798.1,407.53c0.95-4.04-0.71-6.44-4.12-7.88c-0.71,0.02-1.42,0.02-2.12,0.07c-1.13,0.07-2.26,0.18-3.4,0.27
		c0.57,1.16,0.87,2.64,1.77,3.42c0.91,0.79,1.91,1.48,2.93,2.14c1.02,0.66,2.06,1.3,3.07,1.98
		C796.88,407.53,797.52,407.53,798.1,407.53z"
          />
          <line
            className="st27"
            x1="998.25"
            y1="313.03"
            x2="998.25"
            y2="313.03"
          />
          <line
            className="st27"
            x1="998.25"
            y1="313.03"
            x2="998.25"
            y2="313.02"
          />
          <path
            className="st27"
            d="M998.25,313.03c0.46,0.72,0.92,1.44,1.38,2.16"
          />
          <line
            className="st27"
            x1="998.25"
            y1="313.03"
            x2="998.25"
            y2="313.03"
          />
          <path
            className="st27"
            d="M997.55,311.62c0-0.01-0.01-0.01-0.01-0.02"
          />
          <line
            className="st27"
            x1="997.55"
            y1="311.62"
            x2="997.55"
            y2="311.62"
          />
          <line
            className="st27"
            x1="998.25"
            y1="313.03"
            x2="998.25"
            y2="313.03"
          />
          <line
            className="st27"
            x1="998.25"
            y1="313.03"
            x2="998.25"
            y2="313.03"
          />
          <path
            className="st27"
            d="M998.28,313.09c-0.01-0.02-0.02-0.04-0.03-0.06"
          />
          <path
            className="st27"
            d="M997.55,311.62c0.23,0.47,0.47,0.94,0.7,1.41"
          />
          <path
            className="st2"
            d="M791.59,255.49c1.62,0.52,3.64,0.2,5.39,0.43c0.86-0.3,1.71-0.66,2.6-0.88c3.03-0.73,4.15-2.41,2.51-4.96
		c-1.03-1.6-3.11-2.95-4.97-3.47c-1.29-0.36-2.7-0.3-4.13-0.21c-0.84,0.06-1.69,0.13-2.52,0.14c-0.54,1.84-1.07,3.69-1.61,5.53
		C789.31,254.16,790.33,255.08,791.59,255.49z"
          />
          <path
            className="st2"
            d="M776.7,257.27c4.22,0.25,7.22-1.06,8.79-5.1c0.13-0.65,0.27-1.31,0.4-1.96c-0.26,0.12-0.52,0.24-0.77,0.35
		c-0.58-1.34-1.17-2.67-1.75-4.01c-1.77-0.98-3.45-2.53-5.33-2.82c-4.19-0.63-8.47-0.63-12.71-0.89c-6.27,0-12.53,0-19.57,0
		c1.54,4.85,2.82,8.9,3.95,12.47c5.33,0.76,9.75,1.39,14.18,2.02C768.16,257.29,772.45,257.02,776.7,257.27z M749.67,247.29
		c0.02-0.48,0.05-0.97,0.07-1.45c10.1,0.58,20.2,1.16,30.29,1.75c-0.02,0.34-0.03,0.68-0.05,1.02
		C769.88,248.16,759.77,247.72,749.67,247.29z M751.87,250.75c9.86,0.49,19.72,0.98,29.59,1.47
		C778.43,255.33,757.82,254.08,751.87,250.75z"
          />
          <path
            className="st12"
            d="M1046.21,421.99c0,0,0.02-0.02,0.02-0.02c-0.1-0.05-0.21-0.09-0.31-0.14
		C1046.02,421.89,1046.12,421.94,1046.21,421.99z"
          />
          <path
            className="st24"
            d="M733.85,356.74c-0.26-0.03-0.52-0.06-0.78-0.09"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M811.84,745.05c-2.87,5.53-5.54,11.71-6.05,17.78c-0.82,9.77,2.61,19.07,7.69,27.45
		c1.79,2.95,4.78,5.17,6.75,7.22c2.53-2.3,4.06-4.37,6.11-5.37c2.69-1.31,5.8-1.76,8.73-2.6c8.3-2.37,16.59-4.76,24.89-7.14
		c0,0,0,0,0,0c-1.42-0.22-2.56-0.71-3.39-1.4c-1.83-1.53-2.14-4.03-0.57-6.75c0.83-1.43,1.49-2.97,2.4-4.35
		c2.05-3.13,3.89-6.24,1.55-10.02c-0.71-0.85-1.43-1.71-2.14-2.56c-0.53-1.13-1.06-2.26-1.6-3.4c3.7-1.48,5.18-0.46,6.56,4.15
		c0.86-0.98,1.66-2.02,2.6-2.92c0.59-0.57,1.38-0.93,2.09-1.39c0.39,0.73,0.79,1.46,1.18,2.19c0.4-0.22,0.8-0.45,1.21-0.65
		c0.09-0.04,0.22,0.01,1,0.09c-0.63,3.53-1.25,7.02-1.88,10.52c2.14,1.66,3.24,3.7,3.19,6.03c0,0.01,0,0.02,0,0.02
		c-0.02,0.78-0.17,1.59-0.45,2.42c-0.37,1.11-0.98,2.28-1.84,3.48c-1.49-1.42-2.89-2.76-4.29-4.11c-0.97,1.57-1.84,3.22-2.93,4.7
		c-0.17,0.23-0.37,0.45-0.58,0.66c-0.62,0.63-1.36,1.17-2.02,1.77c-0.02,0.51-0.04,1.02-0.06,1.53l0,0l0,0c0,0,0,0,0.01,0
		c0,0-0.01,0.01-0.01,0.01h0c0.47-0.06,0.94-0.09,1.41-0.14c0.47-0.05,0.93-0.11,1.38-0.23c15.3-4.1,28.42-11.71,39.35-23.35
		c3.7-3.94,8.64-6.72,13.02-10.03c-0.62-7.28-3.22-13.81-7.99-19.31c-6.83-7.85-15.1-13.17-25.89-13.6
		c-11.87-0.48-22.81,3.15-33.66,7.38c-3.19,1.24-6.68,1.69-10.27,2.56c0-1.17-0.07-1.56,0.01-1.59c11.03-5,21.17-12,33.44-14.22
		c10.24-1.85,20.22-2.64,29.69,2.87c0.28,0.16,0.7,0.1,1.06,0.14c-1.25-1.01-2.54-1.91-3.86-2.72c-0.34-0.21-0.69-0.41-1.04-0.6
		c-1.66-0.91-3.37-1.66-5.15-2.21c-0.8-0.24-1.61-0.45-2.43-0.61c-2.06-0.4-4.18-0.54-6.38-0.36c-8.11,0.65-16.23,1.75-24.18,3.43
		c-5.59,1.18-11.41,2.81-16.26,5.69C829.79,722.85,818.64,731.96,811.84,745.05z M870.91,736.83c0.52,0,1.04,0.07,1.55,0.15
		c0.34,0.06,0.68,0.13,1.01,0.2c0.5,0.1,0.98,0.21,1.44,0.28c4.08,0.66,7.6-0.35,10.48-2.64c2.99-2.38,5.15-5.78,7.92-8.48
		c1.04-1.02,2.72-1.62,4.19-1.82c0.47-0.07,1.21,1.75,1.82,2.7c1.26,8.57-6.27,18.99-14.92,20.37c-3.69,0.59-8.29-0.29-11.84-2.3
		c-1.18-0.67-2.25-1.47-3.13-2.38c-0.59-0.61-1.09-1.27-1.48-1.97c-0.4-0.71-0.69-1.46-0.85-2.25
		C868.19,737.22,869.53,736.82,870.91,736.83z M846.53,745.84c0.56-1.32,2.72-2.35,4.31-2.65c0.63-0.12,1.71,2.1,2.59,3.26
		c2.1,6.67,0.28,12.56-4.11,17.57c-5.63,6.42-15.09,10.37-22.65,4.77c-1.84-1.36-3.19-3.37-4.77-5.07
		c-1.56-1.32-3.11-2.63-4.67-3.95c0.21-0.55,0.43-1.1,0.64-1.65c1.66,0,3.37-0.28,4.96,0.04
		C836.04,760.88,841.32,758.07,846.53,745.84z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M946.7,777.27c-0.27-0.65-0.5-1.33-0.75-2c-0.25-0.66-0.53-1.31-0.92-1.88c-4.69-6.92-10.86-11.95-18.6-15.32
		c-5.02-2.19-9.67-2.04-14.15,0.67c-3.3,2-6.32,4.49-9.39,6.85c-6.35,4.88-12.59,9.92-19.03,14.68c-2.21,1.63-4.87,2.64-7.29,3.9
		c1.33,1.61,2.53,3.07,4.14,5.02c-1.23,0-1.56,0.04-1.88-0.01c-3.45-0.5-7.24-2.2-10.3-1.32c-12.05,3.47-23.91,7.61-35.73,11.82
		c-2.29,0.82-4.07,3.07-6.09,4.66c0.16,0.38,0.32,0.77,0.48,1.15c5.91,1.84,11.74,4.02,17.75,5.43c1.76,0.41,3.48,0.86,5.15,1.39
		c0.83,0.27,1.65,0.56,2.45,0.88s1.58,0.67,2.34,1.05c0.76,0.39,1.5,0.81,2.21,1.27c2.14,1.39,4.07,3.18,5.68,5.53l0,0l0,0v0
		c4.07-2.64,8.14-5.27,12.21-7.91c-0.53-1.03-1.06-2.05-1.59-3.08c0.26-0.26,0.52-0.52,0.78-0.78c1.43,0.83,2.87,1.67,4.3,2.5
		c3.79-2.02,7.43-4.45,11.41-5.98c6.86-2.63,13.94-4.71,20.93-7.01c0.06-0.66,0.12-1.32,0.19-2.11c0.2,0,0.38,0,0.56,0
		c0.52,0,0.95,0,1.38,0l0,0l0,0c4.53-3.34,9.07-6.68,13.6-10.02c-0.18-0.45-0.35-0.9-0.56-1.43c-0.07-0.18-0.14-0.36-0.22-0.56
		c0.84-0.04,1.49-0.07,2.14-0.09C932.88,778.87,938.73,775.34,946.7,777.27z M921.22,777.97c-10.04,2.26-17.45,8.44-24.68,15.31
		c2.73,1.4,5.21,2.67,7.69,3.95c-17.57,2.1-34.66,4.22-50.08,13.41c-0.32-0.54-0.64-1.08-0.97-1.62c1.55-1.72,2.87-3.74,4.68-5.12
		c6.84-5.22,15-7.08,23.24-8.61c1.73-0.32,3.58-0.25,5.19-0.84c1.73-0.64,3.44-1.65,4.84-2.86c4.82-4.17,9.33-8.71,14.27-12.72
		c7.67-6.22,17.02-6.75,27.31-7.38C929.01,775.13,925.27,777.06,921.22,777.97z"
            />
          </a>

          <path
            className="st3"
            d="M907,846.7c0.29,0.2,0.58,0.47,0.88,0.72c0.49,0.42,0.97,0.81,1.45,0.8c10.42-0.35,20.84-0.87,31.26-1.27
		c3.74-0.14,7.49-0.05,11.23-0.09c5.11-0.05,10.11,0.58,15-2.4c5.22-3.17,11.03-5.44,16.73-7.73c1.7-0.68,3.91-0.11,6.64-0.11
		c-0.83,3.19-1.42,5.42-2,7.65c2.45-2.67,5.12-5.18,7.33-8.04c8.73-11.33,9.77-15.7-0.31-24.08c-0.37-0.3-0.68-0.67-1.02-1
		c-4.33-4.23-9.23-6.81-15.55-5.88c-2.81,0.42-5.85-0.02-8.47,0.88c-3.67,1.25-7.67,2.71-10.39,5.28
		c-4.79,4.52-8.97,9.77-12.96,15.05c-4.08,5.4-9.17,9.29-15.38,11.63c-4.04,1.52-8.11,2.95-12.19,4.37
		C915.16,843.88,911.08,845.27,907,846.7L907,846.7z"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch4}
              d="M994.13,781.34c-4.51,2.22-9.65,3.17-14.12,4.54c-5.03,11.31-8.02,12.53-17.99,11.72
		c-3.27,5.58-8.21,6.56-12.98,5.01c-3.77,3.19-6.96,5.85-10.08,8.59c-0.68,0.6-1.27,1.43-1.58,2.27c-1.43,3.87-3.91,6.81-7.78,8.21
		c-3.39,1.22-6.93,1.99-10.48,2.98c-2.22,1.27-4.47,3.22-7.05,3.9c-7.03,1.85-14.24,3.04-21.31,4.74c-2.93,0.7-6.39,1.31-8.42,3.22
		c-5.49,5.15-10.47,10.88-15.42,16.58c-5.1,5.87-10.49,11.27-18.04,13.87c-1.74,0.6-4.13,0.15-5.36,1.19
		c-5.18,4.42-11.48,4.44-17.47,4.29c-5.68-0.14-11.31-1.89-16.67-2.88c0.43,1.99,1,4.58,1.44,6.6c4.67,3.09,9.04,5.99,13.53,8.96
		c0.65-1.84-3.04-4.49,0.65-5.5c-0.67-0.97-1.27-1.84-1.86-2.7c3.56-2.72,4.29-2.77,8.75-0.72c-1.61,1.19-3.09,2.28-4.56,3.36
		c1.28,0.44,2.58,0.88,4.02,1.37c-0.17,0.35-0.32,0.65-0.46,0.93c-0.14,0.28-0.26,0.53-0.39,0.79c0,0,0,0,0,0
		c0.73-0.18,1.5-0.3,2.25-0.45c0.75-0.15,1.48-0.35,2.13-0.68c11.37-5.82,23.3-10.65,33.43-18.72c1.05-0.84,2.58-1.08,3.88-1.59
		c1.11-1.22,2.2-2.46,3.32-3.66c2.01-2.15,3.75-4.69,6.11-6.35c9.21-6.46,20.13-8.82,30.59-12.25c6.69-2.19,13.55-4.04,19.94-6.92
		c8.81-3.97,14.43-11.74,20.25-19.08c4.37-5.51,9.3-10.2,16.56-11.31c4.53-0.69,9.15-0.83,13.71-1.4c2.97-0.37,5.9-1.08,9.07-1.67
		c-0.68-1.45-1.09-2.34-1.88-4.02c3.67-1.97,7.23-3.87,11.45-6.14c0.18-0.39,0.76-1.59,1.54-3.24c-3.18,1.55-5.73,2.8-8.29,4.04
		c-2.06-2.4-1.55-4.01,0.38-5.18c2.67-1.61,5.47-3.02,8.54-4.7c-0.55-1.94-1.12-3.96-1.5-5.32
		C999.35,776.57,997.18,779.84,994.13,781.34z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M818.97,867.38c0.15-0.88,0.06-1.89,0.48-2.58c2.06-3.36,3.02-6.91,2.41-10.84c-1.33-0.96-2.67-1.91-4-2.87
		c0.14-0.44,0.28-0.87,0.42-1.31c1.25-0.49,2.5-0.99,3.74-1.48c0,0.01,0,0.02,0,0.03c2.53-0.38,5.06-0.95,7.6-1.01
		c0.77-0.02,2.11,1.35,2.2,2.18c0.09,0.9-0.76,2.28-1.6,2.8c-1.35,0.83-3.04,1.13-4.58,1.66v0c0.03,1.82-0.12,3.58-0.46,5.32
		c-0.57,2.9-1.71,5.71-3.56,8.54c3.19,0.25,6.32,0.15,9.42-0.21c11.31-1.31,18.3-14.6,12.99-24.55c-0.99-1.85-1.99-3.74-2.58-5.73
		c-0.08-0.26,0.02-0.57,0.19-0.91c0.29-0.56,0.79-1.18,1.08-1.74l0,0c0,0,0,0,0,0c4.51-2.96,9.01-5.92,13.53-8.86
		c0.68-0.44,1.43-0.75,2.15-1.12c-0.67-0.95-1.32-1.91-2.08-2.74c-0.38-0.41-0.78-0.8-1.21-1.13c-0.44-0.33-0.91-0.61-1.43-0.82
		c-0.7-0.28-1.48-0.43-2.38-0.42c-0.39-0.39-0.74-0.87-1.12-1.28c-0.13-0.14-0.26-0.27-0.4-0.39c-0.19-0.15-0.39-0.28-0.6-0.35
		c-7.38-2.64-14.8-5.15-21.47-7.44c-0.22,20.61-13.56,31.52-31.67,38.97c3.66,2.35,7.41,4.49,6.69,9.06
		C807.47,863.24,812.2,867.68,818.97,867.38z M836.97,822.9c4.1-3.98,8.93-4.93,14.33-3.26c-0.01-0.01-0.01-0.03-0.02-0.04
		c0,0,0.06,0.05,0.06,0.05c-0.01,0-0.03-0.01-0.04-0.01c0.24,0.5,0.48,1.01,0.78,1.65c-0.71,0.32-1.3,0.68-1.94,0.87
		c-6.56,1.93-11.76,5.77-16.29,10.86c-1.66,1.86-4.14,3.11-6.46,4.22c-2.04,0.98-3.38,0.01-3.44-1.46
		C828.64,831.13,832.77,826.98,836.97,822.9z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M783.28,838.27c-0.2-0.93-0.4-1.87-0.64-2.97c-0.08-0.37-0.16-0.76-0.25-1.17c1.33-0.56,2.56-1.48,3.83-1.53
		c2.74-0.11,5.14-0.82,7.33-1.9c0.73-0.36,1.44-0.76,2.12-1.2c0.34-0.22,0.68-0.44,1.02-0.68c2.35-1.63,4.5-3.62,6.65-5.61
		c-1.69-3.74-4.27-6.29-7.28-8.3c-3.87-2.58-8.44-4.27-12.69-6.42c5.19-1.7,9.59,0.3,14.45,1.7c3.24-2.93,7.09-5.32,12.02-4.99
		c-1.38-2.31-2.75-4.62-4.64-7.78c2.25,0,4.14,0,6.03,0c-0.04-0.07-0.08-0.14-0.12-0.21c-0.03-0.05-0.06-0.11-0.09-0.16
		c-0.17-0.32-0.34-0.68-0.51-1.01c-0.06-0.12-0.12-0.23-0.18-0.33c-0.1-0.17-0.2-0.33-0.31-0.46c-0.16-0.19-0.33-0.33-0.53-0.4
		c-10.66-3.93-20.78-3.53-29.09,5.3c-9.87,10.48-13.09,23.02-8.34,38.13C775.81,838.27,779.54,838.27,783.28,838.27
		C783.28,838.27,783.28,838.27,783.28,838.27L783.28,838.27C783.28,838.27,783.28,838.27,783.28,838.27z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M759.33,784.87c0.59-8.66,1.19-17.45,1.82-26.62c-1.73,0.96-3.34,1.86-4.94,2.75c0,0-0.04,0.04-0.04,0.04
		c-1.85,6.44-4.13,12.79-5.42,19.34c-1.36,6.91-1.96,13.99-2.56,21.02c-0.68,8.01-0.86,16.07-1.53,24.08
		c-0.47,5.62,1.3,10.46,4.18,15.08c0.48,0.77,0.68,1.7,1.01,2.56c0,0,0.03,0.03,0.03,0.03c0.48,0.68,0.97,1.35,1.45,2.03
		c0,0,0.08,0.05,0.08,0.05c0.34,0.35,0.68,0.71,1.02,1.06c1.96,1.38,3.92,2.76,5.88,4.14c3.59,0.91,7.11,1.65,8.75-3.04
		c9.83,1.44,14.51,8.96,18.61,16.36c0.92,2.73,1.83,5.46,2.75,8.19l0,0c0.47,0.48,0.93,0.96,1.4,1.44c3.96,0.56,2.54-2.55,2.99-4.38
		c-0.01-0.23-0.02-0.46-0.02-0.69c-0.02-0.46-0.03-0.92-0.05-1.38c-0.51-0.4-1.01-0.8-1.55-1.22s-1.11-0.87-1.74-1.37
		c0.83-1.27,1.69-2.57,2.55-3.88c-0.47-1.17-0.88-2.37-1.33-3.55c-0.45-1.18-0.93-2.34-1.54-3.43c-2.52-4.47-6.15-8.32-11.37-8.61
		c-6.32-0.35-11.68-2.4-16.73-5.92c-8.89-6.2-10.4-12.23-8.56-21.76c0.54-2.83,1.18-5.63,1.84-8.44s1.34-5.6,1.98-8.41
		C758.62,795.21,758.97,790.06,759.33,784.87z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M788.76,788.7c6.16-2.08,12.44-1.25,18.73,0.51c-1.58-4.21-3.09-8.21-4.5-11.95
		c-0.47,1.39-1.01,2.95-1.54,4.52c-0.36,0.04-0.71,0.09-1.07,0.13c-0.66-1.11-1.65-2.14-1.91-3.33c-0.51-2.29-0.72-4.66-0.86-7.01
		c-0.39-6.32-2.63-11.8-6.97-16.58c-2.32,1.05-4.43,2-6.7,3.03c7.35,5.88,11.06,22.07,5.57,24.88c-4.24-7.05-1.5-16.86-9.23-23.2
		c-2.03,2.54-4.01,5.03-5.92,7.43c6.39,7.43,7.81,19.92,2.53,23.21c-1.4-6.28-2.76-12.4-4.13-18.53c-1.27,0.71-1.75,1.28-1.92,1.92
		c-1.39,5.32-2.54,10.71-4.13,15.97c-1.12,3.73-2.99,7.22-4.2,10.93c-2.28,7.02-3.04,14.24-2.99,21.64c0,0.62,0.03,1.22,0.09,1.79
		c0.42,4.02,2.24,6.92,6.02,8.9c0,0,0,0,0,0c0.11,0.42,0.23,0.84,0.34,1.26c0.28-0.26,0.56-0.53,0.84-0.79
		c-0.23-0.31-0.46-0.63-0.69-0.94c-0.49-7.02,0.14-13.95,2.15-20.7C771.5,800.92,777.61,792.46,788.76,788.7z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M822.52,897.01c-0.66-2.92-1.7-5.52-3.16-7.8c-0.24-0.38-0.5-0.75-0.76-1.12c-0.53-0.73-1.1-1.42-1.73-2.08
		c-0.31-0.33-0.63-0.65-0.97-0.96c-0.67-0.62-1.38-1.21-2.14-1.77c-1.52-1.11-3.23-2.09-5.13-2.94c-3.01,3.15-5.99,6.27-7.95,8.32
		c1.08,5.29,2.57,9.32,2.6,13.36c0.12,14.49-4.49,27.6-12.77,39.45c1.62,3.06,3.24,6.11,4.87,9.17c2.42-1.84,0.28-3.31-0.36-4.9
		c-0.15-0.37-0.21-0.74-0.13-1.13c0.51-0.28,0.97-0.48,1.39-0.57c1.84-0.41,2.96,1.02,4.04,5.1c1.75-1.62,3.24-3.02,4.89-4.55
		c-2.33-2.01-4.14-3.58-6.45-5.57c0.44-0.99,0.81-2.47,1.67-3.57c1.31-1.67,3.03-3.02,4.47-4.6c4.77-5.26,9.49-10.56,14.23-15.85
		C820.27,909.01,821.39,903.01,822.52,897.01z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M942.22,670.04c-0.15,1.26-0.3,2.53-0.45,3.79c-0.15,1.27-0.3,2.54-0.45,3.83c-1.15,0-1.99,0-2.7,0
		c-0.43,0-0.81,0-1.19,0c0,0,0,0,0,0c0.87,0.9,1.8,1.74,2.6,2.7c6.47,7.76,8.45,17.05,9.59,26.8c0.6,5.1-0.25,10.62,3.42,15.2
		c1.81,2.26,3.31,4.95,4.22,7.69c1.11,3.37,3.38,4.83,6.44,5.79c4.33,1.36,8.67,2.75,12.93,4.32c6,2.2,10.35,7.45,8.25,15.05
		c-0.55,2-0.46,4.3-0.13,6.38c0.34,2.21,1.22,4.43,4.42,4.19c2.24-2.44,3.53-4.9,1.29-8.34c-1.59-2.44-2.71-5.28-0.69-8.19
		c-0.51-8.54-6.67-12.01-13.49-14.61c-4.16-1.59-8.6-2.47-12.83-3.89c-1.5-0.5-2.78-1.67-4.15-2.53c0.11-0.56,0.23-1.11,0.34-1.67
		c3.54-0.24,7.08-0.47,10.62-0.71c-0.66-0.82-1.35-1.63-2.03-2.44c-0.45-0.54-0.89-1.08-1.32-1.63c-0.36-0.46-0.71-0.93-1.04-1.41
		c-3.93-5.69-6.02-11.88-7.42-18.81c-1.65-8.14-5.49-15.93-9.06-23.55c-0.18-0.39-0.39-0.76-0.62-1.12
		C947.12,674.34,944.36,672.34,942.22,670.04z M953.62,705.36c4.01,3.27,2.93,6.75,2.89,10.42c-3.31-1.5-4.94-3.99-5.33-7.03
		C951.05,707.78,952.68,706.59,953.62,705.36z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M805.18,719.17c-1.82,4.48-3.64,8.96-5.48,13.44c-0.75,1.82-1.79,3.52-2.98,5.83
		c-1.45-1.11-2.48-1.91-3.52-2.7l0,0c-0.46,0.61-0.9,1.2-1.35,1.74c-0.14,0.17-0.28,0.33-0.42,0.48c-0.47,0.51-0.96,0.97-1.52,1.35
		c-0.32,0.22-0.66,0.41-1.03,0.57c-1.1,0.49-2.45,0.72-4.23,0.59c-3.82-0.29-7.96,0.4-11.59,2.43c-0.91,0.51-1.78,1.09-2.61,1.77
		c-0.28,0.23-0.55,0.46-0.81,0.71c3.04-0.07,6.08-0.21,9.12-0.17c0.55,0.01,1.09,0.7,2.26,1.51c-4.78,2.93-9.12,5.59-13.65,8.38
		c-3.58-2.29-1.98-5.08-0.62-7.85c0,0,0,0-0.01,0c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c-1.37,0.77-2.58,1.67-3.66,2.66
		c-0.54,0.5-1.05,1.02-1.52,1.57c-0.95,1.09-1.79,2.27-2.53,3.52c-1.12,1.88-2.03,3.91-2.83,6.03c1.6-0.89,3.21-1.79,4.94-2.75
		c-0.63,9.17-1.23,17.96-1.82,26.62c-0.36,5.19-0.71,10.34-1.06,15.48c1.16-3.27,2.47-6.49,3.46-9.81
		c2.24-7.48,3.49-15.38,6.66-22.43c5.99-13.31,18.58-21,31.38-18.13c0-1.85-0.26-3.66,0.04-5.36c2.32-12.88,7.49-24.64,14.28-35.74
		c0.6-0.98,1-2.09,1.49-3.15c-1.65-0.01-3.31-0.01-4.96-0.02C808.81,710.2,807,714.69,805.18,719.17z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M904.94,690.16c-1.7,5.17-3.36,10.22-4.98,15.14c3.75,2.67,7.1,5.06,10.51,7.49
		c4.84-5.18,10.71-7.98,17.75-8.54c7.4-0.59,13.23,2.39,18.36,8.17c-1.76-16.18-5.73-25.3-11.98-28.12
		c2.86,12.97,2.86,13.64-0.31,16.31c-2.57-7.04-1.7-15.43-8.68-20.42c-1.3,0-2.84,0-4.61,0c5.45,6.24,7.48,15.03,3.99,20.62
		c-3.34-7.15-3.02-15.9-12.5-20.03c-1.7,1.67-3.79,3.74-6.05,5.96c7.35,5.33,9.95,12.86,7.01,19.75
		C909.24,701.5,910.59,694.07,904.94,690.16z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M900.45,712.74c-9.46-5.52-19.45-4.73-29.69-2.87c-12.27,2.22-22.41,9.22-33.44,14.22
		c-0.08,0.04-0.01,0.42-0.01,1.59c3.59-0.87,7.08-1.32,10.27-2.56c10.86-4.22,21.8-7.85,33.66-7.38
		c10.78,0.43,19.06,5.75,25.89,13.6c4.78,5.49,7.37,12.03,7.99,19.31c1.73,0,3.47,0,5.09,0c0.58-2.8,0.63-6.19,0.03-9.81
		c-0.27-1.61-0.66-3.26-1.18-4.92c-0.26-0.83-0.56-1.67-0.89-2.5s-0.7-1.67-1.1-2.49c-0.2-0.41-0.41-0.83-0.63-1.24
		c-0.44-0.82-0.91-1.64-1.43-2.44c-0.26-0.4-0.52-0.8-0.8-1.2c-0.49-0.71-1.02-1.4-1.57-2.09c-0.39-0.49-0.8-0.97-1.23-1.44
		c-2.65-2.94-5.93-5.57-9.9-7.65C901.15,712.84,900.73,712.9,900.45,712.74z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M980.77,758.89c0.04-1.37,0.16-2.75,0.23-4.12c0.07-1.37,0.08-2.74-0.11-4.07c-0.23-1.67-1.24-4.03-2.56-4.63
		c-4.52-2.05-9.34-3.45-13.8-5.02c-5.97,10.79-14.32,16.41-26.04,17.19c1.35,1.23,2.65,2.49,3.88,3.78
		c3.7,3.88,6.78,8.12,8.65,13.24c2.37-1.27,4.74-2.55,7.11-3.82c-1.23-1.47-2.45-2.93-3.75-4.48c-0.43-0.52-0.87-1.04-1.32-1.58
		c0.81-0.14,1.55-0.17,2.23-0.1c0.46,0.05,0.89,0.14,1.3,0.26c0.21,0.06,0.4,0.13,0.6,0.21c1.95,0.79,3.44,2.35,5.01,3.75l0,0l0,0
		c0,0,0,0,0,0c2.94-1.74,5.88-3.48,8.82-5.22c-2.29-2.73-4.57-5.45-6.86-8.18c0.21-0.28,0.42-0.57,0.63-0.85
		c2.92,2.16,5.84,4.33,8.76,6.49h0l0,0C975.97,760.8,978.37,759.84,980.77,758.89z"
            />
          </a>

          <path
            className="st3"
            d="M778.12,881.17c-0.11-0.22-0.21-0.44-0.31-0.68c-0.7-1.64-1.18-3.59-2.42-4.4
		c-5.59-3.67-11.49-6.88-17.3-10.27c-7.46,14.19-8.45,21.85-7.66,35.37c2.79-1.96,5.6-3.88,8.36-5.88
		C765.25,890.62,771.68,885.89,778.12,881.17z"
          />

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M820.96,705.87c-9.67,12.68-14.58,27.03-17.58,42.16c-0.28,1.43,0.13,3,0.21,4.5
		c6.15-20.35,20.51-32.9,37.6-43.08c-0.48-1.25-0.97-2.38-1.48-3.38c-1.93-3.82-4.08-5.77-6.93-5.85c-0.38-0.01-0.76,0-1.16,0.05
		c-0.08,2.1,1.76,4.23,0.6,6.65c-0.17,0.35-0.39,0.7-0.69,1.05c-1.71-2.78-3.19-5.18-4.96-8.05c-1.36,1.08-2.63,2.1-3.86,3.09
		c2.16,3.2,3.65,6.21,1.33,10.16C822.93,710.53,821.98,708.27,820.96,705.87z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M939.98,750.56c1.31-0.89,2.65-1.81,4.12-2.81c0.56,0.06,1.46,0.16,2.26,0.24c0.33-0.18,0.63-0.24,0.73-0.4
		c4.06-6.95,2.67-14.04,0.46-21.13c-0.52-1.68-1.08-3.34-1.62-5.01c-1.01-1.54-2.03-3.07-3.13-4.5c-0.21-0.28-0.43-0.55-0.65-0.82
		c-2.37-2.9-5.19-5.26-9.23-6.1c-0.42-0.09-0.86-0.16-1.31-0.21c-1.01,1.58-0.8,2.96-0.14,4.16c0.09,0.17,0.19,0.33,0.3,0.49
		c0.4,0.59,0.9,1.12,1.38,1.6c1.71,1.68,3.2,3.45,4.41,5.39c0.51,0.81,0.95,1.66,1.35,2.54c0.38,0.84,0.71,1.71,0.99,2.61
		c0.12,0.4,0.25,0.81,0.35,1.23c0.19,0.07,0.38,0.15,0.58,0.23c0.08,0.03,0.17,0.07,0.25,0.1c0.22,0.09,0.45,0.18,0.7,0.28
		c0.59,0.23,1.3,0.51,2.23,0.88c-1.81,1.66-3.17,2.92-4.54,4.17c2.99,2.12,5.18,4.87,4.36,8.21c-2.96-1.61-6.16-3.36-9.36-5.11
		c0.41,1.01,0.83,2.02,1.2,2.93c-3.78,0.7-7.47,1.39-11.15,2.07c0.1,1.26,0.2,2.52,0.31,3.83c0.18,2.18,0.36,4.48,0.57,7.11
		c2.23-0.18,4.45-0.36,6.66-0.54c2.21-0.18,4.42-0.36,6.62-0.54c0,0,0,0,0,0c0,0,0,0,0,0C939.12,751.16,939.55,750.86,939.98,750.56
		z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M805.19,797.42c1.88,3.16,3.26,5.47,4.64,7.78c0.91,0.09,1.82,0.18,3.34,0.34c-0.62,0.53-1.14,0.96-1.6,1.35
		c-0.46,0.39-0.87,0.74-1.28,1.08c0.47,1.13,0.7,2.18,0.76,3.16c0.02,0.4,0,0.79-0.03,1.17c-0.04,0.48-0.13,0.95-0.25,1.4
		c-0.26,0.96-0.67,1.86-1.18,2.73c-1.11,1.87-2.68,3.59-4.06,5.37c-0.73,0.47-1.45,0.93-2.18,1.4c-2.15,1.99-4.3,3.98-6.65,5.61
		c-0.34,0.23-0.67,0.46-1.02,0.68c-0.69,0.43-1.39,0.84-2.12,1.2c-2.19,1.08-4.59,1.79-7.33,1.9c-1.26,0.05-2.49,0.97-3.83,1.53
		c0.09,0.41,0.17,0.8,0.25,1.17c0.24,1.11,0.44,2.04,0.64,2.97c0,0,0,0,0,0c0.41,0.28,0.82,0.53,1.23,0.76
		c0.56,0.31,1.11,0.57,1.67,0.79c0.09,0.03,0.17,0.08,0.26,0.11c0.97,0.35,1.95,0.57,2.93,0.69c0.13,0.02,0.26,0.03,0.39,0.05
		c1.39,0.13,2.78,0.06,4.18-0.14c2.16-0.3,4.34-0.91,6.52-1.53c2.78-2.36,5.53-4.77,8.35-7.08c5.61-4.6,8.88-10.81,9.26-17.69
		c0.23-4.14-2.41-8.45-3.85-12.65c-0.34-0.98-1.04-1.83-1.58-2.74c0,0,0,0,0,0c-0.47-0.47-0.94-0.94-1.41-1.41l0,0
		C809.33,797.42,807.44,797.42,805.19,797.42z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M1003.03,812.05c1.59,2.65,3.54,5.64,3.61,8.53c0.28,11.13-5,19.26-14.89,24.42
		c-0.65,0.34-1.02,1.23-1.71,2.11c0.98,1.48,1.91,2.89,3.09,4.67c1.58-1.2,2.72-2.06,3.78-2.87c-0.71-1.51-1.23-2.6-1.89-4
		c1.82-0.14,3.1-0.25,4.5-0.36c0.55-1.39,1.07-2.74,1.6-4.08c0.2,0.05,0.39,0.09,0.59,0.14c2.48-2.43,5.18-4.69,7.41-7.34
		c4.94-5.87,8.13-12.4,7.03-20.45c-1.07-7.87-5.13-13.86-11.49-19.77c-2.53,5.12-4.71,9.53-6.84,13.84
		C1000.02,809.02,1001.96,810.27,1003.03,812.05z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M878.47,811.82c2.37,2.65,4.75,5.31,7.12,7.96c4.66-1.48,9.23-3.44,14.01-4.31
		c5.78-1.06,11.13-2.9,16.12-5.91c0.03-0.29,0.05-0.56,0.06-0.84c0.03-1.36-0.28-2.57-0.78-3.7c-1-2.24-2.77-4.13-4.18-6.19
		c-6.99,2.31-14.07,4.38-20.93,7.01C885.9,807.37,882.26,809.8,878.47,811.82z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M935.67,739.51c-0.37-0.91-0.79-1.92-1.2-2.93c-3.75-0.53-4.38-3.36-5.04-6.43c-0.65-3-1.78-5.9-2.89-9.46
		c0.85,0.24,1.56,0.43,2.22,0.61c0.22,0.06,0.44,0.12,0.66,0.18c0.46,0.29,0.91,0.58,1.37,0.86c0,0-0.03-0.02-0.05-0.03l-0.02-0.01
		c2,1.9,3.99,3.8,5.99,5.71h0l0,0c1.18-0.06,2.35-0.13,3.53-0.19v0c-0.1-0.42-0.22-0.82-0.35-1.23c-0.27-0.9-0.6-1.77-0.99-2.61
		c-0.4-0.88-0.84-1.73-1.35-2.54c-1.21-1.94-2.7-3.71-4.41-5.39c-0.48-0.48-0.98-1.01-1.38-1.6c-0.11-0.16-0.21-0.32-0.3-0.49
		c-0.66-1.19-0.88-2.58,0.14-4.16c-0.84-0.12-1.66-0.18-2.47-0.18c-0.03,0-0.07,0-0.1,0c-2.5,0-4.81,0.53-7.02,1.4
		c-0.74,0.29-1.46,0.62-2.17,0.98c-2.14,1.08-4.21,2.43-6.28,3.87c2.44,2.89,4.43,5.9,6.02,9.03c1.06,2.09,1.95,4.23,2.67,6.43
		c0.18,0.55,0.35,1.1,0.52,1.66c0.81,2.78,1.39,5.64,1.76,8.59C928.2,740.9,931.89,740.21,935.67,739.51z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M857.82,679.97c-3.85,2.15-7.98,4.11-9.33,8.98c-0.26,0.93-1.17,1.67-1.83,2.56
		c-2.39-1.01-3.47-2.59-3.83-4.55c-0.12-0.65-0.16-1.35-0.13-2.08c-0.23,0.37-0.58,0.7-0.67,1.1c-0.83,3.89-1.75,4.36-5.52,2.94
		c-1.74-0.65-3.53-1.18-5.29-1.76c-0.68,0.69-1.36,1.97-2.05,1.98c-6.11,0.08-10.11,3.58-13.38,8.14c-1.92,2.67-3.45,5.61-5.16,8.43
		c0,0,0,0,0,0c1.65,0.01,3.31,0.01,4.96,0.02c1.42-2.01,2.84-4.03,4.25-6.04c5.81-8.27,16.11-6.12,20.48-1.65
		c2.59,2.65,4.55,5.91,7.01,9.2c0.76-0.39,1.73-0.89,2.22-1.15c0.39-4.83,0.43-9.13,1.14-13.31c1.22-7.14,2.71-11.5,12.97-11.38
		c2.57,0.03,5.14,0.45,7.7,0.69C867.6,678.61,862.06,677.61,857.82,679.97z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M796.72,738.44c1.19-2.31,2.23-4.01,2.98-5.83c1.84-4.47,3.67-8.95,5.48-13.44
		c1.82-4.48,3.63-8.97,5.45-13.45c1.71-2.82,3.24-5.76,5.16-8.43c3.27-4.55,7.26-8.06,13.38-8.14c0.69-0.01,1.37-1.29,2.05-1.98
		c-0.78-0.2-1.56-0.37-2.33-0.5c-0.77-0.13-1.54-0.22-2.31-0.28c-0.76-0.06-1.52-0.08-2.28-0.06c-3.77,0.08-7.42,1.11-10.86,3.22
		c-4.82,2.95-6.8,8.11-8.63,13.05c-2.03,5.49-3.97,11.01-5.89,16.54c-1.91,5.53-3.8,11.07-5.73,16.6
		C794.24,736.53,795.27,737.33,796.72,738.44z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M874.9,678.87c-5.51-3.02-11.17-5.81-17.89-5.32c-2.76,0.2-5.85,1.38-8.45,3.08
		c-1.49,0.97-2.81,2.12-3.83,3.36s-1.71,2.56-1.95,3.9c-0.06,0.33-0.09,0.67-0.09,1v0c-0.02,0.73,0.02,1.42,0.13,2.08
		c0.36,1.96,1.44,3.53,3.83,4.55c0.66-0.89,1.57-1.63,1.83-2.56c1.35-4.87,5.48-6.83,9.33-8.98c4.23-2.37,9.78-1.37,13.54,2.13
		c6.42,2.31,10.72,7.25,15.09,12.11c0.33,0.34,0.67,0.69,1,1.03c0,0,0.12,0.16,0.12,0.16c1.26-0.88,2.52-1.75,3.79-2.63
		C886.84,687,881.28,682.36,874.9,678.87z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch9}
              d="M924.88,669.74c-8.33-0.04-15.9,1.66-22.61,6.73c-0.57,0.43-1.49,0.38-2.25,0.54
		c-0.34,0.49-0.68,0.99-1.02,1.48c-1.5,2.93-3.01,5.86-4.48,8.74c2.81,4.26,2.66,5.6-1.59,8.01c-0.32-0.51-0.72-1.14-1.13-1.8
		c-0.14-0.22-0.28-0.44-0.42-0.67h0c-1.26,0.88-2.52,1.75-3.79,2.63c2.13,3.47,3.69,7.59,8.88,8.08c0.26-0.9,0.62-1.65,0.69-2.43
		c0.57-5.82,2.35-11.19,5.73-16c4.97-7.07,11.23-11.76,20.37-10.82c4.04,0.41,8.03,1.27,12.04,1.93
		C932.96,672.17,930,669.76,924.88,669.74z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M768.84,859.62c-3.11-0.99-6.29-2.07-9.07-3.72c-7.6-4.52-14.17-10.18-18.1-18.49
		c0.09,4.34,0.62,8.58,0.7,12.83c0.15,7.96,0.21,15.94-0.01,23.9c-0.15,5.3-1.1,10.58-1.23,15.88c-0.01,0.49-0.01,0.98,0,1.47
		c0.06,3.43,0.59,6.87,0.87,10.31c0,0,0,0,0,0c0.27,0.24,0.54,0.49,0.81,0.74c0.12-0.1,0.24-0.2,0.37-0.29
		c-0.18-0.38-0.36-0.75-0.53-1.13c-0.88-15.4,0.61-30.56,4.17-46.2c11.28,3.35,20.42,9.54,29.82,15.29
		C776.56,864.01,774.52,861.42,768.84,859.62z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M899.6,815.46c-4.77,0.87-9.34,2.83-14.01,4.31c0,3.23,0,6.47,0,9.35c8.7-2.3,16.91-4.4,25.07-6.67
		c1.5-0.42,3.79-1.29,4.02-2.33c0.29-1.29,0.46-2.6,0.59-3.92c0.21-2.21,0.27-4.44,0.45-6.66
		C910.73,812.57,905.38,814.4,899.6,815.46z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M856.81,690.75c-0.28,0.08-0.56,0.16-0.83,0.24c-0.61,4.55-1.21,9.11-1.84,13.81
		c10.2-1.71,19.72-3.3,30.05-5.03c-4.44-4.14-8.64-8.06-13.26-12.36c-0.81,1.8-1.15,2.57-1.49,3.33c-3.92-0.15-6.38-4.99-11.24-2.86
		c4.32,2.56,4.36,6.02,3.36,10.49C859.74,695.46,858.27,693.11,856.81,690.75z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M959.62,726.54c-0.11,0.56-0.23,1.11-0.34,1.67c1.38,0.86,2.65,2.03,4.15,2.53c4.24,1.42,8.67,2.3,12.83,3.89
		c6.82,2.61,12.98,6.07,13.49,14.61l0.04,0.04c1.36,1.06,2.71,2.13,4.3,3.37c1.01-1.58,1.79-2.8,2.57-4.02
		c0.06-0.2,0.13-0.39,0.19-0.59c0.13-4.2-1.04-7.74-3.17-10.81c-0.43-0.61-0.89-1.21-1.39-1.78c-0.25-0.29-0.51-0.57-0.77-0.85
		c-0.8-0.84-1.68-1.64-2.62-2.4c-1.91-0.65-3.82-1.3-5.73-1.96c-4.31-1.47-8.62-2.95-12.94-4.42
		C966.7,726.06,963.16,726.3,959.62,726.54z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M865.65,835.27c-0.62,8.2-3,15.28-10.88,19.64c-2.58,1.42-4.39,4.23-6.55,6.4c0.41,0.45,0.81,0.9,1.22,1.36
		c4.12-3.39,8.59-6.44,12.27-10.25c6.79-7.04,13.11-14.53,19.62-21.83c0.38,0.18,0.75,0.35,1.13,0.53
		c-0.36-2.89-0.73-5.77-1.09-8.66c-5.19,3.08-10.38,6.17-15.57,9.25C865.75,832.89,865.74,834.08,865.65,835.27z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M787.68,863.76c-4.1-7.4-8.78-14.92-18.61-16.36c-1.63,4.69-5.16,3.95-8.75,3.04
		c3.38,1.43,6.69,3.1,10.17,4.24c3.76,1.23,6.68,3.04,8.6,6.7c2.22,4.21,1.77,8.63,2.12,13.06c0.18,2.32,1.39,4.56,2.27,7.24
		c3.21-2.15,5.78-3.42,4.14-6.92c-0.13,0.44-0.25,0.88-0.68,2.36c-2.1-2.85-3.74-5.08-5.39-7.32c0.34-0.41,0.68-0.83,1.02-1.24
		c1.4,0.85,2.81,1.71,4.21,2.56c0,0,0,0,0,0c0.99,0.23,1.99,0.45,2.98,0.68c0.22,0.05,0.44,0.1,0.67,0.15
		C789.51,869.22,788.59,866.49,787.68,863.76z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M920.21,805.95c0.22,4.03,0.45,8.05,0.67,12.06c14.97-1.78,16.96-15.44,12.19-23.47
		c-3.24,2.87-6.48,5.74-9.72,8.61C922.3,804.08,921.25,805.02,920.21,805.95z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M921.46,665.04c-0.97,0.13-1.93,0.32-2.88,0.53c-1.43,0.32-2.84,0.73-4.23,1.25
		c-1.54,0.57-3.04,1.26-4.49,2.06c-0.73,0.4-1.44,0.83-2.15,1.29c-1.77,1.15-3.44,2.5-5.01,4c-0.93,0.89-1.84,1.82-2.69,2.83
		c0,0,0,0,0.01,0l0,0c0,0,0-0.01,0.01-0.01c0,0,0,0.01-0.01,0.01c0.76-0.17,1.69-0.12,2.25-0.54c6.72-5.07,14.29-6.76,22.61-6.73
		c5.13,0.02,8.09,2.43,10.42,6.42l0.04,0.04c0.42,0.29,0.84,0.57,1.26,0.86h0c0,0-0.01,0-0.01,0c0.27,0.2,0.54,0.39,0.81,0.59
		c0,0,0.04,0.02,0.04,0.02h0l0,0c0.38,0,0.77,0,1.19,0c0.71,0,1.54,0,2.7,0c0.15-1.29,0.3-2.56,0.45-3.83
		c0.15-1.27,0.3-2.53,0.45-3.79v0c-0.76-0.47-1.5-1-2.24-1.52c-0.74-0.52-1.49-1.03-2.27-1.45c-0.52-0.28-1.05-0.52-1.6-0.7
		C931.3,664.81,926.3,664.39,921.46,665.04z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M927.89,784.6c3.11,2.63,6.22,5.27,9.33,7.9c3.63-2.43,7.25-4.85,10.88-7.28c-0.07-0.4-0.14-0.79-0.21-1.19
		c-0.4-2.26-0.79-4.51-1.19-6.77C938.73,775.34,932.88,778.87,927.89,784.6z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch7}
              d="M868.3,862.82c-10.12,8.08-22.05,12.9-33.43,18.72c-0.66,0.34-1.38,0.53-2.13,0.68
		c-0.75,0.15-1.52,0.27-2.25,0.45c0,0,0,0,0,0c-1.02,0.56-2.04,1.11-3.06,1.67c2.41,0.38,4.51,0.13,6.58-0.24
		c9.32-1.67,18.7-3.43,26-10.08c2.17-1.98,4.2-4.11,6.2-6.27c1.99-2.17,3.95-4.38,5.97-6.52
		C870.88,861.74,869.35,861.98,868.3,862.82z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M814.22,801.57c1.44,4.2,4.08,8.51,3.85,12.65c-0.38,6.88-3.66,13.1-9.26,17.69
		c-2.82,2.31-5.57,4.72-8.35,7.08c11.77-1.89,22.29-14.46,22.18-26.76c-0.03-3.82-0.55-6.52-1.73-8.5
		c-0.58-0.97-1.32-1.76-2.24-2.42c-0.96-0.7-2.11-1.27-3.5-1.75c-0.77-0.26-1.62-0.5-2.54-0.72
		C813.17,799.75,813.88,800.6,814.22,801.57z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M853.3,839.44c2.11-1.31,4.22-2.62,6.32-3.96c0.51-0.32,0.77-1.05,1.14-1.58c-0.79-3.06-1.58-6.13-2.36-9.19
		c-0.72,0.37-1.48,0.68-2.15,1.12c-4.52,2.94-9.02,5.9-13.53,8.86c0,0,0,0,0,0c1.41,2.89,2.83,5.78,4.24,8.68
		C849.07,842.05,851.19,840.75,853.3,839.44z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M912.94,796.71L912.94,796.71c1.14,0.97,2.31,1.92,3.37,2.95c0.71,0.69,1.37,1.41,1.96,2.2
		c0.87,1.18,1.56,2.51,1.94,4.08c1.05-0.93,2.09-1.87,3.14-2.8c3.24-2.87,6.48-5.74,9.72-8.61c-2.18-2.62-4.35-5.23-6.53-7.85
		C922,790.03,917.47,793.37,912.94,796.71z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M862.78,821.07L862.78,821.07c0.26,0.93,0.53,1.87,0.79,2.8c0.74,2.61,1.48,5.22,2.22,7.82c0,0,0,0,0,0
		c5.19-3.08,10.38-6.17,15.57-9.25c-2.13-3.1-4.25-6.19-6.38-9.29C870.92,815.8,866.85,818.44,862.78,821.07z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M750.85,840.56c-2.89-4.62-4.65-9.46-4.18-15.08c0.67-8.01,0.85-16.07,1.53-24.08
		c0.6-7.03,1.19-14.11,2.56-21.02c1.29-6.55,3.57-12.9,5.42-19.34c-0.21,0.06-0.54,0.05-0.61,0.18c-4.18,7.73-6.31,16.09-7.41,24.74
		c-0.68,5.37-1.07,10.79-2.07,16.09c-1.76,9.32-2.68,18.62-0.76,27.98c0.63,3.06,1.34,6.11,2.84,8.78c0.9,1.6,2.08,3.06,3.69,4.3
		C751.53,842.27,751.33,841.33,750.85,840.56z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M965.91,778.88c-0.21,2.3-0.42,4.6-0.63,6.89c-0.21,2.29-0.42,4.57-0.63,6.84
		c9.87-0.84,14.17-10.77,11.38-18.84C972.66,775.47,969.28,777.17,965.91,778.88z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M860.76,833.9c-0.38,0.54-0.63,1.26-1.14,1.58c-2.1,1.33-4.2,2.65-6.32,3.96c-2.11,1.31-4.23,2.61-6.34,3.92
		h0c0.39,3.66,0.77,7.32,1.14,10.8c1.72-1.08,3.32-2.22,5.03-3.15c0.85-0.46,1.69-1.18,2.5-2.07c1.07-1.2,2.08-2.71,2.92-4.34
		C860.43,840.91,861.51,836.61,860.76,833.9z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M951.31,798.09c3.08-0.21,5.92-1.13,7.84-4.67c2.31-4.25,2.73-8.63,2.43-13.24c-3.04,0.95-6.08,1.9-9.13,2.85
		C952.08,788.03,951.7,793.04,951.31,798.09z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M962.21,769.51C962.21,769.51,962.21,769.51,962.21,769.51c0.02,0.05,0.04,0.11,0.06,0.16
		c1.21,3.07,2.42,6.14,3.63,9.21c3.38-1.7,6.75-3.41,10.13-5.11c-1.67-3.16-3.34-6.32-5.01-9.48
		C968.09,766.03,965.15,767.77,962.21,769.51z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M937.22,792.51c1.02,4.01,2.05,8.01,3.1,12.14c2.55-4.1,5.25-8.44,7.96-12.78c0.26,0.05,0.51,0.1,0.77,0.15
		c-0.32-2.26-0.64-4.53-0.95-6.79C944.47,787.66,940.85,790.08,937.22,792.51z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M949.67,726.91c-0.82-1.6-1.7-2.99-2.57-4.11c-0.39-0.51-0.79-0.97-1.17-1.35c0.54,1.67,1.1,3.33,1.62,5.01
		c2.21,7.09,3.6,14.17-0.46,21.13c-0.1,0.17-0.39,0.23-0.73,0.4c-0.79-0.08-1.7-0.18-2.26-0.24c-1.47,1.01-2.81,1.92-4.12,2.81
		c-0.44,0.3-0.87,0.59-1.3,0.89c0,0,0,0,0,0c6.03-0.28,10.82-3.17,15.04-6.88c0.47-4.01-0.5-8.82-2.07-13.12
		C951.06,729.84,950.39,728.3,949.67,726.91z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M767.38,755.08c4.53-2.78,8.87-5.44,13.65-8.38c-1.17-0.81-1.71-1.51-2.26-1.51
		c-3.04-0.04-6.08,0.1-9.12,0.17c-0.22,0.12-0.43,0.24-0.65,0.36c-0.22,0.12-0.43,0.24-0.65,0.36c-0.24,0.19-0.47,0.38-0.71,0.57
		c0,0-0.01,0.01-0.02,0.02c-0.02,0.01-0.03,0.02-0.03,0.02c-0.28,0.18-0.56,0.36-0.84,0.54C765.39,750,763.8,752.79,767.38,755.08z"
            />
            <polygon
              className="st12"
              points="766.75,747.24 766.75,747.23 766.75,747.23 766.75,747.23 	"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M951.03,775.26c0.48,2.59,0.95,5.18,1.43,7.77c3.04-0.95,6.08-1.9,9.13-2.85c-1.15-2.91-2.3-5.83-3.44-8.74
		C955.77,772.71,953.4,773.98,951.03,775.26z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M978.95,769.42C978.95,769.42,978.95,769.42,978.95,769.42c0.23,0.86,0.45,1.71,0.67,2.56
		c0.11,0.42,0.22,0.84,0.33,1.26c0.22,0.83,0.43,1.65,0.64,2.44c0.31,1.18,0.61,2.3,0.88,3.33c2.29-1.96,5-4.29,7.18-6.15
		c-2.78-2.6-5.15-4.83-7.52-7.05C980.41,767.01,979.68,768.21,978.95,769.42C978.95,769.42,978.95,769.42,978.95,769.42z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M989.79,749.29c0.34,1.04,0.52,2.2,0.93,3.2c0.14,0.33,0.3,0.65,0.5,0.94c1.85,2.66,4.01,5.11,6.03,7.62
		c2.34-2.66,3.06-5.88,0.64-9.49c-0.58-0.86-0.83-1.93-1.23-2.91c-0.78,1.22-1.56,2.44-2.57,4.02
		C992.51,751.42,991.15,750.35,989.79,749.29z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M799.52,868.88c0.68,0.74,1.42,1.55,2.31,2.5c0.81-1.02,1.87-2.36,3.92-4.94c-3.26-1.88-6.25-3.61-9.24-5.34
		c0.53-0.3,1.06-0.6,1.59-0.9c-1.11,0.39-2.22,0.77-3.34,1.16c0.96,1.82,1.92,3.64,2.87,5.46
		C798.23,867.49,798.84,868.15,799.52,868.88z"
            />
          </a>
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M973.57,761.75L973.57,761.75c0.37,0.53,0.74,1.05,1.11,1.58c1.42,2.03,2.85,4.06,4.27,6.09c0,0,0,0,0,0
		c0.73-1.21,1.46-2.41,2.19-3.62c-0.12-2.3-0.24-4.61-0.36-6.91C978.37,759.84,975.97,760.8,973.57,761.75z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M991.52,734.61c0.27,0.28,0.53,0.56,0.77,0.85c0.5,0.58,0.96,1.17,1.39,1.78c2.13,3.07,3.3,6.61,3.17,10.81
		c0.94,0.21,1.88,0.43,2.82,0.64c-0.66-1.4-1.32-2.79-2.11-4.46c1.06-0.49,1.97-0.91,2.88-1.33c-0.37-0.6-0.83-1.18-1.24-1.77
		c-0.41-0.59-0.77-1.19-0.92-1.85c-0.54-2.19-1.57-3.67-2.93-4.71c-0.31-0.23-0.63-0.44-0.97-0.63c-0.3-0.17-0.61-0.34-0.94-0.48
		c-0.96-0.43-2.02-0.73-3.12-0.98c-0.47-0.1-0.94-0.2-1.42-0.29C989.84,732.97,990.72,733.77,991.52,734.61z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M997.56,744.23c0.79,1.67,1.45,3.06,2.11,4.46c0.98,1.18,1.96,2.35,2.94,3.53c0.67,0.13,1.34,0.26,2.01,0.39
		c1.82-4.41,1.02-6.5-3.63-9.48c0,0,0,0,0,0c-0.11-0.04-0.21-0.09-0.31-0.13c-0.08-0.03-0.16-0.06-0.24-0.1
		C999.53,743.32,998.62,743.74,997.56,744.23z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M1004.62,752.61c-0.66-0.13-1.33-0.26-2.01-0.39c0.33,1.19,0.66,2.38,1.01,3.61
		c6.9-4.5,6.69-10.82-0.11-14.51c-0.45,0.32-0.88,0.63-1.3,0.93c-0.42,0.3-0.82,0.59-1.23,0.88
		C1005.64,746.11,1006.44,748.2,1004.62,752.61z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M830.21,852.3c0.84-0.52,1.69-1.9,1.6-2.8c-0.09-0.83-1.44-2.2-2.2-2.18c-2.54,0.06-5.07,0.63-7.6,1.01
		c0-0.01,0-0.02,0-0.03c-1.25,0.49-2.5,0.99-3.74,1.48c-0.14,0.44-0.28,0.87-0.42,1.31c1.33,0.96,2.67,1.91,4,2.87
		c0.05-1.87,0.1-3.73,0.16-5.6c1.56,0.29,2.28,1.14,2.69,2.19c0.1,0.27,0.19,0.55,0.27,0.83c0.04,0.14,0.07,0.29,0.11,0.43
		c0.17,0.73,0.31,1.47,0.56,2.15C827.18,853.44,828.86,853.14,830.21,852.3z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch7}
              d="M819.14,915.01c0.87-1,1.61-2.03,2.21-3.08c0.4-0.7,0.75-1.41,1.03-2.13c0.43-1.08,0.74-2.19,0.93-3.32
		c0.51-3.01,0.19-6.17-0.8-9.46C821.39,903.01,820.27,909.01,819.14,915.01z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameState}
              d="M796.2,755.09c2.19,4.17,4.27,8.12,6.13,11.65c0.39-3.65,0.83-7.78,1.23-11.53
		c-0.97,0.05-2.11,0.17-3.25,0.15C798.9,755.33,797.49,755.18,796.2,755.09z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch8}
              d="M792.77,950.54c0.58,0.43,1.73,0.09,2.61,0.1c-1.62-3.06-3.24-6.11-4.87-9.17
		C789.16,945.28,789.84,948.4,792.77,950.54z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M782.56,868.56c-0.34,0.41-0.68,0.83-1.02,1.24c1.64,2.23,3.29,4.46,5.39,7.32c0.43-1.48,0.55-1.92,0.68-2.36
		c-0.28-1.21-0.56-2.42-0.84-3.64C785.37,870.27,783.97,869.42,782.56,868.56z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M791.83,873.38c0,1.64,0,3.28,0,5.43c2.67-1.91,4.07-3.68,4.34-5.48c0.03-0.2,0.05-0.4,0.05-0.6
		c0.01-1.21-0.47-2.43-1.4-3.73C794.37,870.84,795.79,873.94,791.83,873.38z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M791.45,864.34c0.64,0.5,1.21,0.95,1.74,1.37s1.04,0.82,1.55,1.22l0,0c0.96-0.03,1.92-0.07,2.88-0.1
		c0,0,0,0,0,0c-0.96-1.82-1.92-3.64-2.87-5.46c-0.25-0.3-0.5-0.61-0.75-0.91C793.13,861.77,792.28,863.07,791.45,864.34z"
            />
          </a>

          <path
            className="st24"
            d="M751.89,843.15c0.48,0.68,0.97,1.35,1.45,2.03"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M933.62,682.17c-0.19,0.17-0.39,0.35-0.58,0.52c0.28,0.37,0.55,0.75,0.83,1.12c0.23-0.23,0.46-0.45,0.7-0.68
		C934.25,682.81,933.93,682.49,933.62,682.17z"
            />
          </a>

          <path
            className="st26"
            d="M886.46,694.21c0.33,0.34,0.67,0.69,1,1.03"
          />
          <path
            className="st17"
            d="M900.01,677.01c-0.34,0.49-0.68,0.99-1.02,1.48"
          />
          <line className="st17" x1="900.01" y1="677.01" x2="900.01" y2="677" />
          <path
            className="st26"
            d="M936.59,677.06c-0.42-0.29-0.84-0.57-1.26-0.86"
          />
          <path
            className="st24"
            d="M753.42,845.24c0.34,0.35,0.68,0.71,1.02,1.06"
          />
          <path className="st6" d="M986.79,846.9c0.2-0.47,0.39-0.93,0.59-1.4" />
          <path
            className="st24"
            d="M767.64,746.66c0.24-0.19,0.47-0.38,0.71-0.57"
          />
          <line
            className="st24"
            x1="766.75"
            y1="747.23"
            x2="766.75"
            y2="747.24"
          />
          <path
            className="st24"
            d="M767.59,746.7c-0.28,0.18-0.56,0.36-0.84,0.54"
          />
          <path
            className="st24"
            d="M937.39,677.65c-0.27-0.2-0.54-0.39-0.81-0.59"
          />
          <path
            className="st3"
            d="M1056.95,922.69c-0.29,0.27-0.57,0.53-0.86,0.8c-5.21,3.62-10.42,7.25-15.63,10.87
		c-2.58,1.63-5.16,3.26-7.75,4.89c-0.56,0.71-1.12,1.43-1.68,2.14c-1.83,0.96-3.65,1.91-5.48,2.87c-0.27,1.33-0.54,2.67-0.81,4
		c-0.18-0.01-0.35-0.01-0.53-0.02c-0.11-0.85-0.22-1.7-0.32-2.55c-6.68,3.13-13.36,6.27-20.04,9.4c0.52,0.84,1.08,1.25,1.68,1.4
		c1.3,0.33,2.73-0.57,4.09-0.8c0.38-0.24,0.75-0.47,1.13-0.71c0.08,0.12,0.16,0.24,0.24,0.37c-0.27,0.29-0.53,0.57-0.8,0.86
		c0,0,0,0,0,0c-2.97,3.42-5,4.34-7.03,3.57c-0.92-0.35-1.84-1.05-2.85-2.03c-1.99,3.03-4.02,6.12-6.66,10.13
		c-2.01-3.08-3.35-5.14-4.7-7.2c-0.84,0.57-1.68,1.14-2.73,1.85c-1.12-1.05-2.04-1.92-3.58-3.37c2.3-1.65,4.26-3.2,6.37-4.52
		c2.79-1.77,5.72-3.32,8.53-5.07c0.3-0.18,0.52-0.48,0.73-0.81c0.21-0.33,0.39-0.69,0.61-0.99c-2.79,0.38-5.58,0.76-8.37,1.14
		c-0.02-0.18-0.04-0.35-0.06-0.53c-7.54,3.05-15.08,6.1-23.33,9.44c4.39,2.73,7.95,4.93,11.46,7.11c-0.44,1.5-0.88,2.28-0.86,3.04
		c0.17,5.78,1.29,11.4-0.05,17.37c-1.18,5.28-0.25,11.03-0.25,17.32c29.16-17.76,60.69-30.1,86.58-50.97c0-11.54,0-23.1,0-34.65
		c-2.38,1.89-4.76,3.78-7.15,5.66L1056.95,922.69z"
          />
          <path
            className="st14"
            d="M903.46,978.86c2.95,2.79,6.75,3.31,10.51,3.37c10.93,0.18,21.46-2.33,31.85-5.34
		c8.17-2.37,16.21-5.22,25.21-8.15c-5.62-3.02-10.08-5.41-14.78-7.94c-2.71,3.45-5.48,3.24-8.45,0.9c-1.48-1.16-3.22-2.02-4.57-3.3
		c-6.83-6.48-13.56-13.06-20.36-19.56c-1.2-1.14-2.6-2.06-3.9-3.08c-0.96,1.29-1.5,2.21-2.23,2.96
		c-6.45,6.71-11.81,14.19-15.19,22.86C897.58,971.7,897.39,973.12,903.46,978.86z M910.09,954.31c1.38-2.54,3.4-2.44,5.79-1.64
		c3.66,1.23,7.37,2.27,11.07,3.39c0.27,0.08,0.55,0.17,1.24,0.38c-0.53-0.44-0.7-0.58-0.87-0.72c-0.77-0.84-1.54-1.68-2.26-2.47
		c3.4-0.52,6.08,0.55,11,4.49c4.79,3.82,5.39,7.24,1.64,11.88c-0.75,0.92-2.96,1.43-4.2,1.07c-7.8-2.25-15.6-4.62-21.61-10.58
		C910.25,958.49,908.71,956.87,910.09,954.31z"
          />
          <path
            className="st3"
            d="M1031.19,927.22c-2.42,0.35-4.83,0.85-7.27,1.01c-7.42,0.48-14.92,1.34-21.71-2.94
		c-3.16-1.99-4.41-5.17-2.55-8.48c1.43-2.52,3.73-4.55,5.86-7.05c-2.46-5.29-2.24-10.81,0.97-16.58c3.17-5.7,7.88-8.8,14.72-10.45
		c-5.49-1.88-9.6-4.49-15.09-1.59c-10.48,5.54-21.53,9.99-32.03,15.49c-7.19,3.77-13.85,8.58-20.82,12.96
		c5.88,2.44,10.66-1.75,15.97-2.52c5.64-0.81,11.3-1.52,16.97-2.28c3.81,6.59,7.81,13.66,11.97,20.62
		c3.52,5.89,7.24,11.66,10.88,17.48c6.63-4.65,13.26-9.3,19.89-13.96C1029.73,928.4,1030.45,927.79,1031.19,927.22z"
          />
          <path
            className="st12"
            d="M940.96,935.39c4.06,3.84,8.11,7.7,12.23,11.48c2.8,2.57,5.7,5.02,8.56,7.53c5.95-1.91,11.91-3.83,17.86-5.74
		c0.7-2.84,1.4-5.68,2.09-8.52c-2.83-2.06-5.66-4.1-8.49-6.15s-5.65-4.11-8.45-6.21c-4.93-3.69-9.75-7.54-15.49-12
		c-4.87,1.99-10.95,4.47-17.03,6.95C933.37,928.16,937.15,931.78,940.96,935.39z"
          />
          <path
            className="st12"
            d="M1058.48,905.55c-4.05,3.85-8.1,7.69-12.15,11.54c-0.89,1.48-1.43,3.58-2.74,4.31
		c-3.98,2.22-8.25,3.92-12.41,5.82c-0.74,0.58-1.46,1.18-2.22,1.72c-6.62,4.66-13.26,9.31-19.89,13.96
		c-2.19,1.17-4.39,2.33-6.58,3.5c-0.71,0.26-1.42,0.52-2.13,0.78c-0.49,0.2-0.98,0.41-1.47,0.61l0,0l0,0
		c-0.22,0.31-0.41,0.67-0.61,0.99c-0.21,0.33-0.43,0.63-0.73,0.81c-2.81,1.75-5.73,3.3-8.53,5.07c-2.1,1.33-4.06,2.88-6.37,4.52
		c1.54,1.45,2.46,2.32,3.58,3.37c1.04-0.71,1.88-1.28,2.73-1.85c-1.63-1.09-2.27-2.21-0.07-3.62c1.76,1.56,3.48,3.09,5.46,4.85
		c1.6-2.84,3.4-6.04,5.21-9.26c1.85,1.05,3.07,1.74,4.3,2.43c6.68-3.13,13.36-6.27,20.04-9.4c-0.56-2.57-1.11-5.14-1.69-7.82
		c1.86-0.58,3.09,1.22,3.35,6.38c1.83-0.96,3.65-1.91,5.48-2.87c0-3.05,0-6.1,0-9.15c2.68,2.04,2.51,4.47,1.68,7
		c2.58-1.63,5.16-3.26,7.75-4.89c-0.59-0.06-1.17-0.12-2.39-0.25c0.48-1.05,0.65-2.25,1.33-2.75c5.13-3.8,10.34-7.5,15.58-11.13
		c0.5-0.35,1.44-0.05,2.48-0.05c-0.27,1.11-0.43,1.82-0.6,2.53v0c2.38-1.89,4.76-3.78,7.15-5.66
		C1064.4,909.79,1063.09,907.08,1058.48,905.55z M1000.36,947.17c0.71-0.26,1.42-0.52,2.13-0.78
		C1001.78,946.66,1001.07,946.91,1000.36,947.17z"
          />
          <path
            className="st14"
            d="M1068.28,894.24c0.19-8.65-2.56-15.46-11.77-18.77c-0.98-0.35-2.06-0.63-3.09-0.63
		c-9.92-0.04-19.85-0.3-29.64,1.97c-1.68,0.39-3.61-0.29-5.43-0.48c-0.44,0.16-0.88,0.31-1.28,0.46c-0.4,0.14-0.76,0.27-1.03,0.36
		c4.57,1.75,9.2,3.52,14.67,5.61c4-2.34,6.49,1.1,8.59,4.88c6.66-1.9,8.87-0.09,11.02,9.1c4.43,1.7,9.12,3.49,13.8,5.29
		C1067.96,900.76,1068.21,897.1,1068.28,894.24z"
          />
          <path
            className="st3"
            d="M937.33,1005.44c3.51-2.22,7.14-1.82,10.78-1.35c2.14,7.84,3.11,8.91,7.84,8.98c-0.69-0.8-1.4-1.58-2.06-2.4
		c-4.65-5.78-7.85-11.91-4.86-19.26c-12.51,1.11-25.18,2.24-38.38,3.41c-3.23,6.33-2.64,10.81,2.7,15.43
		c1.11,0.96,2.24,1.92,3.37,2.86c1.13,0.95,2.26,1.89,3.38,2.84C925.85,1012.46,931.64,1009.03,937.33,1005.44z"
          />
          <path
            className="st12"
            d="M1066.62,871.38c-6.39-1.32-13.09-1.42-19.66-1.52c-4.38-0.07-8.66,0.36-12.86,1.22
		c-1.2,0.24-2.39,0.52-3.58,0.83c-1.18,0.31-2.36,0.66-3.53,1.03c-2.93,0.94-5.81,2.08-8.64,3.39c1.82,0.19,3.75,0.87,5.43,0.48
		c9.79-2.27,19.72-2.01,29.64-1.97c1.03,0,2.11,0.28,3.09,0.63c9.21,3.32,11.96,10.13,11.77,18.77c-0.06,2.87-0.32,6.53-4.16,7.8
		c1.76,1.66,3.52,3.31,5.76,5.42c3.16-4.09,6.64-7.83,9.22-12.11C1083.23,888.48,1076.96,873.51,1066.62,871.38z"
          />
          <path
            className="st3"
            d="M1050.23,967.22c0.87-0.35,1.74-0.72,2.61-1.08s1.75-0.71,2.63-1.04c3.84-1.43,7.68-2.66,11.55,0.62
		c-0.13,1.51-0.26,3.11-0.43,5.14c3.92-2.27,7.6-4.4,11.28-6.53v0c0.2-0.91,0.42-1.81,0.62-2.71s0.41-1.81,0.57-2.72
		c1.02-5.5,2.95-11.05,2.68-16.49c-0.24-4.77-2.52-9.65-4.83-13.99c-0.89-1.67-4.36-1.96-7.17-3.09c0,7.74-0.27,14.83,0.09,21.88
		c0.24,4.61-0.28,8.23-4.8,10.73c-1.27,0.7-2.53,1.44-3.77,2.2c-1.47,0.89-2.91,1.82-4.36,2.76
		C1054.69,964.34,1052.47,965.8,1050.23,967.22C1050.23,967.22,1050.23,967.22,1050.23,967.22z"
          />
          <path
            className="st4"
            d="M951.7,959.29c2.53-1.24,4.65-2.27,6.62-3.23c1.18-0.58,2.3-1.12,3.42-1.67c-2.86-2.5-5.76-4.96-8.56-7.53
		c-4.12-3.78-8.17-7.63-12.23-11.48c-3.8-3.6-7.59-7.22-8.72-12.67c-5.08,1.91-10.15,3.83-14.77,5.57
		c6.28,5.51,12.08,10.46,17.73,15.58C940.92,949.05,946.5,954.42,951.7,959.29z"
          />
          <path
            className="st14"
            d="M900.22,982.54c0.92,1.11,1.19,1.6,1.59,1.9c5.29,3.97,11.53,5.66,17.88,5.56c8.6-0.13,17.28-0.74,25.75-2.19
		c8.6-1.47,16.99-4.2,25.67-6.43c0-4.16,0-7.53,0-10.56c-12.33,4.03-24.1,8.39-36.16,11.65
		C923.84,985.48,912.42,988.63,900.22,982.54z"
          />
          <path
            className="st12"
            d="M973.86,922.09c7.4,5.04,14.79,10.07,22.19,15.11c0.5-1.96,1.01-3.93,1.51-5.89
		c-4.61-7.74-9.22-15.49-13.65-22.92c-4.42,1-9.02,2.04-13.61,3.08c0.68,2.29,1.31,4.59,2.05,6.86
		C972.77,919.61,973.35,920.84,973.86,922.09z"
          />
          <path
            className="st3"
            d="M1029.14,904.49c2.49-3.29,2.87-6.07,0.64-8.82c-6.94,4.16-14.3,4.81-21.99,2.38
		c-0.09,10.79,9,19.09,18.39,16.76C1022.6,910.08,1026.89,907.48,1029.14,904.49z"
          />
          <path
            className="st3"
            d="M973.76,995.11c1-3.63,1.92-7.26-0.66-10.61c-3.7,1.32-7.1,2.53-10.66,3.8c-0.25,2.27-0.7,4.58-0.72,6.89
		c-0.01,1.21,0.09,2.42,0.21,3.62c0.13,1.21,0.28,2.42,0.39,3.62C966.13,999.99,969.94,997.55,973.76,995.11z"
          />
          <path
            className="st12"
            d="M1029.78,895.67c2.23,2.75,1.84,5.53-0.64,8.82c-2.25,2.98-6.54,5.58-2.96,10.31
		c4.76-1.58,8.6-3.75,8.77-9.75c0.05-1.75,2.12-3.45,3.36-5.32c-0.08-0.8-0.08-2.21-0.44-3.52c-0.15-0.56-1.05-0.92-1.62-1.38
		c-0.74-1.21-1.48-2.41-2.22-3.62C1032.61,892.7,1031.2,894.19,1029.78,895.67z"
          />
          <path
            className="st3"
            d="M1050.11,900.37c-3.54,2.25-6.58,4.18-9.89,6.29c3.83,2.48,6.14,5.82,6.12,10.43
		c4.05-3.85,8.1-7.69,12.15-11.54C1055.46,903.68,1052.44,901.81,1050.11,900.37z"
          />
          <path
            className="st14"
            d="M1087.91,928.53c-3.1-0.86-4.84-3.11-6.47-5.46c-0.3-0.43-0.59-0.86-0.89-1.28c-0.45-0.64-0.91-1.27-1.4-1.86
		c-0.44,0.38-0.88,0.76-1.35,1.17c-0.47,0.41-0.98,0.84-1.55,1.33c5.25,1.73,6,6.35,7.12,10.39c1.16,4.2,1.44,8.64,2.12,13.02
		C1087.31,940.25,1089.01,934.61,1087.91,928.53z"
          />
          <path
            className="st3"
            d="M1016.25,887c-3.49,1.09-6.82,5.32-6.33,9.49c2.07-5.22,6.33-5.48,9.82-6.46c3.63-1.02,7.6-0.8,11.58-1.14
		C1029.28,885.02,1024.2,884.51,1016.25,887z"
          />
          <path
            className="st3"
            d="M1033.92,917.74c0.99-0.44,1.57-1.82,2.33-2.77c0.23-0.37,0.47-0.74,0.7-1.12c-0.12-0.09-0.23-0.18-0.35-0.26
		c-0.28,0.27-0.57,0.53-0.85,0.8c-4.15,2.73-8.52,4.79-13.65,4.85c-5.18,0.06-9.71-1.57-14.01-5.19c-1.06,4.28,2.13,5.1,4.3,5.69
		C1019.73,921.74,1027,920.84,1033.92,917.74z"
          />
          <path
            className="st3"
            d="M972.35,918.32c-0.74-2.27-1.37-4.57-2.05-6.86c-3.25,0.55-6.5,1.11-10.21,1.74c2.36,1.52,4.67,3.01,6.95,4.49
		c2.29,1.48,4.55,2.94,6.81,4.4C973.35,920.84,972.77,919.61,972.35,918.32z"
          />
          <path
            className="st3"
            d="M991.22,942.54c0.81,0.56,2.2,0.26,3.33,0.36c0.06-0.31,0.11-0.63,0.17-0.94c-0.51-0.39-1.02-0.78-1.53-1.17
		c-1.42-0.38-3.26-0.33-4.17-1.23c-1.5-1.48-2.43-3.55-3.58-5.37c-0.51-0.23-1.02-0.46-1.53-0.69c-3.33-1.95-6.66-3.89-9.99-5.84
		c-0.16,0.25-0.32,0.5-0.48,0.76c2.08,1.86,4.07,3.83,6.26,5.55C983.45,936.92,987.28,939.81,991.22,942.54z"
          />
          <path
            className="st15"
            d="M1080.54,921.79c0.3,0.43,0.59,0.86,0.89,1.28c1.64,2.35,3.37,4.6,6.47,5.46c0.16-5.6-2.8-9.12-7.7-11.73
		c-0.2,0.6-0.39,1.15-0.56,1.66c-0.17,0.51-0.33,0.99-0.5,1.47C1079.64,920.52,1080.09,921.15,1080.54,921.79z"
          />
          <path
            className="st4"
            d="M999.33,943.07c1.15-0.45,2.98-1.18,5.38-2.13c-2.46-3.31-4.8-6.48-7.15-9.64c-0.5,1.96-1.01,3.93-1.51,5.89
		C997.12,939.11,998.19,941.03,999.33,943.07z"
          />
          <path
            className="st4"
            d="M1045.43,896.09c-0.55-2.41-1.01-5.79-4.36-5.39c-3.37,0.4-5.56-0.23-7.05-3.37c0,1.29,0,2.59,0,3.88
		c0.74,1.21,1.48,2.41,2.22,3.62c1.03-0.92,2.74-2.78,2.99-2.6c1.96,1.39,3.64,3.17,5.38,4.81c-1.18,1.61-1.79,2.45-2.62,3.6
		C1045.4,900.34,1045.93,898.29,1045.43,896.09z"
          />
          <path
            className="st3"
            d="M979.61,948.65c2.35-0.82,4.71-1.64,7.27-2.54c0.85-0.3,1.73-0.6,2.64-0.92c-1.89-1.22-3.53-2.29-5.08-3.3
		c-0.93-0.6-1.83-1.18-2.73-1.77C981,942.97,980.31,945.81,979.61,948.65z"
          />
          <path
            className="st14"
            d="M955.64,995.58c-1.58,0.39-2.77,0.68-4.77,1.17c2.23,3.81,4.15,7.09,6.07,10.36c0,0,0,0,0,0
		c0.27,0.25,0.54,0.5,0.81,0.74c0.12-0.1,0.25-0.2,0.37-0.3c-0.18-0.37-0.36-0.75-0.55-1.12
		C956.95,1002.91,956.32,999.38,955.64,995.58z"
          />
          <path
            className="st14"
            d="M902.85,991.52c0,3.85,0,7.49,0,11.13C905.73,992.47,905.73,992.47,902.85,991.52z"
          />
          <path
            className="st12"
            d="M985.45,934.19c-0.01,0-0.01-0.01-0.02-0.01c1.16,1.83,2.08,3.9,3.58,5.37c0.91,0.9,2.75,0.85,4.17,1.23
		c-1.43-0.63-2.35-1.86-3.25-3.1c-0.39-0.53-0.78-1.06-1.2-1.55c-0.85-0.98-1.86-1.77-3.34-2.01c0,0,0,0,0,0
		c0.01,0.02,0.02,0.04,0.03,0.06l0,0C985.43,934.19,985.44,934.19,985.45,934.19z"
          />
          <path
            className="st4"
            d="M1003.3,920.98c3.2,1.24,6.33,2.45,9.6,3.71c-3.51-2.59-6.75-4.98-9.6-7.08
		C1003.3,917.92,1003.3,919.09,1003.3,920.98z"
          />
          <path
            className="st3"
            d="M966.23,923.47c2.19,1.54,4.39,3.09,6.58,4.63c0.29-0.34,0.57-0.68,0.86-1.02c-2.26-1.53-4.53-3.05-6.79-4.58
		C966.66,922.83,966.45,923.15,966.23,923.47z"
          />
          <path
            className="st3"
            d="M965.58,922.53c0.11-0.26,0.23-0.52,0.34-0.79c-1.06-0.62-2.12-1.25-3.19-1.87c-0.12,0.21-0.24,0.43-0.36,0.64
		C963.44,921.18,964.51,921.85,965.58,922.53z"
          />
          <path
            className="st6"
            d="M1000.36,947.17c0.71-0.26,1.42-0.52,2.13-0.78"
          />
          <path
            className="st14"
            d="M899.89,981.12c-0.12,0.04-0.24,0.08-0.35,0.12c0.1,0.28,0.19,0.57,0.29,0.85c0.13-0.05,0.27-0.1,0.4-0.14
		C900.12,981.68,900,981.4,899.89,981.12z"
          />
          <path
            className="st17"
            d="M985.43,934.18c-0.51-0.23-1.02-0.46-1.53-0.69"
          />
          <path
            className="st17"
            d="M985.43,934.18c0.01,0,0.01,0.01,0.02,0.01"
          />
          <path
            className="st12"
            d="M1162.19,629.08c-0.26-0.07-0.52-0.14-0.78-0.21c-0.66-0.18-1.32-0.35-1.98-0.53c-0.47,0-0.94,0-1.41,0
		c-0.99,0.22-1.97,0.43-2.96,0.65v0c-0.65-0.29-1.3-0.58-2.11-0.93c-0.27-0.12-0.55-0.24-0.86-0.38c0.4,1.03,0.63,1.62,0.94,2.43
		c-2.26-0.9-4.33-1.73-6.4-2.55c-2.91,0.02-5.83,0.05-8.74,0.07c4.29,1.42,8.59,2.83,12.88,4.26c1.48,0.49,2.95,1.02,4.42,1.54
		c-0.01-0.02-0.01-0.05-0.02-0.07c0,0,0,0,0,0c0.01,0,0.02,0,0.03,0c-0.58-0.79-1.16-1.59-1.74-2.38c0.16-0.19,0.31-0.39,0.47-0.58
		c1.02,0.74,2.04,1.48,3.66,2.66c-0.67,0.08-1.14,0.14-1.51,0.19c-0.36,0.04-0.62,0.08-0.88,0.11l0,0h0
		c0.02,0.03,0.05,0.07,0.07,0.1l0,0h0l0,0l0,0c-0.03-0.01-0.05-0.02-0.08-0.03c0.12,0.38,0.25,0.79,0.51,1.62
		c-2.84-0.81-5.43-1.55-7.65-2.19c-1.8,1.68-3.46,3.23-5.12,4.78c3.36,0.44,6.72,0.87,10.08,1.31c4.45-1.37,8.9-2.74,13.63-4.2
		c-1.31-1.66-2.39-3.04-3.42-4.36C1162.86,629.94,1162.52,629.51,1162.19,629.08L1162.19,629.08L1162.19,629.08L1162.19,629.08z
		 M1157.43,634.56c-0.15-0.3-0.3-0.6-0.45-0.9c0.19-0.09,0.37-0.18,0.56-0.26c0.15,0.32,0.3,0.63,0.44,0.95
		C1157.8,634.42,1157.62,634.49,1157.43,634.56z"
          />
          <path
            className="st12"
            d="M1130.21,635.45c0.63,0.01,1.27,0.02,1.9,0.02c-2.06-0.81-4.13-1.62-6.19-2.43
		c-0.43-0.11-0.86-0.22-1.29-0.32c0.26-0.29,0.53-0.58,0.79-0.87c0.37,0.24,0.74,0.48,1.1,0.72c2.22-0.06,4.44-0.11,6.67-0.17
		s4.47-0.11,6.72-0.17c-1.96,0.69-3.99,1.41-6.15,2.18c0,1.3,0,2.7,0,4.74c4.29-1.86,8.29-3.58,12.29-5.31c-0.1-0.4-0.2-0.8-0.3-1.2
		c-3.21-1.23-6.35-3.19-9.64-3.5c-3.45-0.32-7.05,0.98-10.59,1.5c-0.92,0.13-2.23,0.35-2.75-0.13c-0.74-0.68-0.92-1.97-1.33-3.01
		c-2.4-0.44-4.81-0.87-7.21-1.31l0,0c2.42,2.62,4.83,5.24,7.25,7.86c0.23,0.24,0.47,0.47,0.7,0.71c0.46,0.29,0.91,0.59,1.37,0.88
		c1.58-0.06,3.16-0.15,4.75-0.18C1128.94,635.45,1129.57,635.45,1130.21,635.45z"
          />
          <path
            className="st3"
            d="M1121.44,627.5c3.71,0,7.42,0,11.13,0c0.03-0.3,0.07-0.6,0.1-0.9c-1.87-0.76-3.75-1.53-5.62-2.29
		c0.06-0.35,0.12-0.69,0.17-1.04c2.77,0,5.53,0,8.3,0c-0.02,0.07-0.04,0.15-0.06,0.22c1.71-0.27,3.41-0.55,5.12-0.82c0,0,0,0,0,0
		c-2.07,1.17-4.17,2.35-6.98,3.93c2.1,0.51,3.19,0.77,4.28,1.03c2.91-0.02,5.83-0.05,8.74-0.07c-1.44-0.75-2.89-1.49-4.33-2.24
		c0.08-0.19,0.16-0.37,0.23-0.56c1.28,0.21,2.56,0.43,4.69,0.79c-1.17-1.41-1.78-2.15-2.28-2.76c-1.19,0.5-2.11,0.89-2.9,1.23
		c-0.54-0.64-0.9-1.05-1.25-1.47c0,0,0.06,0.07,0.06,0.07l0,0c-0.05-0.74-0.1-1.47-0.16-2.21l0,0l0,0l0,0
		c-2.6-0.47-5.19-0.94-7.79-1.41l0,0c-4.54,1.68-9.07,3.38-13.63,5.01c-1.26,0.45-2.62,0.64-4.15,0.38
		c3.75-3.76,8.8-4.66,13.54-6.22c-1-0.2-2-0.41-3-0.61c-0.6,0.21-1.2,0.41-1.8,0.62c-1.2,0.41-2.41,0.83-3.63,1.25
		c-3.07,1.05-6.25,2.15-9.76,3.36c1.09,0.99,1.9,1.72,2.59,2.35c0.27,0.24,0.51,0.47,0.76,0.69c0.14,0.13,0.28,0.25,0.42,0.38
		C1116.63,626.63,1119.04,627.07,1121.44,627.5z"
          />
          <path
            className="st3"
            d="M1153,638.94C1153,638.94,1153,638.94,1153,638.94c-3.36-0.44-6.72-0.87-10.08-1.31
		c-2.76,0.78-5.48,1.81-8.29,2.25c-1.72,0.27-3.57-0.35-5.36-0.57c1.34-1.81,2.09-2.83,2.84-3.84c0,0,0,0,0,0
		c-0.63,0-1.27-0.01-1.9-0.02c-0.63-0.01-1.27-0.01-1.9,0.01c-1.58,0.03-3.16,0.12-4.75,0.18c3.36,3.58,6.71,7.16,9.57,10.21
		c5.27-1.83,10.2-3.55,15.04-5.23C1149.79,640.06,1151.39,639.5,1153,638.94z"
          />
          <path
            className="st12"
            d="M1140.94,616.45c1.25,1.71,2.14,2.92,2.95,4.03c1.09-0.77,2.32-1.64,3.54-2.51c0.2,0.23,0.4,0.47,0.6,0.7
		c-0.29,0.59-0.59,1.17-0.88,1.76c1.96-0.48,3.93-0.96,5.89-1.43c-1.01-1.18-2.03-2.35-3.04-3.53c-0.36,0-0.72,0-1.09,0
		c-0.36,0-0.72,0-1.08,0c0.02,0.09,0.03,0.18,0.05,0.28c0,0.02,0.01,0.05,0.01,0.08c0.03,0.2,0.07,0.44,0.13,0.76
		c0.03,0.17,0.06,0.37,0.1,0.6c-0.98-0.84-1.49-1.29-2-1.73l-0.27-0.06c0,0-0.01-0.07-0.03-0.14l-0.03-0.13
		c-0.26-0.08-0.53-0.16-0.85-0.26c-0.32-0.1-0.71-0.21-1.22-0.37c0.61,1.17,1.01,1.94,1.41,2.71c-0.21,0.2-0.42,0.39-0.63,0.59
		c-0.88-0.77-1.76-1.53-2.73-2.39c-2.29-0.44-4.15,0.29-5.87,1.4c-0.52,0.33-1.02,0.69-1.52,1.07c-0.17,0.12-0.33,0.25-0.5,0.38
		c-0.33,0.25-0.66,0.51-1,0.76l0,0c2.6,0.47,5.19,0.94,7.79,1.41l0,0c0.02-0.26,0.03-0.53,0.05-0.81
		C1140.79,618.78,1140.85,617.84,1140.94,616.45z"
          />
          <polygon
            className="st12"
            points="1147.15,620.43 1147.15,620.43 1147.15,620.43 	"
          />
          <path
            className="st3"
            d="M1147.15,620.43L1147.15,620.43c0.28,0.1,0.56,0.19,0.84,0.29c0.84,0.29,1.72,0.6,2.69,0.94
		c-0.45,1.03-0.95,2.18-1.66,3.83c2.73-0.55,4.81-0.96,6.89-1.38c0.07,0.33,0.15,0.67,0.22,1c-0.78,0.22-1.56,0.44-2.35,0.66
		c0.23,0.58,0.44,1.13,0.65,1.66c0.21,0.53,0.41,1.04,0.61,1.56l0,0v0c0.99-0.22,1.97-0.43,2.96-0.65c0.08-0.32,0.15-0.65,0.23-0.97
		c0.4,0.32,0.79,0.65,1.19,0.97c0.66,0.18,1.32,0.35,1.98,0.53c0.26,0.07,0.52,0.14,0.78,0.21v0c-3.05-3.36-6.1-6.72-9.14-10.08
		C1151.08,619.48,1149.12,619.95,1147.15,620.43L1147.15,620.43z"
          />
          <path
            className="st3"
            d="M1140.2,603.41c-0.66,6.67-4.73,6.24-9.22,4.8c-0.17,1.53-0.31,2.76-0.52,4.57c4.63-1.62,9.02-3.15,13.77-4.81
		C1142.7,606.25,1141.71,605.13,1140.2,603.41z"
          />
          <path
            className="st8"
            d="M1118.96,635.93c4.73,5.15,9.45,10.3,14.24,15.52c0.5-0.57,1.11-1.21,1.07-1.25
		c-4.73-5.1-9.52-10.16-14.3-15.23C1119.64,635.29,1119.3,635.61,1118.96,635.93z"
          />
          <path
            className="st4"
            d="M1137.83,647.24c0.18,0.54,0.36,1.07,0.54,1.61c5.05-1.8,10.09-3.6,15.14-5.4c-0.13-0.34-0.26-0.68-0.39-1.01
		C1148.22,644.62,1142.48,644.3,1137.83,647.24z"
          />
          <path
            className="st3"
            d="M1138.62,613.89L1138.62,613.89L1138.62,613.89c0.89-0.1,1.78-0.24,2.67-0.36s1.78-0.19,2.66-0.15
		c0.64,0.03,1.23,1.13,1.85,1.74c0,0,0.01,0.06,0.03,0.13c0.01,0.07,0.03,0.14,0.03,0.14l0.27,0.06c0.57,0,1.14,0.01,1.71,0.01
		c0.36,0,0.72,0,1.08,0c0.36,0,0.72,0,1.09,0c-1.54-1.88-3.07-3.75-4.54-5.54c-2.54,1.11-5.06,2.21-7.59,3.32
		C1138.13,613.46,1138.37,613.67,1138.62,613.89z"
          />
          <path
            className="st4"
            d="M1105.59,615.47c-0.15,0.35-0.3,0.69-0.45,1.04c1.34,1.46,2.68,2.93,4.3,4.69c1.46-0.84,2.81-1.61,4.15-2.38
		C1110.68,617.61,1108.14,616.54,1105.59,615.47z"
          />
          <path
            className="st12"
            d="M1138.62,613.89L1138.62,613.89c-0.25-0.21-0.5-0.43-0.75-0.64v0c-4.07,1.44-8.14,2.88-12.22,4.32
		c0,0,0,0,0,0c1,0.2,2,0.41,3,0.61C1131.98,616.74,1135.3,615.31,1138.62,613.89z"
          />
          <path
            className="st12"
            d="M1121.48,616.05c2.54-1.04,5.08-2.08,7.62-3.12c-2.25-0.68-4.47-1.34-6.53-1.96
		c-0.4,1.49-0.79,2.93-1.18,4.37C1121.42,615.58,1121.45,615.81,1121.48,616.05z"
          />
          <path
            className="st3"
            d="M1121.39,615.34c-2.33-0.47-4.66-0.94-6.97-1.41c0,1.14,0,2.63,0,4.05c2.42-0.66,4.74-1.3,7.06-1.93
		C1121.45,615.81,1121.42,615.58,1121.39,615.34z"
          />
          <path
            className="st8"
            d="M1113.84,629.35c-0.12,0.13-0.24,0.26-0.35,0.38c0.31,0.38,0.61,0.76,0.92,1.14l0,0
		c1.01,0.97,2.02,1.93,3.04,2.9c0.19-0.22,0.39-0.44,0.58-0.66c-0.97-0.92-1.93-1.84-2.9-2.76
		C1114.7,630.02,1114.27,629.69,1113.84,629.35z"
          />
          <path
            className="st8"
            d="M1112.34,628c0.14,0.3,0.29,0.6,0.43,0.9c0.12-0.06,0.23-0.11,0.35-0.17c-0.15-0.3-0.3-0.59-0.45-0.89
		C1112.57,627.88,1112.46,627.94,1112.34,628z"
          />
          <path
            className="st6"
            d="M1121.48,634.05c0.23,0.24,0.47,0.47,0.7,0.71"
          />
          <path
            className="st4"
            d="M70.94,920.78c4.5,2.96,16.04-1.18,17.84-6.2C82.62,913.78,74.09,915.81,70.94,920.78z"
          />
          <path
            className="st4"
            d="M91.82,900.14c-1,0.35-1.68,1.64-2.5,2.51c0.26,0.37,0.52,0.73,0.77,1.1c1.79-0.52,3.58-1.03,5.37-1.55
		c-0.01-0.57-0.01-1.14-0.02-1.71C94.23,900.34,92.87,899.79,91.82,900.14z"
          />
          <path
            className="st4"
            d="M56.32,833.22c-0.74,5.65-2.17,11.21-2.91,16.85c-0.23,1.74,0.82,3.64,1.23,5.26
		c0.82-4.84,1.53-9.5,2.42-14.14c0.99-5.15,2.63-10.22,3.1-15.41c0.41-4.63-4.02-8.2-8.62-7.39
		C58.35,821.85,57.04,827.73,56.32,833.22z"
          />
          <path
            className="st14"
            d="M708.48,534.61c9.21,7.78,20.04,10.99,31.74,12.03c8.36,0.75,16.72,1.51,25.08,2.22
		c8.56,0.73,16.73-0.9,24.6-4.33c-6.13,0.26-12.23,0.88-18.34,1.3c-2.24,0.15-4.91,0.99-6.13-2.08c0.24-1.16,0.48-2.3,0.78-3.75
		c-1.49,0.42-2.43,0.55-3.26,0.94c-3.32,1.53-4.5-0.08-5.29-3.12c-2.8-10.76-5.85-21.45-8.62-32.22c-0.71-2.75-0.7-5.69-1.11-9.34
		c12.26-0.94,23.68-1.82,35.1-2.7c-19.93-2.92-51.16-0.98-63.54,8.39c8.36,0,16.82,0,26.24,0c2.63,11.51,5.21,22.83,8.03,35.19
		c-11.22,0-21.65,0-32.44,0c-0.23-2.15-0.44-4.1-0.63-5.92c-1.77,0-3.44,0-5.14,0c0-9.38,0-18.38,0-27.79
		c-1.94,1.52-3.47,2.77-5.06,3.95C701.38,514.12,700.48,527.85,708.48,534.61z"
          />
          <path
            className="st3"
            d="M776.44,497.3c-5.91,0.05-11.82,0.3-17.72,0.68c-2.83,0.18-5.63,0.78-8.56,1.21
		c3.22,11.09,6.31,21.68,9.34,32.12c10.54-5.31,21.1-2.91,32.17-2.65c-2.76-7.37-5.37-14.33-7.97-21.3
		c-0.39,0.1-0.78,0.21-1.17,0.31c0,2.19,0,4.38,0,6.57c-0.3,0.2-0.61,0.41-0.91,0.61c-1.57-1.66-3.13-3.32-4.63-4.91
		c0,1.84,0,3.76,0,5.67c-0.31,0.05-0.62,0.1-0.93,0.14c-0.6-1.81-1.19-3.63-1.81-5.49c-0.99-0.11-2.02-0.23-3.6-0.41
		c0.58-1.12,0.98-2.62,1.42-2.63c1.55-0.03,3.1,0.44,4.09,0.62c1.29-0.25,2.27-0.26,3.08-0.65c0.96-0.46,1.77-1.25,2.63-1.88
		c-0.46-1.14-0.84-2.08-1.32-3.27c-7.94,0.97-15.8,1.93-23.66,2.89c-0.07-0.41-0.14-0.83-0.21-1.24c7.35-2.57,15.29-2.34,24.05-4.28
		C778.75,498.4,777.59,497.29,776.44,497.3z M762.77,527.52c-0.29-0.45-0.59-0.9-0.88-1.35c0.83-0.5,1.67-1.01,2.32-1.4
		c-0.36-1.73-0.71-3.44-1.06-5.14c0.35-0.1,0.69-0.21,1.04-0.31c0.55,1.5,1.1,3,1.69,4.61c0.91,0.41,1.93,0.87,2.94,1.33
		c0.02,0.29,0.04,0.58,0.06,0.87C766.84,526.58,764.81,527.05,762.77,527.52z M786.07,519.12c0.66,1.92,1.33,3.83,2.08,6.01
		c-1.63,1.35-4.94-0.24-7.66-4.56C782.16,518.62,783.7,516.44,786.07,519.12z M771.28,521.29c0.59-0.87,1.4-3.04,2.23-3.05
		c1.51-0.02,3.03,1.25,5.27,2.32c-0.81,1.8-1.52,3.52-2.44,5.11c-0.07,0.13-1.67-0.63-2.1-0.8c0.94-1.36,1.58-2.29,2.2-3.19
		c-1.87,0.56-3.39,1.01-5.29,1.58c-0.02,0.28-0.11,1.32-0.19,2.35c-0.2,0.07-0.4,0.14-0.6,0.21c-1.03-2.33-2.07-4.66-3.1-6.99
		c0.4-0.29,0.8-0.58,1.2-0.86C769.33,519,770.21,520.03,771.28,521.29z M767.6,509.7c0.45-1.01,0.78-1.74,1.1-2.48
		c0.39,0.01,0.78,0.03,1.17,0.04c0.22,3,0.44,6,0.66,9c-0.36,0.15-0.72,0.3-1.08,0.46c-3.19-1.8-4.4-5-5.91-8.61
		C765.21,508.76,766.3,509.19,767.6,509.7z M757.58,509.92c0.77,0.17,1.55,0.34,2.41,0.53c0.35-0.45,0.81-1.03,1.33-1.7
		c1.43,2.83,2.76,5.45,4.59,9.05c-1.96-0.9-2.9-1.33-3.81-1.74c-0.34,0.77-0.69,1.58-1.05,2.38c-0.16-0.02-0.33-0.04-0.49-0.06
		c0-1.32,0-2.63,0-3.64c-1.29-1.6-2.34-2.89-3.38-4.18C757.31,510.35,757.44,510.13,757.58,509.92z"
          />
          <path
            className="st4"
            d="M839.42,532.61c-1.96,0.53-3.48,0.95-4.46,1.22c0.55,1.28,1.08,2.5,1.75,4.04c-1.68,0.14-2.65,0.22-3.88,0.33
		c1.55,0.79,2.82,1.44,4.09,2.09c-3.58,0.77-6.05-0.44-7.81-4.01c1.84-0.23,3.28-0.4,5.48-0.67c-3.19-0.76-5.62-1.33-8.15-1.93
		c0.73,2.01,1.35,3.72,2.28,6.29c-2.14-0.77-3.58-1.28-5.04-1.8c-0.56,0.45-1.18,0.94-2.07,1.64c-2.34-2.69-4.55-5.23-6.75-7.77
		c-0.21,0.21-0.42,0.41-0.63,0.62c0.59,0.71,1.19,1.43,1.14,1.37c0,2.32,0,3.78,0,5.24c-3.59-0.4-5.88-6.36-10.53-1.37
		c0.06-1.36,0.09-1.98,0.12-2.61c1.46-0.07,2.62-0.13,3.91-0.19c-0.34-0.77-0.61-1.4-1-2.3c-3.3,1.19-6.49,2.34-9.99,3.6
		c-0.62-1.31-1.4-2.94-2.11-4.42c-1.57,0.24-3.1,0.47-4.88,0.74c0.99,1.38,1.72,2.39,2.84,3.95c-8.51,0.83-16.51,1.6-24.81,2.41
		c0.46,1.46,0.73,2.3,1.04,3.3c2.02,0,3.69,0.08,5.36-0.01c12.05-0.69,24.09-1.98,36.13-1.93c8.26,0.04,16.54,1.81,24.77,3.02
		c3.83,0.56,7.58,1.64,11.66,2.55c-2.27-6.44-4.3-12.36-6.45-18.23c-1.96-5.36-4.21-10.62-6.01-16.03c-1.06-3.19-2.9-4.17-6.35-3.54
		C833.87,515.95,831.65,526.21,839.42,532.61z M841.94,540.39c0.06-1.17,0.12-2.33,0.18-3.5c0.2-0.05,0.4-0.11,0.59-0.16
		c0.43,1.23,0.86,2.47,1.52,4.35c-1.34,0.39-2.95,1.45-3.9,1.03c-2.37-1.04-3.15-3.25-2.53-5.42c1.01,1.34,2.16,2.87,3.32,4.41
		C841.4,540.85,841.67,540.62,841.94,540.39z"
          />
          <path
            className="st3"
            d="M782.63,500.05c8.03-1.87,15.71-4.95,23.94-2.44c-4.27,0.37-8.55,0.74-12.82,1.11
		c4.63-0.43,7.17,2.78,10.1,5.85c1.2-1.72,2.28-3.26,3.55-5.08c0.83-0.24,2.05-0.59,3.21-0.92c2.43,3.6,2.41,4.72,0.21,8.42
		c2.17,2.39,4.24,4.66,6.35,6.99c-4.77,4.16-8.46,0.99-12.23-0.05c-0.72,1.6-1.37,3.07-2.15,4.81c-1.72,0.3-3.65,0.63-5.41,0.93
		c-2.64-3.79-2.57-4.7,0.06-7.68c0.65-0.74,1.05-1.71,1.56-2.58c-5.05-1.32-7.63-4.5-7.11-9.96c-2.93,0.78-5.62,1.49-7.71,2.05
		c3.79,8.66,7.61,17.38,11.41,26.04c0.32-0.28,1.32-0.91,1.98-1.8c2.6-3.49,5.84-4.14,10.19-4c5.11,0.18,10.28-1.32,15.46-2.09
		c-2.94-7.25-5.71-13.69-8.1-20.26c-0.66-1.82-1.54-2.44-3.22-3.03c-10.42-3.65-20.66-1.2-31.38,0.35
		C781.42,498.14,782.03,499.11,782.63,500.05z"
          />
          <path
            className="st3"
            d="M799.07,509.42c-0.02-0.01-0.03-0.03-0.03-0.03c-0.01,0.01-0.01,0.02-0.02,0.04c0.03,0.01,0.06,0.02,0.09,0.03
		L799.07,509.42z"
          />
          <path
            className="st3"
            d="M724.08,517c0.31,0.76,0.55,1.33,0.79,1.94c0.38-0.67,0.67-1.18,1.1-1.93c0.69,1.44,1.19,2.5,1.73,3.63
		c0.39-1.2,0.72-2.21,1.15-3.5c0.58,0.8,0.95,1.32,1.01,1.4c2.29,0.18,3.96,0.3,5.74,0.44c0.23,1.21,0.4,2.11,0.46,2.39
		c0.74-0.23,1.52-0.47,2.31-0.72c0,0,0,0,0,0c0.27,0.47,0.55,0.94,0.82,1.41c0.14-0.06,0.29-0.12,0.43-0.18
		c-0.24-0.6-0.49-1.19-0.73-1.79c0.27-0.86,0.53-1.71,0.9-2.88c2.33,3.51-0.13,8.14,3.9,10.39c-1.49-7.17-2.97-14.34-4.44-21.41
		c-6.97,0-13.92,0-21.1,0c-0.29,5.52,0.07,10.45,3.77,15.13C722.74,519.69,723.37,518.43,724.08,517z"
          />
          <path
            className="st3"
            d="M729.86,518.54L729.86,518.54C729.88,518.56,729.87,518.56,729.86,518.54z"
          />
          <path
            className="st3"
            d="M828.14,528c-0.7-1.83-1.41-3.67-2.13-5.54c-0.76,0.39-1.27,0.65-1.62,0.82c0.27,1.38,0.51,2.63,0.75,3.88
		c-0.21,0.08-0.43,0.17-0.64,0.25c-0.83-1.49-1.66-2.99-2.49-4.48c-0.18,0.11-0.35,0.23-0.53,0.34c0.14,1.17,0.28,2.34,0.45,3.8
		c-1.43,0.08-2.44,0.14-3.43,0.19c-0.73-1.24-1.34-2.28-2.3-3.91c-0.27,1.91-0.45,3.2-0.78,5.49c-0.89-2.16-1.35-3.27-1.81-4.37
		c-0.2,0.05-0.4,0.09-0.61,0.14c0.06,1.08,0.13,2.17,0.19,3.25c-0.24,0.14-0.48,0.29-0.73,0.43c-0.94-1.04-1.88-2.08-2.72-3.01
		c-0.92,1.26-1.82,2.49-2.78,3.81c-1.19-0.66-1.96-1.09-2.74-1.53c-0.2,0.91-0.37,1.75-0.7,3.28c-0.94-1.53-1.5-2.44-2.15-3.51
		c-0.85,0.97-1.62,1.84-2.38,2.71c0.41,1.03,0.75,1.91,1.1,2.79c6.93-3.28,12.84-4.27,20.18-3.39c4.46,0.54,8.91,1.13,12.91,1.64
		c-3.11-9.93-6.12-19.53-9.21-29.37c-1.42,0-3.04,0-4.98,0c3.4,8.89,8.66,16.74,10.1,25.93C828.79,527.77,828.46,527.89,828.14,528z
		"
          />
          <path
            className="st11"
            d="M720.28,569.53c5.92-4.01,11.84-8.02,17.76-12.03c-6.16-1.46-12.31-2.92-17.76-4.21c0,3.13,0,6.5,0,9.95
		C720.28,565.32,720.28,567.43,720.28,569.53L720.28,569.53z"
          />
          <path
            className="st4"
            d="M787.31,533.08c-3.36-4.24-20.95-3.06-27.03,2.09c0.07,1.29,0.15,2.67,0.2,3.37
		C769.49,536.71,778.34,534.91,787.31,533.08z"
          />
          <path
            className="st4"
            d="M744.39,530.87c-5.09,0.27-10.18,0.51-15.27,0.72c-1.73,0.07-3.46,0.01-5.32,0.01
		c0.29,1.62,0.5,2.78,0.72,4.03c8.35-0.48,16.54-0.94,25.01-1.42c-0.74-2.07-1.42-3.98-2.11-5.89
		C747.6,530.45,746.07,530.79,744.39,530.87z"
          />
          <path
            className="st4"
            d="M747.41,528.05l-0.06,0.05c0.03,0.07,0.05,0.14,0.08,0.21c0,0,0-0.01,0-0.01
		C747.41,528.22,747.42,528.15,747.41,528.05z"
          />
          <path
            className="st14"
            d="M810.19,571.24c0-8.29,0-16.01,0-24.04c-2.01,0.69-3.64,1.25-5.27,1.82
		C806.67,556.41,808.37,563.58,810.19,571.24z"
          />
          <path
            className="st4"
            d="M809.29,500.81c-3.83,5.47-7.49,10.7-11.16,15.93c0.35,0.32,0.71,0.64,1.06,0.96
		c1.96-2.27,3.92-4.55,5.11-5.94c3.73-0.78,5.86,3.91,9.19,0.66c-2.44-1.51-4.55-2.83-6.98-4.33c1.29-1.96,2.43-3.68,3.7-5.62
		C810.05,502.18,809.72,501.58,809.29,500.81z"
          />
          <path
            className="st13"
            d="M716.44,552.66c-0.32-0.98-1.54-1.93-2.25-2.89c0,0,0,0,0,0c-0.27-0.24-0.55-0.49-0.82-0.73
		c-0.12,0.1-0.24,0.2-0.37,0.3c0.18,0.37,0.36,0.74,0.53,1.12c0.17,2.15,0.27,4.31,0.39,6.47c0.03,0.43,0.05,0.86,0.08,1.29
		c0.06,0.86,0.12,1.72,0.2,2.58c0.32,3.44,0.89,6.84,2.14,10.15c0.28-5.97,0.4-11.92,0.17-17.86
		C716.52,552.94,716.49,552.8,716.44,552.66z"
          />
          <path
            className="st4"
            d="M795.27,501.8c-0.3,0.38-0.61,0.76-0.91,1.14c0.56,0.81,1.09,1.62,1.67,2.41c0.99,1.35,2,2.69,3.01,4.03
		c1.17-1.28,2.34-2.57,3.58-3.92C800.03,504.16,797.65,502.98,795.27,501.8z"
          />
          <path
            className="st4"
            d="M799.1,509.45c-0.02-0.03-0.04-0.05-0.06-0.08c0,0-0.01,0.01-0.01,0.01c0,0,0,0,0,0c0,0,0.02,0.01,0.03,0.03
		C799.08,509.44,799.1,509.45,799.1,509.45z"
          />
          <path
            className="st4"
            d="M744.41,505.55c-0.19-0.66-0.42-1.31-0.7-1.95c-0.61,0.14-1.23,0.27-1.84,0.41
		c1.37,5.38,2.74,10.77,4.11,16.15c0.12-0.11,0.24-0.21,0.37-0.31C745.24,515.19,745.77,510.16,744.41,505.55z"
          />
          <path
            className="st3"
            d="M778.93,557.33c1.74-0.17,3.37-1.56,5.05-2.39c-5.35,0.82-10.69,1.63-16.04,2.45
		C771.6,557.4,775.29,557.69,778.93,557.33z"
          />
          <path
            className="st4"
            d="M825.46,535.81c0-1.08,0-1.78,0-2.65c-2.59-0.3-5.12-0.6-8.69-1.02c1.6,1.87,2.42,2.81,3.44,4
		c0.58-0.9,1.06-1.65,1.55-2.4C823,534.44,824.14,535.07,825.46,535.81z"
          />
          <path
            className="st14"
            d="M725.98,550.18c-0.57-0.23-1.14-0.46-1.72-0.69c-0.05,0.19-0.11,0.37-0.16,0.56
		c0.61,0.07,1.22,0.13,1.83,0.19c5.61,2.02,11.35,3.11,17.33,2.43C737.5,551.84,731.74,551.01,725.98,550.18L725.98,550.18z"
          />
          <path
            className="st4"
            d="M745.98,520.16c0.41,2.26,0.82,4.51,1.22,6.77c-0.28-2.36-0.57-4.72-0.86-7.08
		C746.22,519.95,746.1,520.05,745.98,520.16z"
          />
          <path
            className="st4"
            d="M747.21,526.93c0.05,0.39,0.1,0.78,0.14,1.18c0,0,0.06-0.05,0.06-0.05c-0.07-0.36-0.13-0.73-0.2-1.09
		C747.21,526.95,747.21,526.94,747.21,526.93z"
          />
          <path
            className="st14"
            d="M932.35,518.21c-0.07,0.18-0.37,0.26-1.59,1.06c0-2.54,0-4.44,0-6.35c-2.06-0.5-4.11-1-6.17-1.49
		c0.16,1.11,0.33,2.22,0.49,3.33c-0.19,0.11-0.38,0.21-0.57,0.32c-0.94-1.29-2.05-2.48-2.79-3.87c-2.79-5.26-7.54-7.4-13.34-5.47
		c-3.41,1.14-6.62,2.89-9.96,4.25c-0.96,0.39-2.08,0.39-4.11,0.73c1.07-1.77,1.48-2.45,1.46-2.41c-1.57-2.59-3.01-4.79-4.27-7.1
		c-2.67-4.92-5.59-9.53-10.96-12.05c-2.09-0.98-2.26-1.72-1.95-3.49c-5.52-1.73-8.5-0.53-12.38,4.32c-1.09,1.36-3.15,1.94-4.76,2.87
		c0.22,0.73,0.45,1.45,0.72,2.15c0.65,1.76,1.46,3.43,2.38,5.03c1.65,2.88,3.7,5.52,6.11,7.93h0c0,0,0,0,0,0
		c0.72-0.24,1.44-0.48,2.16-0.71c2.81-2.79,6.77-4.33,9.98-4.24c1.07,0.03,2.06,0.24,2.89,0.65c-3.44,1.34-7.11,2.77-10.78,4.2
		c2.15,1.67,4.3,3.34,6.44,5.01c0.44,0.02,0.87,0.05,1.3,0.07c1.3,0.07,2.57,0.14,3.76,0.21c2.71,6.62,3.97,8.45,8.58,9.28
		c2.83,0.51,5.92-0.38,8.89-0.69c1.28-0.13,2.54-0.4,4.49-0.71c-2.09,3.93-8.39,6.23-12.17,5.28c-5.02-1.26-8.56-4.17-10.6-9.01
		c-0.34-0.81-1.48-1.28-2.4-1.85c-0.13-0.08-0.26-0.16-0.38-0.25c0.33,0.64,0.49,1.13,0.52,1.52s-0.08,0.67-0.27,0.88
		c-0.63,0.71-2.22,0.63-3.13,1.12c9.44,8.63,20.75,10.96,32.91,8.83c5.7-1,11.09-3.64,16.71-5.26c1.6-0.46,3.49,0.05,5.78,0.14
		c0.77-1.3,2.11-3.59,3.46-5.87c-1.65-0.75-3.3-1.5-4.95-2.25C933.36,515.63,932.88,516.93,932.35,518.21z M880.43,495.35
		c0.26,0.23,0.51,0.46,1.55,1.4c-1.29-0.57-1.66-0.74-2.04-0.91c-2.33-0.37-4.66-0.75-6.98-1.12
		C875.65,493.43,878.22,492.98,880.43,495.35z M871.7,503.82c1.96-3.74,5.35-3.66,8.65-4.34c-2.63-0.46-5.25-0.92-8.83-1.55
		c6.21-1.41,8.37-0.63,11.92,3.68C879.51,502.35,875.6,503.09,871.7,503.82z M912.58,526.31c0.19-4.71,1.94-9.26,3.65-9.5
		C917.28,524.29,916.58,526.1,912.58,526.31z"
          />
          <path
            className="st12"
            d="M861.46,492.86c1.61-0.94,3.68-1.52,4.76-2.87c3.88-4.85,6.86-6.05,12.38-4.32
		c8.19,1.01,12.44,6.75,16.15,13.51c2.17,0,4.5,0,5.86,0c5.31-3.73,10.01-7.05,14.76-10.32c0.25-0.17,0.89,0.01,1.21,0.22
		c0.34,0.23,0.52,0.7,0.71,0.98c-2.62,1.99-5.09,3.87-7.57,5.74c0.44-0.09,0.88-0.2,1.31-0.3c0.33-0.07,0.65-0.15,0.98-0.21
		c0.26-0.05,0.51-0.09,0.77-0.12c2.54-0.29,4.65,0.12,5.42,3.1c0.52,2.03,1.32,3.98,1.99,5.97c1.16,1.95,2.32,3.9,3.48,5.86
		c0.29,0.42,0.58,0.84,0.87,1.27c0,0,0.05,0.05,0.05,0.05h0c2.06,0.5,4.11,1,6.17,1.49c-0.42-1.26-0.83-2.53-1.24-3.78
		c1.18-0.13,1.77-0.19,2.31,0.46c0.14,0.16,0.27,0.37,0.41,0.63c0.41,0.79,0.87,2.06,1.61,4.11c1.65,0.75,3.3,1.5,4.95,2.25
		c2.97-4.64,0.94-11.4-4.1-13.71c-1.95-0.89-3.26-2.09-3.16-4.57c0.18-4.62-1.55-8.69-4.38-12.27c-5.93-7.52-17.62-8.01-23.11-0.72
		c-6.09-5.97-13.29-8.89-21.97-8.22c-8.65,0.67-15.43,4.7-21.72,10.61c0.19,0.89,0.38,1.76,0.56,2.61
		C861.1,491.17,861.28,492.01,861.46,492.86z"
          />
          <path
            className="st14"
            d="M866.36,518.12c9.84,17.49,52.51,22.17,65.38,6.92c-12.75,5.14-25.73,8.27-39.27,4.16
		c-13.55-4.11-23.06-13.69-31.41-24.66c-0.29,0.26-0.59,0.52-0.88,0.78c3.1,4.03,6.19,8.05,9.53,12.39
		C868.15,517.9,867.31,518,866.36,518.12z"
          />
          <path
            className="st4"
            d="M857.01,509.63c0.88-0.79,0.81-2.65,1.17-4.02c-0.4-0.08-0.81-0.16-1.21-0.24c-5.92,6.1-7.17,9.35-5.94,15.15
		c1-0.83,2.06-1.71,3.22-2.68C853.86,514.75,854.4,511.97,857.01,509.63z"
          />
          <path
            className="st5"
            d="M871,533c0.35-1.72,0.66-3.22,0.98-4.76c-2.39-2.22-4.99-4.63-7.53-6.98C863.9,526.88,866.28,531.34,871,533z"
          />
          <path
            className="st5"
            d="M878.89,531.51c0.88,4.42,2.82,7.34,7.14,8.89c-0.84-2.47-1.54-4.48-2.24-6.55
		C882.29,533.13,880.76,532.4,878.89,531.51z"
          />
          <path
            className="st15"
            d="M883.34,516.74c-0.03-0.39-0.18-0.89-0.52-1.52c0,0,0,0,0,0c-3-0.5-5.99-1.01-8.99-1.51
		c-0.11,0.21-0.22,0.42-0.33,0.63c2.15,1.47,4.29,2.94,6.44,4.41c0.91-0.48,2.5-0.41,3.13-1.12
		C883.26,517.42,883.37,517.13,883.34,516.74z"
          />
          <path
            className="st12"
            d="M874.91,507.87c-0.7-0.21-1.39-0.41-2.09-0.62c-0.72,0.24-1.44,0.48-2.16,0.71c0,0,0,0,0,0
		c2.91,3.06,6.6,4.42,10.69,4.91C879.2,511.21,877.06,509.54,874.91,507.87z"
          />
          <path
            className="st4"
            d="M847.83,512.6c2.23-3.18,4.43-6.31,6.53-9.29C850.25,503.02,846.08,508.72,847.83,512.6z"
          />
          <path
            className="st5"
            d="M873.82,529.53c1.33,8.09,1.56,8.53,4.84,9.54c-0.83-2.69-1.69-5.49-2.73-8.87
		C875.8,530.16,874.87,529.86,873.82,529.53z"
          />
          <path
            className="st5"
            d="M886.78,535.3c-0.22,0.29-0.45,0.58-0.67,0.88c1.8,1.81,3.6,3.62,5.39,5.43c0.44-0.29,0.88-0.57,1.32-0.86
		c-0.06-1.21-0.11-2.42-0.16-3.62C890.46,536.44,888.62,535.87,886.78,535.3z"
          />
          <path
            className="st4"
            d="M862.97,518.36c-0.31-0.59-0.79-1.49-1.26-2.39c-0.29,0.01-0.59,0.01-0.88,0.02c-0.23,2.69-0.47,5.39-0.7,8.08
		c0.18-0.02,0.37-0.03,0.55-0.05C861.41,522.21,862.14,520.4,862.97,518.36z"
          />
          <path
            className="st4"
            d="M849.95,500.76c1.76-0.26,3.52-0.51,5.5-0.8c-0.02-0.41-0.05-1.06-0.08-1.76c-0.48-0.05-0.95-0.12-1.41-0.17
		c-0.31-0.03-0.61-0.06-0.9-0.07c-0.29-0.01-0.57,0-0.83,0.03C851.06,498.15,850.18,498.81,849.95,500.76z"
          />
          <path
            className="st14"
            d="M864.69,516.32C864.52,516.04,864.59,516.15,864.69,516.32L864.69,516.32z"
          />
          <path
            className="st14"
            d="M864.75,516.42c-0.02-0.04-0.04-0.07-0.06-0.1C864.71,516.35,864.73,516.38,864.75,516.42
		C864.91,515.79,864.83,516.11,864.75,516.42z"
          />
          <path
            className="st3"
            d="M916.5,539.99c-0.06,0.01-0.1,0.02-0.12,0.02c0.03,0,0.07-0.01,0.13-0.02
		C916.32,539.98,916.22,539.97,916.5,539.99z"
          />
          <path
            className="st3"
            d="M916.38,540.01C916.35,540.02,916.35,540.02,916.38,540.01L916.38,540.01z"
          />
          <path
            className="st3"
            d="M916.75,539.95c-0.08,0.01-0.16,0.03-0.23,0.04c0.05,0,0.12,0.01,0.18,0.01
		C916.7,539.99,916.75,539.95,916.75,539.95z"
          />
          <path
            className="st4"
            d="M849.56,501.29c0.09-0.15,0.18-0.29,0.27-0.44c-0.14,0-0.28-0.01-0.42-0.01
		C849.46,500.99,849.51,501.14,849.56,501.29z"
          />
          <path
            className="st4"
            d="M849.94,500.86L849.94,500.86c0-0.03,0.01-0.06,0.01-0.09c0,0,0,0,0,0c-0.02,0-0.05,0.01-0.07,0.01
		c-0.02,0.03-0.03,0.05-0.05,0.08c0,0,0,0,0,0C849.87,500.86,849.9,500.86,849.94,500.86z"
          />
          <path
            className="st4"
            d="M834.84,484.97c0.97,0.95,1.84,1.8,2.55,2.5c7.29-3.79,7.32-9.6,6.67-15.75
		C841.13,475.93,838.24,480.08,834.84,484.97z"
          />
          <path
            className="st25"
            d="M646.44,211.88c-0.5-0.17-1.01-0.34-1.51-0.51"
          />
          <path
            className="st10"
            d="M461.23,448.66c0.56,5.16,1.11,10.25,1.67,15.4c5.05-2.25,9.57-4.27,14.44-6.44
		c-0.54-5.47-1.06-10.86-1.66-16.97C470.62,443.45,466.12,445.95,461.23,448.66z"
          />
          <path
            className="st10"
            d="M480.62,451.12c0.32-6.5,0.62-12.68,0.94-19.33c-5.05,0.95-10.34,1.94-15.68,2.94
		c0.95,2.76,1.74,5.07,2.65,7.72c2.92-1.41,5.55-2.68,8.69-4.2c0.61,4.36,1.26,8.91,1.89,13.43
		C479.64,451.48,480.27,451.25,480.62,451.12z"
          />
          <path
            className="st10"
            d="M490.96,446.23c1,2.34,3.01,3.62,5.71,2.62c3.23-1.21,5.67-5.23,5.55-8.92c-3.06-1.71-6.11-3.42-9.17-5.12
		C488.68,439.76,488.56,440.65,490.96,446.23z"
          />
          <path
            className="st9"
            d="M496.86,433.45c-0.81-0.43-2.52,0.86-3.82,1.36c3.06,1.71,6.11,3.42,9.17,5.12
		C503.09,435.63,499.42,434.79,496.86,433.45z"
          />
          <path
            className="st10"
            d="M612.25,264.98c1.53-2.67,2.33-5.75,3.45-8.64c-0.19-0.12-0.38-0.24-0.57-0.37c-0.3-1.98-0.5-3.98-0.91-5.93
		c-0.6-2.82-2.34-5.33-5.23-4.81c-2.67,0.47-5.91,1.92-7.38,4.01c-2.93,4.18-0.23,13.83,3.93,16.82
		C608.13,267.91,610.67,267.74,612.25,264.98z M603.72,253.01c2.18-1.55,3.78-2.68,5.36-3.8c3.34,4.23,2.9,9.44-0.95,13.41
		C604.28,260.45,605.2,256.34,603.72,253.01z"
          />
          <path
            className="st2"
            d="M619.65,288.73c-1.63,0.05-3.25,0.15-4.88,0.26c-1.63,0.11-3.25,0.23-4.88,0.33c-1.13,0.1-2.26,0.2-3.39,0.3
		c0.02,0.42,0.05,0.84,0.07,1.25c15.81-0.85,31.61-1.7,47.42-2.55c-0.01-0.33-0.02-0.66-0.03-0.99c-2.48,0-4.95,0-7.43,0
		c-0.49,0.22-0.97,0.63-1.46,0.65C636.6,288.25,628.12,288.45,619.65,288.73z"
          />
          <path
            className="st1"
            d="M614.3,287.38c-1.11,0.02-2.21,0.72-3.3,1.35c-0.37,0.21-0.73,0.42-1.1,0.58c0,0,0,0,0,0
		c1.63-0.1,3.25-0.22,4.88-0.33s3.25-0.2,4.88-0.26c8.47-0.28,16.95-0.48,25.42-0.76c0.49-0.02,0.97-0.42,1.46-0.65
		C635.78,287.31,625.04,287.22,614.3,287.38z"
          />
          <path
            className="st3"
            d="M93.07,823.84c-0.83,1.32-1.03,3.02-1.53,4.54c2.22-2.35,4.06-4.74,5.6-7.32c2.84-4.76,3.61-14.51-3.84-17.25
		c-0.37-0.23-0.73-0.46-1.1-0.7c-0.09,0.12-0.18,0.23-0.27,0.35c0.26,0.28,0.53,0.57,0.79,0.85
		C97.43,811.03,97.47,816.83,93.07,823.84z"
          />
          <path
            className="st4"
            d="M91.19,809.36c0,3.29,0,6.57,0,10.86C92.98,815.92,93.12,812.63,91.19,809.36z"
          />
          <path
            className="st4"
            d="M165.34,563.54c0.11,2.07,0.2,3.7,0.28,5.33c0.43,0.21,0.85,0.42,1.28,0.64c1.81-1.34,3.62-2.67,5.43-4.01
		c-0.13-0.36-0.27-0.71-0.4-1.07C170.03,564.17,168.12,563.91,165.34,563.54z"
          />
          <path
            className="st3"
            d="M79.56,595.86c1.2,0.83,2.48,1.71,4.08,2.8c1.21-1.88,2.2-3.41,3.24-5.02
		C83.31,590.63,81.08,591.35,79.56,595.86z"
          />
          <path
            className="st13"
            d="M345.01,776.48c3.68,2.8,7.83,5.18,12.1,6.98c4.34,1.84,9.05,2.45,13.47-0.34c-2.57-0.04-5.14,0.03-7.7-0.17
		c-0.81-0.06-1.57-0.8-2.35-1.23h0c-3.85-0.96-7.67-1.96-10.51-4.02c-2.84-2.06-4.71-5.19-4.65-10.41
		C342.53,769.97,342.12,774.28,345.01,776.48z"
          />
          <path
            className="st3"
            d="M348.89,770.45c0.32,1.67,1.45,3.09,3.23,4.19c0.51,0.32,1.07,0.61,1.69,0.87c0.31,0.13,0.63,0.26,0.96,0.38
		c1,0.35,2.1,0.65,3.31,0.88c0.4,0.08,0.82,0.14,1.24,0.21c0.85,0.12,1.74,0.21,2.67,0.27c0.23-1.12,0.47-2.24,0.7-3.35
		c-5.33-0.33-9.77-2.62-13.81-6.36C348.62,768.65,348.71,769.56,348.89,770.45z"
          />
          <path
            className="st3"
            d="M355.19,760.26c0.15,0.16,0.3,0.32,0.45,0.47c0.46,0.45,0.93,0.83,1.43,1.14c1.66,1.02,3.55,1.2,5.59,0.26
		c1.22-1.25,2.43-2.5,3.82-3.92c-2.78-1.39-5.4-1.9-7.65-1.52c-0.75,0.13-1.46,0.35-2.12,0.68c-0.44,0.22-0.86,0.48-1.26,0.78
		c-0.2,0.15-0.39,0.32-0.58,0.49s-0.37,0.36-0.54,0.56l0,0C354.61,759.58,354.89,759.93,355.19,760.26z"
          />
          <path
            className="st12"
            d="M361.98,777.24c0.78,0.09,1.55,0.15,2.31,0.14c1.13-0.01,2.23-0.15,3.28-0.52c1.04-0.36,2.03-0.94,2.94-1.82
		c0.01-1.4,0.02-2.8,0.03-4.2c-2.62,1.01-5.24,2.02-7.86,3.04C362.44,775.01,362.21,776.13,361.98,777.24z"
          />
          <path
            className="st4"
            d="M375.08,760.27c-0.3-2.2-0.6-4.4-0.96-7.14c-1.94,0.74-3.12,1.19-4.53,1.73c1.74,2.32,3.12,4.17,4.51,6.02
		C374.43,760.67,374.75,760.47,375.08,760.27z"
          />
          <path
            className="st4"
            d="M370.5,775.05c1.47-2.8,2.94-5.59,4.41-8.39c-0.43-0.29-0.86-0.58-1.29-0.86c-1.03,1.68-2.06,3.37-3.09,5.05
		C370.52,772.25,370.51,773.65,370.5,775.05z"
          />
          <path
            className="st12"
            d="M360.54,781.72c0.78,0.43,1.54,1.17,2.35,1.23c2.56,0.19,5.13,0.13,7.7,0.17l-0.05-0.05
		c0.01-0.7,0.01-1.39,0.02-2.09C367.21,781.23,363.87,781.47,360.54,781.72z"
          />
          <path
            className="st4"
            d="M370.53,783.07c1.64-1.18,3.28-2.36,4.93-3.54c-0.22-0.35-0.45-0.69-0.67-1.04c-1.41,0.83-2.83,1.66-4.24,2.49
		C370.54,781.68,370.54,782.38,370.53,783.07z"
          />
          <path
            className="st4"
            d="M378.16,771.36c-0.85,2.28-1.7,4.56-2.55,6.85c0.37,0.12,0.73,0.24,1.1,0.36c0.74-2.31,1.47-4.63,2.21-6.94
		C378.66,771.53,378.41,771.45,378.16,771.36z"
          />
          <path
            className="st4"
            d="M189.24,736.75c0,1.03,0.21,2.14-0.04,3.13c-0.67,2.66-1.75,5.59-4.76,5.79c-2.88,0.19-3.44-2.56-3.9-5.01
		c-0.19-1-1.13-1.87-1.94-3.13c-2.04,4.98-1.01,8.91,0.68,13.07c2.14,5.25,8.2,2.71,11.15,6.2c7.89-3.55,7.25-6.56,6.48-12.48
		C196.21,738.89,193.48,736.63,189.24,736.75z"
          />
          <path
            className="st10"
            d="M480.02,344.92c-1.1,0.5-1.89,0.87-2.69,1.23c0.09,4.15-0.08,8.2-5.22,11.18c-0.5-2.53-0.89-4.47-1.28-6.41
		c-2.54,2.7-1.28,5.27,0.54,8.53c2.88-0.81,5.81-1.64,8.65-2.43C480.02,352.84,480.02,349.1,480.02,344.92z"
          />
          <path
            className="st9"
            d="M477.33,346.16c-2.17,1.59-4.33,3.18-6.5,4.77c0.39,1.94,0.77,3.88,1.28,6.41
		C477.25,354.35,477.42,350.31,477.33,346.16z"
          />
          <path
            className="st10"
            d="M476.46,337.66c4.91-3,7.28-7.71,6.6-13.71c-0.33-2.93-3.41-7.39-6.19-7.84c-2.99-0.49-6.37-0.91-8.48,2.02
		c-2.44,3.4-4.6,7.03-3.9,11.71c-0.9,4.05,1.18,6.91,4.81,8.12C471.47,338.68,474.65,338.77,476.46,337.66z M468.87,330.17
		c-2.39-4.23-0.45-8.21,2.2-11.92c2.96-0.35,6.28-0.37,7.65,2.4c2.31,4.67,0.07,8.72-4.31,13.14
		C472.62,332.68,469.86,331.93,468.87,330.17z"
          />
          <Link to="/">
            <path
              onMouseOver={() => {
                setClassNameDoor("hover");
                setClassNameDoor1("hover");
              }}
              onMouseLeave={() => {
                setClassNameDoor("couch7");
                setClassNameDoor1("couch4");
              }}
              onMouseDown={() => {
                setClassNameDoor("clicked");
                setClassNameDoor1("clicked");
              }}
              className={classNameDoor1}
              d="M337.55,483.48c1.02-2.56,2.81-4.93,2.68-7.21c-0.2-3.53-3.02-5.51-6.97-6.29
		C333.63,474.77,331.79,479.69,337.55,483.48z"
            />
          </Link>
          <path
            className="st9"
            d="M561.82,265.78c1.89,2.25,3.37,4.62,6.75,3.28C566.08,268.68,565.88,263.51,561.82,265.78z"
          />
          <path
            className="st9"
            d="M553.28,275.01c-1.53-1.73-3.06-3.47-5-5.66c-0.72,1.67-1.06,2.47-1.63,3.78c2.08,1.05,3.95,1.99,5.82,2.93
		C552.75,275.71,553.02,275.36,553.28,275.01z"
          />
          <path
            className="st5"
            d="M333.5,828.79c-0.7,1.3-1.28,2.39-2.2,4.1c3.07,1,5.53,1.79,8.36,2.71
		C339.78,830.8,336.38,830.29,333.5,828.79z"
          />
          <path
            className="st12"
            d="M785.42,172.07c-5.88,1.2-8.09,6.13-10.76,10.6c2.86,9.96,5.84,19.9,8.53,29.9
		c0.88,3.27,1.02,6.73,1.49,10.11c5.82-1.77,11.47-3.91,15.06-9.22c6.64-9.83,6.75-20.37,2.01-30.76c-1.13-2.47-2.7-4.46-4.6-6.03
		c-0.95-0.78-1.98-1.46-3.08-2.04c-0.37-0.19-0.74-0.38-1.12-0.55C790.66,173.06,788.11,172.41,785.42,172.07z M788.67,205.82
		c0.33,1.67,0.58,2.92,0.94,4.78c-1.25,0.86-2.72,1.87-4.2,2.89c0,0,0,0.01,0,0.01l0,0c-0.01,0.01-0.01,0.01-0.02,0.02
		c-0.08,0.11-0.16,0.19,0,0c-0.04,0.03-0.07,0.05-0.09,0.07c-0.03,0.03-0.03,0.02,0,0c0.02-0.01,0.04-0.03,0.09-0.07l0,0
		c0,0,0,0,0,0c0.01-0.01,0.01-0.01,0.02-0.02c0,0,0.01,0,0.01-0.01c0.26-0.61,0.52-1.22,0.78-1.83
		C786.98,209.79,787.79,207.89,788.67,205.82z"
          />
          <path
            className="st10"
            d="M774.66,182.67c-4.54,7.4-9.08,14.81-13.63,22.21c-0.18,0.29-0.45,0.53-0.68,0.79
		c-0.48,0.94-0.82,1.98-1.45,2.8c-3.81,4.97-2.95,9.65,0.72,14.21c8.35,0,16.7,0,25.06,0c-0.48-3.38-0.62-6.84-1.49-10.11
		C780.5,202.56,777.52,192.63,774.66,182.67z"
          />
          <path
            className="st11"
            d="M761.31,173.77c0.48,0.28,0.97,0.56,1.45,0.84c-0.7,1.95-1.2,4.01-2.15,5.84c-1.9,3.67-4.04,7.22-6.08,10.83
		c1.41,3.54,2.8,7.07,4.22,10.61c0.51,1.27,1.06,2.53,1.6,3.79c0.23-0.26,0.5-0.5,0.68-0.79c4.55-7.4,9.09-14.8,13.63-22.21
		c2.67-4.47,4.88-9.4,10.76-10.6c-0.68-0.14-1.37-0.37-2.05-0.54c-0.68-0.18-1.36-0.3-2.02-0.24c-9.64,0.89-19.27,1.92-28.91,2.91
		c0.98,1.88,1.95,3.76,2.93,5.64C757.35,177.81,759.33,175.79,761.31,173.77z"
          />
          <path
            className="st7"
            d="M742.94,198.8c3.25-5.91,7.26-11.38,11.29-16.84c0.47-0.64,0.77-1.41,1.14-2.12
		c-0.97-1.88-1.95-3.76-2.92-5.64c-3.36,0.67-6.57,0.8-9.71,3.23c-2.77,2.14-5.02,5.17-6.56,8.62c-0.31,0.69-0.59,1.4-0.84,2.11
		c-0.25,0.72-0.47,1.45-0.66,2.19c-0.66,2.58-0.95,5.28-0.81,7.94c0,0.04,0.01,0.09,0.01,0.13c0.04,0.68,0.11,1.36,0.21,2.04
		c0.02,0.12,0.04,0.23,0.06,0.35c0.48,2.94,1.52,5.76,3.23,8.25c0.22,0.32,0.45,0.63,0.69,0.94
		C739.3,206.05,740.98,202.35,742.94,198.8z"
          />
          <path
            className="st9"
            d="M758.9,208.47c0.63-0.82,0.97-1.86,1.45-2.8c-0.53-1.26-1.09-2.52-1.6-3.79c-1.42-3.53-2.81-7.07-4.22-10.61
		c-2.86,4.83-5.72,9.66-8.58,14.49c-1.67,2.83-3.33,5.67-5,8.51c0.3,0.38,0.6,0.75,0.9,1.11c0.61,0.72,1.24,1.41,1.88,2.05
		c2.26,2.26,4.75,3.99,7.62,4.89c1.64,0.52,3.39,0.77,5.3,0.7c0.48-0.02,0.96-0.06,1.46-0.11c0.49-0.06,1-0.14,1.51-0.24
		C755.95,218.12,755.09,213.44,758.9,208.47z"
          />
          <path
            className="st10"
            d="M745.96,205.76c2.85-4.83,5.72-9.66,8.58-14.49c2.04-3.6,4.18-7.15,6.08-10.83c0.94-1.83,1.45-3.89,2.15-5.84
		c-0.48-0.28-0.97-0.56-1.45-0.84c-1.98,2.02-3.96,4.04-5.94,6.06c0,0,0,0,0,0c-0.38,0.71-0.67,1.48-1.14,2.12
		c-4.03,5.46-8.04,10.93-11.29,16.84c-1.95,3.55-3.63,7.25-4.88,11.2v0c0.55,0.81,1.1,1.62,1.65,2.43c0.42,0.61,0.83,1.23,1.25,1.84
		C742.62,211.44,744.28,208.6,745.96,205.76z"
          />
          <path
            className="st7"
            d="M793.63,222.02c4.73,0.2,6.29-0.05,7.51-2.86c0.41-0.94,0.77-2.16,1.2-3.75
		C799.51,217.55,796.57,219.78,793.63,222.02C793.63,222.01,793.63,222.02,793.63,222.02z"
          />
          <path
            className="st15"
            d="M796.36,172.93c2.18,2.53,4.32,5.02,6.58,7.66C801.14,173.51,801.67,171.89,796.36,172.93z"
          />
          <path
            className="st5"
            d="M301.08,805.9c-0.45,1.99-0.84,3.71-1.28,5.63c1.47,0.54,2.96,1.09,4.74,1.74
		C306.61,809.02,303.14,807.92,301.08,805.9z"
          />
          <path
            className="st5"
            d="M291.09,803.19c1.7,1.85,3.4,3.7,4.85,5.29c1.17-1.44,2.12-2.61,3.26-4.01c-2.43-0.75-4.97-1.55-7.51-2.34
		C291.48,802.48,291.29,802.84,291.09,803.19z"
          />
          <path
            className="st3"
            d="M409.3,857c0.14,0.69,0.29,1.37,0.43,2.06c2.23-0.55,4.47-1.09,6.7-1.64c-0.09-0.48-0.19-0.96-0.28-1.45
		C413.87,856.31,411.59,856.65,409.3,857z"
          />
          <path
            className="st3"
            d="M277.31,429.8c-2.95,3.88-2.3,7.25,0.52,11.46C278.54,436.9,279.49,433.36,277.31,429.8z"
          />
          <path
            className="st13"
            d="M751.87,250.75c5.95,3.33,26.56,4.59,29.59,1.47C771.59,251.72,761.73,251.23,751.87,250.75z"
          />
          <path
            className="st7"
            d="M780.03,247.58c-10.1-0.58-20.2-1.16-30.29-1.75c-0.02,0.48-0.05,0.97-0.07,1.45
		c10.1,0.44,20.21,0.87,30.31,1.31C780,248.26,780.01,247.92,780.03,247.58z"
          />
          <path
            onMouseOver={() => {
              setClassNameCouch("hover");
              setClassNameState("hover");
              setClassNameCouch3("hover");
              setClassNameCouch4("hover");
              setClassNameCouch5("hover");
            }}
            onMouseLeave={() => {
              setClassNameCouch("couch");
              setClassNameState("couch2");
              setClassNameCouch3("couch3");
              setClassNameCouch4("couch4");
              setClassNameCouch5("couch5");
            }}
            onMouseDown={() => {
              setClassNameCouch("clicked");
              setClassNameState("clicked");
              setClassNameCouch3("clicked");
              setClassNameCouch4("clicked");
              setClassNameCouch5("clicked");
            }}
            className={classNameCouch5}
            d="M822.82,758.16c-1.59-0.33-3.3-0.04-4.96-0.04c-0.21,0.55-0.43,1.1-0.64,1.65c1.56,1.32,3.11,2.63,4.67,3.95
		c0.06-1.39,0.13-2.78,0.23-5.05c1.57,1.21,2.74,1.89,3.61,2.83c2.99,3.23,6.6,5.96,10.99,4.76c6.18-1.69,11.53-5.03,13.14-12.01
		c0.39-1.7,0.74-3.47,0.7-5.2c-0.05-2.19,1.11-2.62,2.87-2.61c-0.88-1.16-1.95-3.38-2.59-3.26c-1.58,0.3-3.74,1.33-4.31,2.65
		C841.32,758.07,836.04,760.88,822.82,758.16z"
          />
          <path
            className="st2"
            d="M850.56,749.06c0.04,1.73-0.3,3.5-0.7,5.2c-1.61,6.99-6.96,10.32-13.14,12.01c-4.38,1.2-7.99-1.53-10.99-4.76
		c-0.88-0.95-2.04-1.62-3.61-2.83c-0.1,2.26-0.17,3.65-0.23,5.05c1.58,1.71,2.94,3.71,4.77,5.07c7.56,5.6,17.02,1.65,22.65-4.77
		c4.39-5.01,6.21-10.9,4.11-17.57C851.67,746.44,850.51,746.87,850.56,749.06z"
          />
          <path
            className="st2"
            d="M869.44,742.91c0.88,0.91,1.95,1.71,3.13,2.38c3.55,2.02,8.15,2.9,11.84,2.3c8.64-1.39,16.18-11.8,14.92-20.37
		c-2.34-0.42-3.23,0.61-3.62,2.93c-1.58,9.37-11.52,14.92-20.82,11c-2.5-1.05-5.18-1.66-7.78-2.47c0.16,0.8,0.46,1.55,0.85,2.25
		C868.35,741.64,868.85,742.3,869.44,742.91z"
          />
          <path
            onMouseOver={() => {
              setClassNameCouch("hover");
              setClassNameState("hover");
              setClassNameCouch3("hover");
              setClassNameCouch4("hover");
              setClassNameCouch5("hover");
              setClassNameCouch6("hover");
              setClassNameCouch7("hover");
              setClassNameCouch8("hover");
              setClassNameCouch9("hover");
            }}
            onMouseLeave={() => {
              setClassNameCouch("couch");
              setClassNameState("couch2");
              setClassNameCouch3("couch3");
              setClassNameCouch4("couch4");
              setClassNameCouch5("couch5");
              setClassNameCouch6("couch6");
              setClassNameCouch7("couch7");
              setClassNameCouch8("couch8");
              setClassNameCouch9("couch9");
            }}
            onMouseDown={() => {
              setClassNameCouch("clicked");
              setClassNameState("clicked");
              setClassNameCouch3("clicked");
              setClassNameCouch4("clicked");
              setClassNameCouch5("clicked");
              setClassNameCouch6("clicked");
              setClassNameCouch7("clicked");
              setClassNameCouch8("clicked");
              setClassNameCouch9("clicked");
            }}
            className={classNameCouch5}
            d="M874.88,741.16c9.3,3.92,19.24-1.63,20.82-11c0.39-2.32,1.28-3.35,3.62-2.93c-0.61-0.95-1.35-2.77-1.82-2.7
		c-1.47,0.21-3.15,0.81-4.19,1.82c-2.77,2.7-4.93,6.1-7.92,8.48c-2.88,2.29-6.4,3.3-10.48,2.64c-0.46-0.07-0.94-0.18-1.44-0.28
		c-0.33-0.07-0.67-0.14-1.01-0.2c-0.51-0.09-1.03-0.15-1.55-0.15c-1.38-0.01-2.72,0.39-3.81,1.85
		C869.7,739.5,872.38,740.1,874.88,741.16z"
          />
          <path
            className="st2"
            d="M859.11,776.62c-0.18,0.4-0.14,0.9-0.01,1.44c0.09,0.36,0.22,0.74,0.35,1.12c0.21,0.57,0.43,1.15,0.56,1.69
		c0.66-0.6,1.4-1.14,2.02-1.77c0.21-0.21,0.4-0.43,0.58-0.66c1.1-1.48,1.97-3.12,2.93-4.7c0.48-1.38,0.95-2.77,1.58-4.59
		c1.07,2.58,1.9,4.56,2.72,6.55c0.44-0.13,0.87-0.26,1.31-0.39c-0.74-3.14-1.48-6.27-2.22-9.41c0.62-3.49,1.25-6.99,1.88-10.52
		c-0.78-0.08-0.91-0.13-1-0.09c-0.41,0.2-0.81,0.43-1.21,0.65c-0.75,1.85-1.51,3.71-2.4,5.9c-1.3-1.42-2.38-2.59-3.46-3.77
		c-1.65-0.25-3.31-0.5-4.96-0.76c0.72,0.85,1.43,1.71,2.14,2.56c1.78,1.62,3.57,3.24,5.4,4.9
		C863.06,769.01,860.83,772.71,859.11,776.62z"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M860.01,780.87c-0.13-0.54-0.35-1.12-0.56-1.69c-0.14-0.38-0.27-0.76-0.35-1.12
		c-0.13-0.54-0.16-1.03,0.01-1.44c1.72-3.91,3.94-7.61,6.22-11.85c-1.84-1.67-3.62-3.29-5.4-4.9c2.34,3.79,0.5,6.9-1.55,10.02
		c-0.91,1.38-1.57,2.92-2.4,4.35c-1.57,2.72-1.26,5.22,0.57,6.75c0.83,0.69,1.97,1.19,3.39,1.4l0,0
		C859.97,781.89,859.99,781.38,860.01,780.87z"
            />
          </a>

          <polygon
            className="st12"
            points="859.96,782.4 859.95,782.4 859.95,782.4 859.95,782.4 859.95,782.41 	"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M871.15,775.3c-0.44,0.13-0.87,0.26-1.31,0.39c-0.83-1.99-1.65-3.97-2.72-6.55
		c-0.63,1.83-1.11,3.21-1.58,4.59c1.4,1.34,2.81,2.69,4.29,4.11c0.86-1.21,1.47-2.37,1.84-3.48c0.28-0.84,0.43-1.64,0.45-2.42
		c0-0.01,0-0.02,0-0.02c0.05-2.32-1.05-4.36-3.19-6.03C869.68,769.03,870.41,772.17,871.15,775.3z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M862.75,758.07c1.08,1.18,2.16,2.35,3.46,3.77c0.89-2.19,1.64-4.05,2.4-5.9c-0.39-0.73-0.79-1.46-1.18-2.19
		c-0.7,0.46-1.5,0.82-2.09,1.39C864.41,756.04,863.61,757.08,862.75,758.07c-1.38-4.61-2.86-5.64-6.56-4.16
		c0.53,1.14,1.07,2.27,1.6,3.4C859.44,757.56,861.1,757.82,862.75,758.07z"
            />
          </a>

          <path
            className="st2"
            d="M905.41,778.86c-4.94,4.01-9.45,8.55-14.27,12.72c-1.4,1.21-3.1,2.22-4.84,2.86c-1.61,0.6-3.46,0.52-5.19,0.84
		c-8.24,1.52-16.4,3.39-23.24,8.61c-1.81,1.38-3.14,3.4-4.68,5.12c0.32,0.54,0.64,1.08,0.97,1.62
		c15.42-9.19,32.51-11.31,50.08-13.41c-2.48-1.27-4.96-2.55-7.69-3.95c7.23-6.86,14.65-13.05,24.68-15.31
		c4.05-0.91,7.79-2.84,11.49-6.49C922.43,772.11,913.07,772.64,905.41,778.86z"
          />
          <path
            className="st2"
            d="M827.4,837.23c2.31-1.1,4.8-2.36,6.46-4.22c4.53-5.08,9.73-8.93,16.29-10.86c0.64-0.19,1.22-0.54,1.94-0.87
		c-0.3-0.64-0.54-1.15-0.78-1.65c-5.4-1.67-10.23-0.72-14.33,3.26c-4.2,4.08-8.33,8.23-13.01,12.87
		C824.01,837.23,825.35,838.2,827.4,837.23z"
          />
          <path
            className="st2"
            d="M851.28,819.59c0.01,0.01,0.01,0.03,0.02,0.04c0.01,0,0.03,0.01,0.04,0.01
		C851.34,819.65,851.28,819.59,851.28,819.59z"
          />
          <path
            className="st2"
            d="M805.51,821.81c-1.07-2.77-2.14-5.54-3.51-9.07c2.34-1.35,5.31-3.05,8.28-4.76c0,0,0,0,0,0
		c0.41-0.35,0.82-0.69,1.28-1.08c0.46-0.39,0.98-0.83,1.6-1.35c-1.52-0.15-2.43-0.25-3.34-0.34c-4.94-0.32-8.78,2.06-12.02,4.99
		c-4.85-1.4-9.25-3.4-14.45-1.7c4.25,2.15,8.82,3.84,12.69,6.42c3.01,2.01,5.6,4.56,7.28,8.3
		C804.06,822.75,804.79,822.28,805.51,821.81z"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M956.51,715.78c0.04-3.67,1.12-7.15-2.89-10.42c-0.93,1.23-2.57,2.42-2.44,3.39
		C951.56,711.79,953.19,714.28,956.51,715.78z"
            />
          </a>

          <path
            className="st2"
            d="M936.71,728.01c-0.45,0.2-0.9,0.41-1.4,0.63s-1.05,0.48-1.71,0.77c-0.96-2.38-1.92-4.75-2.88-7.11
		c0,0,0.01,0.01,0.02,0.01c0.02,0.01,0.05,0.03,0.05,0.03c-0.46-0.29-0.91-0.58-1.37-0.86c-0.22-0.06-0.44-0.12-0.66-0.18
		c-0.67-0.18-1.37-0.38-2.22-0.61c1.11,3.56,2.25,6.46,2.89,9.46c0.66,3.07,1.29,5.9,5.04,6.43c3.2,1.75,6.4,3.49,9.36,5.11
		c0.82-3.33-1.37-6.09-4.36-8.21c1.37-1.26,2.73-2.51,4.54-4.17c-0.94-0.37-1.64-0.65-2.23-0.88c-0.25-0.1-0.48-0.19-0.7-0.28
		c-0.09-0.03-0.17-0.07-0.25-0.1c-0.2-0.08-0.39-0.15-0.58-0.23C939.06,727.89,937.88,727.95,936.71,728.01z"
          />
          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch6}
              d="M802.01,812.74c1.36,3.53,2.44,6.3,3.51,9.07c1.39-1.78,2.95-3.5,4.06-5.37c0.52-0.87,0.93-1.77,1.18-2.73
		c0.12-0.45,0.21-0.92,0.25-1.4c0.04-0.38,0.05-0.77,0.03-1.17c-0.05-0.99-0.29-2.03-0.76-3.16
		C807.32,809.68,804.35,811.39,802.01,812.74z"
            />
          </a>

          <a
            href="https://app.manifold.xyz/c/LostPilgrimISSUE0"
            target={"_blank"}
            rel="noreferrer"
          >
            <path
              onMouseOver={() => {
                setClassNameCouch("hover");
                setClassNameState("hover");
                setClassNameCouch3("hover");
                setClassNameCouch4("hover");
                setClassNameCouch5("hover");
                setClassNameCouch6("hover");
                setClassNameCouch7("hover");
                setClassNameCouch8("hover");
                setClassNameCouch9("hover");
              }}
              onMouseLeave={() => {
                setClassNameCouch("couch");
                setClassNameState("couch2");
                setClassNameCouch3("couch3");
                setClassNameCouch4("couch4");
                setClassNameCouch5("couch5");
                setClassNameCouch6("couch6");
                setClassNameCouch7("couch7");
                setClassNameCouch8("couch8");
                setClassNameCouch9("couch9");
              }}
              onMouseDown={() => {
                setClassNameCouch("clicked");
                setClassNameState("clicked");
                setClassNameCouch3("clicked");
                setClassNameCouch4("clicked");
                setClassNameCouch5("clicked");
                setClassNameCouch6("clicked");
                setClassNameCouch7("clicked");
                setClassNameCouch8("clicked");
                setClassNameCouch9("clicked");
              }}
              className={classNameCouch5}
              d="M933.6,729.42c0.65-0.3,1.21-0.55,1.71-0.77s0.95-0.43,1.4-0.63h0c-2-1.9-3.99-3.8-5.99-5.71c0,0,0,0,0,0
		C931.68,724.67,932.64,727.04,933.6,729.42z"
            />
          </a>

          <path
            className="st17"
            d="M929.41,721.48c0.46,0.29,0.91,0.58,1.37,0.86"
          />
          <path
            className="st2"
            d="M1000.32,957.76c1.01,0.98,1.93,1.68,2.85,2.03c2.03,0.77,4.06-0.15,7.03-3.57c0,0,0,0,0,0
		c0.27-0.29,0.53-0.57,0.8-0.86c-0.08-0.12-0.16-0.24-0.24-0.37c-0.38,0.24-0.75,0.47-1.13,0.71c-1.36,0.23-2.79,1.13-4.09,0.8
		c-0.59-0.15-1.16-0.56-1.68-1.4c-1.22-0.69-2.45-1.39-4.3-2.43c-1.81,3.22-3.61,6.42-5.21,9.26c-1.98-1.76-3.7-3.29-5.46-4.85
		c-2.21,1.4-1.56,2.53,0.07,3.62c1.35,2.06,2.69,4.12,4.7,7.2C996.29,963.88,998.33,960.79,1000.32,957.76z"
          />
          <path
            className="st2"
            d="M1056.95,922.69c0,0-0.08,0.02-0.08,0.02c0.17-0.71,0.34-1.42,0.6-2.53c-1.04,0-1.98-0.3-2.48,0.05
		c-5.25,3.64-10.46,7.33-15.58,11.13c-0.68,0.51-0.85,1.71-1.33,2.75c1.22,0.13,1.8,0.19,2.39,0.25
		c5.21-3.62,10.42-7.25,15.63-10.87C1056.38,923.23,1056.67,922.96,1056.95,922.69z"
          />
          <path
            className="st2"
            d="M1024.75,948.27c0.27-1.33,0.54-2.67,0.81-4c-0.26-5.16-1.49-6.96-3.35-6.38c0.58,2.67,1.13,5.24,1.69,7.82
		c0.11,0.85,0.22,1.7,0.32,2.55C1024.4,948.26,1024.57,948.27,1024.75,948.27z"
          />
          <path
            className="st2"
            d="M1031.04,932.25c0,3.05,0,6.1,0,9.15c0.56-0.71,1.12-1.43,1.68-2.14
		C1033.54,936.72,1033.71,934.29,1031.04,932.25z"
          />
          <path
            className="st17"
            d="M1056.09,923.49c0.29-0.27,0.57-0.53,0.86-0.8"
          />
          <path
            className="st2"
            d="M911.89,960.12c6.01,5.96,13.81,8.33,21.61,10.58c1.23,0.36,3.45-0.15,4.2-1.07
		c3.74-4.64,3.14-8.06-1.64-11.88c-4.92-3.93-7.6-5.01-11-4.49c0.72,0.79,1.49,1.63,2.26,2.47c0.17,0.14,0.34,0.28,0.87,0.72
		c-0.69-0.22-0.96-0.3-1.24-0.38c-3.69-1.12-7.41-2.16-11.07-3.39c-2.39-0.8-4.41-0.9-5.79,1.64
		C908.71,956.87,910.25,958.49,911.89,960.12z M931.63,962.2c-1.45,0.95-2.68,1.75-4.06,2.66c-0.91-0.57-1.97-1.24-3.05-1.92
		C927.35,959.17,928.13,959.09,931.63,962.2z M912.64,954.73c3.86,1.16,7.72,2.31,12.13,3.63c-1.51,1.25-2.57,2.13-3.83,3.17
		c-2.9-1.82-5.86-3.67-8.82-5.53C912.29,955.58,912.47,955.15,912.64,954.73z"
          />
          <path
            className="st2"
            d="M1155.69,635.04c-0.26-0.83-0.39-1.23-0.51-1.62c-1.47-0.51-2.94-1.04-4.42-1.54
		c-4.29-1.43-8.58-2.84-12.88-4.26c-1.09-0.26-2.18-0.53-4.28-1.03c2.81-1.58,4.91-2.76,6.98-3.93c-1.71,0.27-3.41,0.55-5.12,0.82
		c0.02-0.07,0.04-0.15,0.06-0.22c-2.77,0-5.53,0-8.3,0c-0.06,0.35-0.12,0.69-0.17,1.04c1.87,0.76,3.75,1.53,5.62,2.29
		c-0.03,0.3-0.07,0.6-0.1,0.9c-3.71,0-7.42,0-11.13,0c0,0,0,0,0,0c0.42,1.03,0.59,2.33,1.33,3.01c0.52,0.48,1.83,0.27,2.75,0.13
		c3.54-0.52,7.14-1.82,10.59-1.5c3.29,0.3,6.43,2.26,9.64,3.5c0.1,0.4,0.2,0.8,0.3,1.2c-4,1.73-8,3.46-12.29,5.31
		c0-2.04,0-3.44,0-4.74c2.17-0.77,4.19-1.48,6.15-2.18c-2.26,0.06-4.49,0.11-6.72,0.17s-4.45,0.11-6.67,0.17
		c-0.2,0.16-0.4,0.32-0.61,0.48c2.06,0.81,4.13,1.62,6.19,2.43c-0.75,1.02-1.51,2.04-2.84,3.84c1.78,0.22,3.64,0.84,5.36,0.57
		c2.81-0.45,5.53-1.47,8.29-2.25c1.66-1.55,3.32-3.1,5.12-4.78C1150.26,633.49,1152.85,634.23,1155.69,635.04z"
          />
          <path
            className="st2"
            d="M1152.08,627.68c0.31,0.14,0.59,0.26,0.86,0.38c0.8,0.35,1.45,0.64,2.11,0.93l0,0
		c-0.2-0.51-0.4-1.03-0.61-1.56c-0.21-0.53-0.42-1.08-0.65-1.66c0.78-0.22,1.57-0.44,2.35-0.66c-0.07-0.33-0.15-0.67-0.22-1
		c-2.08,0.42-4.16,0.83-6.89,1.38c0.71-1.65,1.21-2.79,1.66-3.83c-0.97-0.34-1.85-0.64-2.69-0.94c-0.28-0.1-0.56-0.2-0.84-0.29v0
		l0,0c0.29-0.59,0.59-1.17,0.88-1.76c-0.2-0.23-0.4-0.47-0.6-0.7c-1.22,0.87-2.45,1.73-3.54,2.51c-0.82-1.12-1.7-2.33-2.95-4.03
		c-0.09,1.39-0.15,2.33-0.2,3.16c-0.02,0.28-0.03,0.54-0.05,0.81l0,0c0.05,0.74,0.1,1.47,0.16,2.21v0v0c0,0-0.06-0.07-0.06-0.07
		c0.35,0.42,0.71,0.83,1.25,1.47c0.79-0.34,1.71-0.73,2.9-1.23c0.5,0.61,1.12,1.35,2.28,2.76c-2.13-0.36-3.41-0.57-4.69-0.79
		c-0.08,0.19-0.16,0.37-0.23,0.56c1.44,0.75,2.89,1.49,4.33,2.24c2.07,0.83,4.14,1.65,6.4,2.55
		C1152.71,629.3,1152.48,628.71,1152.08,627.68z"
          />
          <path
            className="st2"
            d="M1156.06,633.24c0.37-0.05,0.84-0.1,1.51-0.19c-1.62-1.17-2.64-1.92-3.66-2.66c-0.16,0.19-0.31,0.39-0.47,0.58
		c0.58,0.79,1.16,1.59,1.74,2.38h0C1155.44,633.32,1155.71,633.29,1156.06,633.24z"
          />
          <path
            className="st2"
            d="M1155.26,633.45L1155.26,633.45L1155.26,633.45L1155.26,633.45c-0.02-0.03-0.05-0.07-0.07-0.1
		c-0.01,0-0.02,0-0.03,0c0,0,0,0,0,0c0.01,0.02,0.01,0.05,0.02,0.07C1155.2,633.44,1155.23,633.45,1155.26,633.45L1155.26,633.45z"
          />
          <path
            className="st2"
            d="M1156.98,633.66c0.15,0.3,0.3,0.6,0.45,0.9c0.19-0.07,0.37-0.15,0.56-0.22c-0.15-0.32-0.3-0.63-0.44-0.95
		C1157.36,633.49,1157.17,633.58,1156.98,633.66z"
          />
          <path
            className="st2"
            d="M1158.24,627.38c-0.08,0.32-0.15,0.65-0.23,0.97c0.47,0,0.94,0,1.42,0
		C1159.03,628.02,1158.63,627.7,1158.24,627.38z"
          />
          <path
            className="st2"
            d="M1125.42,631.85c-0.26,0.29-0.53,0.58-0.79,0.87c0.43,0.11,0.86,0.22,1.29,0.32c0.2-0.16,0.4-0.32,0.61-0.48
		C1126.16,632.33,1125.79,632.09,1125.42,631.85z"
          />
          <path
            className="st2"
            d="M1133.9,618.25c0.17-0.13,0.33-0.25,0.5-0.38c0.5-0.37,1-0.74,1.52-1.07c1.72-1.1,3.57-1.84,5.87-1.4
		c0.98,0.85,1.86,1.62,2.73,2.39c0.21-0.2,0.42-0.39,0.63-0.59c-0.4-0.77-0.8-1.55-1.41-2.71c0.51,0.15,0.89,0.27,1.22,0.37
		c0.32,0.1,0.59,0.18,0.85,0.26c0,0,0,0,0,0c-0.61-0.61-1.21-1.71-1.85-1.74c-0.88-0.04-1.77,0.04-2.66,0.15s-1.78,0.26-2.67,0.36
		c-3.32,1.43-6.64,2.86-9.97,4.28c-4.74,1.56-9.79,2.46-13.54,6.22c1.53,0.26,2.89,0.07,4.15-0.38c4.56-1.62,9.09-3.33,13.63-5.01
		C1133.23,618.76,1133.56,618.5,1133.9,618.25z"
          />
          <path
            className="st2"
            d="M1148.03,616.58c-0.05-0.32-0.09-0.55-0.13-0.76c0-0.03-0.01-0.05-0.01-0.08c-0.02-0.1-0.03-0.19-0.05-0.28
		c-0.57,0-1.14-0.01-1.71-0.01c0.51,0.44,1.02,0.88,2,1.73C1148.09,616.95,1148.06,616.75,1148.03,616.58z"
          />
          <path
            className="st2"
            d="M767.25,518.84c1.03,2.33,2.07,4.66,3.1,6.99c0.2-0.07,0.4-0.14,0.6-0.21c0.08-1.03,0.17-2.06,0.19-2.35
		c1.91-0.57,3.42-1.02,5.29-1.58c-0.62,0.9-1.26,1.82-2.2,3.19c0.43,0.17,2.03,0.93,2.1,0.8c0.92-1.59,1.63-3.31,2.44-5.11
		c-2.23-1.07-3.76-2.34-5.27-2.32c-0.84,0.01-1.64,2.19-2.23,3.05c-1.07-1.26-1.95-2.29-2.83-3.32
		C768.05,518.26,767.65,518.55,767.25,518.84z"
          />
          <path
            className="st2"
            d="M788.14,525.13c-0.75-2.17-1.41-4.09-2.08-6.01c-2.36-2.68-3.91-0.5-5.58,1.45
		C783.21,524.89,786.52,526.48,788.14,525.13z"
          />
          <path
            className="st2"
            d="M769.46,516.72c0.36-0.15,0.72-0.3,1.08-0.46c-0.22-3-0.44-6-0.66-9c-0.39-0.01-0.78-0.03-1.17-0.04
		c-0.33,0.74-0.66,1.48-1.1,2.48c-1.29-0.51-2.39-0.94-4.04-1.6C765.06,511.72,766.27,514.92,769.46,516.72z"
          />
          <path
            className="st2"
            d="M760.56,518.38c0.16,0.02,0.33,0.04,0.49,0.06c0.35-0.8,0.71-1.61,1.05-2.38c0.91,0.42,1.85,0.85,3.81,1.74
		c-1.83-3.6-3.16-6.22-4.59-9.05c-0.53,0.67-0.98,1.25-1.33,1.7c-0.86-0.19-1.63-0.36-2.41-0.53c-0.13,0.21-0.27,0.43-0.4,0.64
		c1.04,1.29,2.09,2.58,3.38,4.18C760.56,515.75,760.56,517.06,760.56,518.38z"
          />
          <path
            className="st2"
            d="M765.88,523.92c-0.59-1.61-1.14-3.11-1.69-4.61c-0.35,0.1-0.69,0.21-1.04,0.31c0.35,1.71,0.71,3.41,1.06,5.14
		c-0.65,0.39-1.49,0.9-2.32,1.4c0.29,0.45,0.59,0.9,0.88,1.35c2.03-0.47,4.07-0.93,6.1-1.4c-0.02-0.29-0.04-0.58-0.06-0.87
		C767.8,524.79,766.79,524.33,765.88,523.92z"
          />
          <path
            className="st2"
            d="M840.34,542.1c0.96,0.42,2.56-0.63,3.9-1.03c-0.66-1.88-1.09-3.11-1.52-4.35c-0.2,0.05-0.4,0.11-0.59,0.16
		c-0.06,1.17-0.12,2.33-0.18,3.5c-0.27,0.23-0.55,0.46-0.82,0.7c-1.15-1.54-2.31-3.07-3.32-4.41
		C837.19,538.84,837.97,541.05,840.34,542.1z"
          />
          <path
            className="st2"
            d="M880.55,489.15c5.37,2.52,8.28,7.13,10.96,12.05c1.25,2.31,2.7,4.51,4.27,7.1c0.03-0.04-0.38,0.64-1.46,2.41
		c2.03-0.34,3.15-0.34,4.11-0.73c3.34-1.36,6.55-3.11,9.96-4.25c5.8-1.94,10.55,0.2,13.34,5.47c0.74,1.39,1.85,2.59,2.79,3.87
		c0.19-0.11,0.38-0.21,0.57-0.32c-0.16-1.11-0.33-2.22-0.49-3.33c0,0-0.05-0.05-0.05-0.05c-0.29-0.42-0.58-0.84-0.87-1.27
		c-1.16-1.95-2.32-3.9-3.48-5.86c-5.15-0.54-10.3-1.09-16-1.69c1.68-2.05,3.6-4.41,5.52-6.77c2.47-1.88,4.95-3.75,7.57-5.74
		c-0.19-0.27-0.37-0.75-0.71-0.98c-0.32-0.22-0.97-0.39-1.21-0.22c-4.74,3.27-9.45,6.59-14.76,10.32c-1.35,0-3.69,0-5.86,0
		c-3.71-6.76-7.96-12.5-16.15-13.51C878.29,487.43,878.46,488.17,880.55,489.15z"
          />
          <path
            className="st2"
            d="M871.52,497.93c3.57,0.63,6.2,1.09,8.83,1.55c-3.3,0.68-6.69,0.6-8.65,4.34c3.91-0.73,7.82-1.47,11.74-2.21
		C879.89,497.3,877.73,496.52,871.52,497.93z"
          />
          <path
            className="st2"
            d="M912.58,526.31c4-0.21,4.7-2.03,3.65-9.5C914.51,517.06,912.77,521.61,912.58,526.31z"
          />
          <path
            className="st2"
            d="M932.35,518.21c0.52-1.28,1-2.58,1.49-3.88c-0.74-2.05-1.2-3.33-1.61-4.11c-0.14-0.26-0.27-0.47-0.41-0.63
		c-0.54-0.65-1.13-0.59-2.31-0.46c0.41,1.26,0.83,2.52,1.24,3.78c0,1.91,0,3.82,0,6.35C931.98,518.47,932.28,518.39,932.35,518.21z"
          />
          <path
            className="st2"
            d="M874.91,507.87c3.67-1.43,7.34-2.86,10.78-4.2c-0.83-0.41-1.82-0.62-2.89-0.65c-3.21-0.09-7.17,1.45-9.98,4.24
		C873.52,507.46,874.21,507.67,874.91,507.87z"
          />
          <path
            className="st2"
            d="M881.98,496.75c-1.03-0.94-1.29-1.17-1.55-1.4c-2.21-2.37-4.78-1.92-7.48-0.63c2.33,0.37,4.66,0.75,6.98,1.12
		C880.31,496.01,880.69,496.18,881.98,496.75z"
          />
          <path
            className="st14"
            d="M904.2,502.57c5.7,0.6,10.85,1.14,16,1.69c-0.67-1.99-1.47-3.95-1.99-5.97c-0.77-2.98-2.88-3.39-5.42-3.1
		c-0.26,0.03-0.52,0.07-0.77,0.12c-0.33,0.06-0.66,0.13-0.98,0.21c-0.44,0.1-0.88,0.2-1.31,0.3
		C907.8,498.16,905.87,500.52,904.2,502.57z"
          />
          <path
            className="st24"
            d="M924.55,511.38c-0.29-0.42-0.58-0.84-0.87-1.27"
          />
          <path
            className="st9"
            d="M609.07,249.2c-1.58,1.12-3.18,2.25-5.36,3.8c1.49,3.33,0.57,7.45,4.41,9.61
		C611.97,258.64,612.41,253.43,609.07,249.2z"
          />
          <path
            className="st7"
            d="M478.72,320.65c-1.37-2.77-4.69-2.74-7.65-2.4c-2.65,3.7-4.59,7.69-2.2,11.92c0.99,1.75,3.75,2.51,5.55,3.62
		C478.79,329.37,481.04,325.32,478.72,320.65z"
          />
          <path
            className="st8"
            d="M785.41,213.48c1.48-1.01,2.95-2.03,4.2-2.89c-0.37-1.85-0.61-3.11-0.94-4.78c-0.88,2.08-1.69,3.98-2.48,5.83
		C785.93,212.26,785.67,212.87,785.41,213.48z"
          />
          <path
            className="st8"
            d="M785.41,213.49c-0.01,0.01-0.01,0.01-0.02,0.02C785.4,213.5,785.4,213.49,785.41,213.49L785.41,213.49
		C785.41,213.49,785.41,213.48,785.41,213.49L785.41,213.49C785.41,213.48,785.41,213.48,785.41,213.49z"
          />

          <path
            className="st8"
            d="M785.3,213.57C785.28,213.59,785.27,213.6,785.3,213.57L785.3,213.57z"
          />
          <path
            className="st8"
            d="M785.39,213.5C785.39,213.5,785.39,213.5,785.39,213.5C785.24,213.7,785.31,213.61,785.39,213.5z"
          />
          <path
            className="st8"
            d="M785.3,213.57c0.02-0.02,0.05-0.04,0.09-0.07l0,0C785.35,213.54,785.32,213.56,785.3,213.57z"
          />
          <path
            className="st14"
            d="M924.77,958.36c-4.41-1.32-8.27-2.48-12.13-3.63c-0.17,0.43-0.35,0.85-0.52,1.28
		c2.96,1.86,5.92,3.71,8.82,5.53C922.2,960.49,923.27,959.61,924.77,958.36z"
          />
          <path
            className="st14"
            d="M924.52,962.94c1.08,0.68,2.14,1.35,3.05,1.92c1.39-0.91,2.62-1.71,4.06-2.66
		C928.13,959.09,927.35,959.17,924.52,962.94z"
          />
        </g>
      </svg>
    </div>
  );
}

export default Cafe;
