import React from 'react'

function Mint() {
  return (
    <div className='under'>
      Under Construction :3
    </div>
  )
}

export default Mint